<div class="flex-column bg-white rounded-card">
    <div class="header-card-gestion py-3">
      <button type="button" class="btn-dt-add my-4 ml-4" routerLink="fiche/nouveau"  data-title="Ajouter un organisme">
        <fa-icon class="icon-btn-add" [icon]="fa['faPlus']"></fa-icon>
      </button>
    </div>
    <div class="p-3 mb-3">
      <table id="Table" class="table">
        <thead class="head-table">
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Active</th>
            <th>Type</th>
            <th>Statut</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody class="body-table">
          <tr  *ngFor="let organisme of listOrganisme; let index = index">
            <td>{{ index+1 }}</td>
            <td>{{ organisme.nom }}</td>
            <td >
              <img src="/assets/icons/check-circle.svg" *ngIf=" organisme.active ===1">
              <img src="/assets/icons/times-icon.svg" *ngIf=" organisme.active ===0">
            </td>
            <td>{{organisme.libelle_type}}</td>
            <td>        
              <button  type="button" class="btn-dt-desactiver" *ngIf="organisme.active == '1'" (click)="changeStatus(organisme.id,0)">
                <fa-icon class="fa-fw" [icon]="fa['faLock']"></fa-icon>&nbsp;
                Désactiver
              </button>
            <button  type="button" class="btn-dt-activer"  *ngIf="organisme.active == '0'" (click)="changeStatus(organisme.id,1)">
              <fa-icon class="fa-fw" [icon]="fa['faUnlockAlt']"></fa-icon>&nbsp;
                Activer
            </button> 
            </td>
           <td>
            <button type="button" class="btn-dt-list" routerLink="fiche/{{organisme.id}}">
              <img src="assets/icons/edit-icon.svg">
             </button>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
 