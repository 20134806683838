export class StatusAutorise{
    listCategory: Category[];
}
export class Category{
  id;
  label;
  listactions: any[]=[];


  constructor(id, label, listactions: any[]) {
    this.id = id;
    this.label = label;
    this.listactions = listactions;
  } 
}
