<div class="row">
    <div class="col">
        <form [formGroup]="formFieldParams" (change)="updateItem()">
            <div class="form-group">
                <label>Nom </label>
                <input formControlName="name" name="name" id="fieldName" type="text" class="form-control" aria-describedby="nameHelper">
                <small id="nameHelper" class="form-text text-muted">Nom du champ</small>
            </div>
            <div class="form-group">
                <label>Texte </label>
                <input formControlName="label" name="label" required="" type="text" class="form-control" aria-describedby="labelHelper" >
                <small id="labelHelper" class="form-text text-muted">Étiquette à afficher
      </small>
            </div>
            <div class="form-group">
                <label for="bootstrapCol">Nombre de colonnes
      </label>
                <select formControlName="col" name="col" class="form-control" id="bootstrapCol">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6" >6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
      </select>
            </div>

            <ng-container *ngIf="item.typename=='Array'">
                <div class="form-group">
                    <label>minItems </label>
                    <input formControlName="minItems" name="minItems" required="" type="number" class="form-control" aria-describedby="labelHelper">
                    <small class="form-text text-muted">Min items to display</small>
                </div>
                <div class="form-group">
                    <label>maxItems </label>
                    <input formControlName="maxItems" name="maxItems" required="" type="number" class="form-control" aria-describedby="labelHelper">
                    <small class="form-text text-muted">Nombre maximum d'éléments à afficher</small>
                </div>
                <div class="form-group">
                    <h2>Coller l'objet Json:</h2>
                    <json-editor [options]="editorOptions" [data]="data" (change)="onChangeJson($event)"></json-editor>
                </div>
            </ng-container>

            <ng-container *ngIf="item.typename!='Array'">

                <div class="form-group">
                    <label>Description </label>
                    <input formControlName="description" name="description" required="" type="text" class="form-control" aria-describedby="descriptionHelper">
                    <small id="descriptionHelper" class="form-text text-muted">Description à afficher</small>
                </div>
                <div class="form-group">
                    <label>Valeur par défaut
        </label>
                    <input formControlName="default" name="default" required="" type="text" class="form-control" aria-describedby="defaultValueHelper">
                    <small id="defaultvalueHelper" class="form-text text-muted">valeur par défaut au démarrage</small>
                </div>
            </ng-container>
            <!--Numbers Options-->
            <ng-container *ngIf="item.typename=='Number'">
                <div class="form-group">
                    <label>Minimum</label>
                    <input formControlName="minimum" name="minimum" required="" type="number" class="form-control">
                </div>
                <div class="form-group">
                    <label>Minimum exclusif
        </label>
                    <input formControlName="exclusiveMinimum" name="exclusiveMinimum" required="" type="number" class="form-control">
                </div>
                <div class="form-group">
                    <label>Maximum</label>
                    <input formControlName="maximum" name="maximum" required="" type="number" class="form-control">
                </div>
                <div class="form-group">
                    <label>Maximum Exclusif</label>
                    <input formControlName="exclusiveMaximum" name="exclusiveMaximum" required="" type="number" class="form-control">
                </div>
                <div class="form-group">
                    <label>Multiple de</label>
                    <input formControlName="multipleOf" name="multipleOf" required="" type="number" class="form-control">
                </div>

            </ng-container>

            <!--Text Options-->
            <ng-container *ngIf="item.typename==('Text' || 'Password' || 'Email')">
                <div class="form-group">
                    <label>longueur maximale
        </label>
                    <input formControlName="maxLength" name="maxLength" required="" type="number" class="form-control" aria-describedby="maxLengthHelper">
                    <small id="maxLengthHelper" class="form-text text-muted">Longueur maximale
        </small>
                </div>
                <div class="form-group">
                    <label>Longueur minimale
        </label>
                    <input formControlName="minLength" name="minLength" required="" type="number" class="form-control" aria-describedby="minLength">
                    <small id="minLength" class="form-text text-muted">Longueur minimale
        </small>
                </div>
                <div class="form-group">
                    <label>modèle</label>
                    <input formControlName="pattern" name="pattern" required="" type="text" class="form-control" aria-describedby="pattern">
                    <small id="pattern" class="form-text text-muted">Motif requis
        </small>
                </div>
                <div class="form-group">
                    <label>Format</label>
                    <input formControlName="format" name="format" required="" type="text" class="form-control" aria-describedby="format">
                    <small id="format" class="form-text text-muted">Format requis</small>
                </div>
            </ng-container>

            <!--Text Area Options-->
            <ng-container *ngIf="item.typename=='Text Area'">
                <div class="form-group">
                    <label>Espace réservé
        </label>
                    <input formControlName="placeholder" name="placeholder" required="" type="text" class="form-control" aria-describedby="placeHolderHelper">
                </div>
                <div class="form-group">
                    <label>Lignes</label>
                    <input formControlName="rows" name="rows" required="" type="number" min="1" class="form-control">
                </div>
            </ng-container>
        </form>

        <!-- Select + Radio -->
        <div class="row" *ngIf="item.typename=='Radio' || item.typename=='Select'">
            <div class="col-12">
                <form [formGroup]="fieldOptions">
                    <div class="table-responsive col-12 form-group">
                        <table class="grid table table-bordered table-sortable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Label</th>
                                    <th>valeur</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let opt of item.widget.formlyConfig.templateOptions.options;let index=index">
                                    <td data-id="11">{{index + 1}}</td>
                                    <td>
                                        <input type="text" value="{{opt.label}}" class="form-control" disabled>
                                    </td>
                                    <td>
                                        <input type="text" value="{{opt.value}}" class="form-control" disabled>
                                    </td>
                                    <td>
                                        <button class="btn btn-danger">
                <i class="fa fa-trash" *ngIf="disableId" (click)="deletePossibleOption(item,index)"></i>
              </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-id="11">{{ item.widget.formlyConfig.templateOptions.options.length + 1}}</td>
                                    <td>
                                        <input type="text" value="" class="form-control" formControlName="label">
                                    </td>
                                    <td>
                                        <input type="text" value="" class="form-control" formControlName="value">
                                    </td>
                                    <td>
                                        <button type="button" (click)="addPossibleOpt(item)" class="btn btn-primary">
                <i class="fa fa-plus"></i>
              </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
        <!-- Select + Radio -->
        <div class="row" *ngIf="item.typename=='Multi Select'">
            <div class="col-12">
                <form [formGroup]="fieldOptions">
                    <div class="table-responsive col-12 form-group">
                        <table class="grid table table-bordered table-sortable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>valeur</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let opt of item.widget.formlyConfig.templateOptions.options;let index=index">
                                    <td data-id="11">{{index + 1}}</td>
                                    <td>
                                        <input type="text" value="{{opt.label}}" class="form-control" disabled>
                                    </td>
                                    <td>
                                        <button class="btn btn-danger">
                <i class="fa fa-trash" (click)="deletePossibleOption(item,index)"></i>
              </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-id="11">{{ item.widget.formlyConfig.templateOptions.options.length + 1}}</td>
                                    <td>
                                        <input type="text" value="" class="form-control" formControlName="value">
                                    </td>
                                    <td>
                                        <button type="button" (click)="addPossibleOpt(item)" class="btn btn-primary">
                                        <i class="fa fa-plus"></i>
                                      </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>