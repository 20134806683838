export const TN = {
  data: {
    PROSPECT: {
      SIREN: "Identifiant de l'établissement",
      SIRET: "Identifiant de l'entreprise",
      SOCIAL_SECURITY: {
        LABEL: 'CIN',
        REGEX: '[0-9]{8}',
        PLACEHOLDER: '09100001',
      },
      NAF: {
        LABEL: 'RNE',
        REGEX: '[0-9]{7}[A-Z]{1}',
        PLACEHOLDER: '0644745Q',
      },
    },
    ORGANISME: {
      SIREN: "Identifiant de l'établissement",
      SIRET: "Identifiant de l'entreprise",
      RCS: 'Immatriculation commerce',
      ORIAS: "Numéro d'agrément",
    },
    USER: {
      ORIAS: "Numéro d'agrément producteur",
    },
  },
};
