
<div class="card bg-white border-0 p-4" style="border-radius: 20px;">
<app-add-tasks [allowed_to_update]="allowed_to_update" [id_opportunite]="opportunity_id" [fromType]="'o'"></app-add-tasks></div>

















