export class CourrierCommunication{
    id_affaire:string;
    id_opp:string;
    id_prospect:string;
    date_envoie:string;
    id_modele:number;
    couleur:string;
    type_envoie:string;
    type_envelope:string;
    statut:string;
    statut_envoi:string;
    nbpage_courrier:string;
    type_courrier:string;
    obj_destinataire: ObjetDestinataire;
    obj_expediteur:ObjetExpediteur;
    constructor(init?: Partial<CourrierCommunication>) {
        Object.assign(this, init);
      }
}

export class ObjetDestinataire{
  rue_dest: string;
  nom_prenom_dest: string;
  complement_adr_dest: string;
  ville_dest: string;
  cp_dest: string;
  bp_dest: string;
  verif_bp_dest: string;
}
export class ObjetExpediteur{
  rue_exp: string;
  nom_prenom_exp: string;
  complement_adr_exp: string;
  ville_exp: string;
  cp_exp: string;
  bp_exp: string;
  verif_bp_exp: string;
}
