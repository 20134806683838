import { ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Tickets } from 'src/app/entity/Affaires/Tickets';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-tickets-fiche',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css'],
})
export class TicketsComponent implements OnInit, OnChanges {
  fa = fa;
  dataTable: any;
  listTickets: Tickets[] = [];
  show_loading_add: boolean = false;
  @Input() prospect: string = '';
  constructor(private apiProspectService: ApiProspectsService, private chRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.getTickets(this.prospect);
  }

  getTickets(id) {
    this.show_loading_add = true;
    this.apiProspectService.getProspectTickets(id).subscribe(
      (data: Tickets[]) => {
        this.listTickets = data;
        this.chRef.detectChanges();
        const table: any = $('#ticketsTable');
        this.dataTable = table.DataTable({
          stateSave: true,
          retrieve: true,
          language: {
            sProcessing: 'Traitement en cours...',
            searchPlaceholder: ' Recherche...',
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
            sInfo: "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            sInfoEmpty: "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: 'Chargement en cours...',
            sZeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
            sEmptyTable: 'Aucune donn&eacute;e disponible dans le tableau',
            oPaginate: {
              sFirst: 'Premier',
              sPrevious: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
              sLast: 'Dernier',
            },
            oAria: {
              sSortAscending: ': activer pour trier la colonne par ordre croissant',
              sSortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant',
            },
            select: {
              rows: {
                _: '%d lignes séléctionnées',
                0: 'Aucune ligne séléctionnée',
                1: '1 ligne séléctionnée',
              },
            },
          },
        });
        this.show_loading_add = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
