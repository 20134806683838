import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListeDocumentsComponent } from './liste-documents/liste-documents.component';
import { HistoriqueComponent } from './historique/historique.component';
import { CoordonneesProspectComponent } from './coordonnees-prospect/coordonnees-prospect.component';
import { InformationsTicketComponent } from './informations-ticket/informations-ticket.component';
import { CommentairesClientTicketComponent } from './commentaires-client-ticket/commentaires-client-ticket.component';
import { SharedModule } from '../../../shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { TicketInformationsSpecifiqueComponent } from './ticket-informations-specifique/ticket-informations-specifique.component';
import { AngularEditorModule } from '../../../components/angular-editor/angular-editor.module';
@NgModule({
  declarations: [
    ListeDocumentsComponent,
    HistoriqueComponent,
    CoordonneesProspectComponent,
    InformationsTicketComponent,
    CommentairesClientTicketComponent,
    TicketInformationsSpecifiqueComponent,
  ],
  imports: [CommonModule, SharedModule, MatMenuModule, AngularEditorModule],
  exports: [
    ListeDocumentsComponent,
    HistoriqueComponent,
    CoordonneesProspectComponent,
    InformationsTicketComponent,
    CommentairesClientTicketComponent,
    TicketInformationsSpecifiqueComponent,
  ],
})
export class TicketsDetailsModule implements OnInit {
  ngOnInit(): void {}
}
