import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, HostListener, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import swal from 'sweetalert2';
class formbuilder {
  data;
  schema;
}
class form {
  form: formbuilder;
  error;
}
class informationSpecifiques {
  informationSpecifiques: form;
}
@Component({
  selector: 'app-ticket-informations-specifique',
  templateUrl: './ticket-informations-specifique.component.html',
  styleUrls: ['./ticket-informations-specifique.component.css']
})
export class TicketInformationsSpecifiqueComponent implements OnInit, OnDestroy {
  fa = fa
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  infospecifique$: Observable<any> | null = null;
  unsubscribeGetInformationSpecifique: Subscription;
  loading: boolean = false;
  formdataspecif: any = null;
  formdata: any = null;
  idTicket: string = "";
  data: any = {}
  @Output() emitFormTicket = new EventEmitter<any>();
  @Output() changeFormInfoSpecifique = new EventEmitter<any>();
  changeInfoSpe: boolean =false;

  constructor(private route: ActivatedRoute, private formlyJsonschema: FormlyJsonschema,
    private apiTicketService: ApiTicketsService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiOpportunitService: ApiOpportunitService,

    private sharedMenuObserverService: SharedMenuObserverService
  ) {
    this.apiOpportunitService.listenSpecif().subscribe((m: any) => {
      this.pushInfo();
    })

    this.apiTicketService.listenTicket().subscribe((m: any) => {
      if (m == "formTicket") {
        this.somethingChanged()
     }
    })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.idTicket = params.get("tickets_id");
      this.loading = true;
      this.unsubscribeGetInformationSpecifique = this.apiTicketService.GetInformationSpecifique(this.idTicket)
        .subscribe((data) => {
          this.data = data.form

          this.form = new FormGroup({});
          if (this.data != null) {
            this.formdataspecif = this.data;
            this.options = {};
            if (this.data.schema) {
              let form: any = this.data.schema.properties
              this.data.schema.properties = {}
              this.model = {}
              form.forEach(element => {
                this.data.schema.properties[element.name] = element
                if (this.model[element.name])
                  this.model[element.name] = element.default
              });
              for (const [key, value] of Object.entries(this.data.data)) {
                if (value != null)
                  this.model[key] = value
              }

              this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.data.schema)))];
            }
            this.loading = false;
          }
          else if (this.data != null) {
            this.options = {};
            this.formdataspecif = this.data;

            if (this.data.schema) {
              this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.data.schema)))];
            }
            this.model = this.data.data;

            this.loading = false;
          }
        })
    });

  }

  alertWarning(title, invalidElements: any[]) {
    let listElementsAsString = ""
    invalidElements.forEach(element => {
      if (typeof element.name == "string")
        listElementsAsString = listElementsAsString + '<li style="list-style-type: none; font-size: 14px">' + element.name + ' : <u>' + element.value + '</u></li>'
    })
    swal.fire({
      title: title,
      icon: 'warning',
      html:
        '<ul class="p-0">' + listElementsAsString + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    })
  }

  ngOnDestroy(): void {
    // this.pushInfo()
    this.unsubscribeGetInformationSpecifique.unsubscribe();
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {
    this.pushInfo()
  }

  onChangeJson($event: any) {
    this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify($event)))];
  }

  somethingChanged() {
    let invalidElements = [];
    for (const [key, value] of Object.entries(this.form.controls)) {
      let element: any = value
      if (element.status == "INVALID") {
        invalidElements.push({ name: key, value: element.value })
      }
    }
    if (this.form.status == 'INVALID') {
      this.alertWarning("Format des informations complementaire ligne de produits invalide", invalidElements)
      this.apiAdminBpmServices.sharedFormIsValid = false;
    } else {
      this.apiAdminBpmServices.sharedFormIsValid = true;
      this.emitFormTicket.emit(this.form.value)
    }
  }

  pushInfo() {
    // if (this.formdataspecif && this.formdataspecif.informationSpecifiques) {
    this.formdata = new informationSpecifiques();
    this.formdata.informationSpecifiques = new form();
    this.formdata.informationSpecifiques.form = new formbuilder();
    this.formdata.informationSpecifiques.error = false;
    this.formdata.informationSpecifiques.form.schema = this.formdataspecif?.informationSpecifiques?.form?.schema;
    this.formdata.informationSpecifiques.form.data = (Object.keys(this.form.value).length !== 0) ? this.form.value : this.formdataspecif.informationSpecifiques.form.data;
    let nouvdata = JSON.stringify(this.formdata?.informationSpecifiques?.form.data);
    //   let anciendata =JSON.stringify(this.opportunitiesDetailsComponent?.detailsState?.informationSpecifiques?.informationSpecifiques?.form.data )
    //if (anciendata !== nouvdata)
    // this.store$.dispatch(actions.UpdateInfoSpecifqueAction({ payload: this.formdata }));
    // }
  }
  fromChange(){
    this.changeInfoSpe = true;
    this.changeFormInfoSpecifique.emit(this.changeInfoSpe )
  }
}
