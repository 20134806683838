export class MailevaElement {
  id: number = 0;
  approuved: boolean = false;
  ar_date: string = '';
  avis_reception: string = '';
  bp_adresse: string = '';
  bp_adresse_exp: string = '';
  bp_compl_ville_dest: string = '';
  bp_compl_ville_exp: string = '';
  complement_adr_dest: string = '';
  complement_adr_exp: string = '';
  couleur: string = '';
  date: string = '';
  date_envoie: string = '';
  date_livraison: string = '';
  donn_exp: string = '';
  donn_prospect: string = '';
  file_courrier: string = '';
  id_affaire: string = '';
  id_modele: string = '';
  id_opp: string = '';
  id_prospect: string = '';
  id_utilisateur: string = '';
  id_ville_dest: string = '';
  id_ville_exp: string = '';
  last_update: string = '';
  modele_reel: string = '';
  msg_error: string = '';
  nbr_page: string = '';
  nomprenom_dest: string = '';
  nomprenom_exp: string = '';
  postal_code_exp: string = '';
  postal_code_post: string = '';
  preuve_attachement: string = '';
  preuve_code: string = '';
  preuve_date: string = '';
  recto_verso: string = '';
  ref_notif: string = '';
  sent_from: string = '';
  statut: string = '';
  statut_details: StatusDetailsMaileva = new StatusDetailsMaileva();
  statut_envoi: string = '';
  statut_notif: string = '';
  subject: string = '';
  tarif_courrier: string = '';
  tarif_suppl: string = '';
  tentative_envoi: number = 0;
  type_courrier: string = '';
  type_envelope: string = 'C4';
  type_envoie: string = 'STANDARD';
  url_dossier: string = '';
  urlcallback: string = '';
  verif_bp_dest: number = 0;
  verif_bp_exp: number = 0;
  ville_dest: string = '';
  ville_exp: string = '';
  modele_nom: string = '';
  attached_files: string[] = [];
}

export class StatusDetailsMaileva {
  background: string = '';
  code: string = '';
  color: string = '';
  icone: string = '';
  libelle: string = '';
  description: string = '';
}
