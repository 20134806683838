import { Component, OnDestroy, OnInit } from '@angular/core';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
import { ApiAuthentificationService } from '../../../../../services/ApiAuthentification/api-authentification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tarificateur',
  templateUrl: './tarificateur.component.html',
  styleUrls: ['./tarificateur.component.css'],
})
export class TarificateurComponent implements OnInit, OnDestroy {
  opportunity_id: string = '';
  apikey: string = '';
  unsubscribeGet_session: Subscription;

  constructor(
    private opportunitiesDetails: OpportunitiesDetailsComponent,
    private apiAuthentificationService: ApiAuthentificationService
  ) {
    this.opportunity_id = this.opportunitiesDetails.opportunity_id;
  }

  ngOnDestroy(): void {
    this.unsubscribeGet_session.unsubscribe();
  }

  ngOnInit(): void {
    this.unsubscribeGet_session = this.apiAuthentificationService.get_session().subscribe((data) => {
      this.apikey = data.token;
    });
  }
}
