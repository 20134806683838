import { FilterBanque } from './../../entity/filter-banque';
import { ApiBanqueService } from './../../services/ApiBanque/api-banque.service';
import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-gestion-banque',
  templateUrl: './gestion-banque.component.html',
  styleUrls: ['./gestion-banque.component.css']
})
export class GestionBanqueComponent implements OnInit {
  subscribegetListBanque: Subscription;
  listBanque={}
  filterListBanque: FilterBanque =new FilterBanque()
  limit = 15;
  pages = 1;
  totalPages = 15;
  intervale = 1;
  fa = fa;
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private ApiBanqueService: ApiBanqueService,
    private chRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Gestion des banques');
    this.showloader()
    this.getListBanque()

    
  }
  getListBanque(){
    
    this.subscribegetListBanque = this.ApiBanqueService
      .getListBanque(this.filterListBanque,this.pages, this.limit)
      .subscribe((response: any) => {
        this.listBanque = response.list_banque
        this.chRef.detectChanges();
        this.calculate_nb_pages(response.iTotalDisplayRecords);
        this.hideloader();
      });

  }
  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.pages = this.intervale;
      if (this.pages <= this.totalPages) {
        this.getListBanque();
      }
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pages - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pages + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.pages = val;
    this.getListBanque();
  }

  isFirst(): boolean {
    return this.pages === 1;
  }

  isLast(): boolean {
    return this.pages === this.totalPages;
  }

  updateListBanque() {
    this.pages = 1;
    this.getListBanque();
  }

  changepage(number: number) {
    this.pages = number;
    localStorage.setItem('pages', number.toString());
    this.getListBanque();
  }

  getDataValue(event) {
    if ((event.target as HTMLInputElement).value.length > 1 || (event.target as HTMLInputElement).value.length === 0) {
      this.filterListBanque.sSearch = (event.target as HTMLInputElement).value;
     
      this.getListBanque();
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }



}
