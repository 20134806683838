import { ApiTagsService } from './api-tags.service';
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';
import {ApiAffairesService} from "./ApiAffaires/api-affaires.service";
import {ApiAuthentificationService} from "./ApiAuthentification/api-authentification.service";
import {ApiAutoService} from "./ApiAuto/api-auto.service";
import {ApiCampagniesService} from "./ApiCompagnies/api-compagnies.service";
import {ApiCampagneService} from "./ApiCampagne/api-campagne.service";
import {ApiClasseService} from "./ApiClasse/api-classe.service";
import {ApiComminucationService} from "./ApiComminucation/api-comminucation.service";
import {ApiConfigLeadsService} from "./ApiConfigLeads/api-config-leads.service";
import {ApiContratsService} from "./ApiContrats/api-contrats.service";
import {ApiDocumentsService} from "./ApiDocuments/api-documents.service";
import {ApiDossierService} from "./ApiDossier/api-dossier.service";
import {ApiEventsService} from "./ApiEvents/api-events.service";
import {ApiGammeService} from "./ApiGamme/api-gamme.service";
import {ApiLexiqueService} from "./ApiLexique/api-lexique.service";
import {ApiMenuService} from "./ApiMenu/api-menu.service";
import {ApiNotificationService} from "./ApiNotification/api-notification.service";
import {ApiOpportunitService} from "./ApiOpportunite/api-opportunite.service";
import {ApiParametreService} from "./ApiParametre/api-parametre.service";
import {ApiProduitsService} from "./ApiPoduits/api-poduits.service";
import {ApiProspectsService} from "./ApiProspects/api-prospects.service";
import {ApiQualificateurService} from "./ApiQualificateur/api-qualificateur.service";
import {ApiStatusService} from "./ApiStatus/api-status.service";
import {ApiTicketsService} from "./ApiTickets/api-tickets.service";
import {ApiUsersService} from "./ApiUsers/api-users.service";
import {ApiVenteADistanceService} from "./ApiVenteDistance/api-vente-distance.service";
import {ApiVilleService} from "./ApiVille/api-ville.service";
import {ApiVillesService} from "./ApiVilles/api-villes.service";
import {ApiVoIPService} from "./ApiVoIP/api-voip.service";
import { ApiLocationServices } from './ApiLocation/api-location.service'
import { ApiLedgerService } from './ApiLedger/api-ledger.service'
import { ChecklistDatabase } from '../pages/opportunities/list-opportunite/filters/filtres-status-actuel/filtres-status-actuel.component';
import {ProspectFinderService} from "./prospect-finder.service";
import {ApiAdminBpmService} from "./ApiAdminBpm/api-admin-bpm.service";
import {ApiOrganismeService} from "./ApiOrganisme/api-organisme.service";
import { EditingMachineService } from './ApiEditingMachine/editing-machine.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApiAffairesService,
    ApiAuthentificationService,
    ApiAutoService,
    ApiCampagneService,
    ApiCampagniesService,
    ApiClasseService,
    ApiComminucationService,
    ApiConfigLeadsService,
    ApiContratsService,
    ApiDocumentsService,
    ApiDossierService,
    ApiEventsService,
    ApiGammeService,
    ApiLexiqueService,
    ApiMenuService,
    ApiNotificationService,
    ApiOpportunitService,
    ApiParametreService,
    ApiProduitsService,
    ApiProspectsService,
    ApiQualificateurService,
    ApiStatusService,
    ApiTicketsService,
    ApiUsersService,
    ApiVenteADistanceService,
    ApiVilleService,
    ApiVillesService,
    ApiVoIPService,
    ApiLocationServices,
    ApiLedgerService,
    ProspectFinderService,
    ApiAdminBpmService,
    ChecklistDatabase,
    ApiOrganismeService,
    EditingMachineService,
    ApiTagsService
  ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
