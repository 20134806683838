import { environment } from 'src/environments/environment';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { CourrierCommunication, ObjetDestinataire } from 'src/app/entity/Generic/CourrierCommunication';
import { DatePipe } from '@angular/common';
import { ApiMailTemplatesService } from 'src/app/services/ApiMailTemplates/api-mail-templates.service';
import swal from 'sweetalert2';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { CommunicationModel } from 'src/app/entity/CommunicationModel';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { Router } from '@angular/router';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { ListProspect } from 'src/app/entity/Prospect';
import { DetailsOpportuniteStore } from 'src/app/pages/opportunities/details-opportunite/store/details-opportunite.reducers';
import { Store } from '@ngrx/store';

import * as actions from '../../pages/opportunities/details-opportunite/store/details-opportunite.actions';
@Component({
  selector: 'app-add-communication',
  templateUrl: './add-communication.component.html',
  styleUrls: ['./add-communication.component.css'],
  providers: [DatePipe],
})
export class AddCommunicationComponent implements OnInit {
  appearance = 'standard';
  @Input() id_opportunite;
  @Input() affaire_id: string = '';
  @Input() listParent;
  @Input() listTypes;
  @Input() type_envoie;
  @Input() statut_envoi;
  @Input() statut;
  @Input() prospect;
  @Input() telNumber;
  @Input() fromtype;
  @Input() allowed_to_update: boolean;
  @Input() allowed_to_update_affaire: boolean;
  telCountry = environment.country

  public Editor = ClassicEditor;
  public config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'undo',
      'redo',
      'blockQuote',
      '|',
      'numberedList',
      'bulletedList',
      'mediaEmbed',
      '|',
      'insertTable',
      'tableColumn',
      'tableRow',
      'mergeTableCells',
    ],
  };

  files: any[] = [];

  fa = fa;
  communicationModel: CommunicationModel[] = [];
  content: string = '';
  formModel: FormGroup;
  sujet: string = '';
  mail_user: string;

  idModel: string = '';
  with_color: string;
  recto_verso: string;
  type_file: string;
  file: File;
  ListFiles: File[] = [];
  index: number = 0;
  courrier: CourrierCommunication;
  obj_destinataire: ObjetDestinataire;
  myDate = new Date();
  currentDateTime: string;

  id_model_courrier: string;

  id_courrier: any;

  show_loading: boolean = true;
  show_loading_prev: boolean = false;
  fromDetails: Boolean = true;
  show_loading_add: any;
  options: any;
  eventsModel: any;
  @ViewChild('external') external: ElementRef;
  @ViewChild('closebuttonSMS') closebuttonSMS; // permet de fermer le popup SMS
  @ViewChild('closebuttonMail') closebuttonMail; // permet de fermer le popup Mail

  id_categorie: string;
  libelle_categorie: string = '';
  contentBody: {} = {};
  contentPreview: any;
  type_comm: any;
  toggleCalendar = false;
  accees: boolean;
  constructor(
    private apiCommunicationService: ApiComminucationService,
    private apiMailTemplateService: ApiMailTemplatesService,
    private apiProspectService: ApiProspectsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    public NotificationsService: NotificationsService,
    private store$: Store<DetailsOpportuniteStore>
  ) {
    this.currentDateTime = this.datePipe.transform(this.myDate, 'yyyy-mm-dd HH:MM:SS.SSS');
  }

  ngOnInit(): void {
    if (this.fromtype === 'O') {
      this.accees = this.allowed_to_update;
      this.getprospectoppp();
    } else if (this.fromtype === 'A') {
      this.accees = this.allowed_to_update_affaire;
    }
    this.formModel = this.formBuilder.group({
      mail_destinataire: new FormControl('', Validators.required),
      content: new FormControl(''),
      body_content_mail: new FormControl('<p>Bonjour...</p>'),
      body_content_sms: new FormControl('<p>Bonjour...</p>'),
      sujet_sms: new FormControl('', Validators.required),
      telephone_destinataire: new FormControl('', Validators.required),
      sujet_mail: new FormControl('', Validators.required),
      file: new FormControl(''),
      preview: new FormControl(''),
    });
    this.options = {
      editable: true,
      customButtons: {
        myCustomButton: {
          text: 'custom!',
          click: function () {
            alert('clicked the custom button!');
          },
        },
      },
      theme: 'standart', // default view, may be bootstrap
      header: {
        left: 'prev,next today myCustomButton',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      columnHeaderHtml: () => {
        return '<b>Friday!</b>';
      },
      locales: [esLocale, frLocale],
      locale: 'fr',
      // add other plugins
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    };
  }

  preview() {
    if (this.affaire_id === '' && this.id_opportunite === '') {
      this.alertWarningModele(
        'Le modèle sélectionné requiert une opportunité ou une affaire !',
        'Veuillez sélectionner un modèle sans <#clefs#> où envoyer le courrier depuis une opportunité ou une affaire'
      );
      return;
    }

    if (!this.idModel && !this.type_comm) {
      this.alertWarningModele('Pas de modèle sélectionné !', 'Veuillez sélectionner un modèle');
      return;
    }
    if (this.contentBody === '') {
      this.alertWarningModele('veuillez saisir un email !', '');
      return;
    }

    this.show_loading_prev = true;
    this.apiCommunicationService
      .editingMachine(this.idModel, this.type_comm, this.affaire_id, this.id_opportunite, this.contentBody)
      .subscribe((data) => {
        this.contentPreview = data.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.formModel.controls.preview.setValue(this.contentPreview);
        this.show_loading_prev = false;
      });
  }

  getValueFromEditor({ editor }) {
    const data = editor.getData();
    if (data === '') this.contentBody = '';
    else this.contentBody = { mail_template: data };
  }

  getInfoModel(model) {
    if (model.type === 'S') {
      this.type_comm = model.type;
      this.idModel = model.id;
      this.formModel.controls.telephone_destinataire.setValue(this.telNumber.list_tel.mobile);
      this.formModel.controls.sujet_sms.setValue(model.sujet);
      this.formModel.controls.body_content_sms.setValue(model.simple_content);
    } else {
      this.type_comm = model.type;
      this.idModel = model.id;
      this.formModel.controls.mail_destinataire.setValue(this.prospect.mainProspect.adresse_mail);
      this.formModel.controls.sujet_mail.setValue(model.sujet);
      this.formModel.controls.body_content_mail.setValue(model.simple_content);
    }
  }

  getprospectoppp() {
    this.apiProspectService.getProspectByOpprtuniteId(this.id_opportunite).subscribe((data: ListProspect) => {
      this.prospect = data;
    });
  }

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarningModele(data, response) {
    swal.fire({
      title: data,
      icon: 'warning',
      html: response,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  setCategorieValue(id: string, libelle: string) {
    this.id_categorie = id;
    this.libelle_categorie = libelle;
  }

  resetForm() {
    this.communicationModel = [];
    this.sujet = '';
    this.idModel = '';
    this.libelle_categorie = '';
    this.formModel.controls.body_content_mail.setValue('<p>Bonjour ..</p>');
    this.formModel.controls.sujet_mail.setValue('');
    this.formModel.controls.sujet_mail.setErrors(null);
    this.formModel.controls.sujet_sms.setValue('');
    this.formModel.controls.sujet_sms.setErrors(null);
    this.formModel.controls.telephone_destinataire.setErrors(null);
    this.formModel.controls.body_content_sms.setValue('<p>Bonjour ..</p>');
    this.formModel.controls.mail_destinataire.setValue(this.prospect.mainProspect.adresse_mail);
  }

  sendSMS() {
    if (this.formModel.value.telephone_destinataire === '' || this.formModel.value.telephone_destinataire === null) {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = 'Veuillez mettre le numéro de téléphone !';
      this.NotificationsService.sendMessage(notificationMessage);
    } else if (this.formModel.value.sujet_sms === '' || this.formModel.value.sujet_sms === null) {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = "Veuillez mettre le sujet de l'SMS";
      this.NotificationsService.sendMessage(notificationMessage);
    } else if (this.formModel.value.body_content_sms === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = 'Veuillez mettre le contenu !';
      this.NotificationsService.sendMessage(notificationMessage);
    } else {
      this.show_loading_add = true;
      const formData = new FormData();
      formData.append('id_affaire', this.affaire_id);
      formData.append('id_model', this.idModel);
      formData.append('id_opp', this.id_opportunite);
      formData.append('id_prospect', this.prospect.mainProspect.id);
      formData.append('media', 'S');
      formData.append('sujet', this.formModel.value.sujet_sms);
      formData.append('mail', '');
      formData.append('tel', this.formModel.value.telephone_destinataire);
      formData.append('modele_reel', this.formModel.value.body_content_sms);
      this.apiCommunicationService.saveDataCommunicationMailSMS(formData).subscribe(
        (response) => {
          this.show_loading_add = false;
          this.apiCommunicationService.pushcommunicationhistoric('test');
          this.closebuttonSMS.nativeElement.click();
          this.alertSuccess(response.message);
          this.resetForm();
        },
        (error) => {
          this.alertError(error.message);
        }
      );
    }
  }

  SendEmail() {
    if (this.formModel.value.mail_destinataire === '' || this.formModel.value.mail_destinataire === null) {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = "Veuillez mettre l'email de destinataire ";
      this.NotificationsService.sendMessage(notificationMessage);
    } else if (this.formModel.value.sujet_mail === '' || this.formModel.value.sujet_mail === null) {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = "Veuillez mettre le sujet de l'email";
      this.NotificationsService.sendMessage(notificationMessage);
    } else if (this.formModel.value.body_content_mail === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = 'Veuillez mettre le contenu ! ';
      this.NotificationsService.sendMessage(notificationMessage);
    } else {
      this.show_loading_add = true;
      const formData = new FormData();
      formData.append('id_affaire', this.affaire_id);
      formData.append('id_model', this.idModel);
      formData.append('id_opp', this.id_opportunite);
      formData.append('id_prospect', this.prospect.mainProspect.id);
      formData.append('media', 'M');
      formData.append('sujet', this.formModel.value.sujet_mail);
      formData.append('tel', '');
      formData.append('mail', this.formModel.value.mail_destinataire);
      formData.append('modele_reel', this.formModel.value.body_content_mail);
      this.apiCommunicationService.saveDataCommunicationMailSMS(formData).subscribe(
        (response) => {
          this.show_loading_add = false;
          this.apiCommunicationService.pushcommunicationhistoric('test');
          this.closebuttonMail.nativeElement.click();
          this.alertSuccess(response.message);
          this.resetForm();
        },
        (error) => {
          this.alertError(error.message);
        }
      );
    }
  }

  dropFile(index: number) {
    this.ListFiles.splice(index, 1);
    this.index--;
  }

  SaveModel(media: string) {
    const formData = new FormData();
    if (media === 'S') {
      formData.append('simple_content', this.formModel.value.body_content);
      formData.append('riche_content', '');
      formData.append('sujet', '');
      formData.append('media', media);
      formData.append('type', '');
      formData.append('file[]', '');
    } else if (media === 'M') {
      formData.append('simple_content', '');
      formData.append('riche_content', this.formModel.value.body_content);
      formData.append('sujet', this.formModel.value.sujet);
      formData.append('media', media);
      formData.append('type', 'LR'); // LR as Default( à voir )
      formData.append('type_entity', 'A'); // LR as Default( à voir )
      this.ListFiles.forEach((file) => formData.append('file[]', file));
    }
    this.apiMailTemplateService.SaveModel(formData).subscribe(
      (response) => {
        alert(response.message);
      },
      (error) => {
        alert(error.message);
      }
    );
  }

  ChangeColor(event: any) {
    if (event.target.checked) {
      this.with_color = 'oui';
    } else {
      this.with_color = 'non';
    }
  }

  ChangeRectoVerso(event: any) {
    if (event.target.checked) {
      this.recto_verso = '1';
    } else {
      this.recto_verso = '0';
    }
  }

  expandFileName(name: string): string {
    name = name?.trim();
    if (name?.length < 12) {
      return name;
    } else {
      return name.substring(0, 6) + '...' + name.substring(name?.length - 5, name?.length);
    }
  }

  showSubMenu($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.closeAllDropDownElements($event);
    const parentNode = $event.target.parentNode;
    const listDropDowns = parentNode.getElementsByClassName('dropdown-menu');
    const elem = listDropDowns[0];
    elem.classList.toggle('show');
  }

  private closeAllDropDownElements($event) {
    const parentNode = $event.target.parentNode;
    const listDropDownss = parentNode.parentNode.getElementsByClassName('dropdown-menu');
    for (let i = 0; i < listDropDownss?.length; i++) {
      listDropDownss[i].classList.remove('show');
    }
  }

  getListModelCommunication(media) {
    this.show_loading = true;
    this.apiCommunicationService
      .listModelWithTypeCommGet(media, this.fromtype)
      .subscribe((data: CommunicationModel[]) => {
        const list: any[] = data.filter((element) => element.id_parent == null);
        list.forEach((element) => (element.childs = this.getChildren(data, element)));
        this.communicationModel = list;
        this.show_loading = false;
      });
    this.formModel.controls.mail_destinataire.setValue(this.prospect.mainProspect.adresse_mail);
    this.formModel.controls.telephone_destinataire.setValue(this.telNumber.list_tel.mobile);
  }

  hasError($event: boolean) {}

  getChildren(array, parent) {
    const list = array.filter((ele) => ele.id_parent === parent.id);
    if (list?.length > 0) {
      list.forEach((element) => (element.childs = this.getChildren(array, element)));
    }
    return list;
  }

  setComValue(value: string) {
    this.id_model_courrier = value;
  }

  /* ---------------- RDV Visio ------------------ */

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  goToPlanification() {
    this.store$.dispatch(actions.UpdateTypeRdv({ payload: { type_rdv_id: 7 } }));
    this.router.navigateByUrl('/opportunities/details/' + this.id_opportunite + '/planification');
  }

  /* -----------------------------END RDV Caledar------------------------------------------------- */
}
