import { Task } from './../../entity/task';

import { Component, OnInit } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiTagsService } from './../../services/api-tags.service';
import { ApiTasksService } from './../../services/ApiTasks/api-tasks.service';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import swal from 'sweetalert2';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit {
  loader: boolean = false;
  pageAffect: number = 1;
  limit: number = 13;
  limitAffect: number = 10;
  totalPagesAffect: number = 5;
  page = 1;
  totalPages: number = 5;
  loaderAffect: boolean = false;
  search_create: string = '';
  search_affect: string = '';
  fa = fa;
  taskDetail: Task = new Task();
  isExpand: boolean = false;
  isExpandAffect: boolean = false;
  isModif: boolean = false;
  typeTache: string = '';
  Tasks: any;
  ListCLientProspect: any = [];
  tagsList: any[] = [];
  Tasksaffect: any;
  priorites: any;
  ListUser: any = [];
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiTagsServices: ApiTagsService,
    private apiTasksService: ApiTasksService,
    private apiUsersService: ApiUsersService,
    private apiTicketsServices: ApiTicketsService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Tâches');

    this.apiTagsServices.GetListTags('A').subscribe(
      (data) => {
        this.tagsList = data;
      },
      (error) => {
        console.log(error);
      }
    );
    this.apiTasksService.list_prospects().subscribe((data) => {
      this.ListCLientProspect = data;
    });

    this.apiTasksService.list_priorite().subscribe((data) => {
      this.priorites = data.data;
    });

    this.apiUsersService.getAllUsers().subscribe((data) => {
      data.forEach((item) => {
        const personne = {
          id: item.id,
          full_name: item.nom + ' ' + item.prenom,
        };
        this.ListUser.push(personne);
      });
    });
    this.list_tachess('');
  }

  searchTache(event, type) {
    if (event.target.value.length > 2 || event.target.value.length === 0) {
      if (type === 'crees') {
        this.page = 1;
        this.search_create = event.target.value;
        this.search_affect = '';
        this.list_tachess('create');
      } else {
        this.search_affect = event.target.value;
        this.search_create = '';
        this.pageAffect = 1;
        this.list_tachess('affect');
      }
    }
  }

  list_tachess(mode) {
    if (mode === 'create') {
      this.loader = true;

      this.apiTasksService
        .list_taches(
          this.search_create,
          this.search_affect,
          this.page,
          this.pageAffect,
          this.limit,
          this.limitAffect,
          mode
        )
        .subscribe((data) => {
          this.Tasks = data.list_createdby;
          this.calculate_nb_pages(data.total_list_createdby);
          this.loader = false;
        });
    } else if (mode === 'affect') {
      this.loaderAffect = true;

      this.apiTasksService
        .list_taches(
          this.search_create,
          this.search_affect,
          this.page,
          this.pageAffect,
          this.limit,
          this.limitAffect,
          mode
        )
        .subscribe((data) => {
          this.Tasksaffect = data.list_affect;
          this.calculate_nb_pagesAffect(data.total_list_affect);
          this.loaderAffect = false;
        });
    } else {
      this.loader = true;
      this.loaderAffect = true;

      this.apiTasksService
        .list_taches(
          this.search_create,
          this.search_affect,
          this.page,
          this.pageAffect,
          this.limit,
          this.limitAffect,
          mode
        )
        .subscribe((data) => {
          this.Tasksaffect = data.list_affect;
          this.Tasks = data.list_createdby;
          this.calculate_nb_pages(data.total_list_createdby);
          this.calculate_nb_pagesAffect(data.total_list_affect);
          this.loaderAffect = false;
          this.loader = false;
        });
    }
  }

  public expandTask() {
    this.isModif = false;
    if (this.isExpand === false) {
      this.isExpand = true;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-12');
      box.classList.add('col-md-8');
      this.isExpandAffect = false;
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-8');
      box2.classList.add('col-md-12');
    }
    this.taskDetail = new Task();
    this.typeTache = '';
  }

  changerStatus(tache) {
    if (tache.statut === 0) {
      tache.statut = 1;
    } else {
      tache.statut = 0;
    }
    if (!tache.entity) {
      tache.entity = '';
      tache.id_entity = '';
    }

    this.apiTicketsServices.updateTicketNotificationV2(tache.id_entity, tache.id, tache.statut, tache.entity).subscribe(
      (data) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = 'Opération réussite';
        notificationMessage.message = data.message;
        this.notificationsService.sendMessage(notificationMessage);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  expandFileName(name: string): string {
    if (name.length < 20) {
      return name;
    } else {
      return name.substring(0, 19) + '...';
    }
  }

  viewModif(tache: Task, type) {
    if (type === 'cree') {
      this.isModif = true;
      this.isExpand = true;
      this.isExpandAffect = false;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-12');
      box.classList.add('col-md-8');
      this.taskDetail = tache;
      this.typeTache = type;
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-8');
      box2.classList.add('col-md-12');
    } else {
      this.isModif = true;
      this.isExpand = false;
      this.isExpandAffect = true;
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-12');
      box2.classList.add('col-md-8');
      this.taskDetail = tache;
      this.typeTache = type;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-8');
      box.classList.add('col-md-12');
    }
  }

  ajoutTaches(taskk) {
    if (!this.isModif) {
      this.apiTicketsServices.saveNotification(taskk).subscribe(
        (response) => {
          this.alertSuccess(response.message);
          this.isExpand = false;
          const box = document.getElementById('expand');
          box.classList.remove('col-md-8');
          box.classList.add('col-md-12');
          this.isExpandAffect = false;
          const box2 = document.getElementById('expand2');
          box2.classList.remove('col-md-8');
          box2.classList.add('col-md-12');
        },
        (error) => {
          this.alertError(error.message);
        }
      );
    } else {
      this.apiTasksService.EditTasks(taskk).subscribe(
        (response) => {
          if (response.error === false) {
            this.alertSuccess(response.message);
            this.isExpand = false;
            const box = document.getElementById('expand');
            box.classList.remove('col-md-8');
            box.classList.add('col-md-12');
            this.isExpandAffect = false;
            const box2 = document.getElementById('expand2');
            box2.classList.remove('col-md-8');
            box2.classList.add('col-md-12');
          } else {
            this.alertError('Erreur lors de la modification de la tâche');
          }
        },
        (err) => {
          console.log(err);
          this.alertError('Erreur lors de la modification de la tâche');
        }
      );
    }
  }

  cancelAddOrUpdate(inexpand): void {
    if (inexpand === false) {
      this.isExpand = false;
      this.isExpandAffect = false;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-8');
      box.classList.add('col-md-12');
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-8');
      box2.classList.add('col-md-12');
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  setPage(val: number) {
    this.page = val;

    this.list_tachess('create');
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    this.list_tachess('create');
  }

  firstAffect() {
    this.setPageAffect(1);
  }

  prevAffect() {
    this.setPageAffect(Math.max(1, this.pageAffect - 1));
  }

  nextAffect() {
    this.setPageAffect(Math.min(this.totalPagesAffect, this.pageAffect + 1));
  }

  lastAffect() {
    this.setPageAffect(this.totalPagesAffect);
  }

  calculate_nb_pagesAffect(iTotalDisplayRecords) {
    this.totalPagesAffect = Math.floor(iTotalDisplayRecords / this.limitAffect);
    if (iTotalDisplayRecords % this.limitAffect !== 0) {
      this.totalPagesAffect += 1;
    }
  }

  setPageAffect(val: number) {
    this.pageAffect = val;
    this.list_tachess('affect');
  }

  isFirstAffect(): boolean {
    return this.pageAffect === 1;
  }

  isLastAffect(): boolean {
    return this.pageAffect === this.totalPagesAffect;
  }

  changepageAffect(number: number) {
    this.pageAffect = number;
    this.list_tachess('affect');
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      icon: 'warning',
      text: "veuillez affecter la tâche à quelqu'un",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertSuccess(data) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (this.typeTache === 'cree') {
            this.list_tachess('create');
          } else if (this.typeTache === 'affecte') {
            this.list_tachess('affect');
          } else {
            this.list_tachess('');
          }
        }
      });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
