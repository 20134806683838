import { AffairesAaData } from './AffairesAaData';
export class AffairesList {

  aaData: AffairesAaData[];
  filtres_query: FiltresQuery;
  iTotalDisplayRecords: number = 0;
  iTotalRecords: number = 0;

}
export class FiltresQuery {
  query_aff: string = "";
  having: string = "";
  query_opp: string = "";
}