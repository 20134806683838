import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';

import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
@Component({
  selector: 'app-list-group-garanties',
  templateUrl: './list-group-garanties.component.html',
  styleUrls: ['./list-group-garanties.component.css'],
})
export class ListGroupGarantiesComponent implements OnInit {
  garantie: {};
  fa = fa;
  dataTable: any;
  unsubscribeGetListGroupGarantie: Subscription;
  constructor(
    private routes: Router,
    private apiGroupGarantieService: ApiGroupGarantieService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.showloader();
    this.sharedMenuObserverService.updateMenu('Gestion groupe des garanties');
     this.unsubscribeGetListGroupGarantie = this.apiGroupGarantieService
      .getListGroupGarantie()
      .subscribe((Response: any) => {
        this.garantie = Response;
        this.chRef.detectChanges();
        const table: any = $('#Table');
        this.dataTable = table.DataTable({
          pagingType: 'full_numbers',
          stateSave: true,
          language: {
            sProcessing: 'Traitement en cours...',
            searchPlaceholder: ' Recherche...',
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
            sInfo: "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            sInfoEmpty: "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: 'Chargement en cours...',
            sZeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
            sEmptyTable: 'Aucune donn&eacute;e disponible dans le tableau',
            oPaginate: {
              sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
              sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
            },
            oAria: {
              sSortAscending: ': activer pour trier la colonne par ordre croissant',
              sSortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant',
            },
            select: {
              rows: {
                _: '%d lignes séléctionnées',
                0: 'Aucune ligne séléctionnée',
                1: '1 ligne séléctionnée',
              },
            },
          },
        });
       
      });
      this.hideloader();
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  goTofiche() {
    this.routes.navigate(['/add-groupe-garantie/nouveau']);
  }

  goToDetails(item: any) {
    this.routes.navigate(['/add-groupe-garantie/' + item.id]);
  }

  expandFileName(name: string): string {
    if (name.length < 12) {
      return name;
    } else {
      return name.substring(0, 11) + '...';
    }
  }
}
