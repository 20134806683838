import { Validators } from '@angular/forms';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ChangeDetectorRef, ViewChild, ElementRef, OnChanges, OnDestroy } from '@angular/core';
import { Courrier } from 'src/app/entity/Maileva/FilterMaileva';
import { tap, startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-filters-courrier',
  templateUrl: './filters-courrier.component.html',
  styleUrls: ['./filters-courrier.component.css']
})
export class FiltersCourrierComponent implements OnInit, OnChanges, OnDestroy {
  fa = fa;
  filterCourrier: Courrier = new Courrier()
  listModeleSelected: any[] = [];
  listEnveloppeSelected: any[] = [];
  allModels: any[] = [];
  allInfo: any[] = [];
  listModels: any[] = [];
  listEmpty: any[] = [];
  modelSelectionnee: string = "";
  statut: any[] = [];
  statut_envoi: any[] = [];
  listTypeEnveloppeSelected: any[] = [];
  listTypeEnvoieSelected: any[] = [];
  listeOfUnsubscribeWebService: Subscription[] = [];

  date_debut_creation: string = "";
  date_debut_envoie: string = "";
  date_debut_livraison: string = "";
  date_debut_prevue: string = "";
  date_fin_creation: string = "";
  date_fin_envoie: string = "";
  date_fin_livraison: string = "";
  date_fin_prevue: string = "";
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushfilterCourrier: EventEmitter<Courrier> = new EventEmitter();

  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];



  // Mat Chips Type d'envoie
  typeEnvoieFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTypeEnvoie: Observable<any[]>;
  ListTypeEnvoie: string[] = [];
  type_envoie: any[] = [];

  @ViewChild('TypeEnvoieInput') TypeEnvoieInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTypeEnvoie') matAutocompleteTypeEnvoie: MatAutocomplete;
  constructor(private chRef: ChangeDetectorRef,
    private apiComminucationService: ApiComminucationService, private datePipe: DatePipe) { }
  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => { element.unsubscribe(); })
  }




  // Type d'envoie---------------------------------------------------------------------------------------------

  selectedTypeEnvoie(event: MatAutocompleteSelectedEvent): void {
    this.ListTypeEnvoie.push(event.option.viewValue);
    this.listTypeEnvoieSelected.push(event.option.value);
    this.TypeEnvoieInput.nativeElement.value = '';
    this.typeEnvoieFromCtrl.setValue(null);

  }

  removeTypeEnvoie(typeEnvoie: string): void {
    const index = this.ListTypeEnvoie.indexOf(typeEnvoie);
    if (index >= 0) {
      this.ListTypeEnvoie.splice(index, 1);
      this.listTypeEnvoieSelected.splice(index, 1);
    }
  }

  private _filterTypeEnvoie(): any[] {
    let filterValue = this.typeEnvoieFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.type_envoie.filter(typeEnvoie => typeEnvoie.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    this.getAllModels();
    this.getInformationDetailsLibelle()
    this.filteredTypeEnvoie = this.typeEnvoieFromCtrl.valueChanges.pipe(
      startWith(null),
      map((typeEnvoie: string | null) => typeEnvoie ? this._filterTypeEnvoie() : this.type_envoie.slice()));
  }

  getAllModels() {
    const unsubscribeGetModelsPost = this.apiComminucationService.GetModelsPost()
      .subscribe(
        (Response: any[]) => {
          this.allModels = Response;
          this.SelectListModels()
        }
      ); this.listeOfUnsubscribeWebService.push(unsubscribeGetModelsPost)

  }

  getInformationDetailsLibelle() {
    const unsubscribe =  this.apiComminucationService.GetDetailsLibellePost().pipe(
      tap(({ statut, statut_envoi, type_envoie }) => {
        this.statut = statut;
        this.statut_envoi = statut_envoi;
        this.type_envoie = type_envoie;
      })
    ).subscribe()
    this.listeOfUnsubscribeWebService.push(unsubscribe);
  }

  SelectListModels() {
    this.listModels = Object.keys(this.allModels);
    this.listEmpty = this.allModels[this.modelSelectionnee];
  }

  getSelectedModels(event) {
    this.filterCourrier.modele = this.listModeleSelected = event.value
  }
  getSelectedTypeEnvoi(event) {
    this.filterCourrier.enveloppe_courrier = this.listEnveloppeSelected = event.value
  }
  ngOnChanges(changes: SimpleChanges) {
    this.resetCourrierForm(changes.Reset.currentValue);
  }
  resetCourrierForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {
      this.listModeleSelected = []
      this.listEmpty = []
      this.filterCourrier.modele = this.listModeleSelected = []
      this.ListTypeEnvoie = []
      this.filterCourrier.type_envoie = this.listTypeEnvoieSelected = []
      this.listEnveloppeSelected = []
      this.filterCourrier.enveloppe_courrier = this.listEnveloppeSelected
      this.filterCourrier.heure_debut_creation = "";
      this.filterCourrier.heure_fin_creation = "";
      this.filterCourrier.heure_debut_prevue = "";
      this.filterCourrier.heure_fin_prevue = "";
      this.filterCourrier.heure_debut_livraison = "";
      this.filterCourrier.heure_fin_livraison = "";
      this.filterCourrier.sent_from = []
      this.filterCourrier.ref_preuve = "";
      this.filterCourrier.ref_notif = "";
      this.filterCourrier.ref_client = "";
      this.filterCourrier.date_fin_envoie = "";
      this.filterCourrier.date_debut_envoie = "";
      this.filterCourrier.date_debut_creation = "";
      this.filterCourrier.date_fin_creation = "";
      this.filterCourrier.date_debut_livraison = "";
      this.filterCourrier.date_fin_livraison = "";
      this.filterCourrier.date_debut_prevue = "";
      this.filterCourrier.date_fin_prevue = "";
      this.filterCourrier.type_courrier = []
      this.modelSelectionnee = "";
      this.date_debut_creation = "";
      this.date_debut_envoie = "";
      this.date_debut_livraison = "";
      this.date_debut_prevue = "";
      this.date_fin_creation = "";
      this.date_fin_envoie = "";
      this.date_fin_livraison = "";
      this.date_fin_prevue = "";

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushfilterCourrier.emit(this.filterCourrier);
    }
    this.SelectListModels()
  }

  EmitFilterCourrier() {
    this.filterCourrier.date_debut_creation = this.datePipe.transform(this.date_debut_creation, 'yyyy-MM-dd');
    this.filterCourrier.date_debut_envoie = this.datePipe.transform(this.date_debut_envoie, 'yyyy-MM-dd');
    this.filterCourrier.date_debut_livraison = this.datePipe.transform(this.date_debut_livraison, 'yyyy-MM-dd');
    this.filterCourrier.date_debut_prevue = this.datePipe.transform(this.date_debut_prevue, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_creation = this.datePipe.transform(this.date_fin_creation, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_envoie = this.datePipe.transform(this.date_fin_envoie, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_livraison = this.datePipe.transform(this.date_fin_livraison, 'yyyy-MM-dd');
    this.filterCourrier.date_fin_prevue = this.datePipe.transform(this.date_fin_prevue, 'yyyy-MM-dd');


    this.filterCourrier.type_envoie = this.listTypeEnvoieSelected;
    this.onPushfilterCourrier.emit(this.filterCourrier);
  }
}
