import {
  ActionUserAffaire,
  Action_user,
  Affaire,
  ContratActuel,
  FilterAffaires,
  Filtre_opp,
  global,
  Prospect,
  Qualite,
  Rdv,
  StatutActuel,
  TelephonieOpp,
  Utilisateur,
  UtilisateurOpp,
} from './../../../../../entity/Affaires/FilterAffaires';

import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter-affaire-global',
  templateUrl: './filter-affaire-global.component.html',
  styleUrls: ['./filter-affaire-global.component.css'],
})
export class FilterAffaireGlobalComponent implements OnInit {
  fa = fa;
  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;

  filterAffaires: global = new global();
  panelOpenState = false;
  collapseFilterBox: boolean = true;

  @Output() onPushFilters: EventEmitter<global> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();

  onResetAffaire: boolean = false;
  onResetProspect: boolean = false;
  onResetUtilisateur: boolean = false;
  onResetContratActuel: boolean = false;
  onResetTelephonie: boolean = false;
  onResetActionUtilisateur: boolean = false;
  onResetStatutActuel: boolean = false;
  onResetOpportunityAffaire: boolean = false;
  onResetUtilisateurOpp: boolean = false;
  onResetStatutActuelOpp: boolean = false;
  onResetActionUtilisateurOpp: boolean = false;
  onResetQualite: boolean = false;
  constructor(private chRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  resetForm() {
    this.onResetAffaire = true;
    this.onResetProspect = true;
    this.onResetUtilisateur = true;
    this.onResetContratActuel = true;
    this.onResetTelephonie = true;
    this.onResetActionUtilisateur = true;
    this.onResetStatutActuel = true;
    this.onResetOpportunityAffaire = true;
    this.onResetUtilisateurOpp = true;
    this.onResetStatutActuelOpp = true;
    this.onResetActionUtilisateurOpp = true;
    this.onResetQualite = true;
    this.chRef.detectChanges();
  }

  getResetAffaire($event) {
    this.onResetAffaire = $event;
  }

  getResetProspect($event) {
    this.onResetProspect = $event;
  }

  getResetUtilisateur($event) {
    this.onResetUtilisateur = $event;
  }

  getResetContratActuel($event) {
    this.onResetContratActuel = $event;
  }

  getResetTelephonie($event) {
    this.onResetTelephonie = $event;
  }

  getResetActionUtilisateur($event) {
    this.onResetActionUtilisateur = $event;
  }

  getResetStatutActuel($event) {
    console.log($event);
    this.onResetStatutActuel = $event;
  }

  getResetOpportunityAffaire($event) {
    this.onResetOpportunityAffaire = $event;
  }

  getResetUtilisateurOpp($event) {
    this.onResetUtilisateurOpp = $event;
  }

  getResetActionUtilisateurOpp($event) {
    this.onResetActionUtilisateurOpp = $event;
  }

  getResetStatutActuelOpp($event) {
    this.onResetStatutActuelOpp = $event;
  }

  getResetQualite($event) {
    this.onResetQualite = $event;
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filterAffaires);
  }

  EmitFilterAffaire($event: Affaire) {
    this.filterAffaires.aff.affaire = $event;
    this.EmitFilters();
  }

  EmitFilterUtilisateur($event: Utilisateur) {
    this.filterAffaires.aff.utilisateur = $event;
    this.EmitFilters();
  }

  EmitFilterProspect($event: Prospect) {
    this.filterAffaires.aff.prospect = $event;
    this.EmitFilters();
  }

  EmitFilterContratActuel($event: ContratActuel) {
    this.filterAffaires.aff.contrat_actuel = $event;
    this.EmitFilters();
  }

  EmitFilterTelephonie($event: TelephonieOpp) {
    this.filterAffaires.opp.telephonie = $event;
    this.EmitFilters();
  }

  EmitFilterActionUtilisateurOpp($event: Action_user) {
    this.filterAffaires.opp.action_user = $event;
    this.EmitFilters();
  }

  EmitFilterActionUtilisateur($event: ActionUserAffaire) {
    this.filterAffaires.aff.action_user = $event;
    this.EmitFilters();
  }

  EmitFilterStatutActuel($event: StatutActuel) {
    this.filterAffaires.aff.statut_actuel = $event;
    this.EmitFilters();
  }

  EmitFilterOpportunityAffaire($event: Filtre_opp) {
    this.filterAffaires.opp.filtre_opp = $event;
    this.EmitFilters();
  }

  EmitFilterStatutActuelOpp($event: Rdv) {
    this.filterAffaires.opp.rdv = $event;
    this.EmitFilters();
  }

  EmitFilterQualite($event: Qualite) {
    this.filterAffaires.aff.qualite = $event;
    this.EmitFilters();
  }

  EmitFilterUtilisateurOpp($event: UtilisateurOpp) {
    this.filterAffaires.opp.utilisateur = $event;
    this.EmitFilters();
  }

  submitFilter() {
    this.onSubmitFilter.emit('submit');
    this.toggleFilterCorp();
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }
}
