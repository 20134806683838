import { ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-fiche-opportunite-prospect',
  templateUrl: './opportunite-prospect.component.html',
  styleUrls: ['./opportunite-prospect.component.css'],
})
export class OpportuniteProspectComponent implements OnInit, OnChanges {
  fa = fa;
  dataTable: any;
  listOpportunite: any[] = [];
  show_loading_add: boolean = false;
  @Input() prospect: string = '';
  constructor(private apiProspectService: ApiProspectsService, private chRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.getOpportunite(this.prospect);
  }

  getOpportunite(id) {
    this.show_loading_add = true;
    this.apiProspectService.getOpportuniteByProspec(id).subscribe(
      (data) => {
        this.listOpportunite = data;
        this.chRef.detectChanges();
        const table: any = $('#opp');
        this.dataTable = table.DataTable({
          stateSave: true,
          retrieve: true,
          language: {
            sProcessing: 'Traitement en cours...',
            searchPlaceholder: ' Recherche...',
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
            sInfo: "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            sInfoEmpty: "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: 'Chargement en cours...',
            sZeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
            sEmptyTable: 'Aucune donn&eacute;e disponible dans le tableau',
            oPaginate: {
              sFirst: 'Premier',
              sPrevious: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
              sLast: 'Dernier',
            },
            oAria: {
              sSortAscending: ': activer pour trier la colonne par ordre croissant',
              sSortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant',
            },
            select: {
              rows: {
                _: '%d lignes séléctionnées',
                0: 'Aucune ligne séléctionnée',
                1: '1 ligne séléctionnée',
              },
            },
          },
        });
        this.show_loading_add = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
