import { AddOpportuniteComponent } from './add-opportunite.component';
import { RouterModule, Routes } from '@angular/router';
import { ValidationComponent } from './Elements/validation/validation.component';
import { DetailsComponent } from './Elements/details/details.component';
import { CalendrierComponent } from './Elements/calendrier/calendrier.component';
import { CampagneComponent } from './Elements/campagne/campagne.component';
import { SharedModule } from '../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { addOpportuniteReducer } from './store/add-opportunite.reducers';
import { CommonModule } from '@angular/common';
import { ProspectFormModule } from './Elements/add_prospect/prospect-form.module';
import { EffectsModule } from '@ngrx/effects';
import { addOpportuniteEffects } from './store/add-opportunite.effects';

import {
  ADD_OPPORTUNITE_CONFIG_TOKEN,
  ADD_OPPORTUNITE_LOCAL_STORAGE_KEY,
  ADD_OPPORTUNITE_STORAGE_KEYS,
} from './store/addOpportunite.tokens';
import { LocalStorageService } from '../../../services/LocalStorageService.service';
import { storageMetaReducer } from 'src/app/factory/storageMetaReducer';

export function getStoreMetaReducerConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
) {
  return { metaReducers: [storageMetaReducer(saveKeys, localStorageKey, storageService)] };
}

const routes: Routes = [
  {
    path: '',
    component: AddOpportuniteComponent,
  },
];

// @ts-ignore
@NgModule({
  declarations: [
    AddOpportuniteComponent,
    ValidationComponent,
    DetailsComponent,
    CalendrierComponent,
    CampagneComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProspectFormModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('addOpportunite', addOpportuniteReducer, ADD_OPPORTUNITE_CONFIG_TOKEN),
    EffectsModule.forFeature([addOpportuniteEffects]),
  ],
  exports: [AddOpportuniteComponent, ValidationComponent, DetailsComponent, CalendrierComponent, CampagneComponent],
  providers: [

    { provide: ADD_OPPORTUNITE_LOCAL_STORAGE_KEY, useValue: '__add_opportunite_storage__' },
    {
      provide: ADD_OPPORTUNITE_STORAGE_KEYS,
      useValue: [
        'loadState',
        'listStatusCampagne',
        'listOfProcess',
        'infoSpecifiquesSchema',
        'infoComplementaireSchema',
        'errorMessage',
        'listLignesProduits',
        'typeProspect',
        'entreprise',
        'prospectParticulier',
        'prospectPro',
        'cycleVie',
        'campagne',
        'details',
        'calendar',
        'cycleVieComp',
        'cycleVieHorsComp',
      ],
    },
    {
      provide: ADD_OPPORTUNITE_CONFIG_TOKEN,
      deps: [ADD_OPPORTUNITE_STORAGE_KEYS, ADD_OPPORTUNITE_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getStoreMetaReducerConfig,
    },
  ],
})
export class AddOpportuniteModule {}
