<div class="row" style="margin-top: 15px">

    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allGroupe.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Groupes</mat-label>
                    <mat-chip-list #Groupe>
                        <mat-chip *ngFor="let groupe of ListGroupe" [selectable]="selectable" [removable]="removable"
                            (removed)="removeGroupe(groupe)" (removed)="EmitFilterCreateur()">
                            {{groupe}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterCreateur()" placeholder="Selectionner Groupe..." #groupeInput
                            [formControl]="groupeFromCtrl" [matAutocomplete]="autoGroupe" [matChipInputFor]="Groupe"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoGroupe="matAutocomplete" (optionSelected)="selectedGroupe($event)">
                        <mat-option *ngFor="let groupe of filteredGroupe | async" [value]="groupe.id">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allCreateur.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Liste des créateurs</mat-label>
                    <mat-chip-list #ListeCreateur>
                        <mat-chip *ngFor="let createur of ListCreateur" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCreateur(createur)"
                            (removed)="EmitFilterCreateur()">
                            {{createur}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterCreateur()" placeholder="Selectionner Liste des créateurs..."
                            #CreateurInput [formControl]="CreateurFromCtrl" [matAutocomplete]="autoCreateur"
                            [matChipInputFor]="ListeCreateur" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCreateur="matAutocomplete" (optionSelected)="selectedCreateur($event)">
                        <mat-option *ngFor="let createur of filteredCreateur | async" [value]="createur.id">
                            {{createur.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>



</div>