<div class="row">
    <div class="col">
        <div class="form-group">
            <mat-form-field class="full-width" appearance="standard" style="width: 100%;">
                <mat-label class="title"> Icôns</mat-label>
                <input type="text" matInput [(ngModel)]="searchText" #item (ngModelChange)="onChange(item.value)" #searchInput="ngModel" [required]="req">
                <mat-hint class="text-danger" *ngIf="filter(searchText).length == 0 && searchText !== ''">Pas d'icônes trouvées</mat-hint>
            </mat-form-field>
            <div *ngIf="  searchInput.invalid && (searchInput.dirty || searchInput.touched)" class="message-erreur">
                <div *ngIf="searchInput.errors.required">
                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                    Icôns est obligatoire!
                </div>
            </div>
            <div class="row list-icon scrolling" *ngIf="filter(searchText).length>0">
                <div class="col-2" style="margin: 5px;" *ngFor="let icon of filter(searchText)">
                    <button type="button" class="btn-icon-style" (click)="onSelectIcon(getIconObject(icon))">
            <fa-icon
                class="icon-style"
                [icon]="fa[getIconObject(icon)]"
                size="2x">
            </fa-icon>
          </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--
        <app-icon-picker (onChangeIcon)="onSelectIcon($event)"></app-icon-picker>
-->