import {Component, OnInit} from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.css']
})
export class FormFieldsComponent implements OnInit {
  fa=fa
  show_list: boolean = false;
  show_loading: boolean = false;

  showList() {
    this.show_list = !this.show_list
  }

  code_form_group: any = `
<div class="row">
  <div class="col-7">
    <div class="form-group-container">
      <p class="form-group-container-title">Contact</p>
      <div class="row">
        <!--Email-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Email</mat-label>
            <input matInput type="email">
          </mat-form-field>

        </div>
        <!--Téléphone-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Téléphone</mat-label>
            <input type="tel" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
        <!--2ème Téléphone-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>2ème Téléphone</mat-label>
            <input type="tel" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
        <!--Mobile-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Mobile</mat-label>
            <input type="mobile" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>
</div>
  `;
  cardbody: any = `
  <div class="card-body-details p-3">
      Content
    <p>
      Compagnie n° :
      <label class="label-card-info">
        1215183
      </label>
    </p>
    <div class="alert-erreur">
      <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
      Alert d'erreur
    </div>
  </div>`;


code_card_doc: any = `
<div class="row">
  <div class="col-md-4 col-sm-12 accordion" id="folder">
    <div class="card-folder border border-primary mb-4 ">
      <div class="card-header" id="headingFolder">
        <div class="row flex-nowrap" >
            <div class="col-1 mt-1 pl-0">
                <fa-icon  class="folder-icon"  [icon]="fa['faFolder']"></fa-icon>
            </div>
            <div class="col-9 text-left" data-toggle="collapse" data-target="#id_doc" aria-expanded="false" aria-controls="collapseTwo"  >
                <p class="text-dark ml-3 mt-2">
                 Nom du Document
                </p>
            </div>
            <div class="col-2 text-right mt-0 position-relative pl-0" >
                <button  type="button" mat-icon-button [matMenuTriggerFor]="menu_doc"  aria-label="Example icon-button with a menu">
                    <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                  </button>
                  <mat-menu #menu_doc="matMenu" >
                    <button mat-menu-item>
                      <input type="file" hidden  />
                      <label class="add-file">
                        <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                            Ajouter un ficher
                        </label>
                    </button>
                  </mat-menu>
            </div>
        </div>
      </div>
      <div id="id_doc"  class="collapse" aria-labelledby="headingFolder" data-parent="#folder">
        <div class="card-body">
              <div class="row flex-nowrap" >
                  <div class="col-1 pl-3">
                    <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                  </div>
                  <div class="col-11 pl-4">
                    <ng-container>
                      <a href="" target="_blank" class="file-name text-dark">
                        Nom du fichier
                      </a>
                    </ng-container>
                  </div>
              </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

boxShadowCard: any = `
<div class="shadow-box-card-body">
  Content
</div>
`;
code_list_tache_and_file: any = `
<div class="row">
  <div class="col-12">
    <div class="card-body-details p-3">
      <div class="row">
        <h2 class="title-details-blue ml-1">
          <fa-icon  [icon]="fa['faStream']"></fa-icon>
          List Tache && Joindre des fichiers
        </h2>
        <button type="button" class="add-btn-en btn-details-add">
          <i class="fa fa-plus-circle fa-2x" aria-hidden="true"></i>
        </button>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 accordion" id="accordionFolder">
          <div class="card-folder border-info">
            <div class="card-header header-bg-color" id="headingFolder">
              <div class="row align-items">
                  <div class="col-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.091" height="28.65" viewBox="0 0 28.091 28.65">
                      <path id="Union_33" data-name="Union 33" d="M1.246,28.65A1.249,1.249,0,0,1,0,27.4V4.111A1.3,1.3,0,0,1,1.246,2.8h4.92l.467-1.308A2.328,2.328,0,0,1,8.813,0h4.422a2.328,2.328,0,0,1,2.18,1.495l.5,1.308h4.64A1.3,1.3,0,0,1,21.8,4.111v6.4l3.879-3.869a1.409,1.409,0,0,1,1.993,1.993L21.8,14.492V27.4a1.249,1.249,0,0,1-1.246,1.246Zm1.246-2.491H19.308V16.978l-5.059,5.047a1.486,1.486,0,0,1-1.027.4,1.363,1.363,0,0,1-1-.4l-6.2-6.2a1.409,1.409,0,0,1,1.993-1.993l5.232,5.2L19.308,13v-7.7h-.5L17.315,8.252a.11.11,0,0,0-.031.063,3.282,3.282,0,0,1-2.9,1.651H7.63A3.342,3.342,0,0,1,4.8,8.439c0-.031-.031,0-.031-.031L3.021,5.294H2.491ZM6.913,7.1a.833.833,0,0,0,.716.374h6.789a.876.876,0,0,0,.747-.4l.9-1.775h-1a1.219,1.219,0,0,1-1.183-.779l-.747-2.024H8.969L8.221,4.515a1.264,1.264,0,0,1-1.183.779H5.886Z"/>
                    </svg>
                  </div>
                  <div class="col-10 folder-name-col"  data-toggle="collapse"  data-target="#hello"  aria-expanded="false" aria-controls="collapseTwo"  >
                      <p class="folder-name">
                        Nom du document
                      </p>
                  </div>
                  <div class="col-1 folder-settings" >
                      <button type="button" mat-icon-button [matMenuTriggerFor]="menu"  aria-label="Example icon-button with a menu">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            </svg>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item>
                            <label class="add-file">
                              Ajouter un ficher
                          </label>
                          </button>
                        </mat-menu>
                  </div>
              </div>
            </div>
            <div id="hello" class="collapse" aria-labelledby="headingFolder" data-parent="#accordionFolder">
              <div class="card-body " >
                    <div class="row" >
                        <div class="col-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16">
                              <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
                              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                            </svg>
                        </div>
                        <div class="col-10 file-name-col" style="padding-left: 0px;">
                            <a class="file-name" href="#" target="_blank">
                              Nom du fichier
                            </a>
                        </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>
`;
code_card_file: any = `
<div class="row">
  <div class="col-md-4 col-sm-12">
    <div class="card-folder border border-primary mb-4 ">
      <div class="card-header">
        <div class="row flex-nowrap" >
            <div class="col-1 mt-2 pl-0">
                <fa-icon  class="file-icon"  [icon]="fa['faFile']"></fa-icon>
            </div>
            <div class="col-9 text-left">
                <p class="text-dark ml-3 mt-2">
                 Nom du Fichier
                </p>
            </div>
            <div class="col-2 text-right mt-2 position-relative pl-0" >
                <fa-icon  class="file-icon"  [icon]="fa['faTrash']"></fa-icon>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

code_porte_document: any = `
<div class="card-doc bg-white p-3 border-0">
  <div class="card-doc-title ml-3 row" >
    <fa-icon class="mr-3 mx-1 fa-lg" [icon]="fa['faFileImport']"></fa-icon>
    <label class="title-card-details">Porte Documents</label>
  </div>
  <button type="button"  class="btn-document-add btn-position-add-document" data-title="Ajouter un dossier">
    <fa-icon class="fa fa-lg" [icon]="fa['faPlus']"></fa-icon>
  </button>
  <div class="row">
      <div class="col-md-12 p-4 div-list-exist scrollbar">
          <div class="row">
                  <div class="col-xl-4 col-lg-12 accordion" id="accordionFolder">
                      <div class="card-folder border border-primary bg-white mb-4 ">
                        <div class="card-header" id="headingFolder">
                          <div class="row flex-nowrap" >
                              <div class="col-1 mt-3 pl-0">
                                  <fa-icon  class="folder-icon"  [icon]="fa['faFolder']"></fa-icon>
                              </div>
                              <div class="col-9 text-left"  data-toggle="collapse"   data-target="#id_doc" aria-expanded="false" aria-controls="collapseTwo"  >
                                  <p class="text-dark ml-3 mt-2">
                                  Nom du Document
                                  </p>
                                  <p class="ml-3 text-muted" >
                                      5 fichier(s)
                                  </p>
                              </div>
                              <div class="col-2 text-right mt-3 position-relative pl-0" >
                                  <button mat-icon-button [matMenuTriggerFor]="menu"  aria-label="Example icon-button with a menu">
                                      <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                      <button mat-menu-item>
                                        <input id="" type="file" hidden />
                                        <label for="" class="add-file">
                                          <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                                            Ajouter un ficher
                                        </label>
                                      </button>
                                    </mat-menu>
                              </div>
                          </div>
                        </div>
                        <div id="id_doc" class="collapse" aria-labelledby="headingFolder" data-parent="#accordionFolder">
                          <div class="card-body" >
                                <div class="row flex-nowrap" >
                                    <div class="col-1 pl-3">
                                      <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                                    </div>
                                    <div class="col-11 pl-4">
                                      <a href="" target="_blank" class="file-name text-dark">
                                          <p  data-title="" >
                                            Nom Fichier
                                          </p>
                                        </a>
                                    </div>
                                </div>
                          </div>
                        </div>
                      </div>
                  </div>
          </div>
      </div>
</div>
</div>

<div class="card-bloc bg-white p-3">
  <div class="card-doc-title ml-3 row" >
    <h2>Documents relatifs à l'affaire</h2>
</div>
<div class="row">
  <div class="col-md-6 div-list-no-exist p-3 mb-2">
      <div class="row">
          <label class="label-title mb-2 ml-2 text-secondary">Documents à Ajouter</label>
      </div>
  </div>
  <div class="col-md-6 div-list-exist p-3">
    <div class="row">
        <label class="label-title mb-2 text-primary">Documents Rajoutés</label>
    </div>
</div>
</div>
</div>
`;
  constructor() {
  }

  ngOnInit(): void {
  }

}
