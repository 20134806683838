import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';
import { Courrier, Createur, Destinataire, FilterMaileva, Expediteur, Statut } from '../../../../../entity/Maileva/FilterMaileva';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter-maileva',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterMailvaComponent implements OnInit {

  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;
  fa=fa ;
  collapseFilterBox: boolean = true;

  filtre_maileva: FilterMaileva = new FilterMaileva();

  @Output() onPushFilters: EventEmitter<FilterMaileva> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();

  onResetCourrier: boolean = false;
  onResetDestinataire: boolean = false;
  onResetExpediteur: boolean = false;
  onResetCreateur: boolean = false;
  onResetStatut: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  resetForm() {
    this.onResetCourrier = true;
    this.onResetDestinataire = true;
    this.onResetExpediteur = true;
    this.onResetCreateur = true;
    this.onResetStatut = true;

  }
  getResetCourrier($event) {
    this.onResetCourrier = $event;
  }
  getResetCreateur($event) {
    this.onResetCreateur = $event;
  }
  getResetDestinataire($event) {
    this.onResetDestinataire = $event;
  }
  getResetExpediteur($event) {
    this.onResetExpediteur = $event;
  }
  getResetStatut($event) {
    this.onResetStatut = $event;
  }

  EmitFilterDestinataire($event: Destinataire) {

    this.filtre_maileva.destinataire = $event;
    this.EmitFilters();
  }
  EmitFilterExpediteur($event: Expediteur) {


    this.filtre_maileva.expediteur = $event;
    this.EmitFilters();
  }
  EmitFilterCreateur($event: Createur) {

    this.filtre_maileva.createur = $event;
    this.EmitFilters();
  }
  EmitFilterCourrier($event: Courrier) {

    this.filtre_maileva.courrier = $event;
    this.EmitFilters();
  }
  EmitFilterStatus($event: Statut) {
    this.filtre_maileva.statut = $event;
    this.EmitFilters();
  }


  submitFilter() {
    this.onSubmitFilter.emit("");
    this.toggleFilterCorp();
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filtre_maileva);

  }
  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit("")
      this.toggleFilterCorp()
    }
  }


}
