<mat-sidenav-container>
    <mat-sidenav-content style="padding: 25px">
        <div class="row">
            <div class="col-12 main-page">
                <shared-formProduit-builder (dataformbuilder)="onDataFormBuilder($event)" [formBuilderData]="formBuilderData" [parentObject]="parentObject"></shared-formProduit-builder>
                <div class="footer-marge"></div>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
