import {FormControl} from '@angular/forms';

export class FormContract{
  cie_text :string;
  cie_text_id:string;
  nom_produit:string;
  pan_ttc:number;
  pm_ttc:number;
  denomination:string;
  nombre_assure:string;
  date_echeance_principale:string;
  echeance_resiliation:string;
  siren:string;
  id_opp:string ; 
  id:string ; 
  constructor(init?: Partial<FormControl>) {
    Object.assign(this, init);
  }
}
