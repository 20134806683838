import {FormContract} from './FormContract';
import {FormControl} from '@angular/forms';

export class Details{
  model_info_specifiques: any;
  model_info_complementaires: any;
  etatActuel: string='N/R';
  commentaire: string=null;
  formContrat: FormContract=new FormContract();
  tags: any[]=[]

  constructor(init?: Partial<FormControl>) {
    Object.assign(this, init);
  }
}
