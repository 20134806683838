<div class="row" [ngClass]="{'cover': !allowed_to_update}">
    <div class="col-12">
        <ng-container *ngIf="fields.length>0 && !loading">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-12">
                        <formly-form (modelchange)="onModelChange($event)" [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="fields.length<1 && !loading">
            <p>pas d'informations</p>
        </ng-container>
    </div>
</div>
<ng-container *ngIf="loading">
    <mat-spinner [diameter]="40"></mat-spinner>
</ng-container>
