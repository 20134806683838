import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as fa from '@fortawesome/free-solid-svg-icons';

import { FilterEcheances } from 'src/app/entity/FilterEcheances';
@Component({
  selector: 'app-filter-echeances',
  templateUrl: './filter-echeances.component.html',
  styleUrls: ['./filter-echeances.component.css']
})
export class FilterEcheancesComponent implements OnInit , OnChanges {
  collapseFilterBox: boolean = true;
  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;
  fa = fa;
  @Input () resetAllFilter : boolean;
  @Output() onPushFilters: EventEmitter<FilterEcheances> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();
  @Output() returnToparent = new EventEmitter<boolean>();

  Allfilter : FilterEcheances = new FilterEcheances();
  resetAffaire :boolean = false;
  resetEcheance :boolean = false;
  resetEcheancier :boolean = false;

  constructor() { }
 

  ngOnInit(): void {
  }
  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
  }
  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
    //  this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }

  EmitFilterAffaire(event){
  this.Allfilter.organisme = event.organisme;
  this.Allfilter.operateur_organisme = event.operateur_organisme;
  this.Allfilter.ligne_de_produit = event.ligne_de_produit;
  this.Allfilter.operateur_ligne_de_produit = event.operateur_ligne_de_produit;
  this.Allfilter.gamme = event.gamme;
  this.Allfilter.operateur_gamme = event.operateur_gamme;
  this.EmitFilters();
  }


  EmitFilterEcheance(event){
    this.Allfilter.date_prelevement = event.date_prelevement;
    this.Allfilter.status_echeances = event.status_echeances;
    this.Allfilter.operateur_status_echeances = event.operateur_status_echeances;
    this.EmitFilters();
  }

  EmitFilterEcheancier(event){
    this.Allfilter.date_debut_periode = event.date_debut_periode;
    this.Allfilter.date_fin_periode = event.date_fin_periode;
    this.Allfilter.motif = event.motif;
    this.Allfilter.operateur_motif = event.operateur_motif;

    this.EmitFilters();
  }

  EmitFilters() {
    this.onPushFilters.emit(this.Allfilter);
  }

  submitFilter() {
    this.onSubmitFilter.emit(this.Allfilter);
    this.toggleFilterCorp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.resetAllFilter.currentValue == true){
      this.resetForm()
    }
  }
  resetForm() {
   this.resetAffaire = true;
   this.resetEcheance = true;
   this.resetEcheancier = true;
   this.returnToparent.emit(false);

  }
  getResetEcheancier(event){
    this.resetEcheancier = event;
  }

  getResetEcheance(event){
    this.resetEcheance = event;

  }

  getResetAffaire(event){
    this.resetAffaire = event;

  }
}
