<div class="row" style="margin-top: 15px">

  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <input (click)="EmitFilterProspec()" (keyup.enter)="EmitFilterProspec()" [(ngModel)]="prospect.nom_prenom"
          matInput placeholder="Nom & Prénom" type="text">
      </mat-form-field>

    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <!-- pattern="[0-9]{9}" -->
        <input (click)="EmitFilterProspec()" (keyup.enter)="EmitFilterProspec()"  name="phone"
          title="Three letter country code" [(ngModel)]="prospect.telephone" matInput placeholder="Téléphone"
          type="number" #phone="ngModel">
      </mat-form-field>
      <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="message-erreur">
        <div *ngIf="phone.errors.pattern">
          <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Téléphone doit contenir 9 chiffres
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <input (click)="EmitFilterProspec()" (keyup.enter)="EmitFilterProspec()"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [(ngModel)]="prospect.adresse_mail" name="mail"
          #mail="ngModel" matInput placeholder="Email" type="text">
      </mat-form-field>
      <div *ngIf="mail.invalid && (mail.dirty || mail.touched)" class="message-erreur">

        <div *ngIf="mail.errors.pattern">
          <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Format invalide !
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <input (click)="EmitFilterProspec()" (keyup.enter)="EmitFilterProspec()" [(ngModel)]="prospect.ville_CP"
          matInput placeholder="Ville/CP" type="text">
      </mat-form-field>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="form-group">
      <ng-container *ngIf="allTagsPros.length > 0">
        <mat-form-field style="width: 100%;">
          <mat-label>Mots-clés</mat-label>
          <mat-chip-list #tagsPros>
            <mat-chip *ngFor="let tags of ListTagsPros" [selectable]="selectable" [removable]="removable"
              (removed)="removeTagsProspect(tags)" (removed)="EmitFilterProspec()">
              {{tags}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterProspec()" placeholder="Selectionner..." #TagsProsInput
              [formControl]="tagsProsFromCtrl" [matAutocomplete]="autoTagsPros" [matChipInputFor]="tagsPros"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoTagsPros="matAutocomplete" (optionSelected)="selectedTagsProspect($event)">
            <mat-option *ngFor="let tags of filteredTagsPros | async" [value]="tags.id">
              {{tags.libelle}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <ng-container *ngIf="allZones.length > 0">
        <mat-form-field style="width: 100%;">
          <mat-label>Zone</mat-label>
          <mat-chip-list #Zone>
            <mat-chip *ngFor="let zone of ListZones" [selectable]="selectable" [removable]="removable"
              (removed)="removeZones(zone)" (removed)="EmitFilterProspec()">
              {{zone}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterProspec()" placeholder="Selectionner Zone..." #ZonesInput
              [formControl]="zoneFromCtrl" [matAutocomplete]="autoZones" [matChipInputFor]="Zone"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoZones="matAutocomplete" (optionSelected)="selectedZones($event)">
            <mat-option *ngFor="let zone of filteredZones | async" [value]="zone.id">
              {{zone.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
<!-- <div class="row" style="margin-top: 10px;display: none;">
  <div class="col-6">

    <label>{{'opportunities.label.suspectingduplicates' | translate}}</label><br>

    <div class="switch-toggle switch-ios">
      <input (click)="EmitFilterProspec()" [(ngModel)]="prospect.suspection" (change)=" onItemChange()" id="On"
        value="on" name="view" type="radio" checked>
      <label for="On" onclick="">ON</label>

      <input (click)="EmitFilterProspec()" [(ngModel)]="prospect.suspection" (change)=" onItemChange()" value="na"
        id="N/A" name="view" type="radio">
      <label for="N/A" onclick="">N/A</label>

      <input (click)="EmitFilterProspec()" [(ngModel)]="prospect.suspection" (change)=" onItemChange()" value="off"
        id="Off" name="view" type="radio">
      <label for="Off" onclick="">OFF</label>

      <a></a>
    </div>

  </div>

  <div class="col-6">

    <label>{{'opportunities.label.vip' | translate}}</label><br>

    <div class="switch-toggle switch-ios">
      <input (click)="EmitFilterProspec()" id="VIPon" name="VIP" [(ngModel)]="prospect.vip" (change)=" onItemChange()"
        value="on" type="radio" checked>
      <label for="VIPon" onclick="">ON</label>

      <input (click)="EmitFilterProspec()" id="VIPof" name="VIP" [(ngModel)]="prospect.vip" (change)=" onItemChange()"
        value="off" type="radio">
      <label for="VIPof" onclick="">OFF</label>

      <a></a>
    </div>
  </div>

</div> -->
<!-- <div class="row" style="margin-top: 15px;display: none;">
  <div class="col-4 p-2">
    <div class="form-group">
      <label>Email du prospect</label>
      <div class="switch-toggle switch-ios">
        <input (click)="EmitFilterProspec()" id="MailOn" name="Mail" [(ngModel)]="prospect.mail_prospect"
          (change)=" onItemChange()" value="on" type="radio" checked>
        <label for="MailOn" onclick="">Activer</label>

        <input (click)="EmitFilterProspec()" id="MailN/A" name="Mail" [(ngModel)]="prospect.mail_prospect"
          (change)=" onItemChange()" value="na" type="radio">
        <label for="MailN/A" onclick="">N/A</label>

        <input (click)="EmitFilterProspec()" id="MailOff" name="Mail" [(ngModel)]="prospect.mail_prospect"
          (change)=" onItemChange()" value="off" type="radio">
        <label for="MailOff" onclick="">Désactiver</label>

        <a></a>
      </div>
    </div>
  </div>

  <div class="col-4 p-2">
    <div class="form-group">
      <label>{{'opportunities.label.prospectphone' | translate}}</label>
      <div class="switch-toggle switch-ios">
        <input (click)="EmitFilterProspec()" id="TelOn" name="Tel" type="radio" [(ngModel)]="prospect.tel_prospect"
          (change)=" onItemChange()" value="on" checked>
        <label for="TelOn" onclick="">Activer</label>

        <input (click)="EmitFilterProspec()" id="TelN/A" name="Tel" [(ngModel)]="prospect.tel_prospect" value="na"
          (change)=" onItemChange()" type="radio">
        <label for="TelN/A" onclick="">N/A</label>

        <input (click)="EmitFilterProspec()" id="TelOff" name="Tel" [(ngModel)]="prospect.tel_prospect" value="off"
          (change)=" onItemChange()" type="radio">
        <label for="TelOff" onclick="">Désactiver</label>

        <a></a>
      </div>
    </div>
  </div>
  <div class="col-4 p-2">
    <div class="form-group">
      <label>{{'opportunities.label.prospectmobile' | translate}}</label>
      <div class="switch-toggle switch-ios">
        <input (click)="EmitFilterProspec()" id="MobileOn" name="Mobile" type="radio"
          [(ngModel)]="prospect.mobile_prospect" (change)=" onItemChange()" value="on" checked>
        <label for="MobileOn" onclick="">Activer</label>

        <input (click)="EmitFilterProspec()" id="MobileN/A" name="Mobile" [(ngModel)]="prospect.mobile_prospect"
          value="na" (change)=" onItemChange()" type="radio">
        <label for="MobileN/A" onclick="">N/A</label>

        <input (click)="EmitFilterProspec()" id="MobileOff" name="Mobile" [(ngModel)]="prospect.mobile_prospect"
          value="off" (change)=" onItemChange()" type="radio">
        <label for="MobileOff" onclick="">Désactiver</label>

        <a></a>
      </div>
    </div>
  </div>
</div> -->