export class DocumentsOpportunite {
  id: string = '';
  id_opp: string = '';
  name: string = '';
  md5_id: string = '';
  files: any[] = [];
  existe: any;
}
export class Files {
  name: string = '';
  size: string = '';
  url: any[] = [];
}
