import { Component, OnInit } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

class Communication {
}


@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {


  communicationList:Communication[]=[
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {}
  ]
  constructor(private sharedMenuObserverService:SharedMenuObserverService
    ) { }

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu("Communiqués")
  }

  onClickPhone(){
    console.log('Phone selected');

  }
  onClickStar(){
    console.log('Star selected');
  }
  onClickVue(){
    console.log('Vue selected');

  }
  onClickCopy(){
    console.log('Copy selected');
  }
  onClickPdf(){
    console.log('PDF selected');
  }
  onClickShare(){
    
    console.log('Share selected');
  }
  onClickStatus(){
    console.log('C selected');
  }

  order() {
    alert("Ordered")
  }

}
