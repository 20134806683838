<div class="row" style="margin-top: 15px">
  <div class="col-6">
    <div class="form-group">
      <ng-container *ngIf="allCreateur.length > 0">
        <mat-form-field style="width: 100%;">
          <mat-label>Créer par</mat-label>
          <mat-chip-list #create>
            <mat-chip *ngFor="let user of ListCreateur" [selectable]="selectable" [removable]="removable"
              (removed)="removeCreateur(user)" (removed)="EmitFilterUtilisateur()">
              {{user}}
              <i class="fa fa-times" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input [formControl]="CrearteurFromCtrl" (click)="EmitFilterUtilisateur()"
              placeholder="Selectionner Type createur..." #CreateurInput [matAutocomplete]="autoCreateur"
              [matChipInputFor]="create" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoCreateur="matAutocomplete" (optionSelected)="selectedCreateur($event)">
            <mat-option *ngFor="let user of filteredCreateur | async" [value]="user.id">
              {{user.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <ng-container *ngIf="allCreateur.length > 0">
        <mat-form-field style="width: 100%;">
          <mat-label>Affecté à</mat-label>
          <mat-chip-list #affecte>
            <mat-chip *ngFor="let user of ListAffecte" [selectable]="selectable" [removable]="removable"
              (removed)="removeAffecte(user)" (removed)="EmitFilterUtilisateur()">
              {{user}}
              <i class="fa fa-times" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input [formControl]="AffecteFromCtrl" (click)="EmitFilterUtilisateur()" placeholder="affecté à "
              #AffecteInput [matAutocomplete]="autoAffecte" [matChipInputFor]="affecte"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoAffecte="matAutocomplete" (optionSelected)="selectedAffecte($event)">
            <mat-option *ngFor="let user of filteredCreateur | async" [value]="user.id">
              {{user.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>