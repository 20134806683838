import {FormField} from './FormField';
import {CustomFormlyConfig, CustomWidget} from './SharedElementsFormly';

export class TextAreaFormly extends FormField {

  constructor() {
    super();
    super.icon = "fa fa-align-left";
    super.name = 'Zone de texte_' + super.generateRandomId();
    super.type = 'string';
    super.title = "Zone de texte"
    super.typename = 'Text Area';
    this.widget.formlyConfig = new CustomFormlyConfig();
    this.widget.formlyConfig.type = 'textarea';
    this.widget.formlyConfig.templateOptions.placeholder = '';
    this.widget.formlyConfig.templateOptions.rows = 5;
    this.widget.formlyConfig.templateOptions.col = "6";
  }

  setPlaceHolder(placeholder: string) {
    this.widget.formlyConfig.templateOptions.placeholder = placeholder;
  }

  getPlaceHolder() {
    return this.widget.formlyConfig.templateOptions.placeholder;
  }

  setRows(rows: number) {
    this.widget.formlyConfig.templateOptions.rows = rows;
  }

  getRows() {
    return this.widget.formlyConfig.templateOptions.rows;
  }

}
