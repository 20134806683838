import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import {
  ActionUser,
  FilterOpportunite,
  FiltreProspects,
  InformationsAncienContrat,
  RendezVous,
  Telephonie,
  Opportunite,
  Utilisateur,
} from '../../../../../entity/Opportunity/FilterOpportunite';
import * as fa from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  fa = fa;
  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;
  faBullhorn = faBullhorn;

  filterOpportunite: FilterOpportunite = new FilterOpportunite();
  panelOpenState = false;
  collapseFilterBox: boolean = true;

  @Output() onPushFilters: EventEmitter<FilterOpportunite> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();
  @Input() onRefreshFilter: any;
  onResetOpp: boolean = false;
  onResetPros: boolean = false;
  onResetUtil: boolean = false;
  onResetAncienContrat: boolean = false;
  onResetTelephonie: boolean = false;
  onResetActionsUtil: boolean = false;
  onResetStatut: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.onRefreshFilter != null) {
      this.filterOpportunite = JSON.parse(this.onRefreshFilter);
    }
  }

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  resetForm() {
    this.onResetOpp = true;
    this.onResetPros = true;
    this.onResetUtil = true;
    this.onResetAncienContrat = true;
    this.onResetTelephonie = true;
    this.onResetActionsUtil = true;
    this.onResetStatut = true;
  }

  getResetOpp($event) {
    this.onResetOpp = $event;
  }

  getResetPros($event) {
    this.onResetPros = $event;
  }

  getResetUtil($event) {
    this.onResetUtil = $event;
  }

  getResetAncienContrat($event) {
    this.onResetAncienContrat = $event;
  }

  getResetTelephonie($event) {
    this.onResetTelephonie = $event;
  }

  getResetActionsUtil($event) {
    this.onResetActionsUtil = $event;
  }

  getResetStatut($event) {
    this.onResetStatut = $event;
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filterOpportunite);
  }

  EmitFilterOpportunite($event: Opportunite) {
    this.filterOpportunite.filtre_opp = $event;
    this.EmitFilters();
  }

  EmitFilterUtilisateur($event: Utilisateur) {
    this.filterOpportunite.utilisateur = $event;
    this.EmitFilters();
  }

  EmitFilterProspec($event: FiltreProspects) {
    this.filterOpportunite.prospect = $event;
    this.EmitFilters();
  }

  EmitFilterAncienContart($event: InformationsAncienContrat) {
    this.filterOpportunite.info_contrat = $event;
    this.EmitFilters();
  }

  EmitFilterTelephonie($event: Telephonie) {
    this.filterOpportunite.telephonie = $event;
    this.EmitFilters();
  }

  EmitFilterActionsUtilisateurs($event: ActionUser) {
    this.filterOpportunite.action_user = $event;
    this.EmitFilters();
  }

  EmitFilterStatusActuel($event: RendezVous) {
 
    this.filterOpportunite.rdv = $event;
    this.EmitFilters();
  }

  submitFilter() {
    this.onSubmitFilter.emit('');
    this.toggleFilterCorp();
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }
}
