import { Component } from '@angular/core';
import {FieldType} from "@ngx-formly/core";

@Component({
  selector: 'app-null-type',
  templateUrl: './null-type.component.html',
  styleUrls: ['./null-type.component.css']
})
export class NullTypeComponent extends FieldType {}


