import {Inject, Injectable, Optional} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from "../variables";
import {ModelObject} from "../model/modelObject";
import {Observable} from "rxjs";
import {CustomHttpUrlEncodingCodec} from "../encoder";
import {IssueGitlab} from "../../entity/IssueGitlab";

@Injectable({
  providedIn: 'root'
})
export class ApiGitLabService {
  protected basePath = environment.api_url;
  protected gitlabPath = 'https://git.interface-crm.com/api/v4/projects/27';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public pushIssue(issue:IssueGitlab, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (issue === null || issue === undefined) {
      throw new Error('Required parameter IssueGitlab was null or undefined when calling rechercheLexiqueGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (issue.title !== undefined && issue.title !== null) {
      queryParameters = queryParameters.set('title', <any>issue.nom+': '+issue.title);
    }
    if (issue.labels !== undefined && issue.labels !== null) {
      queryParameters = queryParameters.set('labels', <any>issue.labels);
    }

    if (issue.description !== undefined && issue.description !== null) {
      queryParameters = queryParameters.set('description', <any>issue.description);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["PRIVATE-TOKEN"]) {
      headers = headers.set('PRIVATE-TOKEN', this.configuration.apiKeys["PRIVATE-TOKEN"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('post',`${this.gitlabPath}/issues`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
