<div class="row">
    <div class="col-6">
        <ng-container *ngIf="allCompagnie.length > 0">
            <mat-form-field style="width: 100%;margin-top: -4px;">
                <mat-label>Compagnie</mat-label>
                <mat-chip-list #Compagnie>
                    <mat-chip *ngFor="let compagnie of ListCompagnies" [selectable]="selectable" [removable]="removable"
                        (removed)="removeCompagnie(compagnie)" (removed)=" EmitFilterAffaire()">
                        {{compagnie}}
                        <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                        </fa-icon>
                    </mat-chip>
                    <input (click)=" EmitFilterAffaire()" placeholder="Selectionner Compagnie..." #compagnieInput
                        [formControl]="compagnieFromCtrl" [matAutocomplete]="autoCompagnies"
                        [matChipInputFor]="Compagnie" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoCompagnies="matAutocomplete" (optionSelected)="selectedCompagnie($event)">
                    <mat-option *ngFor="let compagnie of filteredCompagnie | async" [value]="compagnie.siren">
                        {{compagnie.denomination}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
    <div class="col-6">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -16px;">
            <mat-select (selectionChange)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.operateur_gamme"
                placeholder="Opérateur">
                <mat-option value="=">
                    est égale
                </mat-option>
                <mat-option value="!=">
                    Différent
                </mat-option>

            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <ng-container *ngIf="allGammes.length > 0">
            <mat-form-field style="width: 100%;margin-top: -4px;">
                <mat-label>Gammes</mat-label>
                <mat-chip-list #Gammes>
                    <mat-chip *ngFor="let gammes of ListGammes" [selectable]="selectable" [removable]="removable"
                        (removed)="removeGammes(gammes)" (removed)=" EmitFilterAffaire()">
                        {{gammes}}
                        <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                        </fa-icon>
                    </mat-chip>
                    <input (click)=" EmitFilterAffaire()" placeholder="Selectionner Gammes..." #gammeInput
                        [formControl]="gammeFromCtrl" [matAutocomplete]="autoGamme" [matChipInputFor]="Gammes"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoGamme="matAutocomplete" (optionSelected)="selectedGammes($event)">
                    <mat-option *ngFor="let gammes of filteredGammes | async" [value]="gammes.id">
                        {{gammes.nom}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
    <div class="col-6">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -16px;">
            <mat-select (selectionChange)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.operateur_produit"
                placeholder="Opérateur">
                <mat-option value="=">
                    est égale
                </mat-option>
                <mat-option value="!=">
                    Différent
                </mat-option>

            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <ng-container *ngIf="allProductBackup.length > 0">
            <mat-form-field style="width: 100%;">
                <mat-label>Produit</mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let product of ListProducts" [selectable]="selectable" [removable]="removable"
                        (removed)="removeProducts(product)" (removed)=" EmitFilterAffaire()">
                        {{product}}
                        <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
                    </mat-chip>
                    <input (click)=" EmitFilterAffaire()" placeholder="Selectionner produit..." #ProductsInput
                        [formControl]="ProductsFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedProducts($event)">
                    <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                        {{product.libelle}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
    <div class="col-6">
        <mat-form-field style="width: 100%;">
            <input matInput [(ngModel)]="filterAffaire.recheche_num_contrat" (click)=" EmitFilterAffaire()"
                placeholder="Numéro de Contrat"   name="num"
                #num="ngModel">
        </mat-form-field>
        <div *ngIf="num.invalid && (num.dirty || num.touched)" class="message-erreur">
            <div *ngIf="num.errors.pattern">
                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Seulement des chiffres !
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <mat-form-field style="width: 100%;">
            <input (click)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.pan_ttc_min" matInput
                placeholder="PAN TTC MIN" type="number" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="min" #min="ngModel">
        </mat-form-field>
        <div *ngIf="min.invalid && (min.dirty || min.touched)" class="message-erreur">
            <div *ngIf="min.errors.pattern">
                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Seulement des chiffres !
            </div>
        </div>
    </div>
    <div class="col-6">
        <mat-form-field style="width: 100%;">
            <input (click)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.pan_ttc_max" matInput
                placeholder="PAN TTC MAX" type="text" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="max" #max="ngModel">
        </mat-form-field>
        <div *ngIf="max.invalid && (max.dirty || max.touched)" class="message-erreur">
            <div *ngIf="max.errors.pattern">
                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Seulement des chiffres !
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="creation">
                    <input [(ngModel)]="pose_du_creation" matStartDate placeholder="Date de Création du" />
                    <input [(ngModel)]="pose_au_creation" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="creation"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)=" EmitFilterAffaire()" #creation></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="Souscription">
                    <input [(ngModel)]="pose_du_souscription" matStartDate placeholder="Date souscription du" />
                    <input [(ngModel)]="pose_au_souscription" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="Souscription"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)=" EmitFilterAffaire()" #Souscription></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="Effet">
                    <input [(ngModel)]="date_effet_dossier_du" matStartDate placeholder="Date d'effet du" />
                    <input [(ngModel)]="date_effet_dossier_au" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="Effet"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)=" EmitFilterAffaire()" #Effet></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <mat-form-field appearance="fill" style="width: 100%;">
            <mat-select (selectionChange)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.operateur_contrat"
                placeholder="Opérateur">
                <mat-option value="=">
                    est égale
                </mat-option>
                <mat-option value="!=">
                    Différent
                </mat-option>

            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <ng-container *ngIf="allProducts?.length > 0">
            <mat-form-field style="width: 100%;">
                <mat-label>Ligne de produit</mat-label>
                <mat-chip-list #Contrat>
                    <mat-chip *ngFor="let contrat of ListContrat" [selectable]="selectable" [removable]="removable"
                        (removed)="removeContrat(contrat)" (removed)=" EmitFilterAffaire()">
                        {{contrat}}
                        <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
                    </mat-chip>
                    <input (click)=" EmitFilterAffaire()" placeholder="Selectionner Contrat..." #contratInput
                        [formControl]="contratFromCtrl" [matAutocomplete]="autoContrat" [matChipInputFor]="Contrat"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoContrat="matAutocomplete" (optionSelected)="selectedContrat($event)">
                    <mat-option *ngFor="let contrat of filteredContrat | async" [value]="contrat.id">
                        {{contrat.type}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
    <div class="col-6">
        <ng-container *ngIf="allProducts?.length > 0">
            <mat-form-field style="width: 100%;">
                <mat-label>Classe</mat-label>
                <mat-chip-list #Classe>
                    <mat-chip *ngFor="let Classe of ListClasse" [selectable]="selectable" [removable]="removable"
                        (removed)="removeClasse(Classe)" (removed)=" EmitFilterAffaire()">
                        {{Classe}}
                        <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
                    </mat-chip>
                    <input (click)=" EmitFilterAffaire()" placeholder="Selectionner Classe..." #classeInput
                        [formControl]="classeFromCtrl" [matAutocomplete]="autoClasse" [matChipInputFor]="Classe"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoClasse="matAutocomplete" (optionSelected)="selectedClasse($event)">
                    <mat-option *ngFor="let Classe of filteredClasse | async" [value]="Classe.id">
                        {{Classe.libele}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allTags.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'opportunities.label.tagscontain' | translate}}</mat-label>
                    <mat-chip-list #Tags>
                        <mat-chip *ngFor="let Tags of ListTags" [selectable]="selectable" [removable]="removable"
                            (removed)="removeTags(Tags)" (removed)=" EmitFilterAffaire()">
                            {{Tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterAffaire()" placeholder="Selectionner Tags..." #tagsInput
                            [formControl]="tagsFromCtrl" [matAutocomplete]="autoTags" [matChipInputFor]="Tags"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTags="matAutocomplete" (optionSelected)="selectedTags($event)">
                        <mat-option *ngFor="let Tags of filteredTags | async" [value]="Tags.id">
                            {{Tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <label></label>
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
                <mat-select (selectionChange)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.operateur_recherche_tag"
                    placeholder="Opérateur">
                    <mat-option value="OU">
                        Ou
                    </mat-option>
                    <mat-option value="ET">
                        Et
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allTags.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'opportunities.label.tagsnotcontain' | translate}}</mat-label>
                    <mat-chip-list #TagsNeContientPas>
                        <mat-chip *ngFor="let Tags of ListTagsNeContientPas" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTagsNeContientPas(Tags)"
                            (removed)=" EmitFilterAffaire()">
                            {{Tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterAffaire()" placeholder="Selectionner Tags..." #tagsNeContientPasInput
                            [formControl]="tagsNeContientPasFromCtrl" [matAutocomplete]="autoTagsNeContientPas"
                            [matChipInputFor]="TagsNeContientPas" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTagsNeContientPas="matAutocomplete"
                        (optionSelected)="selectedTagsNeContientPas($event)">
                        <mat-option *ngFor="let Tags of filteredTagsNeContientPas | async" [value]="Tags.id">
                            {{Tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="envoi">
                    <input [(ngModel)]="pose_du_date_envoi_res" matStartDate placeholder="Date d'envoi résiliat.. " />
                    <input [(ngModel)]="pose_au_date_envoi_res" matEndDate placeholder="du..à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="envoi"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)=" EmitFilterAffaire()" #envoi></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <!-- <div class="col-6">
        <div class="form-group">
            <label></label>
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
                <mat-select (selectionChange)=" EmitFilterAffaire()" [(ngModel)]="filterAffaire.res_env"
                    placeholder="Résiliation envoyée">
                    <mat-option value="Oui">
                        Oui
                    </mat-option>
                    <mat-option value="Non">
                        Non
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div> -->
</div>
<div class="row">

    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allRealisation.length > 0">
                <mat-form-field style="width: 100%;margin-top: 11px;">
                    <mat-label>Qui s'occupe de la réalisation ?</mat-label>
                    <mat-chip-list #Realisation>
                        <mat-chip *ngFor="let realisation of ListRealisation" [selectable]="selectable"
                            [removable]="removable" (removed)="removeRealisation(realisation)"
                            (removed)=" EmitFilterAffaire()">
                            {{realisation}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterAffaire()" placeholder="Selectionner ..." #realisationInput
                            [formControl]="realisationFromCtrl" [matAutocomplete]="autoRealisation"
                            [matChipInputFor]="Realisation" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoRealisation="matAutocomplete" (optionSelected)="selectedRealisation($event)">
                        <mat-option *ngFor="let realisation of filteredRealisation | async" [value]="realisation.id">
                            {{realisation.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
</div>
