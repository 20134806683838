import { Validators } from '@angular/forms';
import { ApiOpportunitService } from './../../../../../services/ApiOpportunite/api-opportunite.service';
import { tags_pros } from './../../../../../entity/Opportunity/FilterOpportunite';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Zone } from 'src/app/entity/Zone';
import { ApiLocationServices } from 'src/app/services/ApiLocation/api-location.service';
import { map, startWith, tap } from 'rxjs/operators';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Prospect } from 'src/app/entity/Affaires/FilterAffaires';
@Component({
  selector: 'app-filtre-prospect',
  templateUrl: './filtre-prospect.component.html',
  styleUrls: ['./filtre-prospect.component.css']
})
export class FiltreProspectComponent implements OnInit, OnChanges {

  fa = fa
  ListVille: string;
  ZonesSelectionees: any[] = [];

  tags_pros: tags_pros[] = [];

  tagsContientSelected: any[] = [];
  tagsContientPasSelected: any[] = [];


  // Mat Chips Tags Contient
  tagsContientFromCtrl: FormControl = new FormControl('', [Validators.required]);
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTagsContient: Observable<tags_pros[]>;
  ListTagsContient: string[] = [];
  allTags: tags_pros[] = [];

  @ViewChild('TagsContientInput') TagsContientInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTagsContient') matAutocompleteTagsContient: MatAutocomplete;

  // Mat Chips Tags Ne Contient Pas
  tagsContientPasFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTagsContientPas: Observable<tags_pros[]>;
  ListTagsContientPas: string[] = [];

  @ViewChild('TagsContientPasInput') TagsContientPasInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTagsContientPas') matAutocompleteTagsContientPas: MatAutocomplete;

  // Mat Chips Zones
  zoneFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredZones: Observable<Zone[]>;
  ListZones: string[] = [];
  allZones: Zone[] = [];

  @ViewChild('ZonesInput') ZonesInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoZones') matAutocompleteZones: MatAutocomplete;



  filterProspect: Prospect = new Prospect();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterProspect: EventEmitter<Prospect> = new EventEmitter();
  constructor(
    private apiOpportuniteService: ApiOpportunitService,
    private ApiLocationService: ApiLocationServices,
    private chRef: ChangeDetectorRef) { }

  // Tags Contient -----------------------------------------------------------------------------------------------

  selectedTagsContient(event: MatAutocompleteSelectedEvent): void {
    this.ListTagsContient.push(event.option.viewValue);
    this.tagsContientSelected.push(event.option.value);
    this.TagsContientInput.nativeElement.value = '';
    this.tagsContientFromCtrl.setValue(null);
  }

  removeTagsContient(tagsContient: string): void {
    const index = this.ListTagsContient.indexOf(tagsContient);
    if (index >= 0) {
      this.ListTagsContient.splice(index, 1);
      this.tagsContientSelected.splice(index, 1);
    }
  }

  private _filterTagsContient(): tags_pros[] {
    let filterValue = this.tagsContientFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allTags.filter(tagsContient => tagsContient.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  // Tags Ne Contient Pas -----------------------------------------------------------------------------------------------

  selectedTagsContientPas(event: MatAutocompleteSelectedEvent): void {
    this.ListTagsContientPas.push(event.option.viewValue);
    this.tagsContientPasSelected.push(event.option.value);
    this.TagsContientPasInput.nativeElement.value = '';
    this.tagsContientPasFromCtrl.setValue(null);
  }

  removeTagsContientPas(tagsContientPas: string): void {
    const index = this.ListTagsContientPas.indexOf(tagsContientPas);
    if (index >= 0) {
      this.ListTagsContientPas.splice(index, 1);
      this.tagsContientPasSelected.splice(index, 1);
    }
  }

  private _filterTagsContientPas(): tags_pros[] {
    let filterValue = this.tagsContientPasFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allTags.filter(tagsContientPas => tagsContientPas.libelle.toLowerCase().indexOf(filterValue) === 0);
  }


  // Zones -----------------------------------------------------------------------------------------------

  selectedZones(event: MatAutocompleteSelectedEvent): void {
    this.ListZones.push(event.option.viewValue);
    this.ZonesSelectionees.push(event.option.value);
    this.ZonesInput.nativeElement.value = '';
    this.zoneFromCtrl.setValue(null);
  }

  removeZones(Zones: string): void {
    const index = this.ListZones.indexOf(Zones);
    if (index >= 0) {
      this.ListZones.splice(index, 1);
      this.ZonesSelectionees.splice(index, 1);
    }
  }

  private _filterZones(): Zone[] {
    let filterValue = this.zoneFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allZones.filter(Zones => Zones.nom.toLowerCase().indexOf(filterValue) === 0);
  }


  ngOnInit(): void {

    this.ApiLocationService.getListeZone().subscribe(
      (data: Zone[]) => {
        this.allZones = data;
      }
    );
    this.filteredTagsContient = this.tagsContientFromCtrl.valueChanges.pipe(
      startWith(null),
      map((tagsContient: string | null) => tagsContient ? this._filterTagsContient() : this.allTags.slice()));

    this.filteredTagsContientPas = this.tagsContientPasFromCtrl.valueChanges.pipe(
      startWith(null),
      map((tagsContientPas: string | null) => tagsContientPas ? this._filterTagsContientPas() : this.allTags.slice()));
    this.filteredZones = this.zoneFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Zones: string | null) => Zones ? this._filterZones() : this.allZones.slice()));

    this.getDetailsFilter();


  }
  getDetailsFilter() {
    this.apiOpportuniteService.getDetailsFilter().pipe(
      tap(({ tags }) => {
        this.allTags = tags;
      })
    ).subscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterProspectForm(changes.Reset.currentValue);
  }
  resetfilterProspectForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {
      this.ListTagsContient = [];
      this.filterProspect.tags = this.tagsContientSelected = [];
      this.ListTagsContientPas = [];
      this.filterProspect.tags_no = this.tagsContientPasSelected = [];
      this.ListZones = [];
      this.filterProspect.prospect_zone = this.ZonesSelectionees = [];
      this.filterProspect.prospect_nom_prenom = "";
      this.filterProspect.prospect_tel = "";
      this.filterProspect.prospect_mail = "";
      this.filterProspect.prospect_ville = "";
      this.filterProspect.mail_contient = "";

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterProspect.emit(this.filterProspect);
    }
  }

  EmitFilterProspect() {

    this.filterProspect.prospect_zone = this.ZonesSelectionees
    this.filterProspect.tags = this.tagsContientSelected
    this.filterProspect.tags_no = this.tagsContientPasSelected
    this.onPushFilterProspect.emit(this.filterProspect);
  }

}
