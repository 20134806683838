import {
  Component,
  EventEmitter,
  Injectable,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  Input,
  OnChanges,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { tree } from '../../../../../entity/TreeView';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiStatusService } from '../../../../../services/ApiStatus/api-status.service';
import { RendezVous } from '../../../../../entity/Opportunity/FilterOpportunite';
import { of as ofObservable, Observable, BehaviorSubject } from 'rxjs';

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  id: string;
}
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  id: string;
}
var TREE_DATA;
@Injectable({ providedIn: 'root' })
export class ChecklistDatabase {
  dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor(private apiStatutService: ApiStatusService) {
    this.initialize();
  }

  async initialize() {
    //  await this.apiStatutService.GetTreeView()
    //    .pipe(
    //      tap(({ tree }) => {

    //        TREE_DATA = tree;
    //      })
    //    ).toPromise();
    if(TREE_DATA!==null && TREE_DATA !==undefined){
      const data = this.buildFileTree(TREE_DATA, 0, 2);
    }
    

    // if(data.length!=0){
    //   this.dataChange.next(data);
    // }
  }

  buildFileTree(obj: { [key: string]: any }, level: number, keyLevels: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value === null || value === undefined) {
        // no action
      } else if (typeof value === 'object' && level < keyLevels) {
        node.children = this.buildFileTree(value, level + 1, keyLevels);
      } else if (typeof value === 'object') {
        node.item = value['etat'];
        node.id = value['id'];
        if (value['child'])
          if (value['child'].length > 0) {
            node.children = this.buildFileTree(value['child'], level + 1, keyLevels);
          }
      } else {
        node.item = value;
        node.id = value;
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'app-filtres-status-actuel',
  templateUrl: './filtres-status-actuel.component.html',
  styleUrls: ['./filtres-status-actuel.component.css'],
  providers: [ChecklistDatabase],
})
export class FiltresStatusActuelComponent implements OnInit, OnChanges {
  tree: tree[] = [];
  TreeData: tree[] = [];
  statuActuel: RendezVous = new RendezVous();

  flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(false /* multiple */);

  @Output() onPushFilterStatusActuel: EventEmitter<RendezVous> = new EventEmitter();
  etatRdv: any[];
  etatRendezVous: any[];
  selectedEtatRdv: any[];
  count: number = 0;

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();

  constructor(private apiStatutService: ApiStatusService, private chRef: ChangeDetectorRef) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  async ngOnInit() {
    try {
      this.apiStatutService
        .GetTreeView()
        .pipe(
          tap(({ tree }) => {
            this.dataSource.data = this.buildFileTree(tree, 0, 2);
          })
        )
        .subscribe();
    } catch (err) {
      console.log(err);
    }
  }

  getLevel = (node: TodoItemFlatNode) => {
    return node.level;
  };

  isExpandable = (node: TodoItemFlatNode) => {
    return node.expandable;
  };

  getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
    return ofObservable(node.children);
  };

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.expandable;
  };

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.item === '';
  };

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };
  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));

    this.etatRendezVous = this.checklistSelection.selected;
    let selectedElements = [];
    this.checklistSelection.selected.forEach((element) => {
      selectedElements.push(element.id);
    });
    this.statuActuel.etat_rdv = selectedElements;
    this.onPushFilterStatusActuel.emit(this.statuActuel);
    return descAllSelected;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.resetStatutForm(changes.Reset.currentValue);
  }
  resetStatutForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.statuActuel = new RendezVous();
      this.etatRendezVous = [];
      this.statuActuel.etat_rdv = [];
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterStatusActuel.emit(this.statuActuel);
      this.ngOnInit();
    }
  }
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode, event): void {
    if (event.checked == true) {
      this.statuActuel.etat_rdv = this.unique(this.statuActuel.etat_rdv, node.id);
    } else {
      const index = this.statuActuel.etat_rdv.indexOf(node.id, 0);
      if (index > -1) {
        this.statuActuel.etat_rdv.splice(index, 1);
      }
    }

    this.onPushFilterStatusActuel.emit(this.statuActuel);
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }
  unique = (set, item) => {
    return (set.includes(item) || set.push(item)) && set;
  };
  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);

    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }
  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }
  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
  buildFileTree(obj: { [key: string]: any }, level: number, keyLevels: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value === null || value === undefined) {
        // no action
      } else if (typeof value === 'object' && level < keyLevels) {
        node.children = this.buildFileTree(value, level + 1, keyLevels);
      } else if (typeof value === 'object') {
        node.item = value['etat'];
        node.id = value['id'];
        if (value['child'])
          if (value['child'].length > 0) {
            node.children = this.buildFileTree(value['child'], level + 1, keyLevels);
          }
      } else {
        node.item = value;
        node.id = value;
      }

      return accumulator.concat(node);
    }, []);
  }
}
