import { BankInformation } from './../BankInformation';
export class UserAdmin2 {
    user: user = new user();
    adresse: adresse = new adresse();
    ville: ville = new ville()
   

}
export class user {
    id: string = "";
    civilite: string = "";
    telephone: string = "";
    tel_urgence: string = "";
    mobile: string = "";
    tel2: string = "";
    mail: string = "";
    nom: string = "";
    prenom: string = "";
    matricule_rh: string = "";
    identite_reelle: string = "";
    skype: string = "";
    password: string = "";
    old_password: string = "";
    pointage: boolean = false;
    super_admin: boolean = false;
    admin_restreint: boolean = false;
    api_key: string = "";
    entreprise_id: string = "";
    date_naiss_user: string = "";
    role_auto: any[] = [];
    active: string = "";
    server_in_mail_data: string = "";
    server_out_mail_data: string = "";
    port_in_mail_data: string = "";
    port_out_mail_data: string = "";
    ssl_mail_data: boolean = false;
    num_interne: string = "";
    photo: any = null;
    admin_tpv: number = 2;
    execution_tpv: number = 2;
    validate_paiement: number = 2;
    date_integration: string = ""; 
    heure_fin: string = "";
    heure_debut: string = "";
    proprietaire: boolean = false;
    adresse_auto: string = "";
    samedi_off: boolean = false;
    comparateur: string = "";
    tarificateur: number = 2;
    signature: string = "";
    groupe_commission: string = "";
    act_grp_pubpostage: number = 2;
    act_grp_negociateur: number = 2;
    act_grp_communication: number = 2;
    acces_maileva: number = 2;
    acces_fusion: number = 2;
    nom_producteur: string = "";
    prenom_producteur: string = "";
    qualite_producteur: string = "";
    orias_producteur: string = "";
    location: string = "";
    acces_ett: boolean = false;
    role: string = "";
    zone: string = "";
    code: string = "";
    act_grp_tags: number = 2;
    act_grp_statut: number = 2;
    hist: number = 2;
    hist_comm: number = 2;
    acces_communiques: number = 2;
    ecriture_transaction_financiere_client : number = 2;
    lecture_transaction_financiere_client : number = 2;
    lecture_transaction_financiere_distribution : number = 2;
    ecriture_transaction_financiere_distribution : number = 2;
    transaction_financiere: number = 2;
    user_mail_data: string = "";
    user_pwd_data: string = "";
    acces_reclamation_ticket: number = 2;
    acc_opportunite: Acces[] = [];
    acc_affaire: Acces[] = [];
    manager_list: any[] = [];
    manager_de: any[] = [];
    groupe_user: any[] = [];
    tab_code: any[] = [];
    groupe_user_alert: any[] = [];
    nom_organisme: string = "";
    type_user: string = "";
    information_bancaire: BankInformation = new BankInformation();
    espace_distrib_access : espace_distrib_access= new espace_distrib_access();
}
export class adresse {
    CP: string = "";
    streetNumber: string = "";
    streetName: string = "";
    longitude: number = 0;
    latitude: number = 0;
    geom: any = null
}
export class ville {
    id_pays: string = "";
    nom_comm: string = "";
}

export class Acces {
    id: string = "";
    cat: string = "";
    libele: string = "";
    type: string = "";
}
export class espace_distrib_access{
    changement_statut: number =2;
    acces_info_spec : number= 2;
}
