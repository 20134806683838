import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { Component, OnInit } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';

@Component({
  selector: 'app-commentaires',
  templateUrl: './commentaires.component.html',
  styleUrls: ['./commentaires.component.css'],
})
export class CommentairesComponent implements OnInit {
  id_opportunite: string;
  allowed_to_update: any;
  constructor(
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiOpportuniteServices: ApiOpportunitService
  ) {}

  ngAfterViewInit(): void {
    this.apiOpportuniteServices.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnInit(): void {
    this.id_opportunite = this.opportunitiesDetailsComponent.opportunity_id;
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.sharedMenuObserverService.updateMenu('Opportunités ➜  Détails ➜ Commentaires');
  }
}
