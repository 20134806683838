<div class="row  example-full-width " >
    <div class="col-4 w-100 example-full-width">
        <mat-form-field class="example-full-width w-100">
          <mat-select (selectionChange)=" EmitFilterEcheance()" [(ngModel)]="filterEcheance.operateur_status_echeances"
              placeholder="Opérateur">
              <mat-option value="=">
                  est égale
              </mat-option>
              <mat-option value="!=">
                  Différent
              </mat-option>
      
          </mat-select>
      </mat-form-field>
      </div>
    <div class="col-8 example-full-width">
        <mat-form-field class="example-full-width w-100">
          <mat-label>Status</mat-label>
          <mat-chip-list #chipList>
              <mat-chip *ngFor="let status of ListStatus" [selectable]="selectable" [removable]="removable" (removed)="removeStatus(status)" (removed)="EmitFilterEcheance()">
                  {{status}}
                  <i matChipRemove *ngIf="removable" class="fa fa-times" aria-hidden="true"></i>
                  <!-- <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon> -->
              </mat-chip>
              <input (click)="EmitFilterEcheance()" placeholder="Selectionner produit..." #statusInput 
              [formControl]="StatusFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedStatus($event)">
              <mat-option *ngFor="let status of filteredStatus | async" [value]="status.id">
                  {{status.libelle}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-12">
      <div class="form-group">
        <mat-form-field class="example-full-width w-100" >
        <mat-date-range-input [rangePicker]="pickerDC" >
          <input matStartDate (change)="EmitFilterEcheance()"  [(ngModel)]="date_debut_prelevement" placeholder="Date de prélévement du" />
          <input matEndDate (change)="EmitFilterEcheance()"  [(ngModel)]="date_fin_prelevement" placeholder="à" />

      </mat-date-range-input>
      <mat-datepicker-toggle (change)="EmitFilterEcheance()"  matSuffix [for]="pickerDC"></mat-datepicker-toggle>
      <mat-date-range-picker (closed)="EmitFilterEcheance()" #pickerDC></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>




  