import { Component } from '@angular/core';
import {FieldArrayType} from "@ngx-formly/core";

@Component({
  selector: 'app-repeat-type',
  templateUrl: './repeat-type.component.html',
  styleUrls: ['./repeat-type.component.css']
})
export class RepeatTypeComponent extends FieldArrayType {


}
