import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGardConfig } from './AuthGardeConfig';
import {ApiMenuService} from '../../services/ApiMenu/api-menu.service';
import {RouteInfo} from '../../components/navbar/navbar.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGard implements CanActivate {
  constructor(private apiMenuService: ApiMenuService,
    private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (AuthGardConfig.routes.length === 0) {
          this.apiMenuService.getMenuByRoleGet().subscribe(
            (data) => {
              AuthGardConfig.routes = data.menu;
              this.router.navigate([state.url]);
            }
          )
     }else {
        if (this.recursive_path_finder(AuthGardConfig.routes,state.url)){
          return true
        }else  {
          // this.router.navigate(['']);
           this.router.navigate([AuthGardConfig.routes[0].path]);
        }
      }
  }

  recursive_path_finder(listPaths:RouteInfo[],path:string):boolean
  {
    for (let i= 0; i< listPaths.length; i++){
      if(listPaths[i].path==path){
        return true;
      }else{
        if(listPaths[i].child.length>0){
          if( this.recursive_path_finder(listPaths[i].child, path)){
            return true;
          }
        }
      }
    }
    return false
  }
}
