import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import * as fa from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-form-objet-assurer',
  templateUrl: './form-objet-assurer.component.html',
  styleUrls: ['./form-objet-assurer.component.css'],
})
export class FormObjetAssurerComponent implements OnInit {
  @Input() formObjectAssurer;
  @Input() index;
  @Output() dataform = new EventEmitter<any>();
  @Output() dataformcopie = new EventEmitter<any>();
  @Output() dataformdelete = new EventEmitter<any>();
  form_objet_assurer = new FormGroup({});
  model_objet_assurer: any = {};
  options: FormlyFormOptions = {};
  model: any = {};
  form: any;
  data: any = {};
  fields = [];
  prospectFormnew: any = {};
  fa = fa;
  isExpanded: boolean = true;

  constructor(private formlyJsonschema: FormlyJsonschema) {}

  ngOnInit(): void {
    if (this.formObjectAssurer.expand === true) {
      this.isExpanded = true;
    } else {
      this.isExpanded = false;
    }

    const objCopy = JSON.parse(JSON.stringify(this.formObjectAssurer));
    // if(objCopy.form?.data){
    //   this.model=objCopy.form?.data;
    // }
    this.form = new FormGroup({});
    if (objCopy.form != null) {
      this.options = {};
      if (objCopy.form?.schema) {
        const form: any = objCopy.form?.schema?.properties;
        // if(objCopy.form?.schema?.properties?.length>0){
        //   this.appear = false;
        // } else {
        //   this.appear = true;
        // }
        objCopy.form.schema.properties = {};
        this.model = {};
        if (form !== undefined) {
          form.forEach((element) => {
            objCopy.form.schema.properties[element.name] = element;
            if (this.model[element.name]) this.model[element.name] = element.default;
          });
        }
        if (objCopy.form?.data) {
          for (const [key, value] of Object.entries(objCopy.form?.data)) {
            if (value != null) this.model[key] = value;
          }
        }
        this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(objCopy.form.schema)))];
      }
    } else if (objCopy.form != null) {
      this.options = {};

      if (objCopy.form?.schema) {
        this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(objCopy.form?.schema)))];
      }
      this.model = objCopy.form?.data;
    }
  }

  senddata($event) {
    const objCopy = JSON.parse(JSON.stringify(this.formObjectAssurer));
    objCopy.form.data = this.model;
    objCopy.expand = true;
    this.formObjectAssurer = Object.assign({}, objCopy);
    this.dataform.emit(this.formObjectAssurer);
  }

  duplicateobjet(formObjectAssurer) {
    this.dataformcopie.emit(formObjectAssurer);
  }

  deleteobjet(formObjectAssurer) {
    this.dataformdelete.emit(formObjectAssurer);
  }
}
