<div class="row">
    <div style="position: fixed;    z-index: 5;">
        <app-filter-maileva (onSubmitFilter)="getListMaileva()" (onPushFilters)="EmitFilters($event)">
        </app-filter-maileva>
    </div>
</div>

<div class="row">
    <!-- Datatables -->
    <div class="col-lg-12">
        <div class="card mb-4 border-0" style="border-radius: 13px;">
            <div class="row">
                <div class="col-8 d-block mt-4">
                    <button type="button" class="btn-dt-add  ml-4" routerLink="add" data-title="Ajouter un courrier">
              <fa-icon class="icon-btn-add" [icon]="fa['faPlus']"></fa-icon>
            </button>
                </div>
                <div class="col-4 mt-2  p-2 text-right">
                    <input class="search" name="" placeholder="Recherche...">
                    <fa-icon aria-hidden="true" class="icon-search fa-md" [icon]="fa['faSearch']"></fa-icon>
                </div>
            </div>
            <div class="row row-info">
                <div class="card-info-1" (click)="setStatusFilter('')">
                    <p class="p-info-1">{{maileva.iTotalCourrierRecords}}</p>
                    <p class="p-info-2">Total Courriers</p>
                </div>
                <div class="card-info-2" (click)="setStatusFilter('Success')">
                    <p class="p-info-1">{{maileva.iTotalSuccessRecords}}</p>
                    <p class="p-info-2">Envoyés</p>
                </div>
                <div class="card-info-3" (click)="setStatusFilter('Pending')">
                    <p class="p-info-1">{{maileva.iTotalPendingRecords}}</p>
                    <p class="p-info-2">Planifiés</p>
                </div>
                <div class="card-info-4" (click)="setStatusFilter('Error')">
                    <p class="p-info-1">{{maileva.iTotalErrorRecords}}</p>
                    <p class="p-info-2">Echoués / Non Traités</p>
                </div>
            </div>


            <div class="table-responsive p-3 ">
                <table class="table align-items-center " id="dataTable">


                    <thead class="head-table">
                        <tr>
                            <th>
                                Actions
                            </th>
                            <th>Création
                                <div (click)="setSortField(1)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(1) }">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']"></fa-icon>
                                </div>
                            </th>
                            <th> Affaire/Opportunité
                            </th>
                            <th>Type d'Envoi
                                <div (click)="setSortField(5)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(5) }">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']"></fa-icon>
                                </div>
                            </th>
                            <th>Date d'Envoi
                                <div (click)="setSortField(0)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(0) }">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']"></fa-icon>
                                </div>
                            </th>
                            <th>Date de Réception
                                <div (click)="setSortField(3)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(0) }">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']"></fa-icon>
                                </div>
                            </th>

                            <th>Expéditeur
                                <div (click)="setSortField(0)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(0) }">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']"></fa-icon>
                                </div>
                            </th>
                            <th>Destinataire
                                <div (click)="setSortField(6)" class="d-inline-block" [ngClass]="{'sort-up':checkSort(6) }">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']"></fa-icon>
                                </div>
                            </th>
                            <th>Adresse Destinataire
                                <div (click)="setSortField(7)" class="d-inline-block" [ngClass]="{'sort-up': checkSort(7) }">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']"></fa-icon>
                                </div>
                            </th>
                            <th>Status</th>
                        </tr>
                    </thead>


                    <tbody class="body-table">
                        <tr *ngFor="let mail of maileva.aaData">
                            <td>
                                <button type="button" class="btn-dt-list" routerLink="/maileva/details/{{mail.id_md5}}/">
                  <img src="/assets/icons/edit-icon.svg">
                </button>
                                <a target="_blank" href="{{mail.file_courrier}}">
                                    <button type="button" class="btn-dt-list">
                    <img src="/assets/icons/pdf-icon.svg">
                  </button>
                                </a>
                            </td>
                            <td>
                                {{mail.date| date:'dd-MM-yyyy HH:mm' }}
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <div *ngIf="mail.id_affaire == null && mail.url_dossier == null && mail.id_opp == null">
                                    </div>
                                    <div *ngIf="mail.id_affaire !== null ">
                                        <button type="button" class="btn-dt-link text-left" (click)="clickAffaire(mail.id_affaire)">
                      <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                      Affaire
                    </button>
                                    </div>
                                    <div *ngIf="mail.url_dossier !== null">
                                        <a href="{{mail.url_dossier}}">
                                            <button type="button" class="btn-dt-link text-left">
                      <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                      Dossier
                      </button>
                                        </a>
                                    </div>
                                    <div *ngIf="mail.id_opp !== null">
                                        <button type="button" class="btn-dt-link text-left pl-2" (click)="clickOpportunite(mail.id_opp)">
                      <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                      Opportunité
                    </button>
                                    </div>
                                </div>
                            </td>

                            <td>
                                {{mail.type_envoie}}
                            </td>


                            <td *ngIf="format_date(mail.date_envoie)">
                                {{mail.date_envoie}}
                            </td>
                            <td *ngIf="!format_date(mail.date_envoie)">

                            </td>

                            <td *ngIf="format_date(mail.date_livraison)">
                                {{ mail.date_livraison}}
                            </td>
                            <td *ngIf="!format_date(mail.date_livraison)">
                            </td>

                            <td>
                                {{mail.nomprenom_exp}}
                            </td>
                            <td>
                                {{mail.nomprenom_dest}}
                            </td>
                            <td>
                                <p>
                                    {{ mail.postal_code_post + ' ' + mail.ville_dest }}
                                </p>
                            </td>
                            <td>
                                <!-- <div class="d-inline-block label-status p-1" tabindex="0" data-toggle="tooltip"
                  title="{{mail?.statut_details?.description}}" [style.background]="mail?.statut_details?.background">
                    <p  [style.color]="mail.statut_details?.color">
                      {{mail.statut_details?.libelle}}
                    </p>
                </div> -->
                                <div *ngIf="mail.statut_details?.libelle" class="status-maileva d-flex justify-content-center" [style.background]="mail?.statut_details?.background" [style.color]="mail.statut_details?.color">{{mail.statut_details?.libelle}}</div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="navbar-center mb-4 row">
            <div class="col pt-1">
                <div class="row w-50 flex-nowrap">
                    <div class="col mt-1">
                        <p>{{'label.show' | translate}}</p>
                    </div>
                    <div class="col mx-1">
                        <select [(ngModel)]="limit" (change)="updateListMaileva()" class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
                    </div>
                    <div class="col mt-1">
                        <p>{{'label.entries' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                    <ul class="pagination pg-blue">
                        <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Previous">
                                <i class="fa fa-chevron-left "></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+Page-3>0" class="page-item">
                                <a (click)="ChangePage(i+Page-3)" class="page-link mt-2 border rounded">{{i + Page - 3}}</a>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+Page<totalPages" class="page-item">
                                <a (click)="ChangePage(i+Page)" class="page-link mt-2 border rounded" [ngClass]="{'active-page':Page==(i+Page)}">
                {{i + Page}}
              </a>
                            </li>
                        </ng-container>
                        <li *ngIf="totalPages > 3 " class="m-2"> ... </li>
                        <li class="page-item">
                            <a (click)="ChangePage(totalPages)" class="page-link mt-2 border rounded" [ngClass]="{'active-page':Page==totalPages}">{{totalPages}}</a>
                        </li>
                        <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Next">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                        </li>
                        <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{'label.goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48" (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;" size="1" class="page_input bg-white border rounded font-weight-bold"
                            [value]="Page" max="{{totalPages}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal View Details -->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <ng-container *ngIf="selectedMaileva">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{selectedMaileva.nomprenom_dest}}#{{selectedMaileva.id}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    ...
                </div>
            </ng-container>
        </div>
    </div>
</div>
