import { FormModel } from '../Formbuilder/FormModel';
import { Brand } from './brand/brand';

export class ListOrganisme {
  active: string = '0';
  adresse: string = '';
  adresse_autorise: string = '';
  dossier: string = '';
  id: string = '';
  id_org: string = '';
  id_adresse: string = '';
  nom: string = '';
  organisme_libelle: string = '';
  type: string = '';
  campagnes: any[] = [];
  id_type: string = '';
  last_update: string = '';
  libelle: string = '';
  libelle_type: string = '';
  logo: File;
  favicon: File;
  tel: string = '';
  tel_gestion: string = '';
  mail: string = '';
  mail_gestion: string = '';
  capital: string = '';
  form_juridique: string = '';
  rcs: string = '';
  code_debiteur: string = '';
  code_intermediaire: string = '';
  pre_pay: string = '0';
  description_ficheconseil: string = '';
  id_robot: string = '';
  site: string = '';
  orias: string = '';
  sarl: string = '';
  robot: string = '';
  nom_robot: string = '';
  real_id: string = '';
  libelle_campagnes: any[] = [];
  nom_commerciaux: any[] = [];
  siren: string = '';
  siret: string = '';
  url_cgu: string = '';
  url_privacy: string = '';
  id_externe: string = '';
  urlcomparateur: string = '';
  urltarification: string = '';
  nom_organisme_master: string = '';
  organisme_master: string = '';
  brand: Brand;
  images: File[] = [];
  background_image: File;
  used_brand: any;
  acces_geoprod: string = '0';
  form : any ={};
  constructor(init?: Partial<ListOrganisme>) {
    Object.assign(this, init);
  }
}
