import {FormField} from './FormField';

export class PasswordFormly extends FormField {
  maxLength=255;
  minLength=0;
  pattern="";
  format="";
  constructor() {
    super();
    super.icon="fa fa-unlock-alt";
    super.name = 'texte_' + super.generateRandomId();
    this.type = 'password';
    this.title="Mot de passe"
    super.typename = 'Password';
  }
}
