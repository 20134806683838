import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import 'datatables.net';
import 'datatables.net-bs4';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { HistoriquesTickets } from '../../../../entity/TicketsAndSupport/HistoriquesTickets';
import { ApiTicketsService } from '../../../../services/ApiTickets/api-tickets.service';
import { ActivatedRoute } from '@angular/router';
import { UniformiserDateToLocal } from 'src/app/shared/Utils/sharedFunctions';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-ticket-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css']
})
export class HistoriqueComponent implements OnInit, OnDestroy {
  fa = fa;
  historiquesTickets: HistoriquesTickets[] = []
  id: string = "";
  loader: Boolean = false;
  dataTable: any;

  displayedColumns: string[] = ['date', 'user', 'commentaire'];
  UnsubscribeGetHistoriquesTickets: Subscription;
  dataSource: MatTableDataSource<HistoriquesTickets>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private apiTicketService: ApiTicketsService,
    private chRef: ChangeDetectorRef, private route: ActivatedRoute) {
    this.apiTicketService.listenTicket().subscribe((data: any) => {
      this.getHistoriquesTickets()
    })
  }
  ngOnDestroy(): void {
    this.UnsubscribeGetHistoriquesTickets.unsubscribe();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("tickets_id");
    });
    this.getHistoriquesTickets()
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getHistoriquesTickets() {
    this.UnsubscribeGetHistoriquesTickets = this.apiTicketService.GetHistoriquesTickets(this.id)
      .subscribe((data) => {
        this.historiquesTickets = data["result"].reverse();
        this.historiquesTickets.forEach((element, index) => {
          this.historiquesTickets[index].date_action = UniformiserDateToLocal(
            element.date_action
          );
        })
        this.dataSource = new MatTableDataSource(data["result"]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator._intl.itemsPerPageLabel = "Eléments par page";
        this.dataSource.sort = this.sort;
        this.chRef.detectChanges();

      })

  }
}
