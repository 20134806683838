import {Entreprise} from "./Entreprise";
import {Prospect} from "../Prospect";

export class ProspectAdd{
  entreprise:Entreprise=new Entreprise();
  prospect:Prospect[]=[];
  mainProspect: Prospect = new Prospect();
  choice:boolean=false;
  constructor() {
  }
}
