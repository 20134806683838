import { Component, OnInit, ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiComminucationService } from '../../../services/ApiComminucation/api-comminucation.service';
import { CommunicationModel } from '../../../entity/CommunicationModel';
import { tap } from 'rxjs/operators';
import { ListTypesAndParent } from '../../../entity/Opportunity/ListTypesAndParent';
import {
  CourrierCommunication,
  ObjetDestinataire,
  ObjetExpediteur,
} from '../../../entity/Generic/CourrierCommunication';
import { DatePipe } from '@angular/common';
import { Process } from '../../../entity/Process';
import swal from 'sweetalert2';
import { GetOpportuniteDetails } from 'src/app/entity/Opportunity/GetOpportuniteDetails';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css'],
})
export class CommunicationComponent implements OnInit {
  public Editor = ClassicEditor;
  public config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'undo',
      'redo',
      'blockQuote',
      '|',
      'numberedList',
      'bulletedList',
      'mediaEmbed',
      '|',
      'insertTable',
      'tableColumn',
      'tableRow',
      'mergeTableCells',
    ],
  };

  showhidedist_dest: boolean = false;
  showhidedist_exp: boolean = false;
  show_loading: boolean = true;
  fa = fa;
  faa = fa;
  content: string = '';
  formModel: FormGroup;
  sujet: string = '';
  ShowNumber: number = 4;
  mail_user: string;
  url;
  communicationModel: CommunicationModel[] = [];
  idModel: string = '';
  with_color: string;
  recto_verso: string;
  listParent: ListTypesAndParent[] = [];
  listTypes: ListTypesAndParent[] = [];
  ListFiles: File[] = [];
  file: File;
  type_file: any;
  index: number = 0;
  sendMail: CommunicationModel;
  id_model_courrier: string;
  file_location: string;
  courrier: CourrierCommunication;
  obj_destinataire: ObjetDestinataire;
  files: any[] = [];
  myDate = new Date();
  currentDateTime: string;
  opportunity_id: string;
  obj_expediteur: ObjetExpediteur;
  id_courrier: any;
  nbre_page_courrier: any;
  getValue: string;
  listOfProcess: Process[] = [
    new Process('template', 'active'),
    new Process('Distinataire', 'toDo'),
    new Process('Expéditeur', 'toDo'),
    new Process('Paramètres', 'toDo'),
    new Process('Content', 'toDo'),
  ];

  step: number = 0;
  statut: any;
  statut_envoi: any;
  type_envoie: any;
  opportunite_details: GetOpportuniteDetails = new GetOpportuniteDetails();
  id_prospect: string = '';
  @ViewChild('closebuttonSMS') closebuttonSMS; // permet de fermer le popup SMS
  @ViewChild('closebuttonMail') closebuttonMail; // permet de fermer le popup Mail

  constructor(
    private apiCommunicationService: ApiComminucationService,
    private formBuilder: FormBuilder,
    private apiOpportuniteServices: ApiOpportunitService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public NotificationsService: NotificationsService
  ) {
    this.currentDateTime = this.datePipe.transform(this.myDate, 'yyyy-mm-dd HH:MM:SS.SSS');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.opportunity_id = params.get('op_id');
    });

    this.getListTypeAndParent();
    this.getDetailsLibelleCourrier();
    this.getOpportunite();
    this.formModel = this.formBuilder.group({
      mail_destinataire: new FormControl('', Validators.required),
      content: new FormControl(''),
      body_content: new FormControl('<p>Bonjour ..</p>'),
      sujet: new FormControl('', Validators.required),
      mail_source: new FormControl(''),
      expediteur: new FormControl(''),
      impression: new FormControl(''),
      couleur: new FormControl(''),
      type: new FormControl(''),
      complement_adr_destinataire: new FormControl(''),
      complement_adr_expediteur: new FormControl(''),
      code_postal_destinataire: new FormControl(''),
      code_postal_expediteur: new FormControl(''),
      ville_destinataire: new FormControl(''),
      ville_expediteur: new FormControl(''),
      rue_destinataire: new FormControl(''),
      rue_expediteur: new FormControl(''),
      num_street_destinataire: new FormControl(''),
      num_street_expediteur: new FormControl(''),
      adresse_dest: new FormControl(''),
      saisie_auto_adr: new FormControl(''),
      boite_postale_destinataire: new FormControl(''),
      boite_postale_expediteur: new FormControl(''),
      prenom_destinataire: new FormControl(''),
      prenom_expediteur: new FormControl(''),
      nom_destinataire: new FormControl(''),
      nom_expediteur: new FormControl(''),
      civilite_destinataire: new FormControl(''),
      civilite_expediteur: new FormControl(''),
      file: new FormControl(''),
      upload_image: new FormControl(''),
      upload_file: new FormControl(''),
      nbre_page: new FormControl(''),
      telephone_destinataire: new FormControl(''),
      telephone_expediteur: new FormControl(''),
      distrubution_destinataire: new FormControl(''),
      distrubution_expediteur: new FormControl(''),
      type_envoie: new FormControl(''),
      statut_envoi: new FormControl(''),
      statut: new FormControl(''),
      type_envelope: new FormControl(''),
    });
    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails ➜ Communications');
  }

  getOpportunite() {
    this.apiOpportuniteServices.GetOpportunite(this.opportunity_id).subscribe((data: GetOpportuniteDetails) => {
      this.opportunite_details = data;
      this.id_prospect = this.opportunite_details.opportunite_details.main_prospect_id;
    });
  }

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  onNext() {
    if (this.step < 4) {
      this.listOfProcess[this.step].class = 'done';
      this.step += 1;
      this.listOfProcess[this.step].class = 'active';
    }
  }

  onPred() {
    if (this.step > 0) {
      this.listOfProcess[this.step].class = 'toDo';
      this.step -= 1;
      this.listOfProcess[this.step].class = 'active';
    }
  }

  getInfoModel(mail: string, sujet: string, content: string, idModel: string) {
    this.mail_user = mail;
    this.idModel = idModel;
    this.sujet = sujet;
    this.formModel.controls.mail_source.setValue(mail);
    this.formModel.controls.sujet.setValue(sujet);
    this.formModel.controls.body_content.setValue(content);
  }

  resetForm() {
    this.formModel.reset();
    this.communicationModel = [];
    this.sujet = '';
    this.formModel.controls.body_content.reset();
    this.idModel = '';
    this.formModel.controls.mail_source.reset();
    this.formModel.controls.sujet.reset();
    this.formModel.controls.body_content.reset();
  }

  getFiles(event: any) {
    this.file = event.target.files[0];
    this.type_file = this.file.type;
    this.ListFiles[this.index] = this.file;
    this.index++;
    this.ngOnInit();
  }

  dropFile(index: number) {
    this.ListFiles.splice(index, 1);
    this.index--;
  }

  SaveModel(media: string) {
    const formData = new FormData();
    if (media === 'S') {
      formData.append('simple_content', this.formModel.value.body_content);
      formData.append('riche_content', '');
      formData.append('sujet', '');
      formData.append('media', media);
      formData.append('type', '');
      formData.append('file[]', '');
    } else if (media === 'M') {
      formData.append('simple_content', '');
      formData.append('riche_content', this.formModel.value.body_content);
      formData.append('sujet', this.formModel.value.sujet);
      formData.append('media', media);
      formData.append('type', 'LR'); // LR as Default( à voir )
      formData.append('type_entity', 'O');
      this.ListFiles.forEach((file) => formData.append('file[]', file));
    }
    this.apiCommunicationService.SaveModel(formData).subscribe(
      (response) => {
        alert(response.messaage);
      },
      (error) => {
        alert(error.messaage);
      }
    );
  }

  SendEmail() {
    if (this.formModel.value.sujet === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = 'Veuillez remplir le sujet';
      this.NotificationsService.sendMessage(notificationMessage);
    } else if (this.formModel.value.mail_destinataire === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = "Veuillez remplir l'email destinataire";
      this.NotificationsService.sendMessage(notificationMessage);
    } else {
      const formData = new FormData();
      formData.append('id_affaire', '');
      formData.append('id_model', this.idModel);
      formData.append('id_opp', this.opportunity_id);
      formData.append('id_prospect', this.id_prospect);
      formData.append('media', 'M');
      formData.append('sujet', this.formModel.value.sujet);
      formData.append('tel', '');
      formData.append('mail', this.formModel.value.mail_destinataire);
      formData.append('modele_reel', this.formModel.value.body_content);
      this.apiCommunicationService.saveDataCommunicationMailSMS(formData).subscribe(
        (response) => {
          this.closebuttonMail.nativeElement.click();
          this.alertSuccess(response.message);
          this.resetForm();
          this.idModel = '';
        },
        (error) => {
          this.alertSuccess(error.message);
          this.resetForm();
          this.idModel = '';
        }
      );
    }
  }

  sendSMSMessage() {
    if (this.formModel.value.sujet === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = "Mettez sujet de l'SMS";
      this.NotificationsService.sendMessage(notificationMessage);
    } else if (this.formModel.value.mail_destinataire === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = "Mettez l'email destinataire de l'SMS";
      this.NotificationsService.sendMessage(notificationMessage);
    } else {
      const formData = new FormData();
      formData.append('id_affaire', '');
      formData.append('id_model', this.idModel);
      formData.append('id_opp', this.opportunity_id);
      formData.append('id_prospect', this.id_prospect);
      formData.append('media', 'S');
      formData.append('sujet', this.formModel.value.sujet);
      formData.append('tel', '');
      formData.append('mail', this.formModel.value.mail_destinataire);
      formData.append('modele_reel', this.formModel.value.body_content);
      this.apiCommunicationService.saveDataCommunicationMailSMS(formData).subscribe(
        (response) => {
          this.closebuttonSMS.nativeElement.click();
          this.alertSuccess(response.message);
          this.resetForm();
          this.idModel = '';
        },
        (error) => {
          this.alertSuccess(error.message);
          this.resetForm();
          this.idModel = '';
        }
      );
    }
  }

  ChangeColor(event: any) {
    if (event.target.checked) {
      this.with_color = 'oui';
    } else {
      this.with_color = 'non';
    }
  }

  ChangeRectoVerso(event: any) {
    if (event.target.checked) {
      this.recto_verso = 'oui';
    } else {
      this.recto_verso = 'non';
    }
  }

  getListTypeAndParent() {
    this.apiCommunicationService
      .GetListTypeAndParent()
      .pipe(
        tap(({ parent, types }) => {
          this.listParent = parent;
          this.listTypes = types;
        })
      )
      .subscribe();
  }

  validatePdfMail() {
    const formData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      formData.append('file[]', this.files[i]);
    }

    formData.append('recto_verso', this.recto_verso);
    formData.append('type_envoie', this.formModel.value.type);
    formData.append('body_content', this.formModel.value.body_content);
    this.apiCommunicationService.SaveDataCommunication(formData).subscribe(
      (response) => {
        this.file_location = response.message.file_path;
        this.nbre_page_courrier = response.message.number_page;
        this.savePostDetails(
          this.file_location.substring(this.file_location.lastIndexOf('/'), this.file_location.length),
          this.nbre_page_courrier
        );
      },
      (error) => {
        alert(error.message);
      }
    );
  }

  getDetailsLibelleCourrier() {
    this.apiCommunicationService
      .GetDetailsLibellePost()
      .pipe(
        tap(({ statut, statut_envoi, type_envoie }) => {
          this.statut = statut;
          this.statut_envoi = statut_envoi;
          this.type_envoie = type_envoie;
        })
      )
      .subscribe();
  }

  savePostDetails(file_location: string, nbre_page: string) {
    this.courrier = new CourrierCommunication(this.courrier);
    this.obj_destinataire = new ObjetDestinataire();
    this.obj_destinataire.nom_prenom_dest = this.formModel.value.nom + ' ' + this.formModel.value.prenom;
    this.obj_destinataire.rue_dest = this.formModel.value.rue;
    this.obj_destinataire.cp_dest = this.formModel.value.code_postal;
    this.obj_destinataire.ville_dest = this.formModel.value.ville;
    this.obj_destinataire.complement_adr_dest = this.formModel.value.complement_adr;
    this.obj_destinataire.verif_bp_dest = this.formModel.value.boite_postale_destinataire;
    this.obj_destinataire.bp_dest = this.formModel.value.distrubution_destinataire;

    this.obj_expediteur = new ObjetExpediteur();
    this.obj_expediteur.nom_prenom_exp =
      this.formModel.value.nom_expediteur + ' ' + this.formModel.value.prenom_expediteur;
    this.obj_expediteur.rue_exp =
      this.formModel.value.rue_expediteur + ' ' + this.formModel.value.num_street_expediteur;
    this.obj_expediteur.cp_exp = this.formModel.value.code_postal_expediteur;
    this.obj_expediteur.ville_exp = this.formModel.value.ville_expediteur;
    this.obj_expediteur.complement_adr_exp = this.formModel.value.complement_adr_expediteur;
    this.obj_expediteur.verif_bp_exp = this.formModel.value.boite_postale_expediteur;
    this.obj_expediteur.bp_exp = this.formModel.value.distrubution_expediteur;

    this.courrier.statut = this.formModel.value.statut;
    this.courrier.statut_envoi = this.formModel.value.statut_envoi;
    this.courrier.type_envoie = this.formModel.value.type_envoie;
    this.courrier.id_opp = this.opportunity_id;
    this.courrier.id_prospect = '';
    this.courrier.id_modele = 3;
    this.courrier.date_envoie = this.currentDateTime;
    this.courrier.obj_destinataire = this.obj_destinataire;
    this.courrier.type_courrier = 'S';
    this.courrier.nbpage_courrier = nbre_page;
    this.courrier.couleur = this.with_color;
    this.apiCommunicationService.SavePostDetails(this.courrier).subscribe((response) => {
      this.saveDataCommunication(response.id_comm, file_location);
    });
  }

  saveDataCommunication(id_courrier: string, file_location: string) {
    const formData = new FormData();
    formData.append('id_courrier', id_courrier);
    formData.append('modele_reel', this.formModel.value.body_content);
    formData.append('file', file_location);
  }

  expandFileName(name: string): string {
    if (name.length < 12) {
      return name;
    } else {
      return name.substring(0, 5) + '...' + name.substring(name.length - 5, name.length);
    }
  }

  showSubMenu($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.closeAllDropDownElements($event);
    const parentNode = $event.target.parentNode;
    const listDropDowns = parentNode.getElementsByClassName('dropdown-menu');
    const elem = listDropDowns[0];
    elem.classList.toggle('show');
  }

  private closeAllDropDownElements($event) {
    const parentNode = $event.target.parentNode;
    const listDropDownss = parentNode.parentNode.getElementsByClassName('dropdown-menu');
    for (let i = 0; i < listDropDownss.length; i++) {
      listDropDownss[i].classList.remove('show');
    }
  }

  getListModelCommunication(media) {
    this.show_loading = true;
    this.apiCommunicationService.listModelWithTypeCommGet(media, 'O').subscribe((data: CommunicationModel[]) => {
      const list: any[] = data.filter((element) => element.id_parent == null);
      list.forEach((element) => (element.childs = this.getChildren(data, element)));
      this.communicationModel = list;
      this.show_loading = false;
    });
  }

  getChildren(array, parent) {
    const list = array.filter((ele) => ele.id_parent === parent.id);
    if (list.length > 0) {
      list.forEach((element) => (element.childs = this.getChildren(array, element)));
    }
    return list;
  }

  setComValue(value: string) {
    this.id_model_courrier = value;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  boitePostale(event: any, type: string) {
    if (event.target.checked && type === 'dest') {
      this.formModel.controls.boite_postale_destinataire.setValue('1');
    } else if (event.target.checked && type === 'exp') {
      this.formModel.controls.boite_postale_expediteur.setValue('1');
    } else {
      this.formModel.controls.boite_postale_destinataire.setValue('0');
      this.formModel.controls.boite_postale_expediteur.setValue('0');
    }
  }
}
