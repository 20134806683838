import { Component, Input, OnInit, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { InformationTicket, priorite, TicketNotification } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { UserList } from 'src/app/entity/User/UserList';
import { Taches } from 'src/app/pages/parametres/gestion-categorie-ticket/_models/Categorie-ticket';
import { ApiTagsService } from 'src/app/services/api-tags.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert2';
import { ApiTasksService } from 'src/app/services/ApiTasks/api-tasks.service';
import { Observable } from 'rxjs';

import { Task } from 'src/app/entity/task';
interface affecteList {
  id: string;
  full_name: string;
}
@Component({
  selector: 'app-add-tasks',
  templateUrl: './add-tasks.component.html',
  styleUrls: ['./add-tasks.component.css'],
})
export class AddTasksComponent implements OnInit, OnChanges {
  @ViewChild('autoService') autoService;
  @ViewChild('autoServicepopup') autoServicepopup;
  @Input() fromType;
  @Input() id_opportunite;
  @Input() allowed_to_update: boolean;
  @Input() allowed_to_update_affaire: boolean;
  fa = fa;
  page: number = 1;
  limit: number = 12;
  totalPages: number;
  tagsList: any[] = [];
  taskDetail: Task = new Task();
  typeTache: string = '';
  searchTaches: string = '';
  isExpand: boolean = false;
  informationTicket = new InformationTicket();
  filteredAffecte: Observable<UserList[]>;
  allTaches: Taches[] = [];
  listTache: TicketNotification[] = [];
  modeAjoutTache: boolean = true;
  priorites: priorite[] = [];
  ListCLientProspect: affecteList[] = [];
  ListUser: any = [];
  accees: boolean;

  constructor(
    private apiUsersService: ApiUsersService,
    private apiTicketsServices: ApiTicketsService,
    private apiTagsServices: ApiTagsService,
    private apiTasksService: ApiTasksService,
    private notificationsService: NotificationsService,
    public apiOpportunitService: ApiOpportunitService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fromType === 'o') {
      this.accees = this.allowed_to_update;
    } else if (this.fromType === 'A') {
      this.accees = this.allowed_to_update_affaire;
    } else {
      this.accees = true;
    }
  }

  ngOnInit(): void {
    if (this.fromType === 'o') {
      this.accees = this.allowed_to_update;
    } else if (this.fromType === 'A') {
      this.accees = this.allowed_to_update_affaire;
    } else {
      this.accees = true;
    }
    this.apiTagsServices.GetListTags('A').subscribe(
      (data) => {
        this.tagsList = data;
      },
      (error) => {
        console.log(error);
      }
    );
    this.apiTasksService.list_prospects().subscribe((data: affecteList[]) => {
      this.ListCLientProspect = data;
    });

    this.apiTasksService.list_priorite().subscribe((data) => {
      this.priorites = data.data;
    });

    this.apiUsersService.getAllUsers().subscribe((data) => {
      data.forEach((item) => {
        const personne = {
          id: item.id,
          full_name: item.nom + ' ' + item.prenom,
        };
        this.ListUser.push(personne);
      });
    });

    this.getlistTache();
  }

  getlistTache() {
    this.apiTicketsServices
      .GetListTachesV1(this.id_opportunite, this.fromType, this.searchTaches, this.page, this.limit)
      .subscribe((data) => {
        this.allTaches = data.list_taches;
        this.calculate_nb_pages(data.total);
      });
  }

  searchTache(event) {
    if (event.target.value.length > 2 || event.target.value.length === 0) {
      this.searchTaches = event.target.value;
      this.page = 1;
      this.getlistTache();
    }
  }

  expandFileName(name: string): string {
    if (name.length < 10) {
      return name;
    } else {
      return name.substring(0, 10) + '...';
    }
  }

  public expandTask() {
    if (this.isExpand === false) {
      this.isExpand = true;
      this.modeAjoutTache = true;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-12');
      box.classList.add('col-md-8');
    }
    this.taskDetail = new Task();
  }

  viewModif(tache: Task, type) {
    this.typeTache = 'supportTicket';
    this.isExpand = true;
    this.modeAjoutTache = false;
    const box = document.getElementById('expand');
    box.classList.remove('col-md-12');
    box.classList.add('col-md-8');
    this.taskDetail = tache;
    this.taskDetail.id_entity = this.id_opportunite;
    this.taskDetail.entity = this.fromType;
  }

  cancelAddOrUpdate(inexpand): void {
    if (inexpand === false) {
      this.isExpand = false;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-7');
      box.classList.add('col-md-12');
    }
  }

  changerStatus(tache) {
    if (tache.statut === 0) {
      tache.statut = 1;
    } else {
      tache.statut = 0;
    }
    this.apiTicketsServices
      .updateTicketNotification(this.id_opportunite, tache.id, tache.statut, this.fromType)
      .subscribe(
        (data) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = data.message;
          this.notificationsService.sendMessage(notificationMessage);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  addTodo(taskk) {
    taskk.id_entity = this.id_opportunite;
    taskk.entity = this.fromType;
    if (this.modeAjoutTache) {
      this.apiTicketsServices.saveNotification(taskk).subscribe(
        (response) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Tâche ajoutée';
          this.notificationsService.sendMessage(notificationMessage);
          this.isExpand = false;
          const box = document.getElementById('expand');
          box.classList.remove('col-md-7');
          box.classList.add('col-md-12');
          this.ngOnInit();
        },
        (err) => {
          console.log(err);
          this.alertError("Erreur lors de l'ajout de la tâche");
        }
      );
    } else {
      this.apiTicketsServices.UpdateTache(taskk).subscribe(
        (response) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Tâche modifiée';
          this.notificationsService.sendMessage(notificationMessage);
          this.isExpand = false;
          const box = document.getElementById('expand');
          box.classList.remove('col-md-7');
          box.classList.add('col-md-12');
          this.ngOnInit();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  setPage(val: number) {
    this.page = val;

    this.getlistTache();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    this.getlistTache();
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: '#68a45b',
      focusConfirm: true,
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  alertWarning() {
    let list_invalid_string = '';
    if (this.informationTicket.affecte_a === '') {
      list_invalid_string =
        list_invalid_string +
        '<li style="list-style-type: none; font-size: 14px"><u>Champ:</u> ' +
        ' Affecté à ' +
        '</li>';
    }
    if (this.informationTicket.sujet === '') {
      list_invalid_string =
        list_invalid_string + '<li style="list-style-type: none; font-size: 14px"><u>Champ:</u> ' + ' Sujet' + '</li>';
    }
    swal.fire({
      title: 'Il faut remplir les champs obligatoires',
      icon: 'warning',
      html: '<ul style="margin-top: 13px;padding: 0px;">' + list_invalid_string + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e99954',
    });
  }
}
