<form (keyup.enter)="submitFilterEnter($event)">
    <div class="filter-bubble">
      <div class="row">
        <div class="col">
          <div class="filter-bubble-icon" (click)="toggleFilterCorp()">
            <fa-icon [icon]="faSearch"></fa-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
      <div class="filter-close-corp-bubble" (click)="toggleFilterCorp()">
        <div class="close-element">
          <fa-icon [icon]="faPlus"></fa-icon>
        </div>
      </div>
      <div class="filter-elements">
        <div class="row">
          <div class="col-6">
            <div class="filter-bubble-icon2" (click)="toggleFilterCorp()">
              <fa-icon [icon]="faSearch"></fa-icon>
            </div>
            <div class="Group_486">
              <div class="FILTRES__RECHERCHES">
                <span>PLUS DE FILTRES</span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-3">
                <button class="btn-load icon-load submit-load-btn" (click)="resetForm()" >
                  <fa-icon class="icon-load" [icon]="faSyncAlt"></fa-icon>
  
                </button>
              </div>
              <div class="col-9">
                <button type="submit" class="btn btn-primary submit-btn" (click)="submitFilter()">
                  <span>
                    <fa-icon class="Rectangle_111" [icon]="faCheckCircle"></fa-icon>
                  </span>
                  Lancer la recherche </button>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="scrolling">
          <div class="row mb-1" >
            <div class="col-12">
              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="row w-100" >
                        <div class="col-5 pr"></div>
                        <div class="col-4 pl">
                          <div class="Group_2264">
                            <span class="Rectangle_110">
                              <fa-icon class="fa-lg mr-2" [icon]="fa['faBriefcase']" width="28px"></fa-icon>

                            </span>
                            <div class="Opportunits">
                              <span>Affaire</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-3"></div>
                      </div>
  
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row mt-2" >
                 <app-filtre-affaires (returnToparent)="getResetAffaire($event)" (onPushFilterAffaire)="EmitFilterAffaire($event)" [resetAffaire]="resetAffaire"></app-filtre-affaires>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
  
          <div class="row mb-1 w-100" >
            <div class="col-12">
              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="row w-100" >
                        <div class="col-5 pr" ></div>
                        <div class="col-4 pl" >
                          <div class="Group_2264">
                            <span class="Rectangle_110">
                              <fa-icon class="fa-lg mr-2" [icon]="fa['faMoneyCheckAlt']" width="28px"></fa-icon>

                            </span>
                            <div class="Opportunits">
                              <span>Echéance</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-3"></div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row mt-2 w-100">
                 <app-filter-echeance-echeance (returnToparent)="getResetEcheance($event)" [resetEcheance]="resetEcheance" (onPushFilterEcheance)="EmitFilterEcheance($event)"></app-filter-echeance-echeance>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
  
          <div class="row mb-1" >
            <div class="col-12">
              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="row w-100" >
                        <div class="col-5 pr" ></div>
                        <div class="col-4 pl" >
                          <div class="Group_2264">
                            <span class="Rectangle_110">
                              <fa-icon class="fa-lg" [icon]="fa['faMoneyCheck']"></fa-icon>

                            </span>
                            <div class="Opportunits">
                              <span>Echéancier</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-3"></div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="row mt-2 w-100" >

                    <app-filtre-echeancier (returnToparent)="getResetEcheancier($event)" [resetEcheancier]="resetEcheancier"  (onPushFilterEcheancier)="EmitFilterEcheancier($event)"></app-filtre-echeancier>
                 </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

  
        </div>
  
      </div>
  
  
    </div>
  </form>