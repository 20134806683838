export class Parrainage {
  id_prospect: string = "";
  relation_parrainage: string = "";
  nom_parrainage: string = "";
  type_opp: string = "";
  id_campagne: string = "";
  prenom_parrainage: string = "";
  tel_parrainage: string = "";
  mail_parrainage: string = "";
  ville_parrainage: string = "";
  cp_parrainage: string = "";
  comm_parrainage: string = "";

  constructor(init?: Partial<Parrainage>) {
    Object.assign(this, init);
  }
}
