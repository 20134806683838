<app-add-communication 
        [affaire_id]="affaire_id" 
        [id_opportunite]="opportunity_id" 
        [listParent]="listParent"
        [listTypes]="listTypes"
        [type_envoie]='type_envoie'
        [statut_envoi]="statut_envoi"
        [statut]="statut"
        [prospect]="prospect"
        [telNumber]="telNumber"  
        [fromtype]="fromtype" 
        [allowed_to_update]="allowed_to_update"
        >
</app-add-communication> 