<div class="row">
    <div class=" position-fixed " style="z-index: 5;">
        <app-filter-affaire-global (onSubmitFilter)="getDataValue($event)" (onPushFilters)="EmitFilterAffaire($event)">
        </app-filter-affaire-global>
    </div>
</div>
<div class="row">
    <!-- Datatables -->
    <div class="col-lg-12">
        <div class="card mb-4 border-0" style="border-radius: 13px;">

            <div class="row">
                <div class="col-3 mt-4">
                    <button type="button" class="btn-dt-add  ml-4" (click)="AddAffaire()" data-title="Ajouter une affaire">
                          <fa-icon class="icon-btn-add" [icon]="fa['faPlus']"></fa-icon>
                        </button>
                </div>
                <div class="col-5 mt-2">
                    <div class="row">
                        <div class="col-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Type date</mat-label>
                                <mat-select [(value)]="selected" (change)="quickFilterChange()">
                                    <mat-option value="effet">
                                        Date d'Effet
                                    </mat-option>
                                    <mat-option value="Creation">
                                        Date de Création
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'label.debutdate' | translate}}</mat-label>
                                <input matInput [matDatepicker]="pickerDebut" id="date_debut" name="date_debut" [(ngModel)]="date_debut" [value]="date_debut">
                                <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                                <mat-datepicker #pickerDebut></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>{{'label.enddate' | translate}} </mat-label>
                                <input matInput [matDatepicker]="pickerFin" id="date_fin" name="date_fin" [(ngModel)]="date_fin" [value]="date_fin">
                                <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                                <mat-datepicker #pickerFin></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-3 w-100 pl-0 mt-3">
                            <button type="button" class="btn-dt-filter" (click)="getDataValue('')">
                              <fa-icon class="fa-fw" [icon]="fa['faFilter']"></fa-icon>&nbsp;
                              Filtrer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-4 mt-2 p-2 text-right">
                    <input class="search" name="" [(ngModel)]="sSearch" (keypress)="getDataValuee($event)" placeholder="Recherche...">
                    <fa-icon aria-hidden="true" class="icon-search fa-md" [icon]="fa['faSearch']"></fa-icon>
                </div>
            </div>

            <div class="row" *ngIf="actionGroupeAccess">
                <!--     <button  type="button" (click)="updateAffaire()" class="btn-keygen ml-4" id="keygen" style="background-color:#fe8c96; width: 150px;">
                  Actions groupées
                </button> -->
                <button type="button" class="btn-dt-actions-groupee ml-4" (click)="updateAffaire()" [disabled]="!actionButton" [ngStyle]="{'background-color': actionButton ? 'rgb(61, 102, 188)' : '#dedfdf','color' :actionButton ? 'white' : '#727677'}">
                    Actions Groupées
                  </button>
            </div>
            <div class="table-responsive p-3">
                <table class="table align-items-center table-flush" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th>
                                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()" />
                            </th>
                            <th>{{'affaires.label.actions' | translate}}
                            </th>
                            <th (click)="setSortField('affaire_date_creation')">{{'affaires.label.creation' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='affaire_date_creation','sort-down': sort_type == 'desc' && sort_field == 'affaire_date_creation', 'sort-active': sort_field=='affaire_date_creation'}">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']">
                                    </fa-icon>
                                </div>
                            </th>
                            <th (click)="setSortField('prospects_name')">{{'affaires.label.client' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='prospects_name','sort-down': sort_type == 'desc' && sort_field == 'prospects_name', 'sort-active': sort_field=='prospects_name'}">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']">
                                    </fa-icon>
                                </div>
                            </th>
                            <th (click)="setSortField('affaire_mca')">{{'affaires.label.frais' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='affaire_mca','sort-down': sort_type == 'desc' && sort_field == 'affaire_mca', 'sort-active': sort_field=='affaire_mca'}">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']">
                                    </fa-icon>
                                </div>
                            </th>
                            <th (click)="setSortField('cmp_denomination')">{{'affaires.label.cie' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='cmp_denomination','sort-down': sort_type == 'desc' && sort_field == 'cmp_denomination', 'sort-active': sort_field=='cmp_denomination'}">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']">
                                    </fa-icon>
                                </div>
                            </th>
                            <th>Produit </th>
                            <!--      <th (click)="setSortField('affaire_produit_contrat')">{{'affaires.label.produit' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='affaire_produit_contrat','sort-down': sort_type == 'desc' && sort_field == 'affaire_produit_contrat', 'sort-active': sort_field=='affaire_produit_contrat'}">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']">
                                    </fa-icon>
                                </div>
                            </th>-->



                            <th (click)="setSortField('affaire_date_deff')">{{'affaires.label.dateeffet' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='affaire_date_deff','sort-down': sort_type == 'desc' && sort_field == 'affaire_date_deff', 'sort-active': sort_field=='affaire_date_deff'}">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']">
                                    </fa-icon>
                                </div>
                            </th>
                            <th (click)="setSortField('etat')">
                                {{'affaires.label.status' | translate}}
                                <div class="d-inline-block" [ngClass]="{'sort-up': sort_type == 'asc' && sort_field=='etat','sort-down': sort_type == 'desc' && sort_field == 'etat', 'sort-active': sort_field=='etat'}">
                                    <fa-icon class="fa-lg ml-2" [icon]="fa['faSortAmountDownAlt']">
                                    </fa-icon>
                                </div>
                            </th>
                            <th>Mots-clés</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let affaires of affairesList.aaData; let i = index">
                            <td style="width: 10px;">
                                <div class="mt-2">
                                    <label class="container">
                                      <input type="checkbox" [(ngModel)]="affaires.isSelected" name="list_name" value="{{affaires.id}}"
                                      (change)="isAllSelected()">
                                    </label>

                                </div>
                            </td>
                            <td style="width: 90px;">
                                <div class="d-flex justify-content-center">
                                    <div class="pt-1">
                                        <button type="button" class="btn-dt-list" data-toggle="modal" data-target="#ActionsPhone" (click)="getPhoneList(affaires.id_prospect)">
                                                <img src="/assets/icons/phone-icon.svg">
                                            </button>
                                    </div>
                                    <div class="pt-1">
                                        <a type="button" class="btn-dt-list" (click)="isEdit=true" routerLink="/affaires/details/{{affaires.affaire_id}}/">
                                            <img src="/assets/icons/edit-icon.svg">
                                        </a>
                                    </div>
                                    <div>
                                        <input type='checkbox' id="permanent_{{i}}" title="Add Favoris" class="star" [checked]="affaires.add_to_fav" />
                                        <label for="permanent_{{i}}" (click)="add_suivi_affaire(affaires.affaire_id)"></label>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 150px;">{{affaires.affaire_date_creation}}</td>
                            <td style="width: 150px;">{{affaires.prospects_name}} {{affaires.prospects_surname}}
                            </td>
                            <td style="width: 190px;">
                                <span>
                                        {{affaires.affaire_mca | currency:currency:'symbol':formatCurrency:'fr-FR'}}
                                    </span>
                                <!-- <fa-icon style="font-size: 12px;" [icon]="fa['faEuroSign']"></fa-icon> -->
                            </td>
                            <td>{{affaires.cmp_denomination}}</td>
                            <td style="width: 220px;">
                                {{affaires.contrat_type}}<br> {{affaires.nom_produit}}
                            </td>


                            <!--  <td>{{affaires.contrat_type}}</td> -->
                            <td width="150px;">{{affaires.affaire_date_deff }}
                            </td>
                            <td width="150px;">
                                <div class="btn-status d-flex justify-content-center" [style.background]="affaires.etat_background" [style.color]="affaires.etat_color">{{affaires.etat}}</div>
                            </td>
                            <td width="290px;" *ngIf="affaires.tags_list !==null">
                                <div class="tagAffaire pb-1" id="tagsList">
                                    <mat-chip-set class="example-chip ">
                                        <mat-chip class="example-box mr-2 mt-2" [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}" *ngFor="let tag of affaires.tags_list; let index = index ;  let first = first;">
                                            {{tag.libelle}}
                                        </mat-chip>
                                    </mat-chip-set>
                                </div>


                            </td>
                            <td *ngIf="affaires.tags_list == null">-</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
        <div class="navbar-center row mb-4">
            <div class="col pt-1">
                <div class="row w-50 flex-nowrap">
                    <div class="col mt-1">
                        <p>{{'label.show' | translate}}</p>
                    </div>
                    <div class="col mx-1">
                        <select [(ngModel)]="limit" (change)="updateListAffaires()" class="bg-white border pt-1 rounded font-weight-bold">
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                    </div>
                    <div class="col mt-1">
                        <p>{{'label.entries' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <nav aria-label="Page navigation example" *ngIf="totalPages>0">
                    <ul class="pagination pg-blue">
                        <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Previous">
                                <i class="fa fa-chevron-left "></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page-3>0" class="page-item">
                                <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page - 3}}</a>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let item of [].constructor(3); let i = index">
                            <li *ngIf="i+page<totalPages" class="page-item">
                                <a (click)="changepage(i+page)" class="page-link mt-2 border rounded" [ngClass]="{'active-page':page==(i+page)}">
                      {{i + page}}
                    </a>
                            </li>
                        </ng-container>
                        <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                        <li class="page-item">
                            <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded" [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                        </li>
                        <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                            <a class="page-link-symbol-chevron" aria-label="Next">
                                <i class="fa fa-chevron-right"></i>
                            </a>
                        </li>
                        <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                            <a class="page-link-symbol">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <div class="row position-absolute flex-nowrap" style="right: 0px">
                    <div class="col text-nowrap text-right mt-2">
                        <p>{{'label.goto' | translate}}</p>
                    </div>
                    <div class="col mt-1 ml-2">
                        <input [(ngModel)]="intervale" size="1" min="1" onkeypress="return event.charCode >= 48" (keyup.enter)="getchangeLimite()" (change)="getchangeLimite()" type="number" style="margin-left: 4px;" size="1" class="page_input bg-white border rounded font-weight-bold"
                            [value]="page" max="{{totalPages}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--------------------------------Action Phone----------------------------------->
<div class="modal fade" id="ActionsPhone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
                <fa-icon class="  fa-2x" [icon]="fa['faPhoneAlt']"></fa-icon>
                <h2 style="margin-left: 15px">{{'opportunities.label.phoneactions' | translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12 loader-spinner">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="list_tel.mobile!= null && list_tel.mobile!=''">
                            <div class="col-3 text-center">
                                {{'opportunities.label.mobile' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.mobile}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.mobile)">
                  <fa-icon class="  fa-lg " style="margin-left: 5px; color: #43ac43;"
                    [icon]="fa['faPhoneAlt']"></fa-icon>
                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel!= null && list_tel.tel!=''">
                            <div class="col-3 text-center">
                                {{'opportunities.label.phone' | translate}}
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel}}
                            </div>
                            <div class="col-3 text-center">

                                <button class="btn" (click)="callVoip(list_tel.tel)">
                  <fa-icon class="  fa-lg " style="margin-left: 5px; color: #43ac43;"
                    [icon]="fa['faPhoneAlt']"></fa-icon>
                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="list_tel.tel2!= null && list_tel.tel2!=''">
                            <div class="col-3 text-center">
                                {{'opportunities.label.phone' | translate}} 2
                            </div>
                            <div class="col-6 text-center">
                                {{this.list_tel.tel2}}
                            </div>
                            <div class="col-3 text-center">
                                <button class="btn" (click)="callVoip(list_tel.tel2)">
                  <fa-icon class="  fa-lg " style="margin-left: 5px; color: #43ac43;"
                    [icon]="fa['faPhoneAlt']"></fa-icon>
                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <button class="btn-style" data-dismiss="modal">{{'opportunities.label.close' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--------------------------------------------------------------------------------------->
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Doublons Probables</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <table class="table align-items-center table-flush" id="dataTable">
                    <thead class="thead-light">
                        <tr>
                            <th>Fiche Prospect</th>
                            <th>Nom</th>
                            <th>Prenom</th>
                            <th>Opportunités/Affaires</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                            <td>
                                <button class="btn"><img src="../../../../assets/img/opportunites/defaults/share.png" alt=""></button>
                            </td>
                            <td>Atef</td>
                            <td>Badri</td>
                            <td>aaaaaaaaaaaaaaaaaaaa</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"><img
            src="../../../../assets/img/opportunites/defaults/fusion.png" alt="" width="30" height="25">Fusionner
        </button>
            </div>
        </div>
    </div>
</div>
