import {ListProspect, Prospect} from '../Prospect';
import {Entreprise} from './Entreprise';
import {Campagne} from './Campagne';
import {Details} from './Details';
import {Calendar} from './Calendar';

export class OpportunityAdd{
  prospect: any;
  campagne: Campagne= new Campagne();
  details: Details = new Details();
  calendar: Calendar = new Calendar();

  public isValid(): any[]{
    let prospect_valid=true;
    let list_invalid=[]
    this.prospect?.listProspects.forEach(prospect=>{if(prospect.invalidForms.length){ prospect_valid =false; list_invalid.push(prospect.invalidForms)}})
    if(!prospect_valid) return list_invalid;
    if(this.prospect?.invalidForms?.length) {
      list_invalid.push(this.prospect?.invalidForms);
      return list_invalid;
    };
    if(this.campagne?.invalidForms.length) list_invalid.push(this.campagne?.invalidForms)
    if(this.calendar?.invalidForms.length) list_invalid.push(this.calendar?.invalidForms)
    if(this.prospect?.mainProspect.invalidForms.length) list_invalid.push(this.prospect?.mainProspect.invalidForms)
    return list_invalid;
  }
}
