<div class="card m-5 border-0">
  <div class="row card-head">
    <div class="col-3">
      <button type="button" class="btn-dt-retour"  (click)="returnToMaileva()">
        <div class="icon-position">
          <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        </div>
        <span> Retour à la liste </span>
      </button>
    </div>
    <div class="col-6 text-center">
      <span class="d-inline-block" tabindex="0">
        <label class="label-status d-flex justify-content-center align-items-center border-0"
        [ngStyle]="{'background':maileva?.statut_details?.background}"
        data-toggle="tooltip" title="{{maileva?.statut_details?.description}}">
          <span [ngStyle]="{'color':maileva.statut_details?.color}">
            {{maileva?.statut_details?.libelle.split('(')[0].trim()}}
          </span>
        </label>
      </span>
    </div>
    <div class="col-3">
      <button type="button" class="btn-dt-save float-right" *ngIf="maileva?.approuved == false" (click)="approve()">
        <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
          Approuver
      </button>
    </div>
  </div>
  <hr>
  <div class="row mt-2">
    <div class="col-12">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h2><b>Identifiant du Courrier : </b></h2>
          <p class="ml-2"> {{maileva?.id}}</p>
        </div>

        <div class="col-6 d-flex align-items-center">
          <h2><b>Créé le : </b></h2>
          <p class="ml-2"> {{maileva?.date | date:'dd-MM-yyyy HH:mm'}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body2 row mt-3">
    <div class="col-12 mt-3">
      <h3 class="titre">Détails Courrier</h3>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="title">Nom & Prénom du Créateur</label>
        <h4 *ngIf="maileva?.nomprenom_exp"><b>{{maileva?.nomprenom_exp}}</b></h4>
        <h4 *ngIf="!maileva?.nomprenom_exp"><b>--</b></h4>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label class="title">Modèle</label>
        <h4 *ngIf="maileva?.modele_nom"><b>{{maileva?.modele_nom}}</b></h4>
        <h4 *ngIf="!maileva?.modele_nom"><b>--</b></h4>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label class="title">Réference maileva</label>
        <a target="_blank " href="maileva?.notif_link">
          <h4 *ngIf="maileva?.ref_notif"><b>{{maileva?.ref_notif}}</b>
            <fa-icon class="mr-2" [icon]="fa['faLink']"></fa-icon>
          </h4>
        </a>
        <h4 *ngIf="!maileva?.ref_notif"><b>--</b></h4>

      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="title">Type d'Envoie</label>
        <h4 *ngIf="maileva?.type_envoie"><b>{{maileva?.type_envoie}}</b></h4>
        <h4 *ngIf="!maileva?.type_envoie"><b>--</b></h4>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="title">Type D'Enveloppe</label>
        <h4 *ngIf="maileva?.type_envelope"><b>{{maileva?.type_envelope}}</b></h4>
        <h4 *ngIf="!maileva?.type_envelope"><b>--</b></h4>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="title">Couleur</label>
        <h4 *ngIf="maileva?.couleur"><b>{{maileva?.couleur}}</b></h4>
        <h4 *ngIf="!maileva?.couleur"><b>--</b></h4>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="title">Impression recto-verso</label>
        <h4><b>{{maileva?.recto_verso ? 'Oui' : 'Non'}}</b></h4>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="title">Date d'affranchissement</label>
        <h4 *ngIf="maileva?.date_livraison"><b>{{maileva?.date_livraison}}</b></h4>
        <h4 *ngIf="!maileva?.date_livraison"><b>--</b></h4>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label class="title">
          Date d'Envoi Planifié
        </label>
        <div class="row">
          <div class="col-1-mg-2" *ngIf="!showPlanifier" (click)="onclickPlanifier()">
            <fa-icon [icon]="fa['faPen']" style="color: #383838;"></fa-icon>
          </div>

          <h4 *ngIf="!showPlanifier"><b>{{datepalanifier | date: "dd-MM-yyyy"}}</b></h4>

          <div *ngIf="showPlanifier">
            <input type="date" class="form-control date-picker" id="date_debut" name="date_debut"
              [ngModel]="datepalanifier | date:'dd-MM-yyyy'" (ngModelChange)="datepalanifier = $event"
              [value]="datepalanifier | date:'dd-MM-yyyy'">
            <button class="btn btn-primary mt-4 mr-1" style="width: 45%;"
              (click)="updateDateplanifie()">
              <fa-icon [icon]="fa['faCheck']"></fa-icon>
            </button>
            <button class="btn btn-danger mt-4 mr-1" style="width: 45%;"
              (click)="onclickPlanifier()">
              <fa-icon [icon]="fa['faTimes']"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label class="title">Nombre total de pages</label>
        <h4 *ngIf="maileva?.nbr_page"><b>{{maileva?.nbr_page}}</b></h4>
        <h4 *ngIf="!maileva?.nbr_page"><b>--</b></h4>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label class="title">Nombre de pages facturés</label>
        <h4 *ngIf="maileva?.nbr_page"><b>{{maileva?.nbr_page -1 }}</b></h4>
        <h4 *ngIf="!maileva?.nbr_page"><b>--</b></h4>
      </div>
    </div>


    <div class="col-3">
      <div class="form-group">
        <label class="title">Tarif Courrier</label>
        <h4 *ngIf="maileva?.tarif_suppl"><b>{{maileva?.tarif_suppl}}€</b></h4>
        <h4 *ngIf="!maileva?.tarif_suppl"><b>--</b></h4>
      </div>
    </div>

    <div class="row col-12" *ngIf="showReceptionRow()">
      <div class="col-6" style="margin-left: -15px;" *ngIf="maileva?.avis_reception!=null">
        <div class="form-group">
          <label class="title">Avis de Réception</label>
          <a *ngIf="maileva?.avis_reception" href="{{maileva?.avis_reception}}" target="_blank">
            <button class="btn-doc">
              <div class="row">
                <div class="col-1" style=" position: absolute;">
                  <fa-icon [icon]="fa['faFileArchive']" style="font-size: 20px;"></fa-icon>
                </div>
                <div class="col-10">Avis de Réception</div>
                <div class="col-1" style=" position: relative;">
                  <fa-icon [icon]="fa['faEdit']" style="font-size: 20px; "></fa-icon>
                </div>
              </div>
            </button>
          </a>
          <h4 *ngIf="!maileva?.avis_reception"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title">Date Réception</label>
          <h4 *ngIf="maileva?.ar_date"><b>{{maileva?.ar_date}}</b></h4>
          <h4 *ngIf="!maileva?.ar_date"><b>--</b></h4>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label class="title"></label>
        </div>
      </div>
    </div>
    <div class="row col-12" *ngIf="showPreuveRow()">
      <div class="col-6">
        <div class="form-group" style="margin-left: -15px;">

          <label class="title">Preuve électronique</label>
          <a *ngIf="maileva?.preuve_attachement" href="{{maileva?.preuve_attachement}}" target="_blank">
            <button class="btn-doc">
              <div class="row">
                <div class="col-1" style=" position: absolute;">
                  <fa-icon [icon]="fa['faFileArchive']" style="font-size: 20px;"></fa-icon>
                </div>
                <div class="col-10">Preuve électronique</div>
                <div class="col-1" style=" position: relative;">
                  <fa-icon [icon]="fa['faEdit']" style="font-size: 20px; "></fa-icon>
                </div>
              </div>
            </button>
          </a>
          <h4 *ngIf="!maileva?.preuve_attachement"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title">Date récepection preuve électronique</label>
          <h4 *ngIf="maileva?.preuve_date"><b>{{maileva?.preuve_date}}</b></h4>
          <h4 *ngIf="!maileva?.preuve_date"><b>--</b></h4>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group">
          <label class="title"> Code preuve électronique</label>
          <h4 *ngIf="maileva?.preuve_code"><b>{{maileva?.preuve_code}}</b></h4>
          <h4 *ngIf="!maileva?.preuve_code"><b>--</b></h4>
        </div>
      </div>

    </div>


    <div class="col-6">
      <div class="form-group">

        <label class="title">Document Envoyé</label>
        <a *ngIf="maileva?.file_courrier" href="{{maileva?.file_courrier}}" target="_blank">
          <button class="btn-doc">
            <div class="row">
              <div class="col-1" style=" position: absolute;">
                <fa-icon [icon]="fa['faFilePdf']" style="font-size: 20px;"></fa-icon>
              </div>
              <div class="col-10">Document Envoyé</div>
              <div class="col-1" style=" position: relative;">

              </div>
            </div>
          </button>
        </a>
        <h4 *ngIf="!maileva?.file_courrier"><b>--</b></h4>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label class="title"> </label>
        <!-- <h4> {{maileva?.approuved ? 'Oui' : 'Non'}} </h4> -->
      </div>
    </div>

    <div class="col-3" *ngIf="maileva?.statut_details?.code =='N' ">
      <div class="form-group">
        <label class="title"></label>
        <div></div>
        <h4 class="statut-envoieRenvoye text-center">
          <div class="row" (click)="resendMaileva()">
            <div class="col-2">
              <fa-icon class="mr-2" [icon]="fa['faPaperPlane']"></fa-icon>
            </div>
            <div class="col">
              <b>Ré-Envoyer</b>
            </div>
          </div>
        </h4>
      </div>
    </div>
    <div class="col-3" style="margin-top:10px;" *ngIf="maileva?.statut_details?.code ==null">
      <div class="form-group">
        <label class="title"></label>
        <div></div>
        <h4 class="statut-envoieCancel text-center">
          <div class="row" (click)="cancelMaileva()">
            <div class="col-2">
              <fa-icon [icon]="fa['faTrash']"></fa-icon>
            </div>
            <div class="col">
              <b>Annuler</b>
            </div>
          </div>
        </h4>
      </div>
    </div>
  </div>


  <div class="row mt-4" style="margin-right: -12px; margin-left: -12px;">
    <div class="col-6">
      <div class="card-body ">
        <h3 class="row titre">Détails Expéditeur</h3>
        <div class="row">
          <div class="col-6 ">
            <div class="form-group">
              <label class="title">Nom & Prénom</label>
              <h4><b>{{maileva?.nomprenom_exp}}</b></h4>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="title">Adresse Expéditeur</label>
              <h4><b>
                {{maileva?.donn_exp}} {{ maileva?.postal_code_exp }} {{ maileva?.ville_exp }} {{
                  maileva?.bp_adresse_exp }}
                  {{ maileva?.complement_adr_exp }}
              </b></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card-body ">
        <h3 class="row titre">Détails Destinataire</h3>
        <div class="row">
          <div class="col-6 ">
            <div class="form-group">
              <label class="title">Nom & Prénom</label>
              <h4><b>{{maileva?.nomprenom_dest}}</b></h4>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="title">Adresse Destinataire</label>
              <h4><b>
                {{maileva?.donn_prospect}} {{ maileva?.postal_code_post }} {{ maileva?.ville_dest }} {{
                  maileva?.bp_adresse
                  }} {{ maileva?.complement_adr_dest }}
              </b></h4>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="col-12 d-flex justify-content-center mt-4"
      *ngIf="maileva.id_affaire != null || maileva.url_dossier != null || maileva.id_opp != null">
      <div class="card-body">
        <div class=" row">
          <h3 class="titre">Affaire / Opportunité </h3>
        </div>
        <div class="row" style="margin-left:12%;">
          <div class="col " *ngIf="maileva.id_affaire!=null">
            <div class="form-group">
              <label class="title">Lien affaire</label>
              <button  type="button" class="btn-dt-details" (click)="router.navigate(['/affaires/details/'+maileva.id_affaire])">
                <div class="icon-position">
                  <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                </div>
                <span> Affaire </span>
              </button>
            </div>
          </div>
          <div class="col" *ngIf="maileva?.id_opp!=null">
            <div class="form-group">
              <label class="title">Lien opportunité</label>
              <button  type="button" class="btn-dt-details" (click)="router.navigate(['/opportunities/details/'+maileva.id_opp])">
                <div class="icon-position">
                  <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                </div>
                <span> Opportunité </span>
              </button>
            </div>
          </div>
          <div class="col" *ngIf="maileva?.url_dossier!=null">
            <div class="form-group">
              <label class="title">Lien Dossier</label>
              <button  type="button" class="btn-dt-details" (click)="router.navigate(['maileva?.url_dossier'])">
                <div class="icon-position">
                  <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                </div>
                <span> Dossier </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>



<div class="card-historiques bg-white m-5 p-4">
  <div class="card-doc-title ml-3 row" >
    <fa-icon class="mr-2 fa-lg"  [icon]="fa['faHistory']" ></fa-icon>
    <h4> Historiques de Courrier</h4>
  </div>
  <mat-form-field style="width: 100%;">
    <input matInput (keyup)="applyFilter($event)" placeholder="Recherche..." #input>
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table mat-table class="w-100"  [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  > Date </th>
        <td mat-cell *matCellDef="let row" > {{row.date_action | date:'dd-MM-yyyy HH:mm'}} </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Utilisateur</th>
        <td mat-cell *matCellDef="let row" > {{row.mail_user}} </td>
      </ng-container>
      <ng-container matColumnDef="commentaire">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Commentaire</th>
        <td mat-cell *matCellDef="let row" > {{row.commentaire}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3">Pas de données disponibles </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>