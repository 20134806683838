import { Component, OnInit } from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-password-type',
  templateUrl: './password-type.component.html',
  styleUrls: ['./password-type.component.scss']
})
export class PasswordTypeComponent extends FieldType implements OnInit {


  ngOnInit(): void {
  }

}
