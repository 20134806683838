import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
} from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { DocumentProduit } from 'src/app/entity/Bpm/LigneProduits';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import swal from 'sweetalert2';

@Component({
  selector: 'shared-list-documents',
  templateUrl: './shared-list-documents.component.html',
  styleUrls: ['./shared-list-documents.component.css'],
})
export class SharedListDocumentsComponent implements OnInit, OnChanges {
  @Output() onShareListDocument: EventEmitter<DocumentProduit[]> = new EventEmitter();
  @Input() documentList: DocumentProduit[];
  @Input() defaultDocumentList: DocumentProduit[];
  fa = fa;
  formDocument: FormGroup;
  typeDocuments: DocumentProduit[] = [];
  id: string;
  edit: boolean = false;
  loaderListDocument: boolean = false;
  public docAutoComplete$: Observable<DocumentProduit[]> = null;
  dataTable: any;
  accee: boolean;

  constructor(private apiAdminBpmServices: ApiAdminBpmService, private chRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.typeDocuments = this.documentList;
  }

  ngOnInit(): void {
    this.formDocument = new FormGroup({
      documentText: new FormControl(),
      documentTextId: new FormControl(),
    });
    this.docAutoComplete$ = this.formDocument.controls.documentText.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => {
        if (value !== '') {
          return this.getListTypeDocument();
        } else {
          // if no value is present, return null
          return of(null);
        }
      })
    );
  }

  alertDelete(idDoc, index) {
    swal
      .fire({
        title: 'Voulez-vous vraiment supprimer?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.typeDocuments.splice(index, 1);
        }
      });
  }

  getListTypeDocument(): Observable<DocumentProduit[]> {
    this.loaderListDocument = true;
    return this.apiAdminBpmServices.rechercheTypeDocument(this.formDocument.value.documentText.toLowerCase()).pipe(
      map((res) => {
        this.typeDocuments.forEach(function (item) {
          const index: number = res.type_doc_list.findIndex((i) => i.id === item.id);
          if (index !== -1) {
            res.type_doc_list.splice(index, 1);
          }
        });
        this.loaderListDocument = false;
        return res.type_doc_list;
      }),
      catchError((_) => {
        this.loaderListDocument = false;
        return of(null);
      })
    );
  }

  getDocumentInfo(id, index) {
    this.alertDelete(id, index);
  }

  onSelectDoc(document: DocumentProduit) {
    this.formDocument.controls.documentTextId.setValue(document.id);
    const documentproduit = new DocumentProduit();
    documentproduit.libelle = document.libelle;
    documentproduit.id = document.id;
    documentproduit.real_id = document.real_id;
    documentproduit.oblig = document.oblig !== undefined ? document.oblig : 0;
    this.pushInfo(documentproduit);
    this.formDocument.controls.documentText.reset();
  }

  pushInfo(document: DocumentProduit) {
    const table: any = $('#TableSharedDocument');

    table.dataTable().fnDestroy();
    this.typeDocuments.unshift(document);
    this.onShareListDocument.emit(this.typeDocuments);
    this.loadtable();
    this.edit = true;
    table.dataTable().fnDestroy();
  }

  isObligatoire(event, item) {
    if (event.target.checked) {
      item.oblig = '1';
    } else {
      item.oblig = '0';
    }
  }

  loadtable() {
    this.chRef.detectChanges();
    const table: any = $('#TableSharedDocument');
    this.dataTable = table.DataTable({
      language: {
        sProcessing: 'Traitement en cours...',
        searchPlaceholder: ' Recherche...',
        sSearch: '<p aria-hidden="true"></p>',
        sLengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
        sInfo: "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
        sInfoEmpty: "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
        sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
        sInfoPostFix: '',
        sLoadingRecords: 'Chargement en cours...',
        sZeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
        sEmptyTable: 'Aucune donn&eacute;e disponible dans le tableau',
        oPaginate: {
          sFirst: 'Premier',
          sPrevious: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
          sNext: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
          sLast: 'Dernier',
        },
        oAria: {
          sSortAscending: ': activer pour trier la colonne par ordre croissant',
          sSortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant',
        },
        select: {
          rows: {
            _: '%d lignes séléctionnées',
            0: 'Aucune ligne séléctionnée',
            1: '1 ligne séléctionnée',
          },
        },
        data: 'UserFlag',
        className: 'table-cell-edit',
      },
    });
  }
}
