import { Entreprise } from '../Entreprise';
import { Campagne } from '../Campagne';
import { DetailsVelo } from './Details';
import { Calendar } from '../Calendar';
import { Interlocuteur } from './Interlocuteur';

import { Prospect } from '../../Prospect';
export class ProspectVelo {
  mainProspect: Prospect = new Prospect();
  listProspects: Prospect[] = [];
  siren?: string;
  RS?: string;
  typeProspect?: string;
  type_opportunite?: string;
  adress?: Address;
}

export class OpportunityAddVelo {
  prospect: ProspectVelo = new ProspectVelo();
  campagne: Campagne = new Campagne();
  details: DetailsVelo = new DetailsVelo();
  calendar: Calendar = new Calendar();
  interlocuteur: Interlocuteur = new Interlocuteur();
}
export class Address {
postalCode : string
streetNumber : string;
streetName : string;
ville : string;
}
