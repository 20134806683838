import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationMessage, NotificationType } from '../../entity/NotificationMessage';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private notificationSubject: Subject<NotificationMessage> = new Subject<NotificationMessage>();

  sendMessage(message: NotificationMessage) {
    this.notificationSubject.next(message);
  }

  constructor(private toaster: ToastrService) {
    this.notificationSubject.subscribe(
      (message) => {
        switch (message.type) {
          case NotificationType.success:
            this.toaster.success(message.message, message.title, { timeOut: message.timeOut || 3000 });
            break;
          case NotificationType.error:
            this.toaster.error(message.message, message.title, { timeOut: message.timeOut || 3000 });
            break;
          case NotificationType.info:
            this.toaster.info(message.message, message.title, { timeOut: message.timeOut || 3000 });
            break;
          case NotificationType.warning:
            this.toaster.warning(message.message, message.title, { timeOut: message.timeOut || 3000 });
            break;
        }
      },
      () => console.log('Error when processing toaster message')
    );
  }
}
