import { Component, OnInit } from '@angular/core';
import {FieldType, FormlyFieldConfig} from "@ngx-formly/core";

@Component({
  selector: 'app-formly-field-stepper',
  templateUrl: './formly-field-stepper.component.html',
  styleUrls: ['./formly-field-stepper.component.css']
})
export class FormlyFieldStepperComponent extends FieldType implements OnInit {
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup.every(f => this.isValid(f));
  }

  ngOnInit(): void {
  }

}
