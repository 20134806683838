import {Injectable, EventEmitter} from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EventEmiterService {

  listObjet = new Subject<any[]>();
  updatelistObjet = new Subject<boolean>();
  person = new Subject<boolean>();
  errorUpdateProspect = new Subject<boolean>();
  constructor() { }

  sendListObject(data: any[]) {
    this.listObjet.next(data);
  }

  setUpdateListObject(updated: boolean) {

    this.updatelistObjet.next(updated);
  }

  setperson(person: boolean) {

    this.person.next(person);
  }

  getListObject() :Observable<any[]>{
    return this.listObjet.asObservable()
  }


  getUpdateListObject() :Observable<boolean>{
    return this.updatelistObjet.asObservable()
  }

  getperson() :Observable<boolean>{
    return this.person.asObservable()
  }


  seterrorUpdateProspect(errorUpdateProspect: boolean) {
    this.errorUpdateProspect.next(errorUpdateProspect);
  }

  geterrorUpdateProspect() :Observable<boolean>{
    return this.errorUpdateProspect.asObservable()
  }


}
