<div class="">
    <div class="row card-nav">
        <div class="col-12">
            <app-status-bar (onClickStatus)="navigateToView($event)" [listOfProcesses]="listOfProcess"></app-status-bar>
        </div>
    </div>

    <div class="d-flex card-body">
        <div class="p-2">
            <button (click)="returnTo()" type="button" class="btn-dt-retour">
        <div class="icon-position">
          <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        </div>
        <span> Retour à la liste </span>
    </button>
        </div>
        <div class="p-2">
            <button (click)="onPred()" *ngIf="listOfProcess[0]?.class!=='active'" type="button" style="margin-top: 4px;" class="btn-dt-chevron-white">
          <img src="assets/icons/chevron-white-icon.svg">
        </button>

            <button (click)="onNext()" *ngIf="listOfProcess[listOfProcess.length-2]?.class!=='done'" style="margin-left:10px" type="button" class="btn-dt-chevron">
          <img src="assets/icons/chevron-black-icon.svg">
        </button>
        </div>
        <div class="ml-auto p-2">
            <button type="button" class="btn-load" type="button" (click)="resetAddOpportunite()">
        <fa-icon [icon]="fa['faSyncAlt']"></fa-icon>
      </button>
            <button (click)="onSubmit()" *ngIf="listOfProcess[3]?.class=='active'" type="submit" class="btn-dt-save-popup">
        <fa-icon class="fa-icon-style" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
        Enregistrer
      </button>
        </div>
    </div>
 <div *ngIf="listOfProcess[0]?.class=='active'" class="row card-body">
        <ng-container *ngIf="cycleVieHorsComp$ && (cycleVieComp$ | async).length>0 && campagnie$">
            <app-campagne [listCycleVieComp]="cycleVieComp$ | async" [listCycleVieHorsComp]="cycleVieHorsComp$ | async" [listLignesProduits]="listLignesProduits$ | async" [campagne]="campagnie$ | async" (onUpdateInfo)="updateCampagne($event)"  (ongetObjetAssurer)="getObjetAssurer($event)" (onProductSelected)="setInfoSpecifiques($event)"
            (onCampagneSelected)="setInfoComplementaires($event)" (bien)="getbien($event)" (person)="getperson($event)" style="width: 100%;"></app-campagne>
        </ng-container>
    </div>

    <div *ngIf="listOfProcess[1]?.class=='active'" class="row card-body">

        <app-add-prospect [modeupdateProspect]="'false'" [person]="isperson"></app-add-prospect>
    </div>

    <div *ngIf="listOfProcess[2]?.class=='active'" class="row card-body">
        <app-details [bien]="isbien" [campagne]="campagnie$ | async" [details]="details$ | async" (onUpdateComapagne)="compagneupdate($event)" (onUpdateInfo)="updateDetailsInfo($event)" [info_specifiques]="infoSpecifiquesShema$ | async" [info_complementaires]="infoComplementaireShema$ |async" style="width: 100%;"></app-details>
    </div>

    <div *ngIf="listOfProcess[3]?.class=='active'" class="row card-body">
        <app-calendrier [listCommerciaux]="listCommerciaux$| async" [listStatusCampagne]="listStatusCampagne$ | async" [calendar]="calendar$ | async" (onUpdateInfo)="updateCalendar($event)" (onSelectStatus)="updateStatus($event)" style="width: 100%;"></app-calendrier>
    </div>

    <div class="card-body d-flex justify-content-end" style="margin-bottom: 50px;">
        <div>
            <button (click)="onPred()" [disabled]="listOfProcess[0]?.class=='active'" type="button" style="margin-top: 4px;" class="btn-dt-chevron-white">
        <img src="assets/icons/chevron-white-icon.svg">
      </button>
            <button (click)="onNext()" *ngIf="listOfProcess[listOfProcess.length-2]?.class!=='done'"  style="margin-left:10px" type="button" class="btn-dt-chevron">
        <img src="assets/icons/chevron-black-icon.svg">
      </button>
        </div>
    </div>

</div>
