import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';

import { Prospect } from 'src/app/entity/TicketsAndSupport/FilterTicket';
@Component({
  selector: 'app-filter-prospect-ticket',
  templateUrl: './filter-prospect-ticket.component.html',
  styleUrls: ['./filter-prospect-ticket.component.css']
})
export class FilterProspectTicketComponent implements OnInit, OnChanges {

  @Output() onPushFilterProspec: EventEmitter<Prospect> = new EventEmitter();
  prospet: Prospect = new Prospect();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  constructor(private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.resetprospetForm(changes.Reset.currentValue);
  }
  resetprospetForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {
      this.prospet.recheche_nom_prenom = '';
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterProspec.emit(this.prospet);

    }
  }


  EmitFilterProspec() {

    this.onPushFilterProspec.emit(this.prospet);
  }
}
