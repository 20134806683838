<div class="row">
    <div class="col-6" style="margin-top: 30px">
        <div class="form-group">
            <label>Dérniere action</label>
        </div>
    </div>
    <div class="col-6" style="margin-top: 30px">
        <div class="form-group">
            <div class="checkbox-container" style="font-size: 2em">
                <input (click)="EmitFilterActionUtilisateurOpp()"
                    [(ngModel)]="filterActionUtilisateurOpp.dernier_action" class="checkbox" type="checkbox"
                    id="lastAction" (change)="checking($event)">
                <div class="checkbox-visual" style="margin-left: 15px">
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -22px;">
                <mat-date-range-input [rangePicker]="picker">
                    <input [(ngModel)]="date_debut_creation" matStartDate placeholder="Date d'action du" />
                    <input [(ngModel)]="date_fin_action" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterActionUtilisateurOpp()" #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allUser.length > 0">
                <mat-form-field style="width: 100%;margin-top: -9px;">
                    <mat-label>Recherche Utilisateurs</mat-label>
                    <mat-chip-list #RechercheUtilisateurs>
                        <mat-chip *ngFor="let user of ListUser" [selectable]="selectable" [removable]="removable"
                            (removed)="removeUser(user)" (removed)="EmitFilterActionUtilisateurOpp()">
                            {{user}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterActionUtilisateurOpp()" placeholder="Selectionner Utilisateurs..."
                            #userInput [formControl]="userFormCtrl" [matAutocomplete]="autoUser"
                            [matChipInputFor]="RechercheUtilisateurs"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selectedUser($event)">
                        <mat-option *ngFor="let filteredUser of filteredUser | async" [value]="filteredUser.id">
                            {{filteredUser.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row" style="margin-top: -11px;">
    <div class="col-2">
        <div class="form-group" style="margin-bottom: 0px;">
            <label></label>
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
                <mat-select (click)="EmitFilterActionUtilisateurOpp()"
                    [(ngModel)]="filterActionUtilisateurOpp.operateur" placeholder="Opérateur">
                    <mat-option value="OU">
                        Ou
                    </mat-option>
                    <mat-option value="ET">
                        Et
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="col-10">
        <div class="form-group">
            <ng-container *ngIf="allGroupe.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Groupes</mat-label>
                    <mat-chip-list #Groupes>
                        <mat-chip *ngFor="let groupe of listGroupe" [selectable]="selectable" [removable]="removable"
                            (removed)="removeGroupe(groupe)" (removed)="EmitFilterActionUtilisateurOpp()">
                            {{groupe}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterActionUtilisateurOpp()" placeholder="Selectionner Groupes ..."
                            #groupeInput [formControl]="groupeFromCtrl" [matAutocomplete]="autoGroupe"
                            [matChipInputFor]="Groupes" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoGroupe="matAutocomplete" (optionSelected)="selectedGroupe($event)">
                        <mat-option *ngFor="let groupe of filteredGroupe | async" [value]="groupe.id">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row" style="margin-top: 10px;">
    <div class="col-3">
        <div class="form-group">
            <label for="enregistrementSimple">{{'opportunities.label.simpleregistration' | translate}}</label>
        </div>
    </div>
    <div class="col-3">
        <div class="form-group">
            <div class="checkbox-container" style="font-size: 2em">
                <input (click)="EmitFilterActionUtilisateurOpp()" class="checkbox" type="checkbox"
                    id="enregistrementSimple" (change)="simple($event)"
                    [(ngModel)]="filterActionUtilisateurOpp.enreg_simple">
                <div class="checkbox-visual" style="margin-left: 15px">
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-3">
        <div class="form-group">
            <label for="enregistrementAction">{{'opportunities.label.registeraction' | translate}}</label>
        </div>
    </div>
    <div class="col-3">
        <div class="form-group">
            <div class="checkbox-container" style="font-size: 2em">
                <input (click)="EmitFilterActionUtilisateurOpp()" class="checkbox" type="checkbox"
                    [(ngModel)]="filterActionUtilisateurOpp.enreg_avec_action" id="enregistrementAction"
                    (change)="action($event)">
                <div class="checkbox-visual" style="margin-left: 15px">
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</div>