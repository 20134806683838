<div class="filter-elements">
  <div class="row" style="margin-top: 15px">
    <div class="col-6">
      <div class="form-group">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -22px;">
          <input matInput [matDatepicker]="picker" [(ngModel)]="date_creation_action"
            placeholder="Date début d'action" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker (closed)="onEmit()" #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <input [(ngModel)]="filterActionUtilisateur.heure_creation_action" class="style-timePicker"
          placeholder="Heure début d'action " aria-label="24hr format" [ngxTimepicker]="actionDebut" [format]="24"
          readonly>
        <ngx-material-timepicker (closed)="onEmit()" #actionDebut></ngx-material-timepicker>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -22px;">
          <input matInput [matDatepicker]="finAction" [(ngModel)]="date_fin_action" placeholder="Date fin d'action" />
          <mat-datepicker-toggle matSuffix [for]="finAction"></mat-datepicker-toggle>
          <mat-datepicker (closed)="onEmit()" #finAction></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <input [(ngModel)]="filterActionUtilisateur.heure_fin_action" class="style-timePicker"
          placeholder="Heure fin d'action " aria-label="24hr format" [ngxTimepicker]="actionFin" [format]="24" readonly>
        <ngx-material-timepicker (closed)="onEmit()" #actionFin></ngx-material-timepicker>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -22px;">
          <input matInput [matDatepicker]="dateObj" [(ngModel)]="date_debut_objectif"
            placeholder="Date début de l'objectif" />
          <mat-datepicker-toggle matSuffix [for]="dateObj"></mat-datepicker-toggle>
          <mat-datepicker (closed)="onEmit()" #dateObj></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <input [(ngModel)]="filterActionUtilisateur.heure_debut_objectif" class="style-timePicker"
          placeholder="Heure début de l'objectif " aria-label="24hr format" [ngxTimepicker]="objectifDebut"
          [format]="24" readonly>
        <ngx-material-timepicker (closed)="onEmit()" #objectifDebut></ngx-material-timepicker>
      </div>
    </div>
  </div>
  <div class="row" >
    <div class="col-6">
      <div class="form-group">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -22px;">
          <input matInput [matDatepicker]="dateObjfin" [(ngModel)]="date_fin_objectif"
            placeholder="Date fin de l'objectif" />
          <mat-datepicker-toggle matSuffix [for]="dateObjfin"></mat-datepicker-toggle>
          <mat-datepicker (closed)="onEmit()" #dateObjfin></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <input [(ngModel)]="filterActionUtilisateur.heure_fin_objectf" class="style-timePicker"
          placeholder="Heure fin de l'objectif" aria-label="24hr format" [ngxTimepicker]="objectifFin" [format]="24"
          readonly>
        <ngx-material-timepicker (closed)="onEmit()" #objectifFin></ngx-material-timepicker>
      </div>
    </div>


  </div>
  <div class="row" style="margin-top: 14px">

    <div class="col-12">
      <div class="form-group">
        <ng-container *ngIf="allUser.length > 0">
          <mat-form-field style="width: 100%;margin-top: -9px;">
            <mat-label>Recherche Utilisateurs</mat-label>
            <mat-chip-list #RechercheUtilisateurs>
              <mat-chip *ngFor="let user of ListUser" [selectable]="selectable" [removable]="removable"
                (removed)="removeUser(user)" (removed)="onEmit()">
                {{user}}
                <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
              </mat-chip>
              <input (click)="onEmit()" placeholder="Selectionner Utilisateurs..." #userInput
                [formControl]="userFormCtrl" [matAutocomplete]="autoUser" [matChipInputFor]="RechercheUtilisateurs"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
            </mat-chip-list>
            <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selectedUser($event)">
              <mat-option *ngFor="let filteredUser of filteredUser | async" [value]="filteredUser.id">
                {{filteredUser.nom}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>
      </div>
    </div>


  </div>
  <div class="row">


    <div class="col-2">
      <div class="form-group" style="margin-bottom: 0px;">
        <label for="ouEt"></label>
        <mat-form-field appearance="fill" style="margin-top: -18px;width: 100%;">
          <mat-select (selectionChange)="onEmit()" [(ngModel)]="filterActionUtilisateur.operateur"
            placeholder="Opérateur">
            <mat-option value="OU">
              Ou
            </mat-option>
            <mat-option value="ET">
              Et
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-10" style="margin-top: -10px;">
      <div class="form-group">
        <ng-container *ngIf="allGroup.length > 0">
          <mat-form-field style="width: 100%;">
            <mat-label>Groupe</mat-label>
            <mat-chip-list #Groupe>
              <mat-chip *ngFor="let group of ListGroup" [selectable]="selectable" [removable]="removable"
                (removed)="removeGroup(group)" (removed)="onEmit()">
                {{group}}
                <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
              </mat-chip>
              <input (click)="onEmit()" placeholder="Selectionner Groupe..." #groupInput [formControl]="groupFromCtrl"
                [matAutocomplete]="autoGroup" [matChipInputFor]="Groupe"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
            </mat-chip-list>
            <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="selectedGroup($event)">
              <mat-option *ngFor="let group of filteredGroup | async" [value]="group.id">
                {{group.nom}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row">
   
    <div class="col-2">
      <div class="form-group">
        <div class="checkbox-container" style="font-size: 2em">
          <input (click)="onEmit()" class="checkbox" type="checkbox" id="lastAction" (change)="checking($event)"
            [(ngModel)]="filterActionUtilisateur.dernier_action">
          <div class="checkbox-visual" style="margin-left: 15px">
            <div></div>
          </div>
        </div>
      </div>
    </div> <div class="col-2">
      <div class="form-group">
        <label>Dérniere action</label>
      </div>
    </div>
   
    <div class="col-2">
      <div class="form-group">
        <div class="checkbox-container" style="font-size: 2em">
          <input (click)="onEmit()" class="checkbox" type="checkbox" id="enregistrementSimple" (change)="simple($event)"
            [(ngModel)]="filterActionUtilisateur.enreg_simple">
          <div class="checkbox-visual" style="margin-left: 15px">
            <div></div>
          </div>
        </div>
      </div>
    </div>  <div class="col-2">
      <div class="form-group">
        <label for="enregistrementSimple">{{'opportunities.label.simpleregistration' | translate}}</label>
      </div>
    </div>
   
    <div class="col-2">
      <div class="form-group">
        <div class="checkbox-container" style="font-size: 2em">
          <input (click)="onEmit()" class="checkbox" type="checkbox" id="enregistrementAction" (change)="action($event)"
            [(ngModel)]="filterActionUtilisateur.enreg_action">
          <div class="checkbox-visual" style="margin-left: 15px">
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <label for="enregistrementAction">{{'opportunities.label.registeraction' | translate}}</label>
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 10px;">

  </div>
</div>