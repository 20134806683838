import { Component, OnInit } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-add-new-maileva',
  templateUrl: './add-new-maileva.component.html',
  styleUrls: ['./add-new-maileva.component.css'],
})
export class AddNewMailevaComponent implements OnInit {
  fromDetails: Boolean = false;
  constructor(private sharedMenuObserverService: SharedMenuObserverService) {}

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Maileva ➜ Ajouter');
  }
}
