
import { Component, DoCheck,  ElementRef,  Input, OnInit,   ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { MailevaElement } from 'src/app/entity/Maileva/MailevaElement';
import { DatePipe, Location } from '@angular/common';
import swal from 'sweetalert2';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { tap } from 'rxjs/operators';
import { CommunicationModel } from 'src/app/entity/CommunicationModel';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';


@Component({
  selector: 'app-add-maileva',
  templateUrl: './add-maileva.component.html',
  styleUrls: ['./add-maileva.component.css'],
})
export class AddMailevaComponent implements OnInit,DoCheck {
  @Input() affaire_id: string = ""
  @Input() id_opportunite: string = ""
  @Input() id_prospect: string = ""
  @Input() fromDetails;
  @Input() fromtype;
  @ViewChild('tabs', { static: false }) tabs;
  @ViewChild('closeModal') private closeModal: ElementRef;
  @ViewChild('picker') picker: any;
   nomprenom_dest: String="";
   donn_prospect: String="";
   postal_code_post: String="";
   ville_dest: String="";
   bp_adresse_exp: String="";
   donn_exp: String="";
   postal_code_exp: String="";
   ville_exp: String="";
   bp_adresse: String="";

  @ViewChild('closebutton') closebutton; // permet de fermer le popup

  public Editor = ClassicEditor;
  public config = {
    toolbar: [
    'heading', '|',
    'bold', 'italic', 'undo', 'redo', 'blockQuote', '|',
    'numberedList', 'bulletedList', 'mediaEmbed', '|',
    'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells'
    ],
  }

  public datepalanifier: Date = new Date();

  CurrentStep: number = 0;
  MailEva: MailevaElement = new MailevaElement();
  ListFiles: File[] = [];
  file: File;
  fa = fa;
  files: any[] = [];
  type_file: any;
  index: number = 0;
  file_location: string = '';
  attached_files: any[] = [];
  type_envoie: any[] = [
    { 'value': 'STANDARD', 'libelle': 'STANDARD' },
    { 'value': 'SLOW', 'libelle': 'Ecopli' },
    { 'value': 'LETTRE_GRAND_COMPTE', 'libelle': 'Lettre Grand Compte' },
    { 'value': 'ECOPLI_GRAND_COMPTE', 'libelle': 'Ecopli Grand Compte' },
    { 'value': 'LRE_AR', 'libelle': 'Lettre recommandée avec avis de réception' },
  ];

  send_now: boolean = true;
  nbre_page_courrier: any;
  maileva_id: string = "";
  num_rue_dest: string = '';
  num_rue_exp: string = '';
  communicationModel: CommunicationModel[] = []
  id_model_courrier: string = "";
  show_loading: boolean = false;
  show_loading_prev: boolean = false;
  show_loading_add: any;

  libelle_modele: string = "";
  villeValue: string = "";
  cedexValue: any = "";
  limit: any = "";
  message: boolean = false;
  villeValueExp: string = "";
  cedexValueExp: any = "";
  messageExp: boolean = false;
  limitExp: any = "";
  forbiddenFirstCode: boolean = false;
  forbiddenSecondCode: boolean = false;
  event: string = "";
  eventExp: any;
  forbiddenFirstCodeExp: boolean = false;
  forbiddenSecondCodeExp: boolean = false;
  contentPreview:string="";
  indexTab:any;
  contentBody: {} = {};
  type_comm: string;
  @Input() apercu:boolean=false;
  constructor(private sharedMenuObserverService: SharedMenuObserverService,
    private apiCommunicationService: ApiComminucationService,
    private apiProspectsService: ApiProspectsService,
    private route: ActivatedRoute,
    private routes: Router,
    private location: Location,
    private datePipe: DatePipe) {
  }

  ngDoCheck() {
    this.tabs?.realignInkBar();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.maileva_id = params.get('add');
    });
    this.getExpediteurCourrier()

    if (this.id_prospect !== '' && this.id_opportunite !== '') {
      this.MailEva.id_opp = this.id_opportunite
      this.getDestinataireCourrier()
    }
    if (this.affaire_id !== '' && this.id_opportunite !== '') {
      this.MailEva.id_opp = this.id_opportunite
      this.MailEva.id_affaire = this.affaire_id
      this.MailEva.id_prospect = this.id_prospect
      this.apiProspectsService.getProspectByIdAffaire(this.affaire_id)
        .subscribe((data) => {
          this.MailEva.nomprenom_dest = data.name + ' ' + data.surname
          // this.MailEva.donn_exp = data.streetNumber rue number
          this.MailEva.donn_prospect = data.streetName
          this.MailEva.postal_code_post = data.CP
          this.MailEva.ville_dest = data.adresses_ville
          this.MailEva.complement_adr_dest = data.complement_adr
        })
    }
    this.getListModelCommunication()
  }

  preview(tab) {

    if (tab.index === 1) {
      if ((this.MailEva.id_affaire  ==='' &&  this.MailEva.id_opp === '') && this.MailEva.modele_reel.indexOf("#") !== -1) {
        this.alertWarning('Le modèle sélectionné requiert une opportunité ou une affaire !', 'Veuillez sélectionner un modèle sans <#clefs#> où envoyer le courrier depuis une opportunité ou une affaire');
        return;
      }

      if (!this.MailEva.id_modele && !this.type_comm) {
        this.alertWarning('Pas de modèle sélectionné !', 'Veuillez sélectionner un modèle');
        return;
      }
      if (this.contentBody ==='') {
        this.alertWarning('Pas de contenu disponible','');
        return;
      }
      this.show_loading_prev = true;
      this.apiCommunicationService.editingMachine(this.MailEva.id_modele, this.type_comm, this.MailEva.id_affaire, this.MailEva.id_opp, this.contentBody)
        .subscribe((data) => {
          this.contentPreview = data.replace(/(?:\r\n|\r|\n)/g, '<br>');

          this.show_loading_prev = false
        }).add(() => {

        }).unsubscribe()
    }
  }

  getInfoModel(element) {
    this.type_comm = element.type
    this.MailEva.id_modele = element.id;
    this.MailEva.subject = element.sujet;
    this.MailEva.modele_reel = element.simple_content;

  }

  getValueFromEditor({ editor }) {
    const data = editor.getData();
    if ( data === '')
    this.contentBody= '';
    else
        this.contentBody = { "mail_template": data }
  }

  hideloader() {
    document.getElementById('loadingBar')
      .style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar')
      .style.display = 'block';
  }

  retourToList() {
    this.location.back()
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.MailEva.bp_compl_ville_exp = adress.locality
    this.MailEva.postal_code_exp = adress.postal_code
    this.MailEva.donn_exp = adress.street_number+' '+adress.route
    this.num_rue_exp = adress.street_number
    this.MailEva.ville_exp = adress.locality
    this.controleVilleExp(this.MailEva.ville_exp)
    this.verifyLengthExp()
  }

  onChangeAdresseSaisieAutoDest(adress: AdressEmitter) {

    this.MailEva.complement_adr_dest =""
    this.MailEva.postal_code_post = adress.postal_code
    this.MailEva.donn_prospect = adress.street_number+" "+adress.route
    this.num_rue_dest = adress.street_number
    this.MailEva.ville_dest = adress.locality
    this.controleVilleDest(this.MailEva.ville_dest)
    this.verifyLength()
  }

  getNumber(event) {
    this.event = event
    this.event.toString().charAt(0)
    this.event.toString().charAt(1)
    if (this.event.toString().charAt(0) === '9') {
      this.forbiddenFirstCode = true;
    }

    if (this.event.toString().charAt(1) === '7') {
      this.forbiddenSecondCode = true;
    }
    else {
      this.forbiddenFirstCode = false;
      this.forbiddenSecondCode = false;
    }
  }

  getNumberExp(event) {

    this.eventExp = event
    this.eventExp.toString().charAt(0)
    this.eventExp.toString().charAt(1)
    if (this.eventExp.toString().charAt(0) === '9') {
      this.forbiddenFirstCodeExp = true;

    }
    if (this.eventExp.toString().charAt(1) ==='7') {
      this.forbiddenSecondCodeExp = true;
    }

    else {
      this.forbiddenSecondCodeExp = false;
      this.forbiddenFirstCodeExp = false;
    }

  }

  getExpediteurCourrier() {
    this.apiCommunicationService.GetExpediteurCourrier()
      .pipe(
        (tap(({ adresse_exp }) => {
          this.MailEva.nomprenom_exp = adresse_exp.nomprenom_exp
          this.MailEva.donn_exp = adresse_exp.donn_exp
          this.MailEva.ville_exp = adresse_exp.ville_exp
          this.MailEva.postal_code_exp = adresse_exp.postal_code_exp
          this.MailEva.complement_adr_exp = adresse_exp.complement_adr_exp
        })
        )).subscribe()
  }


  getDestinataireCourrier() {

    this.apiCommunicationService.getDestinataireCourrier(this.id_prospect)
      .pipe(
        (tap(({ adresse_dest }) => {
          this.MailEva.nomprenom_dest = adresse_dest.nomprenom_dest
          this.MailEva.donn_prospect = adresse_dest.donn_prospect
          this.MailEva.ville_dest = adresse_dest.ville_dest
          this.MailEva.postal_code_post = adresse_dest.postal_code_post
          this.MailEva.complement_adr_dest = adresse_dest.complement_adr_dest
        })
        )).subscribe()
  }

  getFiles(event: any) {
    this.file = event.target.files[0];
    this.type_file = this.file.type;
    this.ListFiles[this.index] = this.file;
    this.index++;
    this.ngOnInit();
  }

  dropFile(index: number) {
    this.ListFiles.splice(index, 1);
    this.index--;
  }

  onChangeSendNow() {
    if (this.send_now) {
      this.MailEva.date_envoie = null;
    }
  }

  validatePdfMail(valid, directives) {

  /*   if(!valid){
      if (this.MailEva.modele_reel=="") ||  (this.files.length==0))
      if (this.forbiddenFirstCodeExp
        || this.forbiddenSecondCodeExp
        || this.forbiddenFirstCode
        || this.forbiddenSecondCode
        || this.message
        || this.messageExp
        ) {
        this.alertWarning('formulaire invalide!', 'Il faut verifier les données que vous avez rempli ! ');
        return;
      }
      else if ( valid ){
        if (this.MailEva.modele_reel=="" &&  this.files.length==0){
          this.alertWarning('formulaire invalide!', 'Il faut remplir le contenue de courrier ou selectionner un fichier  ');
          return;
        }else{
          if (this.send_now) {
            this.datepalanifier = new Date();
          }
          this.show_loading_add = true
          this.MailEva.date_envoie = this.datePipe.transform(this.datepalanifier, 'yyyy-MM-dd');
          const formData = new FormData();
          for (var i = 0; i < this.files.length; i++) {
            formData.append('file[]', this.files[i]);
          }
          formData.append('recto_verso', this.MailEva.recto_verso);
          formData.append('type_envoie', this.MailEva.type_envoie);
          formData.append('body_content', this.MailEva.modele_reel);
          this.apiCommunicationService.SaveDataCommunication(formData)
            .subscribe(
              (response) => {
                this.file_location = response.message.file_courrier;
                this.savePostDetails(this.file_location.substring(this.file_location.lastIndexOf('/'), this.file_location.length), response.message.attached_files);
                this.show_loading_add = false
              },
              (err) => {
                if (err.startsWith("Error Code: 415"))
                  this.alertError('Impossible de lire le(s) fichier(s) PDF')
                this.show_loading_add = false
              });
              return;
        }
        }
        else if (!valid){
          this.alertWarning('formulaire invalide!', 'Il faut remplir tout les champs !');
          return;
        }

    }
   */

    if(!valid){
          this.alertWarning('formulaire invalide!', 'Il faut verifier les données que vous avez rempli ! ');
          return;


    }else{
      if (this.forbiddenFirstCodeExp
        || this.forbiddenSecondCodeExp
        || this.forbiddenFirstCode
        || this.forbiddenSecondCode
        || this.message
        || this.messageExp

        ) {
        this.alertWarning('formulaire invalide!', 'Il faut verifier les données que vous avez rempli ! ');
        return;
      }else if (this.MailEva.modele_reel==="" &&  this.files.length===0){
        this.alertWarning('formulaire invalide!', 'Il faut remplir le contenue de courrier ou selectionner un fichier  ');
        return;
      }else{
        if (this.send_now) {
          this.datepalanifier = new Date();
        }
        this.show_loading_add = true
        this.MailEva.date_envoie = this.datePipe.transform(this.datepalanifier, 'yyyy-MM-dd');
        const formData = new FormData();
        for (var i = 0; i < this.files.length; i++) {
          formData.append('file[]', this.files[i]);
        }
        formData.append('recto_verso', this.MailEva.recto_verso);
        formData.append('type_envoie', this.MailEva.type_envoie);
        formData.append('body_content', this.MailEva.modele_reel);
        this.apiCommunicationService.SaveDataCommunication(formData)
          .subscribe(
            (response) => {
              this.file_location = response.message.file_courrier;
              this.savePostDetails(this.file_location.substring(this.file_location.lastIndexOf('/'), this.file_location.length), response.message.attached_files);
              this.show_loading_add = false
              this.MailEva.modele_reel = "" 
              this.libelle_modele = ""   
              if(this.fromtype=='A'){
                this.apiProspectsService.getProspectByIdAffaire(this.affaire_id)
                .subscribe((data) => {
                  this.MailEva.nomprenom_dest = data.name + ' ' + data.surname
                  this.MailEva.donn_prospect = data.streetName
                  this.MailEva.postal_code_post = data.CP
                  this.MailEva.ville_dest = data.adresses_ville
                  this.MailEva.complement_adr_dest = data.complement_adr
                })
              }

            },
            (err) => {
              if (err.startsWith("Error Code: 415"))
                this.alertError('Impossible de lire le(s) fichier(s) PDF')
              this.show_loading_add = false
            });
            return;
      }
    }
  }

  savePostDetails(file_location: string, attached_files: any[]) {
    this.MailEva.file_courrier = file_location;
    this.MailEva.attached_files = attached_files
    this.MailEva.donn_exp = this.MailEva.donn_exp + this.num_rue_exp
    this.apiCommunicationService.SavePostDetails(this.MailEva)
      .subscribe(
        (response) => {
          this.apiCommunicationService.pushcommunicationhistoric('test');
          this.alertSuccess(response.message);
          this.closebutton.nativeElement.click()
        },
        error => {
          this.alertError("Echec de l'ajout du courrier")
          this.show_loading_add = false
        });
  }


  expandFileName(name: string): string {
    if (name.length < 12) {
      return name;
    } else {
      return name.substring(0, 5) + '...' + name.substring(name.length - 5, name.length);
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }


  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    let sommesize=0;
for (var i=0; i<files.length; i++){
  sommesize=sommesize+files[i].size;
}
if(sommesize<40000000){
  this.prepareFilesList(files);
}else{
  this.alertError("la taille des fichiers est volumineuse, elle ne doit pas dépasser 40 Mo");
}

  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);

      this.uploadFilesSimulator(0);
    }
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }



  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: '#68a45b',
      focusConfirm: true,
      showCancelButton: true,
      cancelButtonText: 'Fermer',
      confirmButtonText: 'Liste Maileva',
    }).then((result) => {
      if (result.isConfirmed) {
        this.routes.navigate(['/maileva'])
          .then(() => {

            this.closeModal.nativeElement.click();
          });
      }
      if (result.isDismissed) {

        this.closeModal.nativeElement.click();

      }
      if (result.isDenied) {

        this.closeModal.nativeElement.click();
      }
    })
  }

  alertError(data) {
    swal.fire({
      title: "Erreur",
      text: data,
      html: '',
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    })
  }

  alertWarning(data, response) {
    swal.fire({
      title: data,
      icon: 'warning',
      html: response,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    })
  }

  resetForm() {
 this.MailEva=new MailevaElement()
 this.ngOnInit()
  }


  showSubMenu($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.closeAllDropDownElements($event)
    let parentNode = $event.target.parentNode
    let listDropDowns = parentNode.getElementsByClassName("dropdown-menu")
    let elem = listDropDowns[0]
    elem.classList.toggle("show")
  }

  private closeAllDropDownElements($event) {
    let parentNode = $event.target.parentNode
    let listDropDownss = parentNode.parentNode.getElementsByClassName("dropdown-menu")
    for (let i = 0; i < listDropDownss.length; i++) {
      listDropDownss[i].classList.remove("show")
    }
  }

  getListModelCommunication() {
    this.show_loading = true;
    let type = "M"
    if (this.id_opportunite !== '') {
      type = "O"
    } else if (this.affaire_id !== '' && this.id_opportunite !== '') {
      type = 'A'
    }
    this.apiCommunicationService.listModelWithTypeCommGet('C', type)
      .subscribe((data: CommunicationModel[]) => {
        let list: any[] = data.filter(element => element.id_parent == null)
        list.forEach(element => element.childs = this.getChildren(data, element))
        this.communicationModel = list;
        this.show_loading = false;
      })
  }

  getChildren(array, parent) {
    let list = array.filter(ele => ele.id_parent === parent.id)
    if (list.length > 0) {
      list.forEach(element => element.childs = this.getChildren(array, element))
    }
    return list;
  }

  setComValue(value: string) {
    this.id_model_courrier = value;
  }
  setModelValue(value: string, libelle: string) {

    this.id_model_courrier = value;
    this.libelle_modele = libelle
  }




  controleVilleDest(event) {
    this.villeValue = event
    this.verifyLength()
  }



  controleCedexDest(event) {
    this.cedexValue = event
    this.verifyLength()
  }

  controleVilleExp(event) {
    this.villeValueExp = event
    this.verifyLengthExp()
  }

  controleCedexExp(event) {
    this.cedexValueExp = event
    this.verifyLengthExp()
  }

  verifyLength() {
    this.limit = this.cedexValue.toString().length + this.villeValue.length
    if (this.limit > 33) {
      this.message = true;
    }

    else this.message = false;

  }

  verifyLengthExp() {
    this.limitExp = this.cedexValueExp.toString().length + this.villeValueExp.length
    if (this.limitExp > 33) {
      this.messageExp = true;
    }

    else this.messageExp = false;

  }
}
