import { environment } from 'src/environments/environment';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ApiAdminService } from 'src/app/services/ApiAdmin/api-admin.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Compagnie } from '../../../entity/Compagnie';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';

@Component({
  selector: 'app-companies-fiche',
  templateUrl: './companies-fiche.component.html',
  styleUrls: ['./companies-fiche.component.css'],
})
export class CompaniesFicheComponent implements OnInit {
  Compagnie: Compagnie = new Compagnie();
  initialCompagnie: Compagnie = new Compagnie();
  ModeAjout: boolean = true;
  logo: any = null;
  fa = fa;
  show_image: any = '../../../../assets/img/noimage.png';
  show_loading_add: boolean = false;
  list_invalid: any;
  list_invalid_string: string = '';
  id_comagnie: string = '';
  telCountry = environment.country;

  constructor(
    private route: ActivatedRoute,
    private ApiAdminService: ApiAdminService,
    private router: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private routes: Router,
    private location: Location,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.showloader();
      if (params.get('id') !== 'Nouveau') {
        this.ModeAjout = false;
        this.Compagnie.siren = params.get('id');
        this.ApiAdminService.get_compagne_by_siren(params.get('id'))
          .pipe(
            tap(({ compagnie_details }) => {
              this.Compagnie = compagnie_details;
              if (this.Compagnie.logo_cie !== '') this.show_image = this.Compagnie.logo_cie;
              Object.assign(this.initialCompagnie, this.Compagnie);
              this.hideloader();
            })
          )
          .subscribe();
      } else {
        this.Compagnie.logo_cie = '../../../../assets/img/noimage.png';
        this.hideloader();
      }
    });
    if (this.ModeAjout) {
      this.sharedMenuObserverService.updateMenu('Gestion des compagnies ➜ Ajouter');
    } else {
      this.sharedMenuObserverService.updateMenu('Gestion des compagnies ➜ Modifier');
    }
  }

  retourToList() {
    this.routes.navigate(['/gestion-companies']);
  }

  clearForm() {
    this.Compagnie = new Compagnie();
    this.initialCompagnie = new Compagnie();
    this.ngOnInit();
  }

  alertSuccess(data, idCompagnie, modeedit) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: 'Nouvelle Compagnie',
        denyButtonColor: '833626',
        cancelButtonText: 'Modifier Compagnie',
        confirmButtonText: 'Liste des Compagnies',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['/gestion-companies']);
        }
        if (result.isDismissed) {
          this.hideloader();
          if (this.ModeAjout) this.routes.navigate(['/gestion-companies/fiche/' + idCompagnie]);
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['/gestion-companies/fiche/Nouveau']);
          location.reload();
        }
      });
  }

  alertWarning(data) {
    this.list_invalid_string = '';
    this.list_invalid.forEach((element) => {
      if (typeof element.name === 'string')
        this.list_invalid_string =
          this.list_invalid_string + '<li style="list-style-type: none; font-size: 14px">' + element.name + '</li>';
    });
    swal.fire({
      title: data,
      icon: 'warning',
      html: '<b>Il faut remplir tout les champs!</b><br>' + '<ul>' + this.list_invalid_string + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  getLogo(event: any) {
    if (event.target.files && event.target.files.length) {
      for (const file of event.target.files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            const height = img.naturalHeight;
            const width = img.naturalWidth;
            if (width <= 11168 && height <= 1170) {
              this.logo = event.target.files[0];
              this.Compagnie.logo_cie = this.logo;
              this.show_image = reader.result;
            } else {
              alert('Taille trop grande! , longeur: ' + width + ' largeur: ' + height);
            }
          };
        };
      }
    }
  }

  onSelectFiles(event: any) {
    console.log(event);
  }

  EnregistrerCompanie(valid, directive) {
    this.list_invalid = directive.filter((element) => element.control.status === 'INVALID');
    if (!valid) {
      this.alertWarning('Formulaire Invalid!');
    } else {
      this.show_loading_add = true;
      const formData: FormData = new FormData();

      formData.append('siren', this.Compagnie.siren);
      formData.append('adresse', this.Compagnie.adresse);
      formData.append('blob', this.Compagnie.blob);
      formData.append('cp', this.Compagnie.cp);
      formData.append('denomination', this.Compagnie.denomination);
      formData.append('mail_commercial', this.Compagnie.mail_commercial);
      formData.append('mail_production', this.Compagnie.mail_production);
      formData.append('mail_sav', this.Compagnie.mail_sav);
      formData.append('ville', this.Compagnie.ville);
      formData.append('matricule', this.Compagnie.matricule);
      formData.append('tel_sav', this.Compagnie.tel_sav);
      formData.append('tel_commercial', this.Compagnie.tel_commercial);
      formData.append('tel_production', this.Compagnie.tel_production);
      formData.append('types', this.Compagnie.types);
      formData.append('url', this.Compagnie.url);
      formData.append('logo_cie', this.logo);
      if (this.ModeAjout) {
        this.ApiAdminService.Insert_compagnes(formData).subscribe((Response) => {
          if (Response.message === 'insérer avec succès') {
            this.show_loading_add = false;
            this.alertSuccess(Response.message, Response.siren, true);
          } else {
            this.show_loading_add = false;
            this.alertError('Erreur');
          }
          this.show_loading_add = false;
        });
      } else {
        if (this.logo === null) {
          formData.append('logo_cie', '');
        }
        this.ApiAdminService.Update_compagnes(formData).subscribe((Response) => {
          if (Response.message === 'mise a jour avec succès') {
            this.show_loading_add = false;
            this.alertSuccess(Response.message, Response.id, false);
          } else {
            this.show_loading_add = false;
            this.alertError('Erreur');
          }
          this.show_loading_add = false;
        });
      }
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.Compagnie.ville = adress.locality;
    this.Compagnie.cp = adress.postal_code;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getNumber($event: any, type: string) {
    switch (type) {
      case 'telProd':
        this.Compagnie.tel_production = $event;
        break;
      case 'telsav':
        this.Compagnie.tel_sav = $event;
        break;
      case 'telcomm':
        this.Compagnie.tel_commercial = $event;
        break;
      default:
        break;
    }
  }

  numberlength(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else if (event.target.value.length === 8) {
      return true;
    } else if (event.target.value.length === 9) {
      return false;
    }
  }
}
