import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, ChangeDetectorRef, OnChanges, OnDestroy } from '@angular/core';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { Ticket } from 'src/app/entity/TicketsAndSupport/FilterTicket';
import { DatePipe } from '@angular/common';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { element } from 'protractor';
@Component({
  selector: 'app-filter-ticket-ticket',
  templateUrl: './filter-ticket-ticket.component.html',
  styleUrls: ['./filter-ticket-ticket.component.css']
})
export class FilterTicketTicketComponent implements OnInit, OnChanges, OnDestroy {
  fa = fa;
  status: any[] = [];
  @Output() onPushFilterTicket: EventEmitter<Ticket> = new EventEmitter();
  ticket: Ticket = new Ticket();
  selected2: string;
  hello: string;
  date_debut_traitement: string = "";
  date_fin_traitement: string = "";
  date_debut_creation: string = "";
  date_fin_creation: string = "";
  date_debut_limit: string = "";
  date_fin_limit: string = "";
  listeunsubscribe: Subscription[] = [];

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  constructor(private apiTickets: ApiTicketsService, private datePipe: DatePipe,
    private chRef: ChangeDetectorRef) {

  }
  ngOnDestroy(): void {
    this.listeunsubscribe.forEach((element) => {
      element.unsubscribe();
    })
  }

  ngOnInit(): void {
    this.GetStatusTicket();
    //this.listCatTicketGet();
  }

  GetStatusTicket() {
    const unsubscribeGetStatusTicket = this.apiTickets.GetStatusTicket().subscribe(
      data => {

        this.status = data.list_statut;
      },
      err => {
        console.error(err);
      }
    ); this.listeunsubscribe.push(unsubscribeGetStatusTicket);

  }


  listCatTicketGet() {
    const unsubscribeListCatTicketGet = this.apiTickets.listCatTicketGet().subscribe(
      data => {
        // TODO: add ticket category filter
      },
      err => {
        console.error(err);
      }
    ); this.listeunsubscribe.push(unsubscribeListCatTicketGet);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetticketForm(changes.Reset.currentValue);
  }
  resetticketForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {


      this.ticket.date_debut_creation = this.date_debut_creation = "";
      this.ticket.date_fin_creation = this.date_fin_creation = "";
      this.ticket.date_debut_traitement = this.date_debut_traitement = "";
      this.ticket.date_fin_traitement = this.date_fin_traitement = "";
      this.ticket.date_debut_limit = this.date_debut_limit = "";
      this.ticket.date_fin_limit = this.date_fin_limit = "";
      this.ticket.recheche_num_ticket = "";
      this.ticket.recheche_sujet_ticket = "";
      this.ticket.recheche_status = [];
      this.selected2 = "";
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterTicket.emit(this.ticket);

    }
  }



  EmitFilterNumTickets() {


    this.ticket.date_debut_creation = this.datePipe.transform(this.date_debut_creation, 'yyyy-MM-dd');
    this.ticket.date_fin_creation = this.datePipe.transform(this.date_fin_creation, 'yyyy-MM-dd');
    this.ticket.date_debut_traitement = this.datePipe.transform(this.date_debut_traitement, 'yyyy-MM-dd');
    this.ticket.date_fin_traitement = this.datePipe.transform(this.date_fin_traitement, 'yyyy-MM-dd');
    this.ticket.date_debut_limit = this.datePipe.transform(this.date_debut_limit, 'yyyy-MM-dd');
    this.ticket.date_fin_limit = this.datePipe.transform(this.date_fin_limit, 'yyyy-MM-dd');

    this.ticket.recheche_status = [];
    this.ticket.recheche_status.push(this.selected2);
    this.onPushFilterTicket.emit(this.ticket);
  }


}
