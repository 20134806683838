import { BankInformation } from './../BankInformation';
export class Statut {
  libelle: string = '';
  type: string = '';
  validation: string = '';
}
export class Frais {
  montant: string = '';
  note: string = '';
  id_mode_paiement: string = '';
  mode_paiement: string = '';
  id_affaire: string = '';
  id_frais: string = '';
  date_creation: string = '';
  date_due: string = '';
  statut: Statut[] = [];
  type: string = '';
}

export class Contrat {
  Fractionnement_prime: string = '';
  affaire_fils: any[] = [];
  affaire_parent: string = '';
  autre_affaires: any[] = [];
  cmp: string = '';
  bareme_code: string = '';
  date_deff: string = '';
  date_envoi_res: string = '';
  date_rapelle: string = '';
  denomination: string = '';
  id: string = '';
  description: string = '';
  id_contrat: string = '';
  id_opp: number;
  id_prospect: string = '';
  laison_contrat: any[] = [];
  list_tags: any[] = [];
  list_tags_libelle: any[] = [];
  mca: string = '';
  mcm: string = '';
  mode_paiement: string = '';
  mode_paiement_libelle: string = '';
  montant_ht: string = '';
  nb_adh: string = '';
  nom_p: string = '';
  nom_produit: string = '';
  num_contrat: string = '';
  pm_base: string = '';
  souscription: string = '';
  type_contrat: string = '';
  nom_commerciale: string = '';
  note: string = '';
  num_affaire: string = '';
  id_opp_md5: string = '';
  status: string = '';
  etat_dossier_id: string = '';
  allowed_to_update: any;
  bien:number;
  person:number;
}

export class MainProspect {
  CP: string = '';
  DN: string = '';
  adresse_mail: string = '';
  adresses_adresse: string = '';
  adresses_ville: string = '';
  campagne_id: string = '';
  civilite: string = '';
  complement_adr: string = '';
  description: string = '';
  first_campagne: string = '';
  id: string = '';
  interlocuteur: string = '';
  mobile: string = '';
  name: string = '';
  nb_enfants: string = '';
  post: string = '';
  prospects_civ: string = '';
  prospects_id: string = '';
  prospects_situation: string = '';
  relation: string = '';
  situation: string = '';
  streetName: string = '';
  streetNumber: string = '';
  surname: string = '';
  tags: any[] = [];
  tel: string = '';
  tel2: string = '';
  users_nom: string = '';
  users_prenom: string = '';
  ville: string = '';
}
export class AffaireProspect {
  main_prospect: MainProspect = new MainProspect();
}

export class AffaireDetails {
  contrat: Contrat = new Contrat();
  prospect: AffaireProspect = new AffaireProspect();
  frais: Frais = new Frais();
  // json_object;
  info_bancaire: BankInformation[] = [];
}
