<div class="row">
    <div class="col-12">
        <mat-accordion class="example-headers-align">
            <!-- Info Société-->
            <mat-expansion-panel class="expension-secondary-color" [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <!--Title-->
                        <h2 class="font-primary-geoprod">- Informations Société</h2>
                    </mat-panel-title>
                    <mat-panel-description>
                        <!--Description-->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row body" style="margin-top: 30px;">
                    <div class="col-12">
                        <ng-container *ngIf="type!=3">
                            <div class="col-12">
                                <button mat-button [matMenuTriggerFor]="menu" type="button" class="add-btn-search" style="border-radius: 15px;">
                                    <fa-icon class="fa fa-lg fa-flip-horizontal" [icon]="fa['faSearch']"></fa-icon>
                                    &nbsp;&nbsp;
                                    Recherche par&nbsp;&nbsp;
                                    <fa-icon class="fa fa-lg" [icon]="fa['faCaretDown']"></fa-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <!-- (click)="prospectFinder()" -->
                                    <button data-toggle="modal" data-target="#Recherche-entreprise" mat-menu-item>Entreprise</button>
                                </mat-menu>

                                <!-- <button (click)="reset()" type="reset" class="btn-load" style="float: right;margin-top: 2px;"
                  type="button">
                  <fa-icon [icon]="fa['faSyncAlt']"></fa-icon>
                </button> -->
                            </div>
                        </ng-container>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 prospect-card-field">
                                    <div class="form-group-container">
                                        <p class="form-group-container-title">- Identité </p>
                                        <div class="row">
                                            <form action="" [formGroup]="entrepriseForm" (change)="updateEntrepriseInfo()" [ngClass]="{'cover': !allowed_to_update}">
                                                <div class="row">
                                                  <!--   <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{countryLanguage.PROSPECT.SIREN}}</mat-label>
                                                            <input type="number" (keypress)="numberlength($event)" matInput placeholder="123 456 789" formControlName="siren" pattern="([0-9]{9})" (ngModelChange)="autoRemplirProspectPro($event)" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{countryLanguage.PROSPECT.SIRET}}</mat-label>
                                                            <input type="number" (keypress)="numberlengthSiret($event)" min="0" pattern="([0-9]{14})" maxlength="14" matInput placeholder="123 456 789 00001" formControlName="siret" autocomplete="off">
                                                        </mat-form-field>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Effectif(s)</mat-label>
                                                            <input (keypress)="numberOnly($event)" type="number" min="0" matInput placeholder="0" formControlName="effectif" autocomplete="off">

                                                        </mat-form-field>
                                                    </div>
                                                 <!--    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>{{countryLanguage.PROSPECT.NAF.LABEL}}</mat-label>
                                                            <input type="text" (keypress)="numberlengthNaf($event)" matInput [placeholder]="countryLanguage.PROSPECT.NAF.PLACEHOLDER" formControlName="naf" autocomplete="off">
                                                            <mat-error *ngIf="isValid('naf')"><span> {{countryLanguage.PROSPECT.NAF.LABEL}} invalide doit
                                                                    contenir {{countryLanguage.PROSPECT.NAF.PLACEHOLDER.length}} chiffres et un letter exemple :{{countryLanguage.PROSPECT.NAF.PLACEHOLDER}}
                                                                </span></mat-error>
                                                        </mat-form-field>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Raison Sociale</mat-label>
                                                            <input type="text" matInput formControlName="RS" required name="RS" autocomplete="off">
                                                            <mat-error *ngIf="isValid('RS')"><span> Raison sociale est
                                                                    obligatoire </span></mat-error>

                                                        </mat-form-field>
                                                    </div>
                                              <!--       <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Nom Commercial</mat-label>
                                                            <input type="text" matInput formControlName="nom_commercial" autocomplete="off">
                                                        </mat-form-field>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Convention Collective</mat-label>
                                                            <input type="text" matInput formControlName="convention_collective" placeholder="CCN CHR 3292" autocomplete="off">
                                                            <mat-error *ngIf="isValid('convention_collective')">
                                                                Convention Collective invalid exmple: CCN CHR 3292
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Dirigeant(s)</mat-label>
                                                            <input type="text" matInput formControlName="respensable" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Forme juridique</mat-label>

                                                            <mat-select formControlName="FJ" (selectionChange)="updateEntrepriseInfo()">
                                                                <mat-option *ngFor="let f of FormeJuridique" value="{{f.value}}">{{f.nom}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Numéro tva intracommunautaire</mat-label>
                                                            <input type="number" matInput formControlName="numero_tva_intra" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Date de création</mat-label>
                                                            <input placeholder="jj/mm/aaaa"  [max]="minDate" matInput readonly (dateChange)="creationDateChanged()" [matDatepicker]="pickerCD" formControlName="date_creation" (click)="pickerCD.open()">
                                                            <mat-datepicker-toggle matSuffix [for]="pickerCD">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #pickerCD></mat-datepicker>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-4">
                                                        <mat-form-field class="full-width" appearance="standard">
                                                            <mat-label>Tranche effectifs</mat-label>
                                                            <input type="text" matInput formControlName="tranche_effectifs" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                      
                                <div *ngIf="isfilled" class="col-xl-12 col-lg-12 prospect-card-field">
                                  
                                        <div class="form-group-container "  [ngClass]="{'cover': !allowed_to_update}">
                                            <p class="form-group-container-title">- Informations complémentaires </p>
                                            <formly-form   (change)="updateEntrepriseInfo()" [model]="model" [fields]="fields" [options]="options" [form]="form" ></formly-form>

                                        </div>
                                </div>
                                <div class="col-xl-6 col-lg-12 prospect-card-field">
                                    <form [formGroup]="entrepriseForm" [ngClass]="{'cover': !allowed_to_update}">
                                        <div class="form-group-container">
                                            <p class="form-group-container-title">- Contact </p>
                                            <div class="row">

                                                <div class="col-12">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>Email</mat-label>
                                                        <input type="email" matInput formControlName="mail" name="mail" autocomplete="nofill">
                                                        <fa-icon matSuffix [icon]="fa['faAt']"></fa-icon>
                                                    </mat-form-field>
                                                    <div *ngIf="!entrepriseForm?.get('mail')?.valid && (entrepriseForm?.get('mail')?.dirty || entrepriseForm?.get('mail').touched)" class="message-erreur">
                                                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                                        Adresse e-mail invalide! exemple: geoprod@mail.com
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>Web</mat-label>
                                                        <input type="url" matInput formControlName="web" placeholder="http://www. ..." autocomplete="nofill">
                                                        <mat-error *ngIf="isValid('web')">URL invalid exmple: http://www.exemple.com
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-12">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label class="ng2tel-field-label">Téléphone</mat-label>
                                                        <input ng2TelInput matInput name="tel" (keypress)="numberOnly($event)" (intlTelInputObject)="telInputObject($event)" maxlength="14" formControlName="tel" [ng2TelInputOptions]="{initialCountry: telCountry}" (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event,'tel')">
                                                        <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!--Adresse-->
                                <div class="col-xl-6 col-lg-12 prospect-card-field">
                                    <form [formGroup]="entrepriseForm" [ngClass]="{'cover': !allowed_to_update}">
                                        <div class="form-group-container " style="height: 300px;">
                                            <p class="form-group-container-title">- Adresse </p>
                                            <div class="row">
                                                <!--Adress Auto Google API-->
                                                <div class="col-12">
                                                    <app-auto-complete-adresses [isReadOnlyAdresse]="allowed_to_update" iconColor="#9b9b9b" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                                                    </app-auto-complete-adresses>
                                                </div>
                                                <!--Country-->
                                                <div class="col-6">

                                                    <mat-select-country appearance="standard" label="Pays" class="full-width" [value]="defaultCountry" [itemsLoadSize]="5" readonly>
                                                    </mat-select-country>
                                                </div>
                                                <!--Ville-->
                                                <div class="col-6">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>Ville</mat-label>
                                                        <input matInput type="text"  formControlName="ville" readonly >
                                                    </mat-form-field>
                                                </div>
                                                <!--Rue-->
                                                <div class="col-4">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>Rue</mat-label>
                                                        <input matInput type="text" formControlName="streetName" readonly >
                                                    </mat-form-field>
                                                </div>
                                                <!--N° rue-->
                                                <div class="col-4">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>N° de Rue</mat-label>
                                                        <input type="number" matInput min="0" (keypress)="numberOnly($event)" placeholder="0" readonly size="8" formControlName="streetNumber">
                                                    </mat-form-field>


                                                </div>
                                                <!--Code Postal-->
                                                <div class="col-4">
                                                    <mat-form-field class="full-width" appearance="standard">
                                                        <mat-label>Code Postal</mat-label>
                                                        <input type="number" min="0" (keypress)="numberOnly($event)" matInput placeholder="0"  readonly formControlName="CP" readonly>
                                                    </mat-form-field>

                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <!-- Info Interlocuteur -->
            <mat-expansion-panel class="expension-secondary-color" [expanded]="step === (1)" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="font-primary-geoprod">- Interlocuteur</h2>
                    </mat-panel-title>
                    <mat-panel-description>
                        <!--Description-->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <app-prospect-form [schemaValue]="prospectSchema" (onSelectToFillOldProspect)="prospectFinder($event)" [index]="'0'" [isMainProspect]="true" [type]="type" [prospect]="prospectPro.mainProspect" (onUpdateProspect)="updateProspect($event,-1)">
                </app-prospect-form>
            </mat-expansion-panel>
            <!--Ifo Employers-->
            <ng-container *ngFor="let prospect of prospectPro.listProspectsLier; let index =index">
                <mat-expansion-panel *ngIf="!prospect.deleted" class="expension-secondary-color" [expanded]="step === (index+2)" (opened)="setStep(index+2)" hideToggle>
                    <mat-expansion-panel-header>
                        <div class="col-6">
                            <mat-panel-title>
                                <h2 class="font-primary-geoprod">Collaborateur N°{{index + 1}}:</h2>
                            </mat-panel-title>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-2">
                            <mat-panel-description style="float: right;">
                                <!--Description-->
                                <!-- <span (click)="deleteProspect(index+1)" style="margin-top: 19px;">
                                  <img src="assets/icons/trash-icon-red.svg">
                                </span> -->
                            </mat-panel-description>
                        </div>

                    </mat-expansion-panel-header>
                    <app-prospect-form [schemaValue]="prospectSchema" (onSelectToFillOldProspect)="prospectFinder($event)" [type]="1" [index]="(index+1).toString()" [isMainProspect]="false" [prospect]="prospect" (onUpdateProspect)="updateProspect($event,index+1)"></app-prospect-form>
                </mat-expansion-panel>
            </ng-container>
            <div *ngIf="addProspectBtn" style="margin: 25px 0;" class="row">
                <div class="col-12 text-right">
                    <button type="button" (click)="addProspect()" class="add-btn-em">
                      <img class="fa-icon-add" src="assets/icons/add-icon.svg">&nbsp;
                      <span>Ajouter collaborateur</span>
                    </button>
                </div>
            </div>
        </mat-accordion>
    </div>
</div>