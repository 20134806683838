import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsComponent } from './buttons/buttons.component';
import { SharedModule } from '../shared/shared.module';
import { TemplateComponent } from './template/template.component';
import { TemplateRouting } from './template-routing.module';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { CodeComponent } from './code/code.component';
import { DataTableComponent } from './data-table/data-table.component';
import { FormFieldsComponent } from './form-fields/form-fields.component';
import { InputsComponent } from './inputs/inputs.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [
    TemplateComponent,
    ButtonsComponent,
    CodeComponent,
    DataTableComponent,
    FormFieldsComponent,
    InputsComponent,
  ],
  imports: [
    CommonModule,
    HighlightModule,
    TemplateRouting,
    SharedModule,
    MatFormFieldModule,
    NgxMatColorPickerModule,
    MatMenuModule,
    MatStepperModule,
  ],
  exports: [],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'), // Optional, only if you want the line numbers
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml'),
          hml: () => import('highlight.js/lib/languages/xml'),
        },
      },
    },
  ],
})
export class TemplateModule {}
