import { environment } from './../../../environments/environment';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { inputs } from '@syncfusion/ej2-angular-calendars/src/calendar/calendar.component';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

export interface State {
  flag: string;
  name: string;
  population: string;
}

export class AdressEmitter {
  street_number: string = '';
  route: string = '';
  name: string = '';
  locality: string = '';
  postal_code: string = '';
  pays: string = '';
  longitude: number = 0;
  latitude: number = 0;
  geometry: any = null;
  adresseComplete?: string;
}

@Component({
  selector: 'app-auto-complete-adresses',
  templateUrl: './auto-complete-adresses.component.html',
  styleUrls: ['./auto-complete-adresses.component.css'],
})
export class AutoCompleteAdressesComponent implements OnInit {
  @Output() onChangeAdresse: EventEmitter<AdressEmitter> = new EventEmitter();
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @ViewChild('filterName') input: ElementRef;
  @Input() iconColor = 'black';
  @Input() isReadOnlyAdresse: boolean = false;
  adress: AdressEmitter = new AdressEmitter();
  placeholderText: string;
  adresseComplete: string;
  country = environment.country;

  options = {
    types: [],
    componentRestrictions: { country: this.country },
    fields: ['address_components', 'geometry', 'icon', 'name'],
  };

  constructor() {
    if (this.country === 'TN') this.placeholderText = 'Tunis, Tunisie ...';
    else if (this.country === 'FR') this.placeholderText = '5 rue picot, Toulon...';
  }

  ngOnInit(): void {}

  public handleAddressChange(address: Address) {
    this.adress = new AdressEmitter();
    this.adress.name = address.name;

    address.address_components.forEach((elem) => {
      if (elem.types.includes('route')) {
        this.adress.route = elem.long_name;
      }
      if (elem.types.includes('postal_code')) {
        this.adress.postal_code = elem.long_name;
      }
      if (elem.types.includes('locality') && elem.types.includes('political')) {
        this.adress.locality = elem.long_name;
      }
      if (elem.types.includes('country')) {
        this.adress.pays = elem.long_name;
      }
      if (elem.types.includes('street_number')) {
        this.adress.pays = elem.long_name;
      }
    });
    if (!this.adress.postal_code && this.country === 'TN') this.adress.postal_code = this.findZipCode(address);
    this.adresseComplete = this.adress.name + ', ' + this.adress.locality + ', ' + this.adress.pays;
    this.adress.latitude = address.geometry.location.lat();
    this.adress.longitude = address.geometry.location.lng();
    this.adress.geometry = address.geometry;
    this.adress.adresseComplete = this.adresseComplete;
    this.onChangeAdresse.emit(this.adress);
    this.input.nativeElement.value = '';
  }

  findZipCode(address): string {
    let codePostal: string;
    if (this.matchNumberHad4Digits(address.name) && this.matchNumberHad4Digits(address.name).length === 4) {
      codePostal = this.matchNumberHad4Digits(address.name);
    }
    address.address_components.forEach((elem) => {
      if (
        elem.types.includes('route') ||
        elem.types.includes('country') ||
        (elem.types.includes('locality') && elem.types.includes('political'))
      ) {
        if (this.matchNumberHad4Digits(elem.long_name) && this.matchNumberHad4Digits(elem.long_name).length === 4) {
          codePostal = this.matchNumberHad4Digits(elem.long_name);
        }
      }
    });
    return codePostal;
  }

  matchNumberHad4Digits(value: string): string {
    if (value) {
      const numberPattern = /\d{4}/;
      if (numberPattern.test(value)) return value.match(numberPattern).join();
    }
  }
}
