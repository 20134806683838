/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {environment} from "../../../environments/environment";


@Injectable()
export class ApiContratsService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Sert a inserer un contrat (Type , active, vehicule) avec ses documents (obligatoire ou non ) et groupe ou modifier un contrat (Type , active, vehicule) avec ses documents (obligatoire ou non ) et groupe  suivant id contrat codé en md5
   *
   * @param cle Cle de l&#x27;operation soit create ou update
   * @param type Type de contrat (Assurance Moto par exemple)
   * @param documents Une liste Des objets Json des documents separé par des (,)
   * @param groupe Assurance des personne (1) , Assurance des biens (2), separer par des (,)
   * @param active Contrat active (true), Si non false
   * @param contratId Identifiant de contrat codé en md5 (Obligatoire seulement pour la supression)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createOrUpdateContratGet(cle: string, type: ModelObject, documents: ModelObject, groupe: ModelObject, active: string, contratId?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createOrUpdateContratGet(cle: string, type: ModelObject, documents: ModelObject, groupe: ModelObject, active: string, contratId?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createOrUpdateContratGet(cle: string, type: ModelObject, documents: ModelObject, groupe: ModelObject, active: string, contratId?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createOrUpdateContratGet(cle: string, type: ModelObject, documents: ModelObject, groupe: ModelObject, active: string, contratId?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling createOrUpdateContratGet.');
    }

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling createOrUpdateContratGet.');
    }

    if (documents === null || documents === undefined) {
      throw new Error('Required parameter documents was null or undefined when calling createOrUpdateContratGet.');
    }

    if (groupe === null || groupe === undefined) {
      throw new Error('Required parameter groupe was null or undefined when calling createOrUpdateContratGet.');
    }

    if (active === null || active === undefined) {
      throw new Error('Required parameter active was null or undefined when calling createOrUpdateContratGet.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (contratId !== undefined && contratId !== null) {
      queryParameters = queryParameters.set('contrat_id', <any>contratId);
    }
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    }
    if (documents !== undefined && documents !== null) {
      queryParameters = queryParameters.set('documents', <any>documents);
    }
    if (groupe !== undefined && groupe !== null) {
      queryParameters = queryParameters.set('groupe', <any>groupe);
    }
    if (active !== undefined && active !== null) {
      queryParameters = queryParameters.set('active', <any>active);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/create_or_update_contrat`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher le form de contrat suivant id_contrat codé en md5
   *
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public formContratGet(idContrat: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public formContratGet(idContrat: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public formContratGet(idContrat: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public formContratGet(idContrat: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling formContratGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/form_contrat`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher la liste des documents d&#x27;un contrat assurance suivant son identifiant codé en md5
   *
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listDocumentContratGet(idContrat: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public listDocumentContratGet(idContrat: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public listDocumentContratGet(idContrat: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public listDocumentContratGet(idContrat: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling listDocumentContratGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/list_document_contrat`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher la liste des lignes de produits
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listeAllProduits( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/list_all_produit`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



  /**
   * Afficher les produit  sante suivant le cie de la campagnie et l’id_contrat codé en md5
   *
   * @param idCamp Identifiant de  la campagnie de produit santé codé en md5
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheProduitCieGet(idCamp: ModelObject, idContrat: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public rechercheProduitCieGet(idCamp: ModelObject, idContrat: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public rechercheProduitCieGet(idCamp: ModelObject, idContrat: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public rechercheProduitCieGet(idCamp: ModelObject, idContrat: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idCamp === null || idCamp === undefined) {
      throw new Error('Required parameter idCamp was null or undefined when calling rechercheProduitCieGet.');
    }

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling rechercheProduitCieGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idCamp !== undefined && idCamp !== null) {
      queryParameters = queryParameters.set('id_camp', <any>idCamp);
    }
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/recherche_produit_cie`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher les information sur une opportunité suivant la campagne et l&#x27;ancien contrat
   *
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param idOpp Identifiant de l&#x27;opportunnité codé en md5
   * @param idCamp Identifiant de la campagnie codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectDataAncContratGet(idContrat: string, idOpp: ModelObject, idCamp: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectDataAncContratGet(idContrat: string, idOpp: ModelObject, idCamp: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectDataAncContratGet(idContrat: string, idOpp: ModelObject, idCamp: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectDataAncContratGet(idContrat: string, idOpp: ModelObject, idCamp: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling selectDataAncContratGet.');
    }

    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling selectDataAncContratGet.');
    }

    if (idCamp === null || idCamp === undefined) {
      throw new Error('Required parameter idCamp was null or undefined when calling selectDataAncContratGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', <any>idOpp);
    }
    if (idCamp !== undefined && idCamp !== null) {
      queryParameters = queryParameters.set('id_camp', <any>idCamp);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/select_data_anc_contrat`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher des information sur un contrat suivant son identifiant codé en md5
   *
   * @param idContrat &lt;p&gt;Identifiant de contrat codé en md5&lt;br&gt;c81e728d9d4c2f636f067f89cc14862c  Assurance Auto &lt;br&gt; eccbc87e4b5ce2fe28308fd9f2a7baf3 Assurance Moto&lt;br&gt; 8f14e45fceea167a5a36dedd4bea2543 Protection Juridique &lt;br&gt; c9f0f895fb98ab9159f51fd0297e236d GAV&lt;br&gt; d3d9446802a44259755d38e6d163e820 Santé&lt;br&gt; 6512bd43d9caa6e02c990b0a82652dca Obseques&lt;br&gt; c20ad4d76fe97759aa27a0c99bff6710 Animaux&lt;br&gt; c51ce410c124a10e0db5e4b97fc2af39 RCCANSF&lt;br&gt; aab3238922bcc25a6f606eb525ffdc56 Protection Juridique&lt;br&gt; 9bf31c7ff062936a96d3c8bd1f8f2ff3 Immeubles&lt;br&gt; c74d97b01eae257e44aa9d5bade97baf Multi Pro&lt;br&gt; 70efdf2ec9b086079795c442636b55fb Loyers Impayés&lt;br&gt; 6f4922f45568161a8cdf4ad2299f6d23 PNO  &lt;br&gt; 1f0e3dad99908345f7439f8ffabdffc4 Habitat S &lt;br&gt; 98f13708210194c475687be6106a3b84 Habitat P &lt;br&gt; 3c59dc048e8850243be8079a5c74d079 Véhicule &lt;br&gt; b6d767d2f8ed5d21a44b0e5886680cb9 Dotation &lt;br&gt; 37693cfc748049e45d87b8c7d8b9aacd Epargne - Finance &lt;br&gt; 1ff1de774005f8da13f42943881c655f Retraite &lt;br&gt; 8e296a067a37563370ded05f5a3bf3ec Dépandance &lt;br&gt; 4e732ced3463d06de0ca9a15b6153677 Tempo Décés &lt;br&gt; 02e74f10e0327ad868d138f2b4fdd6f0 Prév-Décés-Inv-IJ &lt;br&gt; e369853df766fa44e1ed0ff613f563bd Santé  collective  Partie Entreprise &lt;br&gt; 1c383cd30b7c298ab50293adfecb7b18 Santé Collective Partie Salarié &lt;br&gt; 19ca14e7ea6328a42e0eb13d585e4c22 IJH &lt;br&gt;  a5bfc9e07964f8dddeb95fc584cd965d  Autres  &lt;br&gt;a5771bce93e200c36f7cd9dfd0e5deaa  Assurance de Prêt&lt;br&gt; les identifiant sont codé respectivement dans la liste deroulante : &lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectcontratGet(idContrat: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectcontratGet(idContrat: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectcontratGet(idContrat: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectcontratGet(idContrat: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idContrat === null || idContrat === undefined) {
      throw new Error('Required parameter idContrat was null or undefined when calling selectcontratGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (idContrat !== undefined && idContrat !== null) {
      queryParameters = queryParameters.set('id_contrat', <any>idContrat);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/selectcontrat`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher les information sur l’affaire suivant son identifiant codé en md5 l’identifiant de contrat (codé en md5) ou seulement la liste l’identifiant de l’affaire codé en md5.
   *
   * @param numContrat Identifiant de contrat
   * @param idAffaire Identifiant de l&#x27;affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public verifNumContratGet(numContrat: ModelObject, idAffaire?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public verifNumContratGet(numContrat: ModelObject, idAffaire?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public verifNumContratGet(numContrat: ModelObject, idAffaire?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public verifNumContratGet(numContrat: ModelObject, idAffaire?: ModelObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (numContrat === null || numContrat === undefined) {
      throw new Error('Required parameter numContrat was null or undefined when calling verifNumContratGet.');
    }


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (numContrat !== undefined && numContrat !== null) {
      queryParameters = queryParameters.set('num_contrat', <any>numContrat);
    }
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>idAffaire);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get',`${this.basePath}/verif_num_contrat`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



  
}
