import { Component, OnInit } from '@angular/core';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-new-add-tickets',
  templateUrl: './add-new-tickets.component.html',
  styleUrls: ['./add-new-tickets.component.css'],
})
export class AddNewTicketsComponent implements OnInit {
  constructor(private sharedMenuObserverService: SharedMenuObserverService) {}

  type: string = 't';
  fromDetails: Boolean = false;
  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Tickets & Support ➜ Ajouter');
  }
}
