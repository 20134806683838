import {CustomItems, CustomWidget} from './SharedElementsFormly';

/**
 * Json schema form input field
 */
export class FormField {
  toggle: boolean = false;
  disabled: boolean = false;
  name: string;
  typename: string;
  title: string = 'Texte';
  type: string = '';
  default: string | number;
  format: string;
  description:string;
  icon:string;
  widget: CustomWidget = new CustomWidget();
  items = new CustomItems();

  constructor() {
    this.widget.formlyConfig.templateOptions.col='6';
  }

  generateRandomId() {
    let possible = '1234567890';
    const lengthOfCode = 4;
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
