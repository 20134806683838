export class FilterBanque {
    sSearch:string=""
    nom :string="";
    adresse = { 
        ville : "",
        pays:"",
        code_postal:"",
        streetName:"",
        streetNumber :""
    };
    code_swift : string ="";
    code_banque : string="";
}



