import {Configuration} from "../services/configuration";
import {UserLogin} from "../login/login.component";

export class ApiConfigurationFactory {
  static createConfiguration() {
    let userLogin: UserLogin = new UserLogin();
    let configuration = new Configuration
    (
      {
        'apiKeys':
          {
            'idSession': "",
            'apiKey': "",
            "PRIVATE-TOKEN": ""
          },
        'id_user': ''
      }
    );
    userLogin.load();
    if(userLogin?.Acces_Token?.length>1) {
      configuration.setLogin(userLogin)
    }
    return configuration;
  }
}
