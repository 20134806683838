/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from "../../../environments/environment";


@Injectable()
export class ApiCampagniesService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Afficherla liste de toutes les compagnies avec tous les details
   */
  public getListCompagniesDetails(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/list_compagnies_details?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher la liste des noms et srien de toutes les compagnies
   */
  public getListCompagnies(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_compagnies?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher le mail de production de la campagnie suivant la siren exactement
   *
   * @param id_camp id campagne
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInfoCompementairesByCampId(id_camp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id_camp === null || id_camp === undefined) {
      throw new Error('Required parameter id_camp was null or undefined when calling getMailCmpGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_camp !== undefined && id_camp !== null) {
      queryParameters = queryParameters.set('id_camp', <any>id_camp);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/selectcamp1`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a retourner la liste des organisme
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetListOrganisme(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_organismes?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public GetAllOrganisme(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_organisme?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher le mail de production de la campagnie suivant la siren exactement
   *
   * @param id_camp id campagne
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListBpmByOppId(id_opp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id_opp === null || id_opp === undefined) {
      throw new Error('Required parameter id_camp was null or undefined when calling getMailCmpGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_opp !== undefined && id_opp !== null) {
      queryParameters = queryParameters.set('id_opp', <any>id_opp);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/list_bpm`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher le mail de production de la campagnie suivant la siren exactement
   *
   * @param siren Siren de la campagnie
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMailCmpGet(siren: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (siren === null || siren === undefined) {
      throw new Error('Required parameter siren was null or undefined when calling getMailCmpGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (siren !== undefined && siren !== null) {
      queryParameters = queryParameters.set('siren', <any>siren);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_mail_cmp`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * sert a afficher samedi_off heure de debut et fin d’un utilisateur suivant son id codé en md5
   *
   * @param SIREN Siren de la societé
   * @param SIRET Siret de la soxieté
   * @param rs Raison sociale de la societé
   * @param fj forme juridique de la socité
   * @param naf Identifiant de code NAF
   * @param web Site web de la societé
   * @param fax fax
   * @param mail mail societé
   * @param effectif Effectif de la societé
   * @param convention convention Collective
   * @param lat lattitude
   * @param lng longitude
   * @param tel telephone de la societé
   * @param streetNumber numero dans la rue de la societé
   * @param rue rue de la societé
   * @param ville ville de la societé
   * @param nomCom Nom de commerciale de la societé
   * @param respensable respensable de la societé
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public insertSocieteGet(SIREN: ModelObject, SIRET: ModelObject, rs: ModelObject, fj?: ModelObject, naf?: ModelObject, web?: ModelObject, fax?: ModelObject, mail?: ModelObject, effectif?: ModelObject, convention?: ModelObject, lat?: ModelObject, lng?: ModelObject, tel?: ModelObject, streetNumber?: ModelObject, rue?: ModelObject, ville?: ModelObject, nomCom?: ModelObject, respensable?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public insertSocieteGet(SIREN: ModelObject, SIRET: ModelObject, rs: ModelObject, fj?: ModelObject, naf?: ModelObject, web?: ModelObject, fax?: ModelObject, mail?: ModelObject, effectif?: ModelObject, convention?: ModelObject, lat?: ModelObject, lng?: ModelObject, tel?: ModelObject, streetNumber?: ModelObject, rue?: ModelObject, ville?: ModelObject, nomCom?: ModelObject, respensable?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public insertSocieteGet(SIREN: ModelObject, SIRET: ModelObject, rs: ModelObject, fj?: ModelObject, naf?: ModelObject, web?: ModelObject, fax?: ModelObject, mail?: ModelObject, effectif?: ModelObject, convention?: ModelObject, lat?: ModelObject, lng?: ModelObject, tel?: ModelObject, streetNumber?: ModelObject, rue?: ModelObject, ville?: ModelObject, nomCom?: ModelObject, respensable?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public insertSocieteGet(SIREN: ModelObject, SIRET: ModelObject, rs: ModelObject, fj?: ModelObject, naf?: ModelObject, web?: ModelObject, fax?: ModelObject, mail?: ModelObject, effectif?: ModelObject, convention?: ModelObject, lat?: ModelObject, lng?: ModelObject, tel?: ModelObject, streetNumber?: ModelObject, rue?: ModelObject, ville?: ModelObject, nomCom?: ModelObject, respensable?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (SIREN === null || SIREN === undefined) {
      throw new Error('Required parameter SIREN was null or undefined when calling insertSocieteGet.');
    }

    if (SIRET === null || SIRET === undefined) {
      throw new Error('Required parameter SIRET was null or undefined when calling insertSocieteGet.');
    }

    if (rs === null || rs === undefined) {
      throw new Error('Required parameter rs was null or undefined when calling insertSocieteGet.');
    }
















    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (SIREN !== undefined && SIREN !== null) {
      queryParameters = queryParameters.set('SIREN', <any>SIREN);
    }
    if (SIRET !== undefined && SIRET !== null) {
      queryParameters = queryParameters.set('SIRET', <any>SIRET);
    }
    if (rs !== undefined && rs !== null) {
      queryParameters = queryParameters.set('rs', <any>rs);
    }
    if (fj !== undefined && fj !== null) {
      queryParameters = queryParameters.set('fj', <any>fj);
    }
    if (naf !== undefined && naf !== null) {
      queryParameters = queryParameters.set('naf', <any>naf);
    }
    if (web !== undefined && web !== null) {
      queryParameters = queryParameters.set('web', <any>web);
    }
    if (fax !== undefined && fax !== null) {
      queryParameters = queryParameters.set('fax', <any>fax);
    }
    if (mail !== undefined && mail !== null) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (effectif !== undefined && effectif !== null) {
      queryParameters = queryParameters.set('effectif', <any>effectif);
    }
    if (convention !== undefined && convention !== null) {
      queryParameters = queryParameters.set('convention', <any>convention);
    }
    if (lat !== undefined && lat !== null) {
      queryParameters = queryParameters.set('lat', <any>lat);
    }
    if (lng !== undefined && lng !== null) {
      queryParameters = queryParameters.set('lng', <any>lng);
    }
    if (tel !== undefined && tel !== null) {
      queryParameters = queryParameters.set('tel', <any>tel);
    }
    if (streetNumber !== undefined && streetNumber !== null) {
      queryParameters = queryParameters.set('street_number', <any>streetNumber);
    }
    if (rue !== undefined && rue !== null) {
      queryParameters = queryParameters.set('rue', <any>rue);
    }
    if (ville !== undefined && ville !== null) {
      queryParameters = queryParameters.set('ville', <any>ville);
    }
    if (nomCom !== undefined && nomCom !== null) {
      queryParameters = queryParameters.set('nom_com', <any>nomCom);
    }
    if (respensable !== undefined && respensable !== null) {
      queryParameters = queryParameters.set('respensable', <any>respensable);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/insert_societe`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;afficher les informations sur les status et ses etats fils par idrole (exemple Qualificateur is_role &#x3D;2 ) ou par role et campagnie (id campagnie)
   *
   * @param cle Cle de l&#x27;operation soit par id_role uniquement ou par id_role et id campagnie
   * @param idRole Identifiant de role codé en md5 (A demander par mail a it@sodedif.fr) &lt;br&gt; (Exemple de role 1 tSuper Admin 2 Qualificateur 3 Superviseur CC 5 COMMERCIAL)
   * @param listcamp List des identifiants des campagnies separé apr des points virgule
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listStatutCampGet(cle: string, idRole: ModelObject, listcamp?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public listStatutCampGet(cle: string, idRole: ModelObject, listcamp?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public listStatutCampGet(cle: string, idRole: ModelObject, listcamp?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public listStatutCampGet(cle: string, idRole: ModelObject, listcamp?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling listStatutCampGet.');
    }

    if (idRole === null || idRole === undefined) {
      throw new Error('Required parameter idRole was null or undefined when calling listStatutCampGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (idRole !== undefined && idRole !== null) {
      queryParameters = queryParameters.set('id_role', <any>idRole);
    }
    if (listcamp !== undefined && listcamp !== null) {
      queryParameters = queryParameters.set('listcamp', <any>listcamp);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/list_statut_camp`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * afficher l’identifiant  et le denomination d’une campagnie suivant une partie de sa denomination
   *
   * @param dataQ une partie de sa denomination de campagnies
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheCompanieGet(dataQ: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // if (dataQ === null || dataQ === undefined) {
    //   throw new Error('Required parameter dataQ was null or undefined when calling rechercheCompanieGet.');
    // }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_companie`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *  sert a afficher la libelle et  l’identifiant de naf suivant une partie de la libelle
   *
   * @param dataQ une partie de libelle NAF
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheNafCloudGet(dataQ: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public rechercheNafCloudGet(dataQ: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public rechercheNafCloudGet(dataQ: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public rechercheNafCloudGet(dataQ: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling rechercheNafCloudGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_naf_cloud`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert afficher les societé Dont le siren , RS , ou Id est similaire a val ainsi que le code postale
   *
   * @param type Critére de recherche soit par SIREN , RS ou ID
   * @param val Une partie de valeur de type de recherche
   * @param CP Code postale de la societé
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheSocieteGet(type: string, val: ModelObject, CP?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public rechercheSocieteGet(type: string, val: ModelObject, CP?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public rechercheSocieteGet(type: string, val: ModelObject, CP?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public rechercheSocieteGet(type: string, val: ModelObject, CP?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling rechercheSocieteGet.');
    }

    if (val === null || val === undefined) {
      throw new Error('Required parameter val was null or undefined when calling rechercheSocieteGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    }
    if (CP !== undefined && CP !== null) {
      queryParameters = queryParameters.set('CP', <any>CP);
    }
    if (val !== undefined && val !== null) {
      queryParameters = queryParameters.set('val', <any>val);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_societe`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher la libelle et l’identifiant de naf suivant la libelle et groupe ,section et division
   *
   * @param dataQ Libelle de naf
   * @param groupe Identifiant groupe de section naf Refere l’identifiant naf groupe (a demander par mail a it@sodedif.com)
   * @param division Identifiant division de section naf Refere l’identifiant naf division (a demander par mail a it@sodedif.com)
   * @param section &lt;p&gt; A Agriculture, sylviculture et pêche &lt;br&gt;B Industries extractives&lt;br&gt;C Industrie manufacturière&lt;br&gt;D Production et distribution d&#x27;électricité, de gaz, de vapeur et d&#x27;air conditionné&lt;br&gt;E Production et distribution d&#x27;eau , assainissement, gestion des déchets et dépollution&lt;br&gt;F Construction&lt;br&gt;G Commerce , réparation d&#x27;automobiles et de motocycles&lt;br&gt;H Transports et entreposage&lt;br&gt;I Hébergement et restauration&lt;br&gt;J Information et communication&lt;br&gt;K Activités financières et d&#x27;assurance&lt;br&gt;L Activités immobilières&lt;br&gt;M Activités spécialisées, scientifiques et techniques&lt;br&gt;N Activités de services administratifs et de soutien&lt;br&gt;O Administration publique&lt;br&gt;P Enseignement&lt;br&gt;Q Santé humaine et action sociale&lt;br&gt;R Arts, spectacles et activités récréatives&lt;br&gt;S Autres activités de services&lt;br&gt;T Activités des ménages en tant qu&#x27;employeurs ; activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre&lt;br&gt;U Activités extra-territoriales
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectNafGet(dataQ: ModelObject, groupe: ModelObject, division: ModelObject, section: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectNafGet(dataQ: ModelObject, groupe: ModelObject, division: ModelObject, section: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectNafGet(dataQ: ModelObject, groupe: ModelObject, division: ModelObject, section: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectNafGet(dataQ: ModelObject, groupe: ModelObject, division: ModelObject, section: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling selectNafGet.');
    }

    if (groupe === null || groupe === undefined) {
      throw new Error('Required parameter groupe was null or undefined when calling selectNafGet.');
    }

    if (division === null || division === undefined) {
      throw new Error('Required parameter division was null or undefined when calling selectNafGet.');
    }

    if (section === null || section === undefined) {
      throw new Error('Required parameter section was null or undefined when calling selectNafGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }
    if (groupe !== undefined && groupe !== null) {
      queryParameters = queryParameters.set('groupe', <any>groupe);
    }
    if (division !== undefined && division !== null) {
      queryParameters = queryParameters.set('division', <any>division);
    }
    if (section !== undefined && section !== null) {
      queryParameters = queryParameters.set('section', <any>section);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/select_naf`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher la libelle et l’identifiant de naf suivant la libelle et la section ou uniquement suivant la libelle
   *
   * @param cle Cle de l&#x27;operation par section (libelle uniquement) ou division (section et libelle)
   * @param dataQ Libelle de naf
   * @param section &lt;p&gt; A Agriculture, sylviculture et pêche &lt;br&gt;B Industries extractives&lt;br&gt;C Industrie manufacturière&lt;br&gt;D Production et distribution d&#x27;électricité, de gaz, de vapeur et d&#x27;air conditionné&lt;br&gt;E Production et distribution d&#x27;eau , assainissement, gestion des déchets et dépollution&lt;br&gt;F Construction&lt;br&gt;G Commerce , réparation d&#x27;automobiles et de motocycles&lt;br&gt;H Transports et entreposage&lt;br&gt;I Hébergement et restauration&lt;br&gt;J Information et communication&lt;br&gt;K Activités financières et d&#x27;assurance&lt;br&gt;L Activités immobilières&lt;br&gt;M Activités spécialisées, scientifiques et techniques&lt;br&gt;N Activités de services administratifs et de soutien&lt;br&gt;O Administration publique&lt;br&gt;P Enseignement&lt;br&gt;Q Santé humaine et action sociale&lt;br&gt;R Arts, spectacles et activités récréatives&lt;br&gt;S Autres activités de services&lt;br&gt;T Activités des ménages en tant qu&#x27;employeurs ; activités indifférenciées des ménages en tant que producteurs de biens et services pour usage propre&lt;br&gt;U Activités extra-territoriales
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectSectionOrDivisionNafGet(cle: string, dataQ: ModelObject, section?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectSectionOrDivisionNafGet(cle: string, dataQ: ModelObject, section?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectSectionOrDivisionNafGet(cle: string, dataQ: ModelObject, section?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectSectionOrDivisionNafGet(cle: string, dataQ: ModelObject, section?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling selectSectionOrDivisionNafGet.');
    }

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling selectSectionOrDivisionNafGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }
    if (section !== undefined && section !== null) {
      queryParameters = queryParameters.set('section', <any>section);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/select_section_or_division_naf`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retourne les information sur la campagne suivant id_prospect (Campagene de add_prospect)et idcampagne ou sur une idcampagne uniquement
   *
   * @param cle Cle de l&#x27;opeartion par id add_prospect (Campagen de add_prospect) , nom et idcamp (campagne suivant son id_camp codé en md5)
   * @param idProspect id_prospect codé en md5
   * @param idCamp id campagne codé en MD5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectcampGet(cle?: string, idProspect?: ModelObject, idCamp?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectcampGet(cle?: string, idProspect?: ModelObject, idCamp?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectcampGet(cle?: string, idProspect?: ModelObject, idCamp?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectcampGet(cle?: string, idProspect?: ModelObject, idCamp?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {




    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (idProspect !== undefined && idProspect !== null) {
      queryParameters = queryParameters.set('id_prospect', <any>idProspect);
    }
    if (idCamp !== undefined && idCamp !== null) {
      queryParameters = queryParameters.set('id_camp', <any>idCamp);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/selectcamp`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher les information sur une societé suivant son SIRET ou RS , Si le resultat est non vide c&#x27;est que la societé exciste
   *
   * @param cle &lt;p&gt;Cle de l&#x27;operation soit par siret ou rs &lt;br&gt; Si par SIRET , uniquement le paramtre siret est oblogtoire. &lt;br&gt; Si par rs, rs , num ,rue,ville,et cp sont obligatoire&lt;/p&gt;
   * @param siret Siret de la societe codé en md5
   * @param rs Nombre des jours
   * @param num Numero batiment de la societe dans sa rue
   * @param rue rue batiment de la societe
   * @param ville Nom de commune de la societé
   * @param cp Code postale de la ville de siege de la societé
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public testExistSocieteGet(cle?: string, siret?: ModelObject, rs?: ModelObject, num?: ModelObject, rue?: ModelObject, ville?: ModelObject, cp?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public testExistSocieteGet(cle?: string, siret?: ModelObject, rs?: ModelObject, num?: ModelObject, rue?: ModelObject, ville?: ModelObject, cp?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public testExistSocieteGet(cle?: string, siret?: ModelObject, rs?: ModelObject, num?: ModelObject, rue?: ModelObject, ville?: ModelObject, cp?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public testExistSocieteGet(cle?: string, siret?: ModelObject, rs?: ModelObject, num?: ModelObject, rue?: ModelObject, ville?: ModelObject, cp?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (siret !== undefined && siret !== null) {
      queryParameters = queryParameters.set('siret', <any>siret);
    }
    if (rs !== undefined && rs !== null) {
      queryParameters = queryParameters.set('rs', <any>rs);
    }
    if (num !== undefined && num !== null) {
      queryParameters = queryParameters.set('num', <any>num);
    }
    if (rue !== undefined && rue !== null) {
      queryParameters = queryParameters.set('rue', <any>rue);
    }
    if (ville !== undefined && ville !== null) {
      queryParameters = queryParameters.set('ville', <any>ville);
    }
    if (cp !== undefined && cp !== null) {
      queryParameters = queryParameters.set('cp', <any>cp);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/test_exist_societe`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getOrganismes(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    let headers = this.defaultHeaders;
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set(
        'idSession',
        this.configuration.apiKeys['idSession']
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/get_list_organismes`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

}
