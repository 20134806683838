import { environment } from 'src/environments/environment';
import { DatePipe , Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ListVille } from '../../../entity/ListVille';
import { UserProfile } from '../../../entity/UserProfile';
import { ApiProspectsService } from '../../../services/ApiProspects/api-prospects.service';
import { ApiUsersService } from '../../../services/ApiUsers/api-users.service';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { Router } from '@angular/router';
import { User } from '../../../entity/User/GroupUser';
import * as fa from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert2';
import { UserAdmin2 } from 'src/app/entity/User/UserAdmin2';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { WebmailService } from 'src/app/services/ApiWebmail/webmail.service';
import { ToastrService } from 'ngx-toastr';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('closeModal') closeModal; // permet de fermer le modal
  userInformation: User = new User();
  User: UserProfile = new UserProfile();
  utilisateur: UserAdmin2 = new UserAdmin2();
  initial_utilisateur: UserAdmin2 = new UserAdmin2();
  Villes: ListVille[] = [];
  step: number = 0;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  url;
  testimage: boolean = false;
  fa = fa;
  show_loading_add: boolean = false;
  picture: any = null;
  geomtry: boolean = false;
  password: boolean = false;
  pictureuser: any = null;

  todo: any[] = [];
  inbox: any[] = [];
  sent: any[] = [];
  draft: any[] = [];
  spam: any[] = [];
  trash: any[] = [];
  configue: boolean = false;
  picIsUploaded: boolean = false;
  croppeimage: boolean = true;
  tab = new FormControl(0);
  /* ---- Passowrd initialisation */
  ancienPassword: string = '';
  newpassword: string = '';
  confirmPassword: string = '';
  validatePassword: boolean = false;
  telCountry = environment.country;

  constructor(
    private apiUserServices: ApiUsersService,
    private route: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    public datepipe: DatePipe,
    private apiProspectsService: ApiProspectsService,
    private location: Location,
    private apiWebmail: WebmailService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.sharedMenuObserverService.updateIconMenu('faUserCircle');
    this.getInformationUser();
    this.utilisateur.user.password = '';
    this.sharedMenuObserverService.updateMenu('Profil');
    if (this.apiWebmail.tab === 3) this.tab.setValue(3);
  }

  checkConfirmPassword(event) {
    if (this.utilisateur.user.password !== this.confirmPassword && this.confirmPassword !== '') {
      this.validatePassword = true;
    } else {
      this.validatePassword = false;
    }
  }

  getInformationUser() {
    this.showloader();
    this.apiUserServices
      .getUserWithIdGet()
      .pipe(
        tap((data: UserAdmin2) => {
          this.utilisateur = data;
          this.croppedImage = data.user.photo;
          if (this.utilisateur.user.date_naiss_user != null) {
            this.utilisateur.user.date_naiss_user = this.datepipe.transform(
              this.utilisateur.user.date_naiss_user,
              'yyyy-MM-dd'
            );
          }
          Object.assign(this.initial_utilisateur, data);
          this.hideloader();
        })
      )
      .subscribe();
    this.getFolders();
  }

  getListeVille(CodePostal) {
    this.apiProspectsService.getListVille(CodePostal).subscribe((Response: ListVille[]) => {
      this.Villes = Response;
    });
  }

  retourToList() {
    this.location.back();
  }

  getFolders() {
    this.apiWebmail.getListFolfder().subscribe(
      (data) => {
        this.todo = data.list_name_folders;
        this.configue = data.is_configured;
        if (this.configue) {
          if (data.configured_folders.inbox) this.inbox = data.configured_folders.inbox;
          if (data.configured_folders.sent) this.sent = data.configured_folders.sent;
          if (data.configured_folders.drafts) this.draft = data.configured_folders.drafts;
          if (data.configured_folders.spam) this.spam = data.configured_folders.spam;
          if (data.configured_folders.trash) this.trash = data.configured_folders.trash;
          this.configue = data.is_configured;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  clearForm() {
    Object.assign(this.utilisateur, this.initial_utilisateur);
  }

  cancel() {
    this.croppeimage = false;
    this.croppedImage = null;
    this.croppedImage = this.utilisateur.user.photo;
    this.testimage = false;
    this.imageChangedEvent = '';
  }

  alertSuccess(data) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showConfirmButton: true,
        confirmButtonColor: '#68a45b',
        confirmButtonText: 'Fermer',
      })
      .then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
  }

  alertWarning(data) {
    swal.fire({
      title: 'Quelque chose ne va pas!',
      icon: 'warning',
      text: data,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#f9a65d',
    });
  }

  alertError(data, title) {
    swal.fire({
      title: title,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.utilisateur.ville.nom_comm = adress.locality;
    this.utilisateur.adresse.CP = adress.postal_code;
    this.utilisateur.adresse.streetName = adress.route;
    this.utilisateur.adresse.streetNumber = adress.street_number;
    this.utilisateur.adresse.latitude = adress.latitude;
    this.utilisateur.adresse.longitude = adress.longitude;
    this.utilisateur.adresse.geom = adress.geometry;
    this.utilisateur.ville.id_pays = adress.pays;
    this.checkGeomtry();
  }

  checkPassoword(value) {
    this.password = true;
  }

  checkGeomtry() {
    this.geomtry = true;
  }

  updateInformationsUser() {
    this.show_loading_add = true;

    if (!this.geomtry) {
      this.utilisateur.adresse.geom = '';
    }

    if (this.picture === null) {
      this.updateUser();
    } else this.UploadPicture();
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }
  // getPicture(event) {
  //   this.picture = event.target.files[0]
  // }

  UploadPicture() {
    const formData = new FormData();
    formData.append('image', this.picture);
    this.apiUserServices.uploadPicture(this.utilisateur.user.id, formData).subscribe(
      (Response) => {
        this.utilisateur.user.photo = Response.path;
        this.picIsUploaded = true;
        this.updateUser();
        this.testimage = true;
        this.getInformationUser();
      },
      () => {
        this.picIsUploaded = false;
        this.updateUser();
        this.getInformationUser();
      }
    );
  }

  updateUser() {
    if (this.validatePassword) {
      this.alertWarning('Nouveau mot de passe invalid!');
      this.show_loading_add = false;
    } else {
      this.utilisateur.user.user_mail_data = this.utilisateur.user.user_mail_data?.trim();
      this.utilisateur.user.server_in_mail_data = this.utilisateur.user.server_in_mail_data?.trim();
      this.utilisateur.user.server_out_mail_data = this.utilisateur.user.server_out_mail_data?.trim();
      this.utilisateur.user.port_in_mail_data = this.utilisateur.user.port_in_mail_data?.trim();
      this.utilisateur.user.port_out_mail_data = this.utilisateur.user.port_out_mail_data?.trim();
      this.apiUserServices.UpdateUser(this.utilisateur.user.id, this.utilisateur).subscribe(
        (Response) => {
          if (
            (this.picIsUploaded && Response.message === 'Aucune modification faite  ') ||
            Response.message === 'Utilisateur modifé avec succées'
          )
            this.alertSuccess('Modification(s) effectuée(s) avec succès');
          else {
            this.alertSuccess(Response.message);
          }
          this.show_loading_add = false;
        },
        (err) => {
          this.show_loading_add = false;
          if (err.includes('403')) this.alertWarning('Ancien mot de passe invalide!');
          else this.alertError('Erreur', err);
        }
      );
    }
  }

  movementCount: number = 0;
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  correspandance() {
    this.show_loading_add = true;
    const Dossiers = { Inbox: this.inbox, Sent: this.sent, Drafts: this.draft, Spam: this.spam, Trash: this.trash };
    const emptyDossiers = Object.keys(Dossiers).reduce((result, key) => {
      if (Dossiers[key].length === 0) {
        result.push(key);
      }
      return result;
    }, []);
    if (emptyDossiers.length !== 0) {
      this.alertWarning('Il faut choisir au moins une correspondance pour chaque dossier');
      this.show_loading_add = false;
    } else {
      this.apiWebmail.correspandanceDossiers(Dossiers).subscribe(
        (data) => {
          this.toastr.success('WebMail configuré avec succés ');
          this.show_loading_add = false;
          location.reload();
          this.closeModal?.nativeElement?.click();
        },
        () => {
          this.show_loading_add = false;
          this.closeModal?.nativeElement?.click();
        }
      );
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    // Preview
    this.croppedImage = event.base64;
    this.picture = this.base64ToFile(event.base64, this.utilisateur.user.prenom + '_' + new Date().getTime());
    this.utilisateur.user.photo = this.picture;
    this.testimage = true;
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  updateInformationsUserTest() {
    this.todo = [];
    if (this.picture === null) {
      this.utilisateur.user.photo = '';
    } else this.UploadPicture();
    if (!this.geomtry) {
      this.utilisateur.adresse.geom = '';
    }

    this.utilisateur.user.user_mail_data = this.utilisateur.user.user_mail_data.trim();
    this.utilisateur.user.server_in_mail_data = this.utilisateur.user.server_in_mail_data.trim();
    this.utilisateur.user.server_out_mail_data = this.utilisateur.user.server_out_mail_data.trim();
    this.utilisateur.user.port_in_mail_data = this.utilisateur.user.port_in_mail_data.trim();
    this.utilisateur.user.port_out_mail_data = this.utilisateur.user.port_out_mail_data.trim();
    this.apiUserServices
      .UpdateUser(this.utilisateur.user.id, this.utilisateur)
      .pipe(
        tap(() => {
          this.getFolders();
          // ('#exampleModalCenter' as any)?.modal('show');
          this.show_loading_add = false;
        })
      )
      .subscribe(
        () => {
          this.show_loading_add = false;
          // this.alertError('Erreur', error.message);
        },
        (error) => {
          this.alertError('Erreur', error.message);
          this.show_loading_add = false;
        }
      );
  }
}
