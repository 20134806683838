import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FontAwesomeModule, FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginService } from './services/login/login.service';
import { ApiModule } from './services/api.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiConfigurationFactory } from './factory/ApiConfigurationFactory';
import { Configuration } from './services/configuration';
import { AdminLayoutModule } from './admin-layout/admin-layout.module';
import { FormlyFieldStepperComponent } from './components/dynamicFormFields/formly-field-stepper/formly-field-stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { LoginComponent } from './login/login.component';
import { HttpErrorInterceptor } from './shared/HttpErrorInterceptor/HttpError.interceptor';
import * as Rollbar from 'rollbar';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { AuthGard } from './shared/AuthGard/auth-gard.guard';
import { GestionGammesComponent } from './pages/gestion-gammes/gestion-gammes/gestion-gammes.component';
import { FicheGestionGammeComponent } from './pages/gestion-gammes/fiche-gestion-gamme/fiche-gestion-gamme.component';
import { GestionCompaniesComponent } from './pages/gestion-companies/gestion-companies.component';
import { CompaniesFicheComponent } from './pages/gestion-companies/companies-fiche/companies-fiche.component';
import { AutorisationIPComponent } from './components/error-page/autorisation-ip/autorisation-ip.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FicheProspectComponent } from './pages/fiche-prospect/fiche-prospect.component';
import { CommunicationComponent } from './pages/fiche-prospect/communication/communication.component';
import { ProspectComponent } from './pages/fiche-prospect/prospect/prospect.component';
import { RelationsComponent } from './pages/fiche-prospect/relations/relations.component';
import { TicketsComponent } from './pages/fiche-prospect/tickets/tickets.component';
import { OpportuniteProspectComponent } from './pages/fiche-prospect/opportunite-prospect/opportunite-prospect.component';
import { SociceteComponent } from './pages/fiche-prospect/socicete/socicete.component';
import { RestPasswordComponent } from './rest-password/rest-password.component';
import { MatMenuModule } from '@angular/material/menu';
import { TemplateModule } from './template/template.module';
import { TasksComponent } from './pages/tasks/tasks.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import localeFr from '@angular/common/locales/fr';
import { AddGarantieComponent } from './pages/granties/add-garantie/add-garantie.component';
import { ListGarantiesComponent } from './pages/granties/list-garanties/list-garanties.component';
import { ListGroupGarantiesComponent } from './pages/groupe-garanties/list-group-garanties/list-group-garanties.component';
import { AddGroupGarantieComponent } from './pages/groupe-garanties/add-group-garantie/add-group-garantie.component';
import { AddConfigurationComponent } from './pages/parametres/configuration/add-configuration/add-configuration.component';
import { FormsModule } from '@angular/forms';
import { GestionBanqueComponent } from './pages/gestion-banque/gestion-banque.component';
import { FicheBanqueComponent } from './pages/gestion-banque/fiche-banque/fiche-banque/fiche-banque.component';
import { EcheancesComponent } from './pages/echeances/echeances.component';
import { ProspectClientComponent } from './pages/prospect-client/prospect-client.component';
import { FilterEcheancesComponent } from './pages/echeances/filter/filter-echeances/filter-echeances.component';
import { WikiComponent } from './pages/wiki/wiki.component';
registerLocaleData(localeFr);

const rollbarConfig = {
  accessToken: 'b957b3b21ae44ff08a1084f0a16d1e15',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
export const RollbarService = new InjectionToken<Rollbar>('rollbar');
@NgModule({
  declarations: [
    AppComponent,
    FormlyFieldStepperComponent,
    LoginComponent,
    ErrorPageComponent,
    AutorisationIPComponent,
    GestionCompaniesComponent,
    CompaniesFicheComponent,
    GestionGammesComponent,
    FicheGestionGammeComponent,
    FicheProspectComponent,
    CommunicationComponent,
    ProspectComponent,
    RelationsComponent,
    TicketsComponent,
    OpportuniteProspectComponent,
    SociceteComponent,
    RestPasswordComponent,
    TasksComponent,
    AddGarantieComponent,
    ListGarantiesComponent,
    ListGroupGarantiesComponent,
    AddGroupGarantieComponent,
    AddConfigurationComponent,
    GestionBanqueComponent,
    FicheBanqueComponent,
    EcheancesComponent,

    ProspectClientComponent,
     FilterEcheancesComponent,
     WikiComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule,
    SharedModule,
    BrowserAnimationsModule,
    AdminLayoutModule,
    MatStepperModule,
    TemplateModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    MatMenuModule,
    MatStepperModule,
    FontAwesomeModule,
    MatProgressBarModule,
    FormsModule,
  ],
  providers: [
    LoginService,
    {
      provide: RollbarService,
      useFactory: rollbarFactory,
    },
    {
      provide: Configuration,
      useFactory: ApiConfigurationFactory.createConfiguration,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    DatePipe,
    AuthGard,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
  ],
  exports: [SharedModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
