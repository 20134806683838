import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-button-enrg',
  templateUrl: './shared-button-enrg.component.html',
  styleUrls: ['./shared-button-enrg.component.css']
})
export class SharedButtonEnrgComponent implements OnInit {
  @Input()  label: string;
  @Input()  allowed_to_update: boolean;
  @Input() type : string;
  accee : string
  constructor() { }

  ngOnInit(): void {

  }

}
