import { NotificationsService } from './../../../shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { ApiDocumentsService } from 'src/app/services/ApiDocuments/api-documents.service';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationTicket, Status } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert2';
import { Subscription } from 'rxjs/internal/Subscription';
@Component({
  selector: 'app-tickets-details',
  templateUrl: './tickets-details.component.html',
  styleUrls: ['./tickets-details.component.css'],
})
export class TicketsDetailsComponent implements OnInit, OnDestroy {
  fa = fa;
  statusList: Status[] = [];
  loaderStatus: boolean = false;
  infoTicketLoader: boolean = false;

  listunsubscribe: Subscription[] = [];

  isListe: boolean = false;
  @ViewChild('closeModal') closeModal: ElementRef;
  formDataUploadDocument: FormData[] = [];
  change: boolean;
  changeForm : boolean;
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiTicketsServices: ApiTicketsService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiDocumentsService: ApiDocumentsService,
    private router: Router
  ) {
    this.apiDocumentsService.uploadDocument().subscribe((formDataDocuments: FormData) => {
      this.formDataUploadDocument.push(formDataDocuments);
    });
  }

  ngOnDestroy(): void {
    if (this.isListe === false) {
      localStorage.removeItem('filtre');
      localStorage.removeItem('pages');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('search');
    }
    this.listunsubscribe.forEach((element) => {
      element.unsubscribe();
    });
  }

  id: string = '';
  tickets_id: string = '';
  informationTicket = new InformationTicket();
  ngOnInit(): void {
    this.apiTicketsServices.selectedchanges$.subscribe((value) => {
      this.change = value;
    });

    this.route.paramMap.subscribe((params) => {
      this.tickets_id = params.get('tickets_id');
    });
    this.sharedMenuObserverService.updateMenu('Tickets & Support ➜ Détails');
    this.getInformationsTicket();
  }

  /**
   * Alert en cas de succès
   */
  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: '#68a45b',
      focusConfirm: true,
    });
  }

  /**
   * Alert en cas d'erreur
   */
  alertError(data) {
    swal.fire({
      title: "Quelque chose s'est mal passé!",
      text: data,
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  /**
   * Alert pour la confirmation les modification sur le ticket
   */
  alertConfirmUpdate(event) {
    swal
      .fire({
        title: 'Veuillez confirmer la modification du ticket?',
        icon: 'info',
        showConfirmButton: true,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        confirmButtonText: 'Confirmer',
        confirmButtonColor: '#28a8d2',
      })
      .then((result) => {
        if (result.isConfirmed) this.getEmitUpdateTicket(event);
      });
  }

  /**
   * Permets de charger les informations du ticket
   */
  getInformationsTicket(): void {
    this.infoTicketLoader = true;
    const UnsubscribeGetInformationTicketGet = this.apiTicketsServices
      .getInformationTicketGet(this.tickets_id)
      .subscribe(
        (data) => {
          this.informationTicket = data;
          this.infoTicketLoader = false;
        },
        (error) => {
          if (error) {
            this.infoTicketLoader = false;
            this.alertError('Erreur lors du chargement des informations du ticket');
          }
        }
      );
    this.listunsubscribe.push(UnsubscribeGetInformationTicketGet);
  }

  /**
   * Permets de retourner la liste des statuts
   */
  getStatus(): void {
    this.loaderStatus = true;
    const UnsubscribeGetStatusTicket = this.apiTicketsServices.GetStatusTicket().subscribe(
      (data) => {
        this.statusList = data.list_statut;
        this.loaderStatus = false;
      },
      (err) => {
        if (err) {
          this.loaderStatus = false;
          this.alertError('Erreur lors du chargement de la liste des statuts');
        }
      }
    );
    this.listunsubscribe.push(UnsubscribeGetStatusTicket);
  }

  /**
   * Permets de changer la statut du ticket
   */
  changeStatus(libelle, color, id, icone) {
    this.loaderStatus = true;
    this.informationTicket.statut_details.libelle = libelle;
    this.informationTicket.statut_details.color = color;
    this.informationTicket.statut_details.icon = icone;
    const UnsubscribeUpdateStatusTickets = this.apiTicketsServices
      .updateStatusTickets(this.informationTicket.id_ticket_md5, id)
      .subscribe(
        (data) => {
          this.alertSuccess(data.message);
          this.apiTicketsServices.pushTicket('updateStatusTicket');
          this.loaderStatus = false;
          this.closeModal.nativeElement.click();
        },
        (err) => {
          if (err) {
            this.loaderStatus = false;
            this.alertError('Erreur lors de la modification du statut');
            this.closeModal.nativeElement.click();
          }
        }
      );
    this.listunsubscribe.push(UnsubscribeUpdateStatusTickets);
  }

  /**
   * Permets de naviguer vers les parties mentionnez
   */
  scroll(el: string) {
    const element = document.getElementById(el);
    const offset = 120;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  /**
   * Permets de détecter la modification sur le ticket
   */
  updateTicket(): void {
    this.apiTicketsServices.pushTicket('updateTicket');
  }

  checkInformationSpecifiqueTicket(): void {
    this.apiTicketsServices.pushTicket('formTicket');
  }

  /**
   * Permets de modifier les informations specifique
   */
  geEmitInfoSpecifique(value): void {
    this.informationTicket.form = { data: value };
  }

  /**
   * Permets de modifier le ticket
   */
  getEmitUpdateTicket(event): void {
    if (this.formDataUploadDocument.length > 0) {
      this.formDataUploadDocument.forEach((element, index) => {
        this.uploadDocumentTicket(element);
        if (index + 1 === this.formDataUploadDocument.length)
          this.apiDocumentsService.loadDocument$.next('refreshTicket');
      });
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.success;
      notificationMessage.title = 'Opération réussite';
      notificationMessage.message = 'Vos fichiers ont été téléchargés avec succès';
      this.notificationsService.sendMessage(notificationMessage);
    }
 
    this.checkInformationSpecifiqueTicket();
    if (this.apiAdminBpmServices.sharedFormIsValid) {
      if(this.change){
      const UnsubscribeUpdateTickets = this.apiTicketsServices.updateTickets(event).subscribe(
        (data) => {
          this.alertSuccess(data.message);
          this.apiTicketsServices.pushTicket('');
          this.infoTicketLoader = false;
          this.getInformationsTicket();
          this.change = false;
          this.informationTicket.comm_ticket = '';
        },
        (err) => {
          this.alertError(err.message);
          this.infoTicketLoader = false;
        }
      );
      this.listunsubscribe.push(UnsubscribeUpdateTickets);
    }
    if(this.changeForm){
      const UnsubscribeUpdateTicketsinformationSpecifiques = this.apiTicketsServices
        .updateTicketsinformationSpecifiques(this.informationTicket.form, this.tickets_id)
        .subscribe(
          (data) => {
            this.alertSuccess(data.message);
            this.infoTicketLoader = false;
            this.changeForm = false;
          },
          (err) => {
            this.infoTicketLoader = false;
            this.alertError(err.message);
          }
        );
      this.listunsubscribe.push(UnsubscribeUpdateTicketsinformationSpecifiques);
    }
  }
  }

  /**
   * Permets de retourner à la liste
   */
  returnToList(): void {
    this.isListe = true;
    this.router.navigate(['ticketsandsupport']);
  }

  NaviagateToAffaireDetials(id: string) {
    this.router.navigate(['/affaires/details/' + id]);
  }

  uploadDocumentTicket(formData: FormData): void {
    this.apiTicketsServices.uploadDocumentTicket(formData).subscribe(
      (response: any) => {},
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  getChangeForm(event){
   
    this.changeForm = event;
  }
}
