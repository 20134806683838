<form (ngSubmit)="Enregistrer(myForm.form.valid, myForm._directives)" #myForm="ngForm">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9 mt-2">
                <p>
                    Gamme n° :
                    <label class="label-card-info">
            {{initialGamme.real_id}}
          </label>
                </p>
                <p>
                    Nom de la Gamme :
                    <label class="label-card-info">
            {{initialGamme.nom}}
          </label>
                </p>
            </div>
            <div class="col-3 text-left">
                <p> Ligne de Produit :
                    <label class="label-card-info">
            {{initialGamme.type_contrat}}
          </label>
                </p>
                <p> Compagnie :
                    <label class="label-card-info">
            {{initialGamme.denomination}}
          </label>
                </p>
            </div>
        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button type="button" class="btn-dt-retour" (click)="retourToList()">
        <div class="icon-position">
          <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        </div>
        <span> Retour à la liste </span>
      </button>
            <div class="row">
                <div class=" row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn-load" (click)="clearForm()">
            <fa-icon [icon]="fa['faSyncAlt']"></fa-icon>
          </button>
                </div>
                <button class="btn-dt-save" type="submit">
          <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
          Enregistrer
        </button>
            </div>
        </div>
    </div>
    <div class="card p-3 border-0 m-3 mb-5">
        <div class="row">
            <div class="col-12">
                <span class="text-danger">( * ) Champs obligatoires!</span>
            </div>
        </div>
        <mat-tab-group mat-align-tabs="center" style="z-index: 0;">
            <mat-tab label="Informations Gamme">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label>Logo de la Gamme</label>
                        <div class="row">
                            <div class="col-2 pt-1 px-3 border-right-0 border border-primary">
                                <fa-icon class="fa fa-2x pt-3 pl-0" [icon]="fa['faImage']"></fa-icon>
                            </div>
                            <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                                <input type="file" accept=".jpg, .jpeg, .png" id="idlogo" (change)="getLogo($event)" hidden />
                                <label for="idlogo" class="select-label py-3 pl-4">
                  Sélectionner logo
                </label>
                            </div>
                            <div class="col-2 pt-3 pl-3 border-left-0 border border-primary">
                                <img class="img" *ngIf="show_image!=null" data-toggle="modal" data-target="#Actions" src="{{show_image}}">
                                <img class="img" *ngIf="show_image==null" data-toggle="modal" data-target="#Actions" src="../../../../assets/img/no-image.png">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field appearance="standard" class="w-100 mt-4">
                                <mat-label>Nom de la Gamme</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.nom" name="nom_gamme" required #nom_gamme="ngModel">
                                <mat-error *ngIf="nom_gamme.invalid && (nom_gamme.dirty || nom_gamme.touched)">
                                    <div *ngIf="nom_gamme.errors.required" class="message-erreur">
                                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                        Le nom de la gamme est obligatoire!
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>Ligne de produit</mat-label>
                                <input type="text" required matInput [(ngModel)]="initial_contrat" [formControl]="affecterLigneAFormControl" name="type" [matAutocomplete]="autoLigne">
                                <mat-autocomplete (optionSelected)="selectContrat($event.option.value)" autoActiveFirstOption [displayWith]="displayLingeFn" #autoLigne="matAutocomplete">
                                    <mat-option *ngFor="let option of listLignedePorduitFilter | async" [value]="option">
                                        {{option.type}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error  *ngIf="affecterLigneAFormControl.hasError('required')" >
                                    <div *ngIf="affecterLigneAFormControl.hasError('required')">
                                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                        Ligne de produit obligatoire!
                                    </div>
                                </mat-error>

                                <mat-error class="message-erreur" *ngIf="affecterLigneAFormControl.hasError('invalidAutocompleteObject') &&  !affecterLigneAFormControl.hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le ligne de produit
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" [ngClass]="{'disabled':!ModeAjout}">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>Compagnie</mat-label>
                                <input type="text" required matInput [(ngModel)]="initial_campagne" [formControl]="affecterACompagnie" name="compagnie" [matAutocomplete]="autoCompagnie">
                                <mat-autocomplete [displayWith]="displayCompagnieFn" autoActiveFirstOption #autoCompagnie="matAutocomplete">
                                    <mat-option *ngFor="let option of listCompagnieFilter | async" [value]="option">
                                        {{option.denomination}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="affecterACompagnie.invalid && (affecterACompagnie.dirty && affecterACompagnie.touched) " class="message-erreur">
                                    <div *ngIf="affecterACompagnie.hasError('required')">
                                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                        Compagnie est obligatoire!
                                    </div>
                                </mat-error>

                                <mat-error class="message-erreur" *ngIf="affecterACompagnie.hasError('invalidAutocompleteObject') &&  !affecterACompagnie.hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le Compagnie
                                </mat-error>
                            </mat-form-field>


                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">

                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>Conditions de souscription</mat-label>
                                <textarea matInput [(ngModel)]="Gamme.conditions_souscription" name="conditions_souscription"></textarea>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>Description</mat-label>
                                <textarea matInput [(ngModel)]="Gamme.description" name="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">

                            <mat-form-field appearance="standard" class="w-100">
                                <mat-label>identifiant externe</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.id_externe" name="id_externe">
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class=" d-inline form-group">
                           
                            <label class="switch  ">
                    <input type="checkbox" name="gammepro" [checked]="Gamme.pro == '1'" (change)="isPro($event) "  >
                    <span class="slider round"></span>
                  </label> <label class="ml-2">Gamme professionnelle</label>
                        </div>

                        <div class="ml-3 d-inline form-group">
                         
                            <label class="switch">
                <input type="checkbox" name="gammeactif" [checked]="Gamme.active == '1'"  (change)="isActive($event)"  >
                <span class="slider round"></span>
              </label>   
              <label class="ml-2">Actif</label>
                        </div>
                    </div>

                </div>


            </mat-tab>
            <mat-tab label="Informations Tarificateur/comparateur">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>URL De Tarification</mat-label>
                                <input type="text" matInput [(ngModel)]="Gamme.url_tarification" #url_tarification="ngModel" name="url_tarification">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Documents Gamme">
                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 accordion" id="folder_tableaux_de_garanties_collapse">
                        <div class="card-folder border border-primary mb-4 ">
                            <div class="card-header" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
                                    </div>
                                    <div class="col-9 text-left" data-toggle="collapse" data-target="#tableaux_de_garanties_collapse" aria-expanded="false" aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                            Tableaux de garanties (Fichier PDF)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu_tableaux_de_garanties" aria-label="Example icon-button with a menu">
                          <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                      </button>
                                        <mat-menu #menu_tableaux_de_garanties="matMenu">
                                            <button mat-menu-item>
                            <input type="file" accept=".pdf" id="tableaux_de_garanties"   (change)='getTableauGarantie($event)'
                            name="tableaux_de_garanties" hidden ngModel #tableaux_de_garanties="ngModel" />
                            <label for="tableaux_de_garanties" class="add-file">
                              <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                                  Ajouter un ficher
                              </label>
                          </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="tableaux_de_garanties_collapse" *ngIf="Gamme.tableaux_de_garanties" class="collapse" aria-labelledby="headingFolder" data-parent="#folder_tableaux_de_garanties_collapse">
                                <div class="card-body">
                                    <div class="row flex-nowrap">
                                        <div class="col-1 pl-3">
                                            <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                                        </div>
                                        <div class="col-11 pl-4">
                                            <div class="d-flex justify-content-between">

                                                <ng-container *ngIf="Gamme.tableaux_de_garanties">


                                                    <a href="{{ Gamme.tableaux_de_garanties }}" target="_blank" class="file-name text-dark">
                                {{expandName(Gamme.tableaux_de_garanties)}}
                            </a>
                                                    <button type="button" class="btn-dt-delete" (click)="deletefile('tableauGarantie')" *ngIf="tableauGarantie">
                              <img src="assets/icons/trash-icon.svg">
                            </button>
                                                </ng-container>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_formulaire_adhesion_collapse">
                        <div class="card-folder border border-primary mb-4 ">
                            <div class="card-header" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
                                    </div>
                                    <div class="col-9 text-left" data-toggle="collapse" data-target="#formulaire_adhesion_collapse" aria-expanded="false" aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                            Formulaire d'adhésion (Fichier PDF)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu_formulaire_adhesion" aria-label="Example icon-button with a menu">
                            <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                          </button>
                                        <mat-menu #menu_formulaire_adhesion="matMenu">
                                            <button mat-menu-item>
                              <input type="file" id="formulaire_adhesion" (change)='getFormulaireAdhesion($event)'
                              name="formulaire_adhesion" hidden ngModel #formulaire_adhesion="ngModel" accept=".pdf" />
                              <label for="formulaire_adhesion" class="add-file">
                                <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                                    Ajouter un ficher
                                </label>
                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="formulaire_adhesion_collapse" *ngIf="Gamme.formulaire_adhesion" class="collapse" aria-labelledby="headingFolder" data-parent="#folder_formulaire_adhesion_collapse">
                                <div class="card-body">
                                    <div class="row flex-nowrap">
                                        <div class="col-1 pl-3">
                                            <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                                        </div>
                                        <div class="col-11 pl-4">

                                            <div class="d-flex justify-content-between">


                                                <ng-container *ngIf="Gamme.formulaire_adhesion">

                                                    <a href="{{ Gamme.formulaire_adhesion }}" target="_blank" class="file-name text-dark">
                                  {{expandName(Gamme.formulaire_adhesion)}}
                              </a>
                                                    <button type="button" class="btn-dt-delete" (click)="deletefile('formulaireAdhesion')" *ngIf="formulaireAdhesion">
                                <img src="assets/icons/trash-icon.svg">
                              </button>
                                                </ng-container>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_notice_information_collapse">
                        <div class="card-folder border border-primary mb-4 ">
                            <div class="card-header" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
                                    </div>
                                    <div class="col-9 text-left" data-toggle="collapse" data-target="#notice_information_collapse" aria-expanded="false" aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                            Notice d'information (Fichier PDF)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu_notice_information" aria-label="Example icon-button with a menu">
                            <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                          </button>
                                        <mat-menu #menu_notice_information="matMenu">
                                            <button mat-menu-item>
                              <input type="file" id="notice_information" (change)='getNoticeInformation($event)'
                              name="notice_information" hidden ngModel #notice_information="ngModel" accept=".pdf" />
                              <label for="notice_information" class="add-file">
                                <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                                    Ajouter un ficher
                                </label>
                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="notice_information_collapse" *ngIf="Gamme.notice_information" class="collapse" aria-labelledby="headingFolder" data-parent="#folder_notice_information_collapse">
                                <div class="card-body">
                                    <div class="row flex-nowrap">
                                        <div class="col-1 pl-3">
                                            <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                                        </div>
                                        <div class="col-11 pl-4">
                                            <div class="d-flex justify-content-between">
                                                <ng-container *ngIf="Gamme.notice_information">
                                                    <a href="{{ Gamme.notice_information }}" target="_blank" class="file-name text-dark">
                                  {{expandName(Gamme.notice_information)}}
                              </a>

                                                    <button type="button" class="btn-dt-delete" (click)="deletefile('noticeInformation')" *ngIf="noticeInformation">
                                <img src="assets/icons/trash-icon.svg">
                              </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_avantages_tiers_payant_collapse">
                        <div class="card-folder border border-primary mb-4 ">
                            <div class="card-header" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
                                    </div>
                                    <div class="col-9 text-left" data-toggle="collapse" data-target="#avantages_tiers_payant_collapse" aria-expanded="false" aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                            Avantages tiers Payant (Fichier PDF)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu_avantages_tiers_payant" aria-label="Example icon-button with a menu">
                            <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                          </button>
                                        <mat-menu #menu_avantages_tiers_payant="matMenu">
                                            <button mat-menu-item>
                              <input type="file" accept=".pdf" id="avantages_tiers_payant" (change)='getAvantages($event)'
                              name="avantages_tiers_payant" hidden ngModel #avantages_tiers_payant="ngModel" />
                              <label for="avantages_tiers_payant" class="add-file">
                                <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                                    Ajouter un ficher
                                </label>
                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="avantages_tiers_payant_collapse" *ngIf="Gamme.avantages_tiers_payant" class="collapse" aria-labelledby="headingFolder" data-parent="#folder_avantages_tiers_payant_collapse">
                                <div class="card-body">
                                    <div class="row flex-nowrap">
                                        <div class="col-1 pl-3">
                                            <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                                        </div>
                                        <div class="col-11 pl-4">
                                            <div class="d-flex justify-content-between">
                                                <ng-container *ngIf="Gamme.avantages_tiers_payant">
                                                    <a href="{{ Gamme.avantages_tiers_payant }}" target="_blank" class="file-name text-dark">
                                  {{expandName(Gamme.avantages_tiers_payant)}}
                              </a>
                                                    <button type="button" class="btn-dt-delete" (click)="deletefile('avantages')" *ngIf="avantages">
                                <img src="assets/icons/trash-icon.svg">
                              </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_ipid_collapse">
                        <div class="card-folder border border-primary mb-4 ">
                            <div class="card-header" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
                                    </div>
                                    <div class="col-9 text-left" data-toggle="collapse" data-target="#ipid_collapse" aria-expanded="false" aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                            IPID (Fichier PDF)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu_pid" aria-label="Example icon-button with a menu">
                            <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                          </button>
                                        <mat-menu #menu_pid="matMenu">
                                            <button mat-menu-item>
                              <input type="file" accept=".pdf" id="id5" (change)='getIpid($event)'
                              name="id5" hidden ngModel />
                              <label for="id5" class="add-file">
                                <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                                    Ajouter un ficher
                                </label>
                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="ipid_collapse" *ngIf="Gamme.ipid" class="collapse" aria-labelledby="headingFolder" data-parent="#folder_ipid_collapse">
                                <div class="card-body">
                                    <div class="row flex-nowrap">
                                        <div class="col-1 pl-3">
                                            <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                                        </div>
                                        <div class="col-11 pl-4">
                                            <div class="d-flex justify-content-between">
                                                <ng-container *ngIf="Gamme.ipid">
                                                    <a href="{{ Gamme.ipid }}" target="_blank" class="file-name text-dark">
                                  {{expandName(Gamme.ipid)}}
                              </a>
                                                    <button type="button" class="btn-dt-delete" (click)="deletefile('ipid')" *ngIf="ipid">
                                <img src="assets/icons/trash-icon.svg">
                              </button>

                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 accordion" id="folder_support_commercial_collapse">
                        <div class="card-folder border border-primary mb-4 ">
                            <div class="card-header" id="headingFolder">
                                <div class="row flex-nowrap">
                                    <div class="col-1 mt-1 pl-0">
                                        <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
                                    </div>
                                    <div class="col-9 text-left" data-toggle="collapse" data-target="#support_commercial_collapse" aria-expanded="false" aria-controls="collapseTwo">
                                        <p class="text-dark ml-3 mt-2">
                                            Support Commerciale (Fichier PDF)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right mt-0 position-relative pl-0">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu_support_commercial" aria-label="Example icon-button with a menu">
                            <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                          </button>
                                        <mat-menu #menu_support_commercial="matMenu">
                                            <button mat-menu-item>
                              <input type="file"   accept=".pdf" id="support_commercial" (change)='getSupportCommercial($event)'
                              name="support_commercial" hidden ngModel #support_commercial="ngModel" />
                              <label for="support_commercial" class="add-file">
                                <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                                    Ajouter un ficher
                                </label>
                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div id="support_commercial_collapse" *ngIf="Gamme.support_commercial" class="collapse" aria-labelledby="headingFolder" data-parent="#folder_support_commercial_collapse">
                                <div class="card-body">
                                    <div class="row flex-nowrap">
                                        <div class="col-1 pl-3">
                                            <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                                        </div>
                                        <div class="col-11 pl-4">
                                            <div class="d-flex justify-content-between">
                                                <ng-container *ngIf="Gamme.support_commercial">
                                                    <a href="{{ Gamme.support_commercial }}" target="_blank" class="file-name text-dark">
                                  {{expandName(Gamme.support_commercial)}}
                              </a>
                                                    <button type="button" class="btn-dt-delete" (click)="deletefile('commerce')" *ngIf="supportCommercial">
                                <img src="assets/icons/trash-icon.svg">
                              </button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <app-group-garantie-shared  [listeGroupefromParent]="listeGroupe" [garanties_herite]="garanties_herite"  [id_contrat]="id"  (listeGroupFromP)="listeGroupFromP($event)" (listeGroupFrom)="addItem($event)"></app-group-garantie-shared>
    </div>
    <div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content w-100">
                <div class="modal-header">
                    <h2 style="margin-left: 15px">Logo du produit</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row justify-content-center ">
                        <img class="img" style="width: 200px;height: 200px;" src="{{show_image}}">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</form>

