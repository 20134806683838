export class CountTicketsByCategorie{
    private _declare: string;
    private _fermer_negatif: string;
    private _totale: string;
    private _fermer_positif: string;

    public get Declare(): string {
        return this._declare;
    }
    public set Declare(value: string) {
        this._declare = value;
    }
    public get Ferme_negatif(): string {
        return this._fermer_negatif;
    }
    public set Ferme_negatif(value: string) {
        this._fermer_negatif = value;
    }
    public get Ferme_positif(): string {
        return this._fermer_positif;
    }
    public set Ferme_positif(value: string) {
        this._fermer_positif = value;
    }
    public get totale(): string {
        return this._totale;
    }
    public set totale(value: string) {
        this._totale = value;
    }
}