import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Qualite } from 'src/app/entity/Affaires/FilterAffaires';

@Component({
  selector: 'app-filtre-qualite',
  templateUrl: './filtre-qualite.component.html',
  styleUrls: ['./filtre-qualite.component.css']
})
export class FiltreQualiteComponent implements OnInit, OnChanges {

  filterQualite: Qualite = new Qualite();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterQualite: EventEmitter<Qualite> = new EventEmitter();
  constructor(private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  onItemChange() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterQualiteForm(changes.Reset.currentValue);
  }
  resetfilterQualiteForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {

      this.filterQualite.compteur_qualite = "";
      this.filterQualite.affaire_enquete = "";
      this.filterQualite.qualite4 = "";
      this.filterQualite.qualite1 = "";
      this.filterQualite.qualite2 = "";
      this.filterQualite.qualite3 = "";

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterQualite.emit(this.filterQualite);
    }
  }

  EmitFilterQualite() {
    this.onPushFilterQualite.emit(this.filterQualite);
  }

}
