import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
interface Nom {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.css']
})

export class InputsComponent implements OnInit {

  values: Nom[] = [
    {value: '0', viewValue: 'Robot Geoprod'},
    {value: '1', viewValue: 'Nour.mattoussi'},
    {value: '2', viewValue: 'Filiale Nabeul'}
  ];


  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;
  public clr = '#FFFFFF' ;
  colorCtr: AbstractControl = new FormControl(null, [Validators.required]);

  codeBasicInput: any =`

  <mat-form-field>
    <mat-label>Input Text</mat-label>
    <input matInput type="text">
  </mat-form-field>
  
  <mat-form-field class="input-width">
    <mat-icon matPrefix [ngStyle]="{'color': colorCtr.value}">circle</mat-icon>
    <input matInput [ngxMatColorPicker]="picker" [formControl]="colorCtr" [disabled]="disabled" [ngStyle]="{'color': colorCtr.value}" >
    <ngx-mat-color-toggle matSuffix [for]="picker" style="font-size: 20px;" ></ngx-mat-color-toggle>
    <ngx-mat-color-picker #picker [touchUi]="touchUi" [color]="color"></ngx-mat-color-picker>
  </mat-form-field>
  
  <mat-label>Date d'arret</mat-label>
    <input  matInput [matDatepicker]="basicDatepicker">
    <mat-datepicker-toggle matSuffix [for]="basicDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #basicDatepicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field  class="input-width">
        <mat-label>Produit Spécifique</mat-label>
        <mat-select>
          <mat-option *ngFor="let v of values" [value]="v.value">
            {{v.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  `;

  codeSelect_logo: any =`
  <div class="row">
  <div class="col-md-6">
    <div class="row">
      <div class="col-2 pt-1 px-3 border-right-0 border border-primary" >
        <fa-icon class="fa fa-2x pt-3 pl-0"  [icon]="fa['faImage']"></fa-icon>
      </div>
      <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary" >
        <input type="file" id="idlogo" (change)="getLogo($event,'favicon')" hidden />
        <label for="idlogo" class="select-label py-3 pl-2">
          Sélectionner favicon
        </label> 
      </div>
      <div class="col-2 pt-3 pl-3 border-left-0 border border-primary" >
        <img class="img" data-toggle="modal"  data-target="#Favicon" src="{{show_favicon}}">
      </div>
    </div>
  </div>
</div>`;
  codeSwitchToggle: any =`
  <div class="container">
  <div class="row container-switch" >
    <div class="col">
      <div class="Liblle">
        <span>Exemple 1.1</span>
      </div>
      <label class="switch space-switch" >
        <input type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="col">
      <div class="Liblle">
        <span>Exemple 1.2</span>
      </div>
      <label class="switch space-switch">
        <input type="checkbox" checked>
        <span class="slider round"></span>
      </label>
    </div>
  </div>
  <br>
  <div class="row container-switch" >
    <div class="col">
      <div class="Liblle-switch">
        <span>Exemple 2.1</span>
      </div>
      <label class="switch-m space-switch" >
        <input type="checkbox">
        <span class="slider-m round"></span>
      </label>
    </div>
    <div class="col">
      <div class="Liblle-switch">
        <span>Exemple 2.2</span>
      </div>
      <label class="switch-m space-switch">
        <input type="checkbox" checked>
        <span class="slider-m round"></span>
      </label>
    </div>
  </div>
</div>`;

  codeDate_Select_Inputs: any =`--------------------------------------------------------------------------------------------------
  <div class="container">
  <div class="row">
    <div class="col">
    <mat-form-field  class="input-width" >
      <mat-label>Date d'arret</mat-label>
      <input  matInput [matDatepicker]="basicDatepicker">
      <mat-datepicker-toggle matSuffix [for]="basicDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #basicDatepicker></mat-datepicker>
    </mat-form-field>
    </div>
    <div class="col">
      <div class="Liblle">
        <span>Produit spécifique</span>
      </div>
      <mat-form-field  class="input-width">
      <mat-label>Produit Spécifique</mat-label>
      <mat-select>
        <mat-option *ngFor="let v of values" [value]="v.value">
        {{v.viewValue}}
        </mat-option>
      </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
------------------------------------------Mat-select.ts--------------------------
values: Nom[] = [
  {value:'',viewValue: 'Select One'},
  {value: '0', viewValue: 'Robot Geoprod'},
  {value: '1', viewValue: 'Nour.mattoussi'},
  {value: '2', viewValue: 'Filiale Nabeul'}
];
`;

  fa = fa;
  show_favicon: any = '../../../../assets/img/no-image.png';
  getLogo(event: any, type: string) {

  }
  constructor() {  }

  ngOnInit(): void {
  }


}
