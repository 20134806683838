export const FR = {
  data: {
    PROSPECT: {
      SIREN: 'SIREN',
      SIRET: 'SIRET',
      SOCIAL_SECURITY: {
        LABEL: 'Sécurité sociale',
        REGEX: '',
        PLACEHOLDER: '',
      },
      NAF: {
        LABEL: 'NAF',
        REGEX: '[0-9]{4}[a-zA-Z]{1}',
        PLACEHOLDER: '1234A',
      },
    },
    ORGANISME: {
      SIREN: 'SIREN',
      SIRET: 'SIRET',
      RCS: 'RCS',
      ORIAS: 'Orias',
    },
    USER: {
      ORIAS: 'N°ORIAS producteur',
    },
  },
};
