<div class="card-folder mb-4" id="accordionFolder" *ngIf="exist.existe=== documents_rajoutes || portedoc" [ngClass]="{'dark': exist.oblig ===1, 'light':exist.oblig ===0 || portedoc}">
    <div class="card-header">
        <div class="row flex-nowrap">
            <div class="col-1 mt-3 pl-0">
                <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
            </div>
            <div class="col-9 text-left" (click)="consulterFiles(exist.md5_id)" data-toggle="collapse" data-target="#idDocument" aria-expanded="true" aria-controls="collapseTwo">
                <p class="text-dark ml-3 mt-2">
                    {{exist.name}} <span *ngIf="exist.oblig === 1">( * )</span>
                </p>
                <p class="ml-3 text-muted">
                    {{exist.files.length}} fichier(s)
                </p>
            </div>
            <div class="col-2 text-right mt-3 position-relative pl-0" *ngIf="show_three_points">
                <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!accee" aria-label="Example icon-button with a menu">
                  <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                    <input id="existeFile{{i}}" type="file" hidden  (change)="getFiles($event,exist.md5_id, exist.name)" />
                    <label for="existeFile{{i}}" class="add-file">
                      <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                      Ajouter un ficher
                    </label>
                  </button>

                    <button mat-menu-item data-toggle="modal" data-target="#formModal" (click)="getIdDocument(exist.md5_id)">
                    <label class="add-file">
                      <fa-icon [icon]="fa['faTable']"></fa-icon>
                      Formulaire
                    </label>
                  </button>
                </mat-menu>

            </div>
        </div>
    </div>



    <div id="idDocument" *ngIf="consulterFile && idDoc == exist.md5_id" class="collapse" aria-labelledby="headingFolder" data-parent="#accordionFolder">
        <div class="card-body" *ngFor="let file of exist.files; let index = index; ">
            <div class="row  flex-nowrap">
                <div class="col-1 pl-3">
                    <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                </div>
                <div class="col-11 pl-4">
                    <div class="file-name text-dark" *ngIf="!file.url">
                        <p class="d-flex justify-content-right" ta-title="{{ file.name }}">
                            {{file.name}}
                            <span class="d-flex justify-content-right" (click)="removeFile(index)"><img  src="assets/icons/trash-icon-red.svg"></span>
                        </p>
                    </div>
                    <a *ngIf="file.url" href="{{ file.url }}" target="_blank" class="file-name text-dark">
                        <p data-title="{{ file.name }}">
                            {{file.name}}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Formulaire Modal -->
<div *ngIf="openModal">
    <div class="modal fade" id="formModal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title" id="formModalLabel">Formulaire</h2>
                    <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close" (click)="onClearForm()">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body text-center">
                    <ng-container *ngIf="loadingData">
                        <div class="d-flex justify-content-center">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!loadingData">
                        <formly-form *ngIf="data != null" [model]="model" [fields]="fields">
                        </formly-form>
                        <p *ngIf="data == null"> Pas de formulaire</p>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <ng-container *ngIf="show_loading_form">
                        <span class="spinner-style">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </span>
                    </ng-container>
                    <button *ngIf="!show_loading_form" type="reset" class="btn-dt-cancel-popup" (click)="onClearForm()">
                <fa-icon class="fa-icon-style" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                Fermer
              </button>

                    <button *ngIf="!show_loading_form || data == null" type="button" class="btn-dt-save-popup" (click)="onSaveForm()">
                <fa-icon class="fa-icon-style" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                Enregistrer
              </button>
                </div>
            </div>
        </div>
    </div>
</div>
