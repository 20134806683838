import { Component, OnInit } from '@angular/core';
import {Process} from '../../../../entity/Process';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  listOfProcess: Process [] = [
    new Process("Création","done"),
    new Process("Signature","done"),
    new Process("Validité","active"),
    new Process("Echéance","toDo"),
  ];
  fa=fa
  constructor() { }

  
  ngOnInit(): void {
  }

}
