export class AddTicket {
  id_affaire_ticket: string = '';
  id_opportunite_ticket: string = '';
  id_prospect_ticket: string = '';
  statut: string = '';
  commentaire: string = '';
  affect: string = '';
  categorie: string = '';
  date_limite: string = '';
  title: string = '';
  breadcrumb_cat_ticket: string = '';
  file: File[];

  constructor(init?: Partial<AddTicket>) {
    Object.assign(this, init);
  }
}