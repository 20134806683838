import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module'
import {MailevaDetailsComponent} from "./maileva-details.component";


@NgModule({
  declarations: [
    MailevaDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class MailevaDetailsModule {
}
