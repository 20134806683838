export class UserAdmin {
    id_user: string = "";
    Informations_essentielles: Informations_essentielles = new Informations_essentielles();
    Informations_webmail: Informations_webmail = new Informations_webmail();
    Informations_etendues: Informations_etendues = new Informations_etendues();
    param_user: param_user = new param_user();
    auto_spec: auto_spec = new auto_spec();
    signature: string = "";
    constructor() { }
}
export class Informations_essentielles {
    adresse: string = "";
    id_user: string = "";
    location: string = "";
    chek_adresse_ip: string = "";
    civilite: string = "";
    nom: string = "";
    prenom: string = "";
    matricule_rh: string = "";
    identite_reelle: string = "";
    mail: string = "";
    password: string = "";
    api_key: string = "";
    role: string = "";
    organisme: string = "";
    date_naiss_user: string = "";
    roles_auto: string = "";
    url_photo: string = "";
    list_org: string[] = [];
    constructor() { }
}
export class Informations_webmail {
    webmail_server_in: string = "";
    webmail_server_out: string = "";
    webmail_port_in: string = "";
    webmail_port_out: string = "";
    webmail_ssl: boolean = false;
    webmail_mail: string = "";
    webmail_pwd: string = "";
    constructor() { }
}
export class Informations_etendues {
    num_inter: string = "";
    num_tel: string = "";
    tel2: string = "";
    mobile: string = "";
    tel_urgence: string = "";
    skype: string = "";
    ville: string = "";
    group: string[] = [];
    group_commission: string = "";
    rue: string = "";
    num: string = "";
    cp: string = "";
    pays: string = "";
    zone: string = "";
    geom: string = "";
    lat: string = "";
    lng: string = "";
    date_integration: string = "";
    constructor() { }
}
export class param_user {
    groupe: any[] = [];
    code: string = "";
    nom_producteur: string = "";
    prenom_producteur: string = "";
    qualite_producteur: string = "";
    orias_producteur: string = "";
    manager: any[] = [];
    manager_de: any[] = [];
    pointage: boolean = false;
    proprietaire: boolean = false;
    super_admin: boolean = false;
    acces_ett: boolean = false;
    admin_restreint: boolean = false;
    tab_code: any[] = [];
    adresse_auto: string = "";
    heure_fin: string = "";
    heure_deb: string = "";
    samedi_off: string = "";
    list_org: any[] = [];
    constructor() { }
}
export class auto_spec {
    filtre: string = "2";
    hist: string = "2";
    hist_comm: string = "2";
    bloc_env: string = "2";
    bloc_exp_aff: string = "2";
    bloc_lect_comp_aff: string = "2";
    bloc_ecrit_comp_aff: string = "2";
    bloc_exp_aff_det: string = "2";
    bloc_exp_opp: string = "2";
    bloc_exp_opp_det: string = "2";
    limit_listing_opp: string = "";
    limit_listing_aff: string = "";
    bloc_act_grp_tags: string = "2";
    bloc_act_grp_statut: string = "2";
    bloc_act_grp_negociateur: string = "2";
    bloc_act_grp_communication: string = "2";
    fiche_dec: string = "2";
    bloc_act_grp_pubpostage: string = "2";
    assistant: string = "2";
    comparateur: string = "2";
    tarificateur: string = "2";
    acces_communiques: string = "2";
    acces_reclamation_ticket: string = "2";
    bloc_acc_fusion: string = "2";
    bloc_acc_maileva: string = "2";
    bloc_edit_maileva: string = "";
    lecture_tpv: string = "2";
    execution_tpv: string = "2";
    admin_tpv: string = "2";
    validate_paiement: string = "2";
    acces_signature: string = "2";
    acces_courrier_entrant: string = "2";
    bloc_exp_maileva: string = "2";
    bloc_export_maileva_det: string = "2";
    limit_listing_maileva: string = "2";
    acc_opp: Acces[] = [];
    acc_aff: Acces[] = [];
    constructor() { }
}
export class Acces {
    id: string = "";
    cat: string = "";
    libele: string = "";
    type: string = "";
}