<div class="row">
    <div class="col mt-3" style="max-width: 248px;">
        <div class="sidebar-affaire position-fixed" id="scrollbar-affaire">
            <div class="card card-affaire bg-white text-center border-0">
                <div class="row">
                    <div class="col-12 mb-2" [ngClass]="{'cover': !allowed_to_update_affaire}">
                        <app-changestatusbutton data-toggle="modal" [allowed_to_update]="allowed_to_update_affaire" [label]="Actions" [type]="'A'" data-target="#Actions" (click)="getStatusAutorise()"></app-changestatusbutton>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <app-shared-button-actuali (click)="Actualise()"></app-shared-button-actuali>
                        </div>
                        <div class="col-10" [ngClass]="{'cover': !allowed_to_update_affaire}">
                            <app-shared-button-enrg [allowed_to_update]="allowed_to_update_affaire" [type]="'A'" (click)="updateAffaire()" class="ml-2" [label]="Enregistrer"></app-shared-button-enrg>
                        </div>

                    </div>
                    <div class="col-12 mt-2">
                        <button type="button" class="btn-dt-retour btn-custom-details" (click)="returnToList()">
                  <div class="icon-position">
                    <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
                  </div>
                  <span> Retour à la liste </span>
                </button>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="col py-1">
                        <button (click)="scroll('infoAffaire')" class="focus-style d-block border-0 full-width text-left pl-3">
                <label  class="align-details-icon">
                  <fa-icon class="fa-lg" [icon]="fa['faBriefcase']"></fa-icon>
                </label>
                <span>Informations Affaire</span>
              </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('cord')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faUserTie']"></fa-icon>
                  </label>
                  <span>Adhérent/Souscripteur</span>
                </button>
                    </div>

                    <div class="col py-1">
                        <button (click)="scroll('infcont')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faFileContract']"></fa-icon>
                  </label>
                  <span>Information Contrat</span>
                </button>
                    </div>

                    <div class="col py-1">
                        <button (click)="scroll('garantie')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                                <fa-icon class="mr-2 fa-lg " [icon]="fa['faUmbrella']"></fa-icon>
                  </label>
                  <span>Garanties</span>
                </button>
                    </div>
                    <div *ngIf="bien" class="col py-1">
                      <button (click)="scroll('objetAssurer')" class=" focus-style d-block border-0 full-width text-left pl-3">
                <label  class="align-details-icon">
                  <fa-icon class="mr-2 fa-lg" [icon]="fa['faObjectUngroup']"></fa-icon>
                </label>
                <span>Objet(s) / Bien(s) assurer</span>
              </button>
                  </div>
                    <div class="col py-1">
                        <button (click)="scroll('information-bancaire')" class=" focus-style d-block border-0 full-width text-left pl-3">
                <label  class="align-details-icon">
                  <fa-icon class="fa-lg" [icon]="fa['faLandmark']"></fa-icon>
                </label>
                <span>Informations bancaires</span>
              </button>
                    </div>
                    <div class="col py-1" *ngIf="lecture_transaction_financiere_client">
                        <button (click)="scroll('echeancier')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faFileInvoiceDollar']"></fa-icon>
                  </label>
                  <span>Echeancier</span>
                </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('paimentfrais')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faCreditCard']"></fa-icon>
                  </label>
                  <span>Frais & Paiement</span>
                </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('ListDocuments')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faFileImport']"></fa-icon>
                  </label>
                  <span>Porte Documents</span>
                </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('LiaisonsContrats')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faRetweet']"></fa-icon>
                  </label>
                  <span>Relation Affaire</span>
                </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('Tickets')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faTicketAlt']"></fa-icon>
                  </label>
                  <span>Tickets</span>
                </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('tache')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faTasks']">
                    </fa-icon>&nbsp;&nbsp;
                  </label>
                  <span>Taches</span>
                </button>
                    </div>
                    <div class="col py-1">
                        <button (click)="scroll('InfAvancee')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faQuestionCircle']"></fa-icon>
                  </label>
                  <span>Informations Avancées</span>
                </button>
                    </div>
                    <div class="col py-1">
                      <button (click)="scroll('comment')" class=" focus-style d-block border-0 full-width text-left pl-3">
                <label  class="align-details-icon">
                  <fa-icon class="fa-lg" [icon]="fa['faCommentDots']"></fa-icon>
                </label>
                <span>Commentaires</span>
              </button>
                  </div>
                    <div class="col py-1">
                        <button (click)="scroll('Communication')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faComments']"></fa-icon>
                  </label>
                  <span>Communications</span>
                </button>
                    </div>
                    <div class="col py-1" *ngIf="histAccess">
                        <button (click)="scroll('Historique')" class=" focus-style d-block border-0 full-width text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faHistory']"></fa-icon>
                  </label>
                  <span>Historique</span>
                </button>
                    </div>
                    <div class="col py-1" *ngIf="histCommAccess">
                        <button (click)="scroll('HistoriqueCommunication')" class=" focus-style full-width d-block border-0 text-left pl-3">
                  <label  class="align-details-icon">
                    <fa-icon class="fa-lg" [icon]="fa['faHistory']"></fa-icon>
                  </label>
                  <span>Historique de Communication</span>
                </button>
                    </div>
                </div>
            </div>
            <div class="text-left">
                <shared-tags [isReadOnly]="allowed_to_update_affaire" [affaire_id]="affaire_id"></shared-tags>
            </div>
        </div>
    </div>


    <div class="col p-1 overflow-auto">
        <div class="row">
            <div class="col-12 p-0">
                <div class="ml-5">
                    <div class="p-2 mb-2" id="infoAffaire">
                        <app-info-affaire [informationsContrat]="informationsContrat" [societe]="societe"></app-info-affaire>
                    </div>
                    <div class="p-2 mb-2" id="stat">
                        <app-status></app-status>
                    </div>
                    <div class="p-2 mb-2" id="cord" *ngIf="informationsContrat.contrat.id_prospect.toString()">
                        <app-cordonnees-client [person]="person" [allowed_to_update_affaire]="informationsContrat.contrat.allowed_to_update" (onUpdateSociete)="onUpdateSociete($event)" (ErroronUpdateSociete)="getError($event)" [affaireId]="affaire_id">
                        </app-cordonnees-client>
                    </div>

                    <div class="p-2 mb-2" id="infcont" *ngIf="informationsContrat != null ">
                        <app-informations-contract [informationsContrat]="informationsContrat" (onUpdateinformationsContrat)="onUpdateContrat($event)" [allowed_to_update_affaire]="allowed_to_update_affaire">
                        </app-informations-contract>
                    </div>
                    <div class="p-2 mb-2" id="garantie" [ngClass]="{'cover': !allowed_to_update_affaire}" >
                        <div class="card bg-white border-0 p-3">

                            <div class="card-doc-title ml-3 row">
                                <fa-icon class="mr-2 fa-lg " [icon]="fa['faUmbrella']"></fa-icon>
                                <h4>Garanties</h4>
                            </div>

                            <div class="scrollbar mb-2" >
                                <app-garantie [affaire_id]="affaire_id"  (garantiexport)="onUpdateGaranties($event)"></app-garantie>
                            </div>


                        </div>
                    </div>
                    <div class="p-2 mb-2" *ngIf="bien" id="objetAssurer" [ngClass]="{'cover': !allowed_to_update_affaire}" >
                      <div class="card bg-white border-0 p-3">



                          <div class="scrollbar mb-2" >
                            <app-objet-assurer-aff [affaire_id]="affaire_id" [opportunity_id]="id_opportunite" [bien]="bien" (updateObjetAsuure)="updateobjetAssure($event)"></app-objet-assurer-aff>
                          </div>


                      </div>
                  </div>
                    <div class="p-2 mb-2" id="information-bancaire" *ngIf="informationsContrat">
                        <div class="card bg-white border-0 p-3">

                            <div class="card-doc-title ml-3 row">
                                <fa-icon class="mr-2 fa-lg " [icon]="fa['faLandmark']"></fa-icon>
                                <h4>Informations bancaires</h4>
                            </div>
                            <div class="scrollbar mb-2" *ngIf="informationsContrat.contrat.id">
                                <shared-information-bank [customStyle]="'true'" [typeEntity]="'affaire'" [allowed_to_update_affaire]="allowed_to_update_affaire" [bankInformation]="informationsContrat.info_bancaire" (onChangeBankInformation)="getBankInformation($event)"></shared-information-bank>
                            </div>
                        </div>
                    </div>

                    <div class="p-2 mb-2" id=echeancier *ngIf="lecture_transaction_financiere_client" >
                        <app-echeancier [transaction_financiere_client]="ecriture_transaction_financiere_client" [allowed_to_update_affaire]="allowed_to_update_affaire" (echeanciersexport)="getlistecheancier($event)"></app-echeancier>
                    </div>
                    <div class="p-2 mb-2" id=paimentfrais>
                        <app-paiment-frais [allowed_to_update_affaire]="allowed_to_update_affaire" (paimentfraisexport)="getlistpaimentfrais($event)"></app-paiment-frais>
                    </div>
                    <div *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != '' && prospect_id.trim() != ''" class="p-2 mb-2" id="ListDocuments">
                        <app-list-documants [allowed_to_update_affaire]="allowed_to_update_affaire" (listDocumentsOpporExport)="getListDocOpp($event)" (listdocumentexport)="getListDoc($event)" [affaire_id]="affaire_id" [id_opportunite]="id_opportunite" [prospect_id]="prospect_id"
                            [contrat_id]="contrat_id"></app-list-documants>
                    </div>
                    <div class="p-2 mb-2" id="LiaisonsContrats" *ngIf="informationsContrat != null ">
                        <app-liaisons-contracts [allowed_to_update_affaire]="allowed_to_update_affaire" [informationsContrat]="informationsContrat" (listContratEnLiasonExport)="getlistContratenliason($event)"></app-liaisons-contracts>
                    </div>
                    <div *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != ''" class="p-2 mb-2" id="Tickets">
                        <app-tickets [allowed_to_update_affaire]="allowed_to_update_affaire" (listticketsexport)="getlistticket($event)" [affaire_id]="affaire_id" [id_opportunite]="id_opportunite" [prospect_id]="prospect_id">
                        </app-tickets>
                    </div>
                    <div class="p-2 mb-2" id="tache">
                        <app-tache [allowed_to_update_affaire]="allowed_to_update_affaire"></app-tache>
                    </div>
                    <div class="p-2 mb-2" id="InfAvancee" *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != ''">
                        <app-information-avancees [allowed_to_update_affaire]="allowed_to_update_affaire" (infoComplementaireexport)="getlistComp($event)" (infoSpecifiqueexport)="getlistSpecif($event)" (onUpdateinformationsComp)="submitInfoCompl($event)" (onUpdateinformationsSpecifique)="submitInfoSpecifique($event)"
                            [affaire_id]="affaire_id" [id_opportunite]="id_opportunite">
                        </app-information-avancees>
                    </div>
                    <div class="p-2 mb-2" id="comment">

                      <app-add-commentaire (commentaireExport)="getlistcommentaireexport($event)" [allowed_to_update]="allowed_to_update_affaire" [fromType]="'A'"></app-add-commentaire>
                  </div>
                    <div *ngIf="affaire_id.trim() != '' && id_opportunite.trim() != '' " class="p-2 mb-2" id="Communication">

                        <app-add-communication [allowed_to_update_affaire]="allowed_to_update_affaire" [affaire_id]="affaire_id" [id_opportunite]="id_opportunite" [listParent]="listParent" [fromtype]="'A'" [listTypes]="listTypes" [type_envoie]='type_envoie' [statut_envoi]="statut_envoi"
                            [statut]="statut" [prospect]="prospect" [telNumber]="telNumber">
                        </app-add-communication>

                    </div>



                    <div class="p-2 mb-2" id="Historique" *ngIf=" histAccess">
                        <app-historique [allowed_to_update_affaire]="allowed_to_update_affaire"></app-historique>
                    </div>
                    <div class="p-2 mb-2" id="HistoriqueCommunication" *ngIf=" histCommAccess">
                        <app-historiques-communication [allowed_to_update_affaire]="allowed_to_update_affaire"></app-historiques-communication>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog model-lg actions-dialog" role="document">
        <div class="modal-content w-100">
            <div class="modal-header">
                <svg class="mt-1" xmlns="http://www.w3.org/2000/svg" width="21.829" height="22" viewBox="0 0 21.829 22">
                <g id="icons8-correct-document" transform="translate(-4 -6)">
                  <path id="Tracé_1075" data-name="Tracé 1075" d="M34.9,103.328h0a1.9,1.9,0,0,1-1.9,1.9H22a1.9,1.9,0,0,0,1.9-1.9h0V102h11Z" transform="translate(-14.586 -77.793)" fill="rgb(55, 55, 55);"/>
                  <path id="Tracé_1076" data-name="Tracé 1076" d="M99.793,12.793H97.9A1.9,1.9,0,0,1,96,10.9V9h0a3.8,3.8,0,0,1,3.793,3.793Z" transform="translate(-74.552 -2.431)" fill="rgb(55, 55, 55);"/>
                  <path id="Tracé_1077" data-name="Tracé 1077" d="M18.414,103.362h-11a.569.569,0,0,1,0-1.138A1.319,1.319,0,0,0,8.741,100.9V99.569A.559.559,0,0,1,9.31,99h11a.559.559,0,0,1,.569.569V100.9A2.461,2.461,0,0,1,18.414,103.362ZM9.5,102.224h8.914a1.319,1.319,0,0,0,1.328-1.328v-.759H9.879v.759A2.586,2.586,0,0,1,9.5,102.224Zm-4.931,1.138a.241.241,0,0,1-.114-.019.27.27,0,0,1-.114-.038c-.038-.019-.057-.038-.095-.057a.148.148,0,0,1-.076-.076A.452.452,0,0,1,4,102.793a.241.241,0,0,1,.019-.114.27.27,0,0,1,.038-.114c.019-.038.038-.057.057-.095a.333.333,0,0,1,.076-.095l.076-.076c.038-.019.057-.038.095-.057s.076-.019.114-.038a.562.562,0,0,1,.512.152.414.414,0,0,1,.076.095c.019.038.038.057.057.095s.019.076.038.114c0,.038.019.076.019.114a.517.517,0,0,1-.171.4A.606.606,0,0,1,4.569,103.362Z" transform="translate(0 -75.362)"  fill="rgb(55, 55, 55);"/>
                  <path id="Tracé_1078" data-name="Tracé 1078" d="M40.569,63.672A.559.559,0,0,1,40,63.1v-1.9a.38.38,0,0,0-.379-.379H37.25a1.534,1.534,0,0,1-1.119-.493,1.482,1.482,0,0,1-.379-1.176l.3-2.94a1,1,0,0,0-.247-.759.967.967,0,0,0-1.441,0,.929.929,0,0,0-.247.759l.3,2.94a1.578,1.578,0,0,1-.379,1.176,1.725,1.725,0,0,1-1.157.493H30.517a.38.38,0,0,0-.379.379v1.9A.569.569,0,0,1,29,63.1v-1.9a1.522,1.522,0,0,1,1.517-1.517h2.371a.338.338,0,0,0,.284-.133.362.362,0,0,0,.095-.284l-.3-2.94A2.116,2.116,0,0,1,35.069,54a2.071,2.071,0,0,1,1.574.7,2.134,2.134,0,0,1,.531,1.631l-.3,2.94a.335.335,0,0,0,.095.284.4.4,0,0,0,.284.133h2.371a1.522,1.522,0,0,1,1.517,1.517v1.9A.559.559,0,0,1,40.569,63.672Z" transform="translate(-20.259 -38.897)"  fill="rgb(55, 55, 55);"/>
                  <g id="Groupe_883" data-name="Groupe 883" transform="translate(8.741 6)">
                    <path id="Tracé_1079" data-name="Tracé 1079" d="M46.05,25.534l.019-12.707a.569.569,0,0,0-1.138,0l-.019,12.707a1.319,1.319,0,0,1-1.328,1.328H42.466a.569.569,0,0,0,0,1.138h1.119A2.485,2.485,0,0,0,46.05,25.534ZM41.707,6H31.466A2.461,2.461,0,0,0,29,8.466V18.9a.569.569,0,0,0,1.138,0V8.466a1.319,1.319,0,0,1,1.328-1.328h9.672V8.466A2.461,2.461,0,0,0,43.6,10.931h1.9a.618.618,0,0,0,.531-.341.5.5,0,0,0,.057-.228v-.076a4.4,4.4,0,0,0-1.271-3.016A4.456,4.456,0,0,0,41.707,6Zm.569,2.466V7.195a3.215,3.215,0,0,1,2.6,2.6H43.6A1.319,1.319,0,0,1,42.276,8.466Z" transform="translate(-29 -6)"  fill="rgb(55, 55, 55);"/>
                  </g>
                </g>
              </svg>
                <h2>
                    {{'opportunitiesdetails.label.listeactions' | translate}}
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="show_loading_status">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div style="width: min-content; margin: auto; margin-bottom: 50px; margin-top: 50px">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="row" *ngIf="!show_loading_status">
                    <ng-container *ngFor="let item of status_autorise.listCategory">
                        <div class="col">
                            <div class="row">
                                <div class="col-12 style-div title text-center">
                                    Service : {{item.label}}
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <ng-container *ngFor="let status of item.listactions">
                                            <div class="col-3 text-center style-div">

                                                <button [disabled]="status.dependance!==status_id && status.dependance!==null" [ngStyle]="{'opacity':status.dependance!==status_id && status.dependance!==null ? 0.4 : none}" (click)="updateSubActionsList(status?.id)" class="btn-affect border-0" data-toggle="modal"
                                                    data-target="#subActionModal" [styles]="{'background-color': status?.color, 'color': status?.couleur_police}">

                          <p>{{status?.etat}}<span *ngIf="status.etat == prospect.etat" style="color: darkred">*</span>
                          </p>
                        </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="subActionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog model-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    {{'opportunitiesdetails.label.changeraction' | translate}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="show_loading">
                    <div class="row mb-3 mt-3">
                        <div class="text-center m-auto ">
                            <mat-spinner [diameter]="50"></mat-spinner>

                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!show_loading">
                    <div class="row" *ngIf="subStatusList.length>0">
                        <ng-container *ngFor="let status of subStatusList">
                            <div class="col-6 text-center style-div">
                                <button [ngStyle]="{'opacity':(status.dependance!==status_id && status.dependance!==null) ? 0.4 : none}" [disabled]="status.etat == prospect.etat || (status.dependance!==status_id && status.dependance!==null)" (click)="updateSubActionsList(status?.id)"
                                    class="btn-affect" [styles]="{'background-color': status?.color, 'color': status?.couleur_police}">

                                  <p>{{status?.etat}} <span *ngIf="status.etat == prospect.etat" style="color: darkred">*</span></p>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row" *ngIf="subStatusList.length==0">
                        <h2 class="text-center m-auto text-secondary">
                            Voulez-vous vraiment changer le statut de cette Affaire ?
                        </h2>
                    </div>
                    <hr>
                    <div *ngIf="subStatusList.length==0" class="d-flex justify-content-around">
                        <div>
                            <button (click)="changeAction()" class="btn-dt-save-popup">
                                <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                                Oui
                              </button>
                        </div>
                        <div>
                            <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal">
                                <fa-icon class="fa-icon-style" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                                Non
                            </button>
                        </div>

                    </div>
                </ng-container>
            </div>

        </div>
    </div>
</div>
