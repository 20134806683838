<div class="card bg-white p-3 border-0">
    <div class="card-doc-title ml-3 row" [ngClass]="{'cover': !allowed_to_update_affaire}">
        <fa-icon class="mr-2 fa-lg" [icon]="fa['faInfoCircle']"></fa-icon>
        <h4>Informations Avancées</h4>
    </div>
    <div class="row">
        <div class="col-6 pr-0">
            <button class="button-info left-btn w-100" [ngClass]="{'button-style text-white': complementaire}" (click)="getInfoComp()">
              Informations complémentaires compagne :
            </button>
        </div>
        <div class="col-6 pl-0">
            <button class="button-info right-btn w-100" [ngClass]="{'button-style text-white': specifique}" (click)="getInfoSpec()">
                Informations Complémentaires Ligne De Produits :
            </button>
        </div>
    </div>
    <div class="col-12 mt-3" *ngIf="specifique">
        <ng-container *ngIf="fieldsspecif.length>0  && !loading">
            <form [formGroup]="form" [ngClass]="{'cover': !allowed_to_update_affaire}">
                <div class="row">
                    <div class="col-12">
                        <formly-form (click)="submitInfoSpecifique()" [model]="modelspecif" [fields]="fieldsspecif" [options]="options" [form]="formSpecifique"></formly-form>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="fieldsspecif.length<1  && !loading">
            <p class="text-center mt-2">Pas d'informations</p>
        </ng-container>
    </div>
    <div class="col-12 mt-3" *ngIf="complementaire">
        <ng-container *ngIf="fieldscomp.length>0 && !loading">
            <form [formGroup]="form" [ngClass]="{'cover': !allowed_to_update_affaire}">
                <div class="row">
                    <div class="col-12">
                        <formly-form class="info-complementaire-affaire-disabled" [model]="modelcomp" [fields]="fieldscomp" [options]="options" [form]="formComplementaire"></formly-form>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="d-flex justify-content-center">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="fieldscomp.length<1 && !loading">
            <p class="text-center mt-2"><strong>Pas d'informations</strong></p>
        </ng-container>
    </div>
</div>
