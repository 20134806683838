import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { SharedMenuObserverService } from '../../../../../services/SharedMenuObserver/shared-menu-observer.service';
import { ApiComminucationService } from '../../../../../services/ApiComminucation/api-comminucation.service';
import { tap } from 'rxjs/operators';
import { ListTypesAndParent } from '../../../../../entity/Opportunity/ListTypesAndParent';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
import { DatePipe } from '@angular/common';
import { GetOpportuniteDetails } from 'src/app/entity/Opportunity/GetOpportuniteDetails';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { element } from 'protractor';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css'],
  providers: [DatePipe],
})
export class CommunicationComponent implements OnInit, OnDestroy, AfterViewInit {
  mail_user: string;

  listParent: ListTypesAndParent[] = [];
  listTypes: ListTypesAndParent[] = [];

  myDate = new Date();
  currentDateTime: string;
  opportunity_id: string;
  affaire_id: string = '';
  listeOfUnsubscribeWebService: Subscription[] = [];
  statut: any;
  statut_envoi: any;
  type_envoie: any;
  opportunite_details: GetOpportuniteDetails = new GetOpportuniteDetails();
  id_prospect: string = '';
  telNumber: any;
  prospect: any;
  fromtype = 'O';
  allowed_to_update: boolean;
  constructor(
    private apiCommunicationService: ApiComminucationService,
    private apiOpportuniteServices: ApiOpportunitService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private datePipe: DatePipe,
    public NotificationsService: NotificationsService,
    private apiProspectService: ApiProspectsService,
    route: ActivatedRoute
  ) {
    this.currentDateTime = this.datePipe.transform(this.myDate, 'yyyy-mm-dd HH:MM:SS.SSS');
  }

  ngAfterViewInit(): void {
    this.apiOpportuniteServices.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.opportunity_id = this.opportunitiesDetailsComponent.opportunity_id;
    this.getListTypeAndParent();
    this.getOpportunite();
    this.getTelOpp();
    this.getProspect();
    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails ➜ Communications');
  }

  getOpportunite() {
    const unsubscribeGetOpportunite = this.apiOpportuniteServices
      .GetOpportunite(this.opportunity_id)
      .subscribe((data: GetOpportuniteDetails) => {
        this.opportunite_details = data;
        this.id_prospect = this.opportunite_details.opportunite_details.main_prospect_id;
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetOpportunite);
  }

  getProspect() {
    const unsubscribeGetProspectByOpprtuniteId = this.apiProspectService
      .getProspectByOpprtuniteId(this.opportunity_id)
      .subscribe((data) => {
        this.prospect = data;
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetProspectByOpprtuniteId);
  }

  getTelOpp() {
    const unsubscribeGetProspectTelByOppId = this.apiOpportuniteServices
      .getProspectTelByOppId(this.opportunity_id)
      .subscribe((data) => {
        this.telNumber = data;
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetProspectTelByOppId);
  }

  getListTypeAndParent() {
    const unsubscribeGetListTypeAndParent = this.apiCommunicationService
      .GetListTypeAndParent()
      .pipe(
        tap(({ parent, types }) => {
          this.listParent = parent;
          this.listTypes = types;
        })
      )
      .subscribe();
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListTypeAndParent);
  }
}
