import {InjectionToken} from '@angular/core';
import {StoreConfig} from '@ngrx/store/src/store_module';
import {AddOpportuniteState} from './add-opportunite.reducers';


export const ADD_OPPORTUNITE_STORAGE_KEYS =
  new InjectionToken<string[]>('AddOpportuniteStorageKeys');
export const ADD_OPPORTUNITE_LOCAL_STORAGE_KEY =
  new InjectionToken<string[]>('AddOpportuniteStorage');
export const ADD_OPPORTUNITE_CONFIG_TOKEN =
  new InjectionToken<StoreConfig<AddOpportuniteState, any>>('AddOpportuniteConfigToken');
