import { FormField } from 'src/app/entity/Formbuilder/FormField';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'shared-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.css'],
})
export class SharedFormViewComponent implements OnInit {
  @Input() item: FormField;
  @Output() onChangeParams = new EventEmitter<any>();
  dict = {
    Select: 'selectionner',
    Time: 'temps',
    Text: 'Texte',
    'Text Area': 'Zone de texte',
    Radio: 'Radio',
    Password: 'Mot de passe',
    Number: 'Nombre',
    'Null Element': 'Element nul',
    Email: 'E-mail',
    Date: 'Date',
    'Check box': 'Case a cocher',
    Array: 'Tableau',
    'Multi Select': 'Selection multiple',
  };

  constructor() {}

  ngOnInit(): void {}
}
