import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ApiComminucationService } from '../../../services/ApiComminucation/api-comminucation.service';
import { MailevaElement } from '../../../entity/Maileva/MailevaElement';
import { MailevaHistorique } from '../../../entity/Maileva/MailEvaHistorique';
import Swal from 'sweetalert2';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { parseDate } from 'src/app/shared/Utils/sharedFunctions';

@Component({
  selector: 'app-maileva-details',
  templateUrl: './maileva-details.component.html',
  styleUrls: ['./maileva-details.component.css'],
})
export class MailevaDetailsComponent implements OnInit {
  fa = fa;
  maileva_id: String = '';
  maileva: MailevaElement = new MailevaElement();
  datepalanifier: Date = new Date();
  updatedate: any;
  myDate = new Date();
  historique: MailevaHistorique[];

  displayedColumns: string[] = ['date', 'user', 'commentaire'];
  dataSource: MatTableDataSource<MailevaHistorique>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showPlanifier: Boolean = false;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiComminucationService: ApiComminucationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Maileva ➜ Details');
    this.route.paramMap.subscribe((params) => {
      this.maileva_id = params.get('maileva_id');
    });
    this.getpost();
    this.getHistoriqueMaileva(this.maileva_id);
  }

  returnToListMaileva() {
    this.router.navigate(['/maileva']);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  getpost() {
    this.showloader();
    this.apiComminucationService
      .GetDetailsPost(this.maileva_id)
      .pipe(
        tap(({ post_details }) => {
          this.maileva = post_details;
          const dateticket = parseDate(post_details.date);
          post_details.date = dateticket;
          this.datepalanifier = parseDate(this.maileva.date_envoie);
        })
      )
      .subscribe((data) => {
        this.hideloader();
      });
  }

  updateDateplanifie() {
    const datemodifier = this.datePipe.transform(this.datepalanifier, 'yyyy-MM-dd');
    this.updatedate = {
      id_post: this.maileva.id,
      date_planifie: datemodifier,
    };
    this.apiComminucationService.updateDatePlanifier(this.updatedate).subscribe((data) => {
      location.reload();
    });
  }

  resendMaileva() {
    this.apiComminucationService.ResendMail(this.maileva_id).subscribe((data) => {
      location.reload();
    });
  }

  cancelMaileva() {
    Swal.fire({
      text: 'Êtes-vous sur de vouloir annuler ce courrier',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer',
      confirmButtonColor: '#e75e5e',
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiComminucationService.CancleMaileva(this.maileva_id).subscribe((data) => {
          location.reload();
        });
      }
    });
  }

  returnToMaileva() {
    this.router.navigate(['/maileva']);
  }

  approve() {
    this.apiComminucationService.Approuved(this.maileva_id).subscribe((data) => {
      location.reload();
    });
  }

  onclickPlanifier() {
    this.showPlanifier = !this.showPlanifier;
  }

  showReceptionRow() {
    if (
      (this.maileva.avis_reception !== null && this.maileva.avis_reception.length !== 0) ||
      (this.maileva.ar_date !== null && this.maileva.ar_date?.length !== 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  showPreuveRow() {
    if (
      (this.maileva.preuve_attachement !== null && this.maileva?.preuve_attachement.length !== 0) ||
      (this.maileva.preuve_date !== null && this.maileva.preuve_date.length !== 0) ||
      (this.maileva.preuve_code !== null && this.maileva.preuve_code.length !== 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /*
   *
   *permet d'avoir historique de courier
   */
  getHistoriqueMaileva(idcomm) {
    this.apiComminucationService.getHistoriqueMaileva(idcomm).subscribe(
      (data) => {
        this.historique = data.actions.forEach((element) => {
          const dateticket = parseDate(element.date_action);
          element.date_action = dateticket;
        });
        this.historique = data.actions.reverse();
        this.dataSource = new MatTableDataSource(this.historique);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
