import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { FormModel } from 'src/app/entity/Formbuilder/FormModel';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';


class Config {
  key: string;
  value: any;
}
class Instance {
  report_server: string;
  subscription_url: string;
  currentCurrency: string;
  formatCurrency: string;
  country: string;
  api_url: String;

}
@Component({
  selector: 'app-add-configuration',
  templateUrl: './add-configuration.component.html',
  styleUrls: ['./add-configuration.component.css']
})
export class AddConfigurationComponent implements OnInit ,AfterContentInit {
  panelOpenState = false;
  formBuilderDataPhysique: any;
  formBuilderDataMorale: any;
  formBuilderDataOrganisme: any;

  listOfUnsubscribeWebService: Subscription[] = [];
  fa = fa;
  config = new Config;
  keyFormOrganisme: string;
  keyFromPhysique: string;
  keyFormMorale: string;
  keyInstance: string;
  infoSpecif: boolean = true;
  infoSpecifEn: boolean = false;
  organisme: boolean = false;
  instance: boolean;
 // subscription_url: any;
   @ViewChild('souscription_url') souscription_url;
   @ViewChild('report_server') report_server;
   @ViewChild('api_url') api_url;
   
  currentCurrency: string;
  formatCurrency: string;
  country: string;
  bodyInstance = new Instance;
  formulaireTrigger = false
  urlvalid2: boolean;
  urlvalid1: boolean;
  urlvalid3: boolean;
  constructor(private apiAdminBpmServices: ApiAdminBpmService,
    private apiConfigurationService: ApiConfigurationService,
    private sharedMenuObserverService: SharedMenuObserverService,

  ) { }

  ngAfterContentInit(): void {
    console.log(this.souscription_url,'this.subscription_url.nativeElement')

  }

  ngOnInit(): void {
    
    this.sharedMenuObserverService.updateMenu('Configuration');

    this.keyFromPhysique = environment.keyFormProspect;
    this.keyFormMorale = environment.keyFormEntreprise;
    this.keyFormOrganisme = environment.keyFormOrganisme;
    this.keyInstance = environment.keyInstance;

    this.apiConfigurationService.getParamConfig(this.keyFromPhysique).subscribe(
      (Response) => {
        this.formBuilderDataPhysique = Response.value;
        this.formulaireTrigger =true
      });
    this.apiConfigurationService.getParamConfig(this.keyFormMorale).subscribe(
      (Response) => {
        this.formBuilderDataMorale = Response.value;
      });
    this.apiConfigurationService.getParamConfig(this.keyFormOrganisme).subscribe(
      (Response) => {
        this.formBuilderDataOrganisme = Response.value;


      });
    this.apiConfigurationService.getParamConfig(this.keyInstance).subscribe(
      (Response) => {
        this.bodyInstance = Response.value;
        // this.report_server = Response.value.report_server;
        // this.country = Response.value.country;
        // this.currentCurrency = Response.value.currentCurrency;
        // this.formatCurrency = Response.value.formatCurrency;
        // this.subscription_url = Response.value.subscription_url;

      });

  }

  getDataPhysiqueForm(event: any): void {
    this.formBuilderDataPhysique = event;
  }

  getDataMoraleForm(event: any): void {
    this.formBuilderDataMorale= event;
  }

  getDataOrganismeForm(event: any): void {
    this.formBuilderDataOrganisme = event;
  }

  ngOnDestroy(): void {
    this.apiAdminBpmServices.sharedFormbuilder = {};
    this.listOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    })
  }

  scroll(el: string) {
    if (el === 'organisme') {
      this.apiAdminBpmServices.sharedFormbuilder = {};
      this.organisme = true;
      this.infoSpecif = false;
      this.infoSpecifEn = false;
      this.instance = false;

    } else if (el === 'infoSpecifEn') {
      this.apiAdminBpmServices.sharedFormbuilder = {};
      this.infoSpecifEn = true;
      this.infoSpecif = false;
      this.organisme = false;
      this.instance = false;

    } else if (el === 'infoSpecif') {
      this.apiAdminBpmServices.sharedFormbuilder = {};
      this.infoSpecif = true;
      this.organisme = false;
      this.infoSpecifEn = false;
      this.instance = false;
      /*  faHouseUser
       faWarehouse */
    } else {
      this.instance = true;
      this.infoSpecif = false;
      this.organisme = false;
      this.infoSpecifEn = false;
    }

  }


  addConfig(type) {
  
    if (type === 'organisme') {
      this.config.key = this.keyFormOrganisme;
      this.config.value =this.formBuilderDataOrganisme
      this.apiConfigurationService.updateParamConfig(this.keyFormOrganisme, this.config).subscribe(
        (Response) => {
          this.apiAdminBpmServices.sharedIsSubmited = true;
          // this.apiAdminBpmServices.sharedFormbuilder = {};
          this.ngOnInit();
          this.alertSuccess('Informations complémentaires organismes modifiés avec succès');

        }
        ,(error) => {
          console.log(error)
          this.alertError('Erreur lors de la modification du formulaire');
        });

    } else
      if (type === 'morale') {
        this.config.key = this.keyFormMorale;
        this.config.value =this.formBuilderDataMorale
        this.apiConfigurationService.updateParamConfig(this.keyFormMorale, this.config).subscribe(
          (Response) => {
            this.apiAdminBpmServices.sharedIsSubmited = true;
            // this.apiAdminBpmServices.sharedFormbuilder = {};
            this.ngOnInit();
           this.alertSuccess('Informations complémentaires morales ajoutés avec succès');
            
          },(error)=>{
            console.log(error)
            this. alertError('Erreur lors de la modification du formulaire');

          })


      } else
        if (type === 'physique') {
          this.config.key = this.keyFromPhysique;
          this.config.value =this.formBuilderDataPhysique
          this.apiConfigurationService.updateParamConfig(this.keyFromPhysique, this.config).subscribe(
            (Response) => {
              //  this.apiAdminBpmServices.sharedFormbuilder = {};
              this.ngOnInit();

              this.apiAdminBpmServices.sharedIsSubmited = true;
              this.alertSuccess('Informations complémentaires physiques modifiés avec succès');

            }, (error) => {
              console.log(error)
              this.alertError('Erreur lors de la modification du formulaire');
            })

        } else {
          this.config.key = this.keyInstance;
          this.config.value = this.bodyInstance
          // this.bodyInstance.report_server = this.report_server;
          // this.bodyInstance.country = this.country;
          // this.bodyInstance.currentCurrency = this.currentCurrency;
          // this.bodyInstance.formatCurrency = this.formatCurrency;
          // this.bodyInstance.subscription_url = this.subscription_url;
            this.urlvalid1 = this.souscription_url.valueAccessor._elementRef.nativeElement.validity.valid;
            this.urlvalid2 = this.report_server.valueAccessor._elementRef.nativeElement.validity.valid;
            this.urlvalid3 = this.api_url.valueAccessor._elementRef.nativeElement.validity.valid;

            
         if(!this.urlvalid1 || !this.urlvalid2 || !this.urlvalid3 ){
          this.alertError('champs invalid ');
         }else{

        
          this.apiConfigurationService.updateParamConfig(this.keyInstance, this.config).subscribe(
            (Response) => {
              this.ngOnInit();
              this.apiAdminBpmServices.sharedIsSubmited = true;
              this.alertSuccess('Instances modifiés avec succès');

            }, (error) => {
              console.log(error)
              this.alertError('Erreur lors de la modification ');
            })
        }
      }
    /*     this.config.value.schema.properties = list;
        this.apiConfigurationService.addParamConfig(this.config).subscribe(
          (Response) => {
            console.log(Response)
          }); */

  }

  alertError(data, err?) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  alertSuccess(data, err?) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.ngOnInit();
        }
      });
  }
}
