import {Prospect} from "./Prospect";

export class ProspectSelector {
  prospect: Prospect;
  id_pros: string;

  constructor(prospect, id_pros) {
    this.prospect = prospect;
    this.id_pros = id_pros;
  }
}
