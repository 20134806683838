import {FormField} from './FormField';

export class TextFormly extends FormField {
  maxLength=255;
  minLength=0;
  pattern="";
  format="";
  constructor() {
    super();
    super.icon="fa fa-font";
    super.name = 'texte_' + super.generateRandomId();
    this.type = 'string';
    super.typename = 'Text';
  }
}
