import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FicheOrganismeComponent } from './fiche-organisme/fiche-organisme.component';
import { ListOrganismeComponent } from './list-organisme/list-organisme.component';
const routes: Routes = [
  {
    path: '',
    component: ListOrganismeComponent
  },
  {
    path: 'fiche/:organisme_id',
    component: FicheOrganismeComponent
  },

  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]

})
export class GestionOrganismeRoutingModule { }
