import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';

import { FilterTicket, Prospect, Ticket, Utilisateur } from 'src/app/entity/TicketsAndSupport/FilterTicket';

@Component({
  selector: 'app-filtres-ticket',
  templateUrl: './filtres-ticket.component.html',
  styleUrls: ['./filtres-ticket.component.css'],
})
export class FiltresTicketComponent implements OnInit {
  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;

  filterTickets: FilterTicket = new FilterTicket();
  panelOpenState = false;
  collapseFilterBox: boolean = true;

  @Output() onPushFilters: EventEmitter<FilterTicket> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();

  onResetPros: boolean = false;
  onResetUtil: boolean = false;
  onResetTickets: boolean = false;
  onResetReclamations: boolean = false;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {}

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  resetForm() {
    this.onResetPros = true;
    this.onResetUtil = true;
    this.onResetReclamations = true;
    this.onResetTickets = true;
  }

  getResetPros($event) {
    this.onResetPros = $event;
  }

  getResetUtil($event) {
    this.onResetUtil = $event;
  }

  getResetReclamations($event) {
    this.onResetReclamations = $event;
  }

  getResetTickets($event) {
    this.onResetTickets = $event;
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filterTickets);
  }

  EmitFilterUtilisateur($event: Utilisateur) {
    this.filterTickets.utilisateur = $event;
    this.EmitFilters();
  }

  EmitFilterProspec($event: Prospect) {
    this.filterTickets.prospect = $event;
    this.EmitFilters();
  }

  EmitFilterTickets($event: Ticket) {
    this.filterTickets.ticket = $event;
    this.EmitFilters();
  }

  submitedFilter() {
    this.onSubmitFilter.emit('');
    this.toggleFilterCorp();
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }
}
