class Campagne {
  id: string;
  campagne: string;
  bien: number;
  person: number;
}

export class CycleVie {
  campagne: { [key: string]: Campagne };
  hors_campagne: { [key: string]: Campagne };
}
