import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Tasks } from 'src/app/entity/Tasks/TasksDetails';
import { Task } from 'src/app/entity/task';
@Injectable({
  providedIn: 'root',
})
export class ApiTasksService {
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public list_prospects(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', `${this.basePath}/list_prospects`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public list_priorite(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('get', `${this.basePath}/list_priorite`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public list_taches(
    search_create: string,
    search_affect: string,
    page_create,
    page_affecte,
    limit_create,
    limit_affect,
    mode,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    queryParameters = queryParameters.set('search_create', <any>search_create);
    queryParameters = queryParameters.set('search_affect', <any>search_affect);
    queryParameters = queryParameters.set('page_affect', <any>page_affecte);
    queryParameters = queryParameters.set('limit_affect', <any>limit_affect);
    queryParameters = queryParameters.set('page_create', <any>page_create);
    queryParameters = queryParameters.set('limit_create', <any>limit_create);
    queryParameters = queryParameters.set('mode', <any>mode);
    return this.httpClient.request<any>('get', `${this.basePath}/list_taches_user_v1`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sert a parinner ajouter une tache
   *
   * @param Tasks object de tache
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addTasks(Tasks: Tasks, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/create_tache`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: Tasks,
    });
  }

  /**
   * Sert a parinner Modifier une tache
   *
   * @param Tasks object de tache
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public EditTasks(Tasks: Task, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_tache`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: Tasks,
    });
  }
}
