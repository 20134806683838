import { ApiDocumentsService } from 'src/app/services/ApiDocuments/api-documents.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentsTicket } from 'src/app/entity/TicketsAndSupport/DocumentsTicket';
import { TicketsDetailsComponent } from 'src/app/pages/tickets-and-support/details-tickets/tickets-details.component';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-liste-documents',
  templateUrl: './liste-documents.component.html',
  styleUrls: ['./liste-documents.component.css'],
})
export class ListeDocumentsComponent implements OnInit, OnDestroy {
  tickets_id: string = '';
  listDocuments: DocumentsTicket[] = [];
  listDocFiltered: DocumentsTicket[] = [];
  filterFile: any[] = [];
  listFileToSend: any[] = [];
  files: any[] = [];
  audio: any[] = [];

  show_loading_add: boolean = false;
  show_loading_doc: boolean = false;
  unsbscribeListDocumentTicket: Subscription;
  fa = fa;

  constructor(
    private apiTicketsServices: ApiTicketsService,
    private ticketsDetailsComponent: TicketsDetailsComponent,
    private apiDocumentsService: ApiDocumentsService
  ) {
    apiDocumentsService.loadDocuments().subscribe((load) => {
      if (load === 'refreshTicket') {
        this.loadListDocuments();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsbscribeListDocumentTicket.unsubscribe();
  }

  ngOnInit(): void {
    this.tickets_id = this.ticketsDetailsComponent.tickets_id;
    this.loadListDocuments();
    this.show_loading_add = true;
  }

  loadListDocuments() {
    this.show_loading_doc = true;
    this.files = [];
    this.audio = [];

    this.unsbscribeListDocumentTicket = this.apiTicketsServices
      .listDocumentTicket(this.tickets_id)
      .subscribe((data) => {
        this.listDocuments = data;
        this.files = data.files;
        this.listDocFiltered = this.listDocuments.filter((doc: any) => doc.existe);
        this.show_loading_doc = false;
        this.show_loading_add = false;
        this.listDocFiltered.forEach((element: any, index) => {
          this.filterFile[index] = element.files;
        });
        for (let index = 0; index < this.filterFile.length; index++) {
          this.filterFile[index].forEach((element) => {
            this.listFileToSend[this.listFileToSend.length] = element.url;
          });
        }
      });
  }

  getSharedDocument($event): void {
    /* if ($event !== undefined) {
      this.show_loading_doc = $event
    } else {
      this.loadListDocuments()
    } */
  }
}
