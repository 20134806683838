export class Campagne {
  choice: boolean = false;
  cycle_vie: string = null;
  campagne_id: string = null;
  product_id = '-1';
  invalidForms: any[] = [];
  objectAssurer: any[] = [];


}
let counter = 0;
export class ObjectAssurer {
  index: number
  form: {
    schema:{},
    data:{}
  };

  expand?: boolean=false;
  icon: string;
  nom: string;
  completed: boolean;
  constructor(index:number,form: any, icon: string, nom: string, completed: boolean,expand:boolean) {
    if(index!=null){
      this.index = index;
    }else{
      this.index = counter++;
    }
    this.expand=expand;
    this.form = form;
    this.icon = icon;
    this.nom = nom;
    this.completed = completed;
}

}

export class ObjectsAssurer {
  name: string;
  completed: boolean;
  color: string;

  objects_Assurer?: ObjectAssurer[];
}
