<div class="row" style="margin-top: 15px">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterCourrier()" (click)="EmitFilterCourrier()"
                    [(ngModel)]="filterCourrier.ref_notif" matInput placeholder="Réference maileva" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterCourrier()" (click)="EmitFilterCourrier()"
                    [(ngModel)]="filterCourrier.ref_client" matInput placeholder="Réference client  " type="text">
            </mat-form-field>

        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
                <mat-select (selectionChange)="EmitFilterCourrier()" [(ngModel)]="filterCourrier.sent_from"
                    placeholder="Envoyé à travers" multiple>
                    <mat-option value="interface">
                        Interface
                    </mat-option>
                    <mat-option value="autres">
                        Autres
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div class="col-6">

        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
                <mat-select [(ngModel)]="filterCourrier.type_courrier" (selectionChange)="EmitFilterCourrier()"
                    placeholder="Type de courrier" multiple>
                    <mat-option value="E">
                        Entrant
                    </mat-option>
                    <mat-option value="S">
                        Sortant
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input [(ngModel)]="filterCourrier.ref_preuve" (keyup.enter)="EmitFilterCourrier()" matInput
                    placeholder="Réference preuve éléctronique" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -6px;">
                <mat-date-range-input [rangePicker]="picker">
                    <input [(ngModel)]="date_debut_creation" matStartDate placeholder="Date de Création du" />
                    <input [(ngModel)]="date_fin_creation" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterCourrier()" #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>


</div>


<div class="row" style="margin-top: 7px;">
    <div class="col-6">
        <div class="form-group">

            <input class="style-timePicker" [(ngModel)]="filterCourrier.heure_debut_creation"
                placeholder="Heure début de Création" aria-label="24hr format" [format]="24"
                [ngxTimepicker]="Debutcreation">
            <ngx-material-timepicker (closed)="EmitFilterCourrier()" #Debutcreation></ngx-material-timepicker>
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <input class="style-timePicker" [(ngModel)]="filterCourrier.heure_fin_creation"
                placeholder="Heure fin de Création" aria-label="24hr format" [format]="24"
                [ngxTimepicker]="fincreation">
            <ngx-material-timepicker (closed)="EmitFilterCourrier()" #fincreation></ngx-material-timepicker>

        </div>
    </div>
</div>



<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: 7px;">
                <mat-date-range-input [rangePicker]="Planifie">
                    <input [(ngModel)]="date_debut_envoie" matStartDate placeholder="Date d'envoi planifié " />
                    <input [(ngModel)]="date_fin_envoie" matEndDate placeholder="du..à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="Planifie"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterCourrier()" #Planifie></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">

            <mat-form-field appearance="fill" style="width: 100%">
                <mat-select (selectionChange)="SelectListModels()" [(ngModel)]="modelSelectionnee" placeholder="Groupe">
                    <mat-option *ngFor="let item of listModels" value="{{item}}">
                        {{item}}
                    </mat-option>

                </mat-select>
            </mat-form-field>

        </div>
    </div>

</div>
<div class="row" *ngIf="listEmpty">
    <div class="col-12">
        <mat-form-field style="width: 100%;" appearance="standard">
            <mat-label>Modéle</mat-label>
            <mat-select placeholder="Modéle" (selectionChange)="EmitFilterCourrier()"
                (selectionChange)="getSelectedModels($event)" multiple>
                <mat-option [value]="list.id" *ngFor="let list of listEmpty">{{list.nom}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-select [(ngModel)]="filterCourrier.enveloppe_courrier" (selectionChange)="EmitFilterCourrier()"
                    (selectionChange)="getSelectedTypeEnvoi($event)" placeholder="Type d'enveloppe" multiple>
                    <mat-option value="C4">
                        C4
                    </mat-option>
                    <mat-option value="a5">
                        a5
                    </mat-option>

                </mat-select>
            </mat-form-field>

        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="type_envoie.length > 0">
                <mat-form-field style="width: 100%;margin-top: 11px;">
                    <mat-label>Type d'envoi</mat-label>
                    <mat-chip-list #typeEnvoi>
                        <mat-chip *ngFor="let typeEv of ListTypeEnvoie" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTypeEnvoie(typeEv)"
                            (removed)="EmitFilterCourrier()">
                            {{typeEv}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterCourrier()" placeholder="Selectionner Type d'envoi..."
                            #TypeEnvoieInput [formControl]="typeEnvoieFromCtrl" [matAutocomplete]="autoTypeEnvoie"
                            [matChipInputFor]="typeEnvoi" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTypeEnvoie="matAutocomplete" (optionSelected)="selectedTypeEnvoie($event)">
                        <mat-option *ngFor="let typeEv of filteredTypeEnvoie | async" [value]="typeEv.libelle">
                            {{typeEv.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row">

</div>
<div class="row">
    <div class="col-12">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="affranchissement">
                    <input [(ngModel)]="date_debut_livraison" matStartDate placeholder="Date d'affranchissement du" />
                    <input [(ngModel)]="date_fin_livraison" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="affranchissement"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterCourrier()" #affranchissement></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>



<div class="row" style="margin-top: 7px;">
    <div class="col-6">
        <div class="form-group">
            <input class="style-timePicker" [(ngModel)]="filterCourrier.heure_debut_livraison"
                placeholder="Heure début d'affranchissement " aria-label="24hr format" [format]="24"
                [ngxTimepicker]="Debutlivraison">
            <ngx-material-timepicker (closed)="EmitFilterCourrier()" #Debutlivraison></ngx-material-timepicker>
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <input class="style-timePicker" [(ngModel)]="filterCourrier.heure_fin_livraison"
                placeholder="Heure fin d'affranchissement" aria-label="24hr format" [format]="24"
                [ngxTimepicker]="finlivraison">
            <ngx-material-timepicker (closed)="EmitFilterCourrier()" #finlivraison></ngx-material-timepicker>

        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="prevue">
                    <input [(ngModel)]="date_debut_prevue" matStartDate
                        placeholder="Date réception preuve électronique" />
                    <input [(ngModel)]="date_fin_prevue" matEndDate placeholder="du..à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="prevue"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterCourrier()" #prevue></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row" style="margin-top: 7px;">
    <div class="col-6">
        <div class="form-group">
            <input class="style-timePicker" [(ngModel)]="filterCourrier.heure_debut_prevue"
                placeholder="Heure debut reception preuve électronique " aria-label="24hr format" [format]="24"
                [ngxTimepicker]="DebutReception">
            <ngx-material-timepicker (closed)="EmitFilterCourrier()" #DebutReception></ngx-material-timepicker>
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <input class="style-timePicker" [(ngModel)]="filterCourrier.heure_fin_prevue"
                placeholder="Heure fin reception preuve électronique" aria-label="24hr format" [format]="24"
                [ngxTimepicker]="finprevue">
            <ngx-material-timepicker (closed)="EmitFilterCourrier()" #finprevue></ngx-material-timepicker>

        </div>
    </div>
</div>