import {FormContract} from '../FormContract';
import {FormControl} from '@angular/forms';
import {  InformationsComplmentaireVelo } from './InformationsComplmentaire'
import {  InformationsSpecifiqueVelo } from './InformationsSpecifique'
export class DetailsVelo{

  etatActuel: string='N/R';
  commentaire: string=null;
  formContrat: FormContract=new FormContract();
  tags: any[]=[]
  model_info_complementaires:InformationsComplmentaireVelo=new InformationsComplmentaireVelo()
  model_info_specifiques:InformationsSpecifiqueVelo=new InformationsSpecifiqueVelo(); 
  constructor(init?: Partial<FormControl>) {
    Object.assign(this, init);
  }
}
