<ng-container *ngIf="campagneClone">
  <div class="row">

    <div class="col-12">
      <ng-container *ngIf="listCycleVieComp && !campagneClone?.choice">
        <div class="form-group">
          <mat-form-field class="w-100" appearance="standard">
            <mat-label>Campagne Marketing</mat-label>
            <!-- <mat-select (selectionChange)="onSelectCampagne()" [formControl]="campagneForm"
              [(ngModel)]="campagneClone.campagne_id" required>
              <mat-option value="{{comp.id}}" *ngFor="let comp of listCycleVieComp">{{comp.campagne}}</mat-option>
            </mat-select> -->

            <input type="text"  required matInput  [(formControl)]="campagneForm" [matAutocomplete]="auto">
            <mat-autocomplete (optionSelected)="onSelectCampagne($event)" autoActiveFirstOption [displayWith]="displayCampFn" #auto="matAutocomplete">
                <mat-option *ngFor="let comp of filteredCampagne |async" [value]="comp">
                    {{comp.campagne}}
                </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="noList && campagneClone?.choice">
        <div class="form-group">
          <mat-form-field class="w-100" appearance="standard">
            <mat-label>Provenance</mat-label>
            <mat-select>
              <mat-option>Aucune provenance</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>


    </div>
  </div>
  <mat-spinner *ngIf="loader==true" [diameter]="20"></mat-spinner>
  <div class="row" *ngIf="objectsAssurer.objects_Assurer.length>0 && loader==false && isbien==true">
    <div class="col-md-12">
      <span class="example-list-section font-weight-bold">
         <!-- <mat-checkbox class="example-margin"
                      [checked]="allComplete"
                      [color]="objectsAssurer.color"
                      (change)="setAll($event.checked)">
          {{objectsAssurer.name}}
        </mat-checkbox>  -->
        <b>{{objectsAssurer.name}}</b>
      </span>
      <span class="example-list-section">
        <ul class="list-without-puce  p-3">
          <li *ngFor="let objects_Assurer of objectsAssurer.objects_Assurer">
            <mat-checkbox [(ngModel)]="objects_Assurer.completed"
                          [color]="objects_Assurer.color"
                          (ngModelChange)="someComplete($event,objects_Assurer)">
              <b>{{objects_Assurer.nom}}</b>
            </mat-checkbox>
          </li>
        </ul>
      </span>
    </div>
  </div>

</ng-container>
