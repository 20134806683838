<div class="row" style="margin-top: 15px">

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterExpiditeur()" (click)="EmitFilterExpiditeur()"
                    [(ngModel)]="filterExpediteur.nom_prenom_expediteur" matInput placeholder="Nom & Prénom expéditeur"
                    type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterExpiditeur()" (click)="EmitFilterExpiditeur()"
                    [(ngModel)]="filterExpediteur.adresse_expediteur" matInput placeholder="N° et nom de rue  "
                    type="text">
            </mat-form-field>

        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterExpiditeur()" (click)="EmitFilterExpiditeur()"
                    pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre" #nombre="ngModel"
                    [(ngModel)]="filterExpediteur.cp_expediteur" matInput placeholder="Code postal " type="text">
            </mat-form-field>
            <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
                <div *ngIf="nombre.errors.pattern">
                    <i class="fa fa-exclamation-triangle"></i>&nbsp;Seulement des chiffres !
                </div>
            </div>
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterExpiditeur()" (click)="EmitFilterExpiditeur()"
                    [(ngModel)]="filterExpediteur.ville_expediteur" matInput placeholder="Ville" type="text">
            </mat-form-field>

        </div>
    </div>


    <div class="col-12">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterExpiditeur()" (click)="EmitFilterExpiditeur()"
                    [(ngModel)]="filterExpediteur.complement_adresse_expediteur" matInput
                    placeholder="Complément d'adresse" type="text">
            </mat-form-field>

        </div>
    </div>
</div>