<div class="card-historiques  bg-white border-0 p-3 mx-5">
  <div class="card-doc-title ml-3 row" >
    <fa-icon class="mr-2 fa-lg"  [icon]="fa['faHistory']" ></fa-icon>
    <h4>  Historique </h4>
  </div>
  <mat-form-field class="w-100" >
    <input matInput (keyup)="applyFilter($event)" placeholder="Recherche..." #input>
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table mat-table  class="w-100" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Date Action</th>
        <td mat-cell *matCellDef="let row" > {{row.date_action}} </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Utilisateur </th>
        <td mat-cell *matCellDef="let row" >  {{row.user}} </td>
      </ng-container>
      <ng-container matColumnDef="commentaire">
        <th mat-header-cell *matHeaderCellDef mat-sort-header  > Commentaire Action</th>
        <td mat-cell *matCellDef="let row" > {{row.commentaire_action}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7">Pas de données disponibles </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
