import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.css']
})
export class WikiComponent implements OnInit {
  dashbordUrl: string;
  urlSafe: SafeResourceUrl;
  constructor(public sanitizer: DomSanitizer,    private sharedMenuObserverService: SharedMenuObserverService,
    ) { }

  ngOnInit(): void {
    this.dashbordUrl = environment.dashboardUrl;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.dashbordUrl+'documentationWiki');
    this.sharedMenuObserverService.updateMenu('Wiki');


  }

}
