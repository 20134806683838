/**
 * Model Json schema form
 */
import {ObjectFormly} from './ObjectFormly';

export class FormModel {
  schema: ObjectFormly = new ObjectFormly();
 //data: [name:  string];

  constructor() {
  }
}
