export class ProspectClient {
    adresse = { postal_code:"",
    nom_dept:""};
    mobile: string =""
    adresse_mail: string="";
    name:string ="";
    surname: string="";
    sSearch: string = '';
    sortCol : number =0;
    sort:"ASC"
}
