<div class="row" [ngClass]="{'cover': !allowed_to_update}">
    <div class="col card p-3 border-0">
        <div class="col">
            <div class="card-doc-title ml-3 row">
                <fa-icon class="mr-2 fa-lg" [icon]="fa['faCommentDots']"></fa-icon>
                <h4>Commentaires</h4>
            </div>
            <button type="button" class="add-btn-en btn-add-comment-affaire-position position-absolute " *ngIf="!addCommentaire" value="Ajouter un Commentaire" (click)="AddComm()">
        <span class="add-bg-btn">
          <fa-icon [icon]="fa['faPlus']"></fa-icon>
        </span>
      </button>
            <ng-container *ngIf="show_loading_add">
                <div class="row mb-3 mt-3">
                    <div class="text-center m-auto">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>
                </div>
            </ng-container>
            <div class="comment scrollbar" [ngClass]="{ 'initial-style': listComments.length < 2 }">
                <div *ngIf="listComments.length < 1" class="row d-flex justify-content-center">Aucun Commentaire</div>
                <div class="row mt-2 mb-2 mr-1" *ngFor="let comment of listComments">
                    <div class="col-2 text-center mt-4" *ngIf="comment.user_image">
                        <img src="{{ comment.user_image }}" class="user-photo" />
                    </div>
                    <div class="col-2 text-center mt-4" *ngIf="comment.user_image == null">
                        <img src="../../../assets/img/user.png" class="user-photo" />
                    </div>
                    <div class="col-10 pt-1 mw-75 card-commentaire">
                        <div class="row p-2">
                            <div class="col-4 blueColor">
                                <h3>
                                    <fa-icon [icon]="fa['faCaretRight']"></fa-icon> {{ comment.user_name }}</h3>
                            </div>


                            <div class="col-8 small greyColor">
                                <p class="pl-5">{{ comment.date | date: 'dd/MM/yyyy HH:mm' }}</p>
                            </div>
                        </div>
                        <div *ngIf="comment.commentaire!=null" class="row p-2">
                            <div class="col">
                                <p class="small">{{ comment.commentaire }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3" *ngFor="let doc of comment.docs">
                                <a href="{{doc}}" target="_blank">
                                    <img *ngIf="doc.includes('.png') || doc.includes('.jpeg') || doc.includes('.jpg')" src='{{doc}}' alt="">
                                    <img *ngIf="!doc.includes('.png') && !doc.includes('.jpeg') && !doc.includes('.jpg')" src='/assets/img/attach-file-16.png' alt="">
                                    <div>{{expandFileName(doc.split('/')[doc.split('/').length-1])}}</div>
                                    <!-- <span  class="small" > <fa-icon class="fa mr-1 blueColor" [icon]="fa['faPaperclip']"></fa-icon><a href="{{doc}}" target="_blank">{{doc.split('/')[doc.split('/').length-1]}}</a></span> -->
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-4 card ml-2 p-3" *ngIf="addCommentaire">
        <button class="icon2-pos text-white w-100 border-0 position-absolute">
      <div class="row" (click)="AddComm()">
        <fa-icon class="fa fa-lg text-white" [icon]="fa['faPlusCircle']"></fa-icon>
        <span class="col mt-1"> Ajouter un Commentaire</span>
      </div>
    </button>

        <form [formGroup]="form">
            <div class="modal-body" class="form-group">
                <textarea name="comment" class="col textComment mt-4" placeholder="Ecrivez Votre Commentaire ici . . . " cols="30" formControlName="comment" rows="5"></textarea>
                <div class="mb-3 col-md-12">
                    <!-- <label for="formFile" class="form-label">Joindre des fichiers</label> -->
                    <!-- <input (change)="this.onFilechange($event)" class="form-control" multiple type="file" id="formFile"> -->
                    <div class="form-group mt-1">
                        <label for="fileField" class="attachment">
              <div class="row btn-file mt-1">
                <div class="btn-file__preview mt-1"></div>
                <div class="btn-file__actions mt-1">
                  <div class="btn-file__actions__item mt-1 col-xs-12 text-center">
                    <div class="btn-file__actions__item--shadow mt-1">
                      <i class="fa fa-plus fa-lg fa-fw mt-1" aria-hidden="true"></i>
                      <div class="visible-xs-block mt-1"></div>
                      Choisir un fichier
                    </div>
                  </div>
                </div>
              </div>
              <input (change)="this.onFilechange($event)" multiple name="file" type="file" id="fileField">
            </label>
                    </div>
                    <div class="row">
                        <div class="col-md-4" *ngFor="let file of fileList">
                            <span (click)="remouvefile(file)" class="close"></span>
                            <img *ngIf="file.types=='image'" src='{{file.path}}' alt="">
                            <img *ngIf="file.types=='file'" src='/assets/img/attach-file-16.png' alt="">
                            <div class="file-info"><span class="file-name">{{file.name  | slice:0:30}} </span></div>
                            <div class="progress-bar"> </div>
                        </div>
                    </div>

                </div>
            </div>
            <p>
                Les informations renseignées ne doivent en aucun cas être des données sensibles,ni porter sur la vie privée.
            </p>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn-dt-ajouter" (click)="onSubmit()" type="submit">
          <fa-icon class="fa fa-lg" [icon]="fa['faPlusCircle']"></fa-icon>&nbsp; Ajouter
        </button>
            </div>
        </form>
    </div>
</div>