<div class="row" style="margin-top: 15px">
 
  <div class="col-6">
    <div class="form-group">
      <div class="checkbox-container" style="font-size: 2em;margin-left: -6px;">
        <input (click)="EmitFilterTelephonie()" class="checkbox" type="checkbox" id="contient"
          (change)="checking($event)" [(ngModel)]="filterTelephonie.contient">
        <div class="checkbox-visual" style="margin-left: 15px">
          <div></div>
        </div>
      </div>
    </div>
  </div> <div class="col-6">
    <div class="form-group">
      <label class="contien">{{'opportunities.label.containnotcontain' | translate}}</label>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="form-group">
      <mat-form-field appearance="fill" style="margin-top: -9px;width: 100%;">
        <mat-select (selectionChange)="EmitFilterTelephonie();getNombre($event)"
          [(ngModel)]="filterTelephonie.operateur_nb_appel" placeholder="Nombres d'appels :">
          <mat-option value="=">
            est égale à
          </mat-option>
          <mat-option value="!=">
            n'est pas égale à
          </mat-option>
          <mat-option value="<">
            est inférieure à
          </mat-option>
          <mat-option value="<=">
            est inférieure ou égale à
          </mat-option>
          <mat-option value=">">
            est supérieure à
          </mat-option>
          <mat-option value=">=">
            est supérieure ou égale à
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <input [disabled]="!noValue" (click)="EmitFilterTelephonie()" (keyup.enter)="EmitFilterTelephonie()"
          [(ngModel)]="filterTelephonie.nb_appel" matInput placeholder="Saisir nombre" type="number"
          pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre" #nombre="ngModel">
      </mat-form-field>
      <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
        <div *ngIf="nombre.errors.pattern">
          <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Seulement des chiffres !
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col-6">
    <div class="form-group">

      <mat-form-field appearance="fill" style="margin-top: -21px;width: 100%;">
        <mat-select (selectionChange)="EmitFilterTelephonie();getDuree($event)"
          [(ngModel)]="filterTelephonie.operateur_duree" placeholder="Durée (sec) :">
          <mat-option value="=">
            est égale à
          </mat-option>
          <mat-option value="!=">
            n'est pas égale à
          </mat-option>
          <mat-option value="<">
            est inférieure à
          </mat-option>
          <mat-option value="<=">
            est inférieure ou égale à
          </mat-option>
          <mat-option value=">">
            est supérieure à
          </mat-option>
          <mat-option value=">=">
            est supérieure ou égale à
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;margin-top: -13px;">
        <input [disabled]="!noDuree" (click)="EmitFilterTelephonie()" (keyup.enter)="EmitFilterTelephonie()"
          [(ngModel)]="filterTelephonie.duree" matInput placeholder="Saisir durée" type="number"
          pattern="[-+]?[0-9]*[.,]?[0-9]+" name="duree" #duree="ngModel">
      </mat-form-field>
      <div *ngIf="duree.invalid && (duree.dirty || duree.touched)" class="message-erreur">
        <div *ngIf="duree.errors.pattern">
          <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Seulement des chiffres !
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">

  <div class="col-6">
    <div class="form-group">
      <mat-form-field appearance="fill" style="margin-top: -25px;width: 100%;">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [(ngModel)]="date_debut_appel" placeholder="Date du" />
          <input matEndDate [(ngModel)]="date_fin_appel" placeholder="à" />

        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker (closed)="EmitFilterTelephonie()" #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <input [(ngModel)]="filterTelephonie.heure_debut_appel" class="style-timePicker" placeholder="Heure Début"
        aria-label="24hr format" [ngxTimepicker]="debut" [format]="24" readonly>
      <ngx-material-timepicker (closed)="EmitFilterTelephonie()" #debut></ngx-material-timepicker>
    </div>
  </div>

</div>
<div class="row">


</div>
<div class="row">
  <div class="col-6">
    <div class="form-group">
      <input style="margin-top: -11px;" [(ngModel)]="filterTelephonie.heure_fin_appel" class="style-timePicker"
        placeholder="Heure Fin" aria-label="24hr format" [ngxTimepicker]="Fin" [format]="24" readonly>
      <ngx-material-timepicker (closed)="EmitFilterTelephonie()" #Fin></ngx-material-timepicker>
    </div>
  </div>
  <div class="col-6" style="margin-top: -14px;">
    <div class="form-group">
      <ng-container *ngIf="allUser.length > 0">
        <mat-form-field style="width: 100%;">
          <mat-label>Recherche Utilisateurs</mat-label>
          <mat-chip-list #Recherche>
            <mat-chip *ngFor="let listUser of ListUser" [selectable]="selectable" [removable]="removable"
              (removed)="removeUser(listUser)" (removed)="EmitFilterTelephonie()">
              {{listUser}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterTelephonie()" placeholder="Selectionner Utilisateurs..." #userInput
              [formControl]="userFormCtrl" [matAutocomplete]="autoUser" [matChipInputFor]="Recherche"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selectedUser($event)">
            <mat-option *ngFor="let filteredUser of filteredUser | async" [value]="filteredUser.id">
              {{filteredUser.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-2">
    <div class="form-group" style="margin-bottom: 0px;">
      <mat-form-field appearance="fill" style="width: 100%;margin-top: -18px;">
        <mat-select (selectionChange)="EmitFilterTelephonie()" [(ngModel)]="filterTelephonie.operateur"
          placeholder="Opérateur">
          <mat-option value="OU">
            Ou
          </mat-option>
          <mat-option value="ET">
            Et
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-10">
    <div class="form-group">
      <ng-container *ngIf="allGroup.length > 0">
        <mat-form-field style="width: 100%;margin-top: -10px;">
          <mat-label>Groupe</mat-label>
          <mat-chip-list #Groupe>
            <mat-chip *ngFor="let group of ListGroup" [selectable]="selectable" [removable]="removable"
              (removed)="removeGroup(group)" (removed)="EmitFilterTelephonie()">
              {{group}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterTelephonie()" placeholder="Selectionner Groupe..." #groupInput
              [formControl]="groupFromCtrl" [matAutocomplete]="autoGroup" [matChipInputFor]="Groupe"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="selectedGroup($event)">
            <mat-option *ngFor="let group of filteredGroup | async" [value]="group.id">
              {{group.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>