<div class="row" style="margin-top: 15px">

    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="statut.length > 0">
                <mat-form-field style="width: 100%;margin-top: 11px;">
                    <mat-label>Etat Courrier</mat-label>
                    <mat-chip-list #EtatCourrier>
                        <mat-chip *ngFor="let etatCourrier of ListEtatCourrier" [selectable]="selectable"
                            [removable]="removable" (removed)="removeEtatCourrier(etatCourrier)"
                            (removed)="EmitFilterStatut()">
                            {{etatCourrier}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterStatut()" placeholder="Selectionner Etat Courrier..."
                            #etatCourrierInput [formControl]="etatCourrierFromCtrl" [matAutocomplete]="autoEtatCourrier"
                            [matChipInputFor]="EtatCourrier" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoEtatCourrier="matAutocomplete"
                        (optionSelected)="selectedEtatCourrier($event)">
                        <mat-option *ngFor="let typeEv of filteredEtatCourrier | async" [value]="typeEv.code">
                            {{typeEv.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="statut_envoi.length > 0">
                <mat-form-field style="width: 100%;margin-top: 11px;">
                    <mat-label>Statut envoi</mat-label>
                    <mat-chip-list #StatutEnvoi>
                        <mat-chip *ngFor="let statutEnvoi of ListStatutEnvoi" [selectable]="selectable"
                            [removable]="removable" (removed)="removeStatutEnvoi(statutEnvoi)"
                            (removed)="EmitFilterStatut()">
                            {{statutEnvoi}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterStatut()" placeholder="Selectionner Statut Envoi..." #statutEnvoiInput
                            [formControl]="statutEnvoiFromCtrl" [matAutocomplete]="autoStatutEnvoi"
                            [matChipInputFor]="StatutEnvoi" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoStatutEnvoi="matAutocomplete" (optionSelected)="selectedStatutEnvoi($event)">
                        <mat-option *ngFor="let statutEnvoi of filteredStatutEnvoi | async" [value]="statutEnvoi.code">
                            {{statutEnvoi.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>


</div>