import { Component, OnInit } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-shared-button-actuali',
  templateUrl: './shared-button-actuali.component.html',
  styleUrls: ['./shared-button-actuali.component.css']
})
export class SharedButtonActualiComponent implements OnInit {
  fa = fa;
  constructor() { }

  ngOnInit(): void {
  }

}
