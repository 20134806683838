<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button>
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <h1>Template Geoprod</h1>
    <span class="menu-spacer"></span>
    <div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row>
    <span style="font-size: 12px;">Template Geoprod</span>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened style="min-width: 200px;">
    <mat-nav-list>
      <a mat-list-item [routerLink]="'buttons'">Bouttons</a>
      <a mat-list-item [routerLink]="'data_table'">Data Tables</a>
      <a mat-list-item [routerLink]="'form_fields'">Form Fields</a>
      <a mat-list-item [routerLink]="'form_inputs'">Form Inputs</a>
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="'../'">Geoprod</a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="sidenav.toggle()">Close</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="padding: 25px">
    <div class="row">
      <div class="col-12 main-page">
        <router-outlet></router-outlet>
        <div class="footer-marge"></div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
