import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { HistoriqueTransactions } from '../../entity/Generic/HistoriqueTransactions';
import { ApiLedgerService } from 'src/app/services/ApiLedger/api-ledger.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-historique-transaction',
  templateUrl: './historique-transaction.component.html',
  styleUrls: ['./historique-transaction.component.css']
})
export class HistoriqueTransactionComponent implements OnInit {

  dataTableCompany: any;
  fa = fa;
  historique_utilisateur: HistoriqueTransactions[] = [];
  historique_company: HistoriqueTransactions[] = [];
  dataTableUser: any;

  constructor(private sharedMenuObserverService: SharedMenuObserverService,
    private chRef: ChangeDetectorRef,
    private ApiLedgerService: ApiLedgerService) { }

  ngOnInit(): void {
    this.showloader()
    this.sharedMenuObserverService.updateMenu("Historiques des transactions")
    this.getHistoriqueTransactionUtilisateur()
    this.getHistoriqueTransactionCompany()
  }

  getHistoriqueTransactionUtilisateur() {
    this.ApiLedgerService.getHistoriqueTransactionUtilisateur()
      .subscribe((data: HistoriqueTransactions[]) => {
        this.historique_utilisateur = data
        this.hideloader()
        this.chRef.detectChanges();
        const table: any = $('#TableUtilisateur');
        this.dataTableUser = table.DataTable({
          stateSave: true,
          language: {
            "sProcessing": "Traitement en cours...",
            "searchPlaceholder": " Recherche...",
            "sSearch": '<p aria-hidden="true"></p>',
            "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
            "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
            "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
            "oPaginate": {
              "sFirst": "Premier",
              "sPrevious": '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              "sNext": '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
              "sLast": "Dernier"
            },
            "oAria": {
              "sSortAscending": ": activer pour trier la colonne par ordre croissant",
              "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
            },
            "select": {
              "rows": {
                _: "%d lignes séléctionnées",
                0: "Aucune ligne séléctionnée",
                1: "1 ligne séléctionnée"
              }
            }
          }
        });
      })
  }
  getHistoriqueTransactionCompany() {
    this.ApiLedgerService.getHistoriqueTransactionCompany()
      .subscribe((data: HistoriqueTransactions[]) => {
        this.historique_company = data
        this.chRef.detectChanges();
        const table: any = $('#TableEntreprise');
        this.hideloader()
        this.dataTableCompany = table.DataTable({
          stateSave: true,
          language: {
            "sProcessing": "Traitement en cours...",
            "searchPlaceholder": " Recherche...",
            "sSearch": '<p aria-hidden="true"></p>',
            "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
            "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
            "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
            "oPaginate": {
              "sFirst": "Premier",
              "sPrevious": '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              "sNext": '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
              "sLast": "Dernier"
            },
            "oAria": {
              "sSortAscending": ": activer pour trier la colonne par ordre croissant",
              "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
            },
            "select": {
              "rows": {
                _: "%d lignes séléctionnées",
                0: "Aucune ligne séléctionnée",
                1: "1 ligne séléctionnée"
              }
            }
          }
        });
      })
  }
  hideloader() {
    document.getElementById('loadingBar')
      .style.display = 'none';
  }
  showloader() {
    document.getElementById('loadingBar')
      .style.display = 'block';
  }
}
