import { Bike } from './bike';
import { Client } from './client';
import { References } from './references';

export class TarificateurVelo {
  references: References;
  bike: Bike;
  client: Client;
  CommissionRate: string;
  CommercialLatitude: string;
  Excess: string;
  isAssistance: boolean;
}
