import { DatePipe } from '@angular/common';
import { Validators, FormControl } from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Compagnie } from 'src/app/entity/Compagnie';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { ContratActuel } from 'src/app/entity/Affaires/FilterAffaires';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
@Component({
  selector: 'app-filtre-info-contrat-actuel',
  templateUrl: './filtre-info-contrat-actuel.component.html',
  styleUrls: ['./filtre-info-contrat-actuel.component.css'],
})
export class FiltreInfoContratActuelComponent implements OnInit, OnChanges {
  assure = false;
  compagnieSelected: any[] = [];
  fa = fa;
  date_ech: string = '';

  // Mat Chips Compagnie
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  compagnieFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCompagnie: Observable<Compagnie[]>;
  ListCompagnies: string[] = [];
  allCompagnie: Compagnie[] = [];

  @ViewChild('compagnieInput') compagnieInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCompagnies') matAutocompleteCompagniess: MatAutocomplete;

  filterContratActuel: ContratActuel = new ContratActuel();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterContratActuel: EventEmitter<ContratActuel> = new EventEmitter();
  noValue: boolean;
  constructor(
    private apiCampagniesService: ApiCampagniesService,
    private chRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  // Compagnie -----------------------------------------------------------------------------------------------
  selectedCompagnie(event: MatAutocompleteSelectedEvent): void {
    this.ListCompagnies.push(event.option.viewValue);
    this.compagnieSelected.push(event.option.value);
    this.compagnieInput.nativeElement.value = '';
    this.compagnieFromCtrl.setValue(null);
  }

  removeCompagnie(compagnie: string): void {
    const index = this.ListCompagnies.indexOf(compagnie);
    if (index >= 0) {
      this.ListCompagnies.splice(index, 1);
      this.compagnieSelected.splice(index, 1);
    }
  }

  private _filterCompagnie(): Compagnie[] {
    let filterValue = this.compagnieFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCompagnie.filter((compagnie) =>
      compagnie.denomination != null
        ? compagnie.denomination.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
        : null
    );
  }

  ngOnInit(): void {
    this.getCompagnie();
    this.filteredCompagnie = this.compagnieFromCtrl.valueChanges.pipe(
      startWith(null),
      map((compagnie: string | null) => (compagnie ? this._filterCompagnie() : this.allCompagnie.slice()))
    );
  }

  typeContrat(type) {
    if (this.assure === false) {
      this.assure = true;
      this.filterContratActuel.deja_assure = type;
    } else this.assure = false;
    this.filterContratActuel.deja_assure = type;
  }

  getCompagnie() {
    this.apiCampagniesService.getListCompagnies().subscribe((data: Compagnie[]) => {
      this.allCompagnie = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterContratActuelForm(changes.Reset.currentValue);
  }

  resetfilterContratActuelForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListCompagnies = [];
      this.filterContratActuel.an_cmp = this.compagnieSelected = [];
      this.filterContratActuel.deja_assure = '';
      this.filterContratActuel.operateur_pan_ttc = '';
      this.filterContratActuel.PAN_TTC = '';
      this.filterContratActuel.date_ech = '';
      this.date_ech = '';

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterContratActuel.emit(this.filterContratActuel);
    }
  }

  EmitFilterfilterContratActuel() {
    this.filterContratActuel.date_ech = this.datePipe.transform(this.date_ech, 'yyyy-MM-dd');
    this.filterContratActuel.an_cmp = this.compagnieSelected;
    this.onPushFilterContratActuel.emit(this.filterContratActuel);
  }

  getValue(event: any) {
    if (event) {
      this.noValue = true;
    }
  }
}
