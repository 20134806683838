<mat-expansion-panel hideToggle style="margin-top: 25px">
  <mat-expansion-panel-header>
    <mat-panel-title>
    </mat-panel-title>
    <mat-panel-description>
      Code
    </mat-panel-description>
  </mat-expansion-panel-header>
  <pre>
  <code class="zone-code" [highlight]="code"></code>
  </pre>
</mat-expansion-panel>
<hr>
