<div class="row" style="margin-top: -16px;">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="picker">
                    <input [(ngModel)]="date_creation" matStartDate placeholder="Date création du" />
                    <input [(ngModel)]="date_fin" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterOpportunityAffaire()" #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-date-range-input [rangePicker]="Dates">
                    <input [(ngModel)]="datesDu" matStartDate placeholder="Date du" />
                    <input [(ngModel)]="datesAu" matEndDate placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="Dates"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterOpportunityAffaire()" #Dates></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group" style="padding: 0;">
            <ng-container *ngIf="allCampagne?.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Campagne</mat-label>
                    <mat-chip-list #Campagne>
                        <mat-chip *ngFor="let Campagne of ListCampagne" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCampagne(Campagne)"
                            (removed)="EmitFilterOpportunityAffaire()">
                            {{Campagne}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterOpportunityAffaire()" placeholder="Selectionner Campagne..."
                            #CampagneInput [formControl]="CampagnesFromCtrl" [matAutocomplete]="autoCampagne"
                            [matChipInputFor]="Campagne" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCampagne="matAutocomplete" (optionSelected)="selectedCampagne($event)">
                        <mat-option *ngFor="let Campagne of filteredCampagne | async" [value]="Campagne.id">
                            {{Campagne.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allCycleDevie?.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Cycle de vie</mat-label>
                    <mat-chip-list #CycleDevie>
                        <mat-chip *ngFor="let CycleDevie of ListCycleDevie" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCycleDevie(CycleDevie)"
                            (removed)="EmitFilterOpportunityAffaire()">
                            {{CycleDevie}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterOpportunityAffaire()" placeholder="Selectionner Cycle de vie..."
                            #CycleDevieInput [formControl]="cycleDevieFromCtrl" [matAutocomplete]="autoCycleDevie"
                            [matChipInputFor]="CycleDevie" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCycleDevie="matAutocomplete" (optionSelected)="selectedCycleDevie($event)">
                        <mat-option *ngFor="let CycleDevie of filteredcycleDevie | async" [value]="CycleDevie.id">
                            {{CycleDevie.libele}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="form-group">
            <ng-container *ngIf="allTags?.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Mots-clés</mat-label>
                    <mat-chip-list #Tags>
                        <mat-chip *ngFor="let Tags of ListTags" [selectable]="selectable" [removable]="removable"
                            (removed)="removeTags(Tags)" (removed)="EmitFilterOpportunityAffaire()">
                            {{Tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterOpportunityAffaire()" placeholder="Selectionner Tags..." #tagsInput
                            [formControl]="tagsFromCtrl" [matAutocomplete]="autoTags" [matChipInputFor]="Tags"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTags="matAutocomplete" (optionSelected)="selectedTags($event)">
                        <mat-option *ngFor="let Tags of filteredTags | async" [value]="Tags.id">
                            {{Tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
