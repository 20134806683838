import { Component, Input, OnInit } from '@angular/core';
import { ApiNotificationService } from '../../services/ApiNotification/api-notification.service';
import { SharedMenuObserverService } from '../../services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiPaymentService } from '../../services/ApiPayment/api-payment.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationDate, Notifications } from 'src/app/entity/Generic/Notifications';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { UserAdmin } from 'src/app/entity/User/UserAdmin';
import { ApiLedgerService } from 'src/app/services/ApiLedger/api-ledger.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserAdmin2 } from 'src/app/entity/User/UserAdmin2';
import { WebmailService } from 'src/app/services/ApiWebmail/webmail.service';
import { Mail } from 'src/app/entity/mail';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { tap } from 'rxjs/operators';
import swal from 'sweetalert2';

moment.locale('fr');
export declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  menu_id: string;
  child: RouteInfo[];
  isNotActiveMenu: boolean;
}

export const ROUTES: RouteInfo[] = [];
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  fa = fa;
  lu: boolean = false;
  languages: string[] = ['EN', 'FR'];
  selected_lang = '';
  @Input() collapse = false;
  user: UserAdmin2 = new UserAdmin2();
  payment_facture_url: SafeUrl;
  deposit_value: number = 0;
  bundleList: number[] = [5, 10, 50, 100, 200, 500];
  other_bundle: boolean = false;

  notification: Notifications[] = [];
  notificationDate: NotificationDate = new NotificationDate();
  mesg: Mail[] = [];
  AllMesg: Mail[] = [];
  routes: RouteInfo[] = [];
  position: string;
  prepay: boolean = false;
  solde: number;
  // a
  now: any;
  configue: boolean = false;
  currentIcon: string = '';
  constructor(
    private apiNotificationService: ApiNotificationService,
    public sanitizer: DomSanitizer,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiPaymentService: ApiPaymentService,
    private apiUsersService: ApiUsersService,
    private apiSolde: ApiLedgerService,
    private translate: TranslateService,
    private datepipe: DatePipe,
    private router: Router,
    private apiWebmail: WebmailService
  ) {
    this.routes = ROUTES;
  }

  ngOnInit(): void {
    this.translate.setDefaultLang('fr');
    this.selected_lang = 'fr';
    this.sharedMenuObserverService.onUpdateMenu().subscribe((data) => {
      this.position = data;
    });
    this.sharedMenuObserverService.onUpdateIconMenu().subscribe((data) => {
      if (data == null) this.currentIcon = 'faChartLine';
      else this.currentIcon = data;
    });

    /*     this.apiWebmail.isConfigured()
    .pipe(
      tap((result) => {
        let configured = result.is_configured
        if (configured) {



        } else {

          this.alertError("Il faut configurer votre compte email")
        }
      })
    ).subscribe(); */
    this.getListEmail();
    this.getDateNotification();
    this.getUserConnected();
    this.getsolde();

    this.apiWebmail.getListFolfder().subscribe((data) => {
      this.configue = data.is_configured;
    });
  }

  getDateNotification() {
    this.apiNotificationService.GetDateNotifications().subscribe((data: NotificationDate) => {
      this.notificationDate = data;
      this.getNotificationList(this.notificationDate.date, this.notificationDate.time, this.notificationDate.week);
    });
  }

  getNotificationList(date, heure, week) {
    this.apiNotificationService.getNotificationGet(date, heure, week).subscribe((data: Notifications[]) => {
      this.notification = data;
      this.notification.forEach(
        (notif) => (notif.date_notification = this.datepipe.transform(notif.date_notification, 'yyyy-MM-dd'))
      );
    });
  }

  createFacture() {
    this.apiPaymentService.getFacture(this.deposit_value * 100).subscribe((data) => {
      this.payment_facture_url = this.sanitizer.bypassSecurityTrustResourceUrl(data.payment_url);
    });
  }

  readen(value) {
    this.lu = value;
    $('#dropdownMailNotification').stop();
    event.stopImmediatePropagation();
  }

  getUserConnected() {
    this.apiUsersService.getUserWithIdGet().subscribe((data: UserAdmin2) => {
      this.user = data;
    });
  }

  alertError(data) {
    swal
      .fire({
        title: 'Pas de configuration Webmail!',
        text: data,
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        confirmButtonText: 'Configurer',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.apiWebmail.tab = 3;
          this.router.navigate(['/profil']);
        }
      });
  }

  setDepositValue(number: number) {
    this.deposit_value = number;
  }

  clearPaymentFrame() {
    this.payment_facture_url = null;
  }

  expandName(name: string): string {
    name = name?.trim();
    if (name?.length < 15) {
      return name;
    } else {
      return name?.substring(0, 14) + '...';
    }
  }

  expandNameNofification(name: string): string {
    name = name?.trim();
    if (name.length < 35) {
      return name;
    } else {
      return name?.substring(0, 34) + '...';
    }
  }

  expandNameEnp(name: string): string {
    name = name?.trim();
    if (name.length < 10) {
      return name;
    } else {
      return name?.substring(0, 9) + '...';
    }
  }

  getsolde() {
    this.apiSolde.getSolde().subscribe((data) => {
      this.prepay = data.pre_pay;
      this.solde = data.balance;
    });
  }

  setLanguage(event) {
    this.selected_lang = event.toLowerCase();
    this.translate.use(event.toLowerCase());
  }

  logOut() {
    localStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  expandNameUser(name: string): string {
    name = name?.trim();
    if (name.length < 9) {
      return name;
    } else {
      return name?.substring(0, 0) + ' ';
    }
  }

  getListEmail() {
    this.apiWebmail.getListEmail().subscribe((data) => {
      if (data?.error) {
        console.log('');
      } else {
        this.AllMesg = data[0];
        this.AllMesg?.forEach((element) => {
          element.time = moment(element.time, 'YYYYMMDD').fromNow();
        });
        this.mesg = this.AllMesg?.filter((element) => element.read === false);
      }
    });
  }

  redirectioWeB(id) {
    this.router.navigate([`/webmail/`]);
  }
}
