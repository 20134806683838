<h2>Outils</h2>
<div class="row card-communication bg-white pb-3 pt-3">
    <div class="col text-center">
        <button class="style-btn-bubble" [disabled]="!accees" data-toggle="modal" data-target="#ModelEmail" (click)="getListModelCommunication('M')">
      <div class="d-flex flex-column">
        <div class="logo-bubble mt-4">
          <img class="position-bubble background-bubble-Email" alt="" />
        </div>
        <div class="text-center">
          <p>Email</p>
        </div>
      </div>
    </button>
    </div>

    <div class="col text-center" *ngIf="fromtype == 'O'">
        <button class="style-btn-bubble" [disabled]="!accees" data-toggle="modal" data-target="#ModelVisio" (click)="goToPlanification()">
      <div class="d-flex flex-column">
        <div class="logo-bubble mt-4">
          <img class="position-bubble background-bubble-Visio" alt="" />
        </div>
        <div class="text-center">
          <p>Visio</p>
        </div>
      </div>
    </button>
    </div>
    <div class="col text-center">
        <button class="style-btn-bubble" [disabled]="!accees" data-toggle="modal" data-target="#ModelCourrier" (click)="getListModelCommunication('C')">
      <div class="d-flex flex-column">
        <div class="logo-bubble mt-4">
          <img class="position-bubble background-bubble-Courrier" alt="" />
        </div>
        <div class="text-center">
          <p>Courrier</p>
        </div>
      </div>
    </button>
    </div>
    <div class="col text-center">
        <button class="style-btn-bubble" [disabled]="!accees" data-toggle="modal" data-target="#ModelSMS" (click)="getListModelCommunication('S')">
      <div class="d-flex flex-column">
        <div class="logo-bubble mt-4">
          <img class="position-bubble background-bubble-SMS" alt="" />
        </div>
        <div class="text-center">
          <p>SMS</p>
        </div>
      </div>
    </button>
    </div>
</div>
<!-----------------------------Email Model------------------------------------>
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelEmail" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title style-modal">Nouveau Email</h5>
                <button type="button" class="close" (click)="resetForm()" #closebuttonMail data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <ng-container>
                <div class="modal-body">
                    <h3>Sélectionner un modèle:</h3>
                    <ng-container *ngIf="show_loading">
                        <div class="row mb-3 mt-3">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row" *ngIf="!show_loading">
                        <div class="col">
                            <ul>
                                <li class="nav-item dropdown" *ngFor="let model of communicationModel; let i = index">
                                    <ng-container *ngIf="model.childs.length > 0">
                                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle btn-style dropbtn">
                      {{ expandFileName(model.nom) }}
                    </a>
                                        <ng-container *ngTemplateOutlet="dropdownList; context: { menu: model }"></ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="model.childs.length < 1">
                                        <ng-container *ngTemplateOutlet="defaultBtnModel; context: { menu: model }"></ng-container>
                                    </ng-container>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p *ngIf="communicationModel.length < 1 && !show_loading" class="small font-weight-bold text-center">
                        Pas de modèle disponible
                    </p>
                    <div *ngIf="libelle_categorie !== ''">
                        <label> Modèle : {{ libelle_categorie }}</label>
                    </div>

                    <form [formGroup]="formModel" (ngSubmit)="SendEmail()">
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>Vers</mat-label>
                                <input type="text " matInput formControlName="mail_destinataire" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="mail_destinataire" required />
                            </mat-form-field>
                            <div *ngIf="
                  !formModel.get('mail_destinataire').valid &&
                  (formModel.get('mail_destinataire').dirty || formModel.get('mail_destinataire').touched)
                ">
                                <div *ngIf="formModel.get('mail_destinataire').hasError('required')" class="message-erreur">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Email est obligatoire
                                </div>
                                <div *ngIf="formModel.get('mail_destinataire').hasError('pattern')" class="message-erreur">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Format email est invalide (exemple: email@email.com)
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="w-100" appearance="standard">
                                <mat-label>Sujet</mat-label>
                                <input type="text " matInput formControlName="sujet_mail" name="sujet_mail" required />
                            </mat-form-field>
                            <div *ngIf="
                  !formModel.get('sujet_mail').valid &&
                  (formModel.get('sujet_mail').dirty || formModel.get('sujet_mail').touched)
                " class="message-erreur">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Le sujet est obligatoire!
                            </div>
                        </div>

                        <mat-tab-group (selectedTabChange)="preview()" mat-align-tabs="center" style="z-index: 0">
                            <mat-tab label="Écrire ">
                                <ckeditor [config]="config" class="w-100 mt-3" name="body_content_mail " data="{{ contentBody }}" (change)="getValueFromEditor($event)" formControlName="body_content_mail" [editor]="Editor">
                                </ckeditor>
                            </mat-tab>
                            <mat-tab label="Aperçu ">
                                <ng-container *ngIf="show_loading_prev">
                                    <div class="col-12">
                                        <div class="spinner-style">
                                            <mat-spinner [diameter]="30"></mat-spinner>
                                        </div>
                                    </div>
                                </ng-container>
                                <ckeditor [config]="config" class="w-100 mt-3" name="preview" formControlName="preview" [editor]="Editor" [disabled]="true">
                                </ckeditor>
                            </mat-tab>
                        </mat-tab-group>

                        <div class="form-group text-right">
                            <ng-container *ngIf="ListFiles.length > 0">
                                <div class="row">
                                    <div class="col-2 file-selected-style" *ngFor="let file of ListFiles; let index = index">
                                        <div class="row">
                                            <div class="col-2 delete-btn" (click)="dropFile(index)">
                                                <fa-icon [icon]="fa['faTrash']"></fa-icon>
                                            </div>
                                            <div class="col-10">
                                                {{ expandFileName(file.name) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="modal-footer">
                            <div class="row w-100">
                                <div class="col-6">
                                    <ng-container *ngIf="show_loading_add">
                                        <div class="col-12 d-flex justify-content-end">
                                            <div class="spinner-style">
                                                <mat-spinner [diameter]="30"></mat-spinner>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn-dt-cancel-popup" type="button" (click)="resetForm()" #closebutton data-dismiss="modal">
                    <fa-icon class="fa-lg" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp; Annuler
                  </button>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn-dt-save-popup" type="submit">
                    <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp; Enregistrer
                  </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-----------------------------Courrier Model------------------------------------>
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelCourrier" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" style="left: 75px">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title style-modal">Nouveau Courrier</h5>
                <button type="button" class="close" #closeModal (click)="resetForm()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body p-0">
                <app-add-maileva *ngIf="prospect?.mainProspect?.id" [id_prospect]="prospect?.mainProspect?.id" [id_opportunite]="id_opportunite" [affaire_id]="affaire_id" [fromDetails]="fromDetails" [fromtype]="fromtype"></app-add-maileva>
            </div>
        </div>
    </div>
</div>

<!-----------------------------SMS Model------------------------------------>
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelSMS" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title style-modal">Nouveau SMS</h5>
                <button type="button" class="close" (click)="resetForm()" #closebuttonSMS data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <form [formGroup]="formModel" (ngSubmit)="sendSMS()">
                <div class="modal-body">
                    <h3>Sélectionner un modèle:</h3>
                    <ng-container *ngIf="show_loading">
                        <div class="row mb-3 mt-3">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row" *ngIf="!show_loading">
                        <div class="col">
                            <ul>
                                <li class="nav-item dropdown" *ngFor="let model of communicationModel; let i = index">
                                    <ng-container *ngIf="model.childs.length > 0">
                                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle btn-style dropbtn">
                      {{ expandFileName(model.nom) }}
                    </a>
                                        <ng-container *ngTemplateOutlet="dropdownList; context: { menu: model }"></ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="model.childs.length < 1">
                                        <ng-container *ngTemplateOutlet="defaultBtnModel; context: { menu: model }"></ng-container>
                                    </ng-container>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <p *ngIf="communicationModel.length < 1 && !show_loading" class="small font-weight-bold text-center">
                        Pas de modèle disponible
                    </p>
                    <div *ngIf="libelle_categorie !== ''">
                        <label> Modèle : {{ libelle_categorie }}</label>
                    </div>

                    <h4 class="col-form-label">Paramètres SMS:</h4>

                    <div class="form-group">
                        <mat-form-field class="w-100" appearance="{{ appearance }}" floatLabel="always">
                            <mat-label class="ng2tel-field-label">Téléphone</mat-label>
                            <input (keypress)="numberOnly($event)" ng2TelInput matInput name="telephone_destinataire" class="full_width" maxlength="15" min="2" formControlName="telephone_destinataire" [ng2TelInputOptions]="{ initialCountry: telCountry }" (hasError)="hasError($event)"
                            />
                            <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                        </mat-form-field>
                        <div *ngIf="
                !formModel.get('telephone_destinataire').valid &&
                (formModel.get('telephone_destinataire').dirty || formModel.get('telephone_destinataire').touched)
              ">
                            <div *ngIf="formModel.get('telephone_destinataire').hasError('required')" class="message-erreur">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Téléphone est obligatoire !
                            </div>
                            <div *ngIf="formModel.get('telephone_destinataire').hasError('pattern')" class="message-erreur">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Merci de saisir un numéro de téléphone valide
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="w-100" appearance="standard">
                            <mat-label>Sujet</mat-label>
                            <input type="text" matInput formControlName="sujet_sms" name="sujet_sms" required />
                        </mat-form-field>
                        <div *ngIf="
                !formModel.get('sujet_sms').valid &&
                (formModel.get('sujet_sms').dirty || formModel.get('sujet_sms').touched)
              " class="message-erreur">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le sujet est obligatoire!
                        </div>
                    </div>
                    <mat-tab-group (selectedTabChange)="preview()" mat-align-tabs="center" style="z-index: 0">
                        <mat-tab label="Écrire">
                            <ckeditor [config]="config" class="w-100 mt-3" name="body_content_sms" formControlName="body_content_sms" data="{{ contentBody }}" (change)="getValueFromEditor($event)" [editor]="Editor"></ckeditor>
                        </mat-tab>
                        <mat-tab label="Aperçu">
                            <ng-container *ngIf="show_loading_prev">
                                <div class="col-12">
                                    <div class="spinner-style">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                    </div>
                                </div>
                            </ng-container>
                            <ckeditor [config]="config" class="w-100 mt-3" name="preview" formControlName="preview" [editor]="Editor" [disabled]="true">
                            </ckeditor>
                        </mat-tab>
                    </mat-tab-group>
                    <div class="form-group"></div>
                </div>
                <div class="modal-footer">
                    <div class="row w-100">
                        <div class="col-6">
                            <ng-container *ngIf="show_loading_add">
                                <div class="d-flex justify-content-end">
                                    <div class="spinner-style">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-3 text-right">
                            <button class="btn-dt-cancel-popup" type="button" (click)="resetForm()" #closebutton data-dismiss="modal">
                <fa-icon class="fa-lg" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp; Annuler
              </button>
                        </div>
                        <div class="col-3 text-right">
                            <button class="btn-dt-save-popup" type="submit">
                <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp; Enregistrer
              </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-----------------------------Ajouter Model------------------------------------>
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelAjouter" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            ...
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                <button type="button" class="btn btn-primary">Envoyer</button>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="col-12 text-center">
        <ng-container *ngIf="show_loading_add">
            <div class="d-flex justify-content-end">
                <div class="spinner-style">
                    <mat-spinner [diameter]="50"></mat-spinner>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #dropdownList let-menuList="menu">
    <ul class="dropdown-menu border-0 shadow">
        <ng-container *ngFor="let menu of menuList.childs">
            <ng-container *ngIf="menu.childs.length > 0">
                <ng-container *ngTemplateOutlet="subMenu; context: { menu: menu }"></ng-container>
            </ng-container>
            <ng-container *ngIf="menu.childs.length < 1">
                <ng-container *ngTemplateOutlet="subElement; context: { menu: menu }"></ng-container>
            </ng-container>
        </ng-container>
    </ul>
</ng-template>

<ng-template #subElement let-element="menu">
    <li>
        <a (click)="
        closeAllDropDownElements($event);
        getInfoModel(element);
        setComValue(element.id);
        setCategorieValue(element.id, element.nom)
      " class="dropdown-item">{{ element.nom }}
    </a>
    </li>
</ng-template>

<ng-template #subMenu let-element="menu">
    <li class="dropdown-submenu">
        <a (click)="showSubMenu($event)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-item dropdown-toggle">{{ element.nom }}</a
    >
    <ng-container *ngTemplateOutlet="dropdownList; context: { menu: element }"></ng-container>
  </li>
</ng-template>
<ng-template #defaultBtnModel let-model="menu">
  <a
    (click)="getInfoModel(model); setCategorieValue(model.id, model.nom)"
    aria-haspopup="true"
    aria-expanded="false"
    class="nav-link btn-style dropbtn"
  >
    {{ expandFileName(model.nom) }}
  </a>
</ng-template>
