<div class="flex-column bg-white rounded-card">
    <div class="header-card-gestion py-3">
     
    </div>
    <div class="col-4 p-2 mt-2 text-right float-right" >
      <input class="search" name=""
      [(ngModel)]="FilterProspectClient.sSearch"
      (keypress)="getDataValue($event)"
        placeholder="Recherche...">
      <fa-icon aria-hidden="true" class="icon-search fa-md" [icon]="fa['faSearch']"></fa-icon>
    </div>
    <div class="p-3 mb-3" >
      <table id="Table" class="table w-100" >
        <thead class="head-table">
          <tr>
           
            <th> Nom</th>
            <th> Prénom</th>
            <th> Email</th>
          <th> Num téléphone</th>
          <th> Activités</th>
          <th> Détails</th>
          </tr>
        </thead>
        <tbody class="body-table">
   
          <tr *ngFor="let item of listProspectClient; let index = index">
        
            <td *ngIf="item.surname !== null">
                 {{item.surname}}
             </td>
             <td *ngIf="item.surname == null">
              -
             </td>
             <td *ngIf="item.name !== null">
              {{item.name}}
               </td>
               <td *ngIf="item.name == null">
                -
               </td>
               <td *ngIf="item.adresse_mail !== null ">
                {{item.adresse_mail}}
               </td >
               <td *ngIf="item.adresse_mail == null">
                -
               </td>
               <td *ngIf="item.mobile !== null">
                {{item.mobile}}
               </td>
               <td *ngIf="item.mobile == null">
                -
               </td>
               <td class=" d-flex justify-content-center">
                <div class="w-100 text-center" >
                
                  <div class="btn-status my-2  Opportunite"  >

                  <fa-icon class="pr-2" [icon]="fa['faBullhorn']"></fa-icon>
                

                  <span  > {{item.nombre_opportunite}} Opportunité(s)</span></div>
                 
               
                
                  <div class="btn-status my-2 affaire" >
                    <fa-icon class="pr-2" [icon]="fa['faRoad']"></fa-icon><span  >{{item.nombre_affaire}} Affaire(s)</span></div>
                
                   <div class="btn-status my-2 ticket" >
                    <fa-icon class="pr-2" [icon]="fa['faMoneyCheck'] " ></fa-icon><span >{{item.nombre_ticket}} Ticket(s)</span></div></div>
               </td>
            <td>
              <button type="button" class="btn-dt-list" routerLink="/fiche-prospect/ProspectCLient/{{item.id}}/ProspectCLient">
              <img src="/assets/icons/edit-icon.svg" >
            </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="navbar-center mb-4 row">
      <div class="col pt-1">
        <div class="row w-50 flex-nowrap" >
          <div  class="col mt-1">
            <p>{{'label.show' | translate}}</p>
          </div>
          <div  class="col mx-1">
            <select [(ngModel)]="limit" (change)="updateListProspect()" 
            class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col mt-1">
            <p>{{'label.entries' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-8">
        <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
          <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
              <a class="page-link-symbol">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2" >
              <a class="page-link-symbol-chevron" aria-label="Previous">
                <i class="fa fa-chevron-left "></i>
              </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages-3>0" class="page-item">
                <a (click)="changepage(i+pages-3)" class="page-link mt-2 border rounded">{{i + pages - 3}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages<totalPages" class="page-item">
                <a (click)="changepage(i+pages)" class="page-link mt-2 border rounded" 
                [ngClass]="{'active-page':pages==(i+pages)}">
                {{i + pages}}
              </a>
              </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
              <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':pages==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
              <a class="page-link-symbol-chevron" aria-label="Next">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
              <a class="page-link-symbol">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          </ul>
        </nav>
    </div>
      <div class="col">
        <div class="row position-absolute flex-nowrap pagination">
          <div class="col text-nowrap text-right mt-2">
            <p >{{'label.goto' | translate}}</p>
          </div>
          <div class="col mt-1 ml-2">
            <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number" 
            size="1" class="page_input bg-white border rounded font-weight-bold ml-1" [value]="pages" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
  </div>
  
