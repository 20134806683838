import { environment } from 'src/environments/environment';
import { BankInformation } from './../../../../../../entity/BankInformation';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Prospect } from '../../../../../../entity/Prospect';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { ApiProspectsService } from '../../../../../../services/ApiProspects/api-prospects.service';
import { AutoFillEntity } from '../../../../../../entity/Opportunity/AutoFillEntity';
import { Country } from '@angular-material-extensions/select-country';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_FORMATS } from '././../../../../../../shared/Utils/dateAdapter';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FR as France } from '../prospect-pro-form/json-dict/fr';
import { TN as Tunis } from '../prospect-pro-form/json-dict/tn';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
class formbuilder {
  data;
  schema;
}
@Component({
  selector: 'app-prospect-form',
  templateUrl: './prospect-form.component.html',
  styleUrls: ['./prospect-form.component.css'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ProspectFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  visible = true;
  selectable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  longitude: number;
  latitude: number;
  isfilled = false;
  loaderinfo = true;
  dateNow: Date = new Date();
  // -------------------------------------------------------------------------------------------------
  fa = fa;
  isLoadingTitre: boolean = true;
  AdresseSaisieAuto: string = '';
  AdresseComplement: string = '';
  Civilite = [
    { id: 'M.', nom: 'M.' },
    { id: 'Mme.', nom: 'Mme.' },
  ];

  SituationFamiliale = [{ id: 'Marie', nom: 'Marie(é)' }];

  isLoadingFormeJuridique: boolean = true;
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'nom',
    selectAllText: 'Selectionner tout',
    unSelectAllText: 'Déselectionner tout',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  listUnsubscribe: Subscription[] = [];
  @Input() typeEntity? = '';
  @Input() index: string = '';
  @Input() prospect: Prospect = new Prospect();
  prospectClone: Prospect = new Prospect();
  @Input() type: number = 0;
  @Input() isMainProspect: boolean = true;
  @Input() prospectForm: FormGroup;
  @Input() modeupdateProspect?: string;
  @Input() isRequired: boolean;
  @Output() ProspectExport: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateProspect: EventEmitter<Prospect> = new EventEmitter();
  @Output() onSelectToFillOldProspect: EventEmitter<string> = new EventEmitter();
  @Input() ticket;
  @Input() formProspectChild;
  @Input() formMainProspect;
  model: any = {};
  model_copy: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  control = new FormControl();
  telCountry = environment.country;
  countryLanguage: any;
  form: any;
  data: any = {};
  keyFormProspect: any;
  prospectFormnew: formbuilder = new formbuilder();
  public href: string = '';
  loadingFormBuilder: boolean = false;
  @Input() allowed_to_update: boolean;
  @Input() schemaValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private apiProspectsService: ApiProspectsService,
    private formlyJsonschema: FormlyJsonschema,
    private apiConfigurationService: ApiConfigurationService
  ) {}

  ngAfterViewInit(): void {}

  ngAfterContentInit(): void {}

  ngOnDestroy(): void {
    this.listUnsubscribe.forEach((element) => {
      element.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.prospect?.currentValue) {
      this.prospectClone = this.prospect;

      if (!this.prospectClone.form || Object.keys(this.prospectClone.form.schema).length === 0) {
        this.loaderinfo = false;
      }
      if (Object.keys(changes?.prospect?.currentValue.form.data).length > 0) {
        this.model = changes?.prospect?.currentValue.form.data;
      }

      this.initFormProspect();
    }
    if (changes?.schemaValue?.currentValue?.schema) {
      this.prospectFormnew = this.schemaValue = changes?.schemaValue?.currentValue;
      this.buildForm();

      // if (this.fields[0].fieldGroup.length > 0) {
      //   this.isfilled = true;
      //   this.loaderinfo = false;
      // } else {
      //   this.loaderinfo = false;
      // }
    }
  }

  ngOnInit(): void {
    this.keyFormProspect = environment.keyFormProspect;
    this.formGroup = this.formBuilder.group({
      nomprenom: new FormControl('', Validators.minLength(3)),
      telephone: new FormControl('', Validators.minLength(3)),
      selectNomPrenom: new FormControl(),
      selectProspect: new FormControl(),
      prospect_id: new FormControl(),
    });

    if (this.telCountry === 'FR') {
      this.countryLanguage = France.data;
      this.defaultCountry = {
        name: 'France',
        alpha2Code: 'FR',
        alpha3Code: 'FRA',
        numericCode: '250',
        callingCode: '+33',
      };
    } else if (this.telCountry === 'TN') {
      this.countryLanguage = Tunis.data;
      this.defaultCountry = {
        name: 'Tunisie',
        alpha2Code: 'TN',
        alpha3Code: 'TN',
        numericCode: '',
        callingCode: '+216',
      };
    }
    if (this.typeEntity !== 'o') {
      this.apiProspectsService.AllowToupdate$.subscribe((value) => {
        this.allowed_to_update = value;
      });
    }
    if (this.allowed_to_update === undefined) {
      this.allowed_to_update = true;
    }
  }

  buildForm(): void {
    this.prospectFormnew = this.schemaValue;
    this.form = new FormGroup({});
    if (this.prospectFormnew != null) {
      this.options = {};
      if (this.prospectFormnew?.schema) {
        const data = this.prospectFormnew;
        const formPropreties: any = data?.schema?.properties;

        if (formPropreties?.length > 0) {
          this.isfilled = true;
          this.loaderinfo = false;
          data.schema.properties = {};
          formPropreties.forEach((element) => {
            data.schema.properties[element.name] = element;
            if (this.model[element.name] && Object.values(this.model).length === 0)
              this.model[element.name] = element.default;
          });
        } else if (Object.keys(formPropreties).length) {
          this.isfilled = true;
          this.loaderinfo = false;
        } else {
          this.loaderinfo = false;
        }

        if (data?.data)
          for (const [key, value] of Object.entries(data?.data)) {
            if (value != null) this.model[key] = value;
          }
        if (data.schema.properties)
          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(data.schema)))];
      }
    }
  }

  reset() {
    this.prospectClone = new Prospect();
    this.initFormProspect();
    this.updateProspect();
  }

  /* Convert Date from
  yyyy-MM-dd TO
  MM/dd/yyyy
  */
  formatDate(date: string) {
    if (date === undefined || date == null) {
      return null;
    }
    const DN = date.split('-');
    const date1 = DN.join('/');
    return date1;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  initFormProspect() {
    this.prospectForm = new FormGroup({
      id: new FormControl(this.prospectClone.id),
      prospects_id: new FormControl(this.prospectClone.prospects_id),
      post: new FormControl(this.prospectClone.post),
      ville: new FormControl(this.prospectClone.ville),
      situation: new FormControl(this.prospectClone.situation),
      adresse_mail: new FormControl(this.prospectClone.adresse_mail, [
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      civilite: new FormControl(this.prospectClone.civilite),
      name: new FormControl(this.prospectClone.name, [Validators.required]),
      surname: new FormControl(this.prospectClone.surname, [Validators.required]),
      DN: new FormControl(this.prospectClone.DN ? new Date(this.formatDate(this.prospectClone.DN)) : null),
      nb_enfants: new FormControl(this.prospectClone.nb_enfants),
      streetNumber: new FormControl(this.prospectClone.streetNumber),
      longitude: new FormControl(this.prospectClone.longitude),
      latitude: new FormControl(this.prospectClone.latitude),
      CP: new FormControl(this.prospectClone.CP),
      streetName: new FormControl(this.prospectClone.streetName),
      /*      securite_sociale: new FormControl(this.prospectClone.securite_sociale, [
             Validators.pattern(this.countryLanguage?.PROSPECT.SOCIAL_SECURITY.REGEX || ''),
           ]), */
      complement_adr: new FormControl(this.prospectClone.complement_adr),
      tel: new FormControl(this.prospectClone.tel),
      tel2: new FormControl(this.prospectClone.tel2),
      mobile: new FormControl(this.prospectClone.mobile),
      description: new FormControl(this.prospectClone.description),
      relation: new FormControl(this.prospectClone.relation),
    });

    if (this.allowed_to_update === false) {
      this.prospectForm.disable();
    }
  }

  onChangeAdresseComplement(valeur: string) {
    this.AdresseComplement = valeur;
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.prospectForm.controls.CP.setValue(adress.postal_code);
    this.prospectForm.controls.streetName.setValue(adress.route);
    this.prospectForm.controls.streetNumber.setValue(adress.street_number);
    this.prospectForm.controls.ville.setValue(adress.locality);
    this.prospectForm.controls.longitude.setValue(adress.longitude);
    this.prospectForm.controls.latitude.setValue(adress.latitude);
    this.updateProspect();
  }

  updateProspect() {
    this.prospectClone = new Prospect(this.prospectForm.value);
    if (this.model != null && Object.keys(this.model).length > 0) {
      const jsonnn = JSON.parse(JSON.stringify(this.form.value));
      const objCopy = { ...this.prospect.form };
      objCopy.data = jsonnn;
      this.prospectClone.form = objCopy;
    }

    if (this.prospectClone.DN) this.prospectClone.DN = this.datePipe.transform(this.prospectClone.DN, 'yyyy-MM-dd');
    /* Toggle between interlocuteur and Main prospect */
    if (this.type === 1) {
      this.prospectClone.relation = null;
      if (this.isMainProspect) {
        this.prospectClone.interlocuteur = true;
      } else {
        this.prospectClone.interlocuteur = false;
      }
    } else {
      this.prospectClone.interlocuteur = null;
      if (this.isMainProspect) {
        this.prospectClone.relation = 'principal';
      }
    }
    this.prospectClone.invalidForms = this.findInvalidControls();

    this.onUpdateProspect.emit(this.prospectClone);

    this.prospectClone = new Prospect();
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.prospectForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  seartch_str: string = '';
  formGroup: FormGroup;
  list_auto_fill_options: AutoFillEntity[] = [];
  defaultCountry: Country = {
    name: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    numericCode: '250',
    callingCode: '+33',
  };

  findByName() {
    if (this.seartch_str.length > 2) {
      const unsubscribeRechercheProspectGet = this.apiProspectsService
        .rechercheProspectGet(this.seartch_str)
        .pipe(
          tap(({ results }) => {
            this.list_auto_fill_options = results;
          })
        )
        .subscribe();
      this.listUnsubscribe.push(unsubscribeRechercheProspectGet);
    } else {
      this.list_auto_fill_options = null;
    }
  }

  onSubmit() {
    const unsubscribeselectProspectWithIdGet = this.apiProspectsService
      .selectProspectWithIdGet(this.formGroup.value.prospect_id)
      .subscribe((data: Prospect) => {
        this.prospect = data;
        this.initFormProspect();
      });
    this.listUnsubscribe.push(unsubscribeselectProspectWithIdGet);
  }

  prospectFinder() {
    this.onSelectToFillOldProspect.emit(this.index);
  }

  hasError($event: boolean) {}

  /**
   * Permets de retourner les informations bancaire saisie
   * @param $event
   */
  getBankInformation($event: BankInformation): void {
    this.prospectClone.information_bancaire = $event;
    this.onUpdateProspect.emit(this.prospectClone);
  }

  getNumber($event: any, type: string) {
    switch (type) {
      case 'mobile':
        this.prospectForm.controls.mobile.setValue($event);
        break;
      case 'tel':
        this.prospectForm.controls.tel.setValue($event);
        break;
      case 'tel2':
        this.prospectForm.controls.tel2.setValue($event);
        break;
      default:
        break;
    }
  }

  /**
   * Permets de détecter le pays
   * @param object
   */
  telInputObject(object): void {
    object.setCountry(object.j);
  }

  onCountrySelected($event: Country) {}

  isValid(field: string) {
    return this.prospectForm?.controls[field].invalid;
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
