import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiTicketsService } from '../../../services/ApiTickets/api-tickets.service';
import { TicketsAndSupport } from '../../../entity/TicketsAndSupport/TicketsAndSupport';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { CountTicketsByCategorie } from '../../../entity/TicketsAndSupport/CountTicketsByCategorie';
import { FilterTicket } from 'src/app/entity/TicketsAndSupport/FilterTicket';
import { DatePipe } from '@angular/common';
import { formatDateToLocal, UniformiserDateToLocal } from 'src/app/shared/Utils/sharedFunctions';
import { Subscription } from 'rxjs';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
@Component({
  selector: 'app-tickets-and-support',
  templateUrl: './tickets-and-support.component.html',
  styleUrls: ['./tickets-and-support.component.css'],
})
export class TicketsAndSupportComponent implements OnInit, OnDestroy {

  limit = 15;
  pages = 1;
  totalPages = 0;
  categorieTickets: CountTicketsByCategorie = new CountTicketsByCategorie();
  ticketsSupport: TicketsAndSupport[] = [];
  fa = fa;
  intervale = 1;
  sort_field: string = "default";
  sort_value: boolean = false;
  sort_type: string = 'desc';
  search: string = ""
  listStatutWebService: Subscription[] = [];
  filtres_tickets: FilterTicket = new FilterTicket();

  date_fin: Date = new Date();
  date_debut: Date = new Date();
  myDate = new Date();
  isEdit: boolean = false;
  filtre: string = null;
  isListe: boolean = false;

  constructor(
    private apiTickets: ApiTicketsService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private router: Router,
    private datePipe: DatePipe,
    private apiAuthentificationService: ApiAuthentificationService,

  ) {}

  ngOnInit(): void {

    this.sharedMenuObserverService.updateMenu('Tickets & Support');
    this.filtre = localStorage.getItem('filtre');
    const page = localStorage.getItem('pages');
    const sort_fields = localStorage.getItem('sort_field');
    const searchs = localStorage.getItem('search');

    if (searchs != null) {
      this.search = searchs;
    }

    if (sort_fields != null) {
      this.sort_field = sort_fields;
    }

    if (page !== null) {
      this.pages = parseInt(page);
    }
    if (this.filtre !== null) {
      this.filtres_tickets = JSON.parse(this.filtre);
    }
    this.getListTicketsSupport();
    this.getCountTicketsByCategorie();
  }

  ngOnDestroy() {
    if (this.isEdit === false) {
      localStorage.removeItem('filtre');
      localStorage.removeItem('pages');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('search');
    }
    this.listStatutWebService.forEach((element) => {
      element.unsubscribe();

    })
  }

  getListTicketsSupport() {
    localStorage.setItem('filtre', JSON.stringify(this.filtres_tickets));
    if (this.sort_value === false) {
      this.sort_value = true;
    } else {
      this.sort_value = false;
    }
    this.showloader();
    localStorage.setItem('filtre', JSON.stringify(this.filtres_tickets));
    if (this.sort_value === false) {
      this.sort_value = true;
    } else {
      this.sort_value = false;
    }
    this.showloader();
    const unsubscribeGetListTickets = this.apiTickets
      .GetListTickets(this.filtres_tickets, this.pages, this.limit, this.search, this.sort_type, this.sort_field)
      .subscribe(
        (data) => {
          if (data.length !== 0) {
            this.ticketsSupport = data?.aaData.filter((element) => element.statut_libelle[0] !== null);

            this.calculate_nb_pages(data?.iTotalDisplayRecords);
            this.hideloader();
            this.ticketsSupport.forEach((element) => {
              const dateticket = UniformiserDateToLocal(element.ticket_date_creation);
              element.ticket_date_creation = dateticket;
            });
          } else {
            this.ticketsSupport = [];

            this.calculate_nb_pages(data?.iTotalDisplayRecords);

            this.hideloader();
          }
          this.listStatutWebService.push(unsubscribeGetListTickets)
        },
        () => {
          this.hideloader();
        }
      );

  }

  getDataValue() {
    localStorage.setItem('search', this.search);
    localStorage.removeItem('pages');
    this.pages = 1;
    this.getListTicketsSupport();
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pages - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pages + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.pages = val;
    this.getListTicketsSupport();
  }

  isFirst(): boolean {
    return this.pages === 1;
  }

  isLast(): boolean {
    return this.pages === this.totalPages;
  }

  updateListTickets() {
    this.pages = 1;
    this.getListTicketsSupport();
  }

  changepage(number: number) {
    localStorage.setItem('pages', number.toString());
    this.pages = number;
    this.getListTicketsSupport();
  }

  setSortField(sort_field: string) {
    this.sort_field = sort_field;
    localStorage.setItem('sort_field', this.sort_field);
    this.getListTicketsSupport();
  }

  getCountTicketsByCategorie() {
    const unsubscribeGetCountTicketsByCategorie = this.apiTickets.GetCountTicketsByCategorie()
      .subscribe((data: CountTicketsByCategorie) => {
        this.categorieTickets = data;
      }); this.listStatutWebService.push(unsubscribeGetCountTicketsByCategorie);
  }


  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.pages = this.intervale;
      if (this.pages <= this.totalPages) {
        this.getListTicketsSupport();
      }
    }
  }

  setStatusFilter(status_filter: string) {
    localStorage.removeItem('pages');
    localStorage.setItem('status_filter', this.sort_field);
    this.pages = 1;
    if (this.filtres_tickets.ticket.recheche_status.indexOf(status_filter) !== -1) {
      this.hideloader();
      return;
    } else {
      if (status_filter !== '') {
        this.filtres_tickets.ticket.recheche_status = [];
        this.filtres_tickets.ticket.recheche_status.push(status_filter);
      } else {
        this.filtres_tickets.ticket.recheche_status = [];
      }
    }
    this.getListTicketsSupport();
  }

  NavigateToAddTicket() {
    this.router.navigate(['/ticketsandsupport/add']);
  }

  NavigateToTicketDetails(id: string) {
    this.router.navigate(['/ticketsandsupport/details/' + id]);
  }

  NaviagateToOpportuniteDetials(id: string) {
    this.router.navigate(['/opportunities/details/' + id + '/prospect']);
  }

  NaviagateToAffaireDetials(id: string) {
    this.router.navigate(['/affaires/details/' + id]);
  }

  EmitFilterTickets($event: FilterTicket) {
    this.filtres_tickets = $event;
  }

  convertUTCDateToLocalDate(date) {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
}
