import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {Prospect} from "../entity/Prospect";
import {ProspectSelector} from "../entity/ProspectSelector";

@Injectable({
  providedIn: 'root'
})
export class ProspectFinderService {

  id_Pros:string=null;
  selectedProspect = new Subject<ProspectSelector>();


  selectProspect( prospect:Prospect) {
    const pros = new ProspectSelector(prospect,this.id_Pros)
    this.selectedProspect.next(pros);
  }

  setIdProspect(id_Pros: string){
    this.id_Pros = id_Pros;
  }

  onSelectProspect() {
    return this.selectedProspect.asObservable();
  }

  constructor() {
  }
}
