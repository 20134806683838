<div class="row">
    <div class="col card bg-white border-0 p-3 mx-5">
        <div class="col">
            <div class="card-doc-title ml-3 row">
                <fa-icon class="mr-2 fa-lg " [icon]="fa['faInfoCircle']"></fa-icon>
                <h4>Informations spécifiques</h4>
            </div>
            <div class="row">
                <div class="col-12">
                    <ng-container *ngIf="fields.length>0  && !loading">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="col-12">
                                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" (change)="fromChange()" ></formly-form>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                    <ng-container *ngIf="fields.length<1  && loading">
                        <p>pas d'informations</p>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>