export class GroupUser{
    description: string;
    id: string;
    manager: string;
    nom: string;
    test_aff_drive: string;
    users: User;
}
export class User{
    nom: string;
    prenom: string;
}