import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpportunitiesDetailsRoutingModule } from './opportunities-details-routing.module';
import { DetailsComponent } from './Elements/details/details.component';
import { PlanificationComponent } from './Elements/planification/planification.component';
import { HistoriqueComponent } from './Elements/historique/historique.component';
import { CommunicationComponent } from './Elements/communication/communication.component';
import { ContractActuelComponent } from './Elements/details/contract-actuel/contract-actuel.component';
import { InformationComplementairesComponent } from './Elements/details/information-complementaires/information-complementaires.component';
import { InformationSpecifiquesComponent } from './Elements/details/information-specifiques/information-specifiques.component';
import { OpportunitiesDetailsComponent } from './opportunities-details.component';
import { SharedModule } from '../../../shared/shared.module';
import { AssistantComponent } from './Elements/assistant/assistant.component';
import { ProspectComponent } from './Elements/prospect/prospect.component';
import { EnregistrementDocumentsComponent } from './Elements/enregistrement-documents/enregistrement-documents.component';
import { TarificateurComponent } from './Elements/tarificateur/tarificateur.component';
import { StoreModule } from '@ngrx/store';

import {
  DETAILS_OPPORTUNITE_CONFIG_TOKEN,
  DETAILS_OPPORTUNITE_LOCAL_STORAGE_KEY,
  DETAILS_OPPORTUNITE_STORAGE_KEYS,
} from './store/details-opportunite.tokens';
import { LocalStorageService } from '../../../services/LocalStorageService.service';
import { EventEmiterService } from '../../../services/event.emmiter.service';
import { getStoreMetaReducerConfig } from '../add-opportunite/add-opportunite.module';
import { detailsOpportuniteReducer } from './store/details-opportunite.reducers';
import { DetailsOpportuniteEffects } from './store/details-opportunite.effects';
import { EffectsModule } from '@ngrx/effects';
import { MatMenuModule } from '@angular/material/menu';
import { TacheComponent } from './Elements/tache/tache.component';
import { TicketComponent } from './Elements/ticket/ticket.component';
import { ObjetAssurerComponent } from './Elements/objet-assurer/objet-assurer.component';

@NgModule({
  declarations: [
    DetailsComponent,
    PlanificationComponent,
    HistoriqueComponent,
    CommunicationComponent,
    ContractActuelComponent,
    InformationComplementairesComponent,
    InformationSpecifiquesComponent,
    OpportunitiesDetailsComponent,
    AssistantComponent,
    ProspectComponent,
    EnregistrementDocumentsComponent,
    TarificateurComponent,
    TacheComponent,
    TicketComponent,
    ObjetAssurerComponent,
  ],
  exports: [HistoriqueComponent],
  imports: [
    CommonModule,
    OpportunitiesDetailsRoutingModule,
    SharedModule,
    StoreModule.forFeature('detailsOpportunite', detailsOpportuniteReducer, DETAILS_OPPORTUNITE_CONFIG_TOKEN),
    EffectsModule.forFeature([DetailsOpportuniteEffects]),
    MatMenuModule,
  ],
  providers: [
    EventEmiterService,
    { provide: DETAILS_OPPORTUNITE_LOCAL_STORAGE_KEY, useValue: '__details_opportunite_storage__' },
    {
      provide: DETAILS_OPPORTUNITE_STORAGE_KEYS,
      useValue: ['entreprise'],
    },
    {
      provide: DETAILS_OPPORTUNITE_CONFIG_TOKEN,
      deps: [DETAILS_OPPORTUNITE_STORAGE_KEYS, DETAILS_OPPORTUNITE_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getStoreMetaReducerConfig,
    },
  ],
})
export class OpportunitiesDetailsModule {}
