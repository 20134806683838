<div class="row">
  <div class="position-fixed" style="z-index: 5;">
    <app-filtres-ticket (onSubmitFilter)="getListTicketsSupport()" (onPushFilters)="EmitFilterTickets($event)">
    </app-filtres-ticket>
  </div>
</div>
<div class="row">
  <!-- Datatables -->
  <div class="col-lg-12">
    <div class="navbar-center row">
     
   
      <div class="col">
        <div class="row position-absolute flex-nowrap" style="right: 0px">
          <div class="col text-nowrap text-right mt-2">
            <p >{{'label.goto' | translate}}</p>
          </div>
          <div class="col mt-1 ml-2">
            <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number" style="margin-left: 4px;"
            size="1" class="page_input bg-white border rounded font-weight-bold" [value]="pages" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
        <div class="card mb-4 border-0" style="border-radius: 13px;">
          <div class="row">
            <div class="col-8 d-block mt-4"  >
              <button type="button" class="btn-dt-add  ml-4" (click)="NavigateToAddTicket()"   data-title="Ajouter un ticket">
                <fa-icon class="icon-btn-add" [icon]="fa['faPlus']"></fa-icon>
              </button>
            </div> 
            <div class="col-4 p-2 mt-2 text-right" >
              <input class="search" name="search" [(ngModel)]="search"  (change)="getDataValue()"
                placeholder="Recherche...">
              <fa-icon aria-hidden="true" class="icon-search fa-md" [icon]="fa['faSearch']"></fa-icon>
          </div>
          </div>
        <div class="row row-info">
          <div class="card-info-1" (click)="setStatusFilter('')">
            <p class="p-info-1">{{categorieTickets.totale}}</p>
            <p class="p-info-2"> {{'tickets.label.total' | translate}}</p>
          </div>
          <div class="card-info-2" (click)="setStatusFilter('c4ca4238a0b923820dcc509a6f75849b')">
            <p class="p-info-1">{{categorieTickets.Declare}}</p>
            <p class="p-info-2"> {{'tickets.label.declares' | translate}}</p>
          </div>
          <div class="card-info-3" (click)="setStatusFilter('eccbc87e4b5ce2fe28308fd9f2a7baf3')">
            <p class="p-info-1">{{categorieTickets.Ferme_positif}}</p>
            <p class="p-info-2"> {{'tickets.label.fermespositif' | translate}}</p>
          </div>
          <div class="card-info-4" (click)="setStatusFilter('a87ff679a2f3e71d9181a67b7542122c')">
            <p class="p-info-1">{{categorieTickets.Ferme_negatif}}</p>
            <p class="p-info-2">{{'tickets.label.fermesnegatif' | translate}}</p>
          </div>
        </div>
 
      <div class="table-responsive p-3">
        <table class="table align-items-center">
          <thead class="head-table">
            <tr>
              <th>{{'tickets.label.actions' | translate}}
              </th>
              <th  (click)="setSortField('ticket_date_creation')">
                {{'tickets.label.creation' | translate}}
                <div class="sort-icon d-inline-block" 
                  [ngClass]="{'sort-active': sort_field=='ticket_date_creation'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='ticket_date_creation'}">
                  <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']">
                  </fa-icon>
                </div>
              </th>
              <th  (click)="setSortField('prospect')">{{'tickets.label.prospect' |
                translate}}
                <div class="sort-icon d-inline-block" 
                  [ngClass]="{'sort-active': sort_field=='prospect'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='prospect'}">
                  <fa-icon class="fa-lg ml-2 " [icon]="fa['faSortAmountDownAlt']">
                  </fa-icon>
                </div>
              </th>
              <th  (click)="setSortField('opportunité')">
                {{'tickets.label.affaireOpportunite' | translate}}
                <div class="sort-icon d-inline-block" 
                  [ngClass]="{'sort-active': sort_field=='opportunité'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='opportunité'}">
                  <fa-icon class="fa-lg ml-2"[icon]="fa['faSortAmountDownAlt']">
                  </fa-icon>
                </div>
              </th>
              <th   (click)="setSortField('User affecté')">{{'tickets.label.affectea'
                | translate}}
                <div class="sort-icon d-inline-block" 
                  [ngClass]="{'sort-active': sort_field=='User affecté'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='User affecté'}">
                  <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']">
                  </fa-icon>
                </div>
              </th>
              <th   (click)="setSortField('sujet')">{{'tickets.label.sujet' |
                translate}}
                <div class="sort-icon  d-inline-block"  [ngClass]="{'sort-active': sort_field=='sujet'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='sujet'}">
                  <fa-icon class="fa-lg ml-2"  [icon]="fa['faSortAmountDownAlt']">
                  </fa-icon>
                </div>
              </th>
              <th  (click)="setSortField('catégorie')">{{'tickets.label.categorie' |
                translate}}
                <div class="sort-icon  d-inline-block" 
                  [ngClass]="{'sort-active': sort_field=='catégorie'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='catégorie'}">
                  <fa-icon class="    fa-lg ml-2"  [icon]="fa['faSortAmountDownAlt']">
                  </fa-icon>
                </div>
              </th>
              <th (click)="setSortField('statut')">
                {{'tickets.label.status' | translate}}
                <div class="sort-icon" style="display: inline-block;" [ngClass]="{'sort-active': sort_field=='statut'}"
                  [ngClass]="{'sort-up': !sort_value && sort_field=='statut'}">
                  <fa-icon class="fa-lg ml-2"  [icon]="fa['faSortAmountDownAlt']">
                  </fa-icon>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="body-table"> 
            <tr *ngFor="let ticket of ticketsSupport; let i = index">
              <td>
                <a  type="button" class="btn-dt-list" (click)="isEdit=true" href="/ticketsandsupport/details/{{ticket.id_ticket}}">
                  <img src="/assets/icons/edit-icon.svg">
                </a>
              </td>
              <td >
                <p >{{ticket.ticket_date_creation}}</p>
                <p >{{ticket.ticket_createur_name}}</p>
              </td>
              <td >
                <p >{{ticket.prospect_full_name}}</p>
              </td>
              <td>
                <div class="d-flex flex-column">
                  <div   *ngIf="ticket.id_affaire_ticket"  >
                    <a  type="button" class="btn-dt-link text-left" target="_blank" href="/affaires/details/{{ticket.id_affaire_ticket}}"  >
                      <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                      Affaire
                    </a>
                  </div>
                  <div  *ngIf="ticket.id_opportunite" >
                    <a  type="button" class="btn-dt-link text-left pl-2"  target="_blank"  href="/opportunities/details/{{ticket.id_opportunite}}" >
                      <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                      Opportunité
                    </a>
                  </div>
                </div>
              </td>
              <td >
                <p >{{ticket.ticket_affect_full_name}}</p>
              </td>
              <td>
                <p>{{ticket.ticket_titre}}</p>
              </td>
              <td >
                <p>{{ticket.categorie_libelle}}</p>
              </td>

               <td>
            
               <!--    -->
                   <div class="label-status"  [ngStyle]="{'background':ticket.statut_libelle[0]?.background_color}" >
                      <span class="pt-2 "  [ngStyle]="{'color':ticket.statut_libelle[0]?.color}" >
                    <fa-icon   class="mr-1" [icon]="fa[ticket.statut_libelle[0]?.icon]"></fa-icon>  
                       {{ticket.statut_libelle[0].libelle.split('(')[0].trim()}}
                     </span> 
                   </div> 

              </td> 
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="navbar-center mb-4 row">
      <div class="col pt-1">
        <div class="row w-50 flex-nowrap" >
          <div  class="col mt-1">
            <p>{{'label.show' | translate}}</p>
          </div>
          <div  class="col mx-1">
            <select [(ngModel)]="limit" (change)="updateListTickets()" 
            class="bg-white border pt-1 rounded font-weight-bold">
              <option value="15">15</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col mt-1">
            <p>{{'label.entries' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-8">
        <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
          <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
              <a class="page-link-symbol">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2" >
              <a class="page-link-symbol-chevron" aria-label="Previous">
                <i class="fa fa-chevron-left "></i>
              </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages-3>0" class="page-item">
                <a (click)="changepage(i+pages-3)" class="page-link mt-2 border rounded">{{i + pages - 3}}</a>
              </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
              <li *ngIf="i+pages<totalPages" class="page-item">
                <a (click)="changepage(i+pages)" class="page-link mt-2 border rounded" 
                [ngClass]="{'active-page':pages==(i+pages)}">
                {{i + pages}}
              </a>
              </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
              <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                [ngClass]="{'active-page':pages==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
              <a class="page-link-symbol-chevron" aria-label="Next">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
              <a class="page-link-symbol">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          </ul>
        </nav>
    </div>
      <div class="col">
        <div class="row position-absolute flex-nowrap" style="right: 0px">
          <div class="col text-nowrap text-right mt-2">
            <p >{{'label.goto' | translate}}</p>
          </div>
          <div class="col mt-1 ml-2">
            <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number" style="margin-left: 4px;"
            size="1" class="page_input bg-white border rounded font-weight-bold" [value]="pages" max="{{totalPages}}">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>