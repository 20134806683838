<div class="row">

    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Réglementer :</label>
        </div>
    </div>
    <div class="col-6">

        <div class="switch-toggle switch-ios">
            <input id="On" value="on" name="view" type="radio" (click)="EmitFilterRec()"
                [(ngModel)]="reclamation.reglement" (change)="onItemChange()" checked>
            <label for="On" onclick="">Activer</label>

            <input value="na" id="N/A" name="view" type="radio" (click)="EmitFilterRec()"
                [(ngModel)]="reclamation.reglement" (change)="onItemChange()">
            <label for="N/A" onclick="">N/A</label>

            <input value="off" id="Off" name="view" type="radio" (click)="EmitFilterRec()"
                [(ngModel)]="reclamation.reglement" (change)="onItemChange()">
            <label for="Off" onclick="">Désactiver</label>

            <a></a>
        </div>

    </div>

</div>


<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class=""> Origine :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <div class="form-group example-form">

                <mat-form-field class="example-full-width">
                    <mat-label> Origine</mat-label>
                    <input type="text" name="origine" matInput>
                </mat-form-field>

            </div>
        </div>
    </div>
</div>



<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Date réclamation client :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Du.. à</mat-label>
                <mat-date-range-input [rangePicker]="pickerDR">
                    <input matStartDate [(ngModel)]="reclamation.date_debut_rec_clt" />
                    <input matEndDate [(ngModel)]="reclamation.date_fin_rec_clt" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerDR"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerDR></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Date de réception :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Du.. à</mat-label>
                <mat-date-range-input [rangePicker]="pickerRC">

                    <input matStartDate [(ngModel)]="reclamation.date_debut_reception">
                    <input matEndDate [(ngModel)]="reclamation.date_fin_reception" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerRC"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerRC></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>



<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class=""> Date accusé réception client :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Du.. à</mat-label>
                <mat-date-range-input [rangePicker]="pickerDA">

                    <input matStartDate [(ngModel)]="reclamation.date_debut_acc_recp_clt" />
                    <input matEndDate [(ngModel)]="reclamation.date_fin_acc_recp_clt" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerDA"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerDA></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class=""> Nature de la réclamation :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <div class="form-group example-form">

                <mat-form-field class="example-full-width">
                    <mat-label> Nature de la réclamation :</mat-label>
                    <input type="text" name="nature" [(ngModel)]="reclamation.nature_rec" matInput>
                </mat-form-field>

            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Date envoi assureur :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Du.. à</mat-label>
                <mat-date-range-input [rangePicker]="pickerE">
                    <input matStartDate [(ngModel)]="reclamation.date_debut_envoi_assure" />
                    <input matEndDate [(ngModel)]="reclamation.date_fin_envoi_assure" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerE"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerE></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="row">

    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Réclamation motivée et justifiée :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="switch-toggle switch-ios">
            <input id="On" value="on" name="view" type="radio" (click)="EmitFilterRec()"
                [(ngModel)]="reclamation.rec_mot_just" (change)="onItemChange()" checked>
            <label for="On" onclick="">ON</label>

            <input value="na" id="N/A" name="view" type="radio" (click)="EmitFilterRec()"
                [(ngModel)]="reclamation.rec_mot_just" (change)="onItemChange()">
            <label for="N/A" onclick="">N/A</label>

            <input value="off" id="Off" name="view" type="radio" (click)="EmitFilterRec()"
                [(ngModel)]="reclamation.rec_mot_just" (change)="onItemChange()">
            <label for="Off" onclick="">OFF</label>

            <a></a>
        </div>

    </div>

</div>




<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Date du dossier complet / réclamation motivée :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Du.. à</mat-label>
                <mat-date-range-input [rangePicker]="pickerDRc">
                    <input matStartDate [(ngModel)]="reclamation.date_debut_doss_complet" />
                    <input matEndDate [(ngModel)]="reclamation.date_fin_doss_complet" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerDRc"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerDRc></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Date réponse réclamation :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Du.. à</mat-label>
                <mat-date-range-input [rangePicker]="pickerRR">
                    <input matStartDate [(ngModel)]="reclamation.date_debut_rep_rec" />
                    <input matEndDate [(ngModel)]="reclamation.date_fin_rep_rec" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerRR"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerRR></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class="">Date de cloture réclamation :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Du.. à</mat-label>
                <mat-date-range-input [rangePicker]="pickerC">
                    <input matStartDate [(ngModel)]="reclamation.date_debut_cloture_rec" />
                    <input matEndDate [(ngModel)]="reclamation.date_fin_cloture_rec" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerC"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerC></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>



<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class=""> Action Juridique :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <div class="form-group example-form">

                <mat-form-field class="example-full-width">
                    <mat-label> Action Juridique :</mat-label>
                    <input type="texte" name="actions" [(ngModel)]="reclamation.action_juridique" matInput>
                </mat-form-field>

            </div>
        </div>
    </div>
</div>




<div class="row">
    <div class="col-6" style="margin-top: 20px;">
        <div class="form-group  example-form" style="width: 100% !important; ">
            <label class=""> Stade de traitement :</label>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <div class="form-group example-form">

                <mat-form-field class="example-full-width">
                    <mat-label> Stade de traitement :</mat-label>
                    <input type="texte" [(ngModel)]="reclamation.stade_traitement" matInput>
                </mat-form-field>

            </div>
        </div>
    </div>
</div>