<div class="flex-column bg-white rounded-card">
    <div class="p-3 mb-3">
        <table id="TableRelations" class="table">
            <thead class="head-table">
                <tr>
                    <th> # </th>
                    <th> Nom </th>
                    <th> Relation </th>
                    <th> Action </th>
                </tr>
            </thead>
            <ng-container *ngIf="show_loading_add">
                <div class="col-12">
                    <div class="spinner-style">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>
                </div>
            </ng-container>
            <tbody class="body-table">
                <tr *ngFor="let item of relations; let index = index">
                    <td>{{index+1}}</td>
                    <td>{{item.nom}}</td>
                    <td> {{item.relation}}</td>
                    <td>
                        <button type="button" routerLink="fiche-prospect/{{opportunite_id}}/{{item.id}}" (click)="onEmitRelationProspect(item.id)" class="btn-dt-list">
                        <img src="/assets/icons/edit-icon.svg" alt="edit">
                    </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>