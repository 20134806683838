<div class="card-historiques  bg-white p-4" [ngClass]="{'cover': !allowed_to_update}" >
    <div class="card-doc-title ml-3 row">
        <fa-icon class="mr-2 fa-lg" [icon]="fa['faHistory']"></fa-icon>
        <label class="title-history-communication"> <b>Historique de communications</b> </label>
    </div>
    <mat-form-field class="w-100">
        <input matInput (keyup)="applyFilter($event)" placeholder="Recherche..." #input>
    </mat-form-field>
    <div class="mat-elevation-z8">
        <table mat-table class="w-100" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
                <td mat-cell *matCellDef="let row"> {{row.date}} </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Utilisateur </th>
                <td mat-cell *matCellDef="let row"> {{row.utilisateur}} </td>
            </ng-container>
            <ng-container matColumnDef="prospect">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Données du prospect </th>
                <td mat-cell *matCellDef="let row">
                    <p *ngIf="row.type==='sms'">{{row.donnees_prospect.tel}}</p>
                    <p *ngIf="row.type !=='sms' && row.type!=='sms' && row.type!=='mail'">{{row.donnees_prospect.mail}}</p>
                </td>
            </ng-container>
            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Détails </th>
                <td mat-cell *matCellDef="let row"> {{row.details}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                <td mat-cell *matCellDef="let row">
                    <p *ngIf="row.type==='post'">
                        <fa-icon class="iconGris" [icon]="fa['faEnvelopeOpenText']"></fa-icon>
                        {{row.type}}
                    </p>
                    <p *ngIf="row.type==='mail'">
                        <fa-icon class="iconGris" [icon]="fa['faEnvelope']"></fa-icon>
                        {{row.type}}
                    </p>
                    <p *ngIf="row.type==='sms'">
                        <fa-icon class="iconGris" [icon]="fa['faSms']"></fa-icon>
                        {{row.type}}
                    </p>
                    <p *ngIf="row.type ==='Visioconférence' && row.type!=='sms' && row.type!=='mail'">
                        <fa-icon class="iconGris" [icon]="fa['faVideo']"></fa-icon>
                        {{row.type}}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="statut">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut</th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.statut==='S'"><img class="status-comm-width" src="assets/icons/check-circle.svg"></span>
                    <span *ngIf="row.statut==='E'"><img class="status-comm-width" src="assets/icons/times-icon.svg"></span>
                    <span *ngIf="row.statut==='P'"><fa-icon class="fa-lg " [icon]="fa['faClock']"></fa-icon></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="fichier">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fichier </th>
                <td mat-cell *matCellDef="let row">
                    <a *ngIf="row?.fichier" class="btn-dt-link iconBleu" target="_blank" href="{{row.fichier}}">
                        <fa-icon [icon]="fa['faPaperclip']"></fa-icon>

                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Lien </th>
                <td mat-cell *matCellDef="let row">
                    <a *ngIf="row?.lien" class="btn-dt-link iconBleu" target="_blank" href="{{row.lien}}">
                        <fa-icon [icon]="fa['faEye']"></fa-icon>
                    </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">Pas de données disponibles </td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>