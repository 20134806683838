export class Calendar {
  type_opportunity: string;
  id_type_opportunity: number;
  affecter: any = null;
  affecterLibelle: any = null;
  status: any = null;
  statusLibelle: string = null;
  parent: number = -1;
  dateDebut: string = "";
  dateFin: string = "";
  invalidForms: any[] = [];

  constructor(affecter?: any, affecterLibelle?: any) {
    this.affecter = affecter;
    this.affecterLibelle = affecterLibelle;
  }
}
