<div class="row  example-full-width " >
    <div class="col-4 ">
        <mat-form-field class="example-full-width w-100">
          <mat-select (selectionChange)=" EmitFilterEcheancier()" [(ngModel)]="filterEcheancier.operateur_motif"
              placeholder="Opérateur">
              <mat-option value="=">
                  est égale
              </mat-option>
              <mat-option value="!=">
                  Différent
              </mat-option>
      
          </mat-select>
      </mat-form-field>
      </div>
    <div class="col-8">
        <mat-form-field class="example-full-width w-100">
          <mat-label>Motif</mat-label>
          <mat-chip-list #chipList>
              <mat-chip *ngFor="let motif of ListMotif" [selectable]="selectable" [removable]="removable"
               (removed)="removeMotif(motif)" (removed)="EmitFilterEcheancier()">
                  {{motif}}
                  <i matChipRemove *ngIf="removable" class="fa fa-times" aria-hidden="true"></i>

                  <!-- <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon> -->
              </mat-chip>
              <input (click)="EmitFilterEcheancier()" placeholder="Selectionner produit..." #MotifInput 
              [formControl]="MotifFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedMotif($event)">
              <mat-option *ngFor="let motif of filteredMotif | async" [value]="motif.id">
                  {{motif.libelle}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-12">
        <mat-form-field class="w-100" >
        <mat-date-range-input [rangePicker]="pickerDC">
          <input matStartDate (change)="EmitFilterEcheancier()"  [(ngModel)]="date_debut_periode" placeholder="Date de période du" />
          <input matEndDate (change)="EmitFilterEcheancier()"  [(ngModel)]="date_fin_periode" placeholder="à" />

      </mat-date-range-input>
      <mat-datepicker-toggle (change)="EmitFilterEcheancier()"  matSuffix [for]="pickerDC"></mat-datepicker-toggle>
      <mat-date-range-picker (change)="EmitFilterEcheancier()"  (closed)="EmitFilterEcheancier()" #pickerDC></mat-date-range-picker>
        </mat-form-field>
      </div>
  </div>


 

  