import {RouteInfo} from '../../components/navbar/navbar.component';

export class AuthGardConfig {
    private static _routes: RouteInfo[] = [];

    public static get routes(): RouteInfo[] {
        return AuthGardConfig._routes;
    }
    public static set routes(value: RouteInfo[]) {
        AuthGardConfig._routes = value;
    }
}
