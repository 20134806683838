import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ae-toolbar-set',
  templateUrl: './ae-toolbar-set.component.html',
  styleUrls: ['./ae-toolbar-set.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AeToolbarSetComponent {
  constructor() {}
}
