import { NotificationsService } from './../../../shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from './../../../entity/NotificationMessage';
import { ApiDocumentsService } from './../../../services/ApiDocuments/api-documents.service';
/* eslint-disable dot-notation */
import { BankInformation } from './../../../entity/BankInformation';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InformationsSpecifique } from './../../../entity/Opportunity/InformationsSpecifique';
import { InformationsComplmentaire } from './../../../entity/Opportunity/InformationsComplmentaire';
import { ApiOpportunitService } from './../../../services/ApiOpportunite/api-opportunite.service';
import { ApiStatusService } from './../../../services/ApiStatus/api-status.service';
import { StatusAutorise } from './../../../entity/Opportunity/StatusAutorise';
import { Component, OnInit, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { tap } from 'rxjs/operators';
import { AffaireDetails } from 'src/app/entity/Affaires/AffaireDetails';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';
import { Prospect } from '../../../entity/Prospect';
import { Entreprise } from '../../../entity/Opportunity/Entreprise';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { HistoriqueComponent } from './historique/historique.component';
import { Subject } from 'rxjs-compat/Subject';
import { Observable } from 'rxjs-compat/Observable';
import { ListTypesAndParent } from 'src/app/entity/Opportunity/ListTypesAndParent';
import { HistoriquesAffaires } from 'src/app/entity/Affaires/HistoriquesAffaires';
import { InformationAvanceesComponent } from './information-avancees/information-avancees.component';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StatusGarantieComponent } from './status-garantie/status-garantie.component';
import { EventEmiterService } from 'src/app/services/event.emmiter.service';
class GarantieAffaire {
  id_aff: string;
  garanties: any = [];
}
@Component({
  selector: 'app-affaire-details',
  templateUrl: './affaire-details.component.html',
  styleUrls: ['./affaire-details.component.css'],
})
export class AffaireDetailsComponent implements OnInit, AfterViewInit , OnChanges {
  fa = fa;
  f = fa.faInfoCircle;
  affaire_id: string = '';
  id_opportunite: string = '';
  prospect_id: string = '';
  contrat_id: string = '';
  private _listners = new Subject<any>();
  entreprise: Entreprise = null;
  societe: Entreprise = new Entreprise();
  entrepriseUpdated: boolean = false;
  contratUpdated: boolean = false;
  infoSpecifiqueUpdated: boolean = false;
  infoComplementaireUpdated: boolean = false;
  successChange: boolean = false;
  isListe: boolean = false;
  idSelected: any;
  ListIdProspect = [];
  item: number = 0;
  Actions = 'Changer le statut';
  Enregistrer = 'Enregistrer';
  affaireDetailsUpdate: AffaireDetails = new AffaireDetails();
  // infoContrat: AffaireDetails;
  telNumber: any;
  prospect = new Prospect();
  status_autorise: StatusAutorise = new StatusAutorise();
  show_loading: boolean = false;
  show_loading_status: boolean = false;
  lastActionId: string = '';
  subStatusList: any[] = [];
  commentChangeAction: string = '';
  status: string = '';
  show_loadingAffaireComm: boolean;
  @ViewChild(HistoriqueComponent) childhisto: HistoriqueComponent;
  @ViewChild(InformationAvanceesComponent) informationAvanceesComponent: InformationAvanceesComponent;
  affaireData: string = '';
  informationsContrat: AffaireDetails = new AffaireDetails();
  infoComplementaire: InformationsComplmentaire;
  infoSpecifique: InformationsSpecifique;
  mobileProspect: string = '';
  mailProspect: string = '';
  sum: number = 0;
  jsonObject = {};
  listParent: ListTypesAndParent[] = [];
  listTypes: ListTypesAndParent[] = [];
  type_envoie: any;
  statut_envoi: any;
  statut: any;
  formInformationSpecifique: any;
  ProspectExport: any;
  Historiquesexport: HistoriquesAffaires[] = [];
  oldBankInformation: BankInformation[] = [];
  status_id: any;
  allowed_to_update_affaire: boolean;
  formDataUploadDocument: FormData[] = [];
  accessList: AccessList;
  ecriture_transaction_financiere_client: boolean;
  lecture_transaction_financiere_client: boolean;
  histAccess: boolean;
  histCommAccess: boolean;
  listeGarantieAffaire: any;
  CA: string;
  listGrantie: GarantieAffaire[] = [];
  exist: boolean = false;
  upadateGarantieAffaire: boolean = false;
  upadateobjetAssureAffaire: boolean = false;
  bodyGarantie: GarantieAffaire = new GarantieAffaire();
  listObjetAssurer: any[] = [];
  bien: boolean = false;
  person: boolean = false;
  errorProspect: boolean = false;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private apiProspectService: ApiProspectsService,
    private apiAffairesService: ApiAffairesService,
    private apiOpportuniteServices: ApiOpportunitService,
    private apiProspectsService: ApiProspectsService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiStatusService: ApiStatusService,
    private apiCommunicationService: ApiComminucationService,
    private apiOpportunitService: ApiOpportunitService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiDocumentsService: ApiDocumentsService,
    private notificationsService: NotificationsService,
    private apiAuthentificationService: ApiAuthentificationService,
    private _eventEmiter: EventEmiterService,
    private router: Router
  ) {
    this.listennumber().subscribe((m: any) => {
      this.sum = this.sum + m;
      if (this.sum > 0) {
        if (this.successChange === true) {
          this.alertSuccess('Affaire modifiée avec succée');
          this.successChange = false;
        } else this.errorInfo();

        this.sum = 0;
        this.childhisto?.getHistorique();
        this.hideloader();
      }
    });
    this.apiDocumentsService.uploadDocument().subscribe((formDataDocuments: FormData) => {
      this.formDataUploadDocument.push(formDataDocuments);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.ecriture_transaction_financiere_client = this.accessList.ecriture_transaction_financiere_client;
      this.lecture_transaction_financiere_client = this.accessList.lecture_transaction_financiere_client;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
    });  }

  ngAfterViewInit(): void {
    this.allowed_to_update_affaire = this.informationsContrat.contrat.allowed_to_update;
  }

  getlistSpecif($event: any) {
    const jsonO = [];
    let test = false;
    const doc = $event;

    Object.keys(doc).forEach(function (value) {
      if (value === 'inf_prod') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Infomation_Specific') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Infomation_Specific'] = $event;

          // jsonO.push({ InfoSpecific: $event });
        }
      });
    });
    if (!test) jsonO['Infomation_Specific'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  getlistComp($event: any) {
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'infcamp') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Informations_Complementaire') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Informations_Complementaire'] = $event;

          // jsonO.push({ InformationsComplmentaire: $event });
        }
      });
    });
    if (!test) jsonO['Informations_Complementaire'] = $event;

    // Object.assign(this.jsonObject, jsonO);
  }

  getListDocOpp($event: any) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      const doc = $event;

      Object.assign($event, doc);
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Document_Opportunité') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Document_Opportunité'] = $event;

            // jsonO.push({ ListDocumentOpportunité: $event });
          }
        });
      });
      if (!test) jsonO['List_Document_Opportunité'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistContratenliason($event) {
    if ($event.length > 0) {
      // this.jsonObject['list_Affaire_En_Relation'] = $event;
    }
  }

  getListDoc($event: any) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      const doc = $event;

      Object.assign($event, doc);
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Document') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Document'] = $event;

            // jsonO.push({ ListDocument: $event });
          }
        });
      });
      if (!test) jsonO['List_Document'] = $event;

      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistecheancier($event) {
    if ($event.length > 0) {
      // this.jsonObject['list_echancier'] = $event;
    }
  }

  getlistpaimentfrais($event) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'frais_de_paiement') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['frais_de_paiement'] = $event;
            // jsonO.push({ paimentfrais: $event });
          }
        });
      });
      if (!test) jsonO['Statut_actuel'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  /**
   *
   * @param bankInformation
   */

  getBankInformation(bankInformation: BankInformation[]): void {
    Object.assign(this.oldBankInformation, bankInformation);
    this.successChange = true;
    if (this.oldBankInformation !== this.informationsContrat.info_bancaire) {
      this.affaireDetailsUpdate.info_bancaire = bankInformation;
      this.affaireDetailsUpdate.info_bancaire.forEach((element: BankInformation) => {
        if (element.iban) element.iban = element.iban.replace(/\s/g, '');
      });
      this.contratUpdated = true;
    }
  }

  getlistcommentaireexport($event) {
    if ($event.length > 0) {
      const jsonO = [];
      let test = false;
      // Object.assign(jsonO, this.jsonObject);
      jsonO.forEach((element, index) => {
        Object.keys(element).forEach(function (value) {
          if (value === 'List_Commentaire') {
            jsonO.splice(index, 1);
            test = true;
            jsonO['List_Commentaire'] = $event;
            // jsonO.push({ ListCommentaire: $event });
          }
        });
      });
      if (!test) jsonO['List_Commentaire'] = $event;
      // Object.assign(this.jsonObject, jsonO);
    }
  }

  getlistticket($event) {
    if ($event.length > 0) {
      // this.jsonObject['List_Tickets_Affaires'] = $event;
    }
  }

  ngOnInit(): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.ecriture_transaction_financiere_client = this.accessList.ecriture_transaction_financiere_client;
      this.lecture_transaction_financiere_client = this.accessList.lecture_transaction_financiere_client;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
    });
    this.showloader();
    this.sharedMenuObserverService.updateMenu('Affaires ➜ Détails');
    this.route.paramMap.subscribe((params) => {
      this.affaire_id = params.get('affaire_id');
      this.getInformationsContrat();
    });
    this.apiAffairesService.listenLiaisonsContracts().subscribe((data: any) => {
      this.getInformationsContrat();
    });
    this.getAffaireGarantie();
    this._eventEmiter.getListObject().subscribe((data) => {
      this.listObjetAssurer = data;
    });
  }

  getListTypeAndParent() {
    this.apiCommunicationService
      .GetListTypeAndParent()
      .pipe(
        tap(({ parent, types }) => {
          this.listParent = parent;
          this.listTypes = types;
        })
      )
      .subscribe();
  }

  getDetailsLibelleCourrier() {
    this.apiCommunicationService
      .GetDetailsLibellePost()
      .pipe(
        tap(({ statut, statut_envoi, type_envoie }) => {
          this.statut = statut;
          this.statut_envoi = statut_envoi;
          this.type_envoie = type_envoie;
        })
      )
      .subscribe();
  }

  getTelOpp() {
    this.apiOpportuniteServices.getProspectTelByOppId(this.id_opportunite).subscribe((data) => {
      this.telNumber = data;
    });
  }

  getProspectTel() {
    this.apiProspectService.getProspectByOpprtuniteId(this.id_opportunite).subscribe((data) => {
      this.prospect = data;
    });
  }

  getProspect(idopp: string) {
    this.apiProspectService.getProspectByOpprtuniteId(idopp).subscribe((data: Entreprise) => {
      this.societe = data;
      this.hideloader();
    });
  }

  getInformationsContrat() {
    this.show_loadingAffaireComm = true;

    this.apiAffairesService.getAffaireInformationsContrat(this.affaire_id).subscribe((data: any) => {
      this.informationsContrat = data;
      if (this.informationsContrat.contrat.bien === 1) {
        this.bien = true;
      } else {
        this.bien = false;
      }
      if (this.informationsContrat.contrat.person === 1) {
        this.person = true;
      } else {
        this.person = false;
      }

      this.allowed_to_update_affaire = this.informationsContrat.contrat.allowed_to_update;
      this.status = this.informationsContrat.contrat.status;
      this.status_id = this.informationsContrat.contrat.etat_dossier_id;
      this.contrat_id = data.contrat.id_contrat;
      this.id_opportunite = data.contrat.id_opp_md5;
      this.prospect_id = data.contrat.id_prospect.toString();
      this.getListTypeAndParent();
      this.getDetailsLibelleCourrier();
      this.getProspectTel();
      this.getTelOpp();
      this.getProspect(data.contrat.id_opp_md5);
      // this.jsonObject['Statut_actuel'] = this.informationsContrat.contrat.status;
      // this.jsonObject['status_id'] = this.informationsContrat.contrat.etat_dossier_id;
      // this.jsonObject['ligne_de_produit'] = this.informationsContrat?.contrat.type_contrat;
      this.getcontratexport(this.informationsContrat);
      this.show_loadingAffaireComm = false;
    });
  }

  getStatusAutorise() {
    this.show_loading_status = true;
    this.apiStatusService
      .getStatutAutoriseAffaireGet(this.affaire_id)
      .pipe(
        tap(({ listactions }) => {
          this.status_autorise.listCategory = listactions;
        })
      )
      .subscribe((data) => {
        this.show_loading_status = false;
      });
  }

  updateSubActionsList(id: string) {
    this.show_loading = true;
    this.lastActionId = id;
    this.apiStatusService.getListActionsAffaireGet(id).subscribe((data) => {
      if (data.length !== 0) {
        this.subStatusList = data.class_etas_list;
        this.show_loading = false;
      } else {
        this.subStatusList = [];
        this.show_loading = false;
      }
    });
  }

  changeAction() {
    this.show_loading = true;
    this.apiOpportunitService
      .updateaffaireAction(this.affaire_id, this.lastActionId, this.commentChangeAction)
      .subscribe(
        () => {
          this.alertSuccess('Action mise a jour avec succès');
          this.Actualise();
        },
        () => {
          this.alertError('Erreur');
          this.Actualise();
        }
      );
  }

  listennumber(): Observable<any> {
    return this._listners.asObservable();
  }

  pushnumber(filterBy: any) {
    this._listners.next(filterBy);
  }

  updateAffaire() {
    if (this.errorProspect === false) {
      if (this.validateFormInformationSpecifique()) {
        this.showloader();
        if (this.formDataUploadDocument.length > 0) {
          this.formDataUploadDocument.forEach((element) => {
            this.uploadDocumentsAffaire(element);
          });
          this.apiAffairesService.pushdocumenthistorique('test');

          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Vos fichiers ont été téléchargés avec succès';
          this.notificationsService.sendMessage(notificationMessage);
          this.successChange = true;
          this.pushnumber(1);
        }

        if (this.contratUpdated) {
          // this.affaireDetailsUpdate = this.infoContrat;
          this.affaireDetailsUpdate.contrat.id_prospect = this.prospect_id;
          // this.affaireDetailsUpdate.json_object = JSON.parse(JSON.stringify(this.jsonObject));
          this.apiAffairesService.UpdateAffaire(this.affaire_id, this.affaireDetailsUpdate).subscribe(
            (response) => {
              this.contratUpdated = false;
              this.successChange = true;
              this.pushnumber(1);
            },
            (error) => {
              this.hideloader();
              this.alertError(error.message);
            }
          );
        }

        if (this.entrepriseUpdated) {
          // this.entreprise.json_object = JSON.parse(JSON.stringify(this.jsonObject));
          this.apiProspectsService.UpdateEntreprise(this.affaire_id, this.entreprise, this.id_opportunite).subscribe(
            (response) => {
              this.entrepriseUpdated = false;
              this.successChange = true;
              this.pushnumber(1);
              this.getInformationsContrat();
            },
            (error) => {
              this.hideloader();
              this.alertError(error.error.message);
            }
          );
        }

        if (this.infoSpecifiqueUpdated) {
          // this.infoSpecifique.json_object = JSON.parse(JSON.stringify(this.jsonObject));

          this.apiOpportunitService.updateInformationsSpecifique(this.affaire_id, this.infoSpecifique).subscribe(
            (response) => {
              this.infoSpecifiqueUpdated = false;
              this.successChange = true;
              this.pushnumber(1);
            },
            (error) => {
              this.hideloader();
              this.alertError(error.message);
            }
          );
        }
        if (this.upadateGarantieAffaire) {
          this.apiAffairesService.updateAffaireGarantie(this.bodyGarantie).subscribe(
            (response) => {
              this.alertSuccess('Garantie mis à jour');
              this.upadateGarantieAffaire = false;
            },
            (error) => {
              this.alertError(error.error.message);
            }
          );
          // this.addAffaireGarantie(this.bodyGarantie);
        }
      }
      if (this.upadateobjetAssureAffaire) {
        if (this.listObjetAssurer.length > 0) {
          const nouvelleListe = this.listObjetAssurer.map((element) => {
            return {
              [element.nom]: { index: element.index, data: element.form.data },
            };
          });
          this.apiOpportunitService.update_objet_assure_by_entity(this.affaire_id, 'affaire', nouvelleListe).subscribe(
            (response) => {
              this.alertSuccess('objet assurer mis à jour');
              this.upadateobjetAssureAffaire = false;
            },
            (error) => {
              this.alertError(error.error.message);
            }
          );
        } else {
          this.hideloader();
          this.alertError('vous devez ajouter au moins un objet à assurer');

          this.show_loading = false;
        }
      }
    } else {
      this.hideloader();
      this.alertError('Veuillez remplir les champ nom,prénom et e-mail de Souscripteur/ Adhérent');
      this.show_loading = false;
    }
  }

  getError($event) {
    this.errorProspect = $event;
  }

  uploadDocumentsAffaire(formData: FormData): void {
    this.apiDocumentsService.UploadDocuments(formData).subscribe(
      (response: any) => {
        this.apiDocumentsService.loadDocument$.next('refreshAffaire');
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  getcontratexport($event: AffaireDetails) {
    const jsonO = [];
    let test = false;
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'informations_Contrat') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['informations_Contrat'] = $event;

          // jsonO.push({ informationsContrat: $event });
        }
      });
    });
    if (!test) jsonO['informations_Contrat'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  onUpdateContrat($event: AffaireDetails) {
    this.affaireDetailsUpdate = $event;
    this.contratUpdated = true;
    this.getcontratexport($event);
  }

  submitInfoCompl($event: any) {
    this.infoComplementaire = $event;
    this.infoComplementaireUpdated = true;
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'infcamp') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Informations_Complementaire') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Informations_Complementaire'] = $event;

          // jsonO.push({ InformationsComplmentaire: $event });
        }
      });
    });
    if (!test) jsonO['Informations_Complementaire'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  submitInfoSpecifique($event: any) {
    this.infoSpecifique = $event;
    this.infoSpecifiqueUpdated = true;
    const jsonO = [];
    let test = false;
    const doc = $event;
    Object.keys(doc).forEach(function (value) {
      if (value === 'inf_prod') delete doc[value];
    });

    Object.assign($event, doc);
    // Object.assign(jsonO, this.jsonObject);
    jsonO.forEach((element, index) => {
      Object.keys(element).forEach(function (value) {
        if (value === 'Infomation_Specific') {
          jsonO.splice(index, 1);
          test = true;
          jsonO['Infomation_Specific'] = $event;
          // jsonO.push({ InfoSpecific: $event });
        }
      });
    });
    if (!test) jsonO['Infomation_Specific'] = $event;
    // Object.assign(this.jsonObject, jsonO);
  }

  onUpdateSociete($event: Entreprise) {
    this.entrepriseUpdated = true;
    this.entreprise = $event;
  }

  alertSuccess(data) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed || result.isDismissed) this.ngOnInit();
      });
  }

  alertWarning(title, invalidElements: any[]) {
    let listElementsAsString = '';
    invalidElements.forEach((element) => {
      if (typeof element.name === 'string')
        listElementsAsString =
          listElementsAsString +
          '<li style="list-style-type: none; font-size: 14px">' +
          element.name +
          ' : <u>' +
          element.value +
          '</u></li>';
    });
    swal.fire({
      title: title,
      icon: 'warning',
      html: '<ul class="p-0">' + listElementsAsString + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  errorInfo() {
    swal.fire({
      title: 'Aucune modification à enregistrer!',
      confirmButtonText: `Annuler`,
      icon: 'info',
    });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  scroll(el: string) {
    const element = document.getElementById(el);
    const offset = 120;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  Actualise() {
    window.location.reload();
  }

  getProspectDoublon(event: any) {
    if (event.target.checked === true) {
      this.idSelected = event.target.value;
      this.ListIdProspect[this.item] = this.idSelected;
      this.item++;
    } else {
      this.idSelected = event.target.value;
      this.deleteItem(this.idSelected);
      this.item--;
    }
  }

  deleteItem(element) {
    const index: number = this.ListIdProspect.indexOf(element);
    if (index !== -1) {
      this.ListIdProspect.splice(index, 1);
    }
  }

  setFussionProspectDoublon() {
    this.apiProspectsService.fussionProspectDoublon(this.ListIdProspect).subscribe(
      () => {
        this.ngOnInit();
        this.alertSuccess('Prospect Fussionner');
      },
      () => {
        this.alertError('Erreur');
      }
    );
  }

  ngOnDestroy(): void {
    this.apiOpportunitService.formdataaffaircomp = null;
    this.apiOpportunitService.formdataaffairspecif = null;
    if (this.isListe === false) {
      localStorage.removeItem('filtre');
      localStorage.removeItem('page');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('sSearch');
    }
  }

  /**
   * Permets de retourner à la liste
   */
  returnToList(): void {
    this.isListe = true;
    this.router.navigate(['affaires']);
  }

  getFormInfoSpecifique(form: any): void {
    this.formInformationSpecifique = form;
  }

  validateFormInformationSpecifique(): boolean {
    if (this.formInformationSpecifique !== undefined) {
      const invalidElements = [];
      for (const [key, value] of Object.entries(this.formInformationSpecifique.controls)) {
        const element: any = value;
        if (element.status === 'INVALID') {
          invalidElements.push({ name: key, value: element.value });
        }
      }
      if (this.formInformationSpecifique.status === 'INVALID') {
        this.alertWarning('Format des informations complementaire ligne de produits est invalide', invalidElements);
        this.apiAdminBpmServices.sharedFormIsValid = false;
        this.infoSpecifique = null;
        this.infoSpecifiqueUpdated = false;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  getAffaireGarantie() {
    this.apiAffairesService.getAffaireGarantieByID(this.affaire_id).subscribe((response) => {
      this.listeGarantieAffaire = response;
    });
  }

  addAffaireGarantie(body: any) {
    this.apiAffairesService.updateAffaireGarantie(body).subscribe(
      (response) => {
        this.alertSuccess('affaire garantie mis à jour');
      },
      (error) => {
        this.alertError(error.error.message);
      }
    );
  }

  onUpdateGaranties($event: any) {
    this.bodyGarantie = $event;
    this.upadateGarantieAffaire = true;
  }

  updateobjetAssure($event: boolean) {
    this.upadateobjetAssureAffaire = true;
  }
}
