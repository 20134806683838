import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { LigneProduit } from 'src/app/entity/Bpm/LigneProduits';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';
import { computeSegDraggable } from '@fullcalendar/core';
import { FormControl, Validators } from '@angular/forms';
import { L } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';

class ListeGroupGarantie {
  code_garantie: any;
  id_groupe: any;
  icon_garantie: any;
  nom_garantie: any;
  obligatoire: any;
}
@Component({
  selector: 'app-group-garantie-shared',
  templateUrl: './group-garantie-shared.component.html',
  styleUrls: ['./group-garantie-shared.component.css'],
})
export class GroupGarantieSharedComponent implements OnInit {
  listeOfUnsubscriptionWebService: Subscription[] = [];
  public garantieAutoComplete$: Observable<any[]> = null;
  fa = fa;
  grantieListe: any;
  loaderListGarantie: boolean = false;
  @Input() id_contrat: string = '';
  @Input() listeGroupefromParent;
  ligneProd: LigneProduit = new LigneProduit();
  ListG: any[] = [];
  garantie: any;
  liste: any[] = [];
  codeListe: any;
  listeOfUnsubscribeWebService: Subscription[] = [];
  listgroup: ListeGroupGarantie[] = [];
  listeGroup: ListeGroupGarantie[] = [];
  affectergroup: FormControl = new FormControl('', {
    validators: [Validators.required],
  });

  codeGarantie: FormControl = new FormControl('', {
    validators: [Validators.required],
  });

  obligatoire: any = 0;
  champsvalid: boolean = false;
  @Output() listeGroupFrom = new EventEmitter<any>();
  @Output() listeGroupFromP = new EventEmitter<any>();
  @Output() validChamps = new EventEmitter<any>();
  @Input() garanties_herite;

  constructor(
    private apiGarantieService: ApiGarantieService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiGroupGarantieService: ApiGroupGarantieService
  ) {}

  ngOnInit(): void {
    this.apiGroupGarantieService.getListGroupGarantie().subscribe((Response: any) => {
      this.garantie = Response;
    });
    this.listeGrantie();
    this.searchForgaranties();

    this.validChamps.emit(this.champsvalid);

    this.listeGroupFrom.emit(this.listgroup);
  }

  searchForgaranties() {
    this.searchgaranties = this.searchAddgarantie.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.type)),
      map((value) => {
        return this._filter(value);
      })
    );
  }

  _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();

    return this.grantieListe.filter((option) => {
      return (
        option.nom?.toLowerCase().indexOf(filterValue) > -1 || option.code?.toLowerCase().indexOf(filterValue) > -1
      );
    });
  }

  ngOnChanges() {
    // if(this.id_contrat !==''){
    // this.ListG = this.garanties_herite;
    //  this.ListG = this.listeGroupefromParent;
    // this.listgroup = this.listeGroupefromParent;

    this.ListG = this.garanties_herite;
    this.listgroup = this.listeGroupefromParent;
    this.listeGroupFromP.emit(this.ListG);
    this.listeGroupFrom.emit(this.listgroup);
  }

  grantieName: string;
  onSelectGarantie(item: any) {
    this.codeListe = [];
    const liste = new ListeGroupGarantie();
    liste.code_garantie = item.option.value.code;
    liste.id_groupe = '';
    liste.obligatoire = 0;
    liste.icon_garantie = item.option.value.icon;
    liste.nom_garantie = item.option.value.nom;

    // liste.icon_garantie =item.option.value.icon;
    for (const i of this.ListG) {
      this.codeListe.push(i.code_garantie, i.nom_garantie);
    }
    for (const i of this.listgroup) {
      this.codeListe.push(i.code_garantie, i.nom_garantie);
    }

    const index = this.codeListe.indexOf(item.option.value.code);
    if (index < 0) {
      this.listgroup.push(liste);
      this.listeGroupFrom.emit(this.listgroup);
    }

    // }
    // this.codeGarantie.setValue('')
  }

  listeGrantie() {
    const unsubscribeGetListGarantie = this.apiGarantieService.getListGarantie().subscribe((data) => {
      this.grantieListe = data;
      this.loaderListGarantie = true;
    });
    this.listeOfUnsubscriptionWebService.push(unsubscribeGetListGarantie);
  }

  getGarantieInfo(code, index) {
    this.listgroup.splice(index, 1);
    this.listeGroupFrom.emit(this.listgroup);
  }

  searchAddgarantie: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  searchgaranties: Observable<string[]>;

  onSelectGroup(item, i, index, x) {
    if (x === 'A') {
      this.listgroup[index].id_groupe = item.value;
      this.listeGroupFrom.emit(this.listgroup);
    } else {
      // this.ListG[index].id_groupe = item.value
      // const listeGaranteHerite = new ListeGroupGarantie();
      // listeGaranteHerite.code_garantie = this.ListG[index].code_garantie;
      // listeGaranteHerite.id_groupe = item.value;
      // listeGaranteHerite.icon_garantie = this.ListG[index].icon_garantie;
      // listeGaranteHerite.nom_garantie = this.ListG[index].nom_garantie;
      // listeGaranteHerite.obligatoire = this.obligatoire;
      // this.liste.push(listeGaranteHerite);
      this.ListG[index].id_groupe = item.value;
      // this.ListG[index].obligatoire = this.obligatoire;

      this.listeGroupFromP.emit(this.ListG);
    }
    console.log('thislistG', this.ListG);
  }

  displayFn(grantie: any): string {
    return grantie ? grantie.code : '';
  }

  isActive(event, item) {
    if (event.target.checked) {
      this.obligatoire = 1;
    } else {
      this.obligatoire = 0;
    }
    item.obligatoire = this.obligatoire;
    this.obligatoire = this.listeGroupFromP.emit(this.ListG);
    this.listeGroupFrom.emit(this.listgroup);
  }
}
