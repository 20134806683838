<div class="form-group">
    <mat-form-field class="full-width">
        <mat-label>{{field.templateOptions.label}}</mat-label>
        <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" matInput type="email" name="email" [formControl]="formControl" [errorStateMatcher]="matcher" [formlyAttributes]="field" placeholder="mon.email@email.com">
        <mat-icon matSuffix class="mat-suffix-email">alternate_email</mat-icon>
        <mat-error *ngIf="emailFormControl.hasError('required')">
            Mettez une adresse email valide (exemple: monemail@mail.com)
        </mat-error>
    </mat-form-field>
</div>
