import {Component, OnInit} from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit {
  listMenus: any[] = [
    {
      libelle: "libelle",
      url: "/test",
      icon: "faRoad",
    }
  ];

  listinggestion: any = `
  <label>Nouveau Listing Gestion :</label>
<div class="flex-column bg-white rounded-card">
  <div class="header-card-gestion py-3">
    <button type="button" class="btn-dt-add my-4 ml-4" routerLink="fiche/nouveau"  data-title="Ajouter une campagne">
      <fa-icon class="icon-btn-add" [icon]="fa['faPlus']"></fa-icon>
    </button>
  </div> 
  <div class="p-3 mb-4">
    <table class="table">
      <thead class="head-table">
        <tr>
          <th> Column1 </th>
          <th> Column2 </th>
          <th> Column2 </th>
          <th> Column3</th>
        </tr>
      </thead>
      <tbody class="body-table">
        <tr>
          <td>Body 1</td> 
          <td>Body 2</td>
          <td>Body 3</td>
          <td>Body 4</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>`;
  codeTableCourier: any = `

<table class="content-table">
<thead>
  <tr>
    <th>#<button id="launch">
        <img id="f1"class="fleche"  src="assets/imageSvg/arrow.svg">
        <img id="f2" class="fleche"  src="assets/imageSvg/arrow.svg">
      </button></th>
    <th>Nom<button id="launch">
      <img id="f1"class="fleche"  src="assets/imageSvg/arrow.svg">
      <img id="f2" class="fleche"  src="assets/imageSvg/arrow.svg">
      </button></th>
    <th>Sujet<button id="launch">
      <img id="f1"class="fleche"  src="assets/imageSvg/arrow.svg">
      <img id="f2" class="fleche"  src="assets/imageSvg/arrow.svg">
      </button></th>
    <th>Contenu<button id="launch">
      <img id="f1"class="fleche"  src="assets/imageSvg/arrow.svg">
      <img id="f2" class="fleche"  src="assets/imageSvg/arrow.svg">
      </button></th>
    <th>Action<button id="launch">
      <img id="f1"class="fleche"  src="assets/imageSvg/arrow.svg">
      <img id="f2" class="fleche"  src="assets/imageSvg/arrow.svg">
      </button></th>
  </tr>
</thead>
<tbody>
  <tr>
    <td>0</td>
    <td class="enGras">PJGUARD</td>
    <td><p>Concerne vos prochaines controles et fiscal URSSAF</p></td>
    <td>
        <p>IMPORTANT</p>
        <p>Concerne vos prochains controles...</p>
    </td>
    <td>

          <button id="Ouvrir">
            <span style="margin-left:15px;">ouvrir</span>
            <img class="icon-ouvrir"  src="assets/imageSvg/ouvrir.svg">
          </button>
    <!-- ----------------------------- -->
          <button id="details">
            <img class="rect-det"  src="assets/imageSvg/datailsRect.svg">
            <img class="stylo-det"  src="assets/imageSvg/details.svg">
          </button>
  </td>
  </tr>
  <tr>
    <td>1</td>
    <td class="enGras">Instance</td>
    <td>Instance Affaire()</td>
    <td>bojour nous vois informons que l'affaire...</td>
    <td><button id="Ouvrir">
        <span style="margin-left:15px;">ouvrir</span>
        <img class="icon-ouvrir"  src="assets/imageSvg/ouvrir.svg">
    </button>
    <!-- ----------------------------- -->
    <button id="details">
        <img class="rect-det"  src="assets/imageSvg/datailsRect.svg">
        <img class="stylo-det"  src="assets/imageSvg/details.svg">
    </button>
    </td>
  </tr>
  <tr>
    <td>2</td>
    <td class="enGras">Courrier de bienvenue</td>
    <td>Votre contrat Complémzntaire Santé</td>
    <td><p>SODEDIF ACTION SANTE vous rappelle le rensez-vous...</p></td>
    <td><button id="Ouvrir">
        <span style="margin-left:15px;">ouvrir</span>
        <img class="icon-ouvrir"  src="assets/imageSvg/ouvrir.svg">
    </button>
    <!-- ----------------------------- -->
    <button id="details">
        <img class="rect-det"  src="assets/imageSvg/datailsRect.svg">
        <img class="stylo-det"  src="assets/imageSvg/details.svg">
    </button></td>
  </tr>
  <tr>
    <td>3</td>
    <td class="enGras">Mail ESC</td>
    <td>Rappel de RDV</td>
    <td><p>Bonjour Madame,Monsieur</p>
       <p>vous avez consulté...</p>
    </td>
    <td><button id="Ouvrir">
        <span style="margin-left:15px;">ouvrir</span>
        <img class="icon-ouvrir"  src="assets/imageSvg/ouvrir.svg">
    </button>
    <!-- ----------------------------- -->
    <button id="details">
        <img class="rect-det"  src="assets/imageSvg/datailsRect.svg">
        <img class="stylo-det"  src="assets/imageSvg/details.svg">
    </button></td>
  </tr>
  <tr>
    <td >4</td>
    <td class="enGras">MAIL CONFIRMATION</td>
    <td>Confirmation du RDV</td>
    <td><p>Chere adhérente,cher adhéeant,</p>
        <p>nous avons..</p>
    </td>
    <td><button id="Ouvrir">
        <span style="margin-left:15px;">ouvrir</span>
        <img class="icon-ouvrir"  src="assets/imageSvg/ouvrir.svg">
    </button>
    <!-- ----------------------------- -->
    <button id="details">
        <img class="rect-det"  src="assets/imageSvg/datailsRect.svg">
        <img class="stylo-det"  src="assets/imageSvg/details.svg">
    </button>
</td>
  </tr>
</tbody>
</table>
`;
  codeLabel: any = `
<div class="Doublon-row">
    <div class="rejected nunitosans-bold-mandy-13px">Doublon</div>
  </div>

  <div class="Propsition-row">
    <div class="approved nunitosans-bold-robins-egg-blue-13px">Propsition</div>
  </div>

  <div class="Rappel-row">
    <div class="pending nunitosans-bold-pizazz-12px">Rappel</div>
  </div>

  <div class="Vente-row">
    <div class="approved nunitosans-bold-pine-13px">Vente</div>
  </div>

  <div class="Affectee-row">
    <div class="x12 nunitosans-bold-pizazz-13px">Affectée (création)</div>
  </div>
  <div class="planifie-row d-flex justify-content-end">
    <div class="Planifie-text">Planifié<div>
  </div>
`;
  codePagination: any = `
<div class="col-lg-12">
<div class="navbar-center" class="row">
  <div class="col">
    <div class="row">
      <div style="max-width: 50px" class="col">
        <p style="font-family: Nunito Sans; ">Afficher</p>
      </div>
      <div style="max-width: 50px ; margin-left: 5px;" class="col">
        <select class="select-style" class="select-entree" style="margin-top: -6px; padding-top: 2px;">
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div class="col">
        <p>Entrées</p>
      </div>
    </div>
  </div>
  <div class="col-3">
    <nav aria-label="Page navigation example">
      <ul class="pagination pagination-circle pg-blue">
        <li class="page-item">
          <a class="page-link-symbol">
            <span aria-hidden="true" style="font-size: 20px;">&laquo;</span>
          </a>
        </li>
        <li class="page-item " style="margin-left: 8px; margin-right: 8px;">
          <a class="page-link-symbol-chevron" aria-label="Previous">
            <i class="fa fa-chevron-left " style="margin-top: 8px;"></i>
          </a>
        </li>
        <ng-container>
          <li class="page-item">
            <a class="page-link">1
            </a>
          </li>
        </ng-container>
        <ng-container>
          <li class="page-item">
            <a class="page-link">4</a>
          </li>
        </ng-container>
        <li class="page-item">
          <a class="page-link">15
          </a>
        </li>
        <li class="page-item" style="margin-left: 8px; margin-right: 8px;">
          <a class="page-link-symbol-chevron" aria-label="Next">
            <i class="fa fa-chevron-right " style="margin-top: 8px;"></i>
          </a>
        </li>
        <li class="page-item">
          <a class="page-link-symbol">
            <span aria-hidden="true" style="font-size: 20px;">&raquo;</span></a>
        </li>
      </ul>
    </nav>
  </div>
</div>
`;
pagination_code: any = `
<h3>Pagination</h3>
<div class="navbar-center row">
  <div class="col pt-1">
    <div class="row w-50 flex-nowrap" >
      <div  class="col mt-1">
        <p>Afficher</p>
      </div>
      <div  class="col mx-1">
        <select  
        class="bg-white border pt-1 rounded font-weight-bold"
          >
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div class="col mt-1">
        <p>Entrées</p>
      </div>
    </div>
  </div>
  <div class="col-8">
    <nav aria-label="Page navigation example">
      <ul class="pagination pg-blue">
        <li class="page-item">
          <a class="page-link-symbol">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item  mx-2" >
          <a class="page-link-symbol-chevron" aria-label="Previous">
            <i class="fa fa-chevron-left "></i>
          </a>
        </li>
        <ng-container>
          <li class="page-item">
            <a class="page-link mt-2 border rounded">1
            </a>
          </li>
        </ng-container>
        <ng-container>
          <li class="page-item">
            <a class="page-link mt-2 border rounded">4</a>
          </li>
        </ng-container>
        <li class="page-item">
          <a class="page-link mt-2 border rounded">15
          </a>
        </li>
        <li class="page-item mx-2" >
          <a class="page-link-symbol-chevron" aria-label="Next">
            <i class="fa fa-chevron-right"></i>
          </a>
        </li>
        <li class="page-item ">
          <a class="page-link-symbol">
            <span aria-hidden="true">&raquo;</span></a>
        </li>
      </ul>
    </nav>
</div>
  <div class="col">
    <div class="row position-absolute flex-nowrap" style="right: 0px">
      <div class="col text-nowrap text-right mt-2">
        <p>Aller à la page N°  </p>
      </div>
      <div class="col mt-1 ml-2">
        <input type="number" value="1" class="page_input bg-white border rounded font-weight-bold">
      </div>
    </div>
  </div>
</div>
`;
  fa = fa;
  codeData_Table: any = `
  <div class="table-responsive p-3">
  <table class="table align-items-center" id="dataTable1">
    <thead class="head-table">
    <tr>
      <th>Actions</th>
      <th>
        Création 
        <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']"></fa-icon>
      </th>
      <th>
        Organisme
        <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']"></fa-icon>
      </th>
      <th>
        Zone & Code postal
        <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']"></fa-icon>
      </th>
      <th>
        Prospect
        <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']"></fa-icon>
      </th>
      <th>
        RDV
        <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']"></fa-icon>
      </th>
      <th>
        Status
        <fa-icon class="fa-lg " [icon]="fa['faSortAmountDownAlt']"></fa-icon>
      </th>
    </tr>
    </thead>
    <tbody class="body-table">
    <tr>
      <td>
        <div class="d-flex justify-content-center">
          <div class="pt-1">
            <button  type="button" class="btn-dt-list">
              <img src="/assets/icons/phone-icon.svg">
            </button>
          </div>
          <div class="pt-1">
            <button  type="button" class="btn-dt-list">
              <img src="/assets/icons/edit-icon.svg">
            </button>
          </div>
          <div>
            <input type='checkbox' id="perm" data-title="Text ici" class="star" />
            <label for="perm"></label>
          </div>
        </div>
      </td>
      <td>Leaders Leads
      </td>
      <td >
        78000 Versailles
      </td>
      <td>Victorine Monnier</td>
      <td>Sans RDV</td>
      <td>
        test
      </td>
      <td>
      test
      </td>
    </tr>
    </tbody>
  </table>
 </div>`;


  constructor() {
  }

  ngOnInit(): void {
  }

}
