<ng-container appearance="standard" class="p-3 w-70">
    <form class="example-form">
        <div class="shadow-box-card-body ">
      
            <div class="table-responsive p-3">
                <table class="table">
                    <thead class="head-table">
                        <tr>
                            <th>#</th>
                            <th>Garantie</th>
                            <th>Groupe garantie</th>
                            <th>Valeur</th>
                            <th>Nbr de sinistres/ans</th>
                            <th>Plafond/ans</th>
                            <th>Plafond/sinistre</th>

                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <!-- *ngFor="let i of ListG; let index = index" -->
                        <tr *ngFor="let i of ListG; let index = index" >
                            <td>{{index+1}}</td>
                            <td>{{i.code_garantie}}</td>
                            <td>{{i.libelle_groupe}}</td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text"  required (keyup)="valuechange($event, 'val' ,index )" (keypress)="numberOnly($event)" [value]="i.valeur ? i.valeur : 0">
                                    <span style="margin-top: 5px; position: absolute; right: 0;">{{i.unite}}</span>
                               </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text"  required  (keyup)="valuechange($event, 'nbr' ,index )" (keypress)="numberOnly($event)" [value]="i.Nb_de_sinistres_ans ? i.Nb_de_sinistres_ans : 0">
                               </mat-form-field>
                               

                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text"  required (keyup)="valuechange($event, 'P_ans', index)"  (keypress)="numberOnly($event)" [value]="i.Plafond_ans ? i.Plafond_ans : 0">
                                    <span style="margin-top: 5px; position: absolute; right: 0;">{{currency}}</span>
                               </mat-form-field>
                               

                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text" required (keyup)="valuechange($event , 'P_sinistre' ,index)" (keypress)="numberOnly($event)" [value]="i.Plafond_sinistre ? i.Plafond_sinistre : 0">
                                    <span style="margin-top: 5px; position: absolute; right: 0;">{{currency}}</span>
                               </mat-form-field>
                            </td>

                        </tr>

                    </tbody>
                </table>
              <p class="text-center" *ngIf="ListG?.length<1 ">
                    Pas des garanties
                </p>  
            </div>
        </div>
    </form>
</ng-container>
