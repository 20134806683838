export class User{
  private _Acces_Token;
  private _active;
  private _mail;
  private _error;
  private _id;
  private _nom;
  private _prenom;
  private _idSession;
  private _apiKeys;
  private _civilite;
  private _postal_code;
  private _tel;
  private _tel2;
  private _tel_urgence;
  private _mobile;
  private _telephone: string;
  private _adresse: string;
  private _date_naiss_user: string;
  private _api_key: string;
  private _matricule_rh: string;
  private _server_in_mail_data: string;
  private _identite_reelle: string;
  private _loginwebcalendar: string;
  private _password: string;
  private _samedi_off: string;
  private _server_out_mail_data: string;
  private _skype: string;
  private _signature: string;
  private _type: string;
  private _streetName: string;
  private _nom_pays: string;
  private _heure_fin: string;
  private _heure_debut: string;
  private _complement_adr: string;
  private _streetNumber: string;
  private _ville: string;
  private _port_in_mail_data: string;
  private _port_out_mail_data: string;
  private _ssl_mail_data: string;
  private _nom_comm: string; // ville
  private _user_mail_data: string;
  private _user_pwd_data: string;
  private _url_photo: string;
  private _location: string;
  private _lat: string;
  private _lng: string;
  private _geom: string;

  public get lat(): string {
    return this._lat;
  }
  public set lat(value: string) {
    this._lat = value;
  }
  public get lng(): string {
    return this._lng;
  }
  public set lng(value: string) {
    this._lng = value;
  }
  public get geom(): string {
    return this._geom;
  }
  public set geom(value: string) {
    this._geom = value;
  }
  public get location(): string {
    return this._location;
  }
  public set location(value: string) {
    this._location = value;
  }
  public get url_photo(): string {
    return this._url_photo;
  }
  public set url_photo(value: string) {
    this._url_photo = value;
  }
  public get user_mail_data(): string {
    return this._user_mail_data;
  }
  public set user_mail_data(value: string) {
    this._user_mail_data = value;
  }
  public get user_pwd_data(): string {
    return this._user_pwd_data;
  }
  public set user_pwd_data(value: string) {
    this._user_pwd_data = value;
  }

  public get port_in_mail_data(): string {
    return this._port_in_mail_data;
  }
  public set port_in_mail_data(value: string) {
    this._port_in_mail_data = value;
  }
  public get port_out_mail_data(): string {
    return this._port_out_mail_data;
  }
  public set port_out_mail_data(value: string) {
    this._port_out_mail_data = value;
  }
  public get ssl_mail_data(): string {
    return this._ssl_mail_data;
  }
  public set ssl_mail_data(value: string) {
    this._ssl_mail_data = value;
  }
  private _num_interne: string;
  public get num_interne(): string {
    return this._num_interne;
  }
  public set num_interne(value: string) {
    this._num_interne = value;
  }
  public get nom_comm(): string {
    return this._nom_comm;
  }
  public set nom_comm(value: string) {
    this._nom_comm = value;
  }

  public get telephone(): string {
    return this._telephone;
  }
  public set telephone(value: string) {
    this._telephone = value;
  }
  public get adresse(): string {
    return this._adresse;
  }
  public set adresse(value: string) {
    this._adresse = value;
  }
  public get date_naiss_user(): string {
    return this._date_naiss_user;
  }
  public set date_naiss_user(value: string) {
    this._date_naiss_user = value;
  }
  public get api_key(): string {
    return this._api_key;
  }
  public set api_key(value: string) {
    this._api_key = value;
  }
  public get matricule_rh(): string {
    return this._matricule_rh;
  }
  public set matricule_rh(value: string) {
    this._matricule_rh = value;
  }

  public get identite_reelle(): string {
    return this._identite_reelle;
  }
  public set identite_reelle(value: string) {
    this._identite_reelle = value;
  }
  public get loginwebcalendar(): string {
    return this._loginwebcalendar;
  }
  public set loginwebcalendar(value: string) {
    this._loginwebcalendar = value;
  }
  public get password(): string {
    return this._password;
  }
  public set password(value: string) {
    this._password = value;
  }
  public get server_in_mail_data(): string {
    return this._server_in_mail_data;
  }
  public set server_in_mail_data(value: string) {
    this._server_in_mail_data = value;
  }
  public get server_out_mail_data(): string {
    return this._server_out_mail_data;
  }
  public set server_out_mail_data(value: string) {
    this._server_out_mail_data = value;
  }
  public get samedi_off(): string {
    return this._samedi_off;
  }
  public set samedi_off(value: string) {
    this._samedi_off = value;
  }
  public get skype(): string {
    return this._skype;
  }
  public set skype(value: string) {
    this._skype = value;
  }
  public get signature(): string {
    return this._signature;
  }
  public set signature(value: string) {
    this._signature = value;
  }
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }
  public get ville(): string {
    return this._ville;
  }
  public set ville(value: string) {
    this._ville = value;
  }
  public get streetNumber(): string {
    return this._streetNumber;
  }
  public set streetNumber(value: string) {
    this._streetNumber = value;
  }
  public get streetName(): string {
    return this._streetName;
  }
  public set streetName(value: string) {
    this._streetName = value;
  }
  public get nom_pays(): string {
    return this._nom_pays;
  }
  public set nom_pays(value: string) {
    this._nom_pays = value;
  }


  public get heure_fin(): string {
    return this._heure_fin;
  }
  public set heure_fin(value: string) {
    this._heure_fin = value;
  }

  public get heure_debut(): string {
    return this._heure_debut;
  }
  public set heure_debut(value: string) {
    this._heure_debut = value;
  }

  public get complement_adr(): string {
    return this._complement_adr;
  }
  public set complement_adr(value: string) {
    this._complement_adr = value;
  }
  public get civilite() {
    return this._civilite;
  }
  public set civilite(value) {
    this._civilite = value;
  }
  public get mobile() {
    return this._mobile;
  }
  public set mobile(value) {
    this._mobile = value;
  }
  public get postal_code() {
    return this._postal_code;
  }
  public set postal_code(value) {
    this._postal_code = value;
  }
  public get tel() {
    return this._tel;
  }
  public set tel(value) {
    this._tel = value;
  }
  public get tel2() {
    return this._tel2;
  }
  public set tel2(value) {
    this._tel2 = value;
  }
  public get tel_urgence() {
    return this._tel_urgence;
  }
  public set tel_urgence(value) {
    this._tel_urgence = value;
  }
  get apiKeys() {
    return this._apiKeys;
  }

  set apiKeys(value) {
    this._apiKeys = value;
  }

  get Acces_Token() {
    return this._Acces_Token;
  }

  set Acces_Token(value) {
    this._Acces_Token = value;
  }

  get active() {
    return this._active;
  }

  set active(value) {
    this._active = value;
  }

  get mail() {
    return this._mail;
  }

  set mail(value) {
    this._mail = value;
  }

  get error() {
    return this._error;
  }

  set error(value) {
    this._error = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get nom() {
    return this._nom;
  }

  set nom(value) {
    this._nom = value;
  }

  get prenom() {
    return this._prenom;
  }

  set prenom(value) {
    this._prenom = value;
  }

  get idSession() {
    return this._idSession;
  }

  set idSession(value) {
    this._idSession = value;
  }
}
