export class Address {
  streetNumber: string = '';
  streetName: string = '';
  city: string = '';
  country: string = '';
  postalCode: string = '';
  supplement: string = '';
}

export class Company {
  RS: string = '';
  siren: string = '';
  address: Address;
}

export class Client {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  mobile: string;
  birthDate: string;
  type: string = 'Private';
  occupation: string = 'Other';
  comments: string;
  address: Address;
  company: Company;
  IBAN: string;
  AccountHolder: string;
}
