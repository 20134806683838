import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterUtilisateursTicketComponent } from './filter-utilisateurs-ticket/filter-utilisateurs-ticket.component';
import { FilterProspectTicketComponent } from './filter-prospect-ticket/filter-prospect-ticket.component';
import { FilterTicketTicketComponent } from './filter-ticket-ticket/filter-ticket-ticket.component';
import { FilterReclamationTicketComponent } from './filter-reclamation-ticket/filter-reclamation-ticket.component';
import { AngularMaterialModule } from 'src/app/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    FilterUtilisateursTicketComponent,
    FilterProspectTicketComponent,
    FilterTicketTicketComponent,
    FilterReclamationTicketComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule

  ],
  exports: [
    FilterUtilisateursTicketComponent,
    FilterProspectTicketComponent,
    FilterTicketTicketComponent,
    FilterReclamationTicketComponent
  ]
})
export class FilterTicketModule { }
