import { ContartActuelOpportunity } from './../../../../entity/Opportunity/ContratActuelOpportunity';
import { createAction, props } from '@ngrx/store';
import { Entreprise } from '../../../../entity/Opportunity/Entreprise';
import { DetailsOpportuniteStatus } from './details-opportunite.reducers';
import { GetOpportuniteDetails } from '../../../../entity/Opportunity/GetOpportuniteDetails';
import { Details } from 'src/app/entity/Opportunity/Details';


const UPDATE_PROSPECT = '[PROSPECT] update prospect';
const RESET_PROSPECT = '[PROSPECT] reset prospect';
const GET_PROSPECT = '[PROSPECT] get prospect';
const GET_PROSPECT_SUCCESS = '[PROSPECT] get prospect success';
const UPDATE_PROSPECT_SERVICE = '[PROSPECT] update prospect service';
const UPDATE_PROSPECT_SERVICE_SUCCESS = '[PROSPECT] update prospect service success';
const UPDATE_PROSPECT_SERVICE_FAIL = '[PROSPECT] update prospect service fail';
const GET_PROSPECT_FAIL = '[PROSPECT] get prospect fail';
const SAVE_DETAILS = '[DETAILS OPPORTUNITE] save details';
const SAVE_DETAILS_SUCCES = '[DETAILS OPPORTUNITE] save details';
const SAVE_DETAILS_FAIL = '[DETAILS OPPORTUNITE] save details';
const GET_DETAILS = '[DETAILS OPPORTUNITE] get details';
const GET_DETAILS_FAIL = '[DETAILS OPPORTUNITE] get details success fail';
const GET_DETAILS_SUCCESS = '[DETAILS OPPORTUNITE] get details success';
const UPDATE_AFFECTED_TO = '[DETAILS OPPORTUNITE] update affecté commercieaux';
const UPDATE_TYPE_RDV = '[DETAILS OPPORTUNITE] update Type RDV';
const UPDATE_DATE_RDV = '[DETAILS OPPORTUNITE] update date RDV';
const UPDATE_DATE_RDV_SUCCESS = '[DETAILS OPPORTUNITE] update date RDV success';
const UPDATE_DATE_RDV_FAIL = '[DETAILS OPPORTUNITE] update date RDV fail';
const UPDATE_INFO_SPECIF_SUCCESS = '[INFOS OPPORTUNITE] update info specifique success';
const UPDATE_INFO_SPECIF_FAIL = '[INFOS OPPORTUNITE] update info specifique fail';
const UPDATE_INFO_SPECIF = '[INFOS OPPORTUNITE] update info specifique';
const GET_INFO_SPECIF = '[INFOS OPPORTUNITE] get info specifique';
const GET_INFO_SPECIF_SUCCESS = '[INFOS OPPORTUNITE] get info specifique success';
const GET_INFO_SPECIF_FAIL = '[INFOS OPPORTUNITE] get info specifique fail';
const UPDATE_INFO_COMPL_SUCCESS = '[INFOS OPPORTUNITE] update info complementaire success';
const UPDATE_INFO_COMPL_FAIL = '[INFOS OPPORTUNITE] update info complementaire fail';
const UPDATE_INFO_COMPL = '[INFOS OPPORTUNITE] update info complementaire';
const GET_INFO_COMPL = '[INFOS OPPORTUNITE] get info complementaire';
const GET_INFO_COMPL_SUCCESS = '[INFOS OPPORTUNITE] get info complementaire success';
const GET_INFO_COMPL_FAIL = '[INFOS OPPORTUNITE] get info complementaire fail';
const GET_CONSTRACT_ACTUEL = '[INFOS OPPORTUNITE] get contrat actuel';
const UPDATE_CONSTRACT_ACTUEL = '[INFOS OPPORTUNITE] update contrat actuel';
const UPDATE_CONSTRACT_ACTUEL_SUCCESS = '[INFOS OPPORTUNITE] update contrat actuel success';
const UPDATE_CONSTRACT_ACTUEL_FAIL = '[INFOS OPPORTUNITE] update contrat actuel fail';
const GET_CONSTRACT_ACTUEL_FAIL = '[INFOS OPPORTUNITE] get contrat actuel fail';
const GET_CONSTRACT_ACTUEL_SUCCESS = '[INFOS OPPORTUNITE] get contrat actuel success';
const NOOP = '[INFOS OPPORTUNITE] Noop action';
const UPDATE_OBJET_Assurer = '[DETAILS OPPORTUNITE] update Objet assurer';
const UPDATE_OBJET_Assurer_SUCCESS = '[DETAILS OPPORTUNITE] update Objet assurer success';
const UPDATE_OBJET_Assurer_FAIL = '[DETAILS OPPORTUNITE] update Objet assurer fail';

export const ADD_PROSPECT = '[PROSPECT] add Prospect';

export const AddProspectAction = createAction(ADD_PROSPECT);
export const UpdateAffectedTo = createAction(UPDATE_AFFECTED_TO, props<{ payload: { commerciaux_id: string } }>());
export const UpdateTypeRdv = createAction(UPDATE_TYPE_RDV, props<{ payload: { type_rdv_id: number } }>());
export const UpdateDateRdv = createAction(UPDATE_DATE_RDV, props<{ payload: { start: string, end: string, id: string, type: string } }>());
export const UpdateDateRdvSuccess = createAction(UPDATE_DATE_RDV_SUCCESS);
export const UpdateDateRdvFail = createAction(UPDATE_DATE_RDV_FAIL);
export const UpdateOBJETAssurer = createAction(UPDATE_OBJET_Assurer, props<{ payload: { id_opp: string, listObjetAssurer: any[] } }>());
export const UpdateOBJETAssurerSuccess = createAction(UPDATE_OBJET_Assurer_SUCCESS);
export const UpdateOBJETAssurerFail = createAction(UPDATE_OBJET_Assurer_FAIL);
export const GetDetails = createAction(GET_DETAILS, props<{ payload: { opportuniteId: string } }>());
export const GetDetailsSuccess = createAction(GET_DETAILS_SUCCESS, props<{ payload: GetOpportuniteDetails }>());
export const GetDetailsFail = createAction(GET_DETAILS_FAIL, props<{ payload: { errorMsg: string } }>());
export const GetProspect = createAction(GET_PROSPECT, props<{ payload: { opportuniteId: string } }>());

export const ResetProspect = createAction(RESET_PROSPECT);
export const GetProspectSuccess = createAction(GET_PROSPECT_SUCCESS, props<{ payload: { entreprise: Entreprise } }>());
export const GetProspectFail = createAction(GET_PROSPECT_FAIL, props<{ payload: { errorMsg: string } }>());
export const UpdateProspectAction = createAction(UPDATE_PROSPECT, props<{ payload: Entreprise }>());
export const UpdateProspectService = createAction(UPDATE_PROSPECT_SERVICE, props<{ payload: Entreprise }>());
export const UpdateProspectServiceSuccess = createAction(UPDATE_PROSPECT_SERVICE_SUCCESS, props<{ payload: Entreprise }>());
export const UpdateProspectServiceFail = createAction(UPDATE_PROSPECT_SERVICE_FAIL, props<{ payload: { errorMsg: string } }>());
export const SaveDetails = createAction(SAVE_DETAILS, props<{ payload: DetailsOpportuniteStatus }>());
export const SaveDetailsSuccess = createAction(SAVE_DETAILS_SUCCES);
export const SaveDetailsFail = createAction(SAVE_DETAILS_FAIL);
/*Update details*/

export const UPDATE_DETAILS = '[DETAILS] Update details';

export const UpdateDetails = createAction(UPDATE_DETAILS, props<{ payload: Details }>());

/*InfoSpecifque*/
export const GetInfoSpecifique = createAction(GET_INFO_SPECIF, props<{ payload: { opportuniteId: string } }>());
export const UpdateInfoSpecifqueAction = createAction(UPDATE_INFO_SPECIF, props<{ payload: any }>());
export const UpdateInfoSpecifqueSuccess = createAction(UPDATE_INFO_SPECIF_SUCCESS);
export const UpdateInfoSpecifqueFail = createAction(UPDATE_INFO_SPECIF_FAIL, props<{ payload: { errorMsg: string } }>());
export const GetInfoSpecifqueFail = createAction(GET_INFO_SPECIF_FAIL, props<{ payload: { errorMsg: string } }>());
export const GetInfoSpecifqueSuccess = createAction(GET_INFO_SPECIF_SUCCESS, props<{ payload: any }>());

/*InfoCompelemntaire*/

export const GetInfoComplementaire = createAction(GET_INFO_COMPL, props<{ payload: { opportuniteId: string } }>());
export const UpdateInfoComplementaireAction = createAction(UPDATE_INFO_COMPL, props<{ payload: any }>());
export const UpdateInfoComplementaireSuccess = createAction(UPDATE_INFO_COMPL_SUCCESS);
export const UpdateInfoComplementaireFail = createAction(UPDATE_INFO_COMPL_FAIL, props<{ payload: { errorMsg: string } }>());
export const GetInfoComplementaireFail = createAction(GET_INFO_COMPL_FAIL, props<{ payload: { errorMsg: string } }>());
export const GetInfoComplementaireSuccess = createAction(GET_INFO_COMPL_SUCCESS, props<{ payload: any }>());


/*Contrat Actuel*/

export const GetConstractActuel = createAction(GET_CONSTRACT_ACTUEL, props<{ payload: { opportuniteId: string } }>());
export const UpdateConstractActuelAction = createAction(UPDATE_CONSTRACT_ACTUEL, props<{ payload: any }>());
export const UpdateConstractActuelSuccess = createAction(UPDATE_CONSTRACT_ACTUEL_SUCCESS);
export const UpdateConstractActuelFail = createAction(UPDATE_CONSTRACT_ACTUEL_FAIL, props<{ payload: { errorMsg: string } }>());
export const GetConstractActuelFail = createAction(GET_CONSTRACT_ACTUEL_FAIL, props<{ payload: { errorMsg: string } }>());
export const GetConstractActuelSuccess = createAction(GET_CONSTRACT_ACTUEL_SUCCESS, props<{ payload: any }>());

export const NoopAction = createAction(NOOP, props<{ payload: any }>());
