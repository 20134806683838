export class InformationsComplmentaire{
    
    id_opp:string;
    id_aff:string;
    jsonform:{};
    infcamp:[]
    constructor(init?: Partial<InformationsComplmentaire>) {
        Object.assign(this, init);
      }
    
}