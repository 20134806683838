import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Process} from '../../entity/Process';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent implements OnInit,OnChanges {
  @Input() listOfProcesses: Process [] = [];
  @Output() onClickStatus: EventEmitter<number> = new EventEmitter<number>();
  widthPercent:number=0;

  ngOnInit(): void {
  }

  StatusClicked(i: number) {
    this.onClickStatus.emit(i);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.widthPercent=100/this.listOfProcesses?.length
  }
}
