export class InformationsComplmentaireVelo {
  EndDateOfCover: string = '';
  EndDateOfPolicy: string = '';
  Excess: string = '';
  NVOption: string = '';
  PAOption: string = '';
  StartDateOfCover: string = '';
  StartDateOfPolicy: string = '';
  Formula: string = '';
  note: string = '';
  comment: string = '';
  AdministrationFees: string = '';
  TotalAmountAllTaxesIncluded: string = '';
  monthly_amount: string = '';
  isAssistance: string;
  TrackerGPS: any;
  constructor(init?: Partial<InformationsComplmentaireVelo>) {
    Object.assign(this, init);
  }
}
