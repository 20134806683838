export class InformationsSpecifiqueVelo {
  purchaseDate: string = '';
  purchasePrice: string = '';
  classification: string = '';
  type: string = '';
  usage: string = '';
  TrackerGPS: string = '';
  etchingNumber: string = '';
  maximumSpeed: string = '';
  serialNumber: string = '';
  brand: string = '';
  model: string = '';
  IBAN: string = '';
  AccountHolder: string = '';
  clientType: string = '';
  clientOccupation: string = '';
  Signature_url: string = '';
  eSignRef: string = '';
  opportunityRef: string = '';
  paymentRef: string = '';
  policyRef: string = '';
  quoteRef: string = '';
  storeRef: string = '';
  isAssistance: string = '';
  valeurVelo: string = '';
  CommercialLatitude: any;
  vol: any;
  dommages: any;
  assistance: string = '';
  zipCode: any;
  city: string = '';
  AdminstrationFees: Number;
  constructor(init?: Partial<InformationsSpecifiqueVelo>) {
    Object.assign(this, init);
  }
}
