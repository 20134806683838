export enum NotificationType {
  success,
  warning,
  error,
  info,
}

export class NotificationMessage {
  message: string;
  title: string;
  type: NotificationType;
  timeOut: number;
}
