import { DatePipe } from '@angular/common';
import { FilterMaileva } from './../../../entity/Maileva/FilterMaileva';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiComminucationService } from '../../../services/ApiComminucation/api-comminucation.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { MailevaElement } from '../../../entity/Maileva/MailevaElement';
import { MailEvaList } from '../../../entity/Maileva/MailEvaList';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import swal from 'sweetalert2';

import { startWith, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { parseDate } from 'src/app/shared/Utils/sharedFunctions';
import * as moment from 'moment';
@Component({
  selector: 'app-maileva',
  templateUrl: './maileva.component.html',
  styleUrls: ['./maileva.component.css']
})
export class MailevaComponent implements OnInit, OnDestroy {

  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  filtre_maileva: FilterMaileva = new FilterMaileva()

  fa = fa;
  pages = 1;
  totalPages = 0;
  ActifPage: number = 1;
  listeOfUnsubscribeWebService: Subscription[] = [];
  maileva: MailEvaList = new MailEvaList();
  sort_field: number = 1;
  sort_type: string = 'desc';
  limit: number = 15;
  Page: number = 1;
  intervale = 1;
  status_filter: string = '';
  sSearch: string = '';

  selectedMaileva: MailevaElement = new MailevaElement();
  myDate = new Date();
  date_fin: Date = new Date();
  date_debut: Date = new Date();
  constructor(private apiOpportunitService: ApiOpportunitService, private sharedMenuObserverService: SharedMenuObserverService,
    private apiComminucationService: ApiComminucationService, private apiAffairesService: ApiAffairesService, private router: Router,
    private datePipe: DatePipe) {
    this.date_debut = new Date('2000-01-01');
    this.date_fin = new Date('2100-01-01');

  }
  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    })
  }


  ngOnInit(): void {


    this.showloader();
    this.sharedMenuObserverService.updateMenu('Maileva');
    this.getListMaileva();

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }


  addValue(value) {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    if (value.length == 0) {
      this.options = [];
      if (this.sSearch.length > 0) {
        this.sSearch = "";
        this.first();
        this.getListMaileva();
      }

    } else {
      const unsubscribeGetListExpediteur = this.apiComminucationService.getListExpediteur(value).pipe(
        tap(({ names }) => {
          this.options = names
        })
      ).subscribe(); this.listeOfUnsubscribeWebService.push(unsubscribeGetListExpediteur);
    }
  }

  getSearchValue() {
    this.sSearch = this.myControl.value;

    this.getListMaileva();
    this.first();
  }


  getListMaileva() {
    this.showloader();
    this.filtre_maileva.courrier.date_debut_creation = this.filtre_maileva.courrier.date_debut_creation != "" ? this.filtre_maileva.courrier.date_debut_creation : this.datePipe.transform(this.date_debut, 'yyyy-MM-dd');
    this.filtre_maileva.courrier.date_fin_creation = this.filtre_maileva.courrier.date_fin_creation != "" ? this.filtre_maileva.courrier.date_fin_creation : this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');
    const unsubscribeListDetailsPostGET = this.apiComminucationService.listDetailsPostGET(
      this.Page,
      this.limit,
      this.sort_type,
      this.sort_field,
      this.filtre_maileva,
      this.status_filter,
      this.sSearch
    )
      .subscribe(
        (data: MailEvaList) => {
          this.maileva = data;
          this.maileva.aaData.forEach((element) => {
            var datemaielva = parseDate(
              element.date);
            element.date = datemaielva.toString();
          });
          this.calculate_nb_pages(data.iTotalDisplayRecords);
          this.hideloader();
        },
        (error) => this.hideloader()
      ); this.listeOfUnsubscribeWebService.push(unsubscribeListDetailsPostGET);
  }



  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.Page = this.intervale;
      if (this.Page <= this.totalPages) {

        this.getListMaileva();
      }
    }
  }


  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit != 0) {
      this.totalPages += 1;
    }
  }
  clickAffaire(id) {//href="/affaires/details/{{mail.id_affaire}}"
    this.getAffaireDetails(id);
    //this.router.navigate(["/affaires/details/"+id])
  }
  getAffaireDetails(affaire_id) {
    const unsubscribeGetAffaireGet = this.apiAffairesService.getAffaireGet(affaire_id)
      .pipe(
        tap(({ affaire, prospect }) => {
          let id_opportunite = affaire.id_opportunite
          let prospect_id = prospect.id
          let contrat_id = affaire.contrat.contrat_id
        })
      )
      .subscribe(
        data => {
          this.hideloader();
          this.router.navigate(["/affaires/details/" + affaire_id]);
        },
        error => {
          this.alertWarning('Aucun affaire ne correspand à cet id')
        }
      ); this.listeOfUnsubscribeWebService.push(unsubscribeGetAffaireGet);

  }
  clickOpportunite(id) {
    this.getOpportunite(id);
  }
  getOpportunite(dataid) {
    const unsubscribeGetOpportunite = this.apiOpportunitService.GetOpportunite(dataid)
      .subscribe(data => {
        this.router.navigate(["/opportunities/details/" + dataid])
      }, error => {
        this.alertWarning('Aucune opportunité ne correspand à cet id')
      }); this.listeOfUnsubscribeWebService.push(unsubscribeGetOpportunite);
  }
  alertWarning(data) {
    swal.fire({
      title: data,
      text: '',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    })
  }

  hideloader() {
    document.getElementById('loadingBar')
      .style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar')
      .style.display = 'block';
  }

  setSortField(sort_field: number) {
    this.showloader();
    if (this.sort_field === sort_field) {
      this.toggleSortType();
    } else {
      this.sort_field = sort_field;
      this.sort_type = 'desc';
    }
    this.getListMaileva();
  }

  setStatusFilter(status_filter: string) {
    this.showloader();
    if (this.status_filter === status_filter) {
      this.hideloader()
      return
    } else {
      this.status_filter = status_filter;
    }
    this.getListMaileva();
  }

  onChangeLimit() {
    this.Page = 1;
    this.showloader();
    this.getListMaileva();
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.Page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.Page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.Page = val;
    this.getListMaileva();
  }

  isFirst(): boolean {
    return this.Page === 1;
  }

  isLast(): boolean {
    return this.Page === this.totalPages;
  }


  ChangePage(index: number) {
    this.Page = index;
    this.getListMaileva();
  }

  checkSort(number: number) {
    if (this.sort_field == number && this.sort_type === 'asc') {
      return true;
    } else {
      return false;
    }
  }



  private toggleSortType() {
    if (this.sort_type == 'asc') {
      this.sort_type = 'desc';
    } else {
      this.sort_type = 'asc';
    }
  }

  select_to_preview(mail: MailevaElement) {
    this.selectedMaileva = mail;
  }

  format_date(date: string) {
    if (date) {
      let date_str = date.split(' ')[0].split('/');
      let datedeux = new Date(+date_str[2], +date_str[1] - 1, +date_str[0]);
      return datedeux;
    } else {
      return null;
    }
  }

  updateListMaileva() {
    this.Page = 1;
    this.getListMaileva();
  }
  setSearchFilter() {

    this.getListMaileva()

  }

  EmitFilters($event: FilterMaileva) {

    this.filtre_maileva = $event;
  }
}


