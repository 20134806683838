<form (ngSubmit)="EnregistrerCompanie(myForm.form.valid, myForm._directives)" #myForm="ngForm">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9" style="margin-top: 8px;">
                <p>
                    Compagnie n° :
                    <label class="label-card-info">
            {{initialCompagnie.siren}}
          </label>
                </p>
                <p>
                    Raison Sociale :
                    <label class="label-card-info">
            {{initialCompagnie.denomination}}
          </label>
                </p>
            </div>
            <div class="col-3 text-left">
                <p> Numéro Orias :
                    <label class="label-card-info">
            {{initialCompagnie.matricule}}
          </label>
                </p>
                <p> Type :
                    <label class="label-card-info">
            {{initialCompagnie.types}}
          </label>
                </p>
            </div>
        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button class="btn-dt-retour" (click)="retourToList()" type="button">
        <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        Retour à la liste
      </button>
            <div class="row">
                <div class=" row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                    <div class="col" style="margin-right: 10px;">
                        <button type="button" class="btn-load" (click)="clearForm()">
              <fa-icon [icon]="fa['faSyncAlt']"></fa-icon>
            </button>
                    </div>
                </div>
                <button class="btn-dt-save" type="submit">
          <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
          Enregistrer
        </button>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="row">
            <div class="col-12">
                <span style="color: red;">( * ) champs obligatoire!</span>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group" *ngIf="ModeAjout">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Siren</mat-label>
                        <input type="number" matInput [(ngModel)]="Compagnie.siren" (keypress)="numberlength($event)" name="siren" placeholder="123 456 789" required #siren="ngModel" min="0" pattern="([0-9]{9})">
                        <mat-error><span> SIREN invalid doit contenir 9 chiffres </span></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <label>logo</label>
                <div class="row">
                    <div class="col-2 pt-1 px-3 border-right-0 border border-primary">
                        <fa-icon class="fa fa-2x pt-3 pl-0" [icon]="fa['faImage']"></fa-icon>
                    </div>
                    <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                        <input type="file" id="idlogo" accept="image/*" (change)="getLogo($event)" hidden />
                        <label for="idlogo" class="select-label py-3 pl-4">
              Sélectionner logo
            </label>
                    </div>
                    <div class="col-2 pt-3 pl-3 border-left-0 border border-primary">
                        <img class="img" data-toggle="modal" data-target="#Actions" src="{{show_image}}">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Numéro orias</mat-label>
                        <input matInput [(ngModel)]="Compagnie.matricule" type="number" name="numo" required #numo="ngModel">
                    </mat-form-field>
                    <div *ngIf="numo.invalid && (numo.dirty || numo.touched)" class="message-erreur">
                        <div *ngIf="numo.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le Numéro orias est obligatoire!
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Raison sociale</mat-label>
                        <input type="text" matInput [(ngModel)]="Compagnie.denomination" name="raison" required #raison="ngModel">
                    </mat-form-field>
                    <div *ngIf="raison.invalid && (raison.dirty || raison.touched)" class="message-erreur">
                        <div *ngIf="raison.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            La Raison Sociale est obligatoire!
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-4 col-sm-12">
                <div class="form-group">

                    <app-auto-complete-adresses  [isReadOnlyAdresse]="true" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                    </app-auto-complete-adresses>
                </div>
            </div>

        </div>
        <div class="row">

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Type</mat-label>
                        <input matInput [(ngModel)]="Compagnie.types" required #type="ngModel" name="type">
                    </mat-form-field>
                    <div *ngIf="type.invalid && (type.dirty || type.touched)" class="message-erreur">
                        <div *ngIf="type.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le Type est obligatoire!
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Code Postal</mat-label>
                        <input type="number" matInput [(ngModel)]="Compagnie.cp" required #cp="ngModel" name="cp">
                    </mat-form-field>
                    <div *ngIf="cp.invalid && (cp.dirty || cp.touched)" class="message-erreur">
                        <div *ngIf="cp.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le Code Postal est obligatoire!
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Ville</mat-label>
                        <input matInput [(ngModel)]="Compagnie.ville" required #ville="ngModel" name="ville">
                    </mat-form-field>
                    <div *ngIf="ville.invalid && (ville.dirty || ville.touched)" class="message-erreur">
                        <div *ngIf="ville.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            La Ville est obligatoire!
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Lien</mat-label>
                        <input matInput [(ngModel)]="Compagnie.url" required #url="ngModel" pattern="https?://.+" name="url">
                    </mat-form-field>
                    <div *ngIf="url.invalid && (url.dirty || url.touched)" class="message-erreur">
                        <div *ngIf="url.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le Lien est obligatoire!
                        </div>
                        <div *ngIf="url.errors.pattern">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Format invalide ! (exemple : https://...)
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Email de production</mat-label>
                        <input type="email" placeholder="exemple@email.com" matInput pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [(ngModel)]="Compagnie.mail_production" required #mail_production="ngModel" name="mail_production">
                    </mat-form-field>
                    <div *ngIf="mail_production.invalid && (mail_production.dirty || mail_production.touched)" class="message-erreur">
                        <div *ngIf="mail_production.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            L'Email de production est obligatoire!
                        </div>
                        <div *ngIf="mail_production.errors.pattern">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Format Email invalide!
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Email commercial</mat-label>
                        <input type="email" matInput placeholder="exemple@email.com" [(ngModel)]="Compagnie.mail_commercial" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="mail_commercial" required #mail_commercial="ngModel">
                    </mat-form-field>
                    <div *ngIf="mail_commercial.invalid && (mail_commercial.dirty || mail_commercial.touched)" class="message-erreur">
                        <div *ngIf="mail_commercial.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            L'Email commercial est obligatoire!
                        </div>
                        <div *ngIf="mail_commercial.errors.pattern">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Format Email invalide!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width" style="margin-top: -6px;">
                        <mat-label>Email SAV</mat-label>
                        <input type="email" matInput placeholder="exemple@email.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [(ngModel)]="Compagnie.mail_sav" name="mail_sav" required #mail_sav="ngModel">
                    </mat-form-field>
                    <div *ngIf="mail_sav.invalid && (mail_sav.dirty || mail_sav.touched)" class="message-erreur">
                        <div *ngIf="mail_sav.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            L'Email SAV est obligatoire!
                        </div>
                        <div *ngIf="mail_sav.errors.pattern">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Format Email invalid!
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field class="full-width" appearance="fill">
                        <label>Téléphone de production  <span style="color: red;">*</span></label>
                        <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_production" name="tel_production" required #tel_production="ngModel" [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telProd')">
                        <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                    </mat-form-field>
                    <div *ngIf="tel_production.invalid && (tel_production.dirty || tel_production.touched)" class="message-erreur">
                        <div *ngIf="tel_production.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le Téléphone de Production est obligatoire!
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field class="full-width" appearance="fill">
                        <label>Téléphone SAV <span style="color: red;">*</span></label>
                        <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_sav" name="tel_sav" required #tel_sav="ngModel" [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telsav')">
                        <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                    </mat-form-field>
                    <div *ngIf="tel_sav.invalid && (tel_sav.dirty || tel_sav.touched)" class="message-erreur">
                        <div *ngIf="tel_sav.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le Téléphone SAV est obligatoire!
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field class="full-width" appearance="fill">
                        <label>Téléphone commercial<span style="color: red;">*</span></label>
                        <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_commercial" name="tel_commercial" required #tel_commercial="ngModel" [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telcomm')">
                        <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                    </mat-form-field>
                    <div *ngIf="tel_commercial.invalid && (tel_commercial.dirty || tel_commercial.touched)" class="message-erreur">
                        <div *ngIf="tel_commercial.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le Téléphone Commercial est obligatoire!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content full-width">
                <div class="modal-header">
                    <h2 style="margin-left: 15px">Logo du Compagnie</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="justify-content: center;">
                        <img class="img" style="width: 200px; height: 200px;" src="{{show_image}}">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</form>
