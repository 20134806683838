import { Garantie } from './../../../entity/Garantie';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { ApiGarantieService } from 'src/app/services/ApiGarantie/api-garantie.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-garantie',
  templateUrl: './add-garantie.component.html',
  styleUrls: ['./add-garantie.component.css'],
})
export class AddGarantieComponent implements OnInit {
  fa = fa;
  grantieForm: FormGroup;
  ModeAjout: boolean;
  garantie_id: string;
  listeOfUnsubscribeWebService: Subscription[] = [];
  garantie: Garantie = new Garantie();
  show_loading_add: boolean = false;
  icon: string ='fa';
  currency : string;
  constructor(
    private routes: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private route: ActivatedRoute,
    private apiGarantieService: ApiGarantieService
  ) {}

  ngOnInit(): void {
    this.currency = environment.currentCurrency;
    this.sharedMenuObserverService.updateMenu('Gestion des garanties ➜ Ajouter');

    this.grantieForm = new FormGroup({
      description: new FormControl(''),
      code: new FormControl('', [Validators.required]),
      unite: new FormControl('', [Validators.required]),
      nom: new FormControl('', [Validators.required]),
      cotisation: new FormControl(false),
      icon: new FormControl(''),
      capitaux_assure: new FormControl(false),


    });
    this.route.paramMap.subscribe((params) => {
      if (params.get('id') !== 'nouveau') {
        this.sharedMenuObserverService.updateMenu('Gestion des garanties ➜ Détails');
        this.ModeAjout = false;
        const unsubscribeGetGarantieById = this.apiGarantieService
          .getGarantieById(params.get('id'))
          .subscribe((data: any) => {
            this.garantie = data;

            this.garantie_id = params.get('id');
            this.grantieForm.get('code').setValue(this.garantie[0].code);
            this.grantieForm.get('description').setValue(this.garantie[0].description);
            this.grantieForm.get('cotisation').setValue(this.garantie[0].cotisation);
            this.grantieForm.get('unite').setValue(this.garantie[0].unite);
            this.grantieForm.get('nom').setValue(this.garantie[0].nom);
            this.grantieForm.get('capitaux_assure').setValue(this.garantie[0].capitaux_assure);
            if(this.garantie[0].icon !== '' && this.garantie[0].icon!== null){
            this.grantieForm.get('icon').setValue(this.garantie[0].icon);
            this.icon =this.garantie[0].icon;
          }else{
            this.icon = 'fa';
          }
          });
        this.listeOfUnsubscribeWebService.push(unsubscribeGetGarantieById);
      } else {
        this.ModeAjout = true;
      }
    });
  }

  add() {
    if (this.grantieForm.valid) {
      if (this.ModeAjout) {
        const unsubscribeInsertGarantie = this.apiGarantieService.addGarantie(this.grantieForm.value).subscribe(
          (Response) => {
            this.show_loading_add = false;
            this.alertSuccess(Response.message, Response.code, true);
          },
          (err) => {
            console.log(err);
            if (err.status=== 304){
              this.alertError("Erreur lors de l'ajout de la garantie", "Code garantie déjà existe !");
              this.show_loading_add = false;
            }else{
              this.alertError("Erreur lors de l'ajout de la garantie", "Quelque chose s'est mal passé!");
              this.show_loading_add = false;
            }

          }
        );
        this.listeOfUnsubscribeWebService.push(unsubscribeInsertGarantie);
      } else {
        const unsubscribeUpdateGarantie = this.apiGarantieService
          .UpdateGarantie(this.grantieForm.value, this.garantie_id)
          .subscribe(
            (Response) => {
              this.show_loading_add = false;
              this.alertSuccess(Response.message, Response.id, false);
            },
            (err) => {
              console.log(err);
              if (err.status === 304) {
                this.alertError('Erreur lors de la modification de la garantie', 'Code garantie déjà existe!');
                this.show_loading_add = false;
              } else {
                this.alertError('Erreur lors de la modification de la garantie', "Quelque chose s'est mal passé!");
                this.show_loading_add = false;
              }
            }
          );
        this.listeOfUnsubscribeWebService.push(unsubscribeUpdateGarantie);
      }
    } else {
      this.alertError('Formulaire non valide', 'Merci de remplire les champs obligatoires');
    }
  }

  retourToList() {
    this.routes.navigate(['/garantie']);
  }

  clearForm() {
    this.grantieForm.get('description').setValue('');
    this.grantieForm.get('code').setValue('');
    this.grantieForm.get('unite').setValue('');
    this.grantieForm.get('nom').setValue('');
    this.grantieForm.get('cotisation').setValue(false);
    this.grantieForm.get('icon').setValue(false);

  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertSuccess(data, idGarantie, modeedit) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: 'Nouvelle Garantie',
        denyButtonColor: '833626',
        cancelButtonText: 'Modifier Garantie',
        confirmButtonText: 'Liste des Garanties',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['/garantie']);
        }
        if (result.isDismissed) {
          this.hideloader();
          if (this.ModeAjout) this.routes.navigate(['/add-garantie/' + idGarantie]);
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['/add-garantie/nouveau']).then(() => {
            window.location.reload();
          });
        }
      });
  }


  alertError(data, err?) {
    swal.fire({
      title: data,
     // text: "Quelque chose s'est mal passé!",
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  onSelectIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon =event;

  }

  onSelectNameIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon =event;

  }

}
