import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Comments } from 'src/app/entity/Comments';
import { CommentaireServicesService } from 'src/app/services/ApiCommentaires/commentaire-services.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';

@Component({
  selector: 'app-add-commentaire',
  templateUrl: './add-commentaire.component.html',
  styleUrls: ['./add-commentaire.component.css'],
})
export class AddCommentaireComponent implements OnInit {
  @Output() commentaireExport: EventEmitter<any> = new EventEmitter();
  addCommentaire: boolean = false;
  listComments: Comments[] = [];
  fa = fa;
  form: FormGroup;
  prospect_id: string;
  comment: any;
  show_loading_add: boolean = false;
  entity_id: string = '';
  fileList: File[] = [];
  index: number = 0;
  @Input() fromType;
  @Input() id_opportunite;
  @Input() allowed_to_update;
  file: any;
  ListFiles: any;
  constructor(
    private fb: FormBuilder,
    private routes: ActivatedRoute,
    private apiTicketsServices: ApiTicketsService,
    private notificationsService: NotificationsService,
    private apiCommentraieService: CommentaireServicesService
  ) {}

  ngOnInit(): void {
    this.show_loading_add = true;
    this.routes.paramMap.subscribe((params) => {
      if (params.get('affaire_id')) {
        this.entity_id = params.get('affaire_id');
      } else if (params.get('tickets_id')) {
        this.entity_id = params.get('tickets_id');
      } else {
        this.entity_id = this.id_opportunite;
      }

      this.getComments(this.entity_id, this.fromType);
    });

    this.form = this.fb.group({
      comment: [''],
    });
  }

  getComments(id_entity, entity) {
    this.show_loading_add = true;
    this.apiCommentraieService.getListCommentaire(id_entity, entity).subscribe(
      (data: Comments[]) => {
        this.show_loading_add = false;
        this.listComments = data.reverse();
        this.commentaireExport.emit(this.listComments);
        this.show_loading_add = false;
      },
      (error) => {
        console.log(error);
        this.show_loading_add = false;
      }
    );
  }

  onSubmit() {
    const formData: FormData = new FormData();
    formData.append('entity', this.fromType);
    formData.append('id_entity', this.entity_id);
    formData.append('commentaire', this.form.value.comment);
    for (let index = 0; index < this.fileList.length; index++) {
      formData.append('docs', this.fileList[index]);
    }
    this.apiCommentraieService.createCommentaire(formData).subscribe(
      (response) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = 'Opération réussite';
        notificationMessage.message = response.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.form.reset();
        this.getComments(this.entity_id, this.fromType);
        this.show_loading_add = false;
      },
      (error: any) => {
        if (error.status === 406) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = 'Aucun add_prospect ne correspant';
          this.notificationsService.sendMessage(notificationMessage);
          this.show_loading_add = false;
        } else {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = 'Erreur';
          this.notificationsService.sendMessage(notificationMessage);
          this.show_loading_add = false;
        }
      }
    );

    this.show_loading_add = true;
    this.addCommentaire = false;
    this.comment = {
      entity: this.fromType,
      id_entity: this.entity_id,
      commentaire: this.form.value.comment,
    };
    this.fileList = [];
    // this.apiCommentraieService.createCommentaire(this.comment)
    //   .subscribe(
    //     (response) => {
    //       let notificationMessage = new NotificationMessage();
    //       notificationMessage.type = NotificationType.success
    //       notificationMessage.title = "Opération réussite";
    //       notificationMessage.message = response.message ;
    //       this.notificationsService.sendMessage(notificationMessage)
    //       this.form.reset();
    //       this.getComments(this.entity_id ,this.fromType );
    //       this.show_loading_add = false
    //     },
    //     (error: any) => {
    //       if (error.status === 406) {
    //         let notificationMessage = new NotificationMessage();
    //         notificationMessage.type = NotificationType.error
    //         notificationMessage.title = "Opération echoué";
    //         notificationMessage.message = "Aucun add_prospect ne correspant" ;
    //         this.notificationsService.sendMessage(notificationMessage)
    //         this.show_loading_add = false
    //       }
    //       else {
    //         let notificationMessage = new NotificationMessage();
    //         notificationMessage.type = NotificationType.error
    //         notificationMessage.title = "Opération echoué";
    //         notificationMessage.message = "Erreur" ;
    //         this.notificationsService.sendMessage(notificationMessage)
    //         this.show_loading_add = false
    //       }
    //     });
  }

  AddComm() {
    this.addCommentaire = !this.addCommentaire;
  }

  remouvefile(file) {
    console.log(file);
    console.log(this.fileList);
    this.fileList.forEach((element, index) => {
      if (element.name === file.name) this.fileList.splice(index, 1);
    });
  }

  onFilechange(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].type.includes('image/')) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          event.target.files[i].path = reader.result;
          event.target.files[i].types = 'image';
        };
      } else {
        event.target.files[i].types = 'file';
        event.target.files[i].path = '';
      }
      this.fileList.push(event.target.files[i]);
    }
  }

  expandFileName(name: string): string {
    if (name.length < 10) {
      return name;
    } else {
      return name.substring(16, name.length);
    }
  }
}
