<div class="row" style="margin-top: 15px">
    <div class="col-6">
        <div class="form-group">
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>N° Ticket</mat-label>
                    <input type="text" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre" #nombre="ngModel" (click)="EmitFilterNumTickets()" name="num" [(ngModel)]="ticket.recheche_num_ticket" matInput>
                </mat-form-field>
                <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
                    <div *ngIf="nombre.errors.pattern">
                        <i class="fa fa-exclamation-triangle"></i>&nbsp;Seulement des chiffres !
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Titre du ticket </mat-label>
                    <input type="text" (click)="EmitFilterNumTickets()" name="titre" [(ngModel)]="ticket.recheche_sujet_ticket" matInput>
                </mat-form-field>
            </form>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-date-range-input [rangePicker]="pickerDC">
                    <input matStartDate [(ngModel)]="date_debut_creation" placeholder="Date de création du" />
                    <input matEndDate [(ngModel)]="date_fin_creation" placeholder="à" />

                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerDC"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterNumTickets()" #pickerDC></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-date-range-input [rangePicker]="pickerDT">
                    <input matStartDate [(ngModel)]="date_debut_traitement" placeholder="Date de traitement du" />
                    <input matEndDate [(ngModel)]="date_fin_traitement" placeholder="à" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerDT"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterNumTickets()" #pickerDT></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-date-range-input [rangePicker]="pickerDL">
                    <input matStartDate [(ngModel)]="date_debut_limit" placeholder="Date limite du" />
                    <input matEndDate [(ngModel)]="date_fin_limit" placeholder="à" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pickerDL"></mat-datepicker-toggle>
                <mat-date-range-picker (closed)="EmitFilterNumTickets()" #pickerDL></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="example-full-width" style="margin-top: -6px;">
                <mat-select [(ngModel)]="selected2" (selectionChange)="EmitFilterNumTickets()" name="status" placeholder="Statuts">
                    <mat-option *ngFor="let item of status" [value]="item.id">
                        {{item.libelle}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>