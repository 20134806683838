import {FormControl} from "@angular/forms";

export class IssueGitlab {
  private _title: string = "";
  private _labels: string = "FromFront";
  private _nom: string = "";
  private _description: string = "";


  constructor(init?: Partial<FormControl>) {
    Object.assign(this, init);
  }


  get nom(): string {
    return this._nom;
  }

  set nom(value: string) {
    this._nom = value;
  }

  get title() {
    return this._title;
  }

  set title(value) {
    this._title = value;
  }

  get labels() {
    return this._labels;
  }

  set labels(value) {
    this._labels = value;
  }

  get description() {
    return this._description;
  }

  set description(value) {
    this._description = value;
  }
}
