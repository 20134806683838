import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Prospect } from '../../../../../entity/Prospect';
import { AutoFillEntity } from '../../../../../entity/Opportunity/AutoFillEntity';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiProspectsService } from '../../../../../services/ApiProspects/api-prospects.service';
import { map, tap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from "rxjs/internal/operators";
import { Observable, Subject } from 'rxjs';
import { Entreprise } from '../../../../../entity/Opportunity/Entreprise';
import { ProspectAdd } from '../../../../../entity/Opportunity/ProspectAdd';
import { ProspectFinderService } from 'src/app/services/prospect-finder.service';
import { addOpportuniteStore, addOpportuniteTypeProspectEnum } from '../../store/add-opportunite.reducers';
import { Store } from '@ngrx/store';
import * as addOppActions from '../../store/add-opportunite.actions';
import { FillOldProspect, SelectOldProspect, UpdateProspectAction, FillOldEntrprise } from '../../store/add-opportunite.actions';
import { OpportunitiesDetailsComponent } from '../../../details-opportunite/opportunities-details.component';
@Component({
  selector: 'app-add-prospect',
  templateUrl: './prospect-add.component.html',
  styleUrls: ['./prospect-add.component.css']
})
export class ProspectAddComponent implements OnInit {
  fa = fa;
  step = 0;
  isPro: boolean = false;
  selectedProspect = new Prospect();
  selected_prospect_id = '';
  prospect_particulier = new Prospect();
  prospect_add: ProspectAdd = new ProspectAdd();
  formGroup: FormGroup;
  list_choix = [
    {
      'key': 'Adhérent/Souscripteur particulier',
      'value': addOpportuniteTypeProspectEnum.PARTICULIER
    },
    {
      'key': 'Adhérent/Souscripteur professionnel',
      'value': addOpportuniteTypeProspectEnum.ENTREPRISE
    }];

  listProspects: Prospect[] = [];
  listProspects_str: string[] = [];
  prospect_principale: Prospect = new Prospect();
  list_auto_fill_options: AutoFillEntity[] = [];
  filteredOptions: Observable<string[]>;
  nomprenom: any;
  prospectForm: FormGroup;
  seartch_str: string = '';
  choix: addOpportuniteTypeProspectEnum = null;
  loadingAutoCmplt: Boolean = false;

  typeProspect$: Observable<any> | null = null;
  mainProspect$: Observable<any> | null = null;
  listProspects$: Observable<any> | null = null;
  listProspectsPro$: Observable<any> | null = null;
  entreprise$: Observable<Entreprise> | null = null;
  prospectPro$: Observable<any> | null = null;
  formMainProspect$: Observable<any> | null = null;
  formProspect$: Observable<any> | null = null;
  entrepriseRecherche: Entreprise = new Entreprise();
  getSearchResult: any;
  telTxtQueryChanged: Subject<string> = new Subject<string>();
  telCountry = environment.country;
  @Input() modeupdateProspect ;
  @Input() person ;
  keyFormProspect: string = environment.keyFormProspect;
  keyFormEntreprise: string = environment.keyFormEntreprise;

  prospectSchema: any = null;
  EntrepriseSchema:  any = null;
  constructor(
    private formBuilder: FormBuilder,
    private apiProspectsService: ApiProspectsService,
    private store$: Store<addOpportuniteStore>,
    private apiConfigurationService: ApiConfigurationService
  ) {
    this.initObservers();
    this.initFormProspect();

    // Subject to watch tel input
    this.telTxtQueryChanged
      .pipe(debounceTime(750), distinctUntilChanged())
      .subscribe((tel) => {
        this.setValueSelectedProspect("")
        if (tel && this.formGroup.controls['telephone'].valid) {
          this.loadingAutoCmplt = true;
          this.apiProspectsService.listProsWithTelGet(tel).pipe(
            tap(({ results }) => {
              this.list_auto_fill_options = results;
            })
          ).subscribe(data => this.loadingAutoCmplt = false,
            error => this.loadingAutoCmplt = false);
        } else {
          this.list_auto_fill_options = null;
        }
      });

  }


  private initObservers() {
    this.typeProspect$ = this.store$.pipe(
      map(
        state => {
          this.choix = state.addOpportunite.typeProspect;
          return state.addOpportunite.typeProspect;
        }
      )
    );
    this.mainProspect$ = this.store$.pipe(
      map(
        state => state.addOpportunite.prospectParticulier.mainProspect
      )
    );

    this.listProspects$ = this.store$.pipe(
      map(
        state => state.addOpportunite.prospectParticulier.listProspects
      )
    );
    this.entreprise$ = this.store$.pipe(
      map(
        state => {
          const entreprise: Entreprise = JSON.parse(JSON.stringify(state.addOpportunite.entreprise))
          entreprise.listProspectsLier = state.addOpportunite.prospectPro.listProspects
          entreprise.mainProspect = state.addOpportunite.prospectPro.mainProspect
          return entreprise;
        }
      )
    );

    this.prospectPro$ = this.store$.pipe(
      map(
        state => state.addOpportunite.prospectPro
      )
    );

  }

  deleteProspect(index: number) {
    this.store$.dispatch(addOppActions.DeleteProspectAction({ payload: index }));
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async getConfigurationForm():Promise<void>{
    this.apiConfigurationService.getParamConfig(this.keyFormProspect).subscribe(
      (response) => {
        this.prospectSchema=response.value;
      })
  }

  async getConfigurationFormEntreprise():Promise<void>{
    this.apiConfigurationService.getParamConfig(this.keyFormEntreprise).subscribe(
      (response) => {
        this.EntrepriseSchema=response.value;
      })
  }

  initFormProspect() {
    this.prospectForm = new FormGroup({
      prospects_id: new FormControl(this.selectedProspect.prospects_id),
      campagne_id: new FormControl(this.selectedProspect.campagne_id),
      ville: new FormControl(this.selectedProspect.ville),
      situation: new FormControl(this.selectedProspect.situation),
      users_nom: new FormControl(this.selectedProspect.users_nom),
      users_prenom: new FormControl(this.selectedProspect.users_prenom),
      adresse_mail: new FormControl(this.selectedProspect.adresse_mail),
      civilite: new FormControl(this.selectedProspect.civilite),
      prospects_civ: new FormControl(this.selectedProspect.prospects_civ),
      name: new FormControl(this.selectedProspect.name),
      surname: new FormControl(this.selectedProspect.surname),
      DN: new FormControl(this.selectedProspect.DN),
      prospects_situation: new FormControl(this.selectedProspect.prospects_situation),
      nb_enfants: new FormControl(this.selectedProspect.nb_enfants),
      adresses_adresse: new FormControl(this.selectedProspect.adresses_adresse),
      streetNumber: new FormControl(this.selectedProspect.streetNumber),
      CP: new FormControl(this.selectedProspect.CP),
      streetName: new FormControl(this.selectedProspect.streetName),
      adresses_ville: new FormControl(this.selectedProspect.adresses_ville),
      complement_adr: new FormControl(this.selectedProspect.complement_adr),
      tel: new FormControl(this.selectedProspect.tel),
      tel2: new FormControl(this.selectedProspect.tel2),
      mobile: new FormControl(this.selectedProspect.mobile),
      first_campagne: new FormControl(this.selectedProspect.first_campagne),
      description: new FormControl(this.selectedProspect.description),
      securite: new FormControl(this.selectedProspect.securite_sociale)

    });

  }

  async ngOnInit() {

    this.formGroup = this.formBuilder.group({
      nomprenom: new FormControl('', Validators.minLength(3)),
      telephone: new FormControl('', Validators.minLength(3)),
      selectNomPrenom: new FormControl(),
      selectProspect: new FormControl(),
      prospect_id: new FormControl(),
      entreprise_id: new FormControl(),
    });
    await this.getConfigurationForm();
    await this.getConfigurationFormEntreprise();
  }

  onItemChange(value) {
    this.updateProspectType();
  }

  findByName() {
    if (this.seartch_str.length > 3) {
      this.loadingAutoCmplt = true;
      this.setValueSelectedProspect("")
      this.apiProspectsService.rechercheProspectGet(this.seartch_str).pipe(
        tap(({ results }) => {
          this.list_auto_fill_options = results
        })
      ).subscribe(next =>
        this.loadingAutoCmplt = false,
        error =>
          this.loadingAutoCmplt = false);
    } else {
      this.list_auto_fill_options = null;
    }
  }


  rechercheSociete() {

    this.loadingAutoCmplt = true;
    this.setValueSelectedProspect("")
    this.apiProspectsService.rechercheSociete(this.entrepriseRecherche).pipe(
      tap(({ data }) => {
        this.getSearchResult = data;
      })
    ).subscribe(next =>
      this.loadingAutoCmplt = false,
      error =>
        this.loadingAutoCmplt = false);
  }


  onSubmit() {

    if (this.formGroup.value.prospect_id)
      this.apiProspectsService.selectProspectWithIdGet(this.formGroup.value.prospect_id)
        .subscribe((data: Prospect) => {
          this.store$.dispatch(FillOldProspect({ payload: data }));
        });

  }

  onSubmitEntreprise() {
    if (this.formGroup.value.entreprise_id)
      this.apiProspectsService.getSocieteById(this.formGroup.value.entreprise_id)
        .subscribe((data) => {
          this.store$.dispatch(FillOldEntrprise({ payload: data.data }));
        });
  }

  reset() {
    this.selectedProspect = new Prospect();
  }

  submitProspectParticulier() {
    const prospect = new Prospect(this.prospectForm.value);
    this.apiProspectsService.insertProspectCompGet(prospect).subscribe(
      resp => {
      }
    );
  }

  updateProspect($event: Prospect, index: number) {
    this.store$.dispatch(addOppActions.UpdateProspectAction({ payload: { index: index, info: $event } }));
  }

  updateEntreprise($event: Entreprise) {
    this.store$.dispatch(addOppActions.UpdateEntrepriseAction({ payload: $event }));
  }

  updateProspectType() {
    this.store$.dispatch(addOppActions.UpdateTypeProspectAction({ payload: this.choix }));
  }

  onInitProspect() {
    this.initFormProspect();
  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  addProspect() {
    // this.getConfigurationForm()
    this.store$.dispatch(addOppActions.AddProspectAction());
  }

  findByTelephone(tel) {
    this.telTxtQueryChanged.next(tel)
  }

  setValueSelectedProspect(id: string) {
    this.formGroup.controls.prospect_id.setValue(id);
    this.formGroup.controls.entreprise_id.setValue(id);
  }

  onSelectToFillOldProspect(index) {
    this.store$.dispatch(SelectOldProspect({ payload: index }))
  }

  resetSerach(){
    this.seartch_str="";
    this.list_auto_fill_options=[];
  }
}
