<div class="row" style="margin-top: 15px">

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (click)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_nom_prenom" matInput
                    placeholder="Nom & Prénom" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <!-- pattern="[0-9]{9}" -->
                <input (click)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_tel" matInput
                    placeholder="Téléphone" type="number" #phone="ngModel"  name="phone">
            </mat-form-field>
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="message-erreur">
                <div *ngIf="phone.errors.pattern">
                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Téléphone doit contenir 9 chiffres
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (click)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_mail" matInput
                    placeholder="Email" type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="mail"
                    #mail="ngModel">
            </mat-form-field>
            <div *ngIf="mail.invalid && (mail.dirty || mail.touched)" class="message-erreur">
                <div *ngIf="mail.errors.pattern">
                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Format invalide !
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (click)=" EmitFilterProspect()" [(ngModel)]="filterProspect.prospect_ville" matInput
                    placeholder="Ville/CP" type="text">
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allZones.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Zone</mat-label>
                    <mat-chip-list #Zone>
                        <mat-chip *ngFor="let zone of ListZones" [selectable]="selectable" [removable]="removable"
                            (removed)="removeZones(zone)" (removed)=" EmitFilterProspect()">
                            {{zone}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterProspect()" placeholder="Selectionner Zone..." #ZonesInput
                            [formControl]="zoneFromCtrl" [matAutocomplete]="autoZones" [matChipInputFor]="Zone"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoZones="matAutocomplete" (optionSelected)="selectedZones($event)">
                        <mat-option *ngFor="let zone of filteredZones | async" [value]="zone.id">
                            {{zone.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allTags.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'opportunities.label.tagscontain' | translate}}</mat-label>
                    <mat-chip-list #tagsContient>
                        <mat-chip *ngFor="let tags of ListTagsContient" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTagsContient(tags)"
                            (removed)=" EmitFilterProspect()">
                            {{tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterProspect()" placeholder="Selectionner Tags Contient..."
                            #TagsContientInput [formControl]="tagsContientFromCtrl" [matAutocomplete]="autoTagsContient"
                            [matChipInputFor]="tagsContient" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTagsContient="matAutocomplete"
                        (optionSelected)="selectedTagsContient($event)">
                        <mat-option *ngFor="let tags of filteredTagsContient | async" [value]="tags.id">
                            {{tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allTags.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{'opportunities.label.tagsnotcontain' | translate}}</mat-label>
                    <mat-chip-list #tagsContientPas>
                        <mat-chip *ngFor="let tags of ListTagsContientPas" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTagsContientPas(tags)"
                            (removed)=" EmitFilterProspect()">
                            {{tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)=" EmitFilterProspect()" placeholder="Selectionner Tags Ne Contient Pas..."
                            #TagsContientPasInput [formControl]="tagsContientPasFromCtrl"
                            [matAutocomplete]="autoTagsContientPas" [matChipInputFor]="tagsContientPas"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTagsContientPas="matAutocomplete"
                        (optionSelected)="selectedTagsContientPas($event)">
                        <mat-option *ngFor="let tags of filteredTagsContientPas | async" [value]="tags.id">
                            {{tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <!-- <div class="col-6">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -12px;">
            <mat-select (selectionChange)=" EmitFilterProspect()" [(ngModel)]="filterProspect.mail_contient"
                placeholder="Email du prospect">
                <mat-option value="0">
                    Ne contient pas
                </mat-option>
                <mat-option value="1">
                    Contient
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
</div>