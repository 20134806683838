export class OpportuniteDetails {
  user_id;
  user_entreprise_id;
  commerciaux_id;
  main_prospect_id;
  date_depot;
  etat;
  description;
  etat_rdv_id;
  campagne_id;
  last_update;
  type_opportunite;
  opportunite_parent;
  startRdv;
  endRdv;
  tags;
  allowed_to_update;
  etat_id;
  bien;
  person;
}
 export class GetOpportuniteDetails {
  opportunite_details: OpportuniteDetails = new OpportuniteDetails();
  id;
  real_id;
}

