import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Destinataire } from 'src/app/entity/Maileva/FilterMaileva';

@Component({
  selector: 'app-filters-distinataire',
  templateUrl: './filters-distinataire.component.html',
  styleUrls: ['./filters-distinataire.component.css']
})
export class FiltersDistinataireComponent implements OnInit, OnChanges {
  filterDestinataire: Destinataire = new Destinataire()
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushfilterDestinataire: EventEmitter<Destinataire> = new EventEmitter();

  constructor(private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.resetDestinataireForm(changes.Reset.currentValue);
  }
  resetDestinataireForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {
      this.filterDestinataire.adresse_destinataire = "";
      this.filterDestinataire.complement_adresse_destinataire = "";
      this.filterDestinataire.cp_destinataire = "";
      this.filterDestinataire.nom_prenom_destinataire = "";
      this.filterDestinataire.ville_destinataire = "";
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushfilterDestinataire.emit(this.filterDestinataire);
    }
  }

  EmitFilterDestinataire() {


    this.onPushfilterDestinataire.emit(this.filterDestinataire);
  }




}
