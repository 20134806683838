import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], fields:string[]=[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!fields || !value) {
      return items;
    }

    return items.filter(singleItem =>{
      let field:string="";
      fields.forEach((elem)=>{
        field+=singleItem[elem]+' '
      })
      return field.toLowerCase().includes(value.toLowerCase());
    }
    );
  }

}
