<div class="row" style="margin-top: 15px">

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterDestinataire()" (click)="EmitFilterDestinataire()"
                    [(ngModel)]="filterDestinataire.nom_prenom_destinataire" matInput
                    placeholder="Nom & Prénom destinataire" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (keyup.enter)="EmitFilterDestinataire()" (click)="EmitFilterDestinataire()"
                    [(ngModel)]="filterDestinataire.adresse_destinataire" matInput placeholder="N° et nom de rue "
                    type="text">
            </mat-form-field>

        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input [(ngModel)]="filterDestinataire.cp_destinataire" (click)="EmitFilterDestinataire()"
                    (keyup.enter)="EmitFilterDestinataire()" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre"
                    #nombre="ngModel" matInput placeholder="Code postal " type="text">
            </mat-form-field>
            <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
                <div *ngIf="nombre.errors.pattern">
                    <i class="fa fa-exclamation-triangle"></i>&nbsp;Seulement des chiffres !
                </div>
            </div>
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input [(ngModel)]="filterDestinataire.ville_destinataire" (keyup.enter)="EmitFilterDestinataire()"
                    (click)="EmitFilterDestinataire()" matInput placeholder="Ville" type="text">
            </mat-form-field>

        </div>
    </div>


    <div class="col-12">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input [(ngModel)]="filterDestinataire.complement_adresse_destinataire"
                    (keyup.enter)="EmitFilterDestinataire()" (click)="EmitFilterDestinataire()" matInput
                    placeholder="Complément d'adresse" type="text">
            </mat-form-field>

        </div>
    </div>
</div>