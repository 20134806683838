import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { ApiAdminService } from 'src/app/services/ApiAdmin/api-admin.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { ListOrganisme } from 'src/app/entity/Generic/ListOrganisme';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { element } from 'protractor';
@Component({
  selector: 'app-list-organisme',
  templateUrl: './list-organisme.component.html',
  styleUrls: ['./list-organisme.component.css'],
})
export class ListOrganismeComponent implements OnInit, OnDestroy {
  listeOfUnsbscribeWebService: Subscription[] = [];
  fa = fa;
  listOrganisme: ListOrganisme[] = [];
  dataTable: any;
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private routes: Router,
    private apiAdminServices: ApiAdminService,
    private apiOrganismeService: ApiOrganismeService,
    private chRef: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}

  ngOnDestroy(): void {
    this.listeOfUnsbscribeWebService.forEach((element) => {
      element.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Gestion des organismes');
    this.showloader();
    this.getListOrganisme();
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  retourToList() {
    this.routes.navigate(['/gestion-organisme']);
  }

  getListOrganisme() {
    const unsbscribeGetListOrganisme = this.apiOrganismeService
      .GetListOrganisme()
      .subscribe((data: ListOrganisme[]) => {
        this.listOrganisme = data;
        this.chRef.detectChanges();
        const table: any = $('#Table');
        this.dataTable = table.DataTable({
          stateSave: true,
          pagingType: 'full_numbers',
          language: {
            sProcessing: 'Traitement en cours...',
            searchPlaceholder: ' Recherche...',
            sSearch: '<p aria-hidden="true"></p>',
            sLengthMenu: 'Afficher _MENU_ &eacute;l&eacute;ments',
            sInfo: "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            sInfoEmpty: "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
            sInfoPostFix: '',
            sLoadingRecords: 'Chargement en cours...',
            sZeroRecords: 'Aucun &eacute;l&eacute;ment &agrave; afficher',
            sEmptyTable: 'Aucune donn&eacute;e disponible dans le tableau',
            oPaginate: {
              sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
              sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
              sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
              sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
            },
            oAria: {
              sSortAscending: ': activer pour trier la colonne par ordre croissant',
              sSortDescending: ': activer pour trier la colonne par ordre d&eacute;croissant',
            },
            select: {
              rows: {
                _: '%d lignes séléctionnées',
                0: 'Aucune ligne séléctionnée',
                1: '1 ligne séléctionnée',
              },
            },
          },
        });
        this.hideloader();
      });
    this.listeOfUnsbscribeWebService.push(unsbscribeGetListOrganisme);
  }

  changeStatus(idOrg: string, status: number) {
    this.apiAdminServices.ActivateDeactivateOrganisme(idOrg, status).subscribe(
      (response) => {
        this.toastr.success('Opération réussite');
        this.getListOrganisme();
      },
      (error) => {
        alert(error.message);
      }
    );
  }
}
