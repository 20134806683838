import { Component, EventEmitter, HostListener, OnInit, Output, Injectable, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ApiOpportunitService } from '../../../../../../services/ApiOpportunite/api-opportunite.service';
import { ActivatedRoute } from '@angular/router';
import { SharedMenuObserverService } from '../../../../../../services/SharedMenuObserver/shared-menu-observer.service';
import { DetailsOpportuniteStore } from '../../../store/details-opportunite.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as selectors from '../../../store';
import * as actions from '../../../store/details-opportunite.actions';
import { OpportunitiesDetailsComponent } from '../../../opportunities-details.component';

class formbuilder {
  data;
  schema;
}
class form {
  form: formbuilder;
  error;
}
class informationSpecifiques {
  informationSpecifiques: form;
}
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-information-specifiques',
  templateUrl: './information-specifiques.component.html',
  styleUrls: ['./information-specifiques.component.css'],
})
export class InformationSpecifiquesComponent implements OnInit, AfterViewInit {
  opportunity_id: string = '';
  form = new FormGroup({});
  @Output() onPush = new EventEmitter<any>();
  model: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  infospecifique$: Observable<any> | null = null;
  loading: boolean = true;
  formdataspecif: any = null;
  formdata: any = null;
  allowed_to_update: any;

  constructor(
    private route: ActivatedRoute,
    private formlyJsonschema: FormlyJsonschema,
    private apiOpportunitService: ApiOpportunitService,
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private sharedMenuObserverService: SharedMenuObserverService,
    private store$: Store<DetailsOpportuniteStore>
  ) {
    this.apiOpportunitService.listenSpecif().subscribe((m: any) => {
      this.pushInfo();
    });
  }

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.sharedMenuObserverService.updateMenu(
      'Opportunités ➜ Détails ➜ Informations complémentaires ligne de produits'
    );

    this.infospecifique$ = this.store$.select(selectors.getInfoSpecifiqueFromDetailsOpportunite);
    this.infospecifique$.subscribe((data) => {
      this.form = new FormGroup({});
      if (data !== null && data.informationSpecifiques !== null) {
        this.formdataspecif = data;
        this.options = {};
        /**
         * @param form permet de stocker && convertir schema
         * @param infoSpecifiquesPropreties permet de stocker la conversion des objets
         */
        const form: any = JSON.parse(JSON.stringify(data.informationSpecifiques.form.schema));
        const infoSpecifiquesPropreties: any[] = [];

        form.properties.forEach((element) => {
          infoSpecifiquesPropreties[element.name] = element;
        });
        form.properties = infoSpecifiquesPropreties;
        if (data.informationSpecifiques.form.schema) {
          this.fields = [this.formlyJsonschema.toFieldConfig(form)];
        }
        this.model =
          Object.keys(data.informationSpecifiques.form.data).length !== 0
            ? data.informationSpecifiques.form.data
            : null;
        this.loading = false;
      } else if (data !== null) {
        this.options = {};
        this.formdataspecif = data;

        if (data.form.schema) {
          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(data.form.schema)))];
        }
        this.model = data.form.data;
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.pushInfo();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {
    this.pushInfo();
  }

  onChangeJson($event: any) {
    this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify($event)))];
  }

  pushInfo() {
    if (this.formdataspecif && this.formdataspecif.informationSpecifiques) {
      this.formdata = new informationSpecifiques();
      this.formdata.informationSpecifiques = new form();
      this.formdata.informationSpecifiques.form = new formbuilder();
      this.formdata.informationSpecifiques.error = false;
      this.formdata.informationSpecifiques.form.schema = this.formdataspecif.informationSpecifiques.form.schema;
      this.formdata.informationSpecifiques.form.data =
        Object.keys(this.form.value).length !== 0
          ? this.form.value
          : this.formdataspecif.informationSpecifiques.form.data;
      const nouvdata = JSON.stringify(this.formdata?.informationSpecifiques?.form.data);
      const anciendata = JSON.stringify(
        this.opportunitiesDetailsComponent?.detailsState?.informationSpecifiques?.informationSpecifiques?.form.data
      );
      if (anciendata !== nouvdata) this.store$.dispatch(actions.UpdateInfoSpecifqueAction({ payload: this.formdata }));
    }
  }
}
