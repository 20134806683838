export class Bike {
  purchaseDate: string = '';
  purchasePrice: number;
  classification: string = 'Off-road Bike';
  type: string = 'Electric';
  usage: string = 'Private Use and Commuting';
  TrackerGPS: string = '';
  etchingNumber: string;
  maximumSpeed: number;
  serialNumber: string;
  brand: string;
  model: string;
}
