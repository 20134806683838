import { Injectable } from '@angular/core';
import {OpportunityAdd} from "../../entity/Opportunity/OpportunityAdd";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AddOpSharedEntityService {

  opportunityChanged = new Subject<OpportunityAdd>();
  onChangeCampagneId = new Subject<string>();
  opportunity :OpportunityAdd;

  constructor() { }

  updateOpportunity(opportunity:OpportunityAdd){
    this.opportunity=opportunity;
    this.opportunityChanged.next(this.opportunity);
  }
  updateCampagne(campagneId){
    this.opportunity.campagne.campagne_id=campagneId;
    this.onChangeCampagneId.next(this.opportunity.campagne.campagne_id)
  }

  onChangeOpportunity(){
    return this.opportunityChanged.asObservable();
  }

  onChangeCampagne(){
    return this.onChangeCampagneId.asObservable();
  }
}
