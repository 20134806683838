import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { EcheancierService } from 'src/app/services/ApiEcheancier/echeancier.service';
import { environment } from 'src/environments/environment';
import { FilterEcheances } from 'src/app/entity/FilterEcheances';

export interface PeriodicElement {
  motif: any;
  date_debut_periode: any;
  date_fin_periode: any;
  status: any;
  affaire_id: any;
}
export class FilterEcheancier {
  date_debut_periode: any = '';
  date_fin_periode: any = '';
  user: any = '';
  montant_total: any = '';
  date_prelevement: any = [];
  motif: any = '';
  status_echeances: any = [];
  status_echeanciers: any = "";
  sortCol: 0;
  sort: 'desc';
  name: string = "";
  surname: string = "";
  sSearch: string = '';

}
@Component({
  selector: 'app-echeances',
  templateUrl: './echeances.component.html',
  styleUrls: ['./echeances.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EcheancesComponent implements OnInit ,OnDestroy {
  fa = fa;
  columnsToDisplay = ['position', 'nameClient', 'motif', 'date_debut_periode', 'date_fin_periode', 'status', 'affaire_id'];
  expandedElement: PeriodicElement | null;
  date_prelevementDebut = new Date();
  date_prelevementFin = new Date();

  limit = 15;
  page = 1;
  all_filtres: FilterEcheances = new FilterEcheances()
  listEcheancier = [];
  indexEcheancier: number = 0;
  dataSource: any;
  columnsToDisplayWithExpand: any;
  intervale = 1;
  totalPages = 0;
  currentCurrency = environment.currentCurrency;
  total_IMPAYE_month_ago: any;
  total_PAYE: any;
  total_PAYE_month_ago: any;
  total_PLANIFIE_next_month: any;
  search: string;
  listeDate: any;
  loader: boolean
  apearFilter: boolean = false;
  resetAllFilter : boolean = false;

  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiecheancierService: EcheancierService
  ) { }
  ngOnDestroy(): void {
   localStorage.removeItem('filterEcheance')
  }

  ngOnInit(): void {
    //this.date_prelevementFin.setDate(this.date_prelevementFin.getDate() + 2 * 7);
    //  this.date_prelevementDebut.setDate(this.date_prelevementDebut.getDate() - 2 * 7);
    //  this.all_filtres.date_prelevement = [this.date_prelevementDebut, this.date_prelevementFin];
    this.sharedMenuObserverService.updateMenu(` Échéanciers `);
    this.listeEcheancier();
    this.columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  }

  listeEcheancier() {
    this.showloader();
    this.loader = false;
    if(localStorage.getItem("filterEcheance")){
      this.all_filtres = JSON.parse(localStorage.getItem("filterEcheance"));
    }
    this.apiecheancierService.listEcheanciersGet(this.page, this.limit, this.all_filtres).subscribe((data: any) => {
      this.listEcheancier = data.data.list_echeanciers;
      this.apearFilter = true;
      this.loader = true;
      for (let i in this.listEcheancier) {
        this.listEcheancier[i].position = Number(i) + 1
      }
      this.total_IMPAYE_month_ago = data.data.total_IMPAYE_month_ago;
      this.total_PAYE = data.data.total_PAYE;
      this.total_PAYE_month_ago = data.data.total_PAYE_month_ago;
      this.total_PLANIFIE_next_month = data.data.total_PLANIFIE_next_month;
      this.dataSource = this.listEcheancier;
      this.calculate_nb_pages(data.data.nb_total);
      this.hideloader();
    });
  }

  getIndex(index: number) {
    this.indexEcheancier = index;
  }

  format_date(date) {
    const words = date.split('T');
    return words[0];
  }

  format_date_echeance(date) {
    const words = date.split('/');
    const datef = words[2] + '-' + words[1] + '-' + words[0];
    return datef;
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.listeEcheancier();
      }
    }
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  first() {
    this.setPage(1);

  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    this.listeEcheancier();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    
    this.listeEcheancier();
  }

  updateListEcheancier() {
    this.page = 1;
    this.listeEcheancier();
  }

  filterWithDate() {
    this.page = 1;
    // this.listeDate = []
    // this.listeDate.push(this.date_prelevementDebut , this.date_prelevementFin )
    this.all_filtres.sSearch = ""
    this.all_filtres.date_prelevement = [this.date_prelevementDebut, this.date_prelevementFin];
    localStorage.setItem("filterEcheance", JSON.stringify(this.all_filtres) )
    this.listeEcheancier();
  }

  filter(event) {
    if ((event.target as HTMLInputElement).value.length >= 3 || (event.target as HTMLInputElement).value.length == 0) {
      this.page = 1;
      this.all_filtres = new FilterEcheances();
      this.all_filtres.status_echeances = []
      // this.all_filtres.date_prelevement  =[]
      this.all_filtres.sSearch = this.search;
      this.loader = false;
      localStorage.setItem("filterEcheance", JSON.stringify(this.all_filtres) )
      this.apiecheancierService.listEcheanciersGet(this.page, this.limit, this.all_filtres).subscribe((data: any) => {
        this.listEcheancier = data.data.list_echeanciers;
        this.apearFilter = true;
        this.loader = true;
        for (let i in this.listEcheancier) {
          this.listEcheancier[i].position = Number(i) + 1
        }
        this.total_IMPAYE_month_ago = data.data.total_IMPAYE_month_ago;
        this.total_PAYE = data.data.total_PAYE;
        this.total_PAYE_month_ago = data.data.total_PAYE_month_ago;
        this.total_PLANIFIE_next_month = data.data.total_PLANIFIE_next_month;
        this.dataSource = this.listEcheancier;
        this.calculate_nb_pages(data.data.nb_total);
      });
      this.resetAllFilter = true;
    }
  }

  setStatusFilter(status_filter: string) {
    this.resetAllFilter = true;
    this.page = 1;
    this.all_filtres = new FilterEcheances();
    this.search = '';
    this.all_filtres.sSearch = "";
    this.listEcheancier = []
    this.dataSource = this.listEcheancier
    localStorage.setItem("filterEcheance", JSON.stringify(this.all_filtres) )
    // this.all_filtres.date_prelevement = [];
    if (this.all_filtres.status_echeances.indexOf(status_filter) !== -1) {
      this.hideloader();
      return;
    } else {
      if (status_filter !== '') {
        if (status_filter == 'IMPAYE') {
          let dateDebut = new Date()
          let dateFin = new Date()
          dateFin.setDate(dateFin.getDate() - 30)
          this.all_filtres.date_prelevement = [dateFin, dateDebut]
          this.all_filtres.status_echeances = [];
          this.all_filtres.status_echeances.push(environment.idStatusInfoBancaires, environment.idStatusImpaye, environment.idStatusDefaut);
        } else if (status_filter == 'PAYE') {
          let dateDebut = new Date()
          let dateFin = new Date()
          dateFin.setDate(dateFin.getDate() - 30)
          this.all_filtres.date_prelevement = [dateFin, dateDebut]
          this.all_filtres.status_echeances = [];
          this.all_filtres.status_echeances.push(environment.idStatusPaye);
        } else if (status_filter == 'PLANIFIE') {
          let dateDebut = new Date()
          let dateFin = new Date()
          dateFin.setDate(dateFin.getDate() + 30)
          this.all_filtres.date_prelevement = [dateDebut, dateFin]
          this.all_filtres.status_echeances = [];
          this.all_filtres.status_echeances.push(environment.idStatusPlanifie, environment.idStatusEncours);
        }
      } else {
        this.all_filtres.status_echeances = [];
      }
    }
    this.listEcheancier = []
    this.loader = false;
    this.apiecheancierService.listEcheanciersGet(this.page, this.limit, this.all_filtres).subscribe((data: any) => {
      this.listEcheancier = data.data.list_echeanciers;
      this.loader = true;
      this.apearFilter = true;
      for (let i in this.listEcheancier) {
        this.listEcheancier[i].position = Number(i) + 1
      }
      this.total_IMPAYE_month_ago = data.data.total_IMPAYE_month_ago;
      this.total_PAYE = data.data.total_PAYE;
      this.total_PAYE_month_ago = data.data.total_PAYE_month_ago;
      this.total_PLANIFIE_next_month = data.data.total_PLANIFIE_next_month;
      this.dataSource = this.listEcheancier;
      this.calculate_nb_pages(data.data.nb_total);
    })
  }

  allFilter(event) {
    this.all_filtres = event;
  }


  submitFilter(event) {
    this.dataSource = [];
    this.loader = false;
    this.search = "";
    this.page = 1;
    this.all_filtres = event;
    localStorage.setItem("filterEcheance", JSON.stringify(this.all_filtres) )
    this.apiecheancierService.listEcheanciersGet(this.page, this.limit, this.all_filtres).subscribe((data: any) => {
      this.listEcheancier = data.data.list_echeanciers;
      this.loader = true;
      this.apearFilter = true;
      for (let i in this.listEcheancier) {
        this.listEcheancier[i].position = Number(i) + 1
      }
      this.total_IMPAYE_month_ago = data.data.total_IMPAYE_month_ago;
      this.total_PAYE = data.data.total_PAYE;
      this.total_PAYE_month_ago = data.data.total_PAYE_month_ago;
      this.total_PLANIFIE_next_month = data.data.total_PLANIFIE_next_month;
      this.dataSource = this.listEcheancier;
      this.calculate_nb_pages(data.data.nb_total);
    });
  }

  resetformFilter(event){
this.resetAllFilter = event;
localStorage.setItem("filterEcheance", JSON.stringify(this.all_filtres) )
  }
}
