<div class="footer">
  <div id="Groupe_976">
    <div id="ID2020__Neopolis_Corp_Tous_Dro">
      <span>{{year}} © Neopolis Development. Tous Droits Réservés ® - Powered by </span>
      <a href="https://neopolis-dev.com/" target="_blank">
        <img style="width: 82px;height: 26px;margin-left: 20px;padding-top: 3px;" src="assets/img/logo_neopolis.png" alt="logo">
      </a>
    </div>
  </div>
</div>
