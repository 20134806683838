import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FiltreAffaireComponent } from './filtre-affaire/filtre-affaire.component';
import { FiltreEcheancierComponent } from './filtre-echeancier/filtre-echeancier.component';
import { FilterEcheanceEcheanceComponent } from './filter-echeance-echeance/filter-echeance-echeance.component';

@NgModule({
    declarations: [
      
        FiltreAffaireComponent,
        FiltreEcheancierComponent,
        FilterEcheanceEcheanceComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
      
        FiltreAffaireComponent,
        FiltreEcheancierComponent,
        FilterEcheanceEcheanceComponent
    ]
})
export class FilterEcheanceModule { }