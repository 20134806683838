import { BankInformation } from './BankInformation';
import { TagOpportunite } from './Opportunity/TagOpportunite';
import { Address } from './tarificateurBerilish/client';

export class Prospect {
  prospects_id: string;
  id: string = null;
  id_opp: string = null;
  campagne_id: string = null;
  post: string = null;
  interlocuteur = false;
  ville: string = null;
  situation: string = null;
  users_nom: string = null;
  users_prenom: string = null;
  adresse_mail: string = null;
  civilite: string = null;
  prospects_civ: string = null;
  name: string = null;
  surname: string = null;
  DN: string = null;
  prospects_situation: string = null;
  nb_enfants: string = null;
  adresses_adresse: string = null;
  streetNumber: string = null;
  CP: string = null;
  securite_sociale : string= null;
  streetName: string = null;
  adresses_ville: string = null;
  complement_adr: string = null;
  tel: string = null;
  tel2: string = null;
  mobile: string = null;
  first_campagne: string = null;
  description: string = null;
  nuage_tags: any[];
  etat: string = null;
  lat: string = null;
  latitude: string = null;
  longitude: string = null;
  commercieux_id: string = null;
  commentaire: string = null;
  geom: string = null;
  tags: TagOpportunite[] = [];
  lng: string = null;
  date_naiss: string = null;
  collab: string = null;
  id_pros: string = null;
  id_camp: string = null;
  coment_plus: string = null;
  code: string = null;
  rue: string = null;
  numero: string = null;
  complement: string = null;
  mail: string = null;
  nb_enfant: string = null;
  infcomp: string = null;
  relation: string = null;
  pays: string = null;
  invalidForms: any[] = [];
  deleted: boolean = false;
  form  :formbuilderr=new formbuilderr();
  information_bancaire: BankInformation = new BankInformation();
  constructor(init?: Partial<Prospect>) {
    Object.assign(this, init);
  }
}
export class formbuilderr {
  data={};
  schema={};
}
export class ListProspect {
  listProspects: Prospect[] = [];
  mainProspect: Prospect = new Prospect();
  address : any; 
}
