import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import {OpportunityAdd} from "../../../../../entity/Opportunity/OpportunityAdd";
import {AddOpSharedEntityService} from "../../../../../shared/SharedInjectors/add-op-shared-entity.service";

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationComponent implements OnInit {

  public editorOptions: JsonEditorOptions;
  public data: any;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  constructor(private addOpSharedEntityService:AddOpSharedEntityService) {
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['view', 'code', 'text']; // set all allowed modes
    this.editorOptions.mode = 'view'; //set only one mode
    this.data=JSON.parse(JSON.stringify(new OpportunityAdd()));

  }

  ngOnInit(): void {
    this.addOpSharedEntityService.onChangeOpportunity().subscribe(data=>{
      this.data=JSON.parse(JSON.stringify(data));
    })
  }

}
