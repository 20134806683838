<div class="row">
    <div class="col-12">
        <div class="form-group">
            <mat-form-field style="width: 100%;">
                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.compteur_qualite" matInput
                    placeholder="Tentative(s) réalisé(s)" type="text">
            </mat-form-field>

        </div>
    </div>
</div>


<div class="row" style="margin-top: 10px;">
    <div class="col-6">

        <label>Enquête(s) réalisée(s) </label><br>

        <div class="switch-toggle switch-ios">
            <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.affaire_enquete" (change)=" onItemChange()"
                id="OnEnquete" value="on" name="Enquete" type="radio" checked>
            <label for="OnEnquete" onclick="">ON</label>

            <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.affaire_enquete" (change)=" onItemChange()"
                value="na" id="EnqueteN/A" name="Enquete" type="radio">
            <label for="EnqueteN/A" onclick="">N/A</label>

            <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.affaire_enquete" (change)=" onItemChange()"
                value="off" id="OffEnquete" name="Enquete" type="radio">
            <label for="OffEnquete" onclick="">OFF</label>
            <a></a>
        </div>

    </div>

    <div class="col-6">

        <label>Le client valide les garanties ...</label><br>

        <div class="switch-toggle switch-ios">
            <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite1" (change)=" onItemChange()"
                id="OnValide" value="on" name="view" type="radio" checked>
            <label for="OnValide" onclick="">ON</label>

            <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite1" (change)=" onItemChange()"
                value="na" id="ValideN/A" name="view" type="radio">
            <label for="ValideN/A" onclick="">N/A</label>

            <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite1" (change)=" onItemChange()"
                value="off" id="OffValide" name="view" type="radio">
            <label for="OffValide" onclick="">OFF</label>
            <a></a>
        </div>
    </div>

</div>
<div class="row" style="margin-top: 15px;">
    <div class="col-4 p-2">
        <div class="form-group">
            <label>Le conseiller a présenté le Groupe ...</label>
            <div class="switch-toggle switch-ios">
                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite2" id="GroupeOn" name="Groupe"
                    (change)=" onItemChange()" value="on" type="radio" checked>
                <label for="GroupeOn" onclick="">ON</label>

                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite2" id="GroupeN/A" name="Groupe"
                    (change)=" onItemChange()" value="na" type="radio">
                <label for="GroupeN/A" onclick="">N/A</label>

                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite2" id="GroupeOff" name="Groupe"
                    (change)=" onItemChange()" value="off" type="radio">
                <label for="GroupeOff" onclick="">OFF</label>

                <a></a>
            </div>
        </div>
    </div>

    <div class="col-4 p-2">
        <div class="form-group">
            <label>Autres produits ont été réalisés ...</label>
            <div class="switch-toggle switch-ios">
                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite3" id="produitsOn"
                    name="produits" type="radio" (change)=" onItemChange()" value="on" checked>
                <label for="produitsOn" onclick="">ON</label>

                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite3" id="produitsN/A"
                    name="produits" value="na" (change)=" onItemChange()" type="radio">
                <label for="produitsN/A" onclick="">N/A</label>

                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite3" id="produitsOff"
                    name="produits" value="off" (change)=" onItemChange()" type="radio">
                <label for="produitsOff" onclick="">OFF</label>

                <a></a>
            </div>
        </div>
    </div>
    <div class="col-4 p-2">
        <div class="form-group">
            <label>Le conseiller a-t-il expliqué ...</label>
            <div class="switch-toggle switch-ios">
                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite4" id="conseillerOn"
                    name="conseiller" type="radio" (change)=" onItemChange()" value="on" checked>
                <label for="conseillerOn" onclick="">ON</label>

                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite4" id="conseillerN/A"
                    name="conseiller" value="na" (change)=" onItemChange()" type="radio">
                <label for="conseillerN/A" onclick="">N/A</label>

                <input (click)="EmitFilterQualite()" [(ngModel)]="filterQualite.qualite4" id="conseillerOff"
                    name="conseiller" value="off" (change)=" onItemChange()" type="radio">
                <label for="conseillerOff" onclick="">OFF</label>

                <a></a>
            </div>
        </div>
    </div>
</div>