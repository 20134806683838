<div class=" shadow-box-card-body"  style="background-color: #fff;">
  <div class=" row containerTache col-md-12 "  style="border-radius: 10px 10px 0px 0px;">
    <div class="col-md-12"  id="expand" >
        <div  >
      
        
        <div class="row  m-2">
            <h2 class="title-details-blue ml-1 col-md-4">
              <fa-icon  [icon]="fa['faTasks']"></fa-icon>
              Mes tâches créées
            </h2>
            <label class=" col-md-4 ">
                <input type="search" class="search " *ngIf="Tasks?.length > 0" (keyup)="searchTache($event , 'crees')" placeholder=" Recherche..." >
            </label>
            <button type="button" *ngIf="!isExpand" (click)="expandTask()" class="add-btn-en btn-details-add "> 
              <i class="fa fa-plus-circle fa-2x" aria-hidden="true"></i>
            </button>
          </div>
        <div class="row  ">
            
            <div  class="example-list w-100 mw-100 overflow-hidden  d-block mr-3 " >
                <div *ngIf="loader" class="col-12 d-flex justify-content-center text-secondary ">
                    <div class="spinner">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                </div>
                <div class="d-flex justify-content-center text-secondary" *ngIf="Tasks?.length < 1 &&!loader ">
                    Pas des tâches
                </div>
               <div *ngIf="Tasks?.length > 0  && !loader">
                <div class="example-box row  px-2 py-2 flex-row justify-content-between align-items-center"   [ngStyle]="{ 'background-color': task.statut==0 ? '#fff' : '#F0F0F0' }" *ngFor="let task of Tasks"  >
                   
                  <div class="col-4 d-flex align-items-center ">
                    <button type="button" *ngIf="task.statut==1" class="btn-dt-add statusbtn " (click)="changerStatus(task)" style=" background-color: #CBE2FA; " data-title="Activer la tache">
                      <fa-icon  class="btnActiver" [icon]="fa['faPlay']">
                      </fa-icon>  
                    </button>
                    <button type="button" *ngIf="task.statut==0" class="btn-dt-add statusbtn  " (click)="changerStatus(task)"style=" background-color: #FFC9C9; "  data-title="Terminer la tache">
                      <fa-icon  class="btnTerminer" [icon]="fa['faPowerOff']">
                      </fa-icon>  
                    </button>
                  
                  
                    <span class="ml-4 " (click)="viewModif(task , 'cree')"  [ngStyle]="{ 'color': task.statut==1 ? '#C2C2C2' : '#3B3B3B' }" >{{expandFileName(task.titre)}}</span>
                  </div>
                  
                    <div class="col-7">
                        <span *ngFor="let tagsAll of tagsList">
                            <span *ngFor="let tags of task.tags" >              
                                <span class="badge badge-primary  ml-2" [ngStyle]="{'color': tagsAll.couleur,'background-color': tagsAll.couleur_background}"  *ngIf="tagsAll.id==tags.id">{{tagsAll.libelle}}</span>
                            </span>
                        </span>
                       </div>
                       <div class="col-1">
                        <button  type="button"  class=" float-left btn-dt-edit edit "  (click)="viewModif(task , 'cree')">
                          <img src="assets/icons/edit-icon.svg">
                        </button>


      <div class="notification"  *ngIf=" task.priorite == 3">
    <div class="bell-icon__circle"></div>
    </div>

  
  
                      </div>
                </div>
            </div>
            </div>
        </div>
        
       

</div>
</div>
<app-task-detail class="col-md-4 " *ngIf="isExpand" [tagsList]="tagsList" [taskDetail]="taskDetail" (taskEmit)="ajoutTaches($event)" (inexpandEmit)="cancelAddOrUpdate($event)" [typeTache]="typeTache" [priorites]="priorites" [ListUser]="ListUser" [ListCLientProspect]="ListCLientProspect" ></app-task-detail>

</div>
<div  *ngIf="totalPages> 0" class="d-flex align-items-center justify-content-center containerTache" style="border-radius: 0px 0px 10px 10px; margin-bottom: 20px;" >
    <nav aria-label="Page navigation example" *ngIf="Tasks?.length > 0">
        <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                <a class="page-link-symbol">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Previous">
                    <fa-icon [icon]="fa['faChevronLeft'] "></fa-icon>
                </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page-3>0" class="page-item">
                    <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                        3}}</a>
                </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page<totalPages" class="page-item">
                    <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                        [ngClass]="{'active-page':page==(i+page)}">
                        {{i + page}}
                    </a>
                </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
                <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                    [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Next">
                    <fa-icon [icon]="fa['faChevronRight'] "></fa-icon>
                </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                <a class="page-link-symbol">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>
    </div>
<div class=" row containerTache col-md-12 "  style="border-radius: 10px 10px 0px 0px;">
    <div class="col-md-12"  id="expand2" >
        <div class="row m-2 ">
            <h2 class="title-details-blue ml-1 col-md-4  ">
                <fa-icon  [icon]="fa['faTasks']"></fa-icon>
                Mes tâches Affectées
              </h2>
              <label class=" col-md-4 ">
                <input type="search" class="search " *ngIf="Tasksaffect?.length > 0" (keyup)="searchTache($event , 'affectees')" placeholder=" Recherche..." >
            </label>
            
            <div   class="example-list  w-100 mw-100 overflow-hidden d-block mr-3 "  >
                <div *ngIf="loaderAffect" class="col-12 d-flex justify-content-center text-secondary ">
                    <div  class="spinner " >
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                </div>
                <div class="d-flex justify-content-center text-secondary" *ngIf="Tasksaffect?.length < 1  &&!loaderAffect">
                    Pas des tâches
                </div>
                <div *ngIf="Tasksaffect?.length > 0  &&!loaderAffect">
                <div class="example-box row  px-2 py-2 flex-row justify-content-between align-items-center"   [ngStyle]="{ 'background-color': task.statut==0 ? '#fff' : '#F0F0F0'  }" *ngFor="let task of Tasksaffect" >
                  <div class="col-4 d-flex align-items-center">
                    <button type="button" *ngIf="task.statut==1" class="btn-dt-add statusbtn " (click)="changerStatus(task)" style=" background-color: #CBE2FA; " data-title="Activer la tache">
                      <fa-icon  class="btnActiver" [icon]="fa['faPlay']">
                      </fa-icon>  
                    </button>
                    <button type="button" *ngIf="task.statut==0" class="btn-dt-add statusbtn  " (click)="changerStatus(task)" style=" background-color: #FFC9C9; "  data-title="Terminer la tache">
                      <fa-icon   class="btnTerminer"  [icon]="fa['faPowerOff']">
                      </fa-icon>  
                    </button>
                  
                    
                      <span class=" ml-4" (click)="viewModif(task,'affecte')" [ngStyle]="{ 'color': task.statut==1 ? '#C2C2C2' : '#3B3B3B' }">{{expandFileName(task.titre)}}</span>
                     </div>
                     <div class="col-7" >
                      <span *ngFor="let tagsAll of tagsList">
                        <span *ngFor="let tags of task.tags" >              
                            <span class="badge badge-primary  ml-2" [ngStyle]="{'color': tagsAll.couleur,'background-color': tagsAll.couleur_background}"  *ngIf="tagsAll.id==tags.id">{{tagsAll.libelle}}</span>
                    </span>
                </span>
                    </div>
                    <div class="col-1" >
                     
                      <button  type="button" class="  float-left btn-dt-edit edit " (click)="viewModif(task,'affecte')" >
                        <img src="assets/icons/edit-icon.svg">
                      </button>
                      <div *ngIf=" task.priorite == 3">
                        <div class="notification"></div>
                      <div class="bell-icon__circle"></div>
                      </div>
                    </div>
                    
                    
                       
                </div>
            </div>
            </div>
        </div>
      
    </div>
    <app-task-detail class="col-md-4 " *ngIf="isExpandAffect" [tagsList]="tagsList" [taskDetail]="taskDetail" (taskEmit)="ajoutTaches($event)" (inexpandEmit)="cancelAddOrUpdate($event)" [typeTache]="typeTache" [priorites]="priorites" [ListUser]="ListUser" [ListCLientProspect]="ListCLientProspect" ></app-task-detail>
    
    </div>  
    <div  *ngIf="totalPagesAffect> 0" class="d-flex align-items-center justify-content-center containerTache" style="border-radius: 0px 0px 10px 10px;">

        <nav aria-label="Page navigation example" *ngIf="Tasksaffect?.length > 0" >
          <ul class="pagination pg-blue">
              <li (click)="firstAffect()" class="page-item" [ngClass]="{'disabled':isFirstAffect()}">
                  <a class="page-link-symbol">
                      <span aria-hidden="true">&laquo;</span>
                  </a>
              </li>
              <li *ngIf="!isFirstAffect()" (click)="prevAffect()" class="page-item mx-2">
                  <a class="page-link-symbol-chevron" aria-label="Previous">
                      <fa-icon [icon]="fa['faChevronLeft'] "></fa-icon>
                  </a>
              </li>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                  <li *ngIf="i+pageAffect-3>0" class="page-item">
                      <a (click)="changepageAffect(i+pageAffect-3)" class="page-link mt-2 border rounded">{{i + pageAffect -
                          3}}</a>
                  </li>
              </ng-container>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                  <li *ngIf="i+pageAffect<totalPagesAffect" class="page-item">
                      <a (click)="changepageAffect(i+pageAffect)" class="page-link mt-2 border rounded"
                          [ngClass]="{'active-page':pageAffect==(i+pageAffect)}">
                          {{i + pageAffect}}
                      </a>
                  </li>
              </ng-container>
              <li *ngIf="totalPagesAffect>1 && totalPagesAffect > 3 " class="m-2"> ... </li>
              <li class="page-item">
                  <a (click)="changepageAffect(totalPagesAffect)" class="page-link mt-2 border rounded"
                      [ngClass]="{'active-page':pageAffect==totalPagesAffect}">{{totalPagesAffect}}</a>
              </li>
              <li *ngIf="!isLastAffect()" (click)="nextAffect()" class="page-item mx-2">
                  <a class="page-link-symbol-chevron" aria-label="Next">
                      <fa-icon [icon]="fa['faChevronRight'] "></fa-icon>
                  </a>
              </li>
              <li (click)="lastAffect()" class="page-item" [ngClass]="{'disabled':isLastAffect()}">
                  <a class="page-link-symbol">
                      <span aria-hidden="true">&raquo;</span>
                  </a>
              </li>
          </ul>
      </nav>
      </div>
</div>
