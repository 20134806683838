import {FormField} from './FormField';
import {CustomOptions, CustomWidget} from './SharedElementsFormly';

export class SelectFormly extends FormField {

  constructor() {
    super();
    super.icon="fa fa-check";
    super.name = 'selection_' + super.generateRandomId();
    super.type = 'string';
    super.title="Selection"
    super.typename = 'Select';
    this.widget.formlyConfig.type = 'enum';
    this.widget.formlyConfig.templateOptions.options = [
      new CustomOptions('option 1', 'opt1'),
      new CustomOptions('option 2', 'opt2'),
      new CustomOptions('option 3', 'opt3'),
    ];
  }

  pushOption(option: CustomOptions) {
    this.widget.formlyConfig.templateOptions.options.push(option);

  }

  sliceOption(start: number, end: number) {
    this.widget.formlyConfig.templateOptions.options.slice(start, end);
  }

}
