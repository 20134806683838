import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiAuthentificationService } from '../services/ApiAuthentification/api-authentification.service';
import { Configuration } from '../services/configuration';
import { NotificationsService } from '../shared/NotificationsService/notifications.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-rest-password',
  templateUrl: './rest-password.component.html',
  styleUrls: ['./rest-password.component.css'],
})
export class RestPasswordComponent implements OnInit {
  mail: string;
  password: string;
  error = '';
  currentRoute: string = '/';
  previousUrl: string;
  show_loading_add: boolean = false;
  submitted = false;
  apiKey: string;
  resetForm: FormGroup;
  colors: any = {
    accent: 'accent',
    warn: 'warn',
    primary: 'primary',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiAuthentificationService: ApiAuthentificationService,
    private notificationsService: NotificationsService,
    private configuration: Configuration
  ) {
    this.apiKey = environment.api_key;
  }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  reset() {
    this.submitted = true;
    this.show_loading_add = true;
    if (this.resetForm.invalid) {
      this.error = 'Champs vide !';
      this.show_loading_add = false;
      return;
    }
    this.apiAuthentificationService.forgetPassword(this.f.email.value).subscribe(
      (data) => {
        this.show_loading_add = false;
        this.alertSuccess(data);
      },
      () => {
        this.show_loading_add = false;
      }
    );
  }

  alertSuccess(data) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: 'votre mot de passe a été changé avec succès,Veuillez consulter votre boite mail pour récupérer votre nouveau mot de passe ',
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed) {
          location.replace('/login');
        }
      });
  }
}
