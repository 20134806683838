import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { TicketDetails } from '../../../../entity/Affaires/Tickets';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css'],
})
export class TicketsComponent implements OnInit {
  @Output() listticketsexport: EventEmitter<any> = new EventEmitter();

  @Input() prospect_id: string = '';
  @Input() affaire_id;
  id_affaire: string;
  fa = fa;
  ticket_details: TicketDetails[] = [];
  show_loading_add: boolean = false;
  @Input() allowed_to_update_affaire: boolean;
  constructor(
    private apiAffairesService: ApiAffairesService,
    private apiProspectService: ApiProspectsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.apiAffairesService.listenTickets().subscribe((m: any) => {
      this.getListTickets();
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id_affaire = params.get('affaire_id');
    });
    this.getListTickets();
  }

  getListTickets() {
    this.show_loading_add = false;
    this.apiAffairesService.SelectTicketsAffaires(this.id_affaire).subscribe((data: TicketDetails[]) => {
      this.listticketsexport.emit(data);
      this.ticket_details = data.reverse();
      this.show_loading_add = true;
    });
  }

  NaviagateToOppoDetials(id: string) {
    this.router.navigate(['/opportunities/details/' + id + '/prospect']);
  }

  NaviagateToTicketDetials(id: string) {
    this.router.navigate(['/ticketsandsupport/details/' + id]);
  }
}
