<div class="row">
    <div class="col-lg-12 col-xl-9 card" style="padding: 1%; border-radius: 15px; margin: 15px 0px 42px 0px">
        <div class="calendar-container">
            <div *ngIf="!updateListRdv" class="loader-span">
                <div class="row" style="margin-top: 150px">
                    <div class="col-12 text-center">
                        <mat-spinner [diameter]="30" style="margin: auto"></mat-spinner>
                    </div>
                </div>
            </div>
            <div [ngStyle]="{'opacity': updateListRdv? '1':'1'}" class="row">
                <div class="col-12">
                    <h2>Agenda</h2>
                </div>
                <div class="col-12">
                    <full-calendar #calendar [options]="calendarOptions">
                    </full-calendar>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 col-xl-3" style=" margin-top: 15px">
        <div class="row">
            <div class="col-xl-12 col-lg-5" style="background-color: white;
           border-radius: 15px;">
                <mat-calendar [selected]="selectedDate" (selectedChange)="onSelect($event)">
                </mat-calendar>
            </div>
            <hr>
            <div class="col col-lg-1"></div>
            <div class="col-xl-12 col-lg-5 card-statut">
                <div class="">
                    <h3 style="font-weight: bold">Prochains RDVs</h3>
                    <br>
                    <ul>
                        <li class="li-next-rdv" *ngFor="let rdv of list_upcoming_rdvs">
                            <a class="url-next-rdv" href="{{rdv.url}}" target="_blank">{{rdv.title}}
                <p style="font-size: small">
                  De: {{parseDate(rdv.start) | date:'dd-MM-yyyy HH:mm'  }} <br>
                  A: {{parseDate(rdv.end)  | date:'dd-MM-yyyy HH:mm'  }} </p>
              </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #OppUpdateRdvModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Paramètres de l'événement </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismissAll()">
      <span aria-hidden="true">×</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <mat-label>Title</mat-label>
                <input matInput type="text" [formControl]="eventTitleControl">
                <button mat-button *ngIf="eventTitleControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventTitleControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Date de début" (dateChange)="changerdatedebut($event)" [formControl]="dateDebutControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" (selectedChanged)="onDate($event)">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Date de Fin" (dateChange)="changerdatefin($event)" [formControl]="dateFinControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker2 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" style="float: left;" (click)="modalService.dismissAll()">
      fermer
    </button>
        <button (click)="deleteRdv()" type="button" class="btn btn-outline-danger " style="float: left;">
      supprimer
    </button>
        <button (click)="updateRdvDetails()" type="button" class="btn btn-outline-success" style="float: right;">Enregistrer
    </button>
    </div>
</ng-template>
<ng-template #OppCreateRdvModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title2">Ajouter un évènement</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">×</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <mat-label>Titre</mat-label>
                <input matInput type="text" [formControl]="eventTitleControl">
                <button mat-button *ngIf="eventTitleControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="eventTitleControl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Date de début" (dateChange)="changerdatedebut($event)" [formControl]="dateDebutControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" (selectedChanged)="onDate($event)" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker2" (dateChange)="changerdatefin($event)" placeholder="Date de Fin" [formControl]="dateFinControl" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker2 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" style="float: left;" (click)="modalService.dismissAll()">
      fermer
    </button>
        <button (click)="createRdvDetails()" type="button" class="btn btn-outline-success" style="float: right;">Enregistrer
    </button>
    </div>
</ng-template>