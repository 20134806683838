import { Component, OnInit } from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-time-type',
  templateUrl: './time-type.component.html',
  styleUrls: ['./time-type.component.scss']
})
export class TimeTypeComponent extends FieldType implements OnInit {

  ngOnInit(): void {
  }

}
