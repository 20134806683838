<mat-form-field class="full-width">
    <mat-label>{{ to.label }}</mat-label>
    <mat-select [formControl]="formControl" [formlyAttributes]="field" [disabled]="false" [required]="false" [multiple]="true">
        <mat-option *ngFor="let opt of to.options" [value]="opt.value">
            {{ opt.label}}
        </mat-option>
    </mat-select>
    <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
</mat-form-field>
