import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commentaires-client-ticket',
  templateUrl: './commentaires-client-ticket.component.html',
  styleUrls: ['./commentaires-client-ticket.component.css'],
})
export class CommentairesClientTicketComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {

  }

}