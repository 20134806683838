import { ProductBackup } from './../../../../../entity/Affaires/ProductBackup';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Affaire } from './../../../../../entity/Affaires/FilterAffaires';
import { ApiProduitsService } from 'src/app/services/ApiPoduits/api-poduits.service';
import { classe, tags } from './../../../../../entity/Opportunity/FilterOpportunite';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { Gamme } from './../../../../../entity/Opportunity/Gamme';
import { map, startWith, tap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Compagnie } from 'src/app/entity/Compagnie';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { LigneProduit } from 'src/app/entity/Opportunity/LigneProduit';
import { ApiContratsService } from 'src/app/services/ApiContrats/api-contrats.service';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
@Component({
  selector: 'app-filtre-affaire',
  templateUrl: './filtre-affaire.component.html',
  styleUrls: ['./filtre-affaire.component.css'],
})
export class FiltreAffaireComponent implements OnInit, OnChanges {
  compagnieSelected: any[] = [];
  fa = fa;
  gammeSelected: any[] = [];
  listProductSelected: string[] = [];
  listClassesSelected: any[] = [];
  listContratSelected: any[] = [];
  pose_du_creation: string = '';
  pose_au_creation: string = '';
  pose_du_souscription: string = '';
  pose_au_souscription: string = '';
  date_effet_dossier_du: string = '';
  date_effet_dossier_au: string = '';
  pose_du_date_envoi_res: string = '';
  pose_au_date_envoi_res: string = '';
  derogLength: string = '';

  // Mat Chips Compagnie
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  compagnieFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCompagnie: Observable<Compagnie[]>;
  ListCompagnies: string[] = [];
  allCompagnie: Compagnie[] = [];
  tagsSelectedContient: any[] = [];
  tagsSelectedNeContient: any[] = [];
  realisationSelected: any[] = [];

  @ViewChild('compagnieInput') compagnieInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCompagnies') matAutocompleteCompagnies: MatAutocomplete;

  // Mat Chips Gammes
  gammeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGammes: Observable<Gamme[]>;
  ListGammes: string[] = [];
  allGammes: Gamme[] = [];

  @ViewChild('gammeInput') gammeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGamme') matAutocompleteGamme: MatAutocomplete;

  // Mat Chips Produit
  ProductsFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredProducts: Observable<ProductBackup[]>;
  ListProducts: string[] = [];
  allProductBackup: ProductBackup[] = [];

  @ViewChild('ProductsInput') ProductsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocompleteProduct: MatAutocomplete;

  // Mat Chips Classe
  classeFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredClasse: Observable<classe[]>;
  ListClasse: string[] = [];
  allClasse: classe[] = [];

  @ViewChild('classeInput') classeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoClasse') matAutocompleteClasse: MatAutocomplete;

  // Mat Chips Tags
  tagsFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredTags: Observable<tags[]>;
  ListTags: string[] = [];
  allTags: tags[] = [];

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTags') matAutocompleteTags: MatAutocomplete;

  // Mat Chips Tags NeContientPas
  tagsNeContientPasFromCtrl: FormControl = new FormControl('', [Validators.required]);
  ListTagsNeContientPas: string[] = [];
  filteredTagsNeContientPas: Observable<tags[]>;

  @ViewChild('tagsNeContientPasInput') tagsNeContientPasInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoTagsNeContientPas') matAutocompleteTagsNeContientPas: MatAutocomplete;

  // Mat Chips Realisation
  realisationFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredRealisation: Observable<any[]>;
  ListRealisation: string[] = [];
  allRealisation: any[] = [
    {
      id: 0,
      libelle: 'Pas de réalisation à faire',
    },
    {
      id: 1,
      libelle: 'Le client',
    },
    {
      id: 2,
      libelle: 'Sodedif',
    },
    {
      id: 3,
      libelle: 'Le Collaborateur',
    },
    {
      id: 4,
      libelle: 'Organisme Partenaire',
    },
  ];

  @ViewChild('realisationInput') realisationInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoRealisation') matAutocompleteRealisation: MatAutocomplete;

  // Mat Chips Contrat
  contratFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredContrat: Observable<LigneProduit[]>;
  ListContrat: string[] = [];
  allProducts: LigneProduit[] = [];

  @ViewChild('contratInput') contratInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoContrat') matAutocompleteContrat: MatAutocomplete;

  filterAffaire: Affaire = new Affaire();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterAffaire: EventEmitter<Affaire> = new EventEmitter();
  allDerogation: any[] = [];

  getRealisation: string = '';
  getMin: string = '';
  getMax: string = '';
  resetForm: any;
  constructor(
    private apiCampagniesService: ApiCampagniesService,
    private apiOpportuniteService: ApiOpportunitService,
    private apiContratsService: ApiContratsService,
    private apiProduitsService: ApiProduitsService,
    private chRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private apiAffaireService: ApiAffairesService
  ) {}

  // Compagnie -----------------------------------------------------------------------------------------------
  selectedCompagnie(event: MatAutocompleteSelectedEvent): void {
    this.ListCompagnies.push(event.option.viewValue);
    this.compagnieSelected.push(event.option.value);
    this.compagnieInput.nativeElement.value = '';
    this.compagnieFromCtrl.setValue(null);
  }

  removeCompagnie(compagnie: string): void {
    const index = this.ListCompagnies.indexOf(compagnie);
    if (index >= 0) {
      this.ListCompagnies.splice(index, 1);
      this.compagnieSelected.splice(index, 1);
    }
  }

  private _filterCompagnie(): Compagnie[] {
    let filterValue = this.compagnieFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCompagnie.filter((compagnie) =>
      compagnie.denomination != null
        ? compagnie.denomination.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
        : null
    );
  }

  // Gammes  -----------------------------------------------------------------------------------------------
  selectedGammes(event: MatAutocompleteSelectedEvent): void {
    this.ListGammes.push(event.option.viewValue);
    this.gammeSelected.push(event.option.value);
    this.gammeInput.nativeElement.value = '';
    this.gammeFromCtrl.setValue(null);
  }

  removeGammes(gamme: string): void {
    const index = this.ListGammes.indexOf(gamme);
    if (index >= 0) {
      this.ListGammes.splice(index, 1);
      this.gammeSelected.splice(index, 1);
    }
  }

  private _filterGammes(): Gamme[] {
    let filterValue = this.gammeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allGammes.filter((gamme) =>
      gamme.nom != null ? gamme.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
    );
  }

  // Product ---------------------------------------------------------------------------------------------

  selectedProducts(event: MatAutocompleteSelectedEvent): void {
    this.ListProducts.push(event.option.viewValue);
    this.listProductSelected.push(event.option.value);
    this.ProductsInput.nativeElement.value = '';
    this.ProductsFromCtrl.setValue(null);
  }

  removeProducts(product: string): void {
    const index = this.ListProducts.indexOf(product);
    if (index >= 0) {
      this.ListProducts.splice(index, 1);
      this.listProductSelected.splice(index, 1);
    }
  }

  private _filter(): any[] {
    let filterValue = this.ProductsFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allProductBackup.filter((product) => product.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  // Classe -----------------------------------------------------------------------------------------------

  selectedClasse(event: MatAutocompleteSelectedEvent): void {
    this.ListClasse.push(event.option.viewValue);
    this.listClassesSelected.push(event.option.value);
    this.classeInput.nativeElement.value = '';
    this.classeFromCtrl.setValue(null);
  }

  removeClasse(Classe: string): void {
    const index = this.ListClasse.indexOf(Classe);
    if (index >= 0) {
      this.ListClasse.splice(index, 1);
      this.listClassesSelected.splice(index, 1);
    }
  }

  private _filterClasse(): classe[] {
    let filterValue = this.classeFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allClasse.filter((Classe) => Classe.libele.toLowerCase().indexOf(filterValue) === 0);
  }

  // Tags Contient -----------------------------------------------------------------------------------------------

  selectedTags(event: MatAutocompleteSelectedEvent): void {
    this.ListTags.push(event.option.viewValue);
    this.tagsSelectedContient.push(event.option.value);
    this.tagsInput.nativeElement.value = '';
    this.tagsFromCtrl.setValue(null);
  }

  removeTags(Tags: string): void {
    const index = this.ListTags.indexOf(Tags);
    if (index >= 0) {
      this.ListTags.splice(index, 1);
      this.tagsSelectedContient.splice(index, 1);
    }
  }

  private _filterTags(): tags[] {
    let filterValue = this.tagsFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTags.filter((Tags) => Tags.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  // Tags Ne Contient pas -----------------------------------------------------------------------------------------------

  selectedTagsNeContientPas(event: MatAutocompleteSelectedEvent): void {
    this.ListTagsNeContientPas.push(event.option.viewValue);
    this.tagsSelectedNeContient.push(event.option.value);
    this.tagsNeContientPasInput.nativeElement.value = '';
    this.tagsNeContientPasFromCtrl.setValue(null);
  }

  removeTagsNeContientPas(Tags: string): void {
    const index = this.ListTagsNeContientPas.indexOf(Tags);
    if (index >= 0) {
      this.ListTagsNeContientPas.splice(index, 1);
      this.tagsSelectedNeContient.splice(index, 1);
    }
  }

  private _filterTagsNeContientPas(): tags[] {
    let filterValue = this.tagsNeContientPasFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allTags.filter((Tags) => Tags.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  // Realisation -------------------------------------------------------------
  selectedRealisation(event: MatAutocompleteSelectedEvent): void {
    this.ListRealisation.push(event.option.viewValue);
    this.realisationSelected.push(event.option.value);
    this.realisationInput.nativeElement.value = '';
    this.realisationFromCtrl.setValue(null);
  }

  removeRealisation(realisation: string): void {
    const index = this.ListRealisation.indexOf(realisation);
    if (index >= 0) {
      this.ListRealisation.splice(index, 1);
      this.realisationSelected.splice(index, 1);
    }
  }

  private _filterRealisation(): any[] {
    let filterValue = this.realisationFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allRealisation.filter((realisation) => realisation.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  // Contrat ---------------------------------------------------------------------------------------------

  selectedContrat(event: MatAutocompleteSelectedEvent): void {
    this.ListContrat.push(event.option.viewValue);
    this.listContratSelected.push(event.option.value);
    this.contratInput.nativeElement.value = '';
    this.contratFromCtrl.setValue(null);
  }

  removeContrat(contrat: string): void {
    const index = this.ListContrat.indexOf(contrat);
    if (index >= 0) {
      this.ListContrat.splice(index, 1);
      this.listContratSelected.splice(index, 1);
    }
  }

  private _filterContrat(): any[] {
    let filterValue = this.contratFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allProducts.filter((contrat) => contrat.type.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
    this.getAllDerogation();
    this.getAllProductBackup();
    this.getCompagnie();
    this.getGamme();
    this.getListLignesProduits();
    this.getDetailsFilter();
    this.filteredCompagnie = this.compagnieFromCtrl.valueChanges.pipe(
      startWith(null),
      map((compagnie: string | null) => (compagnie ? this._filterCompagnie() : this.allCompagnie.slice()))
    );

    this.filteredGammes = this.gammeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((gamme: string | null) => (gamme ? this._filterGammes() : this.allGammes.slice()))
    );

    this.filteredContrat = this.contratFromCtrl.valueChanges.pipe(
      startWith(null),
      map((contrat: string | null) => (contrat ? this._filterContrat() : this.allProducts.slice()))
    );

    this.filteredClasse = this.classeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Classe: string | null) => (Classe ? this._filterClasse() : this.allClasse.slice()))
    );

    this.filteredTags = this.tagsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Tags: string | null) => (Tags ? this._filterTags() : this.allTags.slice()))
    );

    this.filteredTagsNeContientPas = this.tagsNeContientPasFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Tags: string | null) => (Tags ? this._filterTagsNeContientPas() : this.allTags.slice()))
    );

    this.filteredRealisation = this.realisationFromCtrl.valueChanges.pipe(
      startWith(null),
      map((realisation: string | null) => (realisation ? this._filterRealisation() : this.allRealisation.slice()))
    );

    this.filteredProducts = this.ProductsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter() : this.allProductBackup.slice()))
    );
  }

  getCompagnie() {
    this.apiCampagniesService.getListCompagnies().subscribe((data: Compagnie[]) => {
      this.allCompagnie = data;
    });
  }

  getDetailsFilter() {
    this.apiOpportuniteService
      .getDetailsFilter()
      .pipe(
        tap(({ classe, tags }) => {
          this.allClasse = classe;
          this.allTags = tags;
        })
      )
      .subscribe();
  }

  getGamme() {
    this.apiOpportuniteService.getAllGammes().subscribe((Response: Gamme[]) => {
      this.allGammes = Response;
    });
  }

  getListLignesProduits() {
    this.apiContratsService
      .listeAllProduits()
      .pipe(
        tap(({ lignes_produits }) => {
          this.allProducts = lignes_produits;
        })
      )
      .subscribe();
  }

  getAllProductBackup() {
    this.apiProduitsService.GetListProduitBackup().subscribe((Response: ProductBackup[]) => {
      if (Response) this.allProductBackup = Response;
    });
  }

  getAllDerogation() {
    this.apiAffaireService.allDerogation().subscribe((Response: any[]) => {
      this.allDerogation = Response;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterAffaireForm(changes.Reset.currentValue);
  }

  resetfilterAffaireForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListCompagnies = [];
      this.filterAffaire.cmp = this.compagnieSelected = [];
      this.ListGammes = [];
      this.filterAffaire.gamme = this.gammeSelected = [];
      this.ListProducts = [];
      this.filterAffaire.produit = this.listProductSelected = [];
      this.ListContrat = [];
      this.filterAffaire.contrat = this.listContratSelected = [];
      this.ListClasse = [];
      this.filterAffaire.classe = this.listClassesSelected = [];
      this.ListTags = [];
      this.filterAffaire.tags_aff = this.tagsSelectedContient = [];
      this.ListTagsNeContientPas = [];
      this.filterAffaire.tags_aff_not = this.tagsSelectedNeContient = [];
      this.ListRealisation = [];
      this.filterAffaire.affaire_occ_res = this.realisationSelected = [];
      this.filterAffaire.operateur_gamme = '';
      this.filterAffaire.operateur_produit = '';
      this.filterAffaire.recheche_num_contrat = '';
      this.filterAffaire.pan_ttc_min = '';
      this.filterAffaire.pan_ttc_max = '';
      this.filterAffaire.pose_du_creation = this.pose_du_creation = '';
      this.filterAffaire.pose_au_creation = this.pose_au_creation = '';
      this.filterAffaire.pose_du_souscription = this.pose_du_souscription = '';
      this.filterAffaire.pose_au_souscription = this.pose_au_souscription = '';
      this.filterAffaire.date_effet_dossier_du = this.date_effet_dossier_du = '';
      this.filterAffaire.date_effet_dossier_au = this.date_effet_dossier_au = '';
      this.filterAffaire.operateur_contrat = '';
      this.filterAffaire.operateur_recherche_tag = '';
      this.filterAffaire.operateur_recheche_not_tag = '';
      this.filterAffaire.res_env = '';
      this.filterAffaire.pose_du_date_envoi_res = this.pose_du_date_envoi_res = '';
      this.filterAffaire.pose_au_date_envoi_res = this.pose_au_date_envoi_res = '';
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterAffaire.emit(this.filterAffaire);
    }
  }

  EmitFilterAffaire() {
    this.filterAffaire.pose_du_date_envoi_res = this.datePipe.transform(this.pose_du_date_envoi_res, 'yyyy-MM-dd');
    this.filterAffaire.pose_au_date_envoi_res = this.datePipe.transform(this.pose_au_date_envoi_res, 'yyyy-MM-dd');
    this.filterAffaire.pose_du_souscription = this.datePipe.transform(this.pose_du_souscription, 'yyyy-MM-dd');
    this.filterAffaire.pose_au_souscription = this.datePipe.transform(this.pose_au_souscription, 'yyyy-MM-dd');
    this.filterAffaire.date_effet_dossier_du = this.datePipe.transform(this.date_effet_dossier_du, 'yyyy-MM-dd');
    this.filterAffaire.date_effet_dossier_au = this.datePipe.transform(this.date_effet_dossier_au, 'yyyy-MM-dd');
    this.filterAffaire.pose_du_creation = this.datePipe.transform(this.pose_du_creation, 'yyyy-MM-dd');
    this.filterAffaire.pose_au_creation = this.datePipe.transform(this.pose_au_creation, 'yyyy-MM-dd');
    this.filterAffaire.cmp = this.compagnieSelected;
    this.filterAffaire.gamme = this.gammeSelected;
    this.filterAffaire.produit = this.listProductSelected;
    this.filterAffaire.contrat = this.listContratSelected;
    this.filterAffaire.classe = this.listClassesSelected;
    this.filterAffaire.tags_aff = this.tagsSelectedContient;
    this.filterAffaire.tags_aff_not = this.tagsSelectedNeContient;
    this.filterAffaire.affaire_occ_res = this.realisationSelected;
    this.onPushFilterAffaire.emit(this.filterAffaire);
  }
}
