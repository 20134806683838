import { I } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'shared-globalProduit-form',
  templateUrl: './global-form.component.html',
  styleUrls: ['./global-form.component.css'],
})
export class SharedGlobalFormComponent implements OnInit, OnChanges {
  @Input() formBuilderData;
  @Input() parentObject?;
  isShowing: boolean;
  @Output() emitFormBuilder: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('sidenav') sidenav;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) console.log();
  }

  ngOnInit(): void {}

  onDataFormBuilder(sharedFormbuilder) {
    console.log('global', sharedFormbuilder);
    this.emitFormBuilder.emit(sharedFormbuilder);
  }
}
