<div class="row">
    <div class="col-6">

        <mat-form-field  class="full-width" appearance="standard">
            <mat-label>Type rendez-vous d'opportunité</mat-label>
            <mat-spinner *ngIf="loaderRDV==true" [diameter]="20"></mat-spinner>
            <mat-select *ngIf="loaderRDV==false" (selectionChange)="selectTypeRdv($event.value)" [formControl]="opportunitieTypeForm">
                <mat-option *ngFor="let option of listTypeRendezVous" value="{{option.id}}">{{option.libelle}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-6">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Affecter à</mat-label>
            <input type="text" matInput [formControl]="affecterAFormControl" [matAutocomplete]="auto">
            <mat-autocomplete (optionSelected)="selectCommercieaux($event.option.value)" autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of listCommerciauxFilter | async" [value]="option">
                    {{option.nom + ' ' + option.prenom}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="col-12 card-statut" *ngIf="this.calendarClone.id_type_opportunity" style="margin-top: 20px;">
        <ng-template #elseBlock>
            <div style="color: #d40d0d; padding: 10px; border: solid 1px; border-radius: 15px ">

                <mat-icon style="margin-right: 15px">warning</mat-icon>
                <p>Choisir une campagne pour selectionner le status!</p>
            </div>
        </ng-template>
        <ng-container *ngIf="listStatus?.length>0; else elseBlock">
            <h4>Statut sélectionné: <span style="font-size: 20px; margin-left: 15px"> <b>{{calendar.statusLibelle}}</b></span>
            </h4>
            <p>Changer le statut de départ:</p>
            <div class="row">
                <div class="col-12">
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                            <li class="mat-tree-node" >
                                <!-- <button mat-icon-button></button>
                                <mat-checkbox color="primary" class="checklist-leaf-node" [checked]="selectedStatuss.id==node.id" (change)="todoLeafItemSelectionToggle(node)">
                                    {{node.item}}</mat-checkbox> -->

                                    <mat-radio-button name="libelle_cat_parent" [checked]="node.id == selectedStatuss.id" (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-radio-button>
                            </li>
                        </mat-tree-node>
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                            <li>
                                <div class="mat-tree-node" *ngIf="node.expandable ">
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                  </button> {{node.item}}
                                </div>
                                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </ul>
                            </li>
                        </mat-nested-tree-node>
                    </mat-tree>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="calendarClone.id_type_opportunity && calendarClone.id_type_opportunity != 4 && calendarClone.affecter" class="calendar-container">
    <div *ngIf="!updateListRdv" class="loader-span">
        <div class="row" style="margin-top: 150px">
            <div class="col-12 text-center">
                <mat-spinner [diameter]="30" style="margin: auto"></mat-spinner>
            </div>
        </div>
    </div>
    <div [ngStyle]="{'opacity': updateListRdv? '1':'1'}" style="margin-top: 40px;" class="row">
        <div class="col-12">
            <h2>Réservez un rendez-vous</h2>
        </div>
        <div class="col-12">
            <full-calendar #calendar [options]="calendarOptions">
            </full-calendar>
        </div>
    </div>
</div>

<ng-template #OppUpdateRdvModal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Parametres Rendez-vous</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">×</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Date de début" [formControl]="dateDebutControl" (dateChange)="changerdatedebut($event)" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker1 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Date de Fin" [formControl]="dateFinControl" (dateChange)="changerdatefin($event)" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker2 [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" style="float: left;" (click)="modalService.dismissAll()">
      fermer
    </button>
        <button (click)="updateRdvDetails()" type="button" class="btn btn-outline-success" style="float: right;">Enregistrer
    </button>
    </div>
</ng-template>
