import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Compagnie } from 'src/app/entity/Compagnie';
import { Contrat } from 'src/app/pages/gestion-gammes/fiche-gestion-gamme/fiche-gestion-gamme.component';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { UserList } from 'src/app/entity/User/UserList';
import { Produit } from 'src/app/entity/Produit';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { ApiProduitsService } from 'src/app/services/ApiPoduits/api-poduits.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from '@angular/material/tree';

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  id: string;
}
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  id: string;
}

@Injectable({ providedIn: 'root' })
export class ChecklistDatabase {
  dataChange: BehaviorSubject <TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor() {
    this.initialize();
  }

  initialize() {
  }

  buildFileTree(obj: { [key: string]: any }, level: number, keyLevels: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];

      const node = new TodoItemNode();

      node.item = key;

      if (value === null || value === undefined) {
        // no action
      } else if (typeof value === 'object' && level < keyLevels) {
        node.item = value['name']
        node.children = this.buildFileTree(value['children'], level + 1, keyLevels);
      } else if (typeof value === 'object') {
        node.item = value['etat'];
        node.id = value['id']
        if (value['child']?.length > 0) {
          node.children = this.buildFileTree(value['child'], level + 1, keyLevels);
        }
      } else {
        node.item = value;
        node.id = value;
      }

      return accumulator.concat(node);
    }, []);
  }
}

class Affaire {
  id_opp;
  ligne_produit;
  cmp;
  nom_produit;
  date_effet;
  user_id;
  souscription;
  etat_affaire;
  id_produit;
}
class Gamme {
  id;
  libelle;
}



/**
 * The Json object for to-do list data.
 */
const TREE_DATA = {
  Groceries: {
    'Almond Meal flour': null,
    'Organic eggs': null,
    'Protein Powder': null,
    Fruits: {
      Apple: null,
      Berries: ['Blueberry', 'Raspberry'],
      Orange: null,
    },
  },
  Reminders: ['Cook dinner', 'Read the Material Design spec', 'Upgrade Application to Angular'],
};

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */


@Component({
  selector: 'ngbd-add-affaire-modal-content',
  styleUrls: ['./add-affaire-modal.component.css'],
  templateUrl: './add-affaire-modal.component.html',
  providers: [ChecklistDatabase],
})
export class NgbdmodalContent implements OnInit {

  fa = fa;
  @Input() public Id_opp: string;
  public event: EventEmitter<any> = new EventEmitter();

  listAffect: UserList[] = [];
  listLigneProd: Contrat[] = [];
  listCompag: Compagnie[] = [];
  listGamme: Gamme[] = [];
  listProduit: Produit[] = [];
  datedeffet: string;
  datedsous: string;
  affaire: Affaire;
  Compagnie: Compagnie[] = [];
  Contrat: Contrat[] = null;
  Gamme: Gamme[] = [];
  Produit: Produit[] = [];
  id_contrat: string = '';
  id_produit: string = '';
  id_compagnie: string = '';
  id_gamme: string = '';

  affecterAFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  affecterLigneAFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  affecterACompagnie: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  affecterAGamme: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  affecterAproduit: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  affecterStatut: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  listCommerciauxFilter: Observable<UserList[]> = null;
  listLignedePorduitFilter: Observable<Contrat[]> = null;
  listCompagnieFilter: Observable<Compagnie[]> = null;
  listGammeFilter: Observable<Gamme[]> = null;
  listProduitFilter: Observable<Produit[]> = null;
  listStatutFilter: Observable<Produit[]> = null;

  affecte: string = '';
  affecteContrat: string = '';
  affecteStatut: string = '';
  affecteCompagne: string = '';
  affecteProduit: string = '';
  show_loading_gamme: boolean = false;
  show_loading_produit: boolean = false;
  show_loading_statut: boolean = false;
  disabledlist: boolean = false;
  disabledlistproduit: boolean = false;
  disabledliststatut: boolean = false;
  show_loading_add: boolean = false;
  gammeAppear: boolean = true;
  produitAppear: boolean = true;
  commercial_affecte: any;
  ligneAffect_affecte: any;
  affecteStatus: string = '';
  compagnieAffect: any;

  StatutDepart = true;
  @ViewChild('closebuttonAffaire') closebuttonAffaire;
  Statut: any[];
  tree: any= [];
  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();
  selectedStatuss:any;


  /** The selection for checklist */
  checklistSelection = new SelectionModel<any>(false /* multiple */);
  etat_affaire: any;

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: any): void {
    this.checklistSelection.toggle(node);
    const selectedStatus = this.checklistSelection.selected[0];
    if (selectedStatus !== undefined || selectedStatus !== null) {
      this.etat_affaire  = selectedStatus.id;

    } else {
      this.etat_affaire  = null;

    }
  }


  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;
  status_depart = null;
  private listStatus: any[] = [];

  // permet de fermer le popup parrainage
  constructor(
    private checklistDatabase: ChecklistDatabase,
    private datePipe: DatePipe,
    private routes: Router,
    private apiProspectsService: ApiProspectsService,
    private notificationsService: NotificationsService,
    private apiProduitService: ApiProduitsService,
    private apiUsersService: ApiUsersService,
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    this.getListCommerciaux();
    this.listeAffect();
    this.getLigneProd();
    this.listeAffectLigneProd();
    this.getCompagne();
   // this.getStatut();
    this.listeCompagne();
  }


  expand(data: TodoItemNode[], uniqueId: string): any {
    data.forEach(node => {
      if (node.children && node.children.find(c => c.id === uniqueId)) {
        this.treeControl.expand(node);
        this.checklistSelection.isSelected(this.selectedStatuss.id);
        this.expand(this.dataSource.data, node.id);
      }
      else if (node.children && node.children.find(c => c.children)) {
        this.expand(node.children, uniqueId);
      }
    });
  }
  // ligne produit ----------------------->

  selectCompagnie(event) {
    this.id_compagnie = event.siren;
    if (this.id_contrat !== '') {
      this.show_loading_gamme = true;
      this.getListGamme(this.id_contrat, this.id_compagnie);
      this.getGamme();
    }
  }

  selectContrat(event) {
    this.id_contrat = event.id;
    if (this.id_compagnie !== '') {
      this.show_loading_gamme = true;
      this.getListGamme(this.id_contrat, this.id_compagnie);
      this.getGamme();
    }
    this.getListStatut(this.id_contrat);
  }

  selectGamme(event) {
    this.id_gamme = event.id;
    this.show_loading_produit = true;
    this.getListProduit(this.id_gamme);
    this.getProduit();
  }

  SaveAffaire() {
    const notificationMessage = new NotificationMessage();
    notificationMessage.type = NotificationType.warning;
    if (this.Id_opp === '' || this.Id_opp === undefined) {
      notificationMessage.message = 'Veuillez remplir le champ Id !';
      this.notificationsService.sendMessage(notificationMessage);
      return;
    } else if (this.affecterAFormControl.status === 'INVALID') {
      notificationMessage.message = 'Veuillez selectionner un commercial!';
      this.notificationsService.sendMessage(notificationMessage);
      return;
    } else if (this.affecterLigneAFormControl.status === 'INVALID') {
      notificationMessage.message = 'Veuillez selectionner une ligne de produit!';
      this.notificationsService.sendMessage(notificationMessage);
      return;
    } else if (this.affecterACompagnie.status === 'INVALID') {
      notificationMessage.message = 'Veuillez selectionner une compagnie!';
      this.notificationsService.sendMessage(notificationMessage);
      return;
    }

    if (this.affecterAGamme.status === 'INVALID') {
      notificationMessage.message = 'Veuillez selectionner une gamme!';
      this.notificationsService.sendMessage(notificationMessage);
    } else if (this.affecterAproduit.status === 'INVALID') {
      notificationMessage.message = 'Veuillez selectionner un produit!';
      this.notificationsService.sendMessage(notificationMessage);
    } else if (this.datedeffet === '' || this.datedeffet === undefined) {
      notificationMessage.message = 'Veuillez remplir le champ date!';
      this.notificationsService.sendMessage(notificationMessage);
    } else if (this.datedsous === '' || this.datedsous === undefined) {
      notificationMessage.message = 'Veuillez remplir le champ date!';
      this.notificationsService.sendMessage(notificationMessage);
    } else {
      this.show_loading_add = true;
      this.affaire = new Affaire();
      this.show_loading_add = true;
      this.affaire.ligne_produit = this.affecterLigneAFormControl.value.id;
     this.affaire.etat_affaire = this.etat_affaire;
      this.affaire.id_opp = this.Id_opp;
      this.affaire.nom_produit = this.affecterAproduit.value.libelle;
      this.affaire.id_produit = this.affecterAproduit.value.id;
      this.affaire.cmp = this.affecterACompagnie.value.siren;
      this.affaire.user_id = this.affecterAFormControl.value.id;
      this.affaire.date_effet = this.datePipe.transform(this.datedeffet, 'yyyy-MM-dd');
      this.affaire.souscription = this.datePipe.transform(this.datedsous, 'yyyy-MM-dd');

      this.apiProspectsService.addAffaire(this.affaire).subscribe(
        (response) => {
          this.closebuttonAffaire.nativeElement.click();
          this.show_loading_add = false;
          this.alertSuccess('Affaire ajoutée avec succée', response.id_affaire);
        },
        (error) => {
          this.show_loading_add = false;
          this.alertError("Erreur lors de l'ajout d'une affaire: => " + error);
        }
      );
    }
  }

  public displayContactFn(contact?: UserList): string | undefined {
    this.commercial_affecte = contact?.id;
    return contact ? contact.nom + ' ' + contact.prenom : null;
  }

  public displayLingeFn(contrat?: Contrat): string | undefined {
    this.ligneAffect_affecte = contrat?.id;
    return contrat ? contrat.type : null;
  }

  public displayCompagnieFn(compagnie?: Compagnie): string | undefined {
    this.compagnieAffect = compagnie?.siren;
    return compagnie ? compagnie.denomination : null;
  }

  public displayGammeFn(gamme?: Gamme): string | undefined {
    this.affecterAGamme = gamme?.id;
    return gamme ? gamme.libelle : null;
  }

  public displayProduitFn(produit?: Produit): string | undefined {
    this.affecterAproduit = produit?.id;
    return produit ? produit.libelle : null;
  }

  getListStatut(id_LigneProduit: string) {
    this.apiProduitService.GetListStatutByLigneProduit(id_LigneProduit).subscribe((Response: any) => {
      this.disabledliststatut = true;
      this.show_loading_statut = false;
if(Response.etat_dossier.statut_depart !== null){
  this.selectedStatuss = Response.etat_dossier.statut_depart;

}else{
 this. selectedStatuss =''
}
if(Response?.etat_dossier?.tree){
  this.listStatus = this.checklistDatabase.buildFileTree(Response?.etat_dossier?.tree, 0, 1);

}else{
  this.listStatus=[];
}

  if (this.dataSource.data !== this.listStatus) {
    this.dataSource.data = this.listStatus;
    this.expand(this.dataSource.data, this.selectedStatuss.id);
  }
        this.Statut = Response.etat_dossier;
        this.tree = Response.etat_dossier.tree

     /*    if (Response.etat_dossier.status_depart !== null) {
        this.affecteStatut = Response.etat_dossier.status_depart.id;
        const affected_to_statut: any = this.Statut.filter((elem) => elem.id === this.affecteStatut).pop();
        if (affected_to_statut !== undefined) this.affecterStatut.setValue(affected_to_statut);
      } else {
        this.StatutDepart = false;
      } */
      //  if(Response.status_depart !==null) this.affecteStatut=Response.status_depart.id;
    });
  }

  /* zone commercial */

  getListCommerciaux() {
    this.listCommerciauxFilter = this.affecterAFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.nom + ' ' + value.prenom)),
      map((value) => {
        return this._affecterAfilter(value);
      })
    );
  }

  listeAffect() {
    this.apiUsersService.getAllUsers().subscribe((data) => {
      this.listAffect = data;
      const affected_to_user: UserList = this.listAffect.filter((elem) => elem.id === this.affecte).pop();
      if (affected_to_user !== undefined)
        this.affecterAFormControl.setValue(affected_to_user.nom + ' ' + affected_to_user.prenom);
    });
  }

  private _affecterAfilter(value: string): UserList[] {
    const filterValue = value?.toLowerCase();
    return this.listAffect.filter(
      (option) => (option.nom + ' ' + option.prenom)?.toLowerCase().indexOf(filterValue) > -1
    );
  }

  /* fin zone commercial */
  /* zone ligne */
  getLigneProd() {
    this.listLignedePorduitFilter = this.affecterLigneAFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.type)),
      map((value) => {
        return this._affecterfilterLigneProd(value);
      })
    );
  }

  listeAffectLigneProd() {
    this.apiProduitService.GetListContrat().subscribe((data) => {
      this.listLigneProd = data;
      const affected_to_ligne: Contrat = this.listLigneProd.filter((elem) => elem.id === this.affecteContrat).pop();
      if (affected_to_ligne !== undefined) this.affecterLigneAFormControl.setValue(affected_to_ligne.type);
    });
  }

  private _affecterfilterLigneProd(value: string): Contrat[] {
    const filterValue = value?.toLowerCase();
    return this.listLigneProd.filter((option) => option.type?.toLowerCase().indexOf(filterValue) > -1);
  }

  /* fin zone Ligne */
  /* zone Compagne */
  getCompagne() {
    this.listCompagnieFilter = this.affecterACompagnie.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.denomination)),
      map((value) => {
        return this._affecterfilterCompagne(value);
      })
    );
  }

  listeCompagne() {
    this.apiProduitService.getListCompagnesGamme().subscribe((data) => {
      this.Compagnie = data;
      const affected_to_Compagnie: Compagnie = this.Compagnie.filter(
        (elem) => elem.siren === this.affecteCompagne
      ).pop();
      if (affected_to_Compagnie !== undefined) this.affecterACompagnie.setValue(affected_to_Compagnie.denomination);
    });
  }

  private _affecterfilterCompagne(value: string): Compagnie[] {
    const filterValue = value?.toLowerCase();
    return this.Compagnie.filter((option) => option.denomination?.toLowerCase().indexOf(filterValue) > -1);
  }
  /* fin Compagnee */
  /* zone Gamme */

  getGamme() {
    this.listGammeFilter = this.affecterAGamme.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.libelle)),
      map((value) => {
        return this._affecterfilterGamme(value);
      })
    );
  }

  getListGamme(id_contrat: string, id_compagnie: string) {
    this.apiProduitService.GetListGammewithparams(id_contrat, id_compagnie).subscribe((Response: Gamme[]) => {
      if (Response.length === 0) this.gammeAppear = false;

      this.Gamme = Response.filter((element) => element.libelle !== '');
      this.Gamme = Response;
      const affected_to_Gamme: Gamme = this.Gamme.filter((elem) => elem.id === this.affecteCompagne).pop();
      if (affected_to_Gamme !== undefined) this.affecterAGamme.setValue(affected_to_Gamme.libelle);
      this.disabledlist = true;
      this.disabledlistproduit = false;
      this.Produit = null;
      this.show_loading_gamme = false;
    });
  }

  private _affecterfilterGamme(value: string): Gamme[] {
    const filterValue = value?.toLowerCase();
    return this.Gamme.filter((option) => option.libelle?.toLowerCase().indexOf(filterValue) > -1);
  }
  /* fin gamme */
  /* zone produit */

  getProduit() {
    this.listProduitFilter = this.affecterAproduit.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.libelle)),
      map((value) => {
        return this._affecterfilterProduit(value);
      })
    );
  }

  getListProduit(id_gamme: string) {
    this.apiProduitService.GetListProduitByGamme(id_gamme).subscribe((Response: Produit[]) => {
      this.disabledlistproduit = true;
      this.show_loading_produit = false;
      this.Produit = Response;
      if (Response.length === 0) this.produitAppear = false;
      const affected_to_Produit: Produit = this.Produit.filter((elem) => elem.id === this.affecteProduit).pop();
      if (affected_to_Produit !== undefined) this.affecterAproduit.setValue(affected_to_Produit.libelle);
    });
  }

  private _affecterfilterProduit(value: string): Produit[] {
    const filterValue = value?.toLowerCase();
    return this.Produit.filter((option) => option.libelle?.toLowerCase().indexOf(filterValue) > -1);
  }

  /* fin zone produit */
  /// //------- les alerts ---------//////

  alertSuccess(data, idaffaire) {
    swal
      .fire({
        title: 'Opération Réussie',
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        denyButtonText: 'Nouvelle affaire',
        denyButtonColor: '833626',
        cancelButtonText: 'Liste des affaires',
        confirmButtonText: "Modifier l'affaire",
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.routes.navigate(['/affaires/details/' + idaffaire]);
        }
        if (result.isDismissed) {
          this.routes.navigate(['/affaires']);
        }
        if (result.isDenied) {
          this.event.emit();
        }
      });
  }

  alertError(data) {
    swal.fire({
      title: "Quelque chose s'est mal passé!",
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }



}
