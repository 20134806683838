import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ButtonsComponent} from "./buttons/buttons.component";
import {DataTableComponent} from "./data-table/data-table.component";
import {FormFieldsComponent} from "./form-fields/form-fields.component";
import {InputsComponent} from "./inputs/inputs.component";

const routes: Routes = [
  {path: 'buttons', component: ButtonsComponent},
  {path: 'data_table', component: DataTableComponent},
  {path: 'form_fields', component: FormFieldsComponent},
  {path: 'form_inputs', component: InputsComponent},
  {path: '**', redirectTo: 'buttons'}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRouting {
}
