import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DetailsOpportuniteStatus } from './details-opportunite.reducers';

export const getDetailsOpportuniteState = createFeatureSelector<DetailsOpportuniteStatus>('detailsOpportunite');

export const getEntrepriseStateFromDetailsOpportunite = createSelector(
  getDetailsOpportuniteState,
  (state: DetailsOpportuniteStatus) => state.entreprise
);
export const getInfoSpecifiqueFromDetailsOpportunite = createSelector(
  getDetailsOpportuniteState,
  (state: DetailsOpportuniteStatus) => state.informationSpecifiques
);
export const getInfoComplementaireFromDetailsOpportunite = createSelector(
  getDetailsOpportuniteState,
  (state: DetailsOpportuniteStatus) => state.informationComplementaires
);
export const getContratActuelFromDetailsOpportunite = createSelector(
  getDetailsOpportuniteState,
  (state: DetailsOpportuniteStatus) => state.constractActuel
);
export const getStateFromDetailsOpportunite = createSelector(
  getDetailsOpportuniteState,
  (state: DetailsOpportuniteStatus) => state
);
export const getEntrepriseFromDetailsOpportunite = createSelector(
  getEntrepriseStateFromDetailsOpportunite,
  (state) => state.prospectUpdate
);
export const getEntrepriseStatusFromDetailsOpportunite = createSelector(
  getEntrepriseStateFromDetailsOpportunite,
  (state) => state.status
);
export const getOpportuniteDetails = createSelector(
  getDetailsOpportuniteState,
  (state) => state.details
);

export const getOpportuniteTypeRdv = createSelector(
  getOpportuniteDetails,
  (state) => {
    return {
      type_opportunite: state?.opportunite_details?.type_opportunite,
      startRdv: state?.opportunite_details?.startRdv,
      endRdv: state?.opportunite_details?.endRdv,
      affecte: state?.opportunite_details?.commerciaux_id
    };
  }
);
export const getOpportuniteId = createSelector(
  getOpportuniteDetails,
  (state) => state?.id
);
