export class Simulation {
  titre: string = '';
  garantie: string = '';
  codePostal: string = '';
  purchasePrice: any;
  purchaseDate: string = '';
  TrackerGPS: any = '';
  PAOption: any;
  NVOption: any;
  isAssitance: any;
  franchise: string = '';
  lc: string = '';
  monthPrice: number;
  yearPrice: number;
  AdministrationFees: Number;
  poucentage: Number;
  quoteRef: string;
  storeRef: string;
}
