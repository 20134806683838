import { MailevaElement } from "./MailevaElement";

export class MailEvaList {
  aaData: MailevaElement[] = [];
  iTotalDisplayRecords: number;
  iTotalCourrierRecords: number;
  iTotalSuccessRecords: number;
  iTotalPendingRecords: number;
  iTotalErrorRecords: number;
}
