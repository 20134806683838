import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../../../shared/shared.module';
import {FiltersStatusComponent} from './filters-status/filters-status.component';
import {FiltersDistinataireComponent} from './filters-distinataire/filters-distinataire.component';
import {FiltersCreateursComponent} from './filters-createurs/filters-createurs.component';
import {FiltersCourrierComponent} from './filters-courrier/filters-courrier.component';
import { FiltersExpiditeurComponent } from './filters-expiditeur/filters-expiditeur.component';



@NgModule({
  declarations: [
    FiltersStatusComponent, 
    FiltersDistinataireComponent, 
    FiltersCreateursComponent, 
    FiltersCourrierComponent, 
    FiltersExpiditeurComponent, 

  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    FiltersStatusComponent, 
    FiltersDistinataireComponent, 
    FiltersCreateursComponent, 
    FiltersCourrierComponent,  
    FiltersExpiditeurComponent, 

  ]
})
export class FiltresMailevaModule { }
