import { Component, OnInit } from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-radio-type',
  templateUrl: './radio-type.component.html',
  styleUrls: ['./radio-type.component.scss']
})
export class RadioTypeComponent extends FieldType implements OnInit {

  ngOnInit(): void {
  }

}
