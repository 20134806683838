<div class="prospect-card-field" [ngClass]="{'cover': !allowed_to_update_affaire}">
    <div class="form-group-container" [ngClass]="{'shadow-none p-0': customStyle === 'true'}">
        <p *ngIf="customStyle !== 'true'" class="form-group-container-title">- Informations bancaires </p>
        <!-- <form #bankForm="ngForm" (change)="changeBankInformation()">
            <ng-container *ngFor="let bank of listBank; let index = index">
                <div class="row">
                    <label>Informations bancaires {{index+1}}:</label>
                </div>
                <div class="row d-flex align-items-center">
                    <div>
                        <button *ngIf="listBank.length == 2" type="button" class="btn-dt-delete" (click)="removeBank(index)">
                            <img src="assets/icons/trash-icon.svg">
                        </button>
                    </div>
                    <div class="col-11">
                        <div class="row">
                           
                            <div class="col-2">
                                <mat-form-field class="full-width" appearance="legacy">
                                    <mat-label>Type de IBAN</mat-label>
                                    <mat-select name="type_iban{{index}}" [(ngModel)]='listBank[index].type_iban' [disabled]="typeEntity === 'opportunite'" (selectionChange)="changeBankInformation()">
                                        <mat-option value="prélèvement">Prélèvement</mat-option>
                                        <mat-option value="prestation">Prestation</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                               
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>IBAN</mat-label>
                                    <input matInput id="ibanNumber{{index}}" name="ibanNumber{{index}}" [(ngModel)]="listBank[index].iban" (change)="smellsLikeIban(index)">
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-form-field class="full-width" appearance="legacy">
                                    <mat-label>Nom de la banque</mat-label>
                                    <input matInput type="text" name="nom_banque{{index}}" [(ngModel)]="listBank[index].nom_banque">
                                </mat-form-field>
                            </div>
                            
                            <div class="col-md-2">
                                <mat-form-field class="full-width" appearance="legacy">
                                    <mat-label>Titulaire de compte</mat-label>
                                    <input matInput type="text" name="titulaire_compte{{index}}" [(ngModel)]="listBank[index].titulaire_compte">
                                </mat-form-field>
                            </div>
                            
                            <div class="col-md-2">
                                <mat-form-field class="full-width" appearance="legacy">
                                    <mat-label>Code BIC</mat-label>
                                    <input matInput type="text" name="bic_swift{{index}}" [(ngModel)]="listBank[index].bic_swift">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <button *ngIf="listBank?.length == 1" type="button" class="btn-add-iban" (click)="addBank()">
                <fa-icon class="mr-1 fa-sm " [icon]="fa['faPlus']"></fa-icon>
                Ajouter un IBAN
            </button>
        </form> -->
        <ng-container *ngFor="let bank of listBank; let index = index">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel class="expension-secondary-color">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="font-primary-geoprod">Informations compte bancaire {{index+1}}:</h2>
                    </mat-panel-title>
                    <div>
                        <button *ngIf="listBank.length == 2" class="icon" type="button"  (click)="removeBank(index)">
                            <fa-icon  [icon]="fa['faTrash']"></fa-icon>
                        </button>
                    </div>
                </mat-expansion-panel-header>
                <div class="row ">
                    
                    <div class="col-8 mx-auto">
                        <div class="row">
                           
                            <div class="col-12">
                               
                                
                                   
                                    <mat-radio-group  name="type_iban{{index}}" [(ngModel)]='listBank[index].type_iban' [disabled]="typeEntity === 'opportunite'" (change)="changeBankInformation()" aria-label="Select an option">
                                        
                                        <div class="row m-1">
                                            <div class="col" *ngFor="let type of list_type_iban">
                                                <mat-radio-button class="example-radio-button " [value]="type.value">
                                                    
                                                <div class="typeIban" [ngClass]="{'activeDiv': listBank[index].type_iban === type.value}">
                                                    
                                                    <svg class="iconSVG"  width="48" [class.white-fill]="listBank[index].type_iban === 'prélèvement'" *ngIf="type.value==='prélèvement'" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0008 13.8008C24.6635 13.8008 25.2008 14.338 25.2008 15.0008V15.7808H25.6808C28.2332 15.7808 30.2208 17.9289 30.2208 20.4808C30.2208 21.1435 29.6835 21.6808 29.0208 21.6808C28.358 21.6808 27.8208 21.1435 27.8208 20.4808C27.8208 19.1527 26.8083 18.1808 25.6808 18.1808H25.2008V23.135L27.4232 23.9102C28.105 24.1529 28.8483 24.5219 29.3991 25.2272C29.9605 25.9462 30.2008 26.8558 30.2008 27.9408C30.2008 30.2451 28.4205 32.2008 26.1008 32.2008H25.2008V33.0008C25.2008 33.6635 24.6635 34.2008 24.0008 34.2008C23.338 34.2008 22.8008 33.6635 22.8008 33.0008V32.2008H22.3408C19.7883 32.2008 17.8008 30.0527 17.8008 27.5008C17.8008 26.838 18.338 26.3008 19.0008 26.3008C19.6635 26.3008 20.2008 26.838 20.2008 27.5008C20.2008 28.8289 21.2132 29.8008 22.3408 29.8008H22.8008V24.8396L20.5984 24.0713C19.9241 23.8313 19.1778 23.469 18.6245 22.7644C18.0597 22.0452 17.8208 21.1327 17.8208 20.0408C17.8208 17.7617 19.5961 15.7808 21.9208 15.7808H22.8008V15.0008C22.8008 14.338 23.338 13.8008 24.0008 13.8008ZM22.8008 18.1808H21.9208C21.0455 18.1808 20.2208 18.9598 20.2208 20.0408C20.2208 20.7889 20.3818 21.1163 20.512 21.2821C20.6534 21.4622 20.8963 21.6295 21.3998 21.8091L22.8008 22.2978V18.1808ZM25.2008 25.6768V29.8008H26.1008C26.981 29.8008 27.8008 29.0365 27.8008 27.9408C27.8008 27.2057 27.6411 26.8754 27.5075 26.7043C27.3636 26.5201 27.1177 26.3494 26.6218 26.1725L25.2008 25.6768Z"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0008 5.20078C13.6235 5.20078 5.20078 13.6235 5.20078 24.0008C5.20078 34.378 13.6235 42.8008 24.0008 42.8008C34.378 42.8008 42.8008 34.378 42.8008 24.0008C42.8008 23.338 43.338 22.8008 44.0008 22.8008C44.6635 22.8008 45.2008 23.338 45.2008 24.0008C45.2008 35.7035 35.7035 45.2008 24.0008 45.2008C12.298 45.2008 2.80078 35.7035 2.80078 24.0008C2.80078 12.298 12.298 2.80078 24.0008 2.80078C24.6635 2.80078 25.2008 3.33804 25.2008 4.00078C25.2008 4.66352 24.6635 5.20078 24.0008 5.20078ZM34.8008 4.00078C34.8008 3.33804 35.338 2.80078 36.0008 2.80078H44.0008C44.6635 2.80078 45.2008 3.33804 45.2008 4.00078V12.0008C45.2008 12.6635 44.6635 13.2008 44.0008 13.2008C43.338 13.2008 42.8008 12.6635 42.8008 12.0008V6.89784L34.8493 14.8493C34.3807 15.3179 33.6209 15.3179 33.1523 14.8493C32.6836 14.3807 32.6836 13.6209 33.1523 13.1523L41.1037 5.20078H36.0008C35.338 5.20078 34.8008 4.66352 34.8008 4.00078Z" />
                                                        </svg>
                                                        
                                                        <p *ngIf="type.value==='prélèvement'" [class.active]="listBank[index].type_iban === 'prélèvement'">{{type.key}}</p>
                                                        <svg class="iconSVG" width="48" [class.white-fill]="listBank[index].type_iban === 'prestation'" *ngIf="type.value==='prestation'" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0008 13.8008C24.6635 13.8008 25.2008 14.338 25.2008 15.0008V15.7808H25.6808C28.2332 15.7808 30.2208 17.9289 30.2208 20.4808C30.2208 21.1435 29.6835 21.6808 29.0208 21.6808C28.358 21.6808 27.8208 21.1435 27.8208 20.4808C27.8208 19.1527 26.8083 18.1808 25.6808 18.1808H25.2008V23.135L27.4232 23.9102C28.105 24.1529 28.8483 24.5219 29.3991 25.2272C29.9605 25.9462 30.2008 26.8558 30.2008 27.9408C30.2008 30.2451 28.4205 32.2008 26.1008 32.2008H25.2008V33.0008C25.2008 33.6635 24.6635 34.2008 24.0008 34.2008C23.338 34.2008 22.8008 33.6635 22.8008 33.0008V32.2008H22.3408C19.7883 32.2008 17.8008 30.0527 17.8008 27.5008C17.8008 26.838 18.338 26.3008 19.0008 26.3008C19.6635 26.3008 20.2008 26.838 20.2008 27.5008C20.2008 28.8289 21.2132 29.8008 22.3408 29.8008H22.8008V24.8396L20.5984 24.0713C19.9241 23.8313 19.1778 23.469 18.6245 22.7644C18.0597 22.0452 17.8208 21.1327 17.8208 20.0408C17.8208 17.7617 19.5961 15.7808 21.9208 15.7808H22.8008V15.0008C22.8008 14.338 23.338 13.8008 24.0008 13.8008ZM22.8008 18.1808H21.9208C21.0455 18.1808 20.2208 18.9598 20.2208 20.0408C20.2208 20.7889 20.3818 21.1163 20.512 21.2821C20.6534 21.4622 20.8963 21.6295 21.3998 21.8091L22.8008 22.2978V18.1808ZM25.2008 25.6768V29.8008H26.1008C26.981 29.8008 27.8008 29.0365 27.8008 27.9408C27.8008 27.2057 27.6411 26.8754 27.5075 26.7043C27.3636 26.5201 27.1177 26.3494 26.6218 26.1725L25.2008 25.6768Z" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0008 5.20078C13.6235 5.20078 5.20078 13.6235 5.20078 24.0008C5.20078 34.378 13.6235 42.8008 24.0008 42.8008C34.378 42.8008 42.8008 34.378 42.8008 24.0008C42.8008 23.338 43.338 22.8008 44.0008 22.8008C44.6635 22.8008 45.2008 23.338 45.2008 24.0008C45.2008 35.7035 35.7035 45.2008 24.0008 45.2008C12.298 45.2008 2.80078 35.7035 2.80078 24.0008C2.80078 12.298 12.298 2.80078 24.0008 2.80078C24.6635 2.80078 25.2008 3.33804 25.2008 4.00078C25.2008 4.66352 24.6635 5.20078 24.0008 5.20078Z"/>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.8493 3.15225C45.3179 3.62088 45.3179 4.38068 44.8493 4.84931L36.8978 12.8008H42.0008C42.6635 12.8008 43.2008 13.338 43.2008 14.0008C43.2008 14.6635 42.6635 15.2008 42.0008 15.2008H34.0008C33.338 15.2008 32.8008 14.6635 32.8008 14.0008V6.00078C32.8008 5.33804 33.338 4.80078 34.0008 4.80078C34.6635 4.80078 35.2008 5.33804 35.2008 6.00078V11.1037L43.1523 3.15225C43.6209 2.68362 44.3807 2.68362 44.8493 3.15225Z" />
                                                            </svg>
                                                            
                                                            
                                                    <p *ngIf="type.value==='prestation'" [class.active]="listBank[index].type_iban ==='prestation'" >{{type.key}}</p>
                                                </div>
                                                
                                                </mat-radio-button>
                                            </div>
                                        </div>
                                      </mat-radio-group>
                               
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                               
                                <mat-form-field class="full-width" >
                                    <mat-label>IBAN</mat-label>
                                    <input matInput id="ibanNumber{{index}}" name="ibanNumber{{index}}" [(ngModel)]="listBank[index].iban" (change)="smellsLikeIban(index)">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="full-width" >
                                    <mat-label>Nom de la banque</mat-label>
                                    <input matInput type="text" name="nom_banque{{index}}" [(ngModel)]="listBank[index].nom_banque">
                                </mat-form-field>
                            </div>
                        </div>
                            <div  class="row">
                            <div class="col-md-6">
                                <mat-form-field class="full-width" >
                                    <mat-label>Titulaire de compte</mat-label>
                                    <input matInput type="text" name="titulaire_compte{{index}}" [(ngModel)]="listBank[index].titulaire_compte">
                                </mat-form-field>
                            </div>
                            
                            <div class="col-md-6">
                                <mat-form-field class="full-width" >
                                    <mat-label>Code BIC</mat-label>
                                    <input matInput type="text" name="bic_swift{{index}}" [(ngModel)]="listBank[index].bic_swift">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
    </div>
</div>