import { Component, OnInit } from '@angular/core';
import { ApiBusinessIntelligenceService } from '../../services/ApiBusinessIntelligence/api-business-intelligence.service';
import { SharedMenuObserverService } from '../../services/SharedMenuObserver/shared-menu-observer.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccessList } from 'src/app/entity/accessList';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';

class PBIConfig {
  id: number;
  embedUrl: string;
  reportId: string;
  accessToken: string;

  constructor(embedUrl: string, reportId: string, accessToken: string) {
    this.embedUrl = embedUrl;
    this.reportId = reportId;
    this.accessToken = accessToken;
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  pbiConfig: PBIConfig;
  acessList: AccessList;
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  public barChartLabels = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
  ];

  public doughnutChartLabels = ['Ventes Q1', 'Ventes Q2', 'Ventes Q3', 'Ventes Q4'];
  public doughnutChartData = [120, 150, 180, 90];
  public doughnutChartType = 'doughnut';
  public radarChartLabels = ['Q1', 'Q2', 'Q3', 'Q4'];
  public radarChartData = [
    { data: [120, 130, 180, 70], label: '2017' },
    { data: [90, 150, 200, 45], label: '2018' },
  ];

  public radarChartType = 'radar';
  public pieChartLabels = ['Ventes Q1', 'Ventes Q2', 'Ventes Q3', 'Ventes Q4'];
  public pieChartData = [120, 150, 180, 90];
  public pieChartType = 'pie';
  dashbordUrl: string;
  urlSafe: SafeResourceUrl;
  id_org: string;
  appearDash: boolean = false;
  constructor(
    private apiBusinessIntelligenceService: ApiBusinessIntelligenceService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiAuthentificationService: ApiAuthentificationService,

    private translate: TranslateService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.apiAuthentificationService.listOfAccess$.subscribe(
      (value) => {
        // this.acessList = value;
        this.apiBusinessIntelligenceService.getAuthDashboard(value.reporting_name).subscribe((data: any) => {
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.dashbordUrl +
              '?token=' +
              localStorage.getItem('Acces_Token') +
              '&reporting_path=' +
              value.reporting_name +
              '&geoprod_path=' +
              environment.api_url
          );
          this.appearDash = data.acces;
        });
      },
      (error) => {
        console.log(error);
      }
    );

    this.dashbordUrl = environment.dashboardUrl;

    this.apiBusinessIntelligenceService.getPowerBICredits().subscribe((data: PBIConfig) => {
      this.pbiConfig = data;
    });

    this.sharedMenuObserverService.updateMenu('Tableau de bord');
  }

  onEmbedded($event: number) {}
}
