import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ApiGitLabService} from "../../services/ApiGitLab/api-git-lab.service";
import {IssueGitlab} from "../../entity/IssueGitlab";

@Component({
  selector: 'app-gitlab-issue-bulle',
  templateUrl: './gitlab-issue-bulle.component.html',
  styleUrls: ['./gitlab-issue-bulle.component.css']
})
export class GitlabIssueBulleComponent implements OnInit {
  toggleform: boolean = false;
  load: boolean = false;
  issue: IssueGitlab=new IssueGitlab();

  constructor(private apiGitLabService:ApiGitLabService) { }

  ngOnInit(): void {

  }
  pushIssue(){
    this.load=true;
    this.apiGitLabService.pushIssue(this.issue).subscribe(
      data=>{
        this.load=false;
        this.toggleform=!this.toggleform;
        this.resetFrom();
      },
      error => {
        this.load=false
      }
    )
  }

  resetFrom() {

    this.issue=new IssueGitlab()
  }
}
