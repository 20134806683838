import { map, tap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { CycleVie } from 'src/app/entity/CycleVie';
import { Observable, Subscription } from 'rxjs';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import swal from 'sweetalert2';
import { CycleVieBpm } from 'src/app/entity/Bpm/CycleVieBpm';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import { element } from 'protractor';
@Component({
  selector: 'app-fiche-workflow',
  templateUrl: './fiche-workflow.component.html',
  styleUrls: ['./fiche-workflow.component.css']
})
export class FicheWorkflowComponent implements OnInit, OnDestroy {

  fa = fa;
  show_loading_add: boolean = false;
  cycle: CycleVie = new CycleVie();
  cycleBpm: CycleVieBpm = new CycleVieBpm();
  initialListeCycle: CycleVieBpm = new CycleVieBpm();
  libelle: string = '';
  listeCycle: any;
  list_invalid: any[] = [];
  etat_opp: any[] = [];
  list_invalid_string: string = '';
  statut_depart: any = [];
  initial_statut_depart: any = [];
  initial_libele: string = '';
  listeOfUnsubscriptionWebService: Subscription[] = [];
  libelleFormControl: FormControl = new FormControl('');
  affecterCycleFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  listCycleVieFilter: Observable<any[]> = null;
  listCycleVie: any[] = [];
  affecteCycle: string = '';
  cycleVie_affecte: any;
  @Input() id_etat_opp;
  @Input() ModeAjout;
  @Input() type;


  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private routes: Router,
    private route: ActivatedRoute
  ) { }

  ngOnDestroy(): void {
    this.listeOfUnsubscriptionWebService.forEach((element) => {
      element.unsubscribe();
    })
  }

  ngOnInit(): void {
    if(this.id_etat_opp!==''){
      if(this.type === 'c'){
        const unsubscribeGetCdvById = this.apiAdminBpmServices
        .getCdvById(this.id_etat_opp)
        .pipe(
          tap(({ cdv }) => {
            this.cycleBpm.libele = cdv.libele;
            this.statut_depart = cdv.statut_depart;
            this.initial_libele = cdv.libele;
            this.initial_statut_depart = cdv.statut_depart;
            this.cycleBpm = cdv;
            Object.assign(this.initialListeCycle, this.cycleBpm);
            this.hideloader();
          })
        )
        .subscribe(); this.listeOfUnsubscriptionWebService.push(unsubscribeGetCdvById);

      this.listeAffectCycleVie(this.id_etat_opp);
      }else{
        const unsubscribeGetCdvById = this.apiAdminBpmServices
        .getCdvPById(this.id_etat_opp)
       .pipe(
          tap(({ cycle_de_vie_prod }) => {
            this.cycleBpm.libele = cycle_de_vie_prod.libele;
            if(cycle_de_vie_prod.statut_depart_prod === null){
              this.statut_depart= ''
            }else{
              this.statut_depart = cycle_de_vie_prod.statut_depart_prod;
            }
            this.initial_libele = cycle_de_vie_prod.libele;
            this.initial_statut_depart = cycle_de_vie_prod.statut_depart_prod;
            this.cycleBpm = cycle_de_vie_prod;
            Object.assign(this.initialListeCycle, this.cycleBpm);
            this.hideloader();
          })
        ) 
        .subscribe(); 
        this.listeOfUnsubscriptionWebService.push(unsubscribeGetCdvById);

      this.listeAffectCycleVieProd(this.id_etat_opp);
      }
      
      }
        this.getCycleVieComm();
      

    if (this.ModeAjout) {
      if(this.type ==='c'){
        this.sharedMenuObserverService.updateMenu('Workflow commercial ➜ Ajouter');
      }else{
        this.sharedMenuObserverService.updateMenu('Workflow production ➜ Ajouter');

      }
    } else {
      if(this.type ==='c'){
        this.sharedMenuObserverService.updateMenu('Workflow commercial ➜ Modifier');
      }else{
        this.sharedMenuObserverService.updateMenu('Workflow production ➜ Modifier');

      }
    }
  }

  getCycleVieComm() {
    this.listCycleVieFilter = this.affecterCycleFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.libele)),
      map((value) => {

        return this._affecterfilterCycleVie(value);
        
      })
    );

  }

  
  listeAffectCycleVie(id_opp) {
    const unsubscribeGetListStatus = this.apiAdminBpmServices.getListStatus(id_opp).subscribe((data) => {
      this.listCycleVie = data.etat_opp_list;
      const affected_to_cycleVie: any = this.listCycleVie.filter((elem) => elem.id === this.cycleVie_affecte).pop();

      if (affected_to_cycleVie !== undefined) this.affecterCycleFormControl.setValue(affected_to_cycleVie.etat);
    }); this.listeOfUnsubscriptionWebService.push(unsubscribeGetListStatus);
  }
  listeAffectCycleVieProd(id_opp) {
    const unsubscribeGetListStatus = this.apiAdminBpmServices.getListStatusProd(id_opp).subscribe((data) => {
      this.listCycleVie = data.etat_dossier;
      const affected_to_cycleVie: any = this.listCycleVie.filter((elem) => elem.id === this.cycleVie_affecte).pop();

      if (affected_to_cycleVie !== undefined) this.affecterCycleFormControl.setValue(affected_to_cycleVie.etat);
    }); this.listeOfUnsubscriptionWebService.push(unsubscribeGetListStatus);
  }


  private _affecterfilterCycleVie(value: string): any[] {
    const filterValue = value?.toLowerCase();
    return this.listCycleVie.filter((option) => option.etat?.toLowerCase().indexOf(filterValue) > -1);
  }

  public displayCycleVieFn(cyclevie?: any): string | undefined {
    this.cycleVie_affecte = cyclevie?.id;
    if (typeof cyclevie === 'string') {
      return cyclevie || null;
    } else {
      return cyclevie ? cyclevie.etat : null;
    }
  }

  Enregistrer() {
    this.show_loading_add = true;
    if(this.type ==='c'){
           // workflow commercial
    if (this.ModeAjout === true) {
      if (!this.libelleFormControl.invalid) {
        const unsubscribeCreateCycleVie = this.apiAdminBpmServices.createCycleVie(this.cycleBpm).subscribe(
          (data) => {
            this.show_loading_add = false;
            this.alertSuccess(data.message, data.id, true);
          },
          (err) => {
            this.alertWarning(err);
            this.show_loading_add = false;
          }
        ); this.listeOfUnsubscriptionWebService.push(unsubscribeCreateCycleVie);
      }
    } else {
      if (this.statut_depart === undefined || this.statut_depart === '') {
        this.alertError('Champ statut est obligatoire', 'Il faut remplir tous les champs obligatoires');
        this.show_loading_add = false;
        return;
      }
      this.cycleBpm.statut_depart = this.statut_depart.id;
      this.cycleBpm.id = this.id_etat_opp;
      const unsubscribeupdateCycleVie = this.apiAdminBpmServices.updateCycleVie(this.cycleBpm).subscribe(
        (data) => {
          this.show_loading_add = false;
          this.alertSuccess(data.message, data.id, false);
        },
        (err) => {
          if (err) {
            this.show_loading_add = false;
          }
        }
      ); this.listeOfUnsubscriptionWebService.push(unsubscribeupdateCycleVie);
    }
  }else{
 // workflow production
 if (this.ModeAjout === true) {
  if (!this.libelleFormControl.invalid) {
    const unsubscribeCreateCycleVie = this.apiAdminBpmServices.createCycleVieProduction(this.cycleBpm).subscribe(
      (data) => {
        this.show_loading_add = false;
        this.alertSuccess(data.message, data.id, true);
      },
      (err) => {
        this.alertWarning(err);
        this.show_loading_add = false;
      }
    ); this.listeOfUnsubscriptionWebService.push(unsubscribeCreateCycleVie);
  }
 }else{
  if (this.statut_depart === undefined || this.statut_depart === '' || this.statut_depart=== null) {
    this.alertError('Champ statut est obligatoire', 'Il faut remplir tous les champs obligatoires');
    this.show_loading_add = false;
    return;
  }
  this.cycleBpm.statut_depart = this.statut_depart.id;
  this.cycleBpm.id = this.id_etat_opp;
  const unsubscribeupdateCycleVie = this.apiAdminBpmServices.updateCycleVieProduction(this.cycleBpm).subscribe(
    (data) => {
      this.show_loading_add = false;
      this.alertSuccess(data.message, data.id, false);
    },
    (err) => {
      if (err) {
        this.show_loading_add = false;
      }
    }
  ); this.listeOfUnsubscriptionWebService.push(unsubscribeupdateCycleVie);
 }
  }
  }

  alertSuccess(data, id, modeedit) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: 'Nouveau cycle de vie',
        denyButtonColor: '833626',
        cancelButtonText: 'Modifier cycle de vie',
        confirmButtonText: 'Liste des cycles des vies',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          if(this.type === 'c'){
            this.routes.navigate(['gestion-cycle-vies']);
          }else{
            this.routes.navigate(['gestion-cycle-vies-production']);
          }
        }
        if (result.isDismissed) {
          this.hideloader();
        
          if (this.ModeAjout){
            if(this.type === 'c'){
              this.routes.navigate(['/gestion-cycle-vies/fiche/' + id]);
            } 
          }else{
            this.routes.navigate(['/gestion-cycle-vies-production/fiche/' + id]);
            }
        }
        if (result.isDenied) {
          if(this.type === 'c'){
            this.routes.navigate(['/gestion-cycle-vies/fiche/nouveau']).then((result) => {
              this.hideloader();
              location.reload();
            });
          }else{
            this.routes.navigate(['/gestion-cycle-vies-production/fiche/nouveau']).then((result) => {
              this.hideloader();
              location.reload();
            });
          }
       
        }
      });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertWarning(data) {
    this.list_invalid_string = '';
    this.list_invalid.forEach((element) => {
      if (typeof element.name === 'string')
        this.list_invalid_string =
          this.list_invalid_string + '<li style="list-style-type: none; font-size: 14px">' + element.name + '</li>';
    });
    if (this.list_invalid_string.length > 0) {
      swal.fire({
        title: data,
        icon: 'warning',
        html: '<b>Il faut remplir tout les champs!</b><br>' + '<ul>' + this.list_invalid_string + '</ul>',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#e75e5e',
      });
    }
  }

  alertError(data, title) {
    swal.fire({
      title: title,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  retourToList() {
    if(this.type ==='c'){
      this.routes.navigate(['/gestion-cycle-vies']);
    }else{
      this.routes.navigate(['/gestion-cycle-vies-production']);
    }

  }

  clearForm() {

    if (!this.ModeAjout) {
      Object.assign(this.cycleBpm, this.initialListeCycle);
      this.ngOnInit()
    } else {
      this.cycleBpm = new CycleVieBpm();
      this.libelleFormControl.setErrors(null);
      this.affecterCycleFormControl.setErrors(null);
      this.cycleBpm.libele = '';
      this.statut_depart = '';
    }
  }

}
