<!-- <div class="table-responsive p-3">
    <table id="ticketsTable" class="table align-items-center">
        <thead class="text-center" style="background-color: #2a96ff1f;
        opacity: 0.6;">
            <tr>
                <th  
            
            
                <th       >Status
                </th>
                <th       >Categorie
                </th>
                <th       >Date Création
                </th>
                <th     >Date de traitement
                </th>
                <th   >Affaire
                </th>
                <th >Opportunité
                </th>
                <th >Action</th>
            </tr>
        </thead>
        <ng-container *ngIf="show_loading_add">
            <div class="col-12">
                <div class="spinner-style">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <tbody class="main-table-body text-center">

            <tr *ngFor="let item of listTickets; let index = index">
                <td class="style-tds">{{index+1}}</td>
                <td class="style-tds"> {{item.statut}}</td>
                <td class="style-tds"> {{item.categorie}}</td>
                <td class="style-tds"> {{item.date_creation}}</td>
                <td class="style-tds"> {{item.date_traitement}}</td>
                <td class="style-tds">

                    <a target="_blank" href="/affaires/details/{{item.id_affaire}}">
                        <img style="width: 24px;" src="assets/icons/share-icon.svg">
                    </a>
                </td>
                <td class="style-tds">
                    <a target="_blank" href="/opportunities/details/{{item.id_opp}}">
                        <img style="width: 24px;" src="assets/icons/share-icon.svg">
                    </a>
                </td>
                <td class="style-tds">
                    <a class="btn-modifier" href="ticketsandsupport/details/{{item.id}}">
                        <img src="../../../../../assets/icons/edit-icon.svg">
                    </a>
                </td>
            </tr>



 
        </tbody>
    </table>
</div> -->

<div class="flex-column bg-white rounded-card">
    <div class="p-3 mb-3">
        <table id="ticketsTable" class="table">
            <thead class="head-table">
                <tr>
                    <th> # </th>
                    <th>Status</th>
                    <th>Categorie </th>
                    <th>Date Création</th>
                    <th>Date de traitement</th>
                    <th>Affaire</th>
                    <th>Opportunité</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody class="body-table">
                <tr *ngFor="let item of listTickets; let index = index">
                    <td>{{index+1}}</td>
                    <td> {{item.statut}}</td>
                    <td> {{item.categorie}}</td>
                    <td> {{item.date_creation}}</td>
                    <td> {{item.date_traitement}}</td>
                    <td>

                        <a target="_blank" href="/affaires/details/{{item.id_affaire}}">
                            <img  src="assets/icons/share-icon.svg">
                        </a>
                    </td>
                    <td>
                        <a target="_blank" href="/opportunities/details/{{item.id_opp}}">
                            <img  src="assets/icons/share-icon.svg">
                        </a>
                    </td>
                    <td>
                        <button  type="button" class="btn-dt-edit" href="ticketsandsupport/details/{{item.id}}">
                            <img src="assets/icons/edit-icon.svg">
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
