<!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)"
                  >{{node.item}}</mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)"
                  >{{node.item}}</mat-checkbox>
  </mat-tree-node>
</mat-tree>
 -->
 <!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
          (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
          <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
      </button>
      <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
          (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
  </mat-tree-node>
</mat-tree> -->

<!-- <mat-tree class="tree-cat-content" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button type="button" mat-icon-button disabled></button>
      <mat-checkbox class="checklist-leaf-node" (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
  </mat-tree-node>
</mat-tree> -->


<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

  <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle *ngIf="node.expandable"
          [attr.aria-label]="'Toggle ' + node.item">
            <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            {{node.item}}
      </button>
    <mat-checkbox *ngIf="!node.expandable" (change)="todoLeafItemSelectionToggle(node,$event)">{{node.item}}</mat-checkbox>

  </mat-tree-node>
</mat-tree>