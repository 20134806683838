import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

enum FileType {
  PDF,
  AUDIO,
  FILE,
}

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {
  @Output() onChangeFiles: EventEmitter<string> = new EventEmitter();

  fa = fa;
  file: File = null;
  files: any[] = [];
  index = 0;
  uploadFile: {};
  showFile: string;
  audio: any[] = [];
  audioType: FileType = FileType.AUDIO;
  fileType: FileType = FileType.FILE;
  show_list: boolean = false;
  show_loading: boolean = false;
  id_document: any;
  name_document: string;
  multiple: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  /**
   * on file drop handler
   */
  onFileDropped($event, fileType: FileType) {
    this.prepareFilesList($event, fileType);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files, fileType: FileType) {
    if(this.multiple == true){
      this.file = files
    }
    else
    this.prepareFilesList(files, fileType);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  deleteAudio(index: number) {
    this.audio.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadAudioSimulator(index: number) {
    setTimeout(() => {
      if (index === this.audio.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.audio[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadAudioSimulator(index + 1);
          } else {
            this.audio[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>, fileType: FileType) {
    for (const item of files) {
      item.progress = 0;
      if (fileType == FileType.FILE) {
        this.files.push(item);
        this.uploadFilesSimulator(0);
      }
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  expandFileName(name: string): string {
    name=name.trim();
    if (name.length < 12) {
      return name;
    } else {
      return name.substring(0, 6) + '...' + name.substring(name.length - 5, name.length);
    }
  }

  onSelectFiles(files:any){
    this.onChangeFiles.emit(files);
  }
}
