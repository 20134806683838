export class Notifications {
    date_create_api: string;
    date_notification: string;
    heure_notification: string;
    id_notification: string;
    msg: string;
    nb_view: string;
    semaine_notification: string;
    status: string;
    titre: string;
    type: string;
    url: string;
    user: string;

    constructor(init?: Partial<Notifications>) {
        Object.assign(this, init);
      }
  }
export class NotificationDate{
    heure:string;
    num_heure:string;
    num_minute:string;
    time:string;
    week:string;
    date:string;
}

  