import {FormField} from './FormField';

export class EmailFormly extends FormField {
  maxLength=255;
  minLength=0;
  pattern="";
  format="";
  constructor() {
    super();
    // super.icon="fa fa-at";
    super.icon="fa fa-at";

    super.name = 'texte_' + super.generateRandomId();
    this.type = 'email';
    this.title='E-mail';
    super.typename = 'Email';
  }
}
