import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ApiCampagniesService } from '../../../../../services/ApiCompagnies/api-compagnies.service';
import { CampagniesList } from '../../../../../entity/CampagniesList';
import { Details } from '../../../../../entity/Opportunity/Details';
import { FormContract } from '../../../../../entity/Opportunity/FormContract';
import { Observable, of, Subscription } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TagOpportunite } from '../../../../../entity/Opportunity/TagOpportunite';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ApiOpportunitService } from '../../../../../services/ApiOpportunite/api-opportunite.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { addOpportuniteStore } from '../../store/add-opportunite.reducers';
import { UpdateCampagne } from '../../store/add-opportunite.actions';
import { Campagne, ObjectAssurer } from 'src/app/entity/Opportunity/Campagne';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, OnChanges, OnDestroy {
  fa = fa;
  @Input() info_specifiques;
  @Input() info_complementaires;
  @Input() campagne: Campagne;
  @Output() onUpdateInfo: EventEmitter<Details> = new EventEmitter();
  @Output() onUpdateComapagne: EventEmitter<Campagne> = new EventEmitter();
  @Input() bien;
  form_info_specifiques = new FormGroup({});
  form_info_complementaires = new FormGroup({});
  model_info_specifiques: any = {};
  model_info_complementaires: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  formContrat: FormGroup;
  unsubscribeGetListTagsOpportunite: Subscription;
  campagniesList: CampagniesList[] = [];
  @Input() details: Details = new Details();
  detailsClone: Details = new Details();

  constructor(private formBuilder: FormBuilder,
    private apiCampagnieServices: ApiCampagniesService,
    private store$: Store<addOpportuniteStore>,
    private apiOpportunitService: ApiOpportunitService) {
  }

  public cieAutoComplete$: Observable<CampagniesList[]> = null;


  // Mat chips Tags
  tagsFromCtrl: FormControl = new FormControl('');
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<TagOpportunite[]> = null;
  ListTags: TagOpportunite[] = [];
  allTags: TagOpportunite[] = [];
  @ViewChild('tagsInput') TagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocompleteTags: MatAutocomplete;

  // -------------------------------------------------------------------------------------------------


  addListTags(): void {
    const selected = this.allTags.filter(element => element.id === this.tagsFromCtrl.value).pop();
    this.detailsClone.tags.push(selected);
    this.tagsFromCtrl.setValue(null);
    this.TagsInput.nativeElement.value = '';
    this.pushInfo();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  remove(tag: TagOpportunite): void {
    const index = this.detailsClone.tags.indexOf(tag);
    if (index >= 0) {
      this.detailsClone.tags.splice(index, 1);
    }
    this.pushInfo();
  }

  private _filter(): TagOpportunite[] {
    let filterValue = this.tagsFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) {
      filterValue = '';
    }
    return this.allTags.filter(tag => tag.libelle.toLowerCase().indexOf(filterValue) > -1);
  }


  // -------------------------------------------------------------------------------------------------

  ngOnInit(): void {

    this.filteredTags = this.tagsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter() : this.allTags.slice()));
    this.unsubscribeGetListTagsOpportunite = this.apiOpportunitService.getListTagsOpportunite(1).subscribe((data: TagOpportunite[]) => {
      this.allTags = data;
    });
    this.fillFormInfo();
  }

  ngOnDestroy(): void {
    this.pushInfo()
    this.unsubscribeGetListTagsOpportunite.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {
    this.pushInfo()
  }

  fillFormInfo() {
    this.formContrat = new FormGroup({
      cie_text: new FormControl(this.details.formContrat.cie_text),
      cie_text_id: new FormControl(this.details.formContrat.cie_text_id),
      nom_produit: new FormControl(this.details.formContrat.nom_produit),
      pan_ttc: new FormControl(this.details.formContrat.pan_ttc),
      pm_ttc: new FormControl(this.details.formContrat.pm_ttc),
      nombre_assure: new FormControl(this.details.formContrat.nombre_assure),
      date_echeance_principale: new FormControl(this.details.formContrat.date_echeance_principale),
    });
    this.cieAutoComplete$ = this.formContrat.controls.cie_text.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => {
        if (value !== '') {
          // lookup from github
          return this.getCampagnieCie();
        } else {
          // if no value is present, return null
          return of(null);
        }
      })
    );
  }

  getCampagnieCie(): Observable<CampagniesList[]> {
    return this.apiCampagnieServices.rechercheCompanieGet(this.formContrat.value.cie_text.toLowerCase())
      .pipe(
        map(res => res.results),
        catchError(_ => {
          return of(null);
        })
      );
  }


  pushInfo() {
    const formContract = new FormContract(this.formContrat.value);
    this.detailsClone.formContrat = formContract;
    this.onUpdateInfo.emit(this.detailsClone);
  }

  onSelectCie(compagn: CampagniesList) {
    this.formContrat.controls.cie_text_id.setValue(compagn.id);
    this.formContrat.controls.cie_text.setValue(compagn.text);
    this.pushInfo();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.detailsClone = JSON.parse(JSON.stringify(this.details));
    this.model_info_complementaires = this.detailsClone.model_info_complementaires;
    this.model_info_specifiques = this.detailsClone.model_info_specifiques;
    this.fillFormInfo();
  }

  onSubmitInfoSpec() {
    this.detailsClone.model_info_specifiques = JSON.parse(JSON.stringify(this.model_info_specifiques));
    this.pushInfo()
  }

  onSubmitInfoComp() {
    this.detailsClone.model_info_complementaires = JSON.parse(JSON.stringify(this.model_info_complementaires));
    this.pushInfo()
  }


  getformdata($event) {
    const newCompagne = new Campagne();
    newCompagne.campagne_id = this.campagne.campagne_id;
    newCompagne.choice = this.campagne.choice;
    newCompagne.cycle_vie = this.campagne.cycle_vie

    newCompagne.invalidForms = this.campagne.invalidForms;
    newCompagne.product_id = this.campagne.product_id;
    const listobjet: any[] = [];
    for (let index = 0; index < this.campagne.objectAssurer.length; index++) {

      if (this.campagne.objectAssurer[index].index === $event.index) {

        Object.freeze(this.campagne.objectAssurer[index].form);

        const objCopy = { ...this.campagne.objectAssurer[index].form };
        objCopy.data = $event.form.data;
        objCopy.schema = $event.form.schema;

        listobjet.push(new ObjectAssurer(this.campagne.objectAssurer[index].index,objCopy, this.campagne.objectAssurer[index].icon, this.campagne.objectAssurer[index].nom,  true,$event.expand));

      } else {
        listobjet.push(new ObjectAssurer(this.campagne.objectAssurer[index].index,this.campagne.objectAssurer[index].form, this.campagne.objectAssurer[index].icon, this.campagne.objectAssurer[index].nom,  true,false));

      }
    }

    newCompagne.objectAssurer = listobjet;
    this.onUpdateComapagne.emit(newCompagne);

  }

  copieformdata(event: any) {
    const newCompagne = {
      campagne_id: this.campagne.campagne_id,
      choice: this.campagne.choice,
      cycle_vie: this.campagne.cycle_vie,
      invalidForms: this.campagne.invalidForms,
      product_id: this.campagne.product_id,
      objectAssurer: [],
    };

    for (const obj of this.campagne.objectAssurer) {
      const newObj = {
        index: obj.index,
        form: { schema: obj.form.schema, data: obj.form.data },
        icon: obj.icon,
        nom: obj.nom,
        completed: true,
      };
      newCompagne.objectAssurer.push(newObj);
    }

    const source = {
      form: { schema: event.form.schema, data: event.form.data },
      icon: event.icon,
      nom: event.nom,
      completed: event.completed,
    };

    newCompagne.objectAssurer.push({
      index: null,
      form: source.form,
      icon: source.icon,
      nom: source.nom,
      completed: true,
    });

    this.onUpdateComapagne.emit(newCompagne);
  }

  deleteformdata(event: any) {
    const newCompagne = {
      campagne_id: this.campagne.campagne_id,
      choice: this.campagne.choice,
      cycle_vie: this.campagne.cycle_vie,
      invalidForms: this.campagne.invalidForms,
      product_id: this.campagne.product_id,
      objectAssurer: [],
    };

    for (const obj of this.campagne.objectAssurer) {
      const newObj = {
        index: obj.index,
        form: { schema: obj.form.schema, data: obj.form.data },
        icon: obj.icon,
        nom: obj.nom,
        completed: true,
      };

      if (obj !== event) {
        newCompagne.objectAssurer.push(newObj);
      }
    }

    this.onUpdateComapagne.emit(newCompagne);
  }

}
