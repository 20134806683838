import { ListOrganisme } from 'src/app/entity/Generic/ListOrganisme';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { ApiVilleService } from './../../services/ApiVille/api-ville.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { TarificateurVeloService } from 'src/app/services/tarificateur-Berilish/tarificateur-velo.service';
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { TarificateurVelo } from 'src/app/entity/tarificateurBerilish/tarificateurVelo';
import { Bike } from 'src/app/entity/tarificateurBerilish/bike';
import { Address, Client } from 'src/app/entity/tarificateurBerilish/client';
import { Simulation } from 'src/app/entity/tarificateurBerilish/simulation';
import { ExportToCsv } from 'export-to-csv';
import { Prospect } from 'src/app/entity/Prospect';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { OpportunityAddVelo } from 'src/app/entity/Opportunity/Opportunity-Berilish/OpportunityAddBirilish';
import { GenerateDevis } from 'src/app/entity/Opportunity/Opportunity-Berilish/GenerateDevis';
import { SendDevis } from 'src/app/entity/Opportunity/Opportunity-Berilish/SendDevis';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { UserLogin } from 'src/app/login/login.component';
import { environment } from 'src/environments/environment';
import { b64toBlob } from 'src/app/shared/Utils/sharedFunctions';
import { ApiDocumentsService } from 'src/app/services/ApiDocuments/api-documents.service';
import { E, S } from '@angular/cdk/keycodes';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { UserAdmin2 } from 'src/app/entity/User/UserAdmin2';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AddOpportuniteTarificateurDialogComponent } from './_dialog/add-opportunite-tarificateur-dialog-component';
import { reduce } from 'rxjs/operators';
@Component({
  selector: 'app-tarificateur-velo',
  templateUrl: './tarificateur-velo.component.html',
  styleUrls: ['./tarificateur-velo.component.css'],
})
export class TarificateurVeloComponent implements OnInit {
  geerateDevis: GenerateDevis = new GenerateDevis();
  opportunity: OpportunityAddVelo = new OpportunityAddVelo();
  sendMailbody: SendDevis = new SendDevis();
  @ViewChild('closeModal') closeModal: ElementRef;
  send: boolean = false;
  opportunitySim: Simulation = new Simulation();
  show_loading_adding: boolean = false;
  show_loading: boolean = false;
  prospect: Prospect = new Prospect();
  user: UserLogin = new UserLogin();
  userAdmin: UserAdmin2;
  fa = fa;
  showTarificateur_loder: boolean = false;
  showTarificateurGarantier: boolean = false;
  codePostalControl: FormControl = new FormControl('');
  prixVeloControl: FormControl = new FormControl('', [Validators.min(100), Validators.max(8000)]);
  dateAchatControl: FormControl = new FormControl('');
  formOpportunity: FormControl = new FormControl('');
  villeControl: FormControl = new FormControl('', Validators.required);
  myDate = new Date();
  franchise = 10;
  lc = 0;
  fraisDossier: Number = 0;
  tarificationVelo: TarificateurVelo = new TarificateurVelo();
  bike: Bike = new Bike();
  adress: Address = new Address();
  client: Client = new Client();
  list_invalid: any[] = [];
  list_simulation: any[] = [];
  simulation: Simulation = new Simulation();
  gps: boolean = false;
  garaties: any;
  garatiesDeff: any;
  garantiesDiffenrence: any;
  garantiesDiffenrenceNvOption: any;
  garantiesDiffenrencePAOption: any;
  initalGarantie: any;
  dammageGarantie: any;
  volGrantie: any;
  dommageVolGarantie: any;
  titre: string = '';
  DommageVolMois: any;
  VolMois: any;
  DommageMois: any;

  DommageVolYear: any;
  VolYear: any;
  DommageYear: any;
  apiKey: string = '';
  accessToken: string = '';
  PAOption: string = 'No';
  NVOption: string = 'No';
  isAssistance: boolean = false;
  showNvoption: boolean = false;
  first: number = 0;

  differencePAoptionYear: any;
  differencePAoptionMois: any;

  dffenrenceNvOptionYear: any;
  dffenrenceNvOptionMois: any;
  nomControl: FormControl = new FormControl('', [Validators.required, Validators.pattern('[A-Za-z]{1,32}')]);
  loader: boolean = true;
  prenomControl: FormControl = new FormControl('', [Validators.required, Validators.pattern('[A-Za-z]{1,32}')]);
  emailControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  ]);

  telControl: FormControl = new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]);
  fraisdossier = new FormControl('', [Validators.max(15), Validators.min(0)]);
  listCycleVieComp: any;
  idOpportunity: string;
  id_prospect_md5: string;
  id_templateDevisFolote = environment.id_templateDevisFlote;
  id_docFLote = environment.id_Doc;
  dategenrateDevis: string = '';
  body: any;
  codePostalLoader: boolean = false;
  listVilles: string[] = [];
  // idEntreprise: string = localStorage.getItem('brand_id');
  idEntreprise: string = '';
  organisme: ListOrganisme = new ListOrganisme();
  dateSelect: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
  dateNow: Date = new Date();
  isPro: any;
  step: any;
  api_Key: any;
  couleur: any;
  brand_id = localStorage.getItem('entreprise_id');
  color = '#44A2FE';
  // color = 'red';
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private routeA: ActivatedRoute,
    private notificationsService: NotificationsService,
    private apiAuthentificationService: ApiAuthentificationService,
    private apiOpportuniteService: ApiOpportunitService,
    private apiTarificateurVelo: TarificateurVeloService,
    private routes: Router,
    private datePipe: DatePipe,
    private apiDocumentsService: ApiDocumentsService,
    private _matDialog: MatDialog,
    private apiUsersService: ApiUsersService,
    private apiOrganismeService: ApiOrganismeService,
    private apiVilleService: ApiVilleService
  ) {}

  ngOnInit(): void {
    this.myDate = new Date();
    this.dategenrateDevis = this.datePipe.transform(this.myDate, 'yyyy-MM-dd-HH:mm');
    this.dateSelect.setDate(this.dateSelect.getDate() + 1);
    this.dateAchatControl.setValue(this.datePipe.transform(this.myDate, 'yyyy-MM-dd')!);
    this.showloader();
    this.routeA.paramMap.subscribe((params) => {
      if (params.get('partenerShipid') !== '') {
        this.apiKey = params.get('partenerShipid');
        if (this.apiKey) {
          this.login();
          // } else {
          //   this.getUserConnected();
          // this.apiKey = localStorage.getItem('apiKey');
          // }
        } else {
          this.apiKey = localStorage.getItem('apiKey');
          this.accessToken = localStorage.getItem('Acces_Token');
          this.brand_id = localStorage.getItem('entreprise_id');

          // this.login();
        }
      }
    });

    /*  this.routeA.paramMap.subscribe((params) => {
      if (params.get('brand') !== null) {
        this.brand_id = params.get('brand');
      } else {
        this.brand_id = localStorage.getItem('entreprise_id');
      }
    }); */

    this.sharedMenuObserverService.updateMenu('Tarificateur Berilish vélo ');
    this.hideloader();
  }

  /*   getOrganismeById(): void {
    console.log('this.idEntreprise',this.idEntreprise)
    this.apiOrganismeService.GetOrganismeById(this.idEntreprise).subscribe((data) => {
      this.organisme = data;
      this.couleur = this.organisme?.used_brand?.espace_distributeur?.couleur1;
      console.log('this.couleur',this.couleur)
      // this.couleur = 'red';
    });
  } */

  exporter() {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Simulations',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.list_simulation);
  }

  getVilleByCodepostal(): void {
    this.codePostalLoader = true;
    this.apiVilleService.GetVillesByZipcode(this.adress.postalCode).subscribe(
      (response) => {
        this.codePostalLoader = false;
        if (response.cities.length === 0) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.warning;
          notificationMessage.title = 'Code postal erroné';
          notificationMessage.message = 'Aucune ville pour le code postal.';
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          this.listVilles = response.cities;
          if (this.listVilles.length === 1) {
            this.villeControl.setValue(this.listVilles[0]);
          }
        }
      },
      () => {
        this.codePostalLoader = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Code postal';
        notificationMessage.message = 'Aucune ville pour le code postal.';
      }
    );
  }

  check() {
    if (
      this.codePostalControl.status !== 'INVALID' &&
      this.prixVeloControl.status !== 'INVALID' &&
      this.dateAchatControl.status !== 'INVALID' &&
      this.villeControl.status !== 'INVALID'
    ) {
      this.initalGarantie = [];
      this.showNvoption = false;
      this.first = 0;
      this.garaties = [];
      this.initalGarantie = [];

      this.franchise = 10;
      this.lc = 0;
      this.tarificationVelo = new TarificateurVelo();
      this.fraisDossier = 0;
      this.PAOption = 'No';
      this.NVOption = 'No';
      this.isAssistance = false;

      this.dffenrenceNvOptionYear = 0;
      this.dffenrenceNvOptionMois = 0;
      this.differencePAoptionYear = 0;
      this.differencePAoptionMois = 0;

      /* const dialogRef = this._matDialog.open(AddOpportuniteTarificateurDialogComponent, {
        width: '950px',
        data: {
          item: false,
          send: true,
          villes: this.listVilles,
          zipCode: this.adress.postalCode,
          selectedVille: this.villeControl.value,
          couleur: this.organisme?.used_brand?.espace_distributeur?.couleur1,
        },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result !== false) {
          this.addOpportunity(result);
        }
      }); */

      this.Calculer();
    }
  }

  reCalcul() {
    setTimeout(() => {
      if (
        this.codePostalControl.status === 'VALID' &&
        this.prixVeloControl.status === 'VALID' &&
        this.dateAchatControl.status === 'VALID' &&
        this.villeControl.status === 'VALID'
      ) {
        this.Calculer();
      }
    }, 300);
  }

  Calculer() {
    let listInvalid: string = '';
    if (
      this.codePostalControl.status === 'INVALID' ||
      this.prixVeloControl.status === 'INVALID' ||
      this.dateAchatControl.status === 'INVALID' ||
      this.villeControl.status === 'INVALID'
    ) {
      if (this.codePostalControl.status === 'INVALID') {
        listInvalid = listInvalid + '<li style="font-size: 17px"> Veuillez vérfier le code postal.</li>';
      }
      if (this.prixVeloControl.status === 'INVALID') {
        listInvalid = listInvalid + '<li style="font-size: 17px"> Veuillez de vérfier le prix de vélo.</li>';
      }
      if (this.villeControl.status === 'INVALID') {
        listInvalid = listInvalid + '<li style="font-size: 17px">Veuillez vérfier la ville.</li>';
      }

      if (this.dateAchatControl.status === 'INVALID') {
        listInvalid = listInvalid + "<li style='font-size: 17px'>Veuillez vérifier la date d'achat de la vélo.</li>";
      }
      if (listInvalid) this.alertWarning(listInvalid);
    } else {
      if (this.gps) {
        this.bike.TrackerGPS = 'Yes';
      } else {
        this.bike.TrackerGPS = 'No';
      }

      if (this.isOverTwoYear(new Date(this.dateAchatControl.value))) {
        this.alertError("La souscription n'est envisageable que pour les achats de moins de 24 mois.");
      } else {
        this.showTarificateurGarantier = false;
        this.showTarificateur_loder = true;
        this.bike.purchasePrice = this.prixVeloControl.value;
        this.bike.purchaseDate = this.datePipe.transform(this.dateAchatControl.value, 'yyyy-MM-dd');
        this.tarificationVelo.bike = this.bike;
        this.adress.postalCode = this.codePostalControl.value;
        this.adress.city = this.villeControl.value;
        this.client.address = this.adress;
        this.tarificationVelo.client = this.client;
        this.tarificationVelo.Excess = this.franchise + '%';
        this.tarificationVelo.CommercialLatitude = this.lc + '%';
        this.tarificationVelo.isAssistance = true;
        // TODO Add ville here

        this.apiTarificateurVelo.getRate(this.tarificationVelo, this.apiKey).subscribe(
          (data) => {
            this.showTarificateur_loder = false;
            this.showTarificateurGarantier = true;

            this.initalGarantie = data;
            this.showNvoption = false;
            this.initalGarantie.forEach((element) => {
              if (element.warranty.NVOption === 'Yes') {
                this.showNvoption = true;
              }
            });

            this.filterGarantier(this.PAOption, this.NVOption, this.isAssistance);
            if (this.first === 0) {
              if (this.PAOption === 'No') {
                this.filterGarantierOption('Yes', this.NVOption, this.isAssistance);
              } else {
                this.filterGarantierOption('No', this.NVOption, this.isAssistance);
              }

              if (this.showNvoption) {
                if (this.NVOption === 'No') {
                  this.filterGarantierOptionNv(this.PAOption, 'Yes', this.isAssistance);
                } else {
                  this.filterGarantierOptionNv(this.PAOption, 'No', this.isAssistance);
                }
              }

              this.first++;
            }
          },
          (err: any) => {
          if (err.indexOf('Error Code: 404')) {
            this.showTarificateur_loder = false;

            this.alertError('Merci de verifier le code postale  ');
          } else {
            this.showTarificateur_loder = false;

            this.alertError('erreur lors de calculer le tarif , merci de verfier les champs ');
          }
          }
        );
      }
    }
  }

  isactiveIndividuelAccident(event) {
    if (event.target.checked) {
      this.PAOption = 'Yes';
      this.filterGarantier(this.PAOption, this.NVOption, this.isAssistance);
    } else {
      this.PAOption = 'No';
      this.filterGarantier(this.PAOption, this.NVOption, this.isAssistance);
    }
  }

  isactiveValeurAneuf(event) {
    if (event.target.checked) {
      this.NVOption = 'Yes';
      this.filterGarantier(this.PAOption, this.NVOption, this.isAssistance);
    } else {
      this.NVOption = 'No';
      this.filterGarantier(this.PAOption, this.NVOption, this.isAssistance);
    }
  }

  isactiveAssitance(event) {
    if (event.target.checked) {
      this.isAssistance = true;
      this.filterGarantier(this.PAOption, this.NVOption, this.isAssistance);
    } else {
      this.isAssistance = false;
      this.filterGarantier(this.PAOption, this.NVOption, this.isAssistance);
    }
  }

  filterGarantier(PAOption, NVOption, isAssistance) {
    this.garaties = this.initalGarantie.filter((element) => element.warranty.PAOption === PAOption);
    this.garaties = this.garaties.filter((element) => element.warranty.NVOption === NVOption);
    this.dammageGarantie = this.garaties.filter((element) => element.warranty.Formula === 'Damage');
    this.volGrantie = this.garaties.filter((element) => element.warranty.Formula === 'Theft');
    this.dommageVolGarantie = this.garaties.filter((element) => element.warranty.Formula === 'Damage and Theft');
    this.garantiesDiffenrence = this.dammageGarantie[0];

    if (isAssistance) {
      const ratedv =
        this.dommageVolGarantie[0].rate.TotalAmountAllTaxesIncluded + this.dommageVolGarantie[0].rate.AssistanceFees;
      // this.dommageVolGarantie[0].rate.TotalAmountAllTaxesIncluded + 30;

      const num = ratedv / 12;
      this.DommageVolYear = Number(
        // this.dommageVolGarantie[0].rate.TotalAmountAllTaxesIncluded + this.dommageVolGarantie[0].rate.AssistanceFees
        (
          this.dommageVolGarantie[0].rate.TotalAmountAllTaxesIncluded + this.dommageVolGarantie[0].rate.AssistanceFees
        ).toFixed(2)
      );
      this.DommageVolMois = Number(num.toFixed(2));
      const ratev = this.volGrantie[0].rate.TotalAmountAllTaxesIncluded + this.volGrantie[0].rate.AssistanceFees;
      // const ratev = this.volGrantie[0].rate.TotalAmountAllTaxesIncluded + 30;

      const numv = ratev / 12;
      this.VolMois = Number(numv.toFixed(2));
      this.VolYear = Number(
        (this.volGrantie[0].rate.TotalAmountAllTaxesIncluded + this.volGrantie[0].rate.AssistanceFees).toFixed(2)
        //  (this.volGrantie[0].rate.TotalAmountAllTaxesIncluded + 30).toFixed(2)
      );

      const rated =
        this.dammageGarantie[0].rate.TotalAmountAllTaxesIncluded + this.dammageGarantie[0].rate.AssistanceFees;
      // this.dammageGarantie[0].rate.TotalAmountAllTaxesIncluded + 30;

      const numd = rated / 12;
      this.DommageYear = Number(
        (
          this.dammageGarantie[0].rate.TotalAmountAllTaxesIncluded + this.dammageGarantie[0].rate.AssistanceFees
        ).toFixed(2)
        //  (this.dammageGarantie[0].rate.TotalAmountAllTaxesIncluded + 30).toFixed(2)
      );
      this.DommageMois = Number(numd.toFixed(2));
    } else {
      const ratedv = this.dommageVolGarantie[0].rate.TotalAmountAllTaxesIncluded;
      const num = ratedv / 12;
      this.DommageVolYear = Number(this.dommageVolGarantie[0].rate.TotalAmountAllTaxesIncluded.toFixed(2));
      this.DommageVolMois = Number(num.toFixed(2));

      const ratev = this.volGrantie[0].rate.TotalAmountAllTaxesIncluded;
      const numv = ratev / 12;
      this.VolMois = Number(numv.toFixed(2));
      this.VolYear = Number(this.volGrantie[0].rate.TotalAmountAllTaxesIncluded.toFixed(2));
      const rated = this.dammageGarantie[0].rate.TotalAmountAllTaxesIncluded;
      const numd = rated / 12;
      this.DommageMois = Number(numd.toFixed(2));
      this.DommageYear = Number(this.dammageGarantie[0].rate.TotalAmountAllTaxesIncluded.toFixed(2));
    }
  }

  filterGarantierOption(PAOption, NVOption, isAssistance) {
    this.differencePAoptionYear = null;
    this.garatiesDeff = this.initalGarantie.filter((element) => element.warranty.PAOption === PAOption);
    this.garatiesDeff = this.garatiesDeff.filter((element) => element.warranty.NVOption === NVOption);
    this.dammageGarantie = this.garatiesDeff.filter((element) => element.warranty.Formula === 'Damage');
    this.garantiesDiffenrencePAOption = this.dammageGarantie[0];
    this.differencePAoptionYear = Number(
      Math.abs(
        this.garantiesDiffenrence.rate.TotalAmountAllTaxesIncluded -
          this.garantiesDiffenrencePAOption.rate.TotalAmountAllTaxesIncluded
      ).toFixed(2)
    );
    this.differencePAoptionMois = Number(
      Math.abs(
        this.garantiesDiffenrence.rate.TotalAmountAllTaxesIncluded -
          this.garantiesDiffenrencePAOption.rate.TotalAmountAllTaxesIncluded
      ) / 12
    ).toFixed(2);
  }

  filterGarantierOptionNv(PAOption, NVOption, isAssistance) {
    this.dffenrenceNvOptionYear = null;

    this.garatiesDeff = this.initalGarantie.filter((element) => element.warranty.PAOption === PAOption);
    this.garatiesDeff = this.garatiesDeff.filter((element) => element.warranty.NVOption === NVOption);
    this.dammageGarantie = this.garatiesDeff.filter((element) => element.warranty.Formula === 'Damage');

    this.garantiesDiffenrenceNvOption = this.dammageGarantie[0];
    this.dffenrenceNvOptionYear = Number(
      Math.abs(
        this.garantiesDiffenrence.rate.TotalAmountAllTaxesIncluded -
          this.garantiesDiffenrenceNvOption.rate.TotalAmountAllTaxesIncluded
      ).toFixed(2)
    );
    this.dffenrenceNvOptionMois = Number(
      Math.abs(
        this.garantiesDiffenrence.rate.TotalAmountAllTaxesIncluded -
          this.garantiesDiffenrenceNvOption.rate.TotalAmountAllTaxesIncluded
      ) / 12
    ).toFixed(2);
  }

  formatLabel(value: number) {
    return value + '%';
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  changeFranchise() {
    this.first = 0;
    this.tarificationVelo.Excess = this.franchise + '%';

    this.Calculer();
  }

  changeLatitudeCommercial() {
    this.first = 0;
    this.Calculer();
  }

  isOverTwoYear(dateachat: Date) {
    const yearDifMs = Date.now() - dateachat.getTime();
    const yearDate = new Date(yearDifMs);
    const year = yearDate.getUTCFullYear() - 1970;
    if (year > 1 || year < 0) {
      this.dateAchatControl.setErrors({
        notconfirmid: true,
      });
      return true;
    } else {
      return false;
    }
  }

  alertError(data) {
    swal.fire({
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  alertWarning(listInvalid) {
    swal.fire({
      title: 'Informations obligatoires',
      html: '<div style=" margin: 0% 0% 0% 9%"><ul class="text-left">' + listInvalid + '</ul></div>',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  retourToList() {
    this.routes.navigate(['/gestion-cycle-vies']);
  }

  clearForm() {
    this.garaties = [];
    this.initalGarantie = [];
    this.showTarificateur_loder = false;
    this.showTarificateurGarantier = false;
    this.franchise = 10;
    this.titre = '';
    this.lc = 0;
    this.tarificationVelo = new TarificateurVelo();
    this.bike = new Bike();
    this.adress = new Address();
    this.client = new Client();
    this.simulation = new Simulation();
    this.fraisDossier = 0;
    this.gps = false;
    this.PAOption = 'No';
    this.NVOption = 'No';
    this.isAssistance = false;
    this.codePostalControl.reset();
    this.prixVeloControl.reset();
    this.dateAchatControl.reset();
    this.villeControl.reset();
    this.myDate = new Date();
    this.dateAchatControl.setValue(this.datePipe.transform(this.myDate, 'yyyy-MM-dd')!);
  }

  addSimulation(garanties) {
    const simlule = this.garaties.filter((element) => element.warranty.Formula === garanties);
    this.simulation.titre = this.titre;
    this.simulation.garantie = simlule[0].warranty.Formula;
    this.simulation.codePostal = this.tarificationVelo.client.address.postalCode;
    this.simulation.purchasePrice = this.tarificationVelo.bike.purchasePrice;
    this.simulation.purchaseDate = this.tarificationVelo.bike.purchaseDate;
    this.simulation.TrackerGPS = this.tarificationVelo.bike.TrackerGPS;
    this.simulation.PAOption = simlule[0].warranty.PAOption;
    this.simulation.NVOption = simlule[0].warranty.NVOption;
    this.simulation.quoteRef = simlule[0].references.quoteRef;
    this.simulation.storeRef = simlule[0].references.storeRef;
    this.simulation.isAssitance = this.isAssistance;
    this.simulation.franchise = this.tarificationVelo.Excess;
    this.simulation.lc = this.tarificationVelo.CommercialLatitude;
    this.simulation.AdministrationFees = this.fraisDossier;
    if (this.simulation.isAssitance) {
      const rate = simlule[0].rate.TotalAmountAllTaxesIncluded + simlule[0].rate.AssistanceFees;
      // const rate = simlule[0].rate.TotalAmountAllTaxesIncluded + 30;
      const num = rate / 12;
      this.simulation.monthPrice = Number(num.toFixed(2));
      this.simulation.yearPrice = Number(rate.toFixed(2));

      this.simulation.poucentage = Number(
        (this.simulation.yearPrice / this.tarificationVelo.bike.purchasePrice).toFixed(2)
      );
    } else {
      const rate = simlule[0].rate.TotalAmountAllTaxesIncluded;
      const num = rate / 12;
      this.simulation.monthPrice = Number(num.toFixed(2));
      this.simulation.yearPrice = Number(rate.toFixed(2));
      this.simulation.poucentage = Number(
        (this.simulation.yearPrice / this.tarificationVelo.bike.purchasePrice).toFixed(2)
      );
    }

    this.list_simulation.push(this.simulation);
    this.simulation = new Simulation();

    this.garaties = [];
    this.initalGarantie = [];
    this.Calculer();
  }

  remove(index) {
    if (index > -1) {
      this.list_simulation.splice(index, 1);
    }
  }

  opportunitySimilute(item, send) {
    this.send = send;
    this.opportunitySim = item;
    console.log(this.apiKey);
    const dialogRef = this._matDialog.open(AddOpportuniteTarificateurDialogComponent, {
      width: '950px',
      data: {
        api_key: this.apiKey,
        item: item,
        send: send,
        villes: this.listVilles,
        zipCode: this.adress.postalCode,
        selectedVille: this.villeControl.value,
        couleur: this.organisme?.used_brand?.espace_distributeur?.couleur2,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined) {
        this.isPro = result.informationProForm.isPro;
        this.step = result.devis.step;
        this.show_loading = true;
        this.show_loading_adding = true;
        this.addOpportunity(result);
      }
    });
  }

  addOpportunity(informations?: any) {
    this.show_loading_adding = true;
    this.opportunity.campagne.campagne_id = '0aa1883c6411f7873cb83dacb17b0afc';
    this.opportunity.details.model_info_complementaires.Formula = this.opportunitySim.garantie;
    this.opportunity.details.model_info_complementaires.PAOption = this.PAOption;
    this.opportunity.details.model_info_complementaires.NVOption = this.NVOption;
    this.opportunity.details.model_info_complementaires.TrackerGPS = this.opportunitySim.TrackerGPS;
    this.opportunity.details.model_info_complementaires.Excess = this.opportunitySim.franchise;
    this.opportunity.details.model_info_specifiques.TrackerGPS = this.opportunitySim.TrackerGPS;
    this.opportunity.details.model_info_specifiques.quoteRef = this.opportunitySim.quoteRef;
    this.opportunity.details.model_info_specifiques.storeRef = this.opportunitySim.storeRef;
    this.opportunity.details.model_info_specifiques.purchaseDate = this.opportunitySim.purchaseDate;
    this.opportunity.details.model_info_specifiques.purchasePrice = this.opportunitySim.purchasePrice;
    this.opportunity.details.model_info_specifiques.brand = informations.informationVeloForm.brand;
    this.opportunity.details.model_info_specifiques.model = informations.informationVeloForm.model;
    this.opportunity.details.model_info_specifiques.classification = informations.informationVeloForm.classification;
    this.opportunity.details.model_info_specifiques.type = informations.informationVeloForm.type;
    this.opportunity.details.model_info_specifiques.usage = informations.informationVeloForm.usage;
    this.opportunity.details.model_info_specifiques.etchingNumber = informations.informationVeloForm.etchingNumber;
    this.opportunity.details.model_info_specifiques.maximumSpeed = informations.informationVeloForm.maximumSpeed;
    this.opportunity.details.model_info_specifiques.serialNumber = informations.informationVeloForm.serialNumber;
    this.opportunity.details.model_info_specifiques.serialNumber = informations.informationVeloForm.serialNumber;
    this.opportunity.details.model_info_specifiques.clientOccupation = informations.prospectForm.occupation;
    this.opportunity.details.model_info_specifiques.CommercialLatitude = this.opportunitySim.lc;
    this.opportunity.details.model_info_specifiques.valeurVelo = '';
    this.opportunity.details.model_info_specifiques.vol = false;
    this.opportunity.details.model_info_specifiques.dommages = false;
    this.opportunity.details.model_info_specifiques.zipCode = this.opportunitySim.codePostal;
    this.opportunity.details.model_info_specifiques.isAssistance = this.opportunitySim.isAssitance;
    this.opportunity.details.model_info_specifiques.assistance = this.opportunitySim.isAssitance;
    this.opportunity.details.model_info_specifiques.city = informations.prospectForm.ville;
    this.opportunity.details.model_info_specifiques.AdminstrationFees = this.opportunitySim.AdministrationFees;
    // this.opportunity.details.model_info_complementaires.isAssistance = 'false';
    if (this.isAssistance) this.opportunity.details.model_info_complementaires.isAssistance = 'true';
    else this.opportunity.details.model_info_complementaires.isAssistance = 'false';

    /* this.opportunity.details.model_info_specifiques.BikePurchaseDate = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikePurchasePrice = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikeType = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikeClassification = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikeUsage = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikeSerialNumber = this.opportunitySim.purchasePrice; */
    /* this.opportunity.details.model_info_specifiques.BikeEtchingNumber = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikeBrand = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikeModel = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.BikeMaximumSpeed = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.ZipCode = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.City = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.Formula = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.PAOption = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.NVOption = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.Excess = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.StartDateOfPolicy = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.EndDateOfPolicy = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.StartDateOfCover = this.opportunitySim.purchasePrice;
      this.opportunity.details.model_info_specifiques.EndDateOfCover = this.opportunitySim.purchasePrice; */

    if (this.opportunitySim.AdministrationFees !== null)
      this.opportunity.details.model_info_complementaires.AdministrationFees =
        this.opportunitySim.AdministrationFees.toString();
    if (this.opportunitySim.yearPrice !== null)
      this.opportunity.details.model_info_complementaires.TotalAmountAllTaxesIncluded =
        this.opportunitySim.yearPrice.toString();
    if (this.opportunitySim.monthPrice !== null)
      this.opportunity.details.model_info_complementaires.monthly_amount = this.opportunitySim.monthPrice.toString();
    this.opportunity.calendar.id_type_opportunity = 4;
    this.opportunity.calendar.parent = -1;
    this.opportunity.calendar.status = 17;
    this.opportunity.calendar.statusLibelle = 'Invitation envoyé';
    this.opportunity.calendar.affecter = this.user.id_user;
    this.opportunity.calendar.affecterLibelle = this.user.nom + this.user.prenom;
    //  this.opportunity.prospect.mainProspect = this.prospect;
    this.opportunity.prospect.mainProspect = informations.prospectForm;
    //  this.opportunity.prospect.adress.postalCode = informations.informationProForm
    //  this.opportunity.prospect.adress.streetName = informations.informationProForm
    //  this.opportunity.prospect.adress.streetName = informations.informationProForm
    this.opportunity.prospect.mainProspect.CP = this.opportunitySim.codePostal;
    this.opportunity.prospect.mainProspect.relation = 'principal';
    if (informations.informationProForm.isPro) {
      const adress: any = {};
      adress.postalCode = informations.informationProForm.postalCode;
      adress.streetName = informations.informationProForm.streetName;
      adress.streetNumber = informations.informationProForm.streetNumber;
      adress.ville = informations.informationProForm.ville;
      this.opportunity.prospect.adress = adress;
      this.opportunity.prospect.RS = informations.informationProForm.RS;
      this.opportunity.prospect.siren = informations.informationProForm.siren;
      this.opportunity.prospect.typeProspect = 'professionnel';
      this.opportunity.prospect.type_opportunite = 'entrepise';
      this.opportunity.details.model_info_specifiques.clientType = 'Professionnal';
    } else {
      this.opportunity.details.model_info_specifiques.clientType = 'Private';
    }

    this.opportunity.interlocuteur.nom = this.user?.nom;
    this.opportunity.interlocuteur.prenom = this.user?.prenom;
    this.opportunity.interlocuteur.email = this.user?.email;
    this.opportunity.interlocuteur.tel = this.user.user?.mobile;

    this.apiOpportuniteService.insertOpportuniteVelo(this.opportunity, this.accessToken).subscribe(
      (data) => {
        this.idOpportunity = data.id_opportunite_md5;
        this.id_prospect_md5 = data.id_prospect_md5;
        this.show_loading_adding = false;
        this.geerateDevis.data = this.opportunity;
        this.geerateDevis.template_id = this.id_templateDevisFolote;
        this.generateDevis();
      },
      () => {
        this.alertError("Erreur servenue lors d'insertion d'une opportunité  ");
        this.show_loading_adding = false;
      }
    );
  }

  /*   generateDevisflote() {
    this.apiOpportuniteService.generateDevisFlotte(this.geerateDevis, this.accessToken).subscribe(
      (data) => {
        const blob = b64toBlob(data.response, '');
        const formData: FormData = new FormData();
        formData.append('id_pros', this.id_prospect_md5);
        formData.append('id_document', this.id_docFLote);
        formData.append('id_opp', this.idOpportunity);
         formData.append('name_doc', 'devis_flote_' + this.titre + '_' + this.dategenrateDevis);
         formData.append('file[]', blob, 'devis_flote_' + this.titre + '_' + this.dategenrateDevis);
        this.uploadDevis(formData);
        if (this.send) {
          this.sendMailbody.adresse_mail = this.opportunity.prospect.mainProspect.adresse_mail;
          this.sendMailbody.name = this.opportunity.prospect.mainProspect.name;
          this.sendMailbody.surname = this.opportunity.prospect.mainProspect.surname;
          this.sendMailbody.file_base64 = data.response;
          this.sendMailbody.file_name = 'devis_flote_' + this.titre + '_' + this.dategenrateDevis;
          this.sendMailbody.fileRef = this.titre + '_' + this.dategenrateDevis;
          this.sendMail(this.sendMailbody);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }
 */
  generateDevis() {
    // const Acces_Token = localStorage.getItem('Acces_Token');
    const bodyDevis: any = {};
    bodyDevis.opportunityRef = this.idOpportunity;
    bodyDevis.subscription_url =
      environment.subscription_url +
      '?brand=' +
      this.brand_id +
      '&partnership_id=' +
      this.apiKey +
      '&opportunityRef=' +
      this.idOpportunity +
      '&step=1';
    bodyDevis.step = this.step;
    bodyDevis.isPro = this.isPro;
    this.apiOpportuniteService.generateDevis(bodyDevis, this.api_Key).subscribe(
      (data) => {
        this.alertSuccess('Opportunité insérée avec succès', data);
        this.show_loading = false;
        this.show_loading_adding = false;
      },
      (err) => {
        console.error(err);
        this.show_loading = false;
        this.show_loading_adding = false;
        this.alertError("Erreur servenue lors d'insertion d'une opportunité  ");
      }
    );
  }

  /*   uploadDevis(formData) {
    this.apiOpportuniteService.uploadDocumentOpportuniteA(formData, this.accessToken).subscribe(
      (data) => {
        this.body = {
          entity: 'O',
          id_entity: this.idOpportunity,
          type_document: this.id_docFLote,
          data: {
            date: this.datePipe.transform(this.myDate, 'yyyy-MM-dd'),
            montant: this.opportunity.details.model_info_specifiques.purchasePrice,
            numero: this.idOpportunity,
            titre: this.titre,              this.loader = true,

          },
        };
        this.updateDocSecifique(this.body);
      },
      (err) => {
        console.error(err);
      }
    );
  }
 */
  login() {
    this.loader = false;
    this.apiAuthentificationService.getParterShipIdToken(this.apiKey).subscribe(
      (data: UserLogin) => {
        if (data.active && !data.error) {
          this.user = data;
          this.accessToken = data.Acces_Token;
          //  this.api_Key = data.user.api_key;
          // console.log("id org",data.user.entreprise_id)
          this.brand_id = data.user.entreprise_id;
          this.idEntreprise = data.user.entreprise_id;
          this.apiOrganismeService.GetOrganismeById(data.user.entreprise_id).subscribe(
            (data) => {
              this.organisme = data;
              this.couleur = this.organisme?.used_brand?.espace_distributeur?.couleur2;
              this.loader = true;
            },
            () => {
              this.loader = true;
            }
          );
        } else {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Problème de Login';
          notificationMessage.message = "Compte n'est pas activé";
          this.notificationsService.sendMessage(notificationMessage);
        }
      },
      () => {
        this.loader = true;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Problème de Login';
        notificationMessage.message = 'erreur servenue ';
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  alertSuccess(data, dat) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showConfirmButton: false,
        showDenyButton: false,
        showCloseButton: true,
        confirmButtonText: 'Détails',
        confirmButtonColor: '#68a45b',
        focusConfirm: false,
        showCancelButton: false,
      })
      .then((result) => {
        if (result.isDismissed) {
          this.closeModal.nativeElement.click();
          this.emptyFrais();
        }
      });
  }

  updateDocSecifique(body) {
    this.apiDocumentsService.SaveInformationSpecifiqueA(body, this.accessToken).subscribe(
      (response) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = 'Opération réussite';
        notificationMessage.message = 'Le formulaire a été enregistré avec succès';
        this.notificationsService.sendMessage(notificationMessage);
      },
      (error) => {
        if (error.includes('304')) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.info;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = 'Aucun changement détecté';
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = "Erreur lors de l'enregistrement du formulaire";
          this.notificationsService.sendMessage(notificationMessage);
        }
      }
    );
  }

  emptyFrais() {
    this.prospect = new Prospect();
  }

  getUserConnected() {
    this.apiUsersService.getUserWithIdGet().subscribe(
      (data: UserAdmin2) => {
        this.userAdmin = data;
        this.user.nom = this.userAdmin.user?.nom;
        this.user.prenom = this.userAdmin.user?.prenom;
        this.user.email = this.userAdmin.user?.mail;
        this.user.mobile = this.userAdmin.user.mobile;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  sendMail(bodysend) {
    this.apiOpportuniteService.SendDevisFlotte(bodysend, this.accessToken).subscribe(
      (data) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = 'Opération réussite';
        notificationMessage.message = 'Le devis a été envoyé avec succès';
        this.notificationsService.sendMessage(notificationMessage);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
