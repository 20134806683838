/**
 *  Json schema form Types
 */
import {FormField} from './FormField';

export class ArrayFormly extends FormField {
  uniqueItems;
  items:any={};
  minItems:number=0;
  maxItems:number=25;
  constructor() {
    super();
    super.icon="fa fa-th-list";
    this.type = 'array';
    this.name = 'array_'+ super.generateRandomId();
    super.title = null;
    super.typename = 'Array';
    this.widget.formlyConfig.templateOptions.col='12';
  }
}
