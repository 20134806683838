import { NgModule } from '@angular/core';
import { ProspectAddComponent } from './prospect-add.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../../../../angular-material/angular-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../../../../../shared/shared.module';

@NgModule({
  declarations: [ProspectAddComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, AngularMaterialModule, FontAwesomeModule, SharedModule],
  exports: [ProspectAddComponent],
})
export class ProspectFormModule {}
