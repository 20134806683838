import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FilterEcheances } from 'src/app/entity/FilterEcheances';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { EcheancierService } from 'src/app/services/ApiEcheancier/echeancier.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'app-filtre-echeancier',
  templateUrl: './filtre-echeancier.component.html',
  styleUrls: ['./filtre-echeancier.component.css']
})
export class FiltreEcheancierComponent implements OnInit, OnChanges {

  filterEcheancier: FilterEcheances = new FilterEcheances();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Output() onPushFilterEcheancier: EventEmitter<FilterEcheances> = new EventEmitter();
  fa = fa;
  /** Motif */
  _unsubscribeAll: Subscription[] = [];
  listeAllMotif: any[] = [];;
  selectable = true;
  removable = true;
  filteredMotif: Observable<any[]>;
  ListMotif: string[] = [];
  listMotifSelected: string[] = [];
  @ViewChild('MotifInput') MotifInput: ElementRef<HTMLInputElement>;
  MotifFromCtrl: FormControl = new FormControl('');

  /** date de prelevment */
  date_debut_periode: any = "";
  date_fin_periode: any = "";
  @Output() returnToparent = new EventEmitter<boolean>();

  @Input() resetEcheancier: boolean;
  constructor(private echeancierService: EcheancierService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {

    this.listMotif()
    this.filteredMotif = this.MotifFromCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter() : this.listeAllMotif.slice()))
    );
  }



  listMotif() {
    this.echeancierService
      .listeAllMotif().subscribe((response) => {
        this.listeAllMotif = response.motif
      });

  }


  selectedMotif(event: MatAutocompleteSelectedEvent): void {
    this.ListMotif.push(event.option.viewValue);
    this.listMotifSelected.push(event.option.value);
    this.MotifInput.nativeElement.value = '';
    this.MotifFromCtrl.setValue(null);
  }
  removeMotif(product: string): void {
    const index = this.ListMotif.indexOf(product);
    if (index >= 0) {
      this.ListMotif.splice(index, 1);
      this.listMotifSelected.splice(index, 1);
    }
  }

  private _filter(): any[] {
    let filterValue = this.MotifFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.listeAllMotif.filter((Motif) => Motif.libelle.toLowerCase().indexOf(filterValue) === 0);
  }


  EmitFilterEcheancier() {
    if (this.date_debut_periode) {
      let dateDebut = this.datePipe.transform(this.date_debut_periode, 'yyyy-MM-dd');
      this.filterEcheancier.date_debut_periode = dateDebut;

    } else {
      this.filterEcheancier.date_debut_periode = ""
    }

    if (this.date_fin_periode) {
      let dateFin = this.datePipe.transform(this.date_fin_periode, 'yyyy-MM-dd');
      this.filterEcheancier.date_fin_periode = dateFin;

    } else {
      this.filterEcheancier.date_fin_periode = ""
    }
    this.filterEcheancier.motif = this.listMotifSelected;

    this.onPushFilterEcheancier.emit(this.filterEcheancier);

  }


  ngOnChanges(changes: SimpleChanges) {
    this.resetEcheancierForm(changes.resetEcheancier.currentValue);
  }
  
  resetEcheancierForm(Reset) {
    if (Reset === true) {
      this.ListMotif = []
      this.filterEcheancier.motif = this.listMotifSelected = [];
      this.filterEcheancier.operateur_motif = "=";
      this.filterEcheancier.date_debut_periode = "";
      this.filterEcheancier.date_fin_periode = "";
      this.date_debut_periode = "";
      this.date_fin_periode = "";

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterEcheancier.emit(this.filterEcheancier);
    }
  }
}
