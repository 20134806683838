import { Component, OnInit } from '@angular/core';
import {FormField} from '../../../entity/Formbuilder/global.model';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-check-box-type',
  templateUrl: './check-box-type.component.html',
  styleUrls: ['./check-box-type.component.scss']
})
export class CheckBoxTypeComponent extends FieldType implements OnInit {

  colors: any = {
    accent: 'accent',
    warn: 'warn',
    primary: 'primary'
  };
  ngOnInit(): void {
  }
  setAll(completed: boolean) {
  }
}
