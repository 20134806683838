
export class Documents {
    id_doc: string = "";
    doc: string = "";
    oblig: string = "";
    existe: string = "";
    files: Files[] = [];
    libele: string = "";

}

export class Files {
    name: string = "";
    size: string = "";
    url: any[] = [];


}

export class SendMail {
    mail_destinataire: string = "";
    bcc: string = "";
    cc: string = "";
    body_content: string = "";
    sujet: string = "";
    id_opp: string = "";
    id_affaire: string = "";
    id_pros: string = "";
    id_modele: string = "";
    date_envoi: string = "";
    file: any[] = []
}