import {FormField} from './FormField';


export class ObjectFormly extends FormField {
  minProperties;
  maxProperties;
  constructor() {
    super();
    this.type = 'object';
    super.title = null;
  }

  properties;
}
