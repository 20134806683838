import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedMenuObserverService {
  currentMenuPosition = '';
  currentMenuPositionChange = new Subject<string>();

  currentIconMenu = '';
  currentIconMenuChange = new Subject<string>();

  updateMenu(currentPos: string){
    this.currentMenuPosition = currentPos;
    this.currentMenuPositionChange.next(currentPos);
  }

  updateIconMenu(icon: string){
    this.currentIconMenu = icon;
    this.currentIconMenu
    this.currentIconMenuChange.next(icon);
  }

  onUpdateMenu(){
    return this.currentMenuPositionChange.asObservable();
  }

  onUpdateIconMenu(){
    return this.currentIconMenuChange.asObservable();
  }
  constructor() { }
}
