<mat-radio-group color="primary" (change)="onItemChange($event)" aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="choix">
    <div class="row">
        <div class="col" *ngFor="let season of list_choix">
            <mat-radio-button class="example-radio-button" [value]="season.value">
                {{season.key}}
            </mat-radio-button>
        </div>
    </div>
</mat-radio-group>

<div *ngIf="(typeProspect$ | async) === 'particulier'">
    <!--Prsopect Principale-->
    <mat-accordion class="example-headers-align">
        <mat-expansion-panel class="expension-secondary-color" [expanded]="step === (0)" (opened)="setStep(0)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2 class="font-primary-geoprod">- Prospect Principal</h2>
                </mat-panel-title>
                <mat-panel-description>
                    <!--Description-->
                </mat-panel-description>
            </mat-expansion-panel-header>
            <app-prospect-form [schemaValue]="prospectSchema" [isRequired]="true" [typeOp]="'o'" [modeupdateProspect]="modeupdateProspect" [typeEntity]="'opportunite'" (onSelectToFillOldProspect)="onSelectToFillOldProspect($event)" [index]="'0'" [isMainProspect]="true"
                [type]="0" [prospect]="mainProspect$ | async" (onUpdateProspect)="updateProspect($event,-1)"></app-prospect-form>

            <!-- <mat-action-row>
        <button mat-button color="warn" (click)="prevStep()">Précédent</button>
        <button mat-button color="primary" (click)="nextStep()">Suivant</button>
      </mat-action-row> -->
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="example-headers-align">
        <ng-container *ngFor="let prospect  of listProspects$ | async; let index =index">
            <mat-expansion-panel class="expension-secondary-color mt-2" [expanded]="step === (index+1)" (opened)="setStep(index+1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="font-primary-geoprod">- Adhérent/Souscripteur N°{{index + 1}} </h2>
                    </mat-panel-title>
                    <mat-panel-description>
                        <!--Description-->
                        <div class="col-10"></div>
                        <div class="col-2" style="text-align: right;">
                            <!--  <span (click)="deleteProspect(index)"><img src="assets/icons/trash-icon-red.svg"></span> -->
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <app-prospect-form [schemaValue]="prospectSchema" [isRequired]="false" (onSelectToFillOldProspect)="onSelectToFillOldProspect($event)" [isMainProspect]="false" [type]="0" [index]="(index+1).toString()" [prospect]="prospect" (onUpdateProspect)="updateProspect($event,index)">
                </app-prospect-form>

                <!-- <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Précédent</button>
          <button mat-button color="primary" (click)="nextStep()">Suivant</button>
        </mat-action-row> -->
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
    <div *ngIf="person" style="margin: 25px 0" class="row">
        <div class="col-12 text-right">
            <button type="button" (click)="addProspect()" class="add-btn-em">
                    <img class="fa-icon-add" src="assets/icons/add-icon.svg">&nbsp;
                    Adhérent/Bénéficiaire
            </button>
        </div>
    </div>
</div>

<div *ngIf="(typeProspect$ | async)=== 'entrepise'">
    <app-prospect-pro-form [schemaValue]="EntrepriseSchema" (onSelectToFillOldProspect)="onSelectToFillOldProspect($event)" (onAddProspect)="addProspect()" (onUpdateEntreprise)="updateEntreprise($event)" (onUpdateProspect)="updateProspect($event.prospect, $event.index)" (onDeleteProspect)="deleteProspect($event)"
        [entreprise]="entreprise$ | async" [prospectPro]="prospectPro$ | async">
    </app-prospect-pro-form>
</div>


<!---------------------------------------Popoup search nom / prenom----------------------------------------------->
<div class="modal fade" id="Recherche-nomn-prenom" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
                <fa-icon class="icon-style fa-2x" [icon]="fa['faUsers']"></fa-icon>
                <h2 style="margin-left: 15px">Liste des Prospects</h2>
                <button type="button" class="close" data-dismiss="modal" (click)='resetSerach()' aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="conatainer-modal-elements">
                    <div class="input-group col-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nom/Prénom Prospect</mat-label>
                            <input [(ngModel)]="seartch_str" (ngModelChange)="findByName()" type="text" matInput>
                            <mat-icon matSuffix class="icon-edit">mode_edit</mat-icon>
                        </mat-form-field>
                    </div>
                    <form [formGroup]="formGroup" class="example-form">
                        <div class="row">
                            <div class="col-12 text-center">
                                <mat-spinner style="margin: 30px auto" [diameter]="50" *ngIf="loadingAutoCmplt"></mat-spinner>
                            </div>
                        </div>

                        <div class="table-responsive p-3" *ngIf="list_auto_fill_options?.length > 0 && !loadingAutoCmplt">
                            <table class="table align-items-center">
                                <thead class="thead-light" style="text-align: center;">
                                    <th></th>
                                    <th>
                                        Nom
                                    </th>
                                    <th>
                                        Prénom
                                    </th>
                                    <th>
                                        Ville
                                    </th>
                                </thead>
                                <tbody class="main-table-body">
                                    <ng-container *ngFor="let opt of list_auto_fill_options; let i = index">
                                        <tr class="hover-bg" (click)="setValueSelectedProspect(opt.id)">

                                            <td>
                                                <input type="radio" name="prospect_id" [value]="opt.id" formControlName="prospect_id">
                                            </td>
                                            <td>
                                                {{opt.name}}
                                            </td>
                                            <td>
                                                {{opt.surname}}
                                            </td>
                                            <td>
                                                {{opt.ville}}
                                            </td>
                                        </tr>
                                    </ng-container>


                                </tbody>
                            </table>
                        </div>
                    </form>
                    <div style="text-align: center;">
                        <button type="submit" (click)="onSubmit()" class="btn btn-primary" data-dismiss="modal">
              Selectionner
            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!---------------------------------------Popoup search telephone----------------------------------------------->
<div class="modal fade" id="Recherche-telephone" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
                <fa-icon class="icon-style fa-2x" [icon]="fa['faUsers']"></fa-icon>
                <h2 style="margin-left: 15px">Liste Prospects</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="conatainer-modal-elements">
                    <form [formGroup]="formGroup" class="example-form">
                        <div class="input-group mb-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Numéro du téléphone</mat-label>
                                <input (ngModelChange)="findByTelephone(formGroup.value.telephone)" type="tel" matInput formControlName="telephone">
                            </mat-form-field>
                            <div class="col-12 alert alert-danger" role="alert" *ngIf="!formGroup.controls['telephone'].valid && formGroup.controls['telephone'].touched">
                                Saisir au minimum 3 caractéres
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <mat-spinner style="margin: 30px auto" [diameter]="50" *ngIf="loadingAutoCmplt"></mat-spinner>
                            </div>
                        </div>
                        <div class="table-responsive p-3" *ngIf="list_auto_fill_options?.length > 0 && !loadingAutoCmplt">
                            <table class="table align-items-center">
                                <thead class="thead-light" style="text-align: center;">
                                    <th></th>
                                    <th>
                                        Nom
                                    </th>
                                    <th>
                                        Prénom
                                    </th>
                                    <th>
                                        Code Postal
                                    </th>
                                    <th>
                                        Ville
                                    </th>
                                </thead>
                                <tbody class="main-table-body">
                                    <tr class="hover-bg" (click)="setValueSelectedProspect(opt.id)" *ngFor="let opt of list_auto_fill_options">
                                        <td><input type="radio" name="prospect_id" [value]="opt.id" formControlName="prospect_id"></td>
                                        <td>{{opt.name}}</td>
                                        <td>{{opt.surname}}</td>
                                        <td>{{opt.CP}}</td>
                                        <td>{{opt.ville}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                    <div style="text-align: center;">
                        <button type="submit" (click)="onSubmit()" class="btn btn-primary" data-dismiss="modal">
              Selectionner
            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!---------------------------------------Popoup search entreprise ----------------------------------------------->
<div class="modal fade" id="Recherche-entreprise" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog model-lg" role="document">
        <div class="modal-content" style="width: 60rem; margin-left: -135px;">
            <div class="modal-header">
                <fa-icon class="icon-style fa-2x" [icon]="fa['faUsers']"></fa-icon>
                <h2 style="margin-left: 15px">Liste des sociétés</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="conatainer-modal-elements">
                    <div class="input-group row">
                        <div class="col-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>SIREN</mat-label>
                                <input [(ngModel)]="entrepriseRecherche.siren" min="0" (ngModelChange)="rechercheSociete()" type="number" matInput maxlength="9" placeholder="412xxxxxx">
                            </mat-form-field>

                        </div>
                        <div class="col-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>SIRET</mat-label>
                                <input [(ngModel)]="entrepriseRecherche.siret" min="0" maxlength="9" placeholder="412xxxxxx" (ngModelChange)="rechercheSociete()" type="number" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Email</mat-label>
                                <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [(ngModel)]="entrepriseRecherche.mail" name="mail" #mail=ngModel (ngModelChange)="rechercheSociete()" type="text" matInput>
                            </mat-form-field>
                            <div *ngIf="mail.invalid && (mail.dirty || mail.touched)" class="message-erreur">
                                <div *ngIf="mail.errors.pattern">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Adresse e-mail invalide! exemple: geoprod@mail.com
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Web</mat-label>
                                <input [(ngModel)]="entrepriseRecherche.web" #url="ngModel" pattern="https?://.+" name="url" (ngModelChange)="rechercheSociete()" type="text" matInput>
                            </mat-form-field>
                            <div *ngIf="url.invalid && (url.dirty || url.touched)" class="message-erreur">
                                <div *ngIf="url.errors.pattern">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Format invalide ! (exemple : https://...)
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Convention collective</mat-label>
                                <input [(ngModel)]="entrepriseRecherche.convention_collective" (ngModelChange)="rechercheSociete()" type="text" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Nom commercial</mat-label>
                                <input [(ngModel)]="entrepriseRecherche.nom_commercial" (ngModelChange)="rechercheSociete()" type="text" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="example-full-width" style="margin-top: -13px;" appearance="standard" floatLabel="always">
                                <mat-label class="ng2tel-field-label">Téléphone</mat-label>
                                <input ng2TelInput matInput name="tel" (keypress)="numberOnly($event)" [(ngModel)]="entrepriseRecherche.tel" (ngModelChange)="rechercheSociete()" [ng2TelInputOptions]="{initialCountry: telCountry}">
                                <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Raison sociale</mat-label>
                                <input [(ngModel)]="entrepriseRecherche.RS" (ngModelChange)="rechercheSociete()" type="text" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                    <form [formGroup]="formGroup" class="example-form">
                        <div class="row">
                            <div class="col-12 text-center">
                                <mat-spinner style="margin: 30px auto" [diameter]="50" *ngIf="loadingAutoCmplt"></mat-spinner>
                            </div>
                        </div>

                        <div class="table-responsive p-3" *ngIf="getSearchResult?.length > 0 && !loadingAutoCmplt">
                            <table class="table align-items-center ">
                                <thead class="thead-light" style="text-align: center;">
                                    <th></th>
                                    <th>
                                        Raison sociale
                                    </th>
                                    <th>
                                        SIREN
                                    </th>
                                    <th>
                                        SIRET
                                    </th>
                                    <th>
                                        Code postal
                                    </th>
                                </thead>
                                <tbody class="main-table-body">
                                    <ng-container *ngFor="let ste of getSearchResult; let i = index">
                                        <tr class="hover-bg" (click)="setValueSelectedProspect(ste.id)">

                                            <td>
                                                <input type="radio" name="entreprise_id" [value]="ste.id" formControlName="entreprise_id">
                                            </td>
                                            <td>
                                                {{ste.RS}}
                                            </td>
                                            <td>
                                                {{ste.siren}}
                                            </td>
                                            <td>
                                                {{ste.siret}}
                                            </td>
                                            <td>
                                                {{ste.siret}}
                                            </td>
                                        </tr>
                                    </ng-container>


                                </tbody>
                            </table>
                        </div>
                    </form>
                    <div style="text-align: center;">
                        <button type="submit" (click)="onSubmitEntreprise()" class="btn btn-primary" data-dismiss="modal">
              Selectionner
            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
