<div class="row">
    <div class="col mt-2" style="max-width: 248px;">
        <div class="sidebar-ticket position-fixed" id="scrollbar-ticket">
            <div class="card-ticket bg-white text-center border-0">
                <div class="row mb-4">
                    <div class="col-12 mb-2">
                        <app-changestatusbutton data-toggle="modal" [label]="'Changer le statut'" (click)="getStatus()" [allowed_to_update]="true" data-toggle="modal" data-target="#Actions"></app-changestatusbutton>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <app-shared-button-actuali></app-shared-button-actuali>
                        </div>
                        <div class="col-10">
                            <app-shared-button-enrg class="ml-2" [label]="'Enregistrer'" (click)="updateTicket()"></app-shared-button-enrg>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <button type="button" class="btn-dt-retour btn-custom-details" (click)="returnToList()">
                <div class="icon-position">
                  <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
                </div>
                <span> Retour à la liste </span>
              </button>
                    </div>
                </div>

                <div class="col py-1 my-1">
                    <button (click)="scroll('infoTicket')" class="focus-style d-block border-0 full-width text-left pl-3">
            <label  class="align-details-icon">
              <fa-icon class="fa-lg" [icon]="fa['faTasks']"></fa-icon>
            </label>
            <span>Détails</span>
          </button>
                </div>
 
                <!-- début partie décommenter 
                   <div class="col py-1 my-1" *ngIf="informationTicket.id_prospect!==null && informationTicket.id_affaire==null">
                    <button (click)="scroll('infoProspect')" class="focus-style d-block border-0 full-width text-left pl-3">
            <label  class="align-details-icon">
              <fa-icon class="fa-lg" [icon]="fa['faUserTie']"></fa-icon>
            </label >
            <span>Informations prospect</span>
          </button>
                </div> -->

                <div class="col py-1 my-1" *ngIf="informationTicket.id_prospect!==null">
                    <button (click)="scroll('infoProspect')" class="focus-style d-block border-0 full-width text-left pl-3">
            <label  class="align-details-icon" >
              <fa-icon class="fa-lg" [icon]="fa['faUserTie']"></fa-icon>
            </label>
            <span *ngIf="informationTicket.id_prospect!==null && informationTicket.id_affaire===null" > Informations prospect</span>
            <span *ngIf="informationTicket.id_affaire!==null && informationTicket.id_prospect!==null"> Informations client </span>
          </button>
                </div>

                <div class="col py-1 my-1">
                    <button (click)="scroll('infoSpecifique')" class="focus-style d-block border-0 full-width text-left pl-3">
            <label  class="align-details-icon">
              <fa-icon class="fa-lg" [icon]="fa['faInfoCircle']"></fa-icon>
            </label>
            <span>Informations spécifiques</span>
          </button>
                </div>

                <div class="col py-1 my-1">
                    <button (click)="scroll('porteDocument')" class="focus-style d-block border-0 full-width text-left pl-3">
            <label  class="align-details-icon">
              <fa-icon class="fa-lg" [icon]="fa['faFileImport']"></fa-icon>
            </label>
            <span>Porte Documents</span>
          </button>
                </div>

                <div class="col py-1 my-1">
                    <button (click)="scroll('comment')" class="focus-style d-block border-0 full-width text-left pl-3">
            <label  class="align-details-icon">
              <fa-icon class="fa-lg" [icon]="fa['faCommentDots']"></fa-icon>
            </label>
            <span>Commentaires</span>
          </button>
                </div>

                <div class="col py-1 my-1">
                    <button (click)="scroll('history')" class="focus-style d-block border-0 full-width text-left pl-3">
            <label  class="align-details-icon">
              <fa-icon class="fa-lg" [icon]="fa['faHistory']"></fa-icon>
            </label>
            <span>Historique</span>
          </button>
                </div>
            </div>

            <div class="text-left">
                <shared-tags [tickets_id]="tickets_id"></shared-tags>
            </div>
        </div>
    </div>

    <div class="col overflow-auto">
        <div class="card-ticket-header bg-white border-0 p-3 mx-5 mt-2">
            <ng-container *ngIf="infoTicketLoader">
                <span class="d-flex justify-content-center">
            <mat-spinner [diameter]="30"></mat-spinner>
        </span>
            </ng-container>
            <ng-container *ngIf="!infoTicketLoader">
                <div class="row">
                    <div class="col-9 d-flex">
                        <h3><b>Ticket n°:</b></h3>&nbsp;&nbsp;
                        <h3>&nbsp;{{informationTicket.id_ticket}}</h3>
                    </div>
                    <div class="col-3 d-flex">
                        <h3><b>Catégorie:</b></h3>&nbsp;&nbsp;
                        <h3>&nbsp;{{informationTicket.categorie}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9 d-flex">
                        <ng-container *ngIf="informationTicket.id_affaire!==null && informationTicket.id_affaire!==''">
                            <h3><b>Affaire n:°</b></h3>
                            <a class="a-style row" (click)="NaviagateToAffaireDetials(informationTicket.id_affaire)" style="cursor: pointer;">
                    <h3 >{{informationTicket.real_id_affaire}}</h3>
                            </a> 
                        </ng-container>
                    </div>
                    <div class="col-3 d-flex">
                        <h3><b>Statut</b>:</h3>&nbsp;&nbsp;
                        <label class="header-ticket-status text-center p-2" [ngStyle]="{'background':informationTicket.statut_details?.background_color}">
              <span [ngStyle]="{'color':informationTicket.statut_details?.color}" > 
              <fa-icon class="mr-2" [icon]="fa[informationTicket.statut_details?.icon]"></fa-icon>
                  {{informationTicket.statut_details?.libelle.split('(')[0].trim()}}
              </span>
            </label>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row">
            <div class="col-12 p-0">
                <div class="pt-3 mb-2" id="infoTicket">
                    <app-informations-ticket (emitUpdateTicket)="alertConfirmUpdate($event)"></app-informations-ticket>
                </div>
<!--             -->
                <div class="pt-3 mb-2" id="infoProspect">
                    <app-ticket-coordonnees-prospect ></app-ticket-coordonnees-prospect>
                  
                </div>

                <div class="pt-3 mb-2" id="infoSpecifique">
                    <app-ticket-informations-specifique (emitFormTicket)="geEmitInfoSpecifique($event)" (changeFormInfoSpecifique)="getChangeForm($event)"></app-ticket-informations-specifique>
                </div>


                <div class="pt-3 mb-2" id="porteDocument">
                    <app-liste-documents></app-liste-documents>
                </div>

                <div class="pt-3 mx-5 pb-3"  id="comment">
                    <app-commentaires-client-ticket></app-commentaires-client-ticket>
                </div>

                <div class="pt-3 mb-5" id="history">
                    <app-ticket-historique></app-ticket-historique>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
                <h3 class="modal-title" id="exampleModalLongTitle">Changer le statut du ticket</h3>
                <ng-container *ngIf="loaderStatus">
                    <span class="d-flex justify-content-center">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </span>
                </ng-container>
                <ng-container *ngIf="!loaderStatus">
                    <div *ngFor="let status of statusList">
                
                        <label class="ticket-status cursor-pointer ml-2  " (click)="changeStatus(status.libelle,status.color,status.id,status.icon)" 
                        [ngStyle]="{'background':status?.background_color}" data-dismiss="modal">
                        <span   [ngStyle]="{'color':status?.color}"  class="pt-1 text-center"  Style="font-family: 'Nunito Sans'; margin: auto;">
                        <fa-icon class="mr-1" [icon]="fa[status?.icon]"></fa-icon>
                        {{status.libelle.split('(')[0].trim()}}
                      </span>
                    </label>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
