<div class=" card-historiques bg-white p-4" [ngClass]="{'cover': !allowed_to_update_affaire}">
    <div class="card-doc-title ml-3 row">
        <fa-icon class="mr-2 fa-lg " [icon]="fa['faHistory']"></fa-icon>
        <h4>Historique</h4>
    </div>
    <mat-form-field class="w-100">
        <input matInput (keyup)="applyFilter($event)" placeholder="Recherche..." #input>
    </mat-form-field>
    <div class="mat-elevation-z8">
        <table mat-table class="w-100" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Action </th>
                <td mat-cell *matCellDef="let row"> {{row.date_action}} </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Utilisateur </th>
                <td mat-cell *matCellDef="let row"> {{row.utilisateur}} </td>
            </ng-container>
            <ng-container matColumnDef="commentaire">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire Standard </th>
                <td mat-cell *matCellDef="let row"> {{row.commentaire_stantard}} </td>
            </ng-container>
            <ng-container matColumnDef="export">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Time Machine</th>
                <td mat-cell *matCellDef="let row">

                    <button type="button" class="btn-dt-ouvrir" *ngIf="row.json_object" (click)="getJson(row.json_object)" data-toggle="modal" data-target="#JsonModal">
            <fa-icon class="icon-btn-ouvrir" [icon]="fa['faExternalLinkAlt']"></fa-icon>&nbsp;Historique
          </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">Pas de données disponibles </td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>

<!-- Consulter Modal json--------------------------------------------------------------------------------- -->


<div class="modal fade" id="JsonModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <form #myForm="ngForm">
                <div class="modal-header border-0">
                    <h3 class="mt-2" id="exampleModalLabel">Données</h3>
                    <button #closeModal type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">

                    <div class="container-modal-elements">
                        <div class="border-1">

                            <!-- <json-editor [options]="editorOptions" [data]="json_object"></json-editor> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <button class="btn-dt-cancel-popup" type="button" data-dismiss="modal" aria-label="Close" #ClosePopup>
                      <fa-icon [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                              Fermer
                  </button>
                </div>
            </form>
        </div>
    </div>
</div>
