

<div class="row">
  <div class="position-fixed" style="z-index: 5;" *ngIf="apearFilter">
    <app-filter-echeances (returnToparent)="resetformFilter($event)" [resetAllFilter]="resetAllFilter" (onPushFilters)="allFilter($event)" (onSubmitFilter)="submitFilter($event)">
    </app-filter-echeances>
  </div>
</div>

<div class="card mb-4 border-0" style="border-radius: 13px;">
  <div class="row">
    <div class="col-3"></div>
   <!--  <div class="col-4 " >
      <mat-form-field appearance="fill" style="float: right;">
        <mat-label>Date de prélevement</mat-label>
        <input matInput [matDatepicker]="pickerDebut" id="date_prelevement" name="date_prelevement"
          [(ngModel)]="date_prelevement" [value]="date_prelevement">
        <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
        <mat-datepicker #pickerDebut></mat-datepicker>
      </mat-form-field>
    </div> -->
  <!--   <div class="col-5 mt-2">
      <div class="row">
     
     <div class="col-5">
              <mat-form-field  class="w-100">
                  <mat-label>Date de prélevement début </mat-label>
                  <input matInput [matDatepicker]="pickerDebut" id="date_debut" name="date_debut" [(ngModel)]="date_prelevementDebut">
                  <mat-datepicker-toggle matSuffix [for]="pickerDebut"></mat-datepicker-toggle>
                  <mat-datepicker #pickerDebut></mat-datepicker>
              </mat-form-field>
          </div> 
          <div class="col-5">
              <mat-form-field  class="w-100">
                  <mat-label>Date de prélevement fin </mat-label>
                  <input matInput [matDatepicker]="pickerFin" id="date_fin" name="date_fin" [(ngModel)]="date_prelevementFin">
                  <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFin></mat-datepicker>
              </mat-form-field>
          </div>
          <div class="col-2 w-100 pl-0 mt-3">
              <button type="button" class="btn-dt-filter" (click)="filterWithDate()">
                <fa-icon class="fa-fw" [icon]="fa['faFilter']"></fa-icon>&nbsp;
                Filtrer
              </button>
          </div>
      </div>
  </div> -->
 <!--    <div class="col-2 w-100 pl-0 mt-3">
      <button type="button" class="btn-dt-filter" (click)="filterWithDate()">
        <fa-icon class="fa-fw" [icon]="fa['faFilter']"></fa-icon>&nbsp;
        Filtrer
      </button>
    </div> -->
    <div class="col-5 mt-2"></div>
    <div class="col-4 w-50 p-2 mt-2 text-right">
      <input class="search" name="search" [(ngModel)]="search"  (keyup)="filter($event)" placeholder="Recherche...">
      <fa-icon class="icon-search fa-md" [icon]="fa['faSearch']"></fa-icon>
    </div>
  </div>
  <div class="row row-info">

    <div class="card-info-1"  >
      <p class="p-info-1">{{total_PAYE |currency: currentCurrency}} </p>
      <p class="p-info-2">Total payés</p>
    </div>
    <div class="card-info-3" (click)="setStatusFilter('PAYE')">
      <p class="p-info-1">{{total_PAYE_month_ago |currency: currentCurrency}}</p>
      <p class="p-info-2"> J-30 payés</p>
    </div>
    <div class="card-info-4" (click)="setStatusFilter('IMPAYE')">
      <p class="p-info-1">{{total_IMPAYE_month_ago |currency: currentCurrency}}</p>
      <p class="p-info-2"> J-30 Impayés</p>
    </div>

    <div class="card-info-2" (click)="setStatusFilter('PLANIFIE')">
      <p class="p-info-1">{{total_PLANIFIE_next_month | currency: currentCurrency}} </p>
      <p class="p-info-2">J+30 planifiés</p>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> N° </th>
      <td class="text-left" mat-cell *matCellDef="let element ; "> {{element.position}} </td>
    </ng-container>
    <ng-container matColumnDef="nameClient">
      <th mat-header-cell *matHeaderCellDef class="text-center" > Client </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} {{element.surname}} </td>
    </ng-container>
    <ng-container matColumnDef="motif">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Motif </th>
      <td mat-cell *matCellDef="let element"> {{element.motif}} </td>
    </ng-container>
    <ng-container matColumnDef="date_debut_periode">
      <th mat-header-cell *matHeaderCellDef class="text-center" > Date de
        début </th>
      <td mat-cell *matCellDef="let element"> {{element.date_debut_periode | date: 'dd/MM/yyyy' }} </td>
    </ng-container>
    <ng-container matColumnDef="date_fin_periode">
      <th mat-header-cell *matHeaderCellDef class="text-center" > Date de fin
      </th>
      <td mat-cell *matCellDef="let element"> {{element.date_fin_periode | date: 'dd/MM/yyyy'}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="text-center" > Statut
      </th>
      <td mat-cell *matCellDef="let element"> 

        <div class="d-flex justify-content-evenly mt-3" id="box">
          <div *ngIf="element.status == 'PAYE'"
            class="btn-status w-50 echeance-status-color echeance-payed-status">
            Payé
          </div>
          <div *ngIf="element.status == 'DEFAUT'" 
            class="btn-status w-50 echeance-status-color echeance-default-status">
            Défaut
          </div>
          <div *ngIf="element.status == 'IMPAYE'" 
            class="btn-status w-50 echeance-status-color echeance-cancel-status">
            Impyé
          </div>
          <div *ngIf="element.status == 'Information Bancaire manquante'"
          class="btn-status w-50 echeance-status-color echeance-cancel-status">
          Information Bancaire manquante
        </div>
          <div *ngIf="element.status == 'PLANIFIE'" 
            class="btn-status w-50 echeance-status-color echeance-planned-status">
            Planifiée
          </div>
          <div *ngIf="element.status  == 'ENCOURS'" 
            class="btn-status w-50 echeance-status-color echeance-pending-status">
            En cours
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="affaire_id">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Affaire
      </th>
      <td mat-cell *matCellDef="let element">
        <button class="btn-dt-link" [routerLink]="['/affaires/details/'+element.affaire_id]" >
          <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
          Affaire
        </button>


      </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row"
          (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
<div class="row w-100 ">
  <div class="col-2"></div>
  <div class="col w-100 scrollDiv">
    <table class="table  mt-3" >
      <thead class="headtab">
        <tr>
          <th><strong>N°</strong></th>
          <th><strong>Date de début</strong></th>
          <th><strong>Date de fin</strong></th>
          <th><strong>Date de prélevement</strong></th>
          <th><strong>Montant</strong></th>
          <th><strong>Statut</strong></th>
        </tr>
      </thead>
      <tbody *ngFor="let item of element.echeances ; let index = index;">
        <td>{{index+1}}</td>
        <td class="custumWidth">{{item.date_debut_periode  | date: 'dd/MM/yyyy'}}</td>
        <td class="custumWidth" >{{item.date_fin_periode  | date: 'dd/MM/yyyy'}}</td>
        <td class="custumWidth" >{{item.date_prelevement  | date: 'dd/MM/yyyy'}}</td>
        <td class="custumWidth" >{{item.montant}} {{currentCurrency}}</td>
        <td class="custumWidth" >

          <div class="d-flex justify-content-evenly" id="box">
            <div *ngIf="item.status == 'PAYE'" 
              class="btn-status w-100 echeance-status-color echeance-payed-status">
              Payé
            </div>
            <div *ngIf="item.status == 'IMPAYE'" 
            class="btn-status w-100 echeance-status-color echeance-cancel-status">
            Impyé
          </div>
            <div *ngIf="item.status == 'DEFAUT'" 
              class="btn-status w-100 echeance-status-color echeance-default-status">
              Défaut
            </div>
      
            <div *ngIf="item.status == 'PLANIFIE'" 
              class="btn-status w-100 echeance-status-color echeance-planned-status">
              Planifiée
            </div>
            <div *ngIf="item.status  == 'ENCOURS'" 
              class="btn-status w-100 echeance-status-color echeance-pending-status">
              En cours
            </div>
          </div>
        </td>


      </tbody>
    </table>
  </div>

</div>
        


        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;  "
      class="example-element-row" [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail'] " class="example-detail-row"></tr>
  </table>
  <div *ngIf="dataSource.length==0 && this.loader">
    <p class="text-center mt-3" style="font-size: 16px;">pas des écheanciers</p>
  </div>
  <div *ngIf="!this.loader">
    <p class="text-center mt-3 d-flex justify-content-center" style="justify-content: center;">
      <mat-spinner [diameter]="40"></mat-spinner>
    </p>
  </div>
  <div class="navbar-center mb-4 row"  *ngIf="dataSource.length >0">
    <div class="col pt-1">
      <div class="row w-50 flex-nowrap" >
        <div  class="col mt-1">
          <p>Afficher</p>
        </div>
        <div  class="col mx-1">
          <select [(ngModel)]="limit"  (change)="updateListEcheancier()" 
          class="bg-white border pt-1 rounded font-weight-bold">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="col mt-1">
          <p>Entrées</p>
        </div>
      </div>
    </div>
    <div class="col-8">
      <nav aria-label="Page navigation example"  *ngIf="totalPages>0">
        <ul class="pagination pg-blue">
          <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
            <a class="page-link-symbol">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2" >
            <a class="page-link-symbol-chevron" aria-label="Previous">
              <i class="fa fa-chevron-left "></i>
            </a>
          </li>
          <ng-container *ngFor="let item of [].constructor(3); let i = index">
            <li *ngIf="i+page-3>0" class="page-item">
              <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page - 3}}</a>
            </li>
          </ng-container>
          <ng-container *ngFor="let item of [].constructor(3); let i = index">
            <li *ngIf="i+page<totalPages" class="page-item">
              <a (click)="changepage(i+page)" class="page-link mt-2 border rounded" 
              [ngClass]="{'active-page':page==(i+page)}">
              {{i + page}}
            </a>
            </li>
          </ng-container>
          <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
          <li class="page-item">
            <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
              [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
          </li>
          <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
            <a class="page-link-symbol-chevron" aria-label="Next">
              <i class="fa fa-chevron-right"></i>
            </a>
          </li>
          <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
            <a class="page-link-symbol">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
        </ul>
      </nav>
  </div>
    <div class="col">
      <div class="row position-absolute flex-nowrap" style="right: 0px">
        <div class="col text-nowrap text-right mt-2">
          <p >Aller à la page N°</p>
        </div>
        <div class="col mt-1 ml-2">
          <input [(ngModel)]="intervale"    size="1"    min="1"  onkeypress="return event.charCode >= 48"  (keyup.enter)="getchangeLimite()"  (change)="getchangeLimite()"  type="number" style="margin-left: 4px;"
          size="1" class="page_input bg-white border rounded font-weight-bold" [value]="page" max="{{totalPages}}">
        </div>
      </div>
    </div>
  </div>