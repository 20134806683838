/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from "../../../environments/environment";
import { UserProfile } from "../../../app/entity/UserProfile";
import { UserAdmin2 } from 'src/app/entity/User/UserAdmin2';


@Injectable()
export class ApiUsersService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Retourne le nom et id de tous les utilisateurs pour être utilisés dans les filtres
   */
  public getAllUsers(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    let headers = this.defaultHeaders;
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set(
        'idSession',
        this.configuration.apiKeys['idSession']
      );
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/get_all_users?`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retourne une list des groupes d'utilisateurs
   */
  public getGroupUsers(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_group_user`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * Sert a ajoute une url favorer de un utilisateur
   *
   * @param favoLink Url favorer
   * @param idUser Identifiant de l&#x27;utilisateur codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addFavoriteGet(favoLink: ModelObject, idUser: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public addFavoriteGet(favoLink: ModelObject, idUser: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public addFavoriteGet(favoLink: ModelObject, idUser: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public addFavoriteGet(favoLink: ModelObject, idUser: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (favoLink === null || favoLink === undefined) {
      throw new Error('Required parameter favoLink was null or undefined when calling addFavoriteGet.');
    }

    if (idUser === null || idUser === undefined) {
      throw new Error('Required parameter idUser was null or undefined when calling addFavoriteGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (favoLink !== undefined && favoLink !== null) {
      queryParameters = queryParameters.set('favo_link', <any>favoLink);
    }
    if (idUser !== undefined && idUser !== null) {
      queryParameters = queryParameters.set('id_user', <any>idUser);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/add_favorite`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;ajouter un groupe d&#x27;utilisateur qui travaille sur un meme site par exemple et les affecter a un manager
   *
   * @param cle Cle de l&#x27;operation soit create ou update
   * @param manager Identifiant de manager codé en md5
   * @param nom Nom de groupe utilisateurs
   * @param description Description de groupe utilisateurs
   * @param id Identifiant de groupe utilisateurs codé en md5 &lt;br&gt; Obligatoire seulement a pour l&#x27;update
   * @param listUser List des identifiants des utilisateurs codé en md5 separé apr des virgules
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createOrUpdateGroupUserGet(cle: string, manager: ModelObject, nom: ModelObject, description: ModelObject, id?: ModelObject, listUser?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createOrUpdateGroupUserGet(cle: string, manager: ModelObject, nom: ModelObject, description: ModelObject, id?: ModelObject, listUser?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createOrUpdateGroupUserGet(cle: string, manager: ModelObject, nom: ModelObject, description: ModelObject, id?: ModelObject, listUser?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createOrUpdateGroupUserGet(cle: string, manager: ModelObject, nom: ModelObject, description: ModelObject, id?: ModelObject, listUser?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling createOrUpdateGroupUserGet.');
    }

    if (manager === null || manager === undefined) {
      throw new Error('Required parameter manager was null or undefined when calling createOrUpdateGroupUserGet.');
    }

    if (nom === null || nom === undefined) {
      throw new Error('Required parameter nom was null or undefined when calling createOrUpdateGroupUserGet.');
    }

    if (description === null || description === undefined) {
      throw new Error('Required parameter description was null or undefined when calling createOrUpdateGroupUserGet.');
    }



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (manager !== undefined && manager !== null) {
      queryParameters = queryParameters.set('manager', <any>manager);
    }
    if (nom !== undefined && nom !== null) {
      queryParameters = queryParameters.set('nom', <any>nom);
    }
    if (description !== undefined && description !== null) {
      queryParameters = queryParameters.set('description', <any>description);
    }
    if (listUser !== undefined && listUser !== null) {
      queryParameters = queryParameters.set('list_user', <any>listUser);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/create_or_update_group_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Creation un utilisateur et ses paramétre (role , wermail , groupe , ssl, adresse)
   *
   * @param civilite
   * @param matriculeRh Matricule Rh de l&#x27;utilisateur
   * @param identiteReelle identite reel de l&#x27;utilisateur
   * @param nom nom de l&#x27;utilisateur
   * @param prenom prenom de l&#x27;utilisateur
   * @param dateDeNaissance Date de naissance de l&#x27;utilisateur sous format DD/MM/YYYY
   * @param password Mot de passe utilisateur
   * @param apiKey Api key de l&#x27;utilisateur
   * @param role Identifiant de role de de l&#x27;utilisateur (Super admin &#x3D;&#x3D;5) a demander par mail a it@sodedif.fr
   * @param organisme Identifiant de l&#x27;organisme de l&#x27;utilisateur ,(a demander par mail a it@sodedif.fr)
   * @param webmailMail login (adresse mail utilisateur)
   * @param webmailPwd password (adresse mail utilisateur)
   * @param webmailServerIn Serveur des courrier entrant
   * @param webmailPortOut port sortant
   * @param webmailPortIn port entrant
   * @param webmailSsl port entrant
   * @param skype Skype de l&#x27;utilisateur
   * @param numTel numero de telephone de l&#x27;utilisateur
   * @param numInter numero de interne telephone de l&#x27;utilisateur
   * @param urlPhoto Url de photo de l&#x27;utilisateur
   * @param proprietaire proprietaire
   * @param pointage pointage
   * @param superAdmin super_admin
   * @param adminRestreint admin_restreint
   * @param code Code Utuilisateurs
   * @param nomProducteur nom producteur
   * @param prenomProducteur prenom producteur
   * @param qualiteProducteur qualite producteur
   * @param oriasProducteur N°ORIAS producteur
   * @param adresseAuto Adresses autorisées
   * @param heureDeb Heure début format HH:MM
   * @param heureFin Heure fin format HH:MM
   * @param samediOff samedi_off
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createUserGet(civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createUserGet(civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createUserGet(civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createUserGet(civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (civilite === null || civilite === undefined) {
      throw new Error('Required parameter civilite was null or undefined when calling createUserGet.');
    }

    if (matriculeRh === null || matriculeRh === undefined) {
      throw new Error('Required parameter matriculeRh was null or undefined when calling createUserGet.');
    }

    if (identiteReelle === null || identiteReelle === undefined) {
      throw new Error('Required parameter identiteReelle was null or undefined when calling createUserGet.');
    }

    if (nom === null || nom === undefined) {
      throw new Error('Required parameter nom was null or undefined when calling createUserGet.');
    }

    if (prenom === null || prenom === undefined) {
      throw new Error('Required parameter prenom was null or undefined when calling createUserGet.');
    }

    if (dateDeNaissance === null || dateDeNaissance === undefined) {
      throw new Error('Required parameter dateDeNaissance was null or undefined when calling createUserGet.');
    }

    if (password === null || password === undefined) {
      throw new Error('Required parameter password was null or undefined when calling createUserGet.');
    }

    if (apiKey === null || apiKey === undefined) {
      throw new Error('Required parameter apiKey was null or undefined when calling createUserGet.');
    }

    if (role === null || role === undefined) {
      throw new Error('Required parameter role was null or undefined when calling createUserGet.');
    }

    if (organisme === null || organisme === undefined) {
      throw new Error('Required parameter organisme was null or undefined when calling createUserGet.');
    }
























    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (civilite !== undefined && civilite !== null) {
      queryParameters = queryParameters.set('civilite', <any>civilite);
    }
    if (matriculeRh !== undefined && matriculeRh !== null) {
      queryParameters = queryParameters.set('matricule_rh', <any>matriculeRh);
    }
    if (identiteReelle !== undefined && identiteReelle !== null) {
      queryParameters = queryParameters.set('identite_reelle', <any>identiteReelle);
    }
    if (nom !== undefined && nom !== null) {
      queryParameters = queryParameters.set('nom', <any>nom);
    }
    if (prenom !== undefined && prenom !== null) {
      queryParameters = queryParameters.set('prenom', <any>prenom);
    }
    if (dateDeNaissance !== undefined && dateDeNaissance !== null) {
      queryParameters = queryParameters.set('Date de naissance', <any>dateDeNaissance);
    }
    if (password !== undefined && password !== null) {
      queryParameters = queryParameters.set('password', <any>password);
    }
    if (apiKey !== undefined && apiKey !== null) {
      queryParameters = queryParameters.set('api_key', <any>apiKey);
    }
    if (role !== undefined && role !== null) {
      queryParameters = queryParameters.set('role', <any>role);
    }
    if (organisme !== undefined && organisme !== null) {
      queryParameters = queryParameters.set('organisme', <any>organisme);
    }
    if (webmailMail !== undefined && webmailMail !== null) {
      queryParameters = queryParameters.set('webmail_mail', <any>webmailMail);
    }
    if (webmailPwd !== undefined && webmailPwd !== null) {
      queryParameters = queryParameters.set('webmail_pwd', <any>webmailPwd);
    }
    if (webmailServerIn !== undefined && webmailServerIn !== null) {
      queryParameters = queryParameters.set('webmail_server_in', <any>webmailServerIn);
    }
    if (webmailPortOut !== undefined && webmailPortOut !== null) {
      queryParameters = queryParameters.set('webmail_port_out', <any>webmailPortOut);
    }
    if (webmailPortIn !== undefined && webmailPortIn !== null) {
      queryParameters = queryParameters.set('webmail_port_in', <any>webmailPortIn);
    }
    if (webmailSsl !== undefined && webmailSsl !== null) {
      queryParameters = queryParameters.set('webmail_ssl', <any>webmailSsl);
    }
    if (skype !== undefined && skype !== null) {
      queryParameters = queryParameters.set('skype', <any>skype);
    }
    if (numTel !== undefined && numTel !== null) {
      queryParameters = queryParameters.set('num_tel', <any>numTel);
    }
    if (numInter !== undefined && numInter !== null) {
      queryParameters = queryParameters.set('num_inter', <any>numInter);
    }
    if (urlPhoto !== undefined && urlPhoto !== null) {
      queryParameters = queryParameters.set('url_photo', <any>urlPhoto);
    }
    if (proprietaire !== undefined && proprietaire !== null) {
      queryParameters = queryParameters.set('proprietaire', <any>proprietaire);
    }
    if (pointage !== undefined && pointage !== null) {
      queryParameters = queryParameters.set('pointage', <any>pointage);
    }
    if (superAdmin !== undefined && superAdmin !== null) {
      queryParameters = queryParameters.set('super_admin', <any>superAdmin);
    }
    if (adminRestreint !== undefined && adminRestreint !== null) {
      queryParameters = queryParameters.set('admin_restreint', <any>adminRestreint);
    }
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }
    if (nomProducteur !== undefined && nomProducteur !== null) {
      queryParameters = queryParameters.set('nom_producteur', <any>nomProducteur);
    }
    if (prenomProducteur !== undefined && prenomProducteur !== null) {
      queryParameters = queryParameters.set('prenom_producteur', <any>prenomProducteur);
    }
    if (qualiteProducteur !== undefined && qualiteProducteur !== null) {
      queryParameters = queryParameters.set('qualite_producteur', <any>qualiteProducteur);
    }
    if (oriasProducteur !== undefined && oriasProducteur !== null) {
      queryParameters = queryParameters.set('orias_producteur', <any>oriasProducteur);
    }
    if (adresseAuto !== undefined && adresseAuto !== null) {
      queryParameters = queryParameters.set('adresse_auto', <any>adresseAuto);
    }
    if (heureDeb !== undefined && heureDeb !== null) {
      queryParameters = queryParameters.set('heure_deb', <any>heureDeb);
    }
    if (heureFin !== undefined && heureFin !== null) {
      queryParameters = queryParameters.set('heure_fin', <any>heureFin);
    }
    if (samediOff !== undefined && samediOff !== null) {
      queryParameters = queryParameters.set('samedi_off', <any>samediOff);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/create_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet de supprimer l&#x27;id_session d&#x27;un utilisateur suivant son adresse mail pour le deconnecter
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUser(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_users`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * Permet de supprimer l&#x27;id_session d&#x27;un utilisateur suivant son adresse mail pour le deconnecter
   *
   * @param mail Adresse mail de l&#x27;utilisateur
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteSessionUserGet(mail: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteSessionUserGet(mail: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteSessionUserGet(mail: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteSessionUserGet(mail: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (mail === null || mail === undefined) {
      throw new Error('Required parameter mail was null or undefined when calling deleteSessionUserGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (mail !== undefined && mail !== null) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/delete_session_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher la numer d&#x27;heure depuis le debut de l&#x27;anneé , le numero de semaine , le numero d&#x27;heure, et le numero de minute
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDateGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getDateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getDateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getDateGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_date`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;afficher les evenement et les opportunités d&#x27;un collaborateur pendant un nombre des jours
   *
   * @param collaborateur Identifiant de collaborateur codé en md5
   * @param nbJour Nombre des jours
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEventCollabGet(collaborateur: ModelObject, nbJour: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getEventCollabGet(collaborateur: ModelObject, nbJour: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getEventCollabGet(collaborateur: ModelObject, nbJour: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getEventCollabGet(collaborateur: ModelObject, nbJour: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (collaborateur === null || collaborateur === undefined) {
      throw new Error('Required parameter collaborateur was null or undefined when calling getEventCollabGet.');
    }

    if (nbJour === null || nbJour === undefined) {
      throw new Error('Required parameter nbJour was null or undefined when calling getEventCollabGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (collaborateur !== undefined && collaborateur !== null) {
      queryParameters = queryParameters.set('collaborateur', <any>collaborateur);
    }
    if (nbJour !== undefined && nbJour !== null) {
      queryParameters = queryParameters.set('nb_jour', <any>nbJour);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_event_collab`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * sert a afficher samedi_off heure de debut et fin d’un utilisateur suivant son id codé en md5
   *
   * @param idUser identifiant utilisateur codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getParamUsersGet(idUser: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getParamUsersGet(idUser: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getParamUsersGet(idUser: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getParamUsersGet(idUser: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idUser === null || idUser === undefined) {
      throw new Error('Required parameter idUser was null or undefined when calling getParamUsersGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idUser !== undefined && idUser !== null) {
      queryParameters = queryParameters.set('id_user', <any>idUser);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_param_users`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a affiché les  informations sur l’utilisateur suivant son id codé en md5
   *
   * @param id Identifiant de l&#x27;utilisateur codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserWithIdGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_user_with_id`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher la liste des rendez vous et des évenements  d’un collaborateur sur la mois courante , de premier au fin de mois si start et fin sont vide (Avant la prise de rdv)
   *
   * @param collaborateur Identifiant de collaborateur codé en md5
   * @param start Date debut de recherche
   * @param end Date fin de recherche
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listRdvGet(collaborateur: ModelObject, start?: ModelObject, end?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public listRdvGet(collaborateur: ModelObject, start?: ModelObject, end?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public listRdvGet(collaborateur: ModelObject, start?: ModelObject, end?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public listRdvGet(collaborateur: ModelObject, start?: ModelObject, end?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (collaborateur === null || collaborateur === undefined) {
      throw new Error('Required parameter collaborateur was null or undefined when calling listRdvGet.');
    }



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (collaborateur !== undefined && collaborateur !== null) {
      queryParameters = queryParameters.set('collaborateur', <any>collaborateur);
    }
    if (start !== undefined && start !== null) {
      queryParameters = queryParameters.set('start', <any>start);
    }
    if (end !== undefined && end !== null) {
      queryParameters = queryParameters.set('end', <any>end);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/list_rdv`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a affiché les  informations sur l’utilisateur suivant son id codé en md5
   *
   * @param id_user  Identifiant de l&#x27;utilisateur codé en md5
   * @param nom  nom de l&#x27;utilisateur
   * @param prenom  prenom de l&#x27;utilisateur
   * @param civilite  civilite de l&#x27;utilisateur
   * @param heure_fin  heure_fin de l&#x27;utilisateur
   * @param heure_deb  heure_deb de l&#x27;utilisateur
   * @param samedi_off  samedi_off de l&#x27;utilisateur
   * @param password  password de l&#x27;utilisateur
   * @param api_key  api_key de l&#x27;utilisateur
   * @param webmail_port_out  webmail_port_out de l&#x27;utilisateur
   * @param url_photo  url_photo de l&#x27;utilisateur
   * @param mobile  mobile de l&#x27;utilisateur
   * @param num_tel  num_tel de l&#x27;utilisateur
   * @param tel2  tel2 de l&#x27;utilisateur
   * @param skype  skype de l&#x27;utilisateur
   * @param tel_urgence  tel_urgence de l&#x27;utilisateur
   * @param ville  ville de l&#x27;utilisateur
   * @param rue  rue de l&#x27;utilisateur
   * @param num  num de l&#x27;utilisateur
   * @param cp  cp de l&#x27;utilisateur
   * @param location  location de l&#x27;utilisateur
   * @param pays  pays de l&#x27;utilisateur
   * @param identite_reelle  identite_reelle de l&#x27;utilisateur
   * @param adresse_auto  adresse_auto de l&#x27;utilisateur
   * @param matricule_rh  matricule_rh de l&#x27;utilisateur
   * @param webmail_server_in  webmail_server_in de l&#x27;utilisateur
   * @param webmail_server_out  webmail_server_out de l&#x27;utilisateur
   * @param webmail_port_in  webmail_port_in de l&#x27;utilisateur
   * @param webmail_ssl  webmail_ssl de l&#x27;utilisateur
   * @param webmail_pwd  webmail_pwd de l&#x27;utilisateur
   * @param lng  lng de l&#x27;utilisateur
   * @param lat  lng de l&#x27;utilisateur
   * @param geom  geom de l&#x27;utilisateur
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public UpdateInformationsUser(id_user: string, @Optional() nom: string, @Optional() prenom: string, @Optional() civilite: string, @Optional() heure_fin: string, @Optional() heure_deb: string, @Optional() samedi_off: string
    , @Optional() url_photo: string, @Optional() num_interne: string, @Optional() mobile: string, @Optional() num_tel: string, @Optional() tel2: string, @Optional() skype: string, @Optional() tel_urgence: string, @Optional() ville: string, password: string
    , @Optional() rue: string, @Optional() num: string, cp: string, @Optional() location: string, @Optional() pays: string, @Optional() identite_reelle: string, @Optional() adresse_auto: string, api_key: string
    , @Optional() mail: string, @Optional() matricule_rh: string, @Optional() webmail_server_in: string, @Optional() webmail_server_out: string, @Optional() webmail_port_in: string, @Optional() webmail_port_out: string
    , @Optional() webmail_ssl: string, @Optional() webmail_pwd: string, @Optional() lat: string, @Optional() lng: string, @Optional() geom: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id_user === null || nom === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getUserWithIdGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_user !== undefined && id_user !== null) {
      queryParameters = queryParameters.set('id_user', <any>id_user);
    }
    if (nom !== undefined && nom !== null) {
      queryParameters = queryParameters.set('nom', <any>nom);
    }
    if (prenom !== undefined && prenom !== null) {
      queryParameters = queryParameters.set('prenom', <any>prenom);
    }
    if (civilite !== undefined && civilite !== null) {
      queryParameters = queryParameters.set('civilite', <any>civilite);
    }
    if (heure_fin !== undefined && heure_fin !== null) {
      queryParameters = queryParameters.set('heure_fin', <any>heure_fin);
    }
    if (heure_deb !== undefined && heure_deb !== null) {
      queryParameters = queryParameters.set('heure_deb', <any>heure_deb);
    }
    if (samedi_off !== undefined && samedi_off !== null) {
      queryParameters = queryParameters.set('samedi_off', <any>samedi_off);
    }
    if (url_photo !== undefined && url_photo !== null) {
      queryParameters = queryParameters.set('url_photo', <any>url_photo);
    }
    if (num_interne !== undefined && num_interne !== null) {
      queryParameters = queryParameters.set('num_inter', <any>num_interne);
    }
    if (mobile !== undefined && mobile !== null) {
      queryParameters = queryParameters.set('mobile', <any>mobile);
    }
    if (num_tel !== undefined && num_tel !== null) {
      queryParameters = queryParameters.set('num_tel', <any>num_tel);
    }
    if (tel2 !== undefined && tel2 !== null) {
      queryParameters = queryParameters.set('tel2', <any>tel2);
    }
    if (skype !== undefined && skype !== null) {
      queryParameters = queryParameters.set('skype', <any>skype);
    }
    if (tel_urgence !== undefined && tel_urgence !== null) {
      queryParameters = queryParameters.set('tel_urgence', <any>tel_urgence);
    }
    if (ville !== undefined && ville !== null) {
      queryParameters = queryParameters.set('ville', <any>ville);
    }
    if (password !== undefined && password !== null) {
      queryParameters = queryParameters.set('password', <any>password);
    }
    if (rue !== undefined && rue !== null) {
      queryParameters = queryParameters.set('rue', <any>rue);
    }
    if (num !== undefined && num !== null) {
      queryParameters = queryParameters.set('num', <any>num);
    }
    if (cp !== undefined && cp !== null) {
      queryParameters = queryParameters.set('cp', <any>cp);
    }
    if (location !== undefined && location !== null) {
      queryParameters = queryParameters.set('location', <any>location);
    }
    if (pays !== undefined && pays !== null) {
      queryParameters = queryParameters.set('pays', <any>pays);
    }
    if (identite_reelle !== undefined && identite_reelle !== null) {
      queryParameters = queryParameters.set('identite_reelle', <any>identite_reelle);
    }
    if (adresse_auto !== undefined && adresse_auto !== null) {
      queryParameters = queryParameters.set('adresse_auto', <any>adresse_auto);
    }
    if (api_key !== undefined && api_key !== null) {
      queryParameters = queryParameters.set('api_key', <any>api_key);
    }
    if (mail !== undefined && mail !== null) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (matricule_rh !== undefined && matricule_rh !== null) {
      queryParameters = queryParameters.set('matricule_rh', <any>matricule_rh);
    }
    if (webmail_server_in !== undefined && webmail_server_in !== null) {
      queryParameters = queryParameters.set('webmail_server_in', <any>webmail_server_in);
    }
    if (webmail_server_out !== undefined && webmail_server_out !== null) {
      queryParameters = queryParameters.set('webmail_server_out', <any>webmail_server_out);
    }
    if (webmail_port_in !== undefined && webmail_port_in !== null) {
      queryParameters = queryParameters.set('webmail_port_in', <any>webmail_port_in);
    }
    if (webmail_port_out !== undefined && webmail_port_out !== null) {
      queryParameters = queryParameters.set('webmail_port_out', <any>webmail_port_out);
    }
    if (webmail_ssl !== undefined && webmail_ssl !== null) {
      queryParameters = queryParameters.set('webmail_ssl', <any>webmail_ssl);
    }
    if (webmail_pwd !== undefined && webmail_pwd !== null) {
      queryParameters = queryParameters.set('webmail_pwd', <any>webmail_pwd);
    }
    if (lng !== undefined && lng !== null) {
      queryParameters = queryParameters.set('lng', <any>lng);
    }
    if (lat !== undefined && lat !== null) {
      queryParameters = queryParameters.set('lat', <any>lat);
    }
    if (geom !== undefined && geom !== null) {
      queryParameters = queryParameters.set('geom', <any>geom);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/create_user_limite?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public UpdateUser(idUser: string, User: UserAdmin2, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('put', `${this.basePath}/update_user/${idUser}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: User
      }
    );
  }

  /**
   * Permet de changer le mot de passe utilisateurs et l&#x27;envoyer par email
   *
   * @param mail Adresse mail utilisateur
   * @param password Nouvelle mot de passe Utilisateurs
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public motDePassOublieGet(mail: ModelObject, password: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public motDePassOublieGet(mail: ModelObject, password: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public motDePassOublieGet(mail: ModelObject, password: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public motDePassOublieGet(mail: ModelObject, password: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (mail === null || mail === undefined) {
      throw new Error('Required parameter mail was null or undefined when calling motDePassOublieGet.');
    }

    if (password === null || password === undefined) {
      throw new Error('Required parameter password was null or undefined when calling motDePassOublieGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (mail !== undefined && mail !== null) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (password !== undefined && password !== null) {
      queryParameters = queryParameters.set('password', <any>password);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/MotDePassOublie`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher le form de contrat suivant id_contrat codé en md5
   *
   * @param dataQ Une partie de nom ou de prenom de l&#x27;utilsateur
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recherchePrenomGet(dataQ: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public recherchePrenomGet(dataQ: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public recherchePrenomGet(dataQ: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public recherchePrenomGet(dataQ: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling recherchePrenomGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_prenom`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Affichage de list des utilisateurs (active ou code) ou add_prospect ou  nom et prenom autocomplissment qui sont similaire a data[q]
   *
   * @param cle &lt;p&gt;Affichage suivant la cle &lt;br&gt; cle : add_prospect &#x3D;&#x3D;&gt; Affichge de list des add_prospect suivant une partie de leurs nom ou prenom  &lt;br&gt;cle : users &#x3D;&#x3D;&gt; Afficahge de list des utilisateurs suivant une partie de leurs nom ou prenom &lt;br&gt;cle : users_active &#x3D;&#x3D;&gt; Afficahge de list des utilisateurs suivant une partie de leurs nom ou prenom et active  &lt;br&gt;cle : users_code &#x3D;&#x3D;&gt; Afficahge de list des utilisateurs suivant une partie de leurs nom ou prenom et qui posséde un code &lt;br&gt;cle : prenom &#x3D;&#x3D;&gt; Affichge de list des nom et prenom similaire a l&#x27;entré&lt;/p&gt;
   * @param dataQ Une partie de  nom ou prenom de add_prospect
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recherchePrenomOrProspectOrUserGet(cle: string, dataQ: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public recherchePrenomOrProspectOrUserGet(cle: string, dataQ: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public recherchePrenomOrProspectOrUserGet(cle: string, dataQ: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public recherchePrenomOrProspectOrUserGet(cle: string, dataQ: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (cle === null || cle === undefined) {
      throw new Error('Required parameter cle was null or undefined when calling recherchePrenomOrProspectOrUserGet.');
    }

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling recherchePrenomOrProspectOrUserGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (cle !== undefined && cle !== null) {
      queryParameters = queryParameters.set('cle', <any>cle);
    }
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_prenom_or_prospect_or_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *  Afficher l’identifiant concatené au code et le nom concatené au prenom concatené au code si l’utilisateur est active  suivant une partie de nom ou prenom de l&#x27;utilisateur
   *
   * @param dataQ Identifiant de add_prospect codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheUsersActiveGet(dataQ: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public rechercheUsersActiveGet(dataQ: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public rechercheUsersActiveGet(dataQ: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public rechercheUsersActiveGet(dataQ: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling rechercheUsersActiveGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_users_active`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher l’identifiant  concatené au code et le nom concatené au prenom suivant une partie de nom ou prenom de l&#x27;utilsateur
   *
   * @param dataQ Une partie de nom ou prenom de l&#x27;utilisateur
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheUsersCodeGet(dataQ: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public rechercheUsersCodeGet(dataQ: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public rechercheUsersCodeGet(dataQ: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public rechercheUsersCodeGet(dataQ: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (dataQ === null || dataQ === undefined) {
      throw new Error('Required parameter dataQ was null or undefined when calling rechercheUsersCodeGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (dataQ !== undefined && dataQ !== null) {
      queryParameters = queryParameters.set('data[q]', <any>dataQ);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_users_code`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;afficher la liste des utilisateurs dont le manager est l&#x27;utilisateurs connecté
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectAllUserByManagerGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectAllUserByManagerGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectAllUserByManagerGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectAllUserByManagerGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/select_all_user_by_manager`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet de d&#x27;afficher les information sur un collaborateur suivant son identifiant codé en md5
   *
   * @param idCollab identifiant de collaborateur codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectCollabGet(idCollab: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectCollabGet(idCollab: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectCollabGet(idCollab: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectCollabGet(idCollab: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idCollab === null || idCollab === undefined) {
      throw new Error('Required parameter idCollab was null or undefined when calling selectCollabGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idCollab !== undefined && idCollab !== null) {
      queryParameters = queryParameters.set('id_collab', <any>idCollab);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/select_collab`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sert a afficher  Les collaborateurs d’une campagnie suivant son l’identifiant (campagnie) codé en md5
   *
   * @param idcompagne Identifiant de campage codé en md5 (a demander par mail a it@sodedif.com)
   * @param idUser Identifiant de l&#x27;utilisateur codé en md5 (a demander par mail a it@sodedif.com)
   * @param role Role utilisateur (a demander par mail a it@sodedif.com)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectUsersCollabsGet(idcompagne: ModelObject, idUser: ModelObject, role: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectUsersCollabsGet(idcompagne: ModelObject, idUser: ModelObject, role: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectUsersCollabsGet(idcompagne: ModelObject, idUser: ModelObject, role: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectUsersCollabsGet(idcompagne: ModelObject, idUser: ModelObject, role: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idcompagne === null || idcompagne === undefined) {
      throw new Error('Required parameter idcompagne was null or undefined when calling selectUsersCollabsGet.');
    }

    if (idUser === null || idUser === undefined) {
      throw new Error('Required parameter idUser was null or undefined when calling selectUsersCollabsGet.');
    }

    if (role === null || role === undefined) {
      throw new Error('Required parameter role was null or undefined when calling selectUsersCollabsGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idcompagne !== undefined && idcompagne !== null) {
      queryParameters = queryParameters.set('idcompagne', <any>idcompagne);
    }
    if (idUser !== undefined && idUser !== null) {
      queryParameters = queryParameters.set('id_user', <any>idUser);
    }
    if (role !== undefined && role !== null) {
      queryParameters = queryParameters.set('role', <any>role);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/select_users_collabs`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * permet d&#x27;affecter une list des utilisateurs a une opportunite suivant son identifiant codé en md5
   *
   * @param idOpp Identifiant de l&#x27;opportunite codé en md5 separer par une virgule
   * @param tabIdUser List des utilisateurs
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAffectedUserGet(idOpp: ModelObject, tabIdUser: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateAffectedUserGet(idOpp: ModelObject, tabIdUser: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateAffectedUserGet(idOpp: ModelObject, tabIdUser: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateAffectedUserGet(idOpp: ModelObject, tabIdUser: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling updateAffectedUserGet.');
    }

    if (tabIdUser === null || tabIdUser === undefined) {
      throw new Error('Required parameter tabIdUser was null or undefined when calling updateAffectedUserGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', <any>idOpp);
    }
    if (tabIdUser !== undefined && tabIdUser !== null) {
      queryParameters = queryParameters.set('tab_id_user', <any>tabIdUser);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/update_affected_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Modifier un utilisateur et ses paramétre (role , wermail , groupe , ssl, adresse)
   *
   * @param id identifiant de l&#x27;utilisateur a modifier codé en md5
   * @param civilite civilite
   * @param matriculeRh Matricule Rh de l&#x27;utilisateur
   * @param identiteReelle identite reel de l&#x27;utilisateur
   * @param nom nom de l&#x27;utilisateur
   * @param prenom prenom de l&#x27;utilisateur
   * @param dateDeNaissance Date de naissance de l&#x27;utilisateur sous format DD/MM/YYYY
   * @param password Mot de passe utilisateur
   * @param apiKey Api key de l&#x27;utilisateur
   * @param role Identifiant de role de de l&#x27;utilisateur (Super admin &#x3D;&#x3D;5) a demander par mail a it@sodedif.fr
   * @param organisme Identifiant de l&#x27;organisme de l&#x27;utilisateur ,(a demander par mail a it@sodedif.fr)
   * @param webmailMail login (adresse mail utilisateur)
   * @param webmailPwd password (adresse mail utilisateur)
   * @param webmailServerIn Serveur des courrier entrant
   * @param webmailPortOut port sortant
   * @param webmailPortIn port entrant
   * @param webmailSsl port entrant
   * @param skype Skype de l&#x27;utilisateur
   * @param numTel numero de telephone de l&#x27;utilisateur
   * @param numInter numero de interne telephone de l&#x27;utilisateur
   * @param urlPhoto Url de photo de l&#x27;utilisateur
   * @param proprietaire proprietaire
   * @param pointage pointage
   * @param superAdmin super_admin
   * @param adminRestreint admin_restreint
   * @param code Code Utuilisateurs
   * @param nomProducteur nom producteur
   * @param prenomProducteur prenom producteur
   * @param qualiteProducteur qualite producteur
   * @param oriasProducteur N°ORIAS producteur
   * @param adresseAuto Adresses autorisées
   * @param heureDeb Heure début format HH:MM
   * @param heureFin Heure fin format HH:MM
   * @param samediOff samedi_off
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateUserGet(id: string, civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateUserGet(id: string, civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateUserGet(id: string, civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateUserGet(id: string, civilite: string, matriculeRh: ModelObject, identiteReelle: ModelObject, nom: ModelObject, prenom: ModelObject, dateDeNaissance: ModelObject, password: ModelObject, apiKey: ModelObject, role: ModelObject, organisme: ModelObject, webmailMail?: ModelObject, webmailPwd?: ModelObject, webmailServerIn?: ModelObject, webmailPortOut?: ModelObject, webmailPortIn?: ModelObject, webmailSsl?: string, skype?: ModelObject, numTel?: ModelObject, numInter?: ModelObject, urlPhoto?: ModelObject, proprietaire?: string, pointage?: string, superAdmin?: string, adminRestreint?: string, code?: ModelObject, nomProducteur?: ModelObject, prenomProducteur?: ModelObject, qualiteProducteur?: ModelObject, oriasProducteur?: ModelObject, adresseAuto?: ModelObject, heureDeb?: ModelObject, heureFin?: ModelObject, samediOff?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateUserGet.');
    }

    if (civilite === null || civilite === undefined) {
      throw new Error('Required parameter civilite was null or undefined when calling updateUserGet.');
    }

    if (matriculeRh === null || matriculeRh === undefined) {
      throw new Error('Required parameter matriculeRh was null or undefined when calling updateUserGet.');
    }

    if (identiteReelle === null || identiteReelle === undefined) {
      throw new Error('Required parameter identiteReelle was null or undefined when calling updateUserGet.');
    }

    if (nom === null || nom === undefined) {
      throw new Error('Required parameter nom was null or undefined when calling updateUserGet.');
    }

    if (prenom === null || prenom === undefined) {
      throw new Error('Required parameter prenom was null or undefined when calling updateUserGet.');
    }

    if (dateDeNaissance === null || dateDeNaissance === undefined) {
      throw new Error('Required parameter dateDeNaissance was null or undefined when calling updateUserGet.');
    }

    if (password === null || password === undefined) {
      throw new Error('Required parameter password was null or undefined when calling updateUserGet.');
    }

    if (apiKey === null || apiKey === undefined) {
      throw new Error('Required parameter apiKey was null or undefined when calling updateUserGet.');
    }

    if (role === null || role === undefined) {
      throw new Error('Required parameter role was null or undefined when calling updateUserGet.');
    }

    if (organisme === null || organisme === undefined) {
      throw new Error('Required parameter organisme was null or undefined when calling updateUserGet.');
    }
























    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (civilite !== undefined && civilite !== null) {
      queryParameters = queryParameters.set('civilite', <any>civilite);
    }
    if (matriculeRh !== undefined && matriculeRh !== null) {
      queryParameters = queryParameters.set('matricule_rh', <any>matriculeRh);
    }
    if (identiteReelle !== undefined && identiteReelle !== null) {
      queryParameters = queryParameters.set('identite_reelle', <any>identiteReelle);
    }
    if (nom !== undefined && nom !== null) {
      queryParameters = queryParameters.set('nom', <any>nom);
    }
    if (prenom !== undefined && prenom !== null) {
      queryParameters = queryParameters.set('prenom', <any>prenom);
    }
    if (dateDeNaissance !== undefined && dateDeNaissance !== null) {
      queryParameters = queryParameters.set('Date de naissance', <any>dateDeNaissance);
    }
    if (password !== undefined && password !== null) {
      queryParameters = queryParameters.set('password', <any>password);
    }
    if (apiKey !== undefined && apiKey !== null) {
      queryParameters = queryParameters.set('api_key', <any>apiKey);
    }
    if (role !== undefined && role !== null) {
      queryParameters = queryParameters.set('role', <any>role);
    }
    if (organisme !== undefined && organisme !== null) {
      queryParameters = queryParameters.set('organisme', <any>organisme);
    }
    if (webmailMail !== undefined && webmailMail !== null) {
      queryParameters = queryParameters.set('webmail_mail', <any>webmailMail);
    }
    if (webmailPwd !== undefined && webmailPwd !== null) {
      queryParameters = queryParameters.set('webmail_pwd', <any>webmailPwd);
    }
    if (webmailServerIn !== undefined && webmailServerIn !== null) {
      queryParameters = queryParameters.set('webmail_server_in', <any>webmailServerIn);
    }
    if (webmailPortOut !== undefined && webmailPortOut !== null) {
      queryParameters = queryParameters.set('webmail_port_out', <any>webmailPortOut);
    }
    if (webmailPortIn !== undefined && webmailPortIn !== null) {
      queryParameters = queryParameters.set('webmail_port_in', <any>webmailPortIn);
    }
    if (webmailSsl !== undefined && webmailSsl !== null) {
      queryParameters = queryParameters.set('webmail_ssl', <any>webmailSsl);
    }
    if (skype !== undefined && skype !== null) {
      queryParameters = queryParameters.set('skype', <any>skype);
    }
    if (numTel !== undefined && numTel !== null) {
      queryParameters = queryParameters.set('num_tel', <any>numTel);
    }
    if (numInter !== undefined && numInter !== null) {
      queryParameters = queryParameters.set('num_inter', <any>numInter);
    }
    if (urlPhoto !== undefined && urlPhoto !== null) {
      queryParameters = queryParameters.set('url_photo', <any>urlPhoto);
    }
    if (proprietaire !== undefined && proprietaire !== null) {
      queryParameters = queryParameters.set('proprietaire', <any>proprietaire);
    }
    if (pointage !== undefined && pointage !== null) {
      queryParameters = queryParameters.set('pointage', <any>pointage);
    }
    if (superAdmin !== undefined && superAdmin !== null) {
      queryParameters = queryParameters.set('super_admin', <any>superAdmin);
    }
    if (adminRestreint !== undefined && adminRestreint !== null) {
      queryParameters = queryParameters.set('admin_restreint', <any>adminRestreint);
    }
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }
    if (nomProducteur !== undefined && nomProducteur !== null) {
      queryParameters = queryParameters.set('nom_producteur', <any>nomProducteur);
    }
    if (prenomProducteur !== undefined && prenomProducteur !== null) {
      queryParameters = queryParameters.set('prenom_producteur', <any>prenomProducteur);
    }
    if (qualiteProducteur !== undefined && qualiteProducteur !== null) {
      queryParameters = queryParameters.set('qualite_producteur', <any>qualiteProducteur);
    }
    if (oriasProducteur !== undefined && oriasProducteur !== null) {
      queryParameters = queryParameters.set('orias_producteur', <any>oriasProducteur);
    }
    if (adresseAuto !== undefined && adresseAuto !== null) {
      queryParameters = queryParameters.set('adresse_auto', <any>adresseAuto);
    }
    if (heureDeb !== undefined && heureDeb !== null) {
      queryParameters = queryParameters.set('heure_deb', <any>heureDeb);
    }
    if (heureFin !== undefined && heureFin !== null) {
      queryParameters = queryParameters.set('heure_fin', <any>heureFin);
    }
    if (samediOff !== undefined && samediOff !== null) {
      queryParameters = queryParameters.set('samedi_off', <any>samediOff);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/update_user`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public uploadPicture(idUser: string, Data: FormData, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<any>('post', `${this.basePath}/upload_image_user/${idUser}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }
}
