export class EstablishmentInformation{
  unite_legale: UniteLegale;
}

export interface UniteLegale {
  id:                               number;
  siren:                            string;
  statut_diffusion:                 string;
  unite_purgee:                     null;
  date_creation:                    Date;
  sigle:                            null;
  sexe:                             null;
  prenom_1:                         null;
  prenom_2:                         null;
  prenom_3:                         null;
  prenom_4:                         null;
  prenom_usuel:                     null;
  pseudonyme:                       null;
  identifiant_association:          null;
  tranche_effectifs:                string;
  annee_effectifs:                  string;
  date_dernier_traitement:          Date;
  nombre_periodes:                  string;
  categorie_entreprise:             string;
  annee_categorie_entreprise:       string;
  date_fin:                         null;
  date_debut:                       Date;
  etat_administratif:               string;
  nom:                              null;
  nom_usage:                        null;
  denomination:                     string;
  denomination_usuelle_1:           null;
  denomination_usuelle_2:           null;
  denomination_usuelle_3:           null;
  categorie_juridique:              string;
  activite_principale:              string;
  nomenclature_activite_principale: string;
  nic_siege:                        string;
  economie_sociale_solidaire:       string;
  caractere_employeur:              string;
  created_at:                       Date;
  updated_at:                       Date;
  etablissement_siege:              Etablissement;
  numero_tva_intra:                 string;
  etablissements:                   Etablissement[];
}

export interface Etablissement {
  id:                                   number;
  siren:                                string;
  nic:                                  string;
  siret:                                string;
  statut_diffusion:                     string;
  date_creation:                        Date;
  tranche_effectifs:                    string;
  annee_effectifs:                      string;
  activite_principale_registre_metiers: null;
  date_dernier_traitement:              Date;
  etablissement_siege:                  string;
  nombre_periodes:                      string;
  complement_adresse:                   string;
  numero_voie:                          null;
  indice_repetition:                    null;
  type_voie:                            null;
  libelle_voie:                         string;
  code_postal:                          string;
  libelle_commune:                      string;
  libelle_commune_etranger:             null;
  distribution_speciale:                null;
  code_commune:                         string;
  code_cedex:                           null;
  libelle_cedex:                        null;
  code_pays_etranger:                   null;
  libelle_pays_etranger:                null;
  complement_adresse_2:                 null;
  numero_voie_2:                        null;
  indice_repetition_2:                  null;
  type_voie_2:                          null;
  libelle_voie_2:                       null;
  code_postal_2:                        null;
  libelle_commune_2:                    null;
  libelle_commune_etranger_2:           null;
  distribution_speciale_2:              null;
  code_commune_2:                       null;
  code_cedex_2:                         null;
  libelle_cedex_2:                      null;
  code_pays_etranger_2:                 null;
  libelle_pays_etranger_2:              null;
  date_debut:                           Date;
  etat_administratif:                   string;
  enseigne_1:                           null;
  enseigne_2:                           null;
  enseigne_3:                           null;
  denomination_usuelle:                 null;
  activite_principale:                  string;
  nomenclature_activite_principale:     string;
  caractere_employeur:                  string;
  longitude:                            string;
  latitude:                             string;
  geo_score:                            string;
  geo_type:                             string;
  geo_adresse:                          string;
  geo_id:                               string;
  geo_ligne:                            string;
  geo_l4:                               string;
  geo_l5:                               null;
  unite_legale_id:                      number;
  created_at:                           Date;
  updated_at:                           Date;
}
