import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

import { ApiOpportunitService } from '../../../../../services/ApiOpportunite/api-opportunite.service';
import { CycleVie } from '../../../../../entity/CycleVie';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LigneProduit } from '../../../../../entity/Opportunity/LigneProduit';
import { ApiContratsService } from '../../../../../services/ApiContrats/api-contrats.service';
import { ApiCampagniesService } from '../../../../../services/ApiCompagnies/api-compagnies.service';
import { CampagneInfo } from '../../../../../entity/Opportunity/CampagneInfo';
import { Campagne, ObjectAssurer, ObjectsAssurer } from '../../../../../entity/Opportunity/Campagne';
import { element } from 'protractor';
import { AddOpSharedEntityService } from '../../../../../shared/SharedInjectors/add-op-shared-entity.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith, pluck, tap } from 'rxjs/operators';

@Component({
  selector: 'app-campagne',
  templateUrl: './campagne.component.html',
  styleUrls: ['./campagne.component.css'],
})
export class CampagneComponent implements OnInit, OnChanges {
  objectsAssurer: ObjectsAssurer = {
    name: 'Objet(s) / Bien(s) à assurer',
    completed: false,
    color: 'primary',
    objects_Assurer: [],
  };

  allComplete: boolean = false;
  fa = fa;
  selected_cyclevie: boolean = false;
  loader: boolean = false;
  listCycleVieStr: any;
  listCampagnes: any[] = [];
  RdvTerrainHorsSys: boolean = false;
  TroisC: boolean = false;
  form: FormGroup;
  campagneId: string = '';
  productId: string = '';
  cycleVieForm: FormControl = new FormControl(null, [Validators.required]);
  campagneForm: FormControl = new FormControl(null, [Validators.required]);
  productForm: FormControl = new FormControl(null, [Validators.required]);
  campselected: any;
  isbien:boolean=false;
  isperson:boolean=false;
  @Output() onProductSelected: EventEmitter<any> = new EventEmitter();
  @Output() onCampagneSelected: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateInfo: EventEmitter<Campagne> = new EventEmitter();
  @Output() ongetObjetAssurer: EventEmitter<ObjectAssurer[]> = new EventEmitter();
  @Output() bien: EventEmitter<boolean> = new EventEmitter();
  @Output() person: EventEmitter<boolean> = new EventEmitter();
  @Input() cycleVie: CycleVie | null = null;
  @Input() listCycleVieComp: any[] | null = null;
  @Input() listCycleVieHorsComp: any[] | null = null;
  @Input() listLignesProduits: LigneProduit[] | null = null;
  @Input() campagne: Campagne | null = null;
  campagneClone: Campagne | null = null;
  getValue: string;
  noList: boolean;
  filteredCampagne: Observable<any[]>;
  listCycleVieCompCopie: any[];
  constructor(private apiOpportunitService: ApiOpportunitService) {}

  someComplete($event, objects_Assurer): boolean {
    if (!this.objectsAssurer.objects_Assurer) {
      return false;
    }

    if (this.campagneClone.objectAssurer.length === 0) {
      this.campagneClone.objectAssurer = this.objectsAssurer.objects_Assurer.filter((t) => t.completed === true);
      this.pushInfo();
    } else if ($event === true) {
      const mergedList = [...this.campagneClone.objectAssurer];
      this.objectsAssurer.objects_Assurer.forEach((obj2) => {
        const obj1 = mergedList.find(
          (item) => item.nom === obj2.nom && obj2.completed === true && item.completed === true
        );

        if (!obj1 && obj2.completed === true) {
          const maxindex = Math.max.apply(
            Math,
            mergedList.map(function (o) {
              return o.index;
            })
          );
          let newObjectAssurer: any;
          if (isNaN(maxindex)) {
            newObjectAssurer = new ObjectAssurer(0, obj2.form, obj2.icon, obj2.nom, true, false);
          } else {
            newObjectAssurer = new ObjectAssurer(maxindex + 1, obj2.form, obj2.icon, obj2.nom, true, false);
          }
          mergedList.push(newObjectAssurer);
        }
      });
      this.campagneClone.objectAssurer = mergedList;
      this.pushInfo();
    } else if ($event === false) {
      const mergedList = [...this.campagneClone.objectAssurer];

      let foundIndex = mergedList.findIndex((item) => item.nom === objects_Assurer.nom);

      while (foundIndex !== -1) {
        mergedList.splice(foundIndex, 1);
        foundIndex = mergedList.findIndex((item) => item.nom === objects_Assurer.nom);
      }

      this.campagneClone.objectAssurer = mergedList;
      this.pushInfo();
    }

    return this.objectsAssurer.objects_Assurer.filter((t) => t.completed).length > 0 && !this.allComplete;
  }

  ngOnInit(): void {
    this.listCycleVieCompCopie = this.listCycleVieComp;
    this.filteredCampagne = this.campagneForm.valueChanges.pipe(
      startWith(null),
      map((campagne: string | null) => {
        if (campagne === '') {
          const objCopy = { ...this.campagneClone };
          objCopy.campagne_id = '';
          objCopy.objectAssurer = [];
          this.campagneClone = Object.assign({}, objCopy);
          this.onCampagneSelected.emit('');
          this.pushInfo();
          this.loader = false;
          this.objectsAssurer.objects_Assurer = [];
        }
        return campagne ? this._filterCapmagne() : this.listCycleVieCompCopie.slice();
      })
    );
    const listselected = this.campagneClone.objectAssurer;
    if (this.campagneClone.campagne_id !== '' && this.campagneClone.campagne_id != null) {
      this.objectsAssurer.objects_Assurer = this.objectsAssurer.objects_Assurer.concat(listselected);
      this.apiOpportunitService.getObjecttassurer(this.campagneClone.campagne_id, 'id_campagne').subscribe((value) => {
        const listevierge = value.object_assure;
        if (this.campagneClone.objectAssurer.length === 0) {
          if (value) {
            this.objectsAssurer.objects_Assurer.push(...listevierge);
          }
        } else {
          const objectsAssurer = this.objectsAssurer.objects_Assurer;
          listevierge.forEach((obj1) => {
            const index = objectsAssurer.findIndex((obj2) => obj2.nom === obj1.nom);
            if (index !== -1) {
              obj1.completed = true;
            }
          });
          this.objectsAssurer.objects_Assurer = listevierge;
        }
        const seen = new Set();
        const filteredObjects = [];
        for (const item of this.objectsAssurer.objects_Assurer) {
          if (!seen.has(item.nom)) {
            seen.add(item.nom);
            filteredObjects.push(item);
          }
        }
        this.objectsAssurer.objects_Assurer = filteredObjects;
      });
    }

    //  this.objectsAssurer.objects_Assurer=this.campagneClone.objectAssurer;
    if (!this.listCycleVieHorsComp.length) {
      this.noList = true;
    }

    if (this.campagneClone.campagne_id !== '' && this.campagneClone.campagne_id != null) {
      const selected_campp = this.listCycleVieCompCopie.filter(
        (campagne) => campagne.id === this.campagneClone.campagne_id
      );

      this.campagneForm.setValue(selected_campp[0]);
      if(selected_campp[0].bien===1){
        this.isbien=true;
      }
      if(selected_campp[0].person===1){
        this.isperson=true;
      }
      this.bien.emit(this.isbien);
      this.person.emit(this.isperson);
    }
  }

  onSelectCampagne($event: MatAutocompleteSelectedEvent) {

    if($event.option.value.bien===1){
      this.isbien=true;
    }
    if($event.option.value.person===1){
      this.isperson=true;
    }
    this.bien.emit(this.isbien);
    this.person.emit(this.isperson);
    this.loader = true;
    this.objectsAssurer.objects_Assurer = [];

    const objCopy = { ...this.campagneClone };
    objCopy.campagne_id = $event.option.value.id;
    objCopy.objectAssurer = [];
    this.campagneClone = Object.assign({}, objCopy);
    // this.campagneClone.campagne_id = $event.option.value.id;
    this.onCampagneSelected.emit($event.option.value.id);
    this.pushInfo();
    // this.objectsAssurer.objects_Assurer=[];
    this.apiOpportunitService.getObjecttassurer($event.option.value.id, 'id_campagne').subscribe((value) => {
      const data = value.object_assure;
      this.loader = false;
      if (data?.length > 0) {
        data.forEach((element) =>
          this.objectsAssurer.objects_Assurer.push(
            new ObjectAssurer(null, element.form, element.icon, element.nom, false, false)
          )
        );
      }
    });
    this.ongetObjetAssurer.emit(this.objectsAssurer.objects_Assurer);
  }

  onSelectProduct() {
    const product = this.listLignesProduits
      .filter((prod) => prod.id.toString() === this.campagneClone.product_id)
      .pop();
    if (product !== undefined && product != null) {
      this.onProductSelected.emit(product.form);
    }
    this.pushInfo();
  }

  private pushInfo() {
    this.campagneClone.invalidForms = [];
    if (!this.campagneForm.valid) {
      this.campagneClone.invalidForms.push('campagne');
    }
    this.onUpdateInfo.emit(this.campagneClone);
  }

  public displayCampFn(campagne?: any): string | undefined {
    this.campagneForm = campagne?.id;
    return campagne ? campagne.campagne : null;
  }

  SelectListCycleVie(event) {
    if (this.getValue === '0') {
      this.campagneClone.choice = true;
    }
    if (this.getValue === '') {
      this.campagneClone.choice = false;
    }
    this.pushInfo();
  }

  private _filterCapmagne(): any[] {
    let filterValue = this.campagneForm.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.listCycleVieCompCopie.filter((campagne) =>
      campagne.campagne != null ? campagne.campagne.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.campagneClone = Object.assign({}, this.campagne);
    if (this.campagneClone.choice) {
      this.getValue = '0';
    } else {
      this.getValue = '';
    }
    if (changes?.listCycleVieComp?.currentValue) this.listCycleVieComp = changes.listCycleVieComp.currentValue;
  }
}
