<div id="tags" class="mt-2">
    <form [formGroup]="formTag" class="example-form">
        <div class="field-search-tag border-0 p-3 bg-white">
            <label><b>Mots-clés</b></label>

            <mat-form-field class="full-width">
                <ng-container *ngIf="loaderListTags  == true">
                    <div class="spinner-recherche">
                        <mat-spinner [diameter]="15"></mat-spinner>
                    </div>
                </ng-container>
                <mat-label>
                    <fa-icon [icon]="fa['faSearch']" style="color: rgb(110, 108, 108);padding-right:10px"></fa-icon>
                    Rechercher
                </mat-label>
                <input formControlName="tagText" type="text" matInput [matAutocomplete]="auto">
                <input formControlName="tagTextId" type="text" hidden>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <ng-container *ngIf="!notFoundTags">
                        <mat-option (onSelectionChange)="onSelectTag(item)"
                            *ngFor="let item of tagAutoComplete$ | async; let index = index" [value]="item.libelle">
                            {{item.libelle}}
                        </mat-option>
                    </ng-container>
                    <mat-option *ngIf="(tagAutoComplete$ | async)?.length == 0 && notFoundTags &&
            formTag.value.tagText != ''" disabled>
                        Aucun mot-clé</mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <!--      <ng-container *ngIf="loaderActiveTags && !appear ">
                <div class="d-flex justify-content-center">
                    <mat-spinner [diameter]="25"></mat-spinner>
                </div>
            </ng-container> -->
            <ng-container *ngIf="appear">
                <ng-container *ngIf="activeTags.length > 0">
                    <div class="tags-box">
                        <!-- <div style=" height: 90px; overflow-y: scroll;" > -->
                            <mat-chip-set 
                            class="example-chip"
                            >
                            <mat-chip 
                              class="example-box mr-2" 
                              cdkDrag [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}"
                              *ngFor="let tag of activeTags; let index = index ; let first = first;" 
                              [ngClass]="first ? 'mt-2' : 'mt-1'">
                              {{tag.libelle}}
                              <fa-icon class="fa-lg ml-2 remove-tag" [icon]="fa['faTimes']"
                              [ngClass]="{'cover':!this.isReadOnly}" (click)="removeTag(index)"></fa-icon>
                            </mat-chip>
                          </mat-chip-set>
                     <!--    </div> -->
                     <!--    <label *ngFor="let tag of activeTags; let index = index"
                            class="tag-active d-inline-flex m-1 p-2"
                            [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}">
                            {{tag.libelle}}
                            <fa-icon class="fa-lg ml-2 remove-tag" [icon]="fa['faTimes']"
                                [ngClass]="{'cover':!this.isReadOnly}" (click)="removeTag(index)"></fa-icon>
                        </label> -->
                    </div>
                </ng-container>
                <ng-container *ngIf="activeTags.length < 1">
                    <p>Pas des mots-clés</p>
                </ng-container>
            </ng-container>
            <!--ggggggggggggggggggggggg-->
            <ng-container *ngIf="!appear">
                <ng-container *ngIf="list.length > 0">
                    <div class="tags-box">
                        <label *ngFor="let tag of list; let index = index" class="tag-active d-inline-flex m-1 p-2"
                            [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}">
                            {{tag.libelle}}
                            <fa-icon class="fa-lg ml-2 remove-tag" [icon]="fa['faTimes']"
                                (click)="deleteTags(index)"></fa-icon>

                        </label>
                    </div>
                </ng-container>

            </ng-container>
        </div>
    </form>
</div>