import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-changestatusbutton',
  templateUrl: './change-status-button.component.html',
  styleUrls: ['./change-status-button.component.css']
})
export class ChangeStatusButtonComponent implements OnInit {
  @Input()  label: string;
  @Input() allowed_to_update : boolean;
  @Input() type : string;
  accee : string;
  constructor() { }

  ngOnInit(): void {

  }

}
