/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent, }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { BehaviorSubject, Observable }                                        from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {environment} from '../../../environments/environment';


@Injectable()
export class ApiAuthentificationService {

  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Avoir l&#x27;acces token pour pouvoir exécuter les autres webservices
   *
   * @param email Adresse mail de l&#x27;utilisateur
   * @param password Mot de passe utilisateur
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loginGet(email: string, password: string, apiKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (email === null || email === undefined) {
      throw new Error('Required parameter email was null or undefined when calling loginGet.');
    }

    if (password === null || password === undefined) {
      throw new Error('Required parameter password was null or undefined when calling loginGet.');
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization',"Basic " + btoa(email + ':' + password));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/login`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe,
        reportProgress
      }
    );
  }


    /**
   * forget and reset password
   *
   * @param mail Adresse mail de l&#x27;utilisateur
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public forgetPassword(mail: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      if (mail === null || mail === undefined) {
        throw new Error('Required parameter email was null or undefined when calling loginGet.');
      }


      let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

      if (mail !== undefined && mail !== null) {
        queryParameters = queryParameters.set('mail', <any>mail);
      }

      let headers = new HttpHeaders();
    // authentication (Acess_Token) required
      headers.append('Access-Control-Allow-Origin', 'Access-Control-Allow-Origin: *');


      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

        return this.httpClient.request<any>('put', `${this.basePath}/forget_password`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

  public get_session( observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_session`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }







  public getParterShipIdToken( apiKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (apiKey === null || apiKey === undefined) {
      throw new Error('Required parameter apiKey was null or undefined when calling loginGet.');
    }

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization',"Basic " + apiKey);

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/login`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe,
        reportProgress
      }
    );
  }
    /**
   * Sert obtenir le key pour le webphone de Comunik a partir de idSession
   *
   */
     public UpdatesessionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      const queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<any>('put', `${environment.api_url}/update_session_start`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers,
          observe,
          reportProgress
        }
      );
    }
    /**
     * Sert obtenir le key pour le webphone de Comunik a partir de idSession
     *
     */
     public checksessionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      const queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.request<any>('get', `${environment.api_url}/check_session`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers,
          observe,
          reportProgress
        }
      );
    }


    public GetAccessList( observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }else return;

      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      return this.httpClient.request<any>('get', `${this.basePath}/fetch_user_access`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      });
    }

    private access$ = new BehaviorSubject<any>({});
    listOfAccess$ = this.access$.asObservable();

    setAccessListe(liste: any) {
      this.access$.next(liste);
    }
    private loginData$ = new BehaviorSubject<any>({});
    listLoginDatas$ = this.loginData$.asObservable();

    setLoginData(liste: any) {
      this.loginData$.next(liste);
    }

}
