import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OpportunitiesDetailsComponent } from './opportunities-details.component';
import { ProspectComponent } from './Elements/prospect/prospect.component';
import { DetailsComponent } from './Elements/details/details.component';
import { PlanificationComponent } from './Elements/planification/planification.component';
import { HistoriqueComponent } from './Elements/historique/historique.component';
import { CommunicationComponent } from './Elements/communication/communication.component';
import { ContractActuelComponent } from './Elements/details/contract-actuel/contract-actuel.component';
import { InformationComplementairesComponent } from './Elements/details/information-complementaires/information-complementaires.component';
import { InformationSpecifiquesComponent } from './Elements/details/information-specifiques/information-specifiques.component';
import { AssistantComponent } from './Elements/assistant/assistant.component';
import { AuthGard } from 'src/app/shared/AuthGard/auth-gard.guard';
import { CommentairesComponent } from './Elements/commentaires/commentaires.component';
import { EnregistrementDocumentsComponent } from './Elements/enregistrement-documents/enregistrement-documents.component';
import { TacheComponent } from './Elements/tache/tache.component';
import { TicketComponent } from './Elements/ticket/ticket.component';
import { ObjetAssurerComponent } from './Elements/objet-assurer/objet-assurer.component';

const routes: Routes = [
  {
    path: '',
    component: OpportunitiesDetailsComponent,
    // canActivate: [AuthGard],
    children: [
      {
        path: 'prospect',
        component: ProspectComponent,
      },
      {
        path: 'objet-assurer',
        component: ObjetAssurerComponent,
      },
      {
        path: 'details',
        component: DetailsComponent,
        children: [
          {
            path: 'contrat-actuel',
            component: ContractActuelComponent,
          },
          {
            path: 'information-complementaires',
            component: InformationComplementairesComponent,
          },
          {
            path: 'information-specifiques',
            component: InformationSpecifiquesComponent,
          },
        ],
      },
      {
        path: 'planification',
        component: PlanificationComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'historique',
        component: HistoriqueComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'communication',
        component: CommunicationComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'commentaire',
        component: CommentairesComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'tache',
        component: TacheComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'ticket',
        component: TicketComponent,
        // canActivate: [AuthGard],
      },
      {
        path: 'porteDocument',
        component: EnregistrementDocumentsComponent,
        // canActivate: [AuthGard],
      },
      {
        path: '**',
        redirectTo: 'prospect',
      },
    ],
  },
  {
    path: 'assistant',
    component: AssistantComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpportunitiesDetailsRoutingModule {}
