import { Component, OnInit ,Input, } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';

@Component({
  selector: 'app-add-tickets-affaire',
  templateUrl: './add-tickets-affaire.component.html',
  styleUrls: ['./add-tickets-affaire.component.css'],
})
export class AddTicketsAffaireComponent implements OnInit {

  @Input() prospect_id: string = '';
  id:string; 
  type:string="a"; 
  fromDetails:Boolean=true; 
  constructor(    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.paramMap.get('affaire_id');
  }

  ngOnInit(): void {
  
  }
}
