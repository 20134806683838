import {InjectionToken} from '@angular/core';
import {StoreConfig} from '@ngrx/store/src/store_module';
import {DetailsOpportuniteStatus} from './details-opportunite.reducers'

export const DETAILS_OPPORTUNITE_STORAGE_KEYS =
  new InjectionToken<string[]>('detailsOpportuniteStorageKeys');
export const DETAILS_OPPORTUNITE_LOCAL_STORAGE_KEY =
  new InjectionToken<string[]>('detailsOpportuniteStorage');
export const DETAILS_OPPORTUNITE_CONFIG_TOKEN =
  new InjectionToken<StoreConfig<DetailsOpportuniteStatus, any>>('detailsOpportuniteConfigToken');
