import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { OpportunitiesDetailsComponent } from '../../../opportunities-details.component';
import { Store } from '@ngrx/store';
import { DetailsOpportuniteStore } from './../../../store/details-opportunite.reducers';
import { Compagnie } from 'src/app/entity/Compagnie';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable, of, Subscription, Subject } from 'rxjs';
import { Component, OnInit, SimpleChanges, AfterViewInit } from '@angular/core';
import { Prospect } from '../../../../../../entity/Prospect';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import * as selectors from '../../../store';
import * as actions from '../../../store/details-opportunite.actions';
import { FormContract } from 'src/app/entity/Opportunity/FormContract';
import { CampagniesList } from 'src/app/entity/CampagniesList';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import swal from 'sweetalert2';

@Component({
  selector: 'app-contract-actuel',
  templateUrl: './contract-actuel.component.html',
  styleUrls: ['./contract-actuel.component.css'],
})
export class ContractActuelComponent implements OnInit, AfterViewInit {
  allowed_to_update: boolean;
  fa = fa;
  id_contrat: string;
  opportunity_id: string;
  prospect = new Prospect();
  contrat: FormContract = new FormContract();
  loading = true;
  cieControl: FormControl = new FormControl();
  siren: FormControl = new FormControl();
  filteredOptions: Observable<Compagnie[]>;
  allCompagnie: Compagnie[] = [];
  filteredCie: Observable<Compagnie[]>;
  statutContrat: boolean;
  contratActuel$: Observable<any> | null = null;
  form = new FormGroup({});
  testchanges: boolean = false;
  formContrat: FormGroup;
  loadingCie = new Subject<boolean>();
  public cieAutoComplete$: Observable<CampagniesList[]> = null;
  subscriptionCie: Observable<CampagniesList[]>;

  constructor(
    private route: ActivatedRoute,
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private sharedMenuObserverService: SharedMenuObserverService,
    private datePipe: DatePipe,
    private apiCampagniesService: ApiCampagniesService,
    private apiOpportunitService: ApiOpportunitService,
    private formlyJsonschema: FormlyJsonschema,
    private store$: Store<DetailsOpportuniteStore>
  ) {
    this.apiOpportunitService.listenContrat().subscribe((m: any) => {
      if (
        this.contrat.denomination === '' ||
        this.contrat.denomination === null ||
        this.contrat.siren === '' ||
        this.contrat.siren === null
      ) {
        this.alertError();
      } else {
        this.pushInfo();
      }
    });
  }

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  private _filterCie(name: string): Compagnie[] {
    const filterValue = name.toString().toLowerCase();
    return this.allCompagnie.filter((option) => option.denomination.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails ➜ Contrat Actuel');
    this.filteredOptions = this.cieControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCie(value))
    );
    this.formContrat = new FormGroup({
      denomination: new FormControl(),
      siren: new FormControl(),
    });
    this.contratActuel$ = this.store$.select(selectors.getContratActuelFromDetailsOpportunite);
    this.contratActuel$.subscribe((data) => {
      this.form = new FormGroup({});

      if (data !== null) {
        this.loading = false;
        if (data?.contratActuel === undefined) this.contrat = Object.assign({}, data);
        else this.contrat = Object.assign({}, data?.contratActuel);
        if (this.contrat.id === undefined || this.contrat.id === '') this.statutContrat = true;
        this.formContrat.controls.siren.setValue(this.contrat.siren);
        this.formContrat.controls.denomination.setValue(this.contrat.denomination);
      }
    });

    this.cieAutoComplete$ = this.formContrat.controls.denomination.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => {
        if (value !== '') {
          this.subscriptionCie = null;
          // lookup from github
          return this.getCampagnieCie();
        } else {
          // if no value is present, return null
          return of([]);
        }
      })
    );
  }

  getCampagnieCie(): Observable<CampagniesList[]> {
    this.loadingCie.next(true);
    this.subscriptionCie = this.apiCampagniesService
      .rechercheCompanieGet(this.formContrat?.value?.denomination?.toLowerCase())
      .pipe(
        map((res) => res.results),
        catchError((_) => {
          return of([]);
        })
      );
    this.subscriptionCie.subscribe(
      (result) => {
        if (result) this.loadingCie.next(false);
      },
      () => {
        this.loadingCie.next(false);
      }
    );
    return this.subscriptionCie;
  }

  selectTest(contrat: FormContract): void {
    this.contrat = contrat;
  }

  displayFn(cie: Compagnie): string {
    return cie && cie.denomination ? cie.denomination : '';
  }

  selectedCie(compagn: CampagniesList) {
    this.formContrat.controls.siren.setValue(compagn.id);
    this.formContrat.controls.denomination.setValue(compagn.text);
    this.contrat.denomination = compagn.text;
    this.contrat.siren = compagn.id;
    // this.contrat.siren =compagn.id ;
    this.pushInfo();
  }

  getDemononition() {
    if (this.contrat.denomination === '') {
      this.pushInfo();
    }
  }

  // ngOnDestroy(): void {
  //   this.pushInfo()
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunload($event: Event): void {
  //   this.pushInfo();
  // }

  onChangeJson($event: any) {
    // this.fields = [this.forlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify($event)))];
  }

  updateDate(e) {
    this.contrat.echeance_resiliation = this.datePipe.transform(this.contrat.echeance_resiliation, 'yyyy-MM-dd');
    this.pushInfo();
  }

  alertError() {
    swal.fire({
      // title: data,
      text: 'Vous devez remplir le CIE correctement',
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  pushInfo() {
    if (
      this.contrat.denomination === '' ||
      this.contrat.denomination === null ||
      this.contrat.siren === '' ||
      this.contrat.siren === null
    ) {
      this.contrat.siren = null;
      this.contrat.denomination = null;
      this.contrat.siren = '';
      this.contrat.denomination = '';
      // this.contrat.cie_text_id=null;
      // this.alertError();

      return;
    }
    const nouvdata = JSON.stringify(this.contrat);
    const anciendata = JSON.stringify(this.opportunitiesDetailsComponent?.detailsState?.constractActuel);
    if (anciendata !== nouvdata) this.store$.dispatch(actions.UpdateConstractActuelAction({ payload: this.contrat }));
  }

  testvaluechange() {
    this.testchanges = true;
  }
}
