<div class="card head">
    <div class="row details-header">
        <button type="button" class="btn-dt-retour prospect-btn-retour" (click)="retourToList()">
            <div class="icon-position">
                <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
            </div>
            <span> Retour </span>
        </button>

    </div>
</div>



<div class="card">
    <div class="row">
        <div class="col-12">
            <agm-map [latitude]="prospect.mainProspect.latitude" [zoom]="1.3" [longitude]="prospect.mainProspect.longitude" style="height: 350px;">
                <agm-marker [latitude]="prospect.mainProspect.latitude" [longitude]="prospect.mainProspect.longitude"></agm-marker>
            </agm-map>
        </div>
    </div>
</div>


<div class="card" id="scrollTop" *ngIf="prospect?.mainProspect !==null">
    <!-- <app-prospect [prospect]="prospect"></app-prospect> -->
    <app-full-prospect-details [person]="true"  [entreprise]="prospect" [typeProspect]="'Prospect'" [typeEntity]="'o'" ></app-full-prospect-details>
</div>

<div class="card">
    <h3> Opportunité(s) et affaire(s) ouvertes</h3>
    <app-fiche-opportunite-prospect [prospect]="prospect_id"></app-fiche-opportunite-prospect>
</div>

<div class="card">
    <h3>Relations </h3>
    <app-relations [prospect]="prospect_id" [opportunite_id]="opportunite_id" (emitProspectRelation)="getRelationProspect($event)">
    </app-relations>
</div>

<div class="card">
    <h3>Ticket(s)</h3>
    <app-tickets-fiche [prospect]="prospect_id"> </app-tickets-fiche>
</div>

<div class="card">
    <h3>Societé(s) </h3>
    <app-socicete [prospect]="prospect_id">
    </app-socicete>
</div>
