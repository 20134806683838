import { Banque } from './../../../../entity/banque';
import { Component,ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBanqueService } from 'src/app/services/ApiBanque/api-banque.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import * as fa from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert2';
@Component({
  selector: 'app-fiche-banque',
  templateUrl: './fiche-banque.component.html',
  styleUrls: ['./fiche-banque.component.css'],
})
export class FicheBanqueComponent implements OnInit {
  Banque : Banque = new Banque();
  idBanque="";
  ModeAjout: boolean = true;
  show_loading_add: boolean = false;
  fa = fa;
createBanque : Banque = new Banque();
  list_invalid: any;
  list_invalid_string: string = '';
  constructor(
    private route: ActivatedRoute,
    private ApiBanqueService: ApiBanqueService,
    private router: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private routes: Router,
    
   
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.idBanque=params.get('id')
      this.showloader();
      if (params.get('id') !== 'Nouveau') {
        this.ModeAjout = false;
        this.sharedMenuObserverService.updateMenu('Gestion des banques ➜ Details');

        this.showloader();
        this.ApiBanqueService.getBanquebyId(params.get('id')) .subscribe((response: any) => {
          this.Banque=response.banque
          this.hideloader();
        })
       
      } else {
        this.hideloader();
      }
    });
    if (this.ModeAjout) {
      this.sharedMenuObserverService.updateMenu('Gestion des banques ➜ Ajouter');
    } else {
      this.sharedMenuObserverService.updateMenu('Gestion des banques ➜ Details');
    }
  }
  EnregistrerBanque(valid, directive) {
    this.list_invalid = directive.filter((element) => element.control.status === 'INVALID');
    if (!valid) {
      this.alertWarning('Formulaire Invalid!');
    } else {
       this.show_loading_add = true;
    
      
      
    
      this.ApiBanqueService.InsertBanque(this.Banque).subscribe((Response) => {
      
        if (Response.erreur == false) {
          this.show_loading_add = false;
          this.idBanque=Response.id
          
          
          this.alertSuccess("Inséré avec succès", this.idBanque, true);
        
        }
        else{
          this.show_loading_add = false;
          this.alertError('Erreur');
        }
        this.show_loading_add = false;
       
        
      },
      (error) => {
        
        if (error.error.message == "Error while inserting data"){
          this.show_loading_add = false;
          this.alertError('Erreur');
        }
        else if (error.error.message == "code swift dupliqué"){
        this.show_loading_add = false;
        this.alertError('swift');}
        else if (error.error.message == "code banque dupliqué"){
        this.show_loading_add = false;
        this.alertError('banque');}
        else {
          this.show_loading_add = false;
          this.alertError('mal');
        }
      });
      
     
    }}
    alertSuccess(data, idBanque, modeedit) {
      swal
        .fire({
          title: 'Opération Réussie!',
          text: data,
          icon: 'success',
          showDenyButton: true,
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonColor: '#68a45b',
          focusConfirm: true,
          showCancelButton: modeedit,
          denyButtonText: 'Nouvelle Banque',
          denyButtonColor: '833626',
          cancelButtonText: 'détails',
          confirmButtonText: 'Liste des banques',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.hideloader();
            this.routes.navigate(['/gestion-banque']);
          }
          if (result.isDismissed) {
            this.hideloader();
            if (this.ModeAjout) this.routes.navigate(['/gestion-banque/fiche/' + idBanque]);
          }
          if (result.isDenied) {
            this.hideloader();
            this.routes.navigate(['/gestion-banque/fiche/Nouveau']);
            location.reload();
          }
        });
    }
    alertWarning(data) {
   
      this.list_invalid_string = '';
      this.list_invalid.forEach((element) => {
        if (typeof element.name === 'string')
          this.list_invalid_string =
            this.list_invalid_string + '<li style="list-style-type: none; font-size: 14px">' + element.name + '</li>';
      });
      swal.fire({
        title: data,
        icon: 'warning',
        html: '<b>Il faut remplir tout les champs!</b><br>' + '<ul>' + this.list_invalid_string + '</ul>',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#e75e5e',
      });
    }
    
    alertError(data) {
      if (data ==="Erreur"){
      swal.fire({
        title: data,
        text: "veillez à vérifier votre adresse",
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#d53a3a',
      });}
      else if (data==="swift"){
        swal.fire({
          title: data,
          text: "veillez à vérifier votre code swift",
          icon: 'error',
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonText: 'Fermer',
          confirmButtonColor: '#d53a3a',
        });}
        else if (data==="banque"){
          swal.fire({
            title: data,
            text: "veillez à vérifier votre code banque",
            icon: 'error',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: 'Fermer',
            confirmButtonColor: '#d53a3a',
          });}
          else
          {
            swal.fire({
              title: data,
              text: "Quelque chose s'est mal passé!",
              icon: 'error',
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonText: 'Fermer',
              confirmButtonColor: '#d53a3a',
            });}
    }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    
    this.Banque.adresse.ville = adress.locality;
    this.Banque.adresse.code_postal = adress.postal_code;
    this.Banque.adresse.pays=adress.pays;
    this.Banque.adresse.streetName=adress.name;
    this.Banque.adresse.streetNumber=adress.street_number
  }

  retourToList() {
    this.routes.navigate(['/gestion-banque']);
  }
  clearForm() {
    this.Banque.nom=""
    this.Banque.code_banque=""
    this.Banque.code_swift=""
    this.Banque.adresse.ville=""
    this.Banque.adresse.pays=""
    this.Banque.adresse.streetName=""
    this.Banque.adresse.code_postal=""
    this.Banque.adresse.streetNumber=""
   
    
    

    this.ngOnInit();
  }
  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

}
