import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { BankInformation } from './../../entity/BankInformation';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'shared-information-bank',
  templateUrl: './shared-information-bank.component.html',
  styleUrls: ['./shared-information-bank.component.css'],
})
export class SharedInformationBankComponent implements OnInit {
  @Output() onChangeBankInformation: EventEmitter<BankInformation[]> = new EventEmitter();
  @Input() bankInformation: BankInformation[];
  @Input() typeEntity? = '';
  @Input() customStyle?: string = '';
  @Input() allowed_to_update_affaire: boolean;
  bankForm: FormGroup;
  list_type_iban = [
    {
      'key': 'Prélèvement',
      'value': "prélèvement"
    },
    {
      'key': 'Remboursement',
      'value': "prestation"
    }];
  bank = new BankInformation();
  listBank: BankInformation[] = [];
  constructor(private _formBuilder: FormBuilder) {}
  fa = fa;

  ngOnInit(): void {
    if (this.bankInformation[0]?.id) {
      this.listBank = this.bankInformation;
      this.listBank.forEach((element: BankInformation, index: number) => {
        this.smellsLikeIban(index);
      });
    } else this.addBank();
  }

  addBank(): void {
    this.listBank.push(new BankInformation());
    if (this.listBank.length === 2 && this.listBank[0].type_iban === 'prestation')
      this.listBank[this.listBank.length - 1].type_iban = 'prélèvement';
    else this.listBank[this.listBank.length - 1].type_iban = 'prestation';
  }

  removeBank(index): void {
    this.listBank.splice(index, 1);
    this.changeBankInformation();
  }

  
  changeBankInformation(): void {
    
    const listIbanSend: BankInformation[] = [];
    Object.assign(listIbanSend, this.listBank);
    this.onChangeBankInformation.emit(listIbanSend);
   
  }
  
 
  
  
  smellsLikeIban(index): void {
    if (this.listBank[index].iban) {
      const nIban: string = this.listBank[index].iban
        .replace(/[^\dA-Za-z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
      this.listBank[index].iban = nIban.toLocaleUpperCase().trim();
    }
  }
}
