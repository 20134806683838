import { Component, OnInit, HostListener, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ActivatedRoute } from '@angular/router';
import { ApiOpportunitService } from '../../../../../../services/ApiOpportunite/api-opportunite.service';
import { OpportunitiesDetailsComponent } from '../../../opportunities-details.component';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { Observable } from 'rxjs';
import { DetailsOpportuniteStore } from '../../../store/details-opportunite.reducers';
import { Store } from '@ngrx/store';
import * as selectors from '../../../store';

import * as actions from '../../../store/details-opportunite.actions';
class formbuilder {
  data;
  schema;
}
class form {
  form: formbuilder;
  error;
}
class InformationComplementaires {
  informationComplementaires: form;
}
@Component({
  selector: 'app-information-complementaires',
  templateUrl: './information-complementaires.component.html',
  styleUrls: ['./information-complementaires.component.css'],
})
export class InformationComplementairesComponent implements OnInit, AfterViewInit {
  form = new FormGroup({});
  model: any = {};
  @Output() onFilter = new EventEmitter<any>();
  options: FormlyFormOptions = {};
  fields = [];
  loading: boolean = true;
  formdatacomp: any = null;
  anciendata: any = null;
  infocomplementaire$: Observable<any> | null = null;
  formdata: any = null;
  allowed_to_update: any;

  constructor(
    private route: ActivatedRoute,
    private formlyJsonschema: FormlyJsonschema,
    private apiOpportunitService: ApiOpportunitService,
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private sharedMenuObserverService: SharedMenuObserverService,
    private store$: Store<DetailsOpportuniteStore>
  ) {
    this.apiOpportunitService.listen().subscribe((m: any) => {
      this.pushInfo();
    });
  }

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.infocomplementaire$ = this.store$.select(selectors.getInfoComplementaireFromDetailsOpportunite);
    this.infocomplementaire$.subscribe((data) => {
      this.form = new FormGroup({});
      if (data !== null && data.informationComplementaires !== null) {
        this.formdatacomp = Object.assign({}, data);
        this.options = {};
        if (this.formdatacomp.informationComplementaires.form.schema) {
          /**
           * @param form permet de stocker && convertir schema
           * @param infoSpecifiquesPropreties permet de stocker la conversion des objets
           */
          const form: any = JSON.parse(JSON.stringify(data.informationComplementaires.form.schema));
          const infoComplementairesPropreties: any[] = [];
          form.properties.forEach((element) => {
            infoComplementairesPropreties[element.name] = element;
          });
          form.properties = infoComplementairesPropreties;
          this.fields = [this.formlyJsonschema.toFieldConfig(form)];
        }

        this.model =
          Object.keys(this.formdatacomp.informationComplementaires.form.data).length !== 0
            ? this.formdatacomp.informationComplementaires.form.data
            : null;
        this.loading = false;
      } else if (data !== null) {
        this.options = {};
        this.formdatacomp = Object.assign({}, data);
        if (this.formdatacomp.form.schema) {
          this.fields = [
            this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(this.formdatacomp.form.schema))),
          ];
        }
        this.model = this.formdatacomp.form.data;
        this.loading = false;
      }
    });
    this.loading = false;
    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails ➜ Informations complémentaires campagne');
  }

  ngOnDestroy(): void {
    this.pushInfo();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {
    this.pushInfo();
  }

  onChangeJson($event: any) {
    this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify($event)))];
  }

  onModelChange($event: any) {
    this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify($event)))];
  }

  pushInfo() {
    if (this.formdatacomp && this.formdatacomp.informationComplementaires) {
      this.formdata = new InformationComplementaires();
      this.formdata.informationComplementaires = new form();
      this.formdata.informationComplementaires.form = new formbuilder();
      this.formdata.informationComplementaires.error = false;
      this.formdata.informationComplementaires.form.schema = this.formdatacomp.informationComplementaires.form.schema;
      this.formdata.informationComplementaires.form.data =
        Object.keys(this.form.value).length !== 0
          ? this.form.value
          : this.formdatacomp.informationComplementaires.form.data;

      const nouvdata = JSON.stringify(this.formdata?.informationComplementaires?.form.data);
      const anciendata = JSON.stringify(
        this.opportunitiesDetailsComponent?.detailsState?.informationComplementaires?.informationComplementaires?.form
          .data
      );
      if (anciendata !== nouvdata)
        this.store$.dispatch(actions.UpdateInfoComplementaireAction({ payload: this.formdata }));
    }
  }
}
