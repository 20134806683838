import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';

@Component({
  selector: 'app-tache',
  templateUrl: './tache.component.html',
  styleUrls: ['./tache.component.css'],
})
export class TacheComponent implements OnInit, AfterViewInit {
  opportunity_id: string = '';
  allowed_to_update: any;

  constructor(
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private apiOpportunitService: ApiOpportunitService
  ) {}

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.opportunity_id = this.opportunitiesDetailsComponent.opportunity_id;
  }
}
