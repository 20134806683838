import {Component, OnInit} from '@angular/core';
import {Email, EmailClientLibService} from 'email-client-lib';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.css']
})
export class DriveComponent implements OnInit {

  constructor(private sharedMenuObserverService:SharedMenuObserverService
    ) { }

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu("Drive")
  }

}
