<div class="card-head" *ngIf="!ModeAjout">
    <div class="row">
        <div class="col-9 mt-2">
            <p>
                Cycle de vie n° :
                <label class="label-card-info">
            {{initialListeCycle.real_id}}
          </label>
            </p>
            <p>
                Nom du Cycle de vie :
                <label class="label-card-info">
          {{initialListeCycle.libele}}
        </label>
            </p>
        </div>
        <div class="col-3 text-left">
            <p> Statut :
                <label class="label-card-info">
          {{initialListeCycle.statut_depart}}
        </label>
            </p>
        </div>
    </div>
</div>

<div class="card-head text-center">
    <div class="row details-header">
        <button type="button" class="btn-dt-retour" (click)="retourToList()">
  <div class="icon-position">
    <fa-icon [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
  </div>
  <span> Retour à la liste </span>
</button>
        <div class="row">
            <div class="row details-spinner">
                <div class="col">
                    <ng-container *ngIf="show_loading_add">
                        <div class="mt-2">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                    </ng-container>
                </div>
                <div class="col mr-3">
                    <button type="button" class="btn-load" id="clear" (click)="clearForm()">
          <fa-icon [icon]="fa['faSyncAlt']"></fa-icon>
    </button>
                </div>
            </div>
            <button type="button" class="btn-dt-save" (click)="Enregistrer()">
    <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
      Enregistrer
  </button>
        </div>
    </div>
</div>

<div class="card p-3 border-0 m-3">
    <div class="row mt-3">
        <div class="col-6">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>Libelle</mat-label>
                    <input name="nom_organisme" [(ngModel)]="cycleBpm.libele" [formControl]="libelleFormControl" matInput required placeholder="Libelle">
                    <mat-error *ngIf="libelleFormControl.invalid && (libelleFormControl.dirty || libelleFormControl.touched) ">
                        <div *ngIf="libelleFormControl.hasError('required')">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Libelle obligatoire !
                        </div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6" *ngIf="!ModeAjout">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>Statut</mat-label>
                    <input type="text" required matInput [(ngModel)]="statut_depart" [formControl]="affecterCycleFormControl" name="workflow" [matAutocomplete]="autoWorkflowComm">
                    <mat-autocomplete autoActiveFirstOption [displayWith]="displayCycleVieFn" #autoWorkflowComm="matAutocomplete">
                        <mat-option *ngFor="let option of listCycleVieFilter | async" [value]="option">
                            {{option.etat}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="affecterCycleFormControl.invalid && (affecterCycleFormControl.dirty || affecterCycleFormControl.touched) ">
                        <div *ngIf="affecterCycleFormControl.hasError('required')">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Statut obligatoire !
                        </div>
                    </mat-error>
                    <mat-error *ngIf="affecterCycleFormControl.hasError('invalidAutocompleteObject') &&  !affecterCycleFormControl.hasError('required')">
                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le Statut !
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>


</div>

